<div class="quote-subtotal__label" [class.quote-subtotal__label--discount]="isDiscount">
  {{ totalLabel }}
  <kng-time-left
    *ngIf="isDiscount"
    [quoteKey]="quoteKey"
    [expirationDate]="closestExpirationDate"
    [activeQuoteSlot]="activeQuoteSlot"
    [isAccordionExpanded]="isAccordionExpanded"
    [quoteOutputsExpirationDates]="quoteOutputsExpirationDates"
  ></kng-time-left>
</div>
<div class="quote-subtotal__price" [class.quote-subtotal__price--discount]="isDiscount">
  {{ amountTotal | klgPrice : { currency: currency, minDecimalPlaces: decimalPlaces, multiplier: isDiscount ? -1 : 1 } }}
</div>
<kng-quote-accordion-item
  [totalItems]="totalItems"
  [isAccordionExpanded]="isAccordionExpanded"
  [roundedElements]="roundedElements"
  (expandAccordion)="expandAccordion.emit()"
></kng-quote-accordion-item>
