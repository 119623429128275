import { inject, Injectable } from '@angular/core';
import { getBrowserTimeZone } from '@klg/quote-tool/shared/utils/functions';
import { TIMEZONES } from '@klg/quote-tool/shared/types';

@Injectable({
  providedIn: 'root',
})
export class CountryDetectorService {
  private timeZones = inject(TIMEZONES);

  detectCountryCode(): string | undefined {
    // Get the user's timezone
    const userTimezone = getBrowserTimeZone();

    // Find the country code based on the user's timezone
    const [countryCode] = Object.entries(this.timeZones).find(([, timezones]) => timezones.includes(userTimezone)) ?? [];

    return countryCode;
  }
}
