import { bootstrapApplication } from '@angular/platform-browser';
import { AppComponent } from './app/app.component';
import { appConfig } from './app/app.config';
import { loadTranslationsOnBootstrap } from '@klg/shared/app-init/functions/load-translations-on-bootstrap.function';
import { environment } from './environments/environment';
import { EslQqToolParametersEnum } from './app/types/esl-qq-tool-parameters.enum';
import { parseLocale } from '@klg/shared/utils';

const getLocaleOnBootstrap: Promise<void> = new Promise((resolve) => {
  const queryParamsMap = new URLSearchParams(document.location.search);
  const language = queryParamsMap.get(EslQqToolParametersEnum.language);
  environment.LOCALE = parseLocale(language || environment.LOCALE);
  resolve();
});

getLocaleOnBootstrap.then(() =>
  loadTranslationsOnBootstrap(environment, () => bootstrapApplication(AppComponent, appConfig).catch((err) => console.error(err))),
);
