import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { CoreComponentsModule } from '@klg/core/components';
import { ClosingDatesPipe, OffsetTimePipe, ShowEnrolmentButtonPipe } from '@klg/quote-tool/shared/ui/pipes';
import { StepWrapperComponent } from './step-wrapper/step-wrapper.component';
import { PaymentCardOptionsComponent } from './payment-card-options/payment-card-options.component';
import { HolidayClosuresComponent } from './holiday-closures/holiday-closures.component';
import { ButtonComponent } from '@klg/shared/ui/button';
import { GoogleAnalyticsClickEventDirective } from '@klg/shared/google-analytics';

@NgModule({
  declarations: [StepWrapperComponent, HolidayClosuresComponent],
  imports: [
    CommonModule,
    CoreComponentsModule,
    PaymentCardOptionsComponent,
    ShowEnrolmentButtonPipe,
    OffsetTimePipe,
    ClosingDatesPipe,
    ButtonComponent,
    GoogleAnalyticsClickEventDirective,
  ],
  exports: [StepWrapperComponent, PaymentCardOptionsComponent, HolidayClosuresComponent, ShowEnrolmentButtonPipe, OffsetTimePipe],
})
export class QuoteToolSharedModule {}
