import { Component, Input } from '@angular/core';

@Component({
  selector: 'kng-quote-price-item',
  templateUrl: './quote-price-item.component.html',
  styleUrls: ['./quote-price-item.component.scss'],
})
export class QuotePriceItemComponent {
  @Input() hasProductPrice = false;
  @Input() productPrice = '';
}
