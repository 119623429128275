import { Component, Input } from '@angular/core';
import { KeyPoint, KeyPointIcons } from '@klg/shared/models';

@Component({
  selector: 'kng-quote-price-checklist',
  templateUrl: './quote-price-checklist.component.html',
  styleUrls: ['./quote-price-checklist.component.scss'],
})
export class QuotePriceChecklistComponent {
  @Input() icon: KeyPointIcons;
  @Input() label = '';
  @Input() currency = '';
  @Input() keyPoints: Array<KeyPoint> = [];
  @Input() decimalPlaces = 0;
}
