<div id="kng-select-button-group-{{ id }}" class="select-button" [attr.data-qa]="'kng-select-button-group-' + id">
  <div
    *ngFor="let item of defaultSorting ? (options | klgSortBy : [{ property: $any(displayExpr) ?? 'name' }]) : options; let idx = index"
    class="select-button__option"
    [ngClass]="{ 'select-button__option--selected': isItemSelected(item) }"
    (click)="onValueChangeFromSelectOption(item)"
    [@changeVisibility]="getState(item)"
    [attr.data-qa]="'select-button-' + id + '-' + idx"
  >
    <div class="select-button__selected-icon">
      <i class="pi pi-check select-button__selected-icon--style"></i>
    </div>
    <ng-container
      *ngTemplateOutlet="optionItemTpl ? optionItemTpl : defaultOptionItemTpl; context: { $implicit: item, isOpen: isOpen, isSelected: isItemSelected(item) }"
    ></ng-container>
  </div>
</div>

<ng-template #defaultOptionItemTpl let-option>{{ option[displayExpr] }}</ng-template>
