import { Component, EventEmitter, Input, Output } from '@angular/core';

@Component({
  selector: 'kng-quote-product-label',
  templateUrl: './quote-product-details-label.component.html',
  styleUrls: ['./quote-product-details-label.component.scss'],
})
export class QuoteProductDetailsLabelComponent {
  @Input() quoteKey = '';
  @Input() productName = '';
  @Input() productUnits = '';
  @Input() hasProductInfo = false;
  @Input() isProductEditable = false;
  @Input() hasProductLabelUnits = false;
  @Output() infoButtonClicked = new EventEmitter<void>();
  @Output() editButtonClicked = new EventEmitter<void>();
  @Output() removeButtonClicked = new EventEmitter<void>();
}
