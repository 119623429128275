import { Pipe, PipeTransform } from '@angular/core';
import { ProductType, QuoteOutput, TransferType } from '@klg/quote-tool/shared/types';
import { Unpacked } from '@klg/shared/utils-ts';

@Pipe({
  name: 'transfersByType',
  standalone: true,
})
export class TransfersByTypePipe implements PipeTransform {
  transform(
    products: QuoteOutput['products'],
    transferType: TransferType,
  ): Unpacked<QuoteOutput['products']> | Unpacked<QuoteOutput['products']>[keyof Unpacked<QuoteOutput['products']>] | null {
    const filteredProducts = products
      ?.filter((product) => product.classification === ProductType.TRANSFER)
      .filter((product) => product.transferServiceType?.toString().toLowerCase() === transferType?.toString().toLowerCase());
    if (!filteredProducts?.length) {
      return null;
    }
    return filteredProducts;
  }
}
