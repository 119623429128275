import { Component, EventEmitter, inject, OnInit, Output } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ToastModule } from 'primeng/toast';
import { MessageService } from 'primeng/api';
import { ERROR_TOAST_KEY, INFO_TOAST_KEY } from '@klg/shared/utils/constants';

@Component({
  standalone: true,
  selector: 'kng-toast-notification',
  imports: [CommonModule, ToastModule],
  templateUrl: './toast-notification.component.html',
  styleUrls: ['./toast-notification.component.scss'],
})
export class ToastNotificationComponent implements OnInit {
  @Output()
  errorEvent = new EventEmitter<void>();

  private readonly messageService = inject(MessageService);

  readonly infoToastKey = INFO_TOAST_KEY;
  readonly errorToastKey = ERROR_TOAST_KEY;

  ngOnInit(): void {
    this.messageService.messageObserver.subscribe(() => this.errorEvent.emit());
  }
}
