import { Component, Input, OnChanges, SimpleChanges } from '@angular/core';
import { KeyPointIcons } from '@klg/shared/models';

@Component({
  selector: 'kng-key-point',
  templateUrl: './key-point.component.html',
  styleUrls: ['./key-point.component.scss'],
})
export class KeyPointComponent implements OnChanges {
  @Input() icon: KeyPointIcons;
  @Input() text: string;
  @Input() additionalText: string;
  @Input() price: string;

  public keyPointClass = '';

  ngOnChanges(changes: SimpleChanges) {
    if ('icon' in changes) {
      this.keyPointClass = this.icon ? `key-point__text--${this.icon}` : this.icon;
    }
  }
}
