import { Injectable } from '@angular/core';
import { ProductsControllerApiService } from '@klg/shared/api/services';
import { Observable, of } from 'rxjs';
import { CourseType } from '@klg/shared/data-access/types';
import { BaseService, CachedRequestResponse } from '@klg/shared/utils-http';

@Injectable({
  providedIn: 'root',
})
export class CourseTypeService extends BaseService<CourseType> {
  constructor(private productsApiService: ProductsControllerApiService) {
    super();
  }

  protected responseCache = new CachedRequestResponse<CourseType[]>(
    () => this.productsApiService.getCourseTypes(this.locale, this.companyHeader),
    this.cacheSettings.get,
  );

  protected getResponseCache$(): Observable<CachedRequestResponse<CourseType[]>> {
    return of(this.responseCache);
  }
}
