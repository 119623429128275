<!-- TODO: Translate Labels-->
<kng-loading-spinner i18n-message message="Loading School Info..." [loading]="loading" shadingColor="rgba(0,0,0,0.5)"></kng-loading-spinner>
<kng-section-template [sectionIndex]="sectionIndex" i18n-sectionTitle sectionTitle="Select destination and school" data-qa="qt-select-destination">
  <kng-language-selector
    i18n-label
    label="Language"
    class="form-field__validated-selector-padding"
    [buttonView]="false"
    [value]="sectionModel.languageCode"
    [required]="true"
    (valueChange)="selectedLanguageChanged($event)"
    data-qa="qt-language-selector"
  ></kng-language-selector>

  <kng-destination-country-selector
    i18n-label
    label="Country"
    class="form-field__validated-selector-padding"
    [autofill]="!!sectionModel.languageCode"
    [disabled]="!sectionModel.languageCode"
    [buttonView]="false"
    [countries]="availableCountries"
    [value]="sectionModel.countryCode"
    [required]="true"
    [iconValidation]="true"
    (itemChange)="selectedCountryChanged($event)"
    data-qa="qt-country-selector"
  ></kng-destination-country-selector>

  <kng-city-selector
    i18n-label
    label="City"
    class="form-field__validated-selector-padding"
    [autofill]="!!sectionModel.countryCode"
    [disabled]="!sectionModel.countryCode"
    [buttonView]="false"
    [availableCities]="availableCities"
    [value]="sectionModel.destinationCity?.code"
    [countryCode]="sectionModel.countryCode"
    [required]="true"
    (itemChange)="selectedDestinationCityChanged($event)"
    data-qa="qt-city-selector"
  ></kng-city-selector>

  <kng-school-selector
    i18n-label
    label="School"
    class="form-field__validated-selector-padding"
    [autofill]="!!sectionModel.destinationCity"
    [disabled]="!sectionModel.destinationCity"
    [customDisabled]="true"
    [buttonView]="false"
    [value]="sectionModel.school"
    [language]="sectionModel.languageCode"
    [country]="sectionModel.countryCode"
    [city]="sectionModel.destinationCity?.code"
    [withFilterFn]="schoolFilterFn"
    [firstItem]="addUnknownOptionItem()"
    [required]="true"
    [iconValidation]="true"
    (itemChange)="selectedSchoolChanged($event)"
    data-qa="qt-school-selector"
  ></kng-school-selector>
</kng-section-template>
