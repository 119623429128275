import { CommonModule } from '@angular/common';
import { Component, Input, OnInit } from '@angular/core';
import { DateFormat } from '@klg/shared/i18n';
import { getCompany, getConfiguration } from '@klg/shared/tokens';
import { COMPANIES } from '@klg/shared/types';
import { DatePipe } from '@klg/shared/ui/pipes';
import { UrgencyDriverLabelComponent } from '../../prices';

const KIL_MAX_DIFF_DAYS = 35;
const ESL_MAX_DIFF_DAYS = 15;

@Component({
  selector: 'kng-time-left',
  standalone: true,
  imports: [CommonModule, DatePipe, UrgencyDriverLabelComponent],
  templateUrl: './time-left.component.html',
  styleUrls: ['./time-left.component.scss'],
})
export class TimeLeftComponent implements OnInit {
  @Input() quoteKey = '';
  @Input() expirationDate = '';
  @Input() activeQuoteSlot = 1;
  @Input() isAccordionExpanded = false;
  @Input() quoteOutputsExpirationDates = [];
  isEsl = false;
  diffDays = 0;
  isShowingPromoExpiry = false;
  isUrgencyDriverInRange = false;
  urgencyDriverDescription = $localize`Expires on `;
  date: Date | undefined;
  onlyAvailable = '';
  showExpiresOn = false;
  dateFormat = DateFormat;
  private readonly company = getCompany();
  private readonly configuration = getConfiguration();

  ngOnInit(): void {
    this.isEsl = this.company === COMPANIES.ESL;
    this.isShowingPromoExpiry = this.configuration.QUOTE_TOOL_SHOW_PROMOTION_EXPIRY as boolean;
    if (!this.isShowingPromoExpiry) {
      return;
    }
    this.isUrgencyDriverInRange = this.getExpirationDates(this.expirationDate);
    if (this.isUrgencyDriverInRange) {
      if (this.diffDays < 1) {
        this.onlyAvailable = this.isEsl ? $localize`Only today!` : $localize`Only 24 hours until offer ends!`;
      } else if (this.diffDays < 7) {
        this.onlyAvailable = this.isEsl
          ? $localize`Only ${this.diffDays} day${this.diffDays === 1 ? '' : 's'} left!`
          : $localize`Only ${this.diffDays} day${this.diffDays === 1 ? '' : 's'} left until offer ends!`;
      } else if (this.diffDays === 7) {
        this.onlyAvailable = this.isEsl ? $localize`Only 1 week left!` : $localize`Only 1 week left until offer ends!`;
      } else if (!this.isEsl && this.diffDays < KIL_MAX_DIFF_DAYS) {
        const diffWeeks = Math.floor(this.diffDays / 7);
        this.onlyAvailable = this.isEsl
          ? $localize`Only ${diffWeeks} week${diffWeeks === 1 ? '' : 's'} left!`
          : $localize`Only ${diffWeeks} week${diffWeeks === 1 ? '' : 's'} left until offer ends!`;
      } else {
        this.onlyAvailable = this.isEsl ? $localize`Only ${this.diffDays} days left!` : $localize`Only ${this.diffDays} days left until offer ends!`;
      }
    } else {
      this.onlyAvailable = '';
      this.showExpiresOn = this.isEsl;
    }
  }

  public getExpirationDates(date: string) {
    this.showExpiresOn = true;
    const today = new Date();
    today.setUTCHours(0, 0, 0, 0);
    this.date = new Date(date);
    this.diffDays = Math.ceil(Math.abs(this.date.getTime() - today.getTime()) / (1000 * 60 * 60 * 24));
    const maxDiffDays = this.isEsl ? ESL_MAX_DIFF_DAYS : KIL_MAX_DIFF_DAYS;
    return this.diffDays < maxDiffDays;
  }

  public isSelectedSlot(slot: string) {
    return slot === this.activeQuoteSlot.toString();
  }
}
