import { inject, Injectable } from '@angular/core';
import { AccommodationQuoteInput, CourseQuoteInput, QuoteInputForm } from '@klg/quote-tool/shared/types';
import { getTuitionWeeks, getValidAccommodations, getValidCourses } from '@klg/quote-tool/shared/data-access/quote';
import { QuoteRequestProductApiDto } from '@klg/shared/api';
import { PriceRequestExperimentService } from '../../experiments/price-request-experiment.service';

@Injectable({
  providedIn: 'root',
})
export class QuoteInputFormMapper {
  private readonly priceRequestExperimentService = inject(PriceRequestExperimentService);

  private convertToQuoteRequestProductApiDto = ({ code, startDate, weeks }: CourseQuoteInput | AccommodationQuoteInput) =>
    ({ code, startDate, weeks } as QuoteRequestProductApiDto);

  mapQuoteInputFormToQuoteRequestDto(quoteInput: QuoteInputForm) {
    const courses: CourseQuoteInput[] = getValidCourses(quoteInput, this.shouldValidateLevel());
    return {
      schoolCode: quoteInput.school,
      schoolType: quoteInput.schoolType,
      country: quoteInput.country?.name,
      city: quoteInput.destinationCity?.name,
      courseType: courses?.length && courses[0]?.courseType?.name,
      accommodationType: quoteInput.accommodationType,
      tuitionWeeks: getTuitionWeeks(quoteInput, this.shouldValidateLevel()),
      courseLevel: courses?.length && courses[0].level,
      courses: courses.map(this.convertToQuoteRequestProductApiDto),
      // First course would be the earliest, as in the following ones dates are restricted by the first one
      startDate: courses?.length && courses[0]?.startDate,
      currencyCode: quoteInput.destinationCurrency,
      accommodations: getValidAccommodations(quoteInput).map(this.convertToQuoteRequestProductApiDto),
      supplements: quoteInput.supplements || [],
      bookingDate: null,
    };
  }

  /**
   * Check if the level should be validated. If the price request experiment is active,
   * then, the level SHOULD NOT be validated.
   */
  private shouldValidateLevel() {
    return !this.priceRequestExperimentService.isPriceRequestExperimentActive();
  }
}
