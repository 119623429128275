import { Component, EventEmitter, Input, OnChanges, Output, SimpleChanges } from '@angular/core';
import { NumericCodeAndName } from '@klg/shared/types';
// eslint-disable-next-line @nrwl/nx/enforce-module-boundaries
import { LocalizeAndPluralizePeriodPipe } from '@klg/shared/ui/pipes';
import { FormFieldSelectorComponent } from '../form-field-selector/form-field-selector.component';
import { DEFAULT_WEEKS_RANGE } from '@klg/shared/utils';

@Component({
  standalone: true,
  selector: 'kng-weeks-selector',
  templateUrl: './weeks-selector.component.html',
  styleUrls: ['./weeks-selector.component.scss'],
  imports: [FormFieldSelectorComponent],
})
export class WeeksSelectorComponent implements OnChanges {
  @Input() label: string;
  @Input() disabled = false;
  @Input() value: number | undefined;
  @Input() minWeeks = DEFAULT_WEEKS_RANGE.MIN_WEEKS;
  @Input() maxWeeks = DEFAULT_WEEKS_RANGE.MAX_WEEKS;
  @Input() availableWeeksValues = [];
  @Input() placeholder: string;
  @Input() required = false;
  @Input() autofill = true;
  @Output() valueChange = new EventEmitter<number>();
  @Output() itemChange = new EventEmitter<NumericCodeAndName>();

  public weeksOptions: NumericCodeAndName[] = [];

  constructor(private localizeAndPluralizePeriodPipe: LocalizeAndPluralizePeriodPipe) {}

  ngOnChanges({ availableWeeksValues }: SimpleChanges) {
    if (this.availableWeeksValues?.length) {
      if (availableWeeksValues) {
        this.buildWeeksOptions(this.availableWeeksValues);
      }
    } else {
      this.buildWeeksOptions(this.availableWeeksValuesBetweenMinAndMax());
    }
  }

  private availableWeeksValuesBetweenMinAndMax(): number[] {
    return [...Array((this.maxWeeks ?? DEFAULT_WEEKS_RANGE.MAX_WEEKS) + 1).keys()].slice(Math.max(this.minWeeks ?? DEFAULT_WEEKS_RANGE.MIN_WEEKS, 1));
  }

  private buildWeeksOptions(availableWeeksValues: number[]) {
    this.weeksOptions = availableWeeksValues?.map((i) => ({ code: i, name: this.localizeAndPluralizePeriodPipe.transform(i) }));
  }
}
