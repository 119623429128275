import { AfterViewInit, Component, ElementRef, inject, Input, Renderer2, ViewChild } from '@angular/core';
import { CommonModule } from '@angular/common';
import { BreakpointObserver } from '@angular/cdk/layout';
import { ButtonComponent } from '@klg/shared/ui/button';
import { ArgoService } from '../../services/argo.service';

@Component({
  standalone: true,
  imports: [CommonModule, ButtonComponent],
  selector: 'klg-argo-ids',
  templateUrl: './argo-ids.component.html',
  styleUrls: ['./argo-ids.component.scss'],
})
export class ArgoIdsComponent implements AfterViewInit {
  @ViewChild('argoIdsContainer', { read: ElementRef }) argoIdsContainer: ElementRef;

  @Input() argoIds!: string[];

  private readonly renderer = inject(Renderer2);
  private readonly breakpointObserver = inject(BreakpointObserver);
  private readonly argoService = inject(ArgoService);

  private readonly DEFAULT_TOP = 10;

  ngAfterViewInit() {
    const qtMainContentElement = document.getElementById('qt-form-content-main-id');
    const qtAsideElement = document.getElementById('qt-form-content-side-id');
    this.observeQTFormHeightChanges(qtMainContentElement, qtAsideElement);
    this.setArgoIdsContainerTop(qtMainContentElement, qtAsideElement);
  }

  observeQTFormHeightChanges(qtMainContentElement: HTMLElement, qtAsideElement: HTMLElement) {
    if (qtMainContentElement && qtAsideElement) {
      const observer = new MutationObserver(() => {
        this.setArgoIdsContainerTop(qtMainContentElement, qtAsideElement);
      });

      observer.observe(qtMainContentElement, { attributes: true, childList: true, subtree: true });
      observer.observe(qtAsideElement, { attributes: true, childList: true, subtree: true });
    }
  }

  setArgoIdsContainerTop(qtMainContentElement: HTMLElement, qtAsideElement: HTMLElement) {
    const mainHeight = qtMainContentElement.offsetHeight;
    const asideHeight = qtAsideElement.offsetHeight;
    const isMobile = this.breakpointObserver.isMatched('(max-width: 767px)');
    const elementToMoveNative = this.argoIdsContainer.nativeElement as HTMLElement;

    if (mainHeight === asideHeight || isMobile) {
      this.renderer.setStyle(elementToMoveNative, 'top', `${this.DEFAULT_TOP}px`);
    } else if (mainHeight > asideHeight) {
      const heightDifference = mainHeight - (asideHeight + this.DEFAULT_TOP);
      this.renderer.setStyle(elementToMoveNative, 'top', `-${heightDifference}px`);
    }
  }

  copyArgoId(argoIdIndex) {
    const argoId = this.argoIds[argoIdIndex];
    this.argoService.copyIdToClipboard(argoId);
  }
}
