import { inject } from '@angular/core';
import { LoggerService } from './logger.service';
import { HttpErrorResponse } from '@angular/common/http';
import { parseApiError } from '../functions/parse-api-error.function';

export abstract class NotificationService {
  private readonly logger = inject(LoggerService);

  success(message: string, title: string = $localize`Success`): void {
    this.logger.info(this.createLoggerMessage(message, title));
  }

  info(message: string, title: string = $localize`Success`): void {
    this.logger.info(this.createLoggerMessage(message, title));
  }

  warn(message: string, title: string = $localize`Warn`): void {
    this.logger.warn(this.createLoggerMessage(message, title));
  }

  error(message: string, title: string = $localize`Error`, error?: Error): void {
    this.logger.error(this.createLoggerMessage(message, title), error ? error.message : '', error?.stack ? error.stack : '');
  }

  apiError(message: string, responseError: HttpErrorResponse, title: string = $localize`Error`): void {
    this.logger.error(this.createLoggerMessage(message, title), parseApiError(responseError));
  }

  private createLoggerMessage(message: string, title: string) {
    return `${title} - ${message}`;
  }
}
