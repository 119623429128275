import { FormType } from '@klg/quote-tool/shared/types';
import { QuoteRequestProductApiDto } from '@klg/shared/api/models';
import { addDays, addWeeks, getDateFromString, getDateStringFromDate } from '@klg/shared/utils';
import { DateRange, SchoolTypesEnum } from '@klg/shared/data-access/types';
import {
  AccommodationQuoteInput,
  CourseQuoteInput,
  noAccommodationOption,
  PremiumActivitiesSelection,
  ProductQuoteInputWithDate,
  QuoteInput,
  QuoteInputForm,
  QuoteInputModel,
  QuoteInputOptions,
  QuoteInputProduct,
  QuoteInputProductType,
  QuoteRequest,
  SupplementQuoteInput,
} from '@klg/quote-tool/shared/types/quote';

export function calculateEndDate({ startDate, startDateObject, weeks }: CourseQuoteInput | AccommodationQuoteInput): Date {
  return (startDateObject || startDate) && weeks && addWeeks(startDateObject || getDateFromString(startDate), weeks);
}

export function getAccommodationWeeks(quoteInput: QuoteInput): number {
  return getValidAccommodations(quoteInput)?.reduce((sum: number, { weeks }: AccommodationQuoteInput) => sum + weeks, 0) ?? 0;
}

export function getDestinationFromInput(quoteInput: QuoteInput): string {
  const cityName: string = quoteInput?.destinationCity?.name || '';
  const countryName: string = quoteInput?.country?.name || '';
  const separator: string = cityName && countryName ? ', ' : '';

  return cityName + separator + countryName;
}

export function getTuitionWeeks(quoteInput: QuoteInput, validateLevel = true): number {
  return getValidCourses(quoteInput, validateLevel)?.reduce((sum: number, { weeks }: CourseQuoteInput) => sum + weeks, 0) ?? 0;
}

export function getValidAccommodations(quoteInput: QuoteInput): AccommodationQuoteInput[] {
  return quoteInput?.accommodations?.filter((accommodation: AccommodationQuoteInput) => isAccommodationValid(accommodation, quoteInput.formType)) || [];
}

/**
 * Get valid courses from the quote input, if validateLevel is set to false, the level won't be taken
 * into account for the validations
 */
export function getValidCourses(quoteInput: QuoteInput, validateLevel = true): CourseQuoteInput[] {
  return quoteInput?.courses?.filter((course: CourseQuoteInput) => isCourseValid(course, quoteInput.formType, validateLevel)) || [];
}

export function getWeeksWithoutAccommodation(quoteInput: QuoteInput): number {
  return Math.max(getTuitionWeeks(quoteInput) - getAccommodationWeeks(quoteInput), 0);
}

export function hasIncompleteCourses(quoteInput: QuoteInput): boolean {
  return quoteInput?.courses?.length ? quoteInput?.courses?.some((course: CourseQuoteInput) => !isCourseValid(course, quoteInput.formType)) : false;
}

export const hasNoAccommodationsSelected = (quoteInput: QuoteInput): boolean => {
  return quoteInput?.accommodations?.some(({ code }: AccommodationQuoteInput) => code === noAccommodationOption.code);
};

export function isAccommodationMandatory(quoteInput: QuoteInput): boolean {
  return quoteInput?.schoolType === SchoolTypesEnum.JUNIOR || getValidCourses(quoteInput)?.some((course: CourseQuoteInput) => course.accommodationMandatory);
}

export function isAccommodationValid({ code, startDate, weeks }: CourseQuoteInput, formType: FormType): boolean {
  switch (formType) {
    case FormType.ENROLLMENT:
    case FormType.FREE_QUOTE:
    case FormType.QUICK_QUOTE_TOOL:
      return Boolean(startDate && code) && !isNaN(weeks) && weeks > 0;

    case FormType.PRICE_REQUEST: // Price request doesn't have accommodations
    default:
      return false;
  }
}

/**
 * Check if the course is valid, if validateLevel is set to false, the level won't be taken into account
 */
export function isCourseValid({ code, startDate, weeks, courseType, level }: CourseQuoteInput, formType: FormType, validateLevel = true): boolean {
  switch (formType) {
    case FormType.ENROLLMENT:
    case FormType.FREE_QUOTE:
    case FormType.QUICK_QUOTE_TOOL:
      return Boolean(startDate && code) && !isNaN(weeks) && weeks > 0;
    case FormType.PRICE_REQUEST:
      return Boolean(courseType?.code && courseType?.name && (!validateLevel || (level?.code && level?.name)) && startDate) && !isNaN(weeks) && weeks > 0;
    default:
      return false;
  }
}

export function isQuoteInputFormThatNeedPrices(quoteInput: QuoteInput): boolean {
  return [FormType.ENROLLMENT, FormType.FREE_QUOTE, FormType.QUICK_QUOTE_TOOL].includes(quoteInput?.formType);
}

export function isQuoteInputWizard(input: QuoteInput): boolean {
  return input?.formType === FormType.WIZARD;
}

type QuoteInputMapper = (quoteInput: QuoteInput, countryOfResidence?: string) => QuoteInputModel;

const mapQuoteInputV2FromQuoteInputWizard: QuoteInputMapper = (quoteInput: QuoteInput, countryOfResidence?: string): QuoteInputModel => {
  const options = {
    residenceCountry: countryOfResidence,
    schoolCode: quoteInput.school ?? quoteInput.camp,
    quoteDate: getDateStringFromDate(new Date()),
    productPackage: quoteInput.productPackage,
    optedOutProductCodes: quoteInput.optedOutProductCodes,
  } as QuoteInputOptions;

  const course = {
    code: quoteInput.course,
    dateRange: {
      startDate: quoteInput.startDate,
      endDate: quoteInput.endDate,
    },
    units: quoteInput.tuitionWeeks,
  } as QuoteInputProduct;

  const products = [course];

  if (quoteInput.accommodation) {
    products.push({
      code: quoteInput.accommodation.accommodation.code,
      dateRange: {
        startDate: quoteInput.accommodation.startDate,
        endDate: quoteInput.accommodation.endDate,
      },
      units: quoteInput.accommodation.weeks,
    });
  }

  if (quoteInput.insurance && !!quoteInput.insurance.weeks) {
    products.push({
      code: quoteInput.insurance.insurance.code,
      units: quoteInput.insurance.weeks,
    });
  }

  if (quoteInput.virtualInternship && !!quoteInput.virtualInternship.weeks) {
    const { code, weeks: units } = quoteInput.virtualInternship;
    products.push({ code, units });
  }

  if (quoteInput.premiumResidence && !!quoteInput.premiumResidence.weeks) {
    products.push({
      code: quoteInput.premiumResidence.premiumResidence.code,
      units: quoteInput.premiumResidence.weeks,
    });
  }

  if (quoteInput.transfer && quoteInput.transfer.fares && quoteInput.transfer.fares.length > 0) {
    quoteInput.transfer.fares.forEach((t) => {
      if (t.row) {
        products.push({
          row: t.row,
        });
      }
    });
  }

  if (quoteInput.transfer && quoteInput.transfer.unaccompaniedMinors && quoteInput.transfer.unaccompaniedMinors.length > 0) {
    quoteInput.transfer.unaccompaniedMinors.forEach((t) => {
      if (t.row) {
        products.push({
          row: t.row,
        });
      }
    });
  }

  if (quoteInput.premiumActivities && quoteInput.premiumActivities.length > 0) {
    const startDate = new Date(quoteInput.startDate);

    quoteInput.premiumActivities
      .filter((pa) => pa.selectedWeeks.length > 0)
      .forEach((pa) => {
        products.push({
          code: pa.activityCode,
          units: pa.selectedWeeks.length,
          selectedWeeks: getPremiumActivitySelectedWeeks(pa, startDate),
        });
      });
  }

  if (quoteInput.privateLessons && quoteInput.privateLessons.weeks.length > 0) {
    products.push({
      code: quoteInput.privateLessons.course.code,
      units: quoteInput.privateLessons.weeks.length,
    });
  }

  if (quoteInput?.optedOutProductCodes?.length > 0) {
    quoteInput.optedOutProductCodes.forEach((optedOutProduct) => products?.push({ code: optedOutProduct, units: 1 }));
  }

  return {
    options: options,
    products: products,
  };
};

function getPremiumActivitySelectedWeeks(pa: PremiumActivitiesSelection, startDate: Date): DateRange[] {
  return pa.selectedWeeks.map((weekNum) => ({
    startDate: getDateStringFromDate(addDays(startDate, 7 * (weekNum - 1))),
    endDate: getDateStringFromDate(addDays(startDate, 7 * weekNum - 1)),
  }));
}

const mapQuoteInputV2FromQuoteInputForm: QuoteInputMapper = (quoteInput: QuoteInput, countryOfResidence?: string): QuoteInputModel => {
  const courses: CourseQuoteInput[] = getValidCourses(quoteInput);
  const accommodations: AccommodationQuoteInput[] = getValidAccommodations(quoteInput);
  const supplements: SupplementQuoteInput[] = quoteInput.supplements ?? [];

  const options: QuoteInputOptions = {
    residenceCountry: countryOfResidence,
    schoolCode: quoteInput.school,
    schoolType: quoteInput.schoolType,
    country: quoteInput.countryCode,
    city: quoteInput.destinationCity.name,
    currencyCode: quoteInput.destinationCurrency,
    quoteDate: getDateStringFromDate(new Date()),
    courseLevel: courses?.length && courses[0].level,
    optedOutProductCodes: quoteInput.optedOutProductCodes,
  };

  const products: QuoteInputProduct[] = [
    ...courses.map((course): QuoteInputProduct => mapProductQuoteInputWithDate(course, 'COURSE')),
    ...accommodations.map((accommodation): QuoteInputProduct => mapProductQuoteInputWithDate(accommodation, 'ACCOMMODATION')),
    ...supplements.map(
      (supplement): QuoteInputProduct => ({
        code: supplement.code,
        productType: 'OPTION',
        units: supplement.quantity,
      }),
    ),
  ];

  return {
    options,
    products,
  };
};

const mapProductQuoteInputWithDate = (product: ProductQuoteInputWithDate, productType: QuoteInputProductType): QuoteInputProduct => ({
  productType,
  code: product.code,
  units: product.weeks,
  dateRange: {
    startDate: product.startDate,
  },
});

export function mapQuoteInputV2(quoteInput: QuoteInput, countryOfResidence?: string): QuoteInputModel | null {
  let mapper: QuoteInputMapper = null;
  if (isQuoteInputWizard(quoteInput)) {
    mapper = mapQuoteInputV2FromQuoteInputWizard;
  } else if (isQuoteInputFormThatNeedPrices(quoteInput)) {
    mapper = mapQuoteInputV2FromQuoteInputForm;
  }
  return mapper ? mapper(quoteInput, countryOfResidence) : null;
}

export const mapQuoteInputFormToQuoteRequestDto = (quoteInput: QuoteInputForm): QuoteRequest => {
  const courses: CourseQuoteInput[] = getValidCourses(quoteInput);
  return {
    schoolCode: quoteInput.school,
    schoolType: quoteInput.schoolType,
    country: quoteInput.country?.name,
    city: quoteInput.destinationCity?.name,
    courseType: courses?.length && courses[0]?.courseType?.name,
    accommodationType: quoteInput.accommodationType,
    tuitionWeeks: getTuitionWeeks(quoteInput),
    courseLevel: courses?.length && courses[0].level,
    courses: courses.map(convertToQuoteRequestProductApiDto),
    // First course would be the earliest, as in the following ones dates are restricted by the first one
    startDate: courses?.length && courses[0]?.startDate,
    currencyCode: quoteInput.destinationCurrency,
    accommodations: getValidAccommodations(quoteInput).map(convertToQuoteRequestProductApiDto),
    supplements: quoteInput.supplements || [],
    bookingDate: null,
  };
};

export const hasEnoughDataToGetQuote = (quoteInput: QuoteInput): boolean => {
  if (!quoteInput?.languageCode || !quoteInput?.school || !quoteInput.destinationCurrency) {
    return false;
  }
  const hasMultipleCourses: boolean = getValidCourses(quoteInput)?.length > 0;
  const hasAccommodations: boolean =
    !isAccommodationMandatory(quoteInput) || hasNoAccommodationsSelected(quoteInput) || getValidAccommodations(quoteInput)?.length > 0;
  return hasMultipleCourses && hasAccommodations;
};

export const hasEnoughDataToGetSupplements = (quoteInput: QuoteInput): boolean =>
  quoteInput.school &&
  getValidCourses(quoteInput)?.length > 0 &&
  (quoteInput.schoolType === SchoolTypesEnum.ADULT || hasNoAccommodationsSelected(quoteInput) || getValidAccommodations(quoteInput)?.length > 0);

const convertToQuoteRequestProductApiDto = ({ code, startDate, weeks }: CourseQuoteInput | AccommodationQuoteInput) =>
  ({ code, startDate, weeks } as QuoteRequestProductApiDto);
