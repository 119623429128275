import { Injectable } from '@angular/core';
import { ApiEnvironment } from '@klg/shared/types';
import { environment } from '../environments/environment';
import { BaseConfigService } from '@klg/shared/app-config';

@Injectable({
  providedIn: 'root',
})
export class ApplicationConfigurationService extends BaseConfigService {
  protected fetchApiEnvironment(): ApiEnvironment {
    return {
      API_BASE_PATH: environment.API_BASE_PATH,
    };
  }
}
