import { getValidAccommodations, getValidCourses, hasNoAccommodationsSelected } from '@klg/quote-tool/shared/data-access/quote';
import {
  AdultContactPersonSectionPayload,
  DestinationSchoolSectionPayload,
  JuniorContactPersonSectionPayload,
  QuoteInput,
  StudentDetails,
  TermsAndPromotionsSectionPayload,
} from '@klg/quote-tool/shared/types/quote';
import { FormType } from '@klg/quote-tool/shared/types';
import { SchoolTypes, SchoolTypesEnum } from '@klg/shared/data-access/types';

import { ConfigurationService } from '@klg/shared/app-config';

function isDestinationSchoolSectionPayloadValid(quoteInput: QuoteInput): boolean {
  const { languageCode, countryCode, destinationCity, schoolObject } = quoteInput as DestinationSchoolSectionPayload;
  return Boolean(languageCode && countryCode && destinationCity && schoolObject);
}

function isCourseDatesSectionPayloadValid(quoteInput: QuoteInput): boolean {
  if (quoteInput.formType === FormType.PRICE_REQUEST) {
    return quoteInput.courses?.length && getValidCourses(quoteInput)?.length > 0 && quoteInput.accommodationType != null;
  } else {
    return getValidCourses(quoteInput)?.length > 0;
  }
}

function isAccommodationSectionValid(quoteInput: QuoteInput): boolean {
  return quoteInput?.schoolType === SchoolTypesEnum.ADULT || hasNoAccommodationsSelected(quoteInput) || getValidAccommodations(quoteInput)?.length > 0;
}

function isTellUsAboutYouSectionValid(
  formType: FormType,
  {
    title,
    firstName,
    lastName,
    identityDocument,
    email,
    phoneNumber,
    postcode,
    city,
    countryOfResidence,
    address,
    placeOfBirth,
    nationality,
    motherTongue,
    dateOfBirth,
  }: StudentDetails,
): boolean {
  const commonFieldsValidation = title && firstName && lastName && email && phoneNumber && postcode && city && countryOfResidence;
  switch (formType) {
    case FormType.ENROLLMENT: {
      const enrollmentFieldsValidation = commonFieldsValidation && address && placeOfBirth && dateOfBirth && nationality && motherTongue;
      return Boolean(
        (ConfigurationService.getValue('ENROLLMENT_IDENTITY_DOCUMENT_ENABLED') as boolean)
          ? enrollmentFieldsValidation && identityDocument
          : enrollmentFieldsValidation,
      );
    }
    case FormType.PRICE_REQUEST:
      return Boolean(commonFieldsValidation && dateOfBirth && nationality);
    default:
      return Boolean(commonFieldsValidation);
  }
}

function isTermsAndPromotionsSectionValid(quoteInput: QuoteInput): boolean {
  const { agreeToTerms } = quoteInput as TermsAndPromotionsSectionPayload;
  return agreeToTerms;
}

function areContactPersonDetailsValid(schoolType: SchoolTypes, studentDetails: StudentDetails): boolean {
  if (!studentDetails.duringYourStay) return false;

  if (schoolType === SchoolTypesEnum.ADULT) {
    const { contactPersonFirstName, contactPersonLastName, contactPersonPhone } = studentDetails.duringYourStay as AdultContactPersonSectionPayload;
    return Boolean(contactPersonFirstName && contactPersonLastName && contactPersonPhone);
  } else {
    const { legalRepresentativeFirstName, legalRepresentativeLastName, legalRepresentativeEmail, legalRepresentativeMobile } =
      studentDetails.duringYourStay as JuniorContactPersonSectionPayload;
    return Boolean(legalRepresentativeFirstName && legalRepresentativeLastName && legalRepresentativeEmail && legalRepresentativeMobile);
  }
}

export function isStepQuoteFormValid(quoteInput: QuoteInput, studentDetails: StudentDetails): boolean {
  const quoteRequestValid = isQuoteRequestValid(quoteInput);
  switch (quoteInput.formType) {
    case FormType.PRICE_REQUEST:
      return quoteRequestValid && isTellUsAboutYouSectionValid(FormType.PRICE_REQUEST, studentDetails);
    case FormType.FREE_QUOTE:
      return quoteRequestValid && isTellUsAboutYouSectionValid(FormType.FREE_QUOTE, studentDetails);
    case FormType.ENROLLMENT:
    case FormType.QUICK_QUOTE_TOOL:
      return quoteRequestValid;
    default:
      return false;
  }
}

export function isQuoteRequestValid(quoteInput: QuoteInput): boolean {
  switch (quoteInput.formType) {
    case FormType.PRICE_REQUEST:
      return isDestinationSchoolSectionPayloadValid(quoteInput) && isCourseDatesSectionPayloadValid(quoteInput);
    case FormType.ENROLLMENT:
    case FormType.QUICK_QUOTE_TOOL:
    case FormType.FREE_QUOTE:
      return isDestinationSchoolSectionPayloadValid(quoteInput) && isCourseDatesSectionPayloadValid(quoteInput) && isAccommodationSectionValid(quoteInput);
    default:
      return false;
  }
}

export const isStepBookingFormValid = (quoteInput: QuoteInput, studentDetails: StudentDetails) =>
  isTellUsAboutYouSectionValid(FormType.ENROLLMENT, studentDetails) &&
  areContactPersonDetailsValid(quoteInput.schoolType, studentDetails) &&
  isTermsAndPromotionsSectionValid(quoteInput);
