import { inject, Injectable, OnDestroy } from '@angular/core';
import { Message, MessageService } from 'primeng/api';
import { ReplaySubject, Subscription } from 'rxjs';
import { map, reduce } from 'rxjs/operators';
import { NotificationService } from './notification.service';
import { HttpErrorResponse } from '@angular/common/http';
import { ERROR_TOAST_KEY, INFO_TOAST_KEY } from '@klg/shared/utils/constants';

@Injectable()
export class ToastNotificationService extends NotificationService implements OnDestroy {
  protected readonly message$ = new ReplaySubject<Message>();

  private readonly messageService = inject(MessageService);
  private readonly subscription = new Subscription();

  constructor() {
    super();
    this.setupToastSubscription();
  }

  override success(message: string, title: string = $localize`Success`): void {
    this.message$.next({ summary: title, detail: message, severity: 'success' });
    super.success(message, title);
  }

  override info(message: string, title: string = $localize`Info`): void {
    this.message$.next({ summary: title, key: INFO_TOAST_KEY, detail: message, severity: 'info', sticky: true });
    super.info(message, title);
  }

  override warn(message: string, title: string = $localize`Warning`): void {
    this.message$.next({ summary: title, detail: message, severity: 'warn' });
    super.warn(message, title);
  }

  override error(message: string, title: string = $localize`Error`, error?: Error): void {
    this.showErrorToast(title, message);
    super.error(message, title, error);
  }

  override apiError(message: string, responseError: HttpErrorResponse, title: string = $localize`Error`): void {
    this.showErrorToast(title, message);
    super.apiError(message, responseError, title);
  }

  errorNonDismissible(message: string, title: string = $localize`Error`): void {
    this.message$.next({ summary: title, detail: message, severity: 'error', sticky: true, closable: false });
    super.error(message, title);
  }

  repeat(number: number = 10): void {
    const tempReplaySubject = new ReplaySubject<Message>();
    this.message$.subscribe(tempReplaySubject);
    tempReplaySubject.complete();

    tempReplaySubject
      .pipe(
        reduce((acc: Message[], message: Message) => [...acc, message], []),
        map((messages: Message[]) => messages.slice(-number)),
      )
      .subscribe((messages: Message[]) => this.messageService.addAll(messages));
  }

  ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }

  private setupToastSubscription() {
    return this.message$.subscribe((message: Message) => this.messageService.add(message));
  }

  private showErrorToast(summary: string, detail: string) {
    this.message$.next({ summary, detail, key: ERROR_TOAST_KEY, severity: 'error', sticky: true });
  }
}
