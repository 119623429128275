import { Course } from '@klg/shared/data-access/types';
import { LightCourse } from '@klg/shared/data-access/course';

function hasCourseDatesAvailableByStartDate(course: Course, startDate: Date): boolean {
  return course?.courseDates?.some((courseDate) => new Date(courseDate.from) >= startDate);
}

export function findConnectedCourses<T extends Course | LightCourse = Course>(courses: T[] = []): T[] {
  const result: T[] = [];

  for (const course of courses) {
    const { relatedProducts } = course;
    const setOfRelatedProducts = new Set(relatedProducts || []);
    // Find a connected course in the result
    const existingConnectedCourse = result.find((filteredCourse) => setOfRelatedProducts.has(filteredCourse.code));

    if (!existingConnectedCourse) {
      // If there is no connected course in the result, add the course to the result
      result.push(course);
    } else if (!hasCourseDatesAvailableByStartDate(existingConnectedCourse, new Date())) {
      // If the existing connected course has no available dates for today, replace the existing connected course with the new course
      result[result.indexOf(existingConnectedCourse)] = course;
    }
  }

  return result;
}
