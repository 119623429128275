/**
 * KLG Quotes API
 * This is KLG API to perform quote calculations and retrieve auxiliary pricing information.
 *
 * The version of the OpenAPI document: dev
 * Contact: xxx@kaplan.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { QuoteRequestApiDto } from './quote-request.model';
import { QuoteLeadAnalyticsDataApiDto } from './quote-lead-analytics-data.model';
import { QuoteLeadUserDataApiDto } from './quote-lead-user-data.model';

export interface QuoteLeadApiDto {
  formOrigin?: QuoteLeadApiDto.FormOriginApiDtoEnum;
  quoteRequest?: Array<QuoteRequestApiDto>;
  leadUserData?: QuoteLeadUserDataApiDto;
  id?: number;
  analyticsData?: QuoteLeadAnalyticsDataApiDto;
}
export namespace QuoteLeadApiDto {
  export type FormOriginApiDtoEnum = 'PRICE_REQUEST' | 'QUICK_QUOTE' | 'ENROLMENT' | 'ONLINE_COURSES_ENROLMENT' | 'ENGLISH_TEST' | 'ARGO_QUICK_QUOTE_TOOL';
  export const FormOriginApiDtoEnum = {
    PRICE_REQUEST: 'PRICE_REQUEST' as FormOriginApiDtoEnum,
    QUICK_QUOTE: 'QUICK_QUOTE' as FormOriginApiDtoEnum,
    ENROLMENT: 'ENROLMENT' as FormOriginApiDtoEnum,
    ONLINE_COURSES_ENROLMENT: 'ONLINE_COURSES_ENROLMENT' as FormOriginApiDtoEnum,
    ENGLISH_TEST: 'ENGLISH_TEST' as FormOriginApiDtoEnum,
    ARGO_QUICK_QUOTE_TOOL: 'ARGO_QUICK_QUOTE_TOOL' as FormOriginApiDtoEnum,
  };
}
