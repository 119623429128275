import { Amount } from '@klg/shared/data-access/types';
import { Currency } from '../model/currency.model';

const getCurrencyFromPrice = ({ currency: currencyCode }: Amount) => ({
  code: currencyCode,
  name: currencyCode,
  countryIso: currencyCode.toLowerCase().substring(0, 2),
});

const sortCurrencies = (currencyA: Currency, currencyB: Currency) => (currencyA.code < currencyB.code ? -1 : 1);

export function getAvailableCurrencyList(currencyList?: Currency[], priceList?: Amount[]): Currency[] {
  if (priceList && currencyList) {
    return priceList
      .filter(nonZeroAmount)
      .map((priceItem: Amount) => currencyList.find((currencyItem) => currencyItem.code === priceItem.currency) || getCurrencyFromPrice(priceItem))
      .sort(sortCurrencies);
  } else {
    return currencyList || getPriceListCurrencies(priceList);
  }
}

export const getPriceListCurrencies = (priceList: Amount[]): Currency[] =>
  priceList?.filter(nonZeroAmount).map(getCurrencyFromPrice).sort(sortCurrencies) || [];
export const nonZeroAmount = (amount: Amount): boolean => !!amount.value;

export const hasCurrency = (priceList: Amount[] | Currency[], currencyCode: string): boolean =>
  !!(currencyCode && priceList) &&
  priceList?.some((priceItem: Amount | Currency) => (priceItem as Amount).currency === currencyCode || (priceItem as Currency).code === currencyCode);
