<div class="qt-form-section">
  <h3 class="qt-form-section__title">
    <span *ngIf="sectionIndex">{{ sectionIndex }}.</span>
    {{ sectionTitle }}
  </h3>

  <div [class.qt-form-section__form--two-columns]="columns === 2" class="qt-form-section__form">
    <ng-content></ng-content>
  </div>
</div>
