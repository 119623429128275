<div class="item-card" #container>
  <div
    *ngIf="showImage"
    class="item-card__image"
    [ngStyle]="{ backgroundImage: 'url(' + item[imageExpression] + ')', width: imageHeight + 'px' }"
    (click)="handleMoreDetailsClicked($event)"
    [kngGAClickEvent]="googleAnalyticsClickEvent"
  >
    <div class="item-card__more-details" *ngIf="showMoreDetails"></div>
  </div>

  <div class="item-card__cover" *ngIf="mode === 'cover'">
    <ng-container *ngTemplateOutlet="cardCoverTpl ? cardCoverTpl : defaultCardCoverTpl"></ng-container>
  </div>
  <div class="item-card__back" *ngIf="mode === 'back'">
    <ng-container *ngTemplateOutlet="cardBackTpl ? cardBackTpl : defaultCardBackTpl"></ng-container>
  </div>
</div>

<ng-template #defaultCardCoverTpl>
  <div class="item-card__label">{{ item[titleExpression] }}</div>
  <ng-content></ng-content>
</ng-template>

<ng-template #defaultCardBackTpl>
  <ng-container *ngTemplateOutlet="cardCoverTpl ? cardCoverTpl : defaultCardCoverTpl"></ng-container>
  <div class="item-card__summary">{{ item[summaryExpression] }}</div>
  <div
    *ngIf="item[imageExpression]"
    class="item-card__image"
    [ngStyle]="{ backgroundImage: 'url(' + item[imageExpression] + ')', width: imageHeight + 'px' }"
  ></div>
</ng-template>

<kng-offer-label
  *ngIf="hasPromotion"
  (showPromotionPopup)="showPromotionPopup(item)"
  [googleAnalyticsClickEvent]="googleAnalyticsOfferLabelClickEvent"
></kng-offer-label>
