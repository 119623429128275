import { Component, EventEmitter, inject, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { SectionTemplateComponent } from '../../../../sections/section-template/section-template.component';
import { FormFieldComponent } from '@klg/shared/ui/form-field';
import { InputTextModule } from 'primeng/inputtext';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { FormSection } from '@klg/quote-tool/shared/ui/directives';
import { FormFieldObject, SimplifiedTellUsAboutYouSectionFormPayload, StudentDetails } from '@klg/quote-tool/shared/types';
import { StepService } from '@klg/quote-tool/shared/services';
import { emailValidator, phoneValidator } from '@klg/core/validators';
import { BehaviorSubject, Subject, takeUntil } from 'rxjs';
import { NgIf } from '@angular/common';
import { TermsAndPromotionsFormSectionComponent } from '../../../../sections/terms-and-promotions/terms-and-promotions.component';
import { PriceRequestExperimentStore } from '../../../../../store/store';
import { DateOfBirthFieldComponent, DestinationComponentsModule } from '@klg/quote-tool/shared/ui/components';
import { PriceRequestExperimentService } from '../../../../../experiments/price-request-experiment.service';
import { getDateStringFromDate, getToday } from '@klg/shared/utils';
import { getLocale } from '@klg/shared/i18n';

@Component({
  standalone: true,
  selector: 'kng-price-request-student-form',
  templateUrl: './price-request-student-form.component.html',
  styleUrls: ['./price-request-student-form.component.scss'],
  imports: [
    SectionTemplateComponent,
    FormFieldComponent,
    InputTextModule,
    ReactiveFormsModule,
    FormsModule,
    NgIf,
    TermsAndPromotionsFormSectionComponent,
    DestinationComponentsModule,
    DateOfBirthFieldComponent,
  ],
})
export class PriceRequestStudentFormComponent extends FormSection<SimplifiedTellUsAboutYouSectionFormPayload> implements OnInit, OnDestroy {
  // Number of columns of the form, by default it will be 2 just to be as similar as the
  // original price request form
  @Input() columns = 2;

  // The form title (it could be empty)
  @Input() title = '';

  @Output() validForm = new EventEmitter<boolean>();

  isStepValid$ = new BehaviorSubject<boolean>(false);
  withNationalityField = false;

  formFields:
    | {
        [K in keyof Partial<SimplifiedTellUsAboutYouSectionFormPayload>]: FormFieldObject<SimplifiedTellUsAboutYouSectionFormPayload[K]>;
      }
    | undefined;

  private closeSubscriptions$: Subject<void> = new Subject<void>();
  private closeIsValidFormSubscription$: Subject<void> = new Subject<void>();

  private readonly priceRequestStore = inject(PriceRequestExperimentStore);
  private readonly priceRequestExperimentService = inject(PriceRequestExperimentService);
  private readonly locale = getLocale();

  constructor(stepService: StepService) {
    super(stepService);
  }

  ngOnInit(): void {
    // hard-coded locale due to being an experiment
    this.withNationalityField = this.locale === 'fr-FR';

    this.priceRequestStore.studentDetails$.pipe(takeUntil(this.closeSubscriptions$)).subscribe((studentDetails: StudentDetails) => {
      this.initFormFields(studentDetails);
      this.formData = this.getFormData();
      this.isStepValid$.next(this.isFormValid());
      this.closeSubscriptions$.next();
    });

    this.isStepValid$.pipe(takeUntil(this.closeIsValidFormSubscription$)).subscribe((isValid) => {
      if (isValid) {
        this.storeStudentDetails();
      }

      this.validForm.next(isValid);
    });
  }

  ngOnDestroy(): void {
    this.closeIsValidFormSubscription$.next();
  }

  /**
   * It validates the field whose key is passed as a parameter
   */
  validateField(key: keyof StudentDetails) {
    this.formData[key].validate();
    this.isStepValid$.next(this.isFormValid());
  }

  /**
   * Sets the selected country in the form and validates the field
   */
  setSelectedCountry(selectedItem: string) {
    const key = 'countryOfResidence';
    this.formData[key].value = selectedItem;
    this.validateField(key);
  }

  onSelectBirthDate(date: Date): void {
    const key = 'dateOfBirth';
    this.formData[key].value = getDateStringFromDate(date);
    this.validateField(key);
  }

  onSelectNationality(nationality: string): void {
    const key = 'nationality';
    this.formData[key].value = nationality;
    this.validateField(key);
  }

  protected getFormFields(): {
    [K in keyof Partial<SimplifiedTellUsAboutYouSectionFormPayload>]: FormFieldObject<SimplifiedTellUsAboutYouSectionFormPayload[K]>;
  } {
    return this.formFields;
  }

  protected getFieldsValues(fieldKeys: Array<keyof SimplifiedTellUsAboutYouSectionFormPayload>): Partial<SimplifiedTellUsAboutYouSectionFormPayload> {
    const values: Partial<SimplifiedTellUsAboutYouSectionFormPayload> = {};

    fieldKeys.forEach((key: keyof SimplifiedTellUsAboutYouSectionFormPayload) => {
      if (this.getFieldValueIfValid(key) !== undefined || this.getFormFields()[key].required) {
        values[key] = this.getFieldValueIfValid(key);
      }
    });

    return values;
  }

  protected storeStudentDetails() {
    const formValues = this.getFormValues() as StudentDetails;
    this.priceRequestStore.updateStudentDetails(formValues);
  }

  private initFormFields(studentDetails: StudentDetails) {
    this.formFields = {
      firstName: {
        value: studentDetails?.firstName ?? '',
        validator: (firstName) => firstName && firstName.length <= 80,
        errorMessage: $localize`Please enter a valid First Name`,
        required: true,
      },
      lastName: {
        value: studentDetails?.lastName ?? '',
        validator: (lastName) => lastName && lastName.length <= 40,
        errorMessage: $localize`Please enter a valid Last Name`,
        required: true,
      },
      email: {
        value: studentDetails?.email ?? '',
        validator: (email) => emailValidator(email),
        errorMessage: $localize`Please enter a valid Email`,
        required: true,
      },
      phoneNumber: {
        value: studentDetails?.phoneNumber ?? '',
        validator: (phone) => phoneValidator(phone),
        errorMessage: $localize`Please enter a valid Phone`,
        required: true,
      },
      postcode: {
        value: studentDetails?.postcode ?? '',
        validator: (postcode) => postcode && postcode.length <= 20,
        errorMessage: $localize`Please enter a valid Postcode`,
        required: true,
      },
      countryOfResidence: {
        value: studentDetails?.countryOfResidence ?? '',
        validator: Boolean,
        errorMessage: $localize`Please select a Country`,
        required: true,
      },
      dateOfBirth: {
        value: studentDetails?.dateOfBirth ?? '',
        validator: (birthDate: string) => new Date(birthDate).getTime() < getToday().getTime(),
        errorMessage: $localize`Please select your date of birth`,
        required: !this.priceRequestExperimentService.isPriceRequestExperimentDActive(),
      },
    };

    if (this.withNationalityField) {
      this.formFields = {
        ...this.formFields,
        nationality: {
          value: studentDetails?.nationality ?? '',
          validator: Boolean,
          errorMessage: $localize`Please enter a valid nationality`,
          required: true,
        },
      };
    }
  }

  private isFormValid(): boolean {
    const formFields = Object.values(this.formData);
    if (formFields.some((formField) => formField.isFieldRequiredAndPristineAndEmpty())) {
      return false;
    }

    formFields.filter((field) => field.isValid === undefined).forEach((field) => field.validate(field.isDirty));
    return formFields.every((field) => field.isValid);
  }
}
