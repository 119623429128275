export enum GoogleAnalyticsSteps {
  'country' = 1,
  'age' = 2,

  // Adult flow
  'adult-base' = 3,
  'adult-course' = 4,
  'adult-extras' = 5,

  // Junior flow
  'junior-base' = 3,
  'junior-extras' = 4,

  'quote' = 6,

  // Enrolment flow
  'enrolment' = 7,
  'payment' = 8,
  'payment-confirmation' = 12, // after all flywire steps

  // Email my quotes flow
  'registration' = 7,
  'delivery' = 8,

  'online-base' = 4,
}
