import { formatNumber } from '@angular/common';
import { Inject, LOCALE_ID, Pipe, PipeTransform } from '@angular/core';
import { Amount } from '@klg/shared/data-access/types';

@Pipe({
  name: 'klgPrice',
  standalone: true,
})
export class PricePipe implements PipeTransform {
  constructor(@Inject(LOCALE_ID) private locale: string = 'en') {}

  transform(
    value: Amount[] | Amount | number,
    {
      currency,
      showCurrency = true,
      multiplier = 1,
      minDecimalPlaces = 0,
      maxDecimalPlaces = minDecimalPlaces,
      roundValue = true,
      isDiscount = false,
      defaultValue = '',
    }: {
      currency?: string;
      showCurrency?: boolean;
      multiplier?: number;
      minDecimalPlaces?: number;
      maxDecimalPlaces?: number;
      roundValue?: boolean;
      isDiscount?: boolean;
      defaultValue?: string;
    } = {},
  ): string {
    let price: Amount;
    if (Array.isArray(value)) {
      const priceList = value;
      if (!currency) {
        throw Error('On multi-currency prices, target currency must be provided');
      }
      price = priceList.find((priceItem: Amount) => priceItem.currency === currency);
    } else {
      price = typeof value === 'number' ? { value } : value;
    }
    if (!price) return defaultValue;
    let totalPrice = price.value * multiplier;
    if (roundValue) {
      totalPrice = isDiscount ? Math.floor(totalPrice) : Math.round(totalPrice);
    }
    const formattedPriceValue = formatNumber(totalPrice, this.locale, `1.${minDecimalPlaces}-${maxDecimalPlaces}`);
    return `${formattedPriceValue} ${showCurrency && price?.currency ? price.currency : ''}`;
  }
}
