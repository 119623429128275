import { HttpErrorResponse } from '@angular/common/http';

const NO_EXTRA_INFO_ERROR_MESSAGE = 'An error has occurred';
const UNKNOWN_ERROR_REQUEST_OR_NOT_CONNECTION =
  'Unknown Error: Check internet connection. If the problem persists, the server could be unavailable at the moment, or there could be a problem in the request.';

export function parseApiError(response: HttpErrorResponse | string) {
  if (!response) return '';
  if (typeof response === 'string') return response;

  //When the client can't reach the server (CORS, No internet connection or server down) we have a status 0
  if (response.status === 0) {
    return UNKNOWN_ERROR_REQUEST_OR_NOT_CONNECTION;
  }
  if (response.error) {
    if (typeof response.error === 'string') {
      return response.error;
    }
    if (response.error.message) {
      return response.error.message;
    }
    if (response.statusText && response.statusText !== 'OK') {
      return `${typeof response.error === 'string' ? `${response.error} - ` : ''}${response.statusText}`;
    }
    if (response.error.validationErrors?.length > 0) {
      return response.error.validationErrors.length === 1
        ? response.error.validationErrors[0]
        : `Multiple validation errors: ${response.error.validationErrors.join(', ')}`;
    }
  }
  if (response.statusText && response.statusText !== 'OK') {
    return response.statusText;
  }
  if (response.message) {
    // removing awkward OK at the end (https://github.com/angular/angular/issues/23334)
    return response.message.replace(/ ok$/i, '');
  }

  return NO_EXTRA_INFO_ERROR_MESSAGE;
}
