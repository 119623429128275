import { Amount } from '@klg/shared/data-access/types';

export interface KeyPoint {
  description: string;
  additionalDescription?: string;
  price?: Amount;
}

export enum KeyPointIcons {
  INCLUDED = 'tick-green',
  EXCLUDED = 'delete-red',
}
