export const CustomEvents = {
  SESSION_STORAGE_CHANGES: '[SessionStorage] Change',
};

export const WEBAPP_PREFIX = 'KLG-webapp-';

// TODO: Move this to the quote tool/utils-storage
export const STORAGE_KEY_QT_MODE = 'QT_MODE';
export const STORAGE_KEY_QT_SLOTS = 'QT_SLOTS';
export const STORAGE_KEY_QT_STATE = 'QT_STATE';
export const STORAGE_KEY_DATA_LAYER_DETAILS = 'DATA_LAYER_DETAILS';
export const STORAGE_KEY_QT_ACTIVE_SLOT = 'QT_ACTIVE_SLOT';
export const STORAGE_KEY_QT_ACTIVE_STEP_ID = 'QT_ACTIVE_STEP_ID';
export const STORAGE_KEY_QT_STUDENT_DETAILS = 'QT_STUDENT_DETAILS';
export const STORAGE_KEY_QT_AGE_GROUP = 'QT_AGE_GROUP';
export const STORAGE_KEY_QT_RESIDENCE_DATA = 'QT_RESIDENCE_DATA';
export const STORAGE_KEY_QT_QUOTE_SUMMARY_CURRENCY = 'QT_QUOTE_SUMMARY_CURRENCY';
export const STORAGE_KEY_QT_PREFILLED_ENROLMENT_ID = 'QT_PREFILLED_ENROLMENT_ID';
export const STORAGE_KEY_QT_PREFILLED_KLG_TOKEN = 'QT_PREFILLED_KLG_TOKEN';
export const STORAGE_KEY_QT_PRESELECTED_MODE = 'QT_PRESELECTED_MODE';
export const STORAGE_KEY_QT_QUOTE_PAYMENT_STATUS = 'STORAGE_KEY_QT_QUOTE_PAYMENT_STATUS';
export const STORAGE_KEY_LAST_SELECTED_TAB = 'LAST_SELECTED_TAB';
