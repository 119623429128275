import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { PricePipe } from '@klg/shared/ui/pipes';
import { AccommodationOptionsComponent } from './accommodation-options/accommodation-options.component';
import { AccommodationOptionsSummaryComponent } from './accommodation-summary/accommodation-options-summary.component';
import { OfferLabelComponent } from '../offer-label/offer-label.component';
import { GoogleAnalyticsClickEventDirective } from '@klg/shared/google-analytics';

@NgModule({
  declarations: [],
  imports: [
    CommonModule,
    OfferLabelComponent,
    PricePipe,
    AccommodationOptionsComponent,
    AccommodationOptionsSummaryComponent,
    GoogleAnalyticsClickEventDirective,
  ],
  exports: [AccommodationOptionsComponent, AccommodationOptionsSummaryComponent],
})
export class AccommodationComponentsModule {}
