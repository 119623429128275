<p-selectButton
  [style.--button-group-alignment]="alignment"
  [class.kng-button-group--start]="['start', 'flex-start'].includes(alignment)"
  [class.kng-button-group--end]="['end', 'flex-end'].includes(alignment)"
  [class.kng-button-group--center]="alignment === 'center'"
  [class.kng-button-group--grouped]="grouped"
  [class.kng-button-group--rounded]="rounded"
  [class.kng-button-group--mobile]="isMobile"
  [class.kng-button-group--show-icon]="showIconOnSelected"
  class="kng-button-group klg-filter__options"
  [options]="items"
  [(ngModel)]="selectedValue"
  optionLabel="text"
  optionValue="value"
  (ngModelChange)="onItemClick($event)"
>
  <ng-template let-item pTemplate>
    <div class="kng-button-group-item" [class.kng-button-group-item--with-icon]="item.icon">
      <ng-container [ngTemplateOutlet]="item.icon ? iconItemTpl : defaultItemTpl" [ngTemplateOutletContext]="{ $implicit: item }"></ng-container>
    </div>
  </ng-template>
</p-selectButton>

<ng-template #iconItemTpl let-item>
  <span
    class="kng-button-group-item__icon"
    [class.kng-button-group-item__icon-all]="item.icon === 'all'"
    [class.kng-button-group-item__icon-adults]="item.icon === 'adults'"
    [class.kng-button-group-item__icon-juniors]="item.icon === 'juniors'"
  ></span>
  <div class="kng-button-group-item__text-container">
    <span class="p-button-label" [attr.data-qa]="'kng-button-group-item-' + item.index">{{ item.text }}</span>
    <span class="p-button-label kng-button-group-item__subtitle" *ngIf="item.subtitle">{{ item.subtitle }}</span>
  </div>
</ng-template>

<ng-template #defaultItemTpl let-item>
  <span class="p-button-label" [attr.data-qa]="'kng-button-group-item-' + item.index">{{ item.text }}</span>
</ng-template>
