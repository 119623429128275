const DOMAIN_COUNTRY_MAP = {
  de: 'DE',
  'de-at': 'AT',
  'de-ch': 'CH',
  fr: 'FR',
  'fr-ch': 'CH',
  'fr-be': 'BE',
  it: 'IT',
  'it-ch': 'CH',
  nl: 'NL',
  'en-gb': 'GB',
  es: 'ES',
  sv: 'SE',
};

export const getCountryOfResidenceFromDomain = () => {
  const lang = document?.documentElement?.lang;
  return DOMAIN_COUNTRY_MAP[lang] ?? '';
};
