import { Component, Input } from '@angular/core';
import { CommonModule } from '@angular/common';

@Component({
  standalone: true,
  imports: [CommonModule],
  selector: 'klg-sales-communication-settings',
  templateUrl: './sales-communication-settings.component.html',
  styleUrls: ['./sales-communication-settings.component.scss'],
})
export class SalesCommunicationSettingsComponent {
  @Input() argoIdentifier: string;
  @Input() clientName: string;
}
