import { Injectable, OnDestroy } from '@angular/core';
import { Router } from '@angular/router';
import { Meta, MetaDefinition } from '@angular/platform-browser';
import { Subscription } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class MetaService implements OnDestroy {
  private subscription = new Subscription();

  constructor(private meta: Meta, private router: Router) {}

  public addMetaTag(tag: MetaDefinition) {
    this.meta.addTag(tag);
  }

  public removeMetaTagOnRouteChange(tagName: string) {
    this.subscription.add(
      this.router.events.subscribe(() => {
        this.meta.removeTag(`name=${tagName}`);
      }),
    );
  }

  public ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }
}
