<div *ngIf="buttonView">
  <p-selectButton
    [options]="dataSource | klgSortBy : sortCriteria"
    [ngModel]="selectedValue"
    optionLabel="name"
    optionValue="code"
    styleClass="language__selector"
    (onChange)="languageSelected($event)"
    [attr.data-qa]="'language-selector'"
  ></p-selectButton>
</div>

<kng-form-field-selector
  *ngIf="!buttonView"
  [label]="label"
  [disabled]="disabled"
  [value]="value"
  valueExpr="code"
  [itemList]="dataSource"
  [buttonView]="false"
  [placeholder]="placeholder"
  [required]="required"
  [sortCriteria]="sortCriteria"
  [iconValidation]="true"
  (valueChange)="valueChange.emit($any($event))"
></kng-form-field-selector>
