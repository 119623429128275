/**
 * KLG Quotes API
 * This is KLG API to perform quote calculations and retrieve auxiliary pricing information.
 *
 * The version of the OpenAPI document: dev
 * Contact: xxx@kaplan.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { AmountApiDto } from './amount.model';

export interface SchoolSupplementApiDto {
  name?: string;
  productCode?: string;
  schoolCode?: string;
  price?: AmountApiDto;
  multiCurrencyPrice?: Array<AmountApiDto>;
  destinationCurrency?: string;
  row?: number;
  supplementType?: SchoolSupplementApiDto.SupplementTypeApiDtoEnum;
  transferDirection?: SchoolSupplementApiDto.TransferDirectionApiDtoEnum;
}
export namespace SchoolSupplementApiDto {
  export type SupplementTypeApiDtoEnum = 'UNACCOMPANIEDMINORSERVICE';
  export const SupplementTypeApiDtoEnum = {
    UNACCOMPANIEDMINORSERVICE: 'UNACCOMPANIEDMINORSERVICE' as SupplementTypeApiDtoEnum,
  };
  export type TransferDirectionApiDtoEnum = 'ARRIVAL' | 'DEPARTURE' | 'ROUND_TRIP' | 'NO_TRANSFER';
  export const TransferDirectionApiDtoEnum = {
    ARRIVAL: 'ARRIVAL' as TransferDirectionApiDtoEnum,
    DEPARTURE: 'DEPARTURE' as TransferDirectionApiDtoEnum,
    ROUND_TRIP: 'ROUND_TRIP' as TransferDirectionApiDtoEnum,
    NO_TRANSFER: 'NO_TRANSFER' as TransferDirectionApiDtoEnum,
  };
}
