<p-calendar
  #calendar
  class="calendar"
  selectionMode="single"
  [disabled]="disabled"
  [readonlyInput]="true"
  [firstDayOfWeek]="1"
  [defaultDate]="selectedDate"
  [dateFormat]="dateFormat"
  [minDate]="minDate!"
  [maxDate]="maxDate!"
  [inline]="inline"
  [disabledDates]="disabledDates!"
  [disabledDays]="disabledDays!"
  [yearRange]="yearRange!"
  [placeholder]="placeholder"
  [(ngModel)]="pickedDate.dateObject"
  (ngModelChange)="onValueChanges($event)"
  (onShow)="prepareCalendar()"
  [class.calendar__inline]="inline"
  (onFocus)="datePickerFocused.emit()"
  [touchUI]="mobileMode"
  appendTo="body"
>
  <ng-template pTemplate="header">
    <div class="calendar__header">
      <div class="calendar__header__date-selectors">
        <p-dropdown
          class="calendar__header__date-selectors__month"
          [scrollHeight]="monthScrollHeight"
          [options]="months"
          [(ngModel)]="selectedMonth"
          optionLabel="label"
          (onChange)="updateSelectedDate()"
        ></p-dropdown>
        <p-dropdown class="calendar__header__date-selectors__year" [options]="years" [(ngModel)]="selectedYear" (onChange)="updateSelectedDate()"></p-dropdown>
      </div>
      <div class="calendar__header__navigation-buttons">
        <p-button
          icon="pi pi-chevron-left"
          styleClass="p-button-rounded p-button-outlined"
          (onClick)="navigateToPreviousMonth()"
          [disabled]="previousMonthDisabled"
        ></p-button>
        <p-button
          icon="pi pi-chevron-right"
          styleClass="p-button-rounded p-button-outlined"
          (onClick)="navigateToNextMonth()"
          [disabled]="nextMonthDisabled"
        ></p-button>
      </div>
    </div>
  </ng-template>
  <ng-container *ngIf="footerText">
    <ng-template pTemplate="footer">
      <div class="calendar__footer">
        {{ footerText }}
      </div>
    </ng-template>
  </ng-container>
</p-calendar>
