<kng-item-card
  class="country-summary"
  imageExpression="thumbnailSmall"
  [mode]="showDetails ? 'back' : 'cover'"
  [showMoreDetails]="showMoreDetailsIcon"
  (moreDetailsClicked)="moreDetailsClicked.emit($event)"
  [item]="country"
  [containerElement]="elRef.nativeElement.parentElement"
  [googleAnalyticsClickEvent]="GoogleAnalyticsEvents.QUOTE_THUMBNAIL_COUNTRY"
>
  <ng-template #cardCoverTpl>
    <div class="country-summary__header">
      <div class="country-summary__name">{{ country.name }}</div>
    </div>
  </ng-template>
</kng-item-card>

<kng-offer-label
  *ngIf="country?.hasPromotion"
  (showPromotionPopup)="showPromotionPopup(country)"
  [googleAnalyticsClickEvent]="GoogleAnalyticsEvents.QUOTE_PROMOTION_COUNTRY"
></kng-offer-label>
