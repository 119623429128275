<div class="booking-form__error-message" *ngIf="(withValidationErrors$ | async) === true">
  <kng-form-error-message ngProjectAs="additional-info"></kng-form-error-message>
</div>

<kng-tell-us-about-you-section #tellUsAboutYouForm [formType]="formType" [sectionIndex]="tellUsAboutYouSectionIndex"></kng-tell-us-about-you-section>

<kng-extras-selection class="form-field__single-border-input" [schoolType]="schoolType" [sectionIndex]="tellUsAboutYouSectionIndex + 1"></kng-extras-selection>

<kng-contact-person-section
  #contactPersonForm
  class="form-field__single-border-input"
  [schoolType]="schoolType"
  [sectionIndex]="tellUsAboutYouSectionIndex + 2"
></kng-contact-person-section>

<kng-terms-and-promotions-form-section #termsAndPromotionsForm></kng-terms-and-promotions-form-section>
