import { inject, Injectable } from '@angular/core';
import { parse } from 'tinyduration';
import { DurationUnitEnum } from '@klg/shared/data-access/types';
import { DurationUnitsService } from '@klg/shared/utils/services';

type TinyDurationUnitEnum = 'YEARS' | 'MONTH' | 'WEEKS' | 'DAYS';
const TinyDurationUnitEnum = {
  weeks: 'WEEKS' as TinyDurationUnitEnum,
  months: 'MONTH' as TinyDurationUnitEnum,
  days: 'DAYS' as TinyDurationUnitEnum,
  years: 'YEARS' as TinyDurationUnitEnum,
};

@Injectable({
  providedIn: 'root',
})
export class DepositService {
  private localizedAnd = $localize`and`;
  private durationUnitsService = inject(DurationUnitsService);

  public getDepositRequiredMessage(depositRequiredThreshold: string): string {
    if (!depositRequiredThreshold) {
      return null;
    }

    const parsedDepositRequiredThreshold = parse(depositRequiredThreshold);
    const localizedThreshold = Object.keys(parsedDepositRequiredThreshold).map((k) =>
      this.durationUnitsService.getDurationText(parsedDepositRequiredThreshold[k], DurationUnitEnum[TinyDurationUnitEnum[k]]),
    );
    return localizedThreshold.join(', ').replace(/, ([^,]*)$/, ` ${this.localizedAnd} $1`);
  }
}
