import { Injectable } from '@angular/core';
import { ProductsControllerApiService } from '@klg/shared/api/services';
import { BaseService, CachedRequestResponse } from '@klg/shared/utils-http';
import { Observable, of } from 'rxjs';
import { map } from 'rxjs/operators';
import { ProgramType } from '../models';

@Injectable({
  providedIn: 'root',
})
export class ProgramTypeService extends BaseService<ProgramType> {
  private responseCache = new CachedRequestResponse<ProgramType[]>(
    () => this.productsApiService.getProgramTypes(this.locale, this.companyHeader),
    this.cacheSettings.get,
  );

  constructor(private productsApiService: ProductsControllerApiService) {
    super();
  }

  public getAllByCourseTypes(courseTypeCodes: string[]): Observable<ProgramType[]> {
    return this.getAll().pipe(
      map((programTypes) => {
        return programTypes.filter((programType) => programType?.courseTypes?.some((programCourseType) => courseTypeCodes.includes(programCourseType)));
      }),
    );
  }

  protected getResponseCache$(): Observable<CachedRequestResponse<ProgramType[]>> {
    return of(this.responseCache);
  }
}
