import { CommonModule } from '@angular/common';
import { Component, EventEmitter, HostBinding, Input, Output } from '@angular/core';
import { SafeResourceUrl } from '@angular/platform-browser';
import { HtmlATarget } from '@klg/shared/types';
import { LoadingIconSvgComponent } from './loading-icon/loading-icon.component';
import { ButtonIconPosition, ButtonSize, ButtonStyle } from './types';

@Component({
  selector: 'kng-button',
  standalone: true,
  imports: [CommonModule, LoadingIconSvgComponent],
  templateUrl: './button.component.html',
  styleUrls: ['./button.shared.component.scss', './button.sizes.component.scss', './button.styles.component.scss'],
})
export class ButtonComponent {
  @HostBinding('class') get classes(): string {
    const hostClass = 'klg-button';
    const styleClass = this.buttonStyle ? `${hostClass}--${this.buttonStyle.toLowerCase()}` : '';
    const sizeClass = this.size ? `${hostClass}--${this.size.toLowerCase()}` : '';
    const roundedClass = this.btnRounded ? `${hostClass}--rounded` : '';
    const blockClass = this.block ? `${hostClass}--block` : '';
    const loadingClass = this.loading ? `${hostClass}--loading` : '';

    return `${hostClass} ${styleClass} ${sizeClass} ${roundedClass} ${blockClass} ${loadingClass}`.trim();
  }

  @Input() svg = '';
  @Input() text = '';
  @Input() icon: string | undefined;
  @Input() href: string | SafeResourceUrl | undefined;
  @Input() target: HtmlATarget = '_self';
  @Input() size: ButtonSize = 'm';
  @Input() iconPos: ButtonIconPosition = 'before';
  @Input() svgColor = '';
  @Input() buttonId: string | undefined;
  @Input() buttonType: 'submit' | 'button' = 'button';
  @Input() buttonStyle: ButtonStyle = 'primary';
  @Input() googleTagManagerId = '';
  @Input() block = false;
  @Input() hasText = true;
  @Input() loading = false;
  @Input() iconFill = false;
  @Input() disabled = false;
  @Input() uppercase = false;
  @Input() textLight = false;
  @Input() btnRounded = false;
  @Input() iconRounded = false;
  @Input() isUnderlined = false;
  @Output() buttonClick = new EventEmitter();
}
