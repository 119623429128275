import { APP_INITIALIZER, inject } from '@angular/core';
import { CoreEnvironment } from '@klg/shared/types';
import { NGXLogger } from 'ngx-logger';
import { PrimeNGConfig } from 'primeng/api';
import { configFactory } from './config-factory.function';
import { localeFactory } from './locale.factory';
import { loggingFactory } from './logging.factory';
import { registerLocale } from './register-locale.function';
import { APPLICATION_CONFIGURATION_SERVICE, BaseConfigService } from '@klg/shared/app-config';
import { getCompanyHeader } from '@klg/shared/tokens';

export const getApplicationInitializationProviders = (env: CoreEnvironment) => [
  {
    provide: APP_INITIALIZER,
    useFactory: (configService: BaseConfigService) => configFactory(env, configService),
    deps: [APPLICATION_CONFIGURATION_SERVICE],
    multi: true,
  },
  {
    provide: APP_INITIALIZER,
    useFactory: () => {
      registerLocale(env.LOCALE);
      const primeNGConfig = inject(PrimeNGConfig);
      return localeFactory(primeNGConfig);
    },
    multi: true,
  },
  {
    provide: APP_INITIALIZER,
    useFactory: () => {
      const configService = inject(APPLICATION_CONFIGURATION_SERVICE);
      const loggingService = inject(NGXLogger);
      const companyHeader = getCompanyHeader();
      return loggingFactory(configService, loggingService, companyHeader, env);
    },
    multi: true,
  },
];
