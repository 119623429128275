import { inject, Injectable } from '@angular/core';
import { CookieService } from 'ngx-cookie-service';
import { PRESELECT_COUNTRY_AND_PHONE_COOKIE } from '../constants/experiments.constants';

@Injectable({
  providedIn: 'root',
})
export class CountriesExperimentsService {
  private readonly cookieService = inject(CookieService);

  isCountryAndPhoneExperimentActive(): boolean {
    // Check if the experiment cookie is present and set to 'true'
    return this.cookieService.check(PRESELECT_COUNTRY_AND_PHONE_COOKIE) && this.cookieService.get(PRESELECT_COUNTRY_AND_PHONE_COOKIE) === 'true';
  }
}
