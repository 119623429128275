import { Inject, Pipe, PipeTransform } from '@angular/core';
import { PATH_PREFIX } from '@klg/shared/tokens';
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';

@Pipe({
  name: 'safeUrl',
  standalone: true,
})
export class SafeUrlPipe implements PipeTransform {
  constructor(@Inject(PATH_PREFIX) private pathPrefix: string, private domSanitizer: DomSanitizer) {}

  transform(url: string, parameters: string = ''): SafeResourceUrl {
    return this.domSanitizer.bypassSecurityTrustResourceUrl(`${this.pathPrefix ?? ''}${url}${parameters}`);
  }
}
