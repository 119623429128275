import { Component, Input } from '@angular/core';
import { QuoteProduct } from '@klg/quote-tool/shared/types';

@Component({
  selector: 'kng-quote-product-supplement',
  templateUrl: './quote-product-details-supplement.component.html',
  styleUrls: ['./quote-product-details-supplement.component.scss'],
})
export class QuoteProductDetailsSupplementComponent {
  @Input() product: QuoteProduct | undefined;
  @Input() currency = '';
  @Input() decimalPlaces = 0;
}
