import { Component, EventEmitter, inject, Input, OnChanges, OnDestroy, OnInit, Output, SimpleChanges } from '@angular/core';
import { QuoteCampService } from '@klg/quote-tool/shared/data-access/school';
import { deepClone } from '@klg/shared/utils';
import { of, Subscription } from 'rxjs';
import { QuoteCamp } from '@klg/quote-tool/shared/types';
import { GoogleAnalyticsEvents } from '@klg/shared/google-analytics';
import { QuoteToolStore } from '@klg/quote-tool/shared/store';

@Component({
  selector: 'kng-camp-selector',
  templateUrl: './camp-selector.component.html',
  styleUrls: ['./camp-selector.component.scss'],
})
export class CampSelectorComponent implements OnInit, OnDestroy, OnChanges {
  @Input() label: string;
  @Input() value: string | undefined = undefined;
  @Input() country: string;
  @Input() language: string;
  @Input() buttonView = false;
  @Input() placeholder: string;
  @Input() isOpen = false;
  @Output() valueChange = new EventEmitter<string>();
  @Output() isOpenChange = new EventEmitter<boolean>();

  itemList: QuoteCamp[] = [];
  disabled = false;
  popupCamp: QuoteCamp = null;

  readonly GoogleAnalyticsEvents = GoogleAnalyticsEvents;

  private subscription: Subscription;

  private readonly campService = inject(QuoteCampService);
  private readonly quoteToolStore = inject(QuoteToolStore);

  private getItemList() {
    // Unsubscribe from previous, subscription and create a new one.
    // If we don't create a new one, it will unsubscribe from the subscription added next
    this.subscription?.unsubscribe();
    this.subscription = new Subscription();

    const getCampList$ = this.country && this.language ? this.campService.getAllByCountryAndLanguage(this.country, this.language) : of([]);
    this.subscription.add(
      getCampList$.subscribe((data) => {
        this.itemList = data.map(deepClone);
        this.disabled = !this.itemList.length;

        // Store camps in quote tool store
        this.quoteToolStore.addCamps(this.itemList);
      }),
    );
  }

  ngOnInit() {
    this.getItemList();
  }

  ngOnChanges({ country, language }: SimpleChanges): void {
    if (country || language) {
      this.getItemList();
    }
  }

  ngOnDestroy() {
    this.subscription?.unsubscribe();
  }
}
