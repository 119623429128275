import { ApiEnvironment, Configuration, CoreEnvironment, EnvironmentAndConfiguration, RuntimeEnvironment } from '@klg/shared/types';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { companyMarketConfigUIApiDtoToConfiguration } from '@klg/shared/utils';
import { catchError, map, tap } from 'rxjs/operators';
import { firstValueFrom, of } from 'rxjs';
import { inject } from '@angular/core';
import { getCompanyHeader } from '@klg/shared/tokens';
import { LoggerService } from '@klg/shared/logger';

const DEFAULT_CONFIGURATION = [
  { varname: 'QUOTE_TOOL_DATE_FORMAT_SHORT_MONTH_NAME', value: 'd MMM yyyy' },
  { varname: 'QUOTE_TOOL_DATE_FORMAT_FULL_MONTH_NAME', value: 'd MMMM y' },
  { varname: 'QUOTE_TOOL_DATE_FORMAT_NUMBERS_ONLY', value: 'dd-MM-y' },
  { varname: 'QUOTE_TOOL_DATE_FORMAT_SHORT_MONTH_NAME_PRIME_NG', value: 'd M yy' },
];

export abstract class BaseConfigService {
  protected environment: RuntimeEnvironment;
  protected configuration: Configuration;

  private readonly http = inject(HttpClient);
  private readonly logger = inject(LoggerService);

  public async getEnvironmentAndConfiguration(coreEnvironment: CoreEnvironment): Promise<EnvironmentAndConfiguration> {
    const apiEnvironment = this.fetchApiEnvironment(coreEnvironment);
    this.environment = Object.freeze({ ...coreEnvironment, ...apiEnvironment });
    await this.fetchConfiguration();
    return Object.assign({}, this.environment, this.configuration);
  }

  public getEnvironment(coreEnvironment: CoreEnvironment): RuntimeEnvironment {
    if (this.environment) {
      return this.environment;
    }
    const apiEnvironment = this.fetchApiEnvironment(coreEnvironment);
    this.environment = Object.freeze({ ...coreEnvironment, ...apiEnvironment });
    return this.environment;
  }

  protected abstract fetchApiEnvironment(environment?: RuntimeEnvironment): ApiEnvironment;

  public getConfiguration(): Configuration {
    return this.configuration;
  }

  protected fetchConfiguration(): Promise<Configuration> {
    const headers = new HttpHeaders({
      'Accept-Language': this.environment.LOCALE,
      'Accept-Company': getCompanyHeader(this.environment.COMPANY),
    });
    return firstValueFrom(
      this.http.get(`${this.environment.API_BASE_PATH}/admin/v2/configuration`, { headers, responseType: 'json' }).pipe(
        catchError((e) => {
          this.logger.error('Error fetching the configuration.', e);
          return of(DEFAULT_CONFIGURATION);
        }),
        map(companyMarketConfigUIApiDtoToConfiguration),
        tap({ next: (configuration: Configuration) => (this.configuration = Object.freeze(configuration)) }),
      ),
    );
  }
}
