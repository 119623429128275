/**
 * KLG Quotes API
 * This is KLG API to perform quote calculations and retrieve auxiliary pricing information.
 *
 * The version of the OpenAPI document: dev
 * Contact: xxx@kaplan.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
/* tslint:disable:no-unused-variable member-ordering */

import { Inject, Injectable, Optional } from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams, HttpResponse, HttpEvent, HttpParameterCodec } from '@angular/common/http';
import { CustomHttpParameterCodec } from '../encoder';
import { Observable } from 'rxjs';

import { CourseApiDto } from '../model/models';
import { DefaultErrorMessageApiDto } from '../model/models';
import { ExtrasSummaryApiDto } from '../model/models';
import { FindSchoolRequestApiDto } from '../model/models';
import { LightSchoolApiDto } from '../model/models';
import { OnlineClassComboApiDto } from '../model/models';
import { OnlineSchoolResponseApiDto } from '../model/models';
import { PredefinedOnlineSelectionApiDto } from '../model/models';
import { PricingPackageApiDto } from '../model/models';
import { ProductPackageApiDto } from '../model/models';
import { QuotesCourseApiDto } from '../model/models';
import { SchoolApiDto } from '../model/models';
import { SchoolExtrasApiDto } from '../model/models';
import { SchoolListingSummaryApiDto } from '../model/models';
import { SchoolSummaryApiDto } from '../model/models';
import { SchoolV4ApiDto } from '../model/models';
import { TripDatesApiDto } from '../model/models';

import { BASE_PATH, COLLECTION_FORMATS } from '../variables';
import { Configuration } from '../configuration';
import { SchoolControllerApiServiceInterface } from './school-controller.serviceInterface';

@Injectable({
  providedIn: 'root',
})
export class SchoolControllerApiService implements SchoolControllerApiServiceInterface {
  protected basePath = 'http://apiv2-dev.klgintdc.com/api';
  public defaultHeaders = new HttpHeaders();
  public configuration = new Configuration();
  public encoder: HttpParameterCodec;

  constructor(protected httpClient: HttpClient, @Optional() @Inject(BASE_PATH) basePath: string, @Optional() configuration: Configuration) {
    if (configuration) {
      this.configuration = configuration;
    }
    if (typeof this.configuration.basePath !== 'string') {
      if (typeof basePath !== 'string') {
        basePath = this.basePath;
      }
      this.configuration.basePath = basePath;
    }
    this.encoder = this.configuration.encoder || new CustomHttpParameterCodec();
  }

  private addToHttpParams(httpParams: HttpParams, value: any, key?: string): HttpParams {
    if (typeof value === 'object' && value instanceof Date === false) {
      httpParams = this.addToHttpParamsRecursive(httpParams, value);
    } else {
      httpParams = this.addToHttpParamsRecursive(httpParams, value, key);
    }
    return httpParams;
  }

  private addToHttpParamsRecursive(httpParams: HttpParams, value?: any, key?: string): HttpParams {
    if (value == null) {
      return httpParams;
    }

    if (typeof value === 'object') {
      if (Array.isArray(value)) {
        (value as any[]).forEach((elem) => (httpParams = this.addToHttpParamsRecursive(httpParams, elem, key)));
      } else if (value instanceof Date) {
        if (key != null) {
          httpParams = httpParams.append(key, (value as Date).toISOString().substr(0, 10));
        } else {
          throw Error('key may not be null if value is Date');
        }
      } else {
        Object.keys(value).forEach((k) => (httpParams = this.addToHttpParamsRecursive(httpParams, value[k], key != null ? `${key}.${k}` : k)));
      }
    } else if (key != null) {
      httpParams = httpParams.append(key, value);
    } else {
      throw Error('key may not be null if value is not object or array');
    }
    return httpParams;
  }

  /**
   * @param schoolCode
   * @param acceptLanguage Language code to inform the display language for the results
   * @param acceptCompany Company code for the source of data
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
  public findExtrasSummary(
    schoolCode: string,
    acceptLanguage: string,
    acceptCompany: 'ALP' | 'KIL' | 'ESL' | 'KSG',
    observe?: 'body',
    reportProgress?: boolean,
    options?: { httpHeaderAccept?: 'application/json' },
  ): Observable<ExtrasSummaryApiDto>;
  public findExtrasSummary(
    schoolCode: string,
    acceptLanguage: string,
    acceptCompany: 'ALP' | 'KIL' | 'ESL' | 'KSG',
    observe?: 'response',
    reportProgress?: boolean,
    options?: { httpHeaderAccept?: 'application/json' },
  ): Observable<HttpResponse<ExtrasSummaryApiDto>>;
  public findExtrasSummary(
    schoolCode: string,
    acceptLanguage: string,
    acceptCompany: 'ALP' | 'KIL' | 'ESL' | 'KSG',
    observe?: 'events',
    reportProgress?: boolean,
    options?: { httpHeaderAccept?: 'application/json' },
  ): Observable<HttpEvent<ExtrasSummaryApiDto>>;
  public findExtrasSummary(
    schoolCode: string,
    acceptLanguage: string,
    acceptCompany: 'ALP' | 'KIL' | 'ESL' | 'KSG',
    observe: any = 'body',
    reportProgress: boolean = false,
    options?: { httpHeaderAccept?: 'application/json' },
  ): Observable<any> {
    if (schoolCode === null || schoolCode === undefined) {
      throw new Error('Required parameter schoolCode was null or undefined when calling findExtrasSummary.');
    }
    if (acceptLanguage === null || acceptLanguage === undefined) {
      throw new Error('Required parameter acceptLanguage was null or undefined when calling findExtrasSummary.');
    }
    if (acceptCompany === null || acceptCompany === undefined) {
      throw new Error('Required parameter acceptCompany was null or undefined when calling findExtrasSummary.');
    }

    let headers = this.defaultHeaders;
    if (acceptLanguage !== undefined && acceptLanguage !== null) {
      headers = headers.set('Accept-Language', String(acceptLanguage));
    }
    if (acceptCompany !== undefined && acceptCompany !== null) {
      headers = headers.set('Accept-Company', String(acceptCompany));
    }

    let httpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
    if (httpHeaderAcceptSelected === undefined) {
      // to determine the Accept header
      const httpHeaderAccepts: string[] = ['application/json'];
      httpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
    }
    if (httpHeaderAcceptSelected !== undefined) {
      headers = headers.set('Accept', httpHeaderAcceptSelected);
    }

    let responseType: 'text' | 'json' = 'json';
    if (httpHeaderAcceptSelected && httpHeaderAcceptSelected.startsWith('text')) {
      responseType = 'text';
    }

    return this.httpClient.get<ExtrasSummaryApiDto>(
      `${this.configuration.basePath}/products/v4/schools/${encodeURIComponent(String(schoolCode))}/extras-summary`,
      {
        responseType: <any>responseType,
        withCredentials: this.configuration.withCredentials,
        headers: headers,
        observe: observe,
        reportProgress: reportProgress,
      },
    );
  }

  /**
   * @param acceptLanguage Language code to inform the display language for the results
   * @param acceptCompany Company code for the source of data
   * @param residenceCountryCode
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
  public findOnlineSchools(
    acceptLanguage: string,
    acceptCompany: 'ALP' | 'KIL' | 'ESL' | 'KSG',
    residenceCountryCode?: string,
    observe?: 'body',
    reportProgress?: boolean,
    options?: { httpHeaderAccept?: 'application/json' },
  ): Observable<OnlineSchoolResponseApiDto>;
  public findOnlineSchools(
    acceptLanguage: string,
    acceptCompany: 'ALP' | 'KIL' | 'ESL' | 'KSG',
    residenceCountryCode?: string,
    observe?: 'response',
    reportProgress?: boolean,
    options?: { httpHeaderAccept?: 'application/json' },
  ): Observable<HttpResponse<OnlineSchoolResponseApiDto>>;
  public findOnlineSchools(
    acceptLanguage: string,
    acceptCompany: 'ALP' | 'KIL' | 'ESL' | 'KSG',
    residenceCountryCode?: string,
    observe?: 'events',
    reportProgress?: boolean,
    options?: { httpHeaderAccept?: 'application/json' },
  ): Observable<HttpEvent<OnlineSchoolResponseApiDto>>;
  public findOnlineSchools(
    acceptLanguage: string,
    acceptCompany: 'ALP' | 'KIL' | 'ESL' | 'KSG',
    residenceCountryCode?: string,
    observe: any = 'body',
    reportProgress: boolean = false,
    options?: { httpHeaderAccept?: 'application/json' },
  ): Observable<any> {
    if (acceptLanguage === null || acceptLanguage === undefined) {
      throw new Error('Required parameter acceptLanguage was null or undefined when calling findOnlineSchools.');
    }
    if (acceptCompany === null || acceptCompany === undefined) {
      throw new Error('Required parameter acceptCompany was null or undefined when calling findOnlineSchools.');
    }

    let queryParameters = new HttpParams({ encoder: this.encoder });
    if (residenceCountryCode !== undefined && residenceCountryCode !== null) {
      queryParameters = this.addToHttpParams(queryParameters, <any>residenceCountryCode, 'residenceCountryCode');
    }

    let headers = this.defaultHeaders;
    if (acceptLanguage !== undefined && acceptLanguage !== null) {
      headers = headers.set('Accept-Language', String(acceptLanguage));
    }
    if (acceptCompany !== undefined && acceptCompany !== null) {
      headers = headers.set('Accept-Company', String(acceptCompany));
    }

    let httpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
    if (httpHeaderAcceptSelected === undefined) {
      // to determine the Accept header
      const httpHeaderAccepts: string[] = ['application/json'];
      httpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
    }
    if (httpHeaderAcceptSelected !== undefined) {
      headers = headers.set('Accept', httpHeaderAcceptSelected);
    }

    let responseType: 'text' | 'json' = 'json';
    if (httpHeaderAcceptSelected && httpHeaderAcceptSelected.startsWith('text')) {
      responseType = 'text';
    }

    return this.httpClient.get<OnlineSchoolResponseApiDto>(`${this.configuration.basePath}/products/v4/schools/online`, {
      params: queryParameters,
      responseType: <any>responseType,
      withCredentials: this.configuration.withCredentials,
      headers: headers,
      observe: observe,
      reportProgress: reportProgress,
    });
  }

  /**
   * @param schoolCode
   * @param onlineClassProductCode
   * @param acceptLanguage Language code to inform the display language for the results
   * @param acceptCompany Company code for the source of data
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
  public findOnlineSchoolsClassTimes(
    schoolCode: string,
    onlineClassProductCode: string,
    acceptLanguage: string,
    acceptCompany: 'ALP' | 'KIL' | 'ESL' | 'KSG',
    observe?: 'body',
    reportProgress?: boolean,
    options?: { httpHeaderAccept?: 'application/json' },
  ): Observable<Array<OnlineClassComboApiDto>>;
  public findOnlineSchoolsClassTimes(
    schoolCode: string,
    onlineClassProductCode: string,
    acceptLanguage: string,
    acceptCompany: 'ALP' | 'KIL' | 'ESL' | 'KSG',
    observe?: 'response',
    reportProgress?: boolean,
    options?: { httpHeaderAccept?: 'application/json' },
  ): Observable<HttpResponse<Array<OnlineClassComboApiDto>>>;
  public findOnlineSchoolsClassTimes(
    schoolCode: string,
    onlineClassProductCode: string,
    acceptLanguage: string,
    acceptCompany: 'ALP' | 'KIL' | 'ESL' | 'KSG',
    observe?: 'events',
    reportProgress?: boolean,
    options?: { httpHeaderAccept?: 'application/json' },
  ): Observable<HttpEvent<Array<OnlineClassComboApiDto>>>;
  public findOnlineSchoolsClassTimes(
    schoolCode: string,
    onlineClassProductCode: string,
    acceptLanguage: string,
    acceptCompany: 'ALP' | 'KIL' | 'ESL' | 'KSG',
    observe: any = 'body',
    reportProgress: boolean = false,
    options?: { httpHeaderAccept?: 'application/json' },
  ): Observable<any> {
    if (schoolCode === null || schoolCode === undefined) {
      throw new Error('Required parameter schoolCode was null or undefined when calling findOnlineSchoolsClassTimes.');
    }
    if (onlineClassProductCode === null || onlineClassProductCode === undefined) {
      throw new Error('Required parameter onlineClassProductCode was null or undefined when calling findOnlineSchoolsClassTimes.');
    }
    if (acceptLanguage === null || acceptLanguage === undefined) {
      throw new Error('Required parameter acceptLanguage was null or undefined when calling findOnlineSchoolsClassTimes.');
    }
    if (acceptCompany === null || acceptCompany === undefined) {
      throw new Error('Required parameter acceptCompany was null or undefined when calling findOnlineSchoolsClassTimes.');
    }

    let queryParameters = new HttpParams({ encoder: this.encoder });
    if (onlineClassProductCode !== undefined && onlineClassProductCode !== null) {
      queryParameters = this.addToHttpParams(queryParameters, <any>onlineClassProductCode, 'onlineClassProductCode');
    }

    let headers = this.defaultHeaders;
    if (acceptLanguage !== undefined && acceptLanguage !== null) {
      headers = headers.set('Accept-Language', String(acceptLanguage));
    }
    if (acceptCompany !== undefined && acceptCompany !== null) {
      headers = headers.set('Accept-Company', String(acceptCompany));
    }

    let httpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
    if (httpHeaderAcceptSelected === undefined) {
      // to determine the Accept header
      const httpHeaderAccepts: string[] = ['application/json'];
      httpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
    }
    if (httpHeaderAcceptSelected !== undefined) {
      headers = headers.set('Accept', httpHeaderAcceptSelected);
    }

    let responseType: 'text' | 'json' = 'json';
    if (httpHeaderAcceptSelected && httpHeaderAcceptSelected.startsWith('text')) {
      responseType = 'text';
    }

    return this.httpClient.get<Array<OnlineClassComboApiDto>>(
      `${this.configuration.basePath}/products/v4/schools/online/${encodeURIComponent(String(schoolCode))}/class-times`,
      {
        params: queryParameters,
        responseType: <any>responseType,
        withCredentials: this.configuration.withCredentials,
        headers: headers,
        observe: observe,
        reportProgress: reportProgress,
      },
    );
  }

  /**
   * @param schoolCode
   * @param acceptLanguage Language code to inform the display language for the results
   * @param acceptCompany Company code for the source of data
   * @param quoteDate
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
  public findPredefinedOnlineSelections(
    schoolCode: string,
    acceptLanguage: string,
    acceptCompany: 'ALP' | 'KIL' | 'ESL' | 'KSG',
    quoteDate?: string,
    observe?: 'body',
    reportProgress?: boolean,
    options?: { httpHeaderAccept?: 'application/json' },
  ): Observable<Array<PredefinedOnlineSelectionApiDto>>;
  public findPredefinedOnlineSelections(
    schoolCode: string,
    acceptLanguage: string,
    acceptCompany: 'ALP' | 'KIL' | 'ESL' | 'KSG',
    quoteDate?: string,
    observe?: 'response',
    reportProgress?: boolean,
    options?: { httpHeaderAccept?: 'application/json' },
  ): Observable<HttpResponse<Array<PredefinedOnlineSelectionApiDto>>>;
  public findPredefinedOnlineSelections(
    schoolCode: string,
    acceptLanguage: string,
    acceptCompany: 'ALP' | 'KIL' | 'ESL' | 'KSG',
    quoteDate?: string,
    observe?: 'events',
    reportProgress?: boolean,
    options?: { httpHeaderAccept?: 'application/json' },
  ): Observable<HttpEvent<Array<PredefinedOnlineSelectionApiDto>>>;
  public findPredefinedOnlineSelections(
    schoolCode: string,
    acceptLanguage: string,
    acceptCompany: 'ALP' | 'KIL' | 'ESL' | 'KSG',
    quoteDate?: string,
    observe: any = 'body',
    reportProgress: boolean = false,
    options?: { httpHeaderAccept?: 'application/json' },
  ): Observable<any> {
    if (schoolCode === null || schoolCode === undefined) {
      throw new Error('Required parameter schoolCode was null or undefined when calling findPredefinedOnlineSelections.');
    }
    if (acceptLanguage === null || acceptLanguage === undefined) {
      throw new Error('Required parameter acceptLanguage was null or undefined when calling findPredefinedOnlineSelections.');
    }
    if (acceptCompany === null || acceptCompany === undefined) {
      throw new Error('Required parameter acceptCompany was null or undefined when calling findPredefinedOnlineSelections.');
    }

    let queryParameters = new HttpParams({ encoder: this.encoder });
    if (quoteDate !== undefined && quoteDate !== null) {
      queryParameters = this.addToHttpParams(queryParameters, <any>quoteDate, 'quoteDate');
    }

    let headers = this.defaultHeaders;
    if (acceptLanguage !== undefined && acceptLanguage !== null) {
      headers = headers.set('Accept-Language', String(acceptLanguage));
    }
    if (acceptCompany !== undefined && acceptCompany !== null) {
      headers = headers.set('Accept-Company', String(acceptCompany));
    }

    let httpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
    if (httpHeaderAcceptSelected === undefined) {
      // to determine the Accept header
      const httpHeaderAccepts: string[] = ['application/json'];
      httpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
    }
    if (httpHeaderAcceptSelected !== undefined) {
      headers = headers.set('Accept', httpHeaderAcceptSelected);
    }

    let responseType: 'text' | 'json' = 'json';
    if (httpHeaderAcceptSelected && httpHeaderAcceptSelected.startsWith('text')) {
      responseType = 'text';
    }

    return this.httpClient.get<Array<PredefinedOnlineSelectionApiDto>>(
      `${this.configuration.basePath}/products/v4/schools/online/${encodeURIComponent(String(schoolCode))}/predefined-selections`,
      {
        params: queryParameters,
        responseType: <any>responseType,
        withCredentials: this.configuration.withCredentials,
        headers: headers,
        observe: observe,
        reportProgress: reportProgress,
      },
    );
  }

  /**
   * @param schoolCode
   * @param acceptLanguage Language code to inform the display language for the results
   * @param acceptCompany Company code for the source of data
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
  public findPricingPackages(
    schoolCode: string,
    acceptLanguage: string,
    acceptCompany: 'ALP' | 'KIL' | 'ESL' | 'KSG',
    observe?: 'body',
    reportProgress?: boolean,
    options?: { httpHeaderAccept?: 'application/json' },
  ): Observable<PricingPackageApiDto>;
  public findPricingPackages(
    schoolCode: string,
    acceptLanguage: string,
    acceptCompany: 'ALP' | 'KIL' | 'ESL' | 'KSG',
    observe?: 'response',
    reportProgress?: boolean,
    options?: { httpHeaderAccept?: 'application/json' },
  ): Observable<HttpResponse<PricingPackageApiDto>>;
  public findPricingPackages(
    schoolCode: string,
    acceptLanguage: string,
    acceptCompany: 'ALP' | 'KIL' | 'ESL' | 'KSG',
    observe?: 'events',
    reportProgress?: boolean,
    options?: { httpHeaderAccept?: 'application/json' },
  ): Observable<HttpEvent<PricingPackageApiDto>>;
  public findPricingPackages(
    schoolCode: string,
    acceptLanguage: string,
    acceptCompany: 'ALP' | 'KIL' | 'ESL' | 'KSG',
    observe: any = 'body',
    reportProgress: boolean = false,
    options?: { httpHeaderAccept?: 'application/json' },
  ): Observable<any> {
    if (schoolCode === null || schoolCode === undefined) {
      throw new Error('Required parameter schoolCode was null or undefined when calling findPricingPackages.');
    }
    if (acceptLanguage === null || acceptLanguage === undefined) {
      throw new Error('Required parameter acceptLanguage was null or undefined when calling findPricingPackages.');
    }
    if (acceptCompany === null || acceptCompany === undefined) {
      throw new Error('Required parameter acceptCompany was null or undefined when calling findPricingPackages.');
    }

    let headers = this.defaultHeaders;
    if (acceptLanguage !== undefined && acceptLanguage !== null) {
      headers = headers.set('Accept-Language', String(acceptLanguage));
    }
    if (acceptCompany !== undefined && acceptCompany !== null) {
      headers = headers.set('Accept-Company', String(acceptCompany));
    }

    let httpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
    if (httpHeaderAcceptSelected === undefined) {
      // to determine the Accept header
      const httpHeaderAccepts: string[] = ['application/json'];
      httpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
    }
    if (httpHeaderAcceptSelected !== undefined) {
      headers = headers.set('Accept', httpHeaderAcceptSelected);
    }

    let responseType: 'text' | 'json' = 'json';
    if (httpHeaderAcceptSelected && httpHeaderAcceptSelected.startsWith('text')) {
      responseType = 'text';
    }

    return this.httpClient.get<PricingPackageApiDto>(
      `${this.configuration.basePath}/products/v4/schools/${encodeURIComponent(String(schoolCode))}/pricing-packages`,
      {
        responseType: <any>responseType,
        withCredentials: this.configuration.withCredentials,
        headers: headers,
        observe: observe,
        reportProgress: reportProgress,
      },
    );
  }

  /**
   * @param schoolCode
   * @param acceptLanguage Language code to inform the display language for the results
   * @param acceptCompany Company code for the source of data
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
  public findSchoolByCode(
    schoolCode: string,
    acceptLanguage: string,
    acceptCompany: 'ALP' | 'KIL' | 'ESL' | 'KSG',
    observe?: 'body',
    reportProgress?: boolean,
    options?: { httpHeaderAccept?: 'application/json' },
  ): Observable<SchoolApiDto>;
  public findSchoolByCode(
    schoolCode: string,
    acceptLanguage: string,
    acceptCompany: 'ALP' | 'KIL' | 'ESL' | 'KSG',
    observe?: 'response',
    reportProgress?: boolean,
    options?: { httpHeaderAccept?: 'application/json' },
  ): Observable<HttpResponse<SchoolApiDto>>;
  public findSchoolByCode(
    schoolCode: string,
    acceptLanguage: string,
    acceptCompany: 'ALP' | 'KIL' | 'ESL' | 'KSG',
    observe?: 'events',
    reportProgress?: boolean,
    options?: { httpHeaderAccept?: 'application/json' },
  ): Observable<HttpEvent<SchoolApiDto>>;
  public findSchoolByCode(
    schoolCode: string,
    acceptLanguage: string,
    acceptCompany: 'ALP' | 'KIL' | 'ESL' | 'KSG',
    observe: any = 'body',
    reportProgress: boolean = false,
    options?: { httpHeaderAccept?: 'application/json' },
  ): Observable<any> {
    if (schoolCode === null || schoolCode === undefined) {
      throw new Error('Required parameter schoolCode was null or undefined when calling findSchoolByCode.');
    }
    if (acceptLanguage === null || acceptLanguage === undefined) {
      throw new Error('Required parameter acceptLanguage was null or undefined when calling findSchoolByCode.');
    }
    if (acceptCompany === null || acceptCompany === undefined) {
      throw new Error('Required parameter acceptCompany was null or undefined when calling findSchoolByCode.');
    }

    let headers = this.defaultHeaders;
    if (acceptLanguage !== undefined && acceptLanguage !== null) {
      headers = headers.set('Accept-Language', String(acceptLanguage));
    }
    if (acceptCompany !== undefined && acceptCompany !== null) {
      headers = headers.set('Accept-Company', String(acceptCompany));
    }

    let httpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
    if (httpHeaderAcceptSelected === undefined) {
      // to determine the Accept header
      const httpHeaderAccepts: string[] = ['application/json'];
      httpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
    }
    if (httpHeaderAcceptSelected !== undefined) {
      headers = headers.set('Accept', httpHeaderAcceptSelected);
    }

    let responseType: 'text' | 'json' = 'json';
    if (httpHeaderAcceptSelected && httpHeaderAcceptSelected.startsWith('text')) {
      responseType = 'text';
    }

    return this.httpClient.get<SchoolApiDto>(`${this.configuration.basePath}/products/v3/schools/${encodeURIComponent(String(schoolCode))}`, {
      responseType: <any>responseType,
      withCredentials: this.configuration.withCredentials,
      headers: headers,
      observe: observe,
      reportProgress: reportProgress,
    });
  }

  /**
   * @param schoolId
   * @param acceptLanguage Language code to inform the display language for the results
   * @param acceptCompany Company code for the source of data
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
  public findSchoolById(
    schoolId: number,
    acceptLanguage: string,
    acceptCompany: 'ALP' | 'KIL' | 'ESL' | 'KSG',
    observe?: 'body',
    reportProgress?: boolean,
    options?: { httpHeaderAccept?: 'application/json' },
  ): Observable<SchoolApiDto>;
  public findSchoolById(
    schoolId: number,
    acceptLanguage: string,
    acceptCompany: 'ALP' | 'KIL' | 'ESL' | 'KSG',
    observe?: 'response',
    reportProgress?: boolean,
    options?: { httpHeaderAccept?: 'application/json' },
  ): Observable<HttpResponse<SchoolApiDto>>;
  public findSchoolById(
    schoolId: number,
    acceptLanguage: string,
    acceptCompany: 'ALP' | 'KIL' | 'ESL' | 'KSG',
    observe?: 'events',
    reportProgress?: boolean,
    options?: { httpHeaderAccept?: 'application/json' },
  ): Observable<HttpEvent<SchoolApiDto>>;
  public findSchoolById(
    schoolId: number,
    acceptLanguage: string,
    acceptCompany: 'ALP' | 'KIL' | 'ESL' | 'KSG',
    observe: any = 'body',
    reportProgress: boolean = false,
    options?: { httpHeaderAccept?: 'application/json' },
  ): Observable<any> {
    if (schoolId === null || schoolId === undefined) {
      throw new Error('Required parameter schoolId was null or undefined when calling findSchoolById.');
    }
    if (acceptLanguage === null || acceptLanguage === undefined) {
      throw new Error('Required parameter acceptLanguage was null or undefined when calling findSchoolById.');
    }
    if (acceptCompany === null || acceptCompany === undefined) {
      throw new Error('Required parameter acceptCompany was null or undefined when calling findSchoolById.');
    }

    let headers = this.defaultHeaders;
    if (acceptLanguage !== undefined && acceptLanguage !== null) {
      headers = headers.set('Accept-Language', String(acceptLanguage));
    }
    if (acceptCompany !== undefined && acceptCompany !== null) {
      headers = headers.set('Accept-Company', String(acceptCompany));
    }

    let httpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
    if (httpHeaderAcceptSelected === undefined) {
      // to determine the Accept header
      const httpHeaderAccepts: string[] = ['application/json'];
      httpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
    }
    if (httpHeaderAcceptSelected !== undefined) {
      headers = headers.set('Accept', httpHeaderAcceptSelected);
    }

    let responseType: 'text' | 'json' = 'json';
    if (httpHeaderAcceptSelected && httpHeaderAcceptSelected.startsWith('text')) {
      responseType = 'text';
    }

    return this.httpClient.get<SchoolApiDto>(`${this.configuration.basePath}/products/v2/schools/${encodeURIComponent(String(schoolId))}`, {
      responseType: <any>responseType,
      withCredentials: this.configuration.withCredentials,
      headers: headers,
      observe: observe,
      reportProgress: reportProgress,
    });
  }

  /**
   * @param schoolCode School code
   * @param acceptLanguage Language code to inform the display language for the results
   * @param acceptCompany Company code for the source of data
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
  public findSchoolCourses(
    schoolCode: string,
    acceptLanguage: string,
    acceptCompany: 'ALP' | 'KIL' | 'ESL' | 'KSG',
    observe?: 'body',
    reportProgress?: boolean,
    options?: { httpHeaderAccept?: 'application/json' },
  ): Observable<Array<CourseApiDto>>;
  public findSchoolCourses(
    schoolCode: string,
    acceptLanguage: string,
    acceptCompany: 'ALP' | 'KIL' | 'ESL' | 'KSG',
    observe?: 'response',
    reportProgress?: boolean,
    options?: { httpHeaderAccept?: 'application/json' },
  ): Observable<HttpResponse<Array<CourseApiDto>>>;
  public findSchoolCourses(
    schoolCode: string,
    acceptLanguage: string,
    acceptCompany: 'ALP' | 'KIL' | 'ESL' | 'KSG',
    observe?: 'events',
    reportProgress?: boolean,
    options?: { httpHeaderAccept?: 'application/json' },
  ): Observable<HttpEvent<Array<CourseApiDto>>>;
  public findSchoolCourses(
    schoolCode: string,
    acceptLanguage: string,
    acceptCompany: 'ALP' | 'KIL' | 'ESL' | 'KSG',
    observe: any = 'body',
    reportProgress: boolean = false,
    options?: { httpHeaderAccept?: 'application/json' },
  ): Observable<any> {
    if (schoolCode === null || schoolCode === undefined) {
      throw new Error('Required parameter schoolCode was null or undefined when calling findSchoolCourses.');
    }
    if (acceptLanguage === null || acceptLanguage === undefined) {
      throw new Error('Required parameter acceptLanguage was null or undefined when calling findSchoolCourses.');
    }
    if (acceptCompany === null || acceptCompany === undefined) {
      throw new Error('Required parameter acceptCompany was null or undefined when calling findSchoolCourses.');
    }

    let headers = this.defaultHeaders;
    if (acceptLanguage !== undefined && acceptLanguage !== null) {
      headers = headers.set('Accept-Language', String(acceptLanguage));
    }
    if (acceptCompany !== undefined && acceptCompany !== null) {
      headers = headers.set('Accept-Company', String(acceptCompany));
    }

    let httpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
    if (httpHeaderAcceptSelected === undefined) {
      // to determine the Accept header
      const httpHeaderAccepts: string[] = ['application/json'];
      httpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
    }
    if (httpHeaderAcceptSelected !== undefined) {
      headers = headers.set('Accept', httpHeaderAcceptSelected);
    }

    let responseType: 'text' | 'json' = 'json';
    if (httpHeaderAcceptSelected && httpHeaderAcceptSelected.startsWith('text')) {
      responseType = 'text';
    }

    return this.httpClient.get<Array<CourseApiDto>>(`${this.configuration.basePath}/products/v4/schools/${encodeURIComponent(String(schoolCode))}/courses`, {
      responseType: <any>responseType,
      withCredentials: this.configuration.withCredentials,
      headers: headers,
      observe: observe,
      reportProgress: reportProgress,
    });
  }

  /**
   * @param quoteDate Date when the quote is calculated
   * @param residenceCountryCode Country of residence of the student. ISO 3166-1 alpha-2 (2-letter codes).
   * @param units Quantity used for price calculation: Number of weeks, extra nights, one-to-one lessons, etc
   * @param schoolCode The code of the school.
   * @param acceptLanguage Language code to inform the display language for the results
   * @param acceptCompany Company code for the source of data
   * @param productPackage
   * @param dateRangeStartDate
   * @param dateRangeEndDate
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
  public findSchoolQuotedCourses(
    quoteDate: string,
    residenceCountryCode: string,
    units: number,
    schoolCode: string,
    acceptLanguage: string,
    acceptCompany: 'ALP' | 'KIL' | 'ESL' | 'KSG',
    productPackage?: ProductPackageApiDto,
    dateRangeStartDate?: string,
    dateRangeEndDate?: string,
    observe?: 'body',
    reportProgress?: boolean,
    options?: { httpHeaderAccept?: 'application/json' },
  ): Observable<Array<QuotesCourseApiDto>>;
  public findSchoolQuotedCourses(
    quoteDate: string,
    residenceCountryCode: string,
    units: number,
    schoolCode: string,
    acceptLanguage: string,
    acceptCompany: 'ALP' | 'KIL' | 'ESL' | 'KSG',
    productPackage?: ProductPackageApiDto,
    dateRangeStartDate?: string,
    dateRangeEndDate?: string,
    observe?: 'response',
    reportProgress?: boolean,
    options?: { httpHeaderAccept?: 'application/json' },
  ): Observable<HttpResponse<Array<QuotesCourseApiDto>>>;
  public findSchoolQuotedCourses(
    quoteDate: string,
    residenceCountryCode: string,
    units: number,
    schoolCode: string,
    acceptLanguage: string,
    acceptCompany: 'ALP' | 'KIL' | 'ESL' | 'KSG',
    productPackage?: ProductPackageApiDto,
    dateRangeStartDate?: string,
    dateRangeEndDate?: string,
    observe?: 'events',
    reportProgress?: boolean,
    options?: { httpHeaderAccept?: 'application/json' },
  ): Observable<HttpEvent<Array<QuotesCourseApiDto>>>;
  public findSchoolQuotedCourses(
    quoteDate: string,
    residenceCountryCode: string,
    units: number,
    schoolCode: string,
    acceptLanguage: string,
    acceptCompany: 'ALP' | 'KIL' | 'ESL' | 'KSG',
    productPackage?: ProductPackageApiDto,
    dateRangeStartDate?: string,
    dateRangeEndDate?: string,
    observe: any = 'body',
    reportProgress: boolean = false,
    options?: { httpHeaderAccept?: 'application/json' },
  ): Observable<any> {
    if (quoteDate === null || quoteDate === undefined) {
      throw new Error('Required parameter quoteDate was null or undefined when calling findSchoolQuotedCourses.');
    }
    if (residenceCountryCode === null || residenceCountryCode === undefined) {
      throw new Error('Required parameter residenceCountryCode was null or undefined when calling findSchoolQuotedCourses.');
    }
    if (units === null || units === undefined) {
      throw new Error('Required parameter units was null or undefined when calling findSchoolQuotedCourses.');
    }
    if (schoolCode === null || schoolCode === undefined) {
      throw new Error('Required parameter schoolCode was null or undefined when calling findSchoolQuotedCourses.');
    }
    if (acceptLanguage === null || acceptLanguage === undefined) {
      throw new Error('Required parameter acceptLanguage was null or undefined when calling findSchoolQuotedCourses.');
    }
    if (acceptCompany === null || acceptCompany === undefined) {
      throw new Error('Required parameter acceptCompany was null or undefined when calling findSchoolQuotedCourses.');
    }

    let queryParameters = new HttpParams({ encoder: this.encoder });
    if (productPackage !== undefined && productPackage !== null) {
      queryParameters = this.addToHttpParams(queryParameters, <any>productPackage, 'productPackage');
    }
    if (quoteDate !== undefined && quoteDate !== null) {
      queryParameters = this.addToHttpParams(queryParameters, <any>quoteDate, 'quoteDate');
    }
    if (residenceCountryCode !== undefined && residenceCountryCode !== null) {
      queryParameters = this.addToHttpParams(queryParameters, <any>residenceCountryCode, 'residenceCountryCode');
    }
    if (dateRangeStartDate !== undefined && dateRangeStartDate !== null) {
      queryParameters = this.addToHttpParams(queryParameters, <any>dateRangeStartDate, 'dateRange.startDate');
    }
    if (dateRangeEndDate !== undefined && dateRangeEndDate !== null) {
      queryParameters = this.addToHttpParams(queryParameters, <any>dateRangeEndDate, 'dateRange.endDate');
    }
    if (units !== undefined && units !== null) {
      queryParameters = this.addToHttpParams(queryParameters, <any>units, 'units');
    }

    let headers = this.defaultHeaders;
    if (acceptLanguage !== undefined && acceptLanguage !== null) {
      headers = headers.set('Accept-Language', String(acceptLanguage));
    }
    if (acceptCompany !== undefined && acceptCompany !== null) {
      headers = headers.set('Accept-Company', String(acceptCompany));
    }

    let httpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
    if (httpHeaderAcceptSelected === undefined) {
      // to determine the Accept header
      const httpHeaderAccepts: string[] = ['application/json'];
      httpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
    }
    if (httpHeaderAcceptSelected !== undefined) {
      headers = headers.set('Accept', httpHeaderAcceptSelected);
    }

    let responseType: 'text' | 'json' = 'json';
    if (httpHeaderAcceptSelected && httpHeaderAcceptSelected.startsWith('text')) {
      responseType = 'text';
    }

    return this.httpClient.get<Array<QuotesCourseApiDto>>(
      `${this.configuration.basePath}/products/v4/schools/${encodeURIComponent(String(schoolCode))}/quoted-courses`,
      {
        params: queryParameters,
        responseType: <any>responseType,
        withCredentials: this.configuration.withCredentials,
        headers: headers,
        observe: observe,
        reportProgress: reportProgress,
      },
    );
  }

  /**
   * @param cityCode
   * @param acceptLanguage Language code to inform the display language for the results
   * @param acceptCompany Company code for the source of data
   * @param languageCode
   * @param countryCode
   * @param purposeCode
   * @param courseTypeCode
   * @param childCourseTypeCode
   * @param quoteAvailable
   * @param courseCodes
   * @param schoolCodes
   * @param fromPrice
   * @param toPrice
   * @param fromRatingScore
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
  public findSchoolSummariesByCity(
    cityCode: string,
    acceptLanguage: string,
    acceptCompany: 'ALP' | 'KIL' | 'ESL' | 'KSG',
    languageCode?: string,
    countryCode?: string,
    purposeCode?: string,
    courseTypeCode?: string,
    childCourseTypeCode?: string,
    quoteAvailable?: boolean,
    courseCodes?: Array<string>,
    schoolCodes?: Array<string>,
    fromPrice?: number,
    toPrice?: number,
    fromRatingScore?: number,
    observe?: 'body',
    reportProgress?: boolean,
    options?: { httpHeaderAccept?: 'application/json' },
  ): Observable<Array<SchoolListingSummaryApiDto>>;
  public findSchoolSummariesByCity(
    cityCode: string,
    acceptLanguage: string,
    acceptCompany: 'ALP' | 'KIL' | 'ESL' | 'KSG',
    languageCode?: string,
    countryCode?: string,
    purposeCode?: string,
    courseTypeCode?: string,
    childCourseTypeCode?: string,
    quoteAvailable?: boolean,
    courseCodes?: Array<string>,
    schoolCodes?: Array<string>,
    fromPrice?: number,
    toPrice?: number,
    fromRatingScore?: number,
    observe?: 'response',
    reportProgress?: boolean,
    options?: { httpHeaderAccept?: 'application/json' },
  ): Observable<HttpResponse<Array<SchoolListingSummaryApiDto>>>;
  public findSchoolSummariesByCity(
    cityCode: string,
    acceptLanguage: string,
    acceptCompany: 'ALP' | 'KIL' | 'ESL' | 'KSG',
    languageCode?: string,
    countryCode?: string,
    purposeCode?: string,
    courseTypeCode?: string,
    childCourseTypeCode?: string,
    quoteAvailable?: boolean,
    courseCodes?: Array<string>,
    schoolCodes?: Array<string>,
    fromPrice?: number,
    toPrice?: number,
    fromRatingScore?: number,
    observe?: 'events',
    reportProgress?: boolean,
    options?: { httpHeaderAccept?: 'application/json' },
  ): Observable<HttpEvent<Array<SchoolListingSummaryApiDto>>>;
  public findSchoolSummariesByCity(
    cityCode: string,
    acceptLanguage: string,
    acceptCompany: 'ALP' | 'KIL' | 'ESL' | 'KSG',
    languageCode?: string,
    countryCode?: string,
    purposeCode?: string,
    courseTypeCode?: string,
    childCourseTypeCode?: string,
    quoteAvailable?: boolean,
    courseCodes?: Array<string>,
    schoolCodes?: Array<string>,
    fromPrice?: number,
    toPrice?: number,
    fromRatingScore?: number,
    observe: any = 'body',
    reportProgress: boolean = false,
    options?: { httpHeaderAccept?: 'application/json' },
  ): Observable<any> {
    if (cityCode === null || cityCode === undefined) {
      throw new Error('Required parameter cityCode was null or undefined when calling findSchoolSummariesByCity.');
    }
    if (acceptLanguage === null || acceptLanguage === undefined) {
      throw new Error('Required parameter acceptLanguage was null or undefined when calling findSchoolSummariesByCity.');
    }
    if (acceptCompany === null || acceptCompany === undefined) {
      throw new Error('Required parameter acceptCompany was null or undefined when calling findSchoolSummariesByCity.');
    }

    let queryParameters = new HttpParams({ encoder: this.encoder });
    if (languageCode !== undefined && languageCode !== null) {
      queryParameters = this.addToHttpParams(queryParameters, <any>languageCode, 'languageCode');
    }
    if (countryCode !== undefined && countryCode !== null) {
      queryParameters = this.addToHttpParams(queryParameters, <any>countryCode, 'countryCode');
    }
    if (purposeCode !== undefined && purposeCode !== null) {
      queryParameters = this.addToHttpParams(queryParameters, <any>purposeCode, 'purposeCode');
    }
    if (courseTypeCode !== undefined && courseTypeCode !== null) {
      queryParameters = this.addToHttpParams(queryParameters, <any>courseTypeCode, 'courseTypeCode');
    }
    if (childCourseTypeCode !== undefined && childCourseTypeCode !== null) {
      queryParameters = this.addToHttpParams(queryParameters, <any>childCourseTypeCode, 'childCourseTypeCode');
    }
    if (quoteAvailable !== undefined && quoteAvailable !== null) {
      queryParameters = this.addToHttpParams(queryParameters, <any>quoteAvailable, 'quoteAvailable');
    }
    if (courseCodes) {
      courseCodes.forEach((element) => {
        queryParameters = this.addToHttpParams(queryParameters, <any>element, 'courseCodes');
      });
    }
    if (schoolCodes) {
      schoolCodes.forEach((element) => {
        queryParameters = this.addToHttpParams(queryParameters, <any>element, 'schoolCodes');
      });
    }
    if (fromPrice !== undefined && fromPrice !== null) {
      queryParameters = this.addToHttpParams(queryParameters, <any>fromPrice, 'fromPrice');
    }
    if (toPrice !== undefined && toPrice !== null) {
      queryParameters = this.addToHttpParams(queryParameters, <any>toPrice, 'toPrice');
    }
    if (fromRatingScore !== undefined && fromRatingScore !== null) {
      queryParameters = this.addToHttpParams(queryParameters, <any>fromRatingScore, 'fromRatingScore');
    }

    let headers = this.defaultHeaders;
    if (acceptLanguage !== undefined && acceptLanguage !== null) {
      headers = headers.set('Accept-Language', String(acceptLanguage));
    }
    if (acceptCompany !== undefined && acceptCompany !== null) {
      headers = headers.set('Accept-Company', String(acceptCompany));
    }

    let httpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
    if (httpHeaderAcceptSelected === undefined) {
      // to determine the Accept header
      const httpHeaderAccepts: string[] = ['application/json'];
      httpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
    }
    if (httpHeaderAcceptSelected !== undefined) {
      headers = headers.set('Accept', httpHeaderAcceptSelected);
    }

    let responseType: 'text' | 'json' = 'json';
    if (httpHeaderAcceptSelected && httpHeaderAcceptSelected.startsWith('text')) {
      responseType = 'text';
    }

    return this.httpClient.get<Array<SchoolListingSummaryApiDto>>(
      `${this.configuration.basePath}/products/v3/city/${encodeURIComponent(String(cityCode))}/schools`,
      {
        params: queryParameters,
        responseType: <any>responseType,
        withCredentials: this.configuration.withCredentials,
        headers: headers,
        observe: observe,
        reportProgress: reportProgress,
      },
    );
  }

  /**
   * @param schoolCode
   * @param acceptLanguage Language code to inform the display language for the results
   * @param acceptCompany Company code for the source of data
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
  public findSchoolSummary(
    schoolCode: string,
    acceptLanguage: string,
    acceptCompany: 'ALP' | 'KIL' | 'ESL' | 'KSG',
    observe?: 'body',
    reportProgress?: boolean,
    options?: { httpHeaderAccept?: 'application/json' },
  ): Observable<SchoolSummaryApiDto>;
  public findSchoolSummary(
    schoolCode: string,
    acceptLanguage: string,
    acceptCompany: 'ALP' | 'KIL' | 'ESL' | 'KSG',
    observe?: 'response',
    reportProgress?: boolean,
    options?: { httpHeaderAccept?: 'application/json' },
  ): Observable<HttpResponse<SchoolSummaryApiDto>>;
  public findSchoolSummary(
    schoolCode: string,
    acceptLanguage: string,
    acceptCompany: 'ALP' | 'KIL' | 'ESL' | 'KSG',
    observe?: 'events',
    reportProgress?: boolean,
    options?: { httpHeaderAccept?: 'application/json' },
  ): Observable<HttpEvent<SchoolSummaryApiDto>>;
  public findSchoolSummary(
    schoolCode: string,
    acceptLanguage: string,
    acceptCompany: 'ALP' | 'KIL' | 'ESL' | 'KSG',
    observe: any = 'body',
    reportProgress: boolean = false,
    options?: { httpHeaderAccept?: 'application/json' },
  ): Observable<any> {
    if (schoolCode === null || schoolCode === undefined) {
      throw new Error('Required parameter schoolCode was null or undefined when calling findSchoolSummary.');
    }
    if (acceptLanguage === null || acceptLanguage === undefined) {
      throw new Error('Required parameter acceptLanguage was null or undefined when calling findSchoolSummary.');
    }
    if (acceptCompany === null || acceptCompany === undefined) {
      throw new Error('Required parameter acceptCompany was null or undefined when calling findSchoolSummary.');
    }

    let headers = this.defaultHeaders;
    if (acceptLanguage !== undefined && acceptLanguage !== null) {
      headers = headers.set('Accept-Language', String(acceptLanguage));
    }
    if (acceptCompany !== undefined && acceptCompany !== null) {
      headers = headers.set('Accept-Company', String(acceptCompany));
    }

    let httpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
    if (httpHeaderAcceptSelected === undefined) {
      // to determine the Accept header
      const httpHeaderAccepts: string[] = ['application/json'];
      httpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
    }
    if (httpHeaderAcceptSelected !== undefined) {
      headers = headers.set('Accept', httpHeaderAcceptSelected);
    }

    let responseType: 'text' | 'json' = 'json';
    if (httpHeaderAcceptSelected && httpHeaderAcceptSelected.startsWith('text')) {
      responseType = 'text';
    }

    return this.httpClient.get<SchoolSummaryApiDto>(`${this.configuration.basePath}/products/v3/schools/${encodeURIComponent(String(schoolCode))}/summary`, {
      responseType: <any>responseType,
      withCredentials: this.configuration.withCredentials,
      headers: headers,
      observe: observe,
      reportProgress: reportProgress,
    });
  }

  /**
   * @param schoolCode
   * @param acceptLanguage Language code to inform the display language for the results
   * @param acceptCompany Company code for the source of data
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
  public findSchoolV4(
    schoolCode: string,
    acceptLanguage: string,
    acceptCompany: 'ALP' | 'KIL' | 'ESL' | 'KSG',
    observe?: 'body',
    reportProgress?: boolean,
    options?: { httpHeaderAccept?: 'application/json' },
  ): Observable<SchoolV4ApiDto>;
  public findSchoolV4(
    schoolCode: string,
    acceptLanguage: string,
    acceptCompany: 'ALP' | 'KIL' | 'ESL' | 'KSG',
    observe?: 'response',
    reportProgress?: boolean,
    options?: { httpHeaderAccept?: 'application/json' },
  ): Observable<HttpResponse<SchoolV4ApiDto>>;
  public findSchoolV4(
    schoolCode: string,
    acceptLanguage: string,
    acceptCompany: 'ALP' | 'KIL' | 'ESL' | 'KSG',
    observe?: 'events',
    reportProgress?: boolean,
    options?: { httpHeaderAccept?: 'application/json' },
  ): Observable<HttpEvent<SchoolV4ApiDto>>;
  public findSchoolV4(
    schoolCode: string,
    acceptLanguage: string,
    acceptCompany: 'ALP' | 'KIL' | 'ESL' | 'KSG',
    observe: any = 'body',
    reportProgress: boolean = false,
    options?: { httpHeaderAccept?: 'application/json' },
  ): Observable<any> {
    if (schoolCode === null || schoolCode === undefined) {
      throw new Error('Required parameter schoolCode was null or undefined when calling findSchoolV4.');
    }
    if (acceptLanguage === null || acceptLanguage === undefined) {
      throw new Error('Required parameter acceptLanguage was null or undefined when calling findSchoolV4.');
    }
    if (acceptCompany === null || acceptCompany === undefined) {
      throw new Error('Required parameter acceptCompany was null or undefined when calling findSchoolV4.');
    }

    let headers = this.defaultHeaders;
    if (acceptLanguage !== undefined && acceptLanguage !== null) {
      headers = headers.set('Accept-Language', String(acceptLanguage));
    }
    if (acceptCompany !== undefined && acceptCompany !== null) {
      headers = headers.set('Accept-Company', String(acceptCompany));
    }

    let httpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
    if (httpHeaderAcceptSelected === undefined) {
      // to determine the Accept header
      const httpHeaderAccepts: string[] = ['application/json'];
      httpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
    }
    if (httpHeaderAcceptSelected !== undefined) {
      headers = headers.set('Accept', httpHeaderAcceptSelected);
    }

    let responseType: 'text' | 'json' = 'json';
    if (httpHeaderAcceptSelected && httpHeaderAcceptSelected.startsWith('text')) {
      responseType = 'text';
    }

    return this.httpClient.get<SchoolV4ApiDto>(`${this.configuration.basePath}/products/v4/schools/${encodeURIComponent(String(schoolCode))}`, {
      responseType: <any>responseType,
      withCredentials: this.configuration.withCredentials,
      headers: headers,
      observe: observe,
      reportProgress: reportProgress,
    });
  }

  /**
   * @param schoolCode School code
   * @param acceptLanguage Language code to inform the display language for the results
   * @param acceptCompany Company code for the source of data
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
  public findSchoolWithCourses(
    schoolCode: string,
    acceptLanguage: string,
    acceptCompany: 'ALP' | 'KIL' | 'ESL' | 'KSG',
    observe?: 'body',
    reportProgress?: boolean,
    options?: { httpHeaderAccept?: 'application/json' },
  ): Observable<LightSchoolApiDto>;
  public findSchoolWithCourses(
    schoolCode: string,
    acceptLanguage: string,
    acceptCompany: 'ALP' | 'KIL' | 'ESL' | 'KSG',
    observe?: 'response',
    reportProgress?: boolean,
    options?: { httpHeaderAccept?: 'application/json' },
  ): Observable<HttpResponse<LightSchoolApiDto>>;
  public findSchoolWithCourses(
    schoolCode: string,
    acceptLanguage: string,
    acceptCompany: 'ALP' | 'KIL' | 'ESL' | 'KSG',
    observe?: 'events',
    reportProgress?: boolean,
    options?: { httpHeaderAccept?: 'application/json' },
  ): Observable<HttpEvent<LightSchoolApiDto>>;
  public findSchoolWithCourses(
    schoolCode: string,
    acceptLanguage: string,
    acceptCompany: 'ALP' | 'KIL' | 'ESL' | 'KSG',
    observe: any = 'body',
    reportProgress: boolean = false,
    options?: { httpHeaderAccept?: 'application/json' },
  ): Observable<any> {
    if (schoolCode === null || schoolCode === undefined) {
      throw new Error('Required parameter schoolCode was null or undefined when calling findSchoolWithCourses.');
    }
    if (acceptLanguage === null || acceptLanguage === undefined) {
      throw new Error('Required parameter acceptLanguage was null or undefined when calling findSchoolWithCourses.');
    }
    if (acceptCompany === null || acceptCompany === undefined) {
      throw new Error('Required parameter acceptCompany was null or undefined when calling findSchoolWithCourses.');
    }

    let headers = this.defaultHeaders;
    if (acceptLanguage !== undefined && acceptLanguage !== null) {
      headers = headers.set('Accept-Language', String(acceptLanguage));
    }
    if (acceptCompany !== undefined && acceptCompany !== null) {
      headers = headers.set('Accept-Company', String(acceptCompany));
    }

    let httpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
    if (httpHeaderAcceptSelected === undefined) {
      // to determine the Accept header
      const httpHeaderAccepts: string[] = ['application/json'];
      httpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
    }
    if (httpHeaderAcceptSelected !== undefined) {
      headers = headers.set('Accept', httpHeaderAcceptSelected);
    }

    let responseType: 'text' | 'json' = 'json';
    if (httpHeaderAcceptSelected && httpHeaderAcceptSelected.startsWith('text')) {
      responseType = 'text';
    }

    return this.httpClient.get<LightSchoolApiDto>(`${this.configuration.basePath}/products/v3/schools/${encodeURIComponent(String(schoolCode))}/courses`, {
      responseType: <any>responseType,
      withCredentials: this.configuration.withCredentials,
      headers: headers,
      observe: observe,
      reportProgress: reportProgress,
    });
  }

  /**
   * @param filter
   * @param acceptLanguage Language code to inform the display language for the results
   * @param acceptCompany Company code for the source of data
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
  public findSchools(
    filter: FindSchoolRequestApiDto,
    acceptLanguage: string,
    acceptCompany: 'ALP' | 'KIL' | 'ESL' | 'KSG',
    observe?: 'body',
    reportProgress?: boolean,
    options?: { httpHeaderAccept?: 'application/json' },
  ): Observable<Array<SchoolApiDto>>;
  public findSchools(
    filter: FindSchoolRequestApiDto,
    acceptLanguage: string,
    acceptCompany: 'ALP' | 'KIL' | 'ESL' | 'KSG',
    observe?: 'response',
    reportProgress?: boolean,
    options?: { httpHeaderAccept?: 'application/json' },
  ): Observable<HttpResponse<Array<SchoolApiDto>>>;
  public findSchools(
    filter: FindSchoolRequestApiDto,
    acceptLanguage: string,
    acceptCompany: 'ALP' | 'KIL' | 'ESL' | 'KSG',
    observe?: 'events',
    reportProgress?: boolean,
    options?: { httpHeaderAccept?: 'application/json' },
  ): Observable<HttpEvent<Array<SchoolApiDto>>>;
  public findSchools(
    filter: FindSchoolRequestApiDto,
    acceptLanguage: string,
    acceptCompany: 'ALP' | 'KIL' | 'ESL' | 'KSG',
    observe: any = 'body',
    reportProgress: boolean = false,
    options?: { httpHeaderAccept?: 'application/json' },
  ): Observable<any> {
    if (filter === null || filter === undefined) {
      throw new Error('Required parameter filter was null or undefined when calling findSchools.');
    }
    if (acceptLanguage === null || acceptLanguage === undefined) {
      throw new Error('Required parameter acceptLanguage was null or undefined when calling findSchools.');
    }
    if (acceptCompany === null || acceptCompany === undefined) {
      throw new Error('Required parameter acceptCompany was null or undefined when calling findSchools.');
    }

    let queryParameters = new HttpParams({ encoder: this.encoder });
    if (filter !== undefined && filter !== null) {
      queryParameters = this.addToHttpParams(queryParameters, <any>filter, 'filter');
    }

    let headers = this.defaultHeaders;
    if (acceptLanguage !== undefined && acceptLanguage !== null) {
      headers = headers.set('Accept-Language', String(acceptLanguage));
    }
    if (acceptCompany !== undefined && acceptCompany !== null) {
      headers = headers.set('Accept-Company', String(acceptCompany));
    }

    let httpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
    if (httpHeaderAcceptSelected === undefined) {
      // to determine the Accept header
      const httpHeaderAccepts: string[] = ['application/json'];
      httpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
    }
    if (httpHeaderAcceptSelected !== undefined) {
      headers = headers.set('Accept', httpHeaderAcceptSelected);
    }

    let responseType: 'text' | 'json' = 'json';
    if (httpHeaderAcceptSelected && httpHeaderAcceptSelected.startsWith('text')) {
      responseType = 'text';
    }

    return this.httpClient.get<Array<SchoolApiDto>>(`${this.configuration.basePath}/products/v2/schools`, {
      params: queryParameters,
      responseType: <any>responseType,
      withCredentials: this.configuration.withCredentials,
      headers: headers,
      observe: observe,
      reportProgress: reportProgress,
    });
  }

  /**
   * @param schoolCode The code of the school.
   * @param quoteDate Date when the quote is calculated
   * @param residenceCountryCode Country of residence of the student. ISO 3166-1 alpha-2 (2-letter codes).
   * @param units Quantity used for price calculation: Number of weeks, extra nights, one-to-one lessons, etc
   * @param acceptLanguage Language code to inform the display language for the results
   * @param acceptCompany Company code for the source of data
   * @param productPackage
   * @param extraInfo
   * @param privateLessonsLanguage
   * @param dateRangeStartDate
   * @param dateRangeEndDate
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
  public findSchoolsExtras(
    schoolCode: string,
    quoteDate: string,
    residenceCountryCode: string,
    units: number,
    acceptLanguage: string,
    acceptCompany: 'ALP' | 'KIL' | 'ESL' | 'KSG',
    productPackage?: ProductPackageApiDto,
    extraInfo?: Array<
      | 'AIRPORT_TRANSFER'
      | 'INSURANCE'
      | 'PREMIUM_ACTIVITIES'
      | 'PRIVATE_LESSONS'
      | 'SUPPLEMENTS'
      | 'ACCOMMODATIONS'
      | 'PREMIUM_RESIDENCES'
      | 'VIRTUAL_INTERNSHIPS'
    >,
    privateLessonsLanguage?: string,
    dateRangeStartDate?: string,
    dateRangeEndDate?: string,
    observe?: 'body',
    reportProgress?: boolean,
    options?: { httpHeaderAccept?: 'application/json' },
  ): Observable<SchoolExtrasApiDto>;
  public findSchoolsExtras(
    schoolCode: string,
    quoteDate: string,
    residenceCountryCode: string,
    units: number,
    acceptLanguage: string,
    acceptCompany: 'ALP' | 'KIL' | 'ESL' | 'KSG',
    productPackage?: ProductPackageApiDto,
    extraInfo?: Array<
      | 'AIRPORT_TRANSFER'
      | 'INSURANCE'
      | 'PREMIUM_ACTIVITIES'
      | 'PRIVATE_LESSONS'
      | 'SUPPLEMENTS'
      | 'ACCOMMODATIONS'
      | 'PREMIUM_RESIDENCES'
      | 'VIRTUAL_INTERNSHIPS'
    >,
    privateLessonsLanguage?: string,
    dateRangeStartDate?: string,
    dateRangeEndDate?: string,
    observe?: 'response',
    reportProgress?: boolean,
    options?: { httpHeaderAccept?: 'application/json' },
  ): Observable<HttpResponse<SchoolExtrasApiDto>>;
  public findSchoolsExtras(
    schoolCode: string,
    quoteDate: string,
    residenceCountryCode: string,
    units: number,
    acceptLanguage: string,
    acceptCompany: 'ALP' | 'KIL' | 'ESL' | 'KSG',
    productPackage?: ProductPackageApiDto,
    extraInfo?: Array<
      | 'AIRPORT_TRANSFER'
      | 'INSURANCE'
      | 'PREMIUM_ACTIVITIES'
      | 'PRIVATE_LESSONS'
      | 'SUPPLEMENTS'
      | 'ACCOMMODATIONS'
      | 'PREMIUM_RESIDENCES'
      | 'VIRTUAL_INTERNSHIPS'
    >,
    privateLessonsLanguage?: string,
    dateRangeStartDate?: string,
    dateRangeEndDate?: string,
    observe?: 'events',
    reportProgress?: boolean,
    options?: { httpHeaderAccept?: 'application/json' },
  ): Observable<HttpEvent<SchoolExtrasApiDto>>;
  public findSchoolsExtras(
    schoolCode: string,
    quoteDate: string,
    residenceCountryCode: string,
    units: number,
    acceptLanguage: string,
    acceptCompany: 'ALP' | 'KIL' | 'ESL' | 'KSG',
    productPackage?: ProductPackageApiDto,
    extraInfo?: Array<
      | 'AIRPORT_TRANSFER'
      | 'INSURANCE'
      | 'PREMIUM_ACTIVITIES'
      | 'PRIVATE_LESSONS'
      | 'SUPPLEMENTS'
      | 'ACCOMMODATIONS'
      | 'PREMIUM_RESIDENCES'
      | 'VIRTUAL_INTERNSHIPS'
    >,
    privateLessonsLanguage?: string,
    dateRangeStartDate?: string,
    dateRangeEndDate?: string,
    observe: any = 'body',
    reportProgress: boolean = false,
    options?: { httpHeaderAccept?: 'application/json' },
  ): Observable<any> {
    if (schoolCode === null || schoolCode === undefined) {
      throw new Error('Required parameter schoolCode was null or undefined when calling findSchoolsExtras.');
    }
    if (quoteDate === null || quoteDate === undefined) {
      throw new Error('Required parameter quoteDate was null or undefined when calling findSchoolsExtras.');
    }
    if (residenceCountryCode === null || residenceCountryCode === undefined) {
      throw new Error('Required parameter residenceCountryCode was null or undefined when calling findSchoolsExtras.');
    }
    if (units === null || units === undefined) {
      throw new Error('Required parameter units was null or undefined when calling findSchoolsExtras.');
    }
    if (acceptLanguage === null || acceptLanguage === undefined) {
      throw new Error('Required parameter acceptLanguage was null or undefined when calling findSchoolsExtras.');
    }
    if (acceptCompany === null || acceptCompany === undefined) {
      throw new Error('Required parameter acceptCompany was null or undefined when calling findSchoolsExtras.');
    }

    let queryParameters = new HttpParams({ encoder: this.encoder });
    if (productPackage !== undefined && productPackage !== null) {
      queryParameters = this.addToHttpParams(queryParameters, <any>productPackage, 'productPackage');
    }
    if (extraInfo) {
      extraInfo.forEach((element) => {
        queryParameters = this.addToHttpParams(queryParameters, <any>element, 'extraInfo');
      });
    }
    if (privateLessonsLanguage !== undefined && privateLessonsLanguage !== null) {
      queryParameters = this.addToHttpParams(queryParameters, <any>privateLessonsLanguage, 'privateLessonsLanguage');
    }
    if (quoteDate !== undefined && quoteDate !== null) {
      queryParameters = this.addToHttpParams(queryParameters, <any>quoteDate, 'quoteDate');
    }
    if (residenceCountryCode !== undefined && residenceCountryCode !== null) {
      queryParameters = this.addToHttpParams(queryParameters, <any>residenceCountryCode, 'residenceCountryCode');
    }
    if (dateRangeStartDate !== undefined && dateRangeStartDate !== null) {
      queryParameters = this.addToHttpParams(queryParameters, <any>dateRangeStartDate, 'dateRange.startDate');
    }
    if (dateRangeEndDate !== undefined && dateRangeEndDate !== null) {
      queryParameters = this.addToHttpParams(queryParameters, <any>dateRangeEndDate, 'dateRange.endDate');
    }
    if (units !== undefined && units !== null) {
      queryParameters = this.addToHttpParams(queryParameters, <any>units, 'units');
    }

    let headers = this.defaultHeaders;
    if (acceptLanguage !== undefined && acceptLanguage !== null) {
      headers = headers.set('Accept-Language', String(acceptLanguage));
    }
    if (acceptCompany !== undefined && acceptCompany !== null) {
      headers = headers.set('Accept-Company', String(acceptCompany));
    }

    let httpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
    if (httpHeaderAcceptSelected === undefined) {
      // to determine the Accept header
      const httpHeaderAccepts: string[] = ['application/json'];
      httpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
    }
    if (httpHeaderAcceptSelected !== undefined) {
      headers = headers.set('Accept', httpHeaderAcceptSelected);
    }

    let responseType: 'text' | 'json' = 'json';
    if (httpHeaderAcceptSelected && httpHeaderAcceptSelected.startsWith('text')) {
      responseType = 'text';
    }

    return this.httpClient.get<SchoolExtrasApiDto>(`${this.configuration.basePath}/products/v4/schools/${encodeURIComponent(String(schoolCode))}/extras`, {
      params: queryParameters,
      responseType: <any>responseType,
      withCredentials: this.configuration.withCredentials,
      headers: headers,
      observe: observe,
      reportProgress: reportProgress,
    });
  }

  /**
   * @param filter
   * @param acceptLanguage Language code to inform the display language for the results
   * @param acceptCompany Company code for the source of data
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
  public findSchoolsWithFilters(
    filter: FindSchoolRequestApiDto,
    acceptLanguage: string,
    acceptCompany: 'ALP' | 'KIL' | 'ESL' | 'KSG',
    observe?: 'body',
    reportProgress?: boolean,
    options?: { httpHeaderAccept?: 'application/json' },
  ): Observable<Array<SchoolApiDto>>;
  public findSchoolsWithFilters(
    filter: FindSchoolRequestApiDto,
    acceptLanguage: string,
    acceptCompany: 'ALP' | 'KIL' | 'ESL' | 'KSG',
    observe?: 'response',
    reportProgress?: boolean,
    options?: { httpHeaderAccept?: 'application/json' },
  ): Observable<HttpResponse<Array<SchoolApiDto>>>;
  public findSchoolsWithFilters(
    filter: FindSchoolRequestApiDto,
    acceptLanguage: string,
    acceptCompany: 'ALP' | 'KIL' | 'ESL' | 'KSG',
    observe?: 'events',
    reportProgress?: boolean,
    options?: { httpHeaderAccept?: 'application/json' },
  ): Observable<HttpEvent<Array<SchoolApiDto>>>;
  public findSchoolsWithFilters(
    filter: FindSchoolRequestApiDto,
    acceptLanguage: string,
    acceptCompany: 'ALP' | 'KIL' | 'ESL' | 'KSG',
    observe: any = 'body',
    reportProgress: boolean = false,
    options?: { httpHeaderAccept?: 'application/json' },
  ): Observable<any> {
    if (filter === null || filter === undefined) {
      throw new Error('Required parameter filter was null or undefined when calling findSchoolsWithFilters.');
    }
    if (acceptLanguage === null || acceptLanguage === undefined) {
      throw new Error('Required parameter acceptLanguage was null or undefined when calling findSchoolsWithFilters.');
    }
    if (acceptCompany === null || acceptCompany === undefined) {
      throw new Error('Required parameter acceptCompany was null or undefined when calling findSchoolsWithFilters.');
    }

    let queryParameters = new HttpParams({ encoder: this.encoder });
    if (filter !== undefined && filter !== null) {
      queryParameters = this.addToHttpParams(queryParameters, <any>filter, 'filter');
    }

    let headers = this.defaultHeaders;
    if (acceptLanguage !== undefined && acceptLanguage !== null) {
      headers = headers.set('Accept-Language', String(acceptLanguage));
    }
    if (acceptCompany !== undefined && acceptCompany !== null) {
      headers = headers.set('Accept-Company', String(acceptCompany));
    }

    let httpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
    if (httpHeaderAcceptSelected === undefined) {
      // to determine the Accept header
      const httpHeaderAccepts: string[] = ['application/json'];
      httpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
    }
    if (httpHeaderAcceptSelected !== undefined) {
      headers = headers.set('Accept', httpHeaderAcceptSelected);
    }

    let responseType: 'text' | 'json' = 'json';
    if (httpHeaderAcceptSelected && httpHeaderAcceptSelected.startsWith('text')) {
      responseType = 'text';
    }

    return this.httpClient.get<Array<SchoolApiDto>>(`${this.configuration.basePath}/products/v3/schools`, {
      params: queryParameters,
      responseType: <any>responseType,
      withCredentials: this.configuration.withCredentials,
      headers: headers,
      observe: observe,
      reportProgress: reportProgress,
    });
  }

  /**
   * Return trip dates for course and accommodation
   * @param duration
   * @param startDate
   * @param schoolCode School code
   * @param acceptLanguage Language code to inform the display language for the results
   * @param acceptCompany Company code for the source of data
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
  public getTripDates(
    duration: number,
    startDate: string,
    schoolCode: string,
    acceptLanguage: string,
    acceptCompany: 'ALP' | 'KIL' | 'ESL' | 'KSG',
    observe?: 'body',
    reportProgress?: boolean,
    options?: { httpHeaderAccept?: 'application/json' },
  ): Observable<TripDatesApiDto>;
  public getTripDates(
    duration: number,
    startDate: string,
    schoolCode: string,
    acceptLanguage: string,
    acceptCompany: 'ALP' | 'KIL' | 'ESL' | 'KSG',
    observe?: 'response',
    reportProgress?: boolean,
    options?: { httpHeaderAccept?: 'application/json' },
  ): Observable<HttpResponse<TripDatesApiDto>>;
  public getTripDates(
    duration: number,
    startDate: string,
    schoolCode: string,
    acceptLanguage: string,
    acceptCompany: 'ALP' | 'KIL' | 'ESL' | 'KSG',
    observe?: 'events',
    reportProgress?: boolean,
    options?: { httpHeaderAccept?: 'application/json' },
  ): Observable<HttpEvent<TripDatesApiDto>>;
  public getTripDates(
    duration: number,
    startDate: string,
    schoolCode: string,
    acceptLanguage: string,
    acceptCompany: 'ALP' | 'KIL' | 'ESL' | 'KSG',
    observe: any = 'body',
    reportProgress: boolean = false,
    options?: { httpHeaderAccept?: 'application/json' },
  ): Observable<any> {
    if (duration === null || duration === undefined) {
      throw new Error('Required parameter duration was null or undefined when calling getTripDates.');
    }
    if (startDate === null || startDate === undefined) {
      throw new Error('Required parameter startDate was null or undefined when calling getTripDates.');
    }
    if (schoolCode === null || schoolCode === undefined) {
      throw new Error('Required parameter schoolCode was null or undefined when calling getTripDates.');
    }
    if (acceptLanguage === null || acceptLanguage === undefined) {
      throw new Error('Required parameter acceptLanguage was null or undefined when calling getTripDates.');
    }
    if (acceptCompany === null || acceptCompany === undefined) {
      throw new Error('Required parameter acceptCompany was null or undefined when calling getTripDates.');
    }

    let queryParameters = new HttpParams({ encoder: this.encoder });
    if (duration !== undefined && duration !== null) {
      queryParameters = this.addToHttpParams(queryParameters, <any>duration, 'duration');
    }
    if (startDate !== undefined && startDate !== null) {
      queryParameters = this.addToHttpParams(queryParameters, <any>startDate, 'startDate');
    }

    let headers = this.defaultHeaders;
    if (acceptLanguage !== undefined && acceptLanguage !== null) {
      headers = headers.set('Accept-Language', String(acceptLanguage));
    }
    if (acceptCompany !== undefined && acceptCompany !== null) {
      headers = headers.set('Accept-Company', String(acceptCompany));
    }

    let httpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
    if (httpHeaderAcceptSelected === undefined) {
      // to determine the Accept header
      const httpHeaderAccepts: string[] = ['application/json'];
      httpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
    }
    if (httpHeaderAcceptSelected !== undefined) {
      headers = headers.set('Accept', httpHeaderAcceptSelected);
    }

    let responseType: 'text' | 'json' = 'json';
    if (httpHeaderAcceptSelected && httpHeaderAcceptSelected.startsWith('text')) {
      responseType = 'text';
    }

    return this.httpClient.get<TripDatesApiDto>(`${this.configuration.basePath}/products/v4/schools/${encodeURIComponent(String(schoolCode))}/trip-dates`, {
      params: queryParameters,
      responseType: <any>responseType,
      withCredentials: this.configuration.withCredentials,
      headers: headers,
      observe: observe,
      reportProgress: reportProgress,
    });
  }
}
