import { Component, EventEmitter, OnInit, Output, inject, Input, OnDestroy } from '@angular/core';
import { StepService } from '@klg/quote-tool/shared/services';
import { FormFieldComponent } from '@klg/shared/ui/form-field';
import { PaginatorModule } from 'primeng/paginator';
import { of, Subject, switchMap, takeUntil } from 'rxjs';
import { ResidenceCountryService } from '@klg/shared/data-access/destination';
import { QuoteToolStore } from '@klg/quote-tool/shared/store';
import { NgIf } from '@angular/common';
import { CountryDetectorService } from '@klg/quote-tool/shared/services';

@Component({
  standalone: true,
  selector: 'kng-phone-number-field',
  imports: [FormFieldComponent, PaginatorModule, NgIf],
  templateUrl: './phone-number-field.component.html',
  styleUrls: ['./phone-number-field.component.scss'],
})
export class PhoneNumberFieldComponent implements OnInit, OnDestroy {
  @Input() label = '';
  @Input() isValid: null | boolean = false;
  @Input() required = false;
  @Input() phoneCode = '';
  @Input() phoneNumber = '';
  @Input() errorMessage: string | undefined;
  @Output() valueChange = new EventEmitter<string>();

  countries = [];
  inputValidation = false;
  dropdownValidation = false;

  private readonly destroy$ = new Subject<void>();
  private readonly stepService = inject(StepService);
  private readonly quoteToolStore = inject(QuoteToolStore);
  private readonly residenceCountryService = inject(ResidenceCountryService);
  private readonly countryDetectorService = inject(CountryDetectorService);

  ngOnInit(): void {
    // First, try to take the countries from store
    // but if they weren't loaded because we have not passed from residence country selector,
    // get them from residenceCountryService service
    this.quoteToolStore.countriesOfResidence$
      .pipe(
        switchMap((countries) => (!countries?.length ? this.residenceCountryService.getAll() : of(countries))),
        takeUntil(this.destroy$),
      )
      .subscribe((countries) => {
        this.countries = countries;
        const residenceDataPhoneCode = this.stepService.getResidenceData()?.countryPhoneCode;
        const countryDetectorPhoneCode = countries?.find((country) => country?.code === this.countryDetectorService.detectCountryCode())?.phoneCode;
        if (!this.phoneCode) {
          this.phoneCode = residenceDataPhoneCode || countryDetectorPhoneCode;
        }
      });
  }

  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }

  updatePhoneNumber(validation?: boolean) {
    if (validation) {
      this.inputValidation = validation;
    }
    this.valueChange.emit(this.phoneCode + ' ' + this.phoneNumber);
  }

  updateValidation(primaryValidation: boolean, secondaryValidation?: boolean) {
    primaryValidation ? this.updatePhoneNumber(secondaryValidation) : null;
  }

  dropdownFocus(focus?: boolean) {
    if (focus) {
      this.dropdownValidation = focus;
    }
  }
}
