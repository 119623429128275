<div class="quote-print">
  <header>
    <img class="quote-print__header-image" *ngIf="headerImageSrc" [src]="headerImageSrc" alt="" />

    <p class="quote-print__title" i18n>Hello {{ quoteOutput?.input?.destinationCity?.name }}</p>
    <p class="quote-print__subtitle" *ngIf="studentDetails">{{ studentDetails?.firstName }} {{ studentDetails?.lastName }}</p>

    <p class="quote-print__school-title">{{ quoteOutput?.input?.schoolObject?.name }}</p>
  </header>
  <main>
    <p>
      <span *ngIf="schoolTypeText">{{ schoolTypeText }},&nbsp;</span>
      <span *ngIf="languageName">{{ languageName }},&nbsp;</span>
      <span>{{ quoteOutput?.input?.country?.name }}</span>
    </p>

    <div *ngFor="let product of products" class="quote-print__product-block">
      <ng-container *ngTemplateOutlet="productBreakdown; context: { product: product }"></ng-container>
    </div>

    <div *ngIf="supplements?.length" class="quote-print__supplements-block">
      <h4 class="quote-print__section-title" i18n>Supplements</h4>
      <table class="quote-print__supplements">
        <tbody>
          <tr *ngFor="let supplement of supplements">
            <th scope="row">{{ supplement.units }}x {{ supplement.name }}</th>
            <td>{{ supplement.price | klgPrice : { currency: currency, minDecimalPlaces: decimalPlaces } }}</td>
          </tr>
        </tbody>
      </table>
    </div>

    <div *ngIf="quoteOutput?.discounts?.length" class="quote-print__discounts-block">
      <h4 class="quote-print__section-title" i18n>Discounts</h4>
      <table class="quote-print__discounts">
        <tbody>
          <tr *ngFor="let discount of quoteOutput?.discounts">
            <th scope="row">
              {{ discount.name }}
              <kng-time-left [expirationDate]="discount.expirationDate"></kng-time-left>
            </th>
            <td>{{ discount.discount | klgPrice : { currency: currency, minDecimalPlaces: decimalPlaces } }}</td>
          </tr>
        </tbody>
      </table>
    </div>

    <div class="quote-print__price">
      <div class="quote-print__included" *ngIf="keyPoints?.included?.length">
        <h4 class="quote-print__section-title" i18n>Included in the price</h4>
        <kng-key-point
          *ngFor="let included of keyPoints?.included"
          [icon]="KeyPointIcons.INCLUDED"
          [text]="included.description"
          [additionalText]="included.additionalDescription"
        ></kng-key-point>
      </div>

      <div class="quote-print__excluded" *ngIf="keyPoints?.notIncluded?.length">
        <h4 class="quote-print__section-title" i18n>Not Included in the price</h4>
        <kng-key-point
          *ngFor="let excluded of keyPoints?.notIncluded"
          [icon]="KeyPointIcons.EXCLUDED"
          [text]="excluded.description"
          [additionalText]="excluded.additionalDescription"
          [price]="excluded.price | klgPrice : { currency: currency, minDecimalPlaces: decimalPlaces }"
        ></kng-key-point>
      </div>

      <kng-quote-total-price
        class="quote-print__total"
        [quote]="quoteOutput"
        [selectedCurrency]="currency"
        [hasCurrencySelector]="false"
        [displayDestinationCurrency]="displayDestinationCurrency"
        [hasDisclaimerSection]="true"
        [showDecimals]="false"
        [infoDisclaimer]="infoDisclaimer"
      ></kng-quote-total-price>
    </div>
  </main>

  <footer>
    <p class="quote-print__offer-date" i18n>Offer prepared on {{ today | date : 'longDate' }}</p>

    <ul class="quote-print__links">
      <li *ngIf="termsAndConditionsUrl">
        <span i18n>Our terms and conditions</span>: <a [href]="termsAndConditionsUrl">{{ termsAndConditionsUrl }}</a>
      </li>
    </ul>
  </footer>
</div>

<ng-template #productBreakdown let-product="product">
  <h4 class="quote-print__section-title">{{ product.name }}</h4>
  <p class="quote-print__description" *ngFor="let description of product.descriptions">{{ description }}</p>
  <table class="quote-print__price-breakdown">
    <tbody>
      <tr>
        <th scope="row">
          <ng-container *ngIf="product.startDate">
            <div i18n>{{ product.units }} weeks from {{ product.startDate }} to {{ product.endDate }}</div>
            <div class="quote-print__vacations">{{ product.vacations | klgClosingDates : product.classification }}</div>
          </ng-container>
          <ng-container *ngIf="!product.startDate && product.units">{{ product.units }}x</ng-container>
        </th>
        <td>{{ product.price | klgPrice : { currency: currency, minDecimalPlaces: decimalPlaces } }}</td>
      </tr>
      <tr *ngFor="let supplement of product.supplements?.supplementItems || []">
        <th scope="row">{{ supplement.name }}</th>
        <td>{{ supplement.price | klgPrice : { currency: currency, minDecimalPlaces: decimalPlaces } }}</td>
      </tr>
    </tbody>
  </table>
</ng-template>
