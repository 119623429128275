<kng-section-template [sectionIndex]="sectionIndex" [sectionTitle]="sectionTitle">
  <ng-container *ngTemplateOutlet="isAdultSchool() ? adultTemplate : juniorTemplate"></ng-container>
</kng-section-template>

<ng-template #adultTemplate>
  <kng-form-field
    label="Emergency Contact person - First name"
    i18n-label
    [small]="true"
    [isValid]="formData.contactPersonFirstName.isValid"
    [required]="formData.contactPersonFirstName.required"
    [errorMessage]="formData.contactPersonFirstName.errorMessage"
    [iconValidation]="true"
    data-qa="enrolment-emergency-contact-first-name"
  >
    <input
      pInputText
      type="text"
      kngFormDataInputValidity
      [formData]="formData.contactPersonFirstName"
      [(ngModel)]="formData.contactPersonFirstName.value"
      (focusout)="recalculateFormValidation('contactPersonFirstName')"
    />
  </kng-form-field>
  <kng-form-field
    label="Emergency Contact person - Last name"
    i18n-label
    [small]="true"
    [isValid]="formData.contactPersonLastName.isValid"
    [required]="formData.contactPersonLastName.required"
    [errorMessage]="formData.contactPersonLastName.errorMessage"
    [iconValidation]="true"
    data-qa="enrolment-emergency-contact-last-name"
  >
    <input
      type="text"
      pInputText
      kngFormDataInputValidity
      [formData]="formData.contactPersonLastName"
      [(ngModel)]="formData.contactPersonLastName.value"
      (focusout)="recalculateFormValidation('contactPersonLastName')"
    />
  </kng-form-field>
  <kng-phone-number-field
    class="form-field__search-bar"
    label="Emergency Contact person - mobile"
    [isValid]="formData.contactPersonPhone.isValid"
    [required]="formData.contactPersonPhone.required"
    [errorMessage]="formData.contactPersonPhone.errorMessage"
    (valueChange)="updatePhoneNumber($event, 'contactPersonPhone')"
    data-qa="enrolment-emergency-contact-mobile"
  ></kng-phone-number-field>
</ng-template>

<ng-template #juniorTemplate>
  <kng-form-field
    label="First name"
    i18n-label
    [small]="true"
    [isValid]="formData.legalRepresentativeFirstName.isValid"
    [required]="formData.legalRepresentativeFirstName.required"
    [errorMessage]="formData.legalRepresentativeFirstName.errorMessage"
    [iconValidation]="true"
    data-qa="enrolment-juniors-legal-representative-first-name"
  >
    <input
      pInputText
      type="text"
      kngFormDataInputValidity
      [formData]="formData.legalRepresentativeFirstName"
      [(ngModel)]="formData.legalRepresentativeFirstName.value"
      (focusout)="recalculateFormValidation('legalRepresentativeFirstName')"
    />
  </kng-form-field>
  <kng-form-field
    label="Last name"
    i18n-label
    [small]="true"
    [isValid]="formData.legalRepresentativeLastName.isValid"
    [required]="formData.legalRepresentativeLastName.required"
    [errorMessage]="formData.legalRepresentativeLastName.errorMessage"
    [iconValidation]="true"
    data-qa="enrolment-juniors-legal-representative-last-name"
  >
    <input
      pInputText
      type="text"
      kngFormDataInputValidity
      [formData]="formData.legalRepresentativeLastName"
      [(ngModel)]="formData.legalRepresentativeLastName.value"
      (focusout)="recalculateFormValidation('legalRepresentativeLastName')"
    />
  </kng-form-field>
  <kng-form-field
    label="Email address"
    i18n-label
    [small]="true"
    [isValid]="formData.legalRepresentativeEmail.isValid"
    [required]="formData.legalRepresentativeEmail.required"
    [errorMessage]="formData.legalRepresentativeEmail.errorMessage"
    [iconValidation]="true"
    data-qa="enrolment-juniors-legal-representative-email"
  >
    <input
      pInputText
      type="text"
      kngFormDataEmailValidity
      [formData]="formData.legalRepresentativeEmail"
      [(ngModel)]="formData.legalRepresentativeEmail.value"
      (focusout)="recalculateFormValidation('legalRepresentativeEmail')"
    />
  </kng-form-field>
  <kng-phone-number-field
    class="form-field__search-bar"
    label="Phone number"
    [isValid]="formData.legalRepresentativeMobile.isValid"
    [required]="formData.legalRepresentativeMobile.required"
    [errorMessage]="formData.legalRepresentativeMobile.errorMessage"
    (valueChange)="updatePhoneNumber($event, 'legalRepresentativeMobile')"
    data-qa="enrolment-juniors-legal-representative-phone"
  ></kng-phone-number-field>
  <kng-form-field
    label="Address (if different)"
    i18n-label
    [small]="true"
    [isValid]="formData.legalRepresentativeAddress.isValid"
    [required]="formData.legalRepresentativeAddress.required"
    [errorMessage]="formData.legalRepresentativeAddress.errorMessage"
    data-qa="enrolment-juniors-legal-representative-address-different"
  >
    <input
      pInputText
      type="text"
      kngFormDataInputValidity
      [formData]="formData.legalRepresentativeAddress"
      [(ngModel)]="formData.legalRepresentativeAddress.value"
      (focusout)="recalculateFormValidation('legalRepresentativeAddress')"
    />
  </kng-form-field>
</ng-template>
