import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { AccommodationWithOptions } from '@klg/shared/data-access/products';
import { GoogleAnalyticsClickEventDirective, GoogleAnalyticsEvents } from '@klg/shared/google-analytics';
import { getConfiguration } from '@klg/shared/tokens';
import { CommonModule } from '@angular/common';
import { AccommodationOptionsComponent } from '../accommodation-options/accommodation-options.component';
import { PricePipe } from '@klg/shared/ui/pipes';
import { OfferLabelComponent } from '../../offer-label/offer-label.component';

@Component({
  standalone: true,
  selector: 'kng-accommodation-options-summary',
  templateUrl: './accommodation-options-summary.component.html',
  styleUrls: ['./accommodation-options-summary.component.scss'],
  imports: [CommonModule, AccommodationOptionsComponent, PricePipe, OfferLabelComponent, GoogleAnalyticsClickEventDirective],
})
export class AccommodationOptionsSummaryComponent implements OnInit {
  @Input() accommodationOptions: AccommodationWithOptions;
  @Input() hasPromotion = false;
  @Output() public moreDetailsClicked = new EventEmitter<void>();
  @Output() public showOfferPopup = new EventEmitter<AccommodationWithOptions>();
  showAvailabilityLabel: boolean;
  readonly GoogleAnalyticsEvents = GoogleAnalyticsEvents;

  private readonly configuration = getConfiguration();

  ngOnInit() {
    this.showAvailabilityLabel = this.accommodationOptions.subjectToAvailability && (this.configuration.SHOW_RESIDENCE_AVAILABILITY_LABEL as boolean);
  }

  showPromotionPopup(accommodationOptions: AccommodationWithOptions) {
    this.showOfferPopup.emit(accommodationOptions);
  }
}
