import { animate, state, style, transition, trigger } from '@angular/animations';

const hiddenStyle = { height: 0, opacity: 0, marginTop: 0, paddingTop: 0, marginBottom: 0, paddingBottom: 0 };
const enter = [style(hiddenStyle), animate('200ms ease-out', style('*'))];
const leave = [style('*'), animate('200ms ease-in', style(hiddenStyle))];
export const accordionAnimationData = trigger('accordionAnimation', [transition(':enter', enter), transition(':leave', leave)]);

const hiddenOverflowStyle = { ...hiddenStyle, overflow: 'hidden' };
export const promoAccordionAnimation = trigger('accordionAnimation', [
  transition(':enter', [style(hiddenOverflowStyle), animate('100ms ease-out', style('*'))]),
  transition(':leave', [style('*'), animate('200ms ease-in', style(hiddenOverflowStyle))]),
]);

export const accordionAnimation = trigger('accordionAnimation', [
  state(
    'collapsed',
    style({
      height: '0px',
      visibility: 'hidden',
      opacity: 0,
    }),
  ),
  state(
    'expanded',
    style({
      height: '*',
      visibility: 'visible',
      opacity: 1,
    }),
  ),
  transition('collapsed <=> expanded', animate('300ms ease-in-out')),
]);
