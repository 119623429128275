import { inject, Injectable } from '@angular/core';
import { Route } from '@angular/router';
import { BookingFormComponent } from './booking-form.component';
import { ROUTE_IDS, StepDefinition } from '@klg/quote-tool/shared/types';

const stepId = ROUTE_IDS.BOOKING_FORM;

@Injectable({
  providedIn: 'root',
})
export class BookingFormResolver {
  private stepDefinition: StepDefinition = {
    nextButton: $localize`Book Now`,
    nextButtonId: 'enrolment-button',
    prevButton: $localize`Back`,
    displayQuote: true,
    stepId,
    previousStep: () => ROUTE_IDS.QUOTE_FORM,
    nextStep: () => ROUTE_IDS.SEND_QUOTE_TO_EMAIL,
  };

  resolve(): StepDefinition {
    return this.stepDefinition;
  }
}

export const stepRoute: Route = {
  path: stepId,
  resolve: {
    stepDefinition: () => inject(BookingFormResolver).resolve(),
  },
  component: BookingFormComponent,
};
