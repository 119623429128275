import { QuoteInput, StudentDetails } from '@klg/quote-tool/shared/types';
import { CityExtended, Country, CourseType } from '@klg/shared/data-access/types';

export interface PriceRequestExperimentState {
  quoteInput?: QuoteInput;
  studentDetails?: StudentDetails;
  courseType?: CourseType;
  destinationCountries?: Country[];
  destinationCities?: CityExtended[];
  courseTypes?: string[];
}

export const defaultPriceRequestExperimentState: PriceRequestExperimentState = {};
