import { Component, inject, OnInit } from '@angular/core';
import { Router, RouterOutlet } from '@angular/router';
// TODO review to remove this error
// eslint-disable-next-line @nrwl/nx/enforce-module-boundaries
import { FormComponent, QuoteToolFormModule } from '@klg/quote-tool/form';
import { QuoteToolModes, quoteToolModesTypeMap, quoteToolPaths } from '@klg/quote-tool/shared/routing';
import { StepService } from '@klg/quote-tool/shared/services';
import { CommonModule, DOCUMENT } from '@angular/common';
import { SalesCommunicationSettingsComponent } from './components/sales-communication-settings/sales-communication-settings.component';
import { QuoteOutput } from '@klg/quote-tool/shared/types';
import { ArgoIdsComponent } from './components/argo-ids/argo-ids.component';
import { ToastModule } from 'primeng/toast';
import { EslQqToolParametersEnum } from './types/esl-qq-tool-parameters.enum';
import { ArgoService } from './services/argo.service';
import { first } from 'rxjs';
import { ToastNotificationComponent } from '@klg/shared/ui/error';
import { INFO_TOAST_KEY } from './constants/toast.constants';
import { LoadingSpinnerComponent } from '@klg/shared/ui/loading-spinner';

@Component({
  standalone: true,
  imports: [
    CommonModule,
    RouterOutlet,
    QuoteToolFormModule,
    LoadingSpinnerComponent,
    SalesCommunicationSettingsComponent,
    ArgoIdsComponent,
    ToastModule,
    ToastNotificationComponent,
  ],
  selector: 'klg-esl-quick-quote-tool',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
})
export class AppComponent implements OnInit {
  routedComponent: FormComponent;

  // Query param properties
  language = '';
  schoolCode = '';
  argoIdentifier = '';
  clientName = '';

  orderId: number | undefined = undefined;
  formattedArgoIds = [];
  loading = true;

  infoToastKey = INFO_TOAST_KEY;

  private readonly document = inject(DOCUMENT);
  private readonly router = inject(Router);
  private readonly stepService = inject(StepService);
  private readonly argoService = inject(ArgoService);

  private readonly mode = QuoteToolModes.quickQuoteTool;

  ngOnInit() {
    this.getQueryParamsFromUrl();
    this.stepService.initialize();
    this.setFormType();
    this.redirectToModePath();
  }

  componentAdded(component: FormComponent) {
    this.loading = component === undefined;
    this.stepService.cleanSession();
    this.setFormType();
    component.submitQuoteEvent.subscribe((quoteOutput: QuoteOutput) => {
      this.argoService
        .sendLeadToArgo(quoteOutput, this.orderId)
        .pipe(first())
        .subscribe((orderId: number) => {
          this.orderId = orderId;
          const formattedArgoId = `argo-identifier-${this.argoIdentifier}_order-id-${this.orderId}`;
          if (!this.formattedArgoIds.find((id) => id === formattedArgoId)) {
            // Add only new ids to the list
            this.formattedArgoIds.push(formattedArgoId);
          }
          this.argoService.copyIdToClipboard(formattedArgoId);
        });
    });
  }

  componentRemoved() {
    this.routedComponent = null;
  }

  onError() {
    this.loading = false;
  }

  private getQueryParamsFromUrl() {
    // Get from url schoolCode, argo_identifier and client_name
    const queryParams = new URLSearchParams(this.document.location.search);
    this.language = queryParams.get(EslQqToolParametersEnum.language);
    this.schoolCode = queryParams.get(EslQqToolParametersEnum.schoolCode);
    this.argoIdentifier = queryParams.get(EslQqToolParametersEnum.argoIdentifier);
    this.clientName = queryParams.get(EslQqToolParametersEnum.clientName);
  }

  private setFormType() {
    this.stepService.setFormType(quoteToolModesTypeMap.get(this.mode));
  }

  private redirectToModePath() {
    void this.router.navigate([quoteToolPaths.get(this.mode)], {
      queryParams: {
        language: this.language,
        schoolCode: this.schoolCode,
        argo_identifier: this.argoIdentifier,
        client_name: this.clientName,
      },
      queryParamsHandling: 'merge',
    });
  }
}
