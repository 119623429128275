import { Component, DEFAULT_CURRENCY_CODE, EventEmitter, Inject, Input, OnChanges, Output, SimpleChanges } from '@angular/core';
import { DESTINATION_CURRENCY_KEY } from '@klg/currency/shared/model/currency.model';
import {QuoteOutput, QuoteTotalLayouts} from '@klg/quote-tool/shared/types';
import { Amount } from '@klg/shared/data-access/types';
import { DateFormat } from '@klg/shared/i18n';

@Component({
  selector: 'kng-quote-total-price',
  templateUrl: './quote-total-price.component.html',
  styleUrls: ['./quote-total-price.component.scss'],
})
export class QuoteTotalPriceComponent implements OnChanges {
  @Input() layout: QuoteTotalLayouts;
  @Input() quote: QuoteOutput;
  @Input() quoteKey = '';
  @Input() activeQuoteSlot = 1;
  @Input() selectedCurrency = this.defaultCurrencyCode;
  @Input() hasCurrencySelector = false;
  @Input() displayDestinationCurrency = false;
  @Input() filterVisibleCurrencies = false;
  @Input() hasDisclaimerSection = false;
  @Input() showDecimals = false;
  @Input() infoDisclaimer: string;
  @Input() showBothCurrencies = false;
  @Output() viewDetails = new EventEmitter<void>();
  @Output() currencyChanged = new EventEmitter<string>();

  public destinationCurrency: string;
  public currency: string;
  public visibleCurrencies: Amount[];
  public QuoteTotalLayouts = QuoteTotalLayouts;
  public decimalPlaces: number;
  public dateFormat = DateFormat.SHORT_MONTH_NAME;

  constructor(@Inject(DEFAULT_CURRENCY_CODE) private defaultCurrencyCode) {}

  ngOnChanges({ quote, selectedCurrency }: SimpleChanges): void {
    if (quote && quote.currentValue && quote.currentValue !== quote.previousValue) {
      this.destinationCurrency = this.quote.input?.destinationCurrency;
      this.visibleCurrencies = this.filterVisibleCurrencies ? this.quote.grandTotal?.filter((amount) => amount.visible) : this.quote.grandTotal;
    }

    if (!this.currency || selectedCurrency?.currentValue) {
      this.currency = (this.selectedCurrency === DESTINATION_CURRENCY_KEY ? this.destinationCurrency : this.selectedCurrency) ?? this.defaultCurrencyCode;
      if (this.visibleCurrencies?.length && !this.visibleCurrencies?.some((amount) => amount.currency === this.currency)) {
        this.currency = this.visibleCurrencies[0]?.currency;
      }
    }

    // quoting in local currency overrides any other setting
    if (this.quote?.singleCurrencyMode) {
      this.currency = this.quote?.marketCurrency;
    }

    this.decimalPlaces = this.showDecimals ? 2 : 0;
  }

  public onCurrencyChange(newCurrency: string) {
    if (this.quote?.singleCurrencyMode) {
      // we do not allow any other currency rather than market currency on single currency mode
      this.currency = this.quote?.marketCurrency;
      return;
    }

    if (this.currency !== newCurrency) {
      this.currency = newCurrency;
      this.currencyChanged.emit(newCurrency);
    }
  }

  public showCurrencyDisclaimer(): boolean {
    return this.displayDestinationCurrency && this.destinationCurrency && this.currency !== this.destinationCurrency;
  }

  public isSelectedSlot(slot: string) {
    return slot === this.activeQuoteSlot.toString();
  }
}
