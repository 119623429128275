import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Resolve, Route, RouterStateSnapshot } from '@angular/router';
import { ROUTE_IDS, StepDefinition } from '@klg/quote-tool/shared/types';
import { ThankYouComponent } from './thank-you.component';

const stepId = ROUTE_IDS.THANK_YOU;

@Injectable({
  providedIn: 'root',
})
export class ThankYouResolver implements Resolve<StepDefinition> {
  private stepDefinition: StepDefinition = {
    stepId,
    previousStep: () => ROUTE_IDS.BOOKING_FORM,
  };

  resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): StepDefinition {
    return this.stepDefinition;
  }
}

export const stepRoute: Route = {
  path: stepId,
  resolve: {
    stepDefinition: ThankYouResolver,
  },
  component: ThankYouComponent,
};
