import { Directive, HostListener, inject, Input } from '@angular/core';
import { FormData } from '@klg/core/forms/form-data.model';
import { NotificationService } from '@klg/shared/logger';

/**
 * Directive that handles the validity of a form data.
 * If the field is focused, it is considered valid.
 * If the field is blurred, it is considered invalid.
 */
@Directive({
  standalone: true,
  selector: '[kngFormDataDropdownValidity]',
})
export class FormDataDropdownValidityDirective<T> {
  @Input() formData: FormData<T> | undefined;

  private readonly notificationService = inject(NotificationService);

  @HostListener('focusin')
  onFocusIn() {
    this.setFormDataValidity(true);
  }

  @HostListener('focusout')
  onFocusOut() {
    this.setFormDataValidity(false);
  }

  /**
   * Set the validity of the form data.
   * If the form data already has value a value, it does nothing.
   * @param validity the validity to set
   * @private
   */
  private setFormDataValidity(validity: boolean) {
    if (!this.formData) {
      this.notificationService.warn('FormDataDropdownValidityDirective - Form data is not defined');
      return;
    }

    if (this.formData.value !== null && this.formData.value !== undefined && this.formData.value !== '') {
      return;
    }
    this.formData.isValid = validity;
  }
}
