export function arrayEquals(a: any[], b: any[]): boolean {
  if (a === b) {
    return true;
  }
  if (a === null || b === null) {
    return false;
  }
  if (a?.length !== b?.length) {
    return false;
  }
  return a?.length === b?.length && a.every((item) => b.includes(item)) && b.every((item) => a.includes(item));
}
