import { inject, Injectable } from '@angular/core';
import { HttpErrorResponse, HttpEvent, HttpHandlerFn, HttpRequest, HttpResponse } from '@angular/common/http';
import { Observable, tap } from 'rxjs';
import { ErrorNotificationService, parseApiError } from '@klg/shared/logger';
import { GoogleAnalyticsErrorsService } from '@klg/shared/google-analytics';
import { WHITELISTED_ERROR_CODES } from './whitelisted-error-codes.token';

/**
 * Service for doing the logic of intercepting http services and when there is an error log it and
 * notify the user
 */
@Injectable({
  providedIn: 'root',
})
export class HttpErrorInterceptorService {
  private readonly errorNotificationService = inject(ErrorNotificationService);
  private readonly googleAnalyticsErrorsService = inject(GoogleAnalyticsErrorsService);
  private readonly whiteListedErrorCodesList = inject(WHITELISTED_ERROR_CODES, { optional: true });

  intercept(request: HttpRequest<unknown>, next: HttpHandlerFn): Observable<HttpEvent<unknown>> {
    const whiteListedErrors = this.whiteListedErrorCodesList || [];
    return next(request).pipe(
      tap({
        next: (event) => {
          if (event instanceof HttpResponse && !event.ok && !whiteListedErrors.includes(event.status)) {
            this.notifyError(new HttpErrorResponse({ status: event.status }));
          }
        },
        error: (error) => {
          if (!whiteListedErrors.includes(error.status)) {
            this.notifyError(error);
          }
        },
      }),
    );
  }

  private notifyError(responseError?: HttpErrorResponse) {
    // Track the error in Google Analytics
    this.googleAnalyticsErrorsService.trackEvent(parseApiError(responseError));

    // Notify the error
    this.errorNotificationService.notifyApiError(
      $localize`API Error`,
      $localize`Oh no! We found an error. Please try again or feel free to contact one of our sales advisors.`,
      responseError,
    );
  }
}
