import { Component, EventEmitter, Input, Output } from '@angular/core';

@Component({
  selector: 'kng-banner',
  templateUrl: './banner.component.html',
  styleUrls: ['./banner.component.scss'],
})
export class BannerComponent {
  @Input() title: string;
  @Input() subtitle: string;
  @Input() url: string;
  @Input() backgroundImage: string;
  @Input() showCta = true;
  @Input() ctaText: string;

  @Output() bannerButtonClick: EventEmitter<void> = new EventEmitter<void>();
}
