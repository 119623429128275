import { inject, Injectable } from '@angular/core';
import { CookieService } from 'ngx-cookie-service';
import {
  CAMPAIGN_URL_CAMPAIGN_PARAM,
  CAMPAIGN_URL_COOKIE,
  CAMPAIGN_URL_MEDIUM_PARAM,
  CAMPAIGN_URL_SOURCE_PARAM,
  LEADS_EXPERIMENT_EVENT_NAME,
  GA_COOKIE,
} from './analytics.constants';
import { QuoteLeadAnalyticsData } from '@klg/quote-tool/shared/types/quote';
import { LeadExperimentCro } from './lead-experiment-cro.type';

declare const window: Window & { dataLayer: Partial<LeadExperimentCro>[] };

@Injectable({
  providedIn: 'root',
})
export class AnalyticsService {
  private readonly cookieService = inject(CookieService);

  constructor() {
    window.dataLayer = window.dataLayer || [];
  }

  public getQuoteLeadAnalyticsData(): QuoteLeadAnalyticsData {
    const sitecatId = `KLG-${Date.now()}`;
    const experimentId = this.getLeadsExperimentId();
    return { sitecatId, ...this.getGaUserId(), ...this.getCampaignData(), experimentId };
  }

  getLeadsExperimentId() {
    const experimentDataLayer = window.dataLayer.find((dL) => dL.event === LEADS_EXPERIMENT_EVENT_NAME);
    if (!experimentDataLayer) {
      return;
    }

    return `${experimentDataLayer.attributes?.variationId}|${experimentDataLayer.attributes?.isHoldback ? 1 : 0}`;
  }

  private getGaUserId() {
    if (!this.cookieService.check(GA_COOKIE)) {
      return {};
    }
    const gaCookie = this.cookieService.get(GA_COOKIE);
    return { gaUserId: gaCookie.split('.').splice(2, 2).join('.') };
  }

  private getCampaignData() {
    if (!this.cookieService.check(CAMPAIGN_URL_COOKIE)) {
      return {};
    }
    const gtmCampaignUrlCookie = this.cookieService.get(CAMPAIGN_URL_COOKIE);
    const url = new URL(decodeURIComponent(gtmCampaignUrlCookie));
    const urlParams = new URLSearchParams(url.search);
    return {
      gaMedium: urlParams.get(CAMPAIGN_URL_MEDIUM_PARAM),
      gaSource: urlParams.get(CAMPAIGN_URL_SOURCE_PARAM),
      gaCampaign: urlParams.get(CAMPAIGN_URL_CAMPAIGN_PARAM),
    };
  }
}
