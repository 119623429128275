import { CompanyMarketConfigApiDto } from '@klg/shared/api/models';

function getValue(config: CompanyMarketConfigApiDto): string | boolean {
  if (config.value === 'false') {
    return false;
  } else if (config.value === 'true') {
    return true;
  } else {
    return config.value ?? '';
  }
}

export function companyMarketConfigUIApiDtoToConfiguration(config: Array<CompanyMarketConfigApiDto>) {
  return Object.fromEntries(config.map((entry) => [entry.varname, getValue(entry)]));
}
