import { Injectable } from '@angular/core';
import { ProductsControllerApiService } from '@klg/shared/api/services';
import { BaseService, CachedRequestResponse, Memoize } from '@klg/shared/utils-http';
import { Observable, of } from 'rxjs';
import { map } from 'rxjs/operators';
import { PackageContext, SchoolPackageDto } from '@klg/quote-tool/shared/types/course';

@Injectable({
  providedIn: 'root',
})
export class PackageService extends BaseService<SchoolPackageDto> {
  protected responseCache = new CachedRequestResponse<SchoolPackageDto[]>(() => of([]), this.cacheSettings.get);

  constructor(private productsApiService: ProductsControllerApiService) {
    super();
  }

  @Memoize((packageContext: PackageContext) =>
    [
      packageContext.productPackage,
      packageContext.quoteDate,
      packageContext.startDate,
      packageContext.endDate,
      packageContext.residenceCountryCode,
      packageContext.units,
    ].join('|'),
  )
  getAllByPackageAndContext(packageContext: PackageContext): Observable<SchoolPackageDto[]> {
    return this.productsApiService.getPackages(
      packageContext.productPackage,
      packageContext.quoteDate,
      packageContext.residenceCountryCode,
      packageContext.units,
      this.locale,
      this.companyHeader,
      packageContext.startDate,
      packageContext.endDate,
    );
  }

  @Memoize((packageContext: PackageContext, camp: string, language: string) =>
    [
      packageContext.productPackage,
      packageContext.quoteDate,
      packageContext.startDate,
      packageContext.endDate,
      packageContext.residenceCountryCode,
      packageContext.units,
      camp,
      language,
    ].join('|'),
  )
  getByPackageContextCampAndLanguage(packageContext: PackageContext, camp: string, language: string): Observable<SchoolPackageDto[]> {
    return this.getAllByPackageAndContext(packageContext).pipe(
      map((packages) => packages.filter((pack) => pack.schoolCode === camp && pack.courseLanguage === language)),
    );
  }

  @Memoize((packageContext: PackageContext, camp: string, packageCode: string) =>
    [
      packageContext.productPackage,
      packageContext.quoteDate,
      packageContext.startDate,
      packageContext.endDate,
      packageContext.residenceCountryCode,
      packageContext.units,
      camp,
      packageCode,
    ].join('|'),
  )
  getByPackageContextCampAndPackage(packageContext: PackageContext, camp: string, packageCode: string): Observable<SchoolPackageDto> {
    return this.getAllByPackageAndContext(packageContext).pipe(
      map((packages) => packages.filter((pack) => pack.schoolCode === camp && pack.code === packageCode)?.[0]),
    );
  }

  protected getResponseCache$(): Observable<CachedRequestResponse<SchoolPackageDto[]>> {
    return of(this.responseCache);
  }
}
