import { Inject, Injectable } from '@angular/core';
import { DOCUMENT } from '@angular/common';
import { CustomEvents, WEBAPP_PREFIX } from '../../types/session-storage.type';

@Injectable({
  providedIn: 'root',
})
export class SessionStorageService {
  constructor(@Inject(DOCUMENT) private document: Document) {}

  get<T>(key: string): T {
    const namespacedKey = `${WEBAPP_PREFIX}${key}`;
    return JSON.parse(sessionStorage.getItem(namespacedKey));
  }
  set<T>(key: string, item: T): void {
    const namespacedKey = `${WEBAPP_PREFIX}${key}`;
    sessionStorage.setItem(namespacedKey, JSON.stringify(item));
    this.notifySessionStorageChange(key);
  }
  delete(key: string): void {
    const namespacedKey = `${WEBAPP_PREFIX}${key}`;
    sessionStorage.removeItem(namespacedKey);
    this.notifySessionStorageChange(key);
  }

  private notifySessionStorageChange(key: string): void {
    const detail = { field: `${WEBAPP_PREFIX}${key}` };
    this.document.dispatchEvent(new CustomEvent(CustomEvents.SESSION_STORAGE_CHANGES, { detail }));
  }
}
