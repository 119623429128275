<div
  class="accommodation-summary__image"
  [ngStyle]="{ backgroundImage: 'url(' + accommodationOptions.thumbnailSmall + ')' }"
  (click)="moreDetailsClicked.emit(); $event.stopImmediatePropagation()"
  [kngGAClickEvent]="GoogleAnalyticsEvents.QUOTE_HIGHLIGHT_ACCOMMODATION_TYPE_INFO"
>
  <div class="accommodation-summary__more-details"></div>
</div>
<div class="accommodation-summary__details">
  <div class="accommodation-summary__name">{{ accommodationOptions.name }}</div>
  <kng-accommodation-options [accommodationOptions]="accommodationOptions"></kng-accommodation-options>
  <div class="accommodation-summary__prices">
    <div class="accommodation-summary__price">
      <span class="accommodation-summary__price-from" i18n>From </span>
      <strong>{{ accommodationOptions.startingPrice | klgPrice }}</strong>
    </div>
    <div class="accommodation-summary__price-label" i18n>Per week</div>
    <div class="accommodation-summary__price-from-old" *ngIf="accommodationOptions.hasPromotion">
      {{ accommodationOptions.oldPrice | klgPrice }}
    </div>
  </div>
  <div class="accommodation-summary__availability" *ngIf="showAvailabilityLabel || accommodationOptions.seasonalDisclaimer">
    <div class="accommodation-summary__availability__label">
      <span class="accommodation-summary__availability__label__text" i18n>Subject to availability</span>
    </div>
  </div>
</div>
<kng-offer-label *ngIf="accommodationOptions.hasPromotion" (showPromotionPopup)="showPromotionPopup(accommodationOptions)"></kng-offer-label>
