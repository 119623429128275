<div
  class="offer-label special-offer"
  (click)="onLabelClicked($event)"
  [kngGAClickEvent]="googleAnalyticsClickEvent"
  *ngIf="enabledPopupPromo; else nonClickableTemplate"
>
  <ng-container *ngTemplateOutlet="offerTemplate"></ng-container>
</div>

<ng-template #nonClickableTemplate>
  <div class="offer-label special-offer">
    <ng-container *ngTemplateOutlet="offerTemplate"></ng-container>
  </div>
</ng-template>

<ng-template #offerTemplate>
  <i class="offer-label__icon"></i>
  <span class="offer-label__text" i18n>Offer available</span>
</ng-template>
