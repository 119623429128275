<div *ngFor="let supplement of product?.supplements?.supplementItems || []">
  <kng-quote-product-label *ngIf="!!supplement.name" [productName]="supplement.name"></kng-quote-product-label>

  <kng-quote-product-description *ngIf="!!supplement.description" [productDescription]="[supplement.description]"></kng-quote-product-description>

  <kng-quote-price-item
    [hasProductPrice]="!supplement.priceOnRequest"
    [productPrice]="supplement.price | klgPrice: { currency: currency, minDecimalPlaces: decimalPlaces }"
  ></kng-quote-price-item>
</div>
