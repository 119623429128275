/**
 * KLG Quotes API
 * This is KLG API to perform quote calculations and retrieve auxiliary pricing information.
 *
 * The version of the OpenAPI document: dev
 * Contact: xxx@kaplan.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

/**
 * The type of room
 */
export type RoomTypeApiDto =
  | 'SINGLE'
  | 'DOUBLE'
  | 'STUDIO'
  | 'GOLD_SINGLE'
  | 'SILVER_SINGLE'
  | 'BRONZE_SINGLE'
  | 'ECONOMY_SINGLE'
  | 'PREMIUM_SINGLE'
  | 'STANDARD_SINGLE'
  | 'STUDIO_DOUBLE';

export const RoomTypeApiDto = {
  SINGLE: 'SINGLE' as RoomTypeApiDto,
  DOUBLE: 'DOUBLE' as RoomTypeApiDto,
  STUDIO: 'STUDIO' as RoomTypeApiDto,
  GOLD_SINGLE: 'GOLD_SINGLE' as RoomTypeApiDto,
  SILVER_SINGLE: 'SILVER_SINGLE' as RoomTypeApiDto,
  BRONZE_SINGLE: 'BRONZE_SINGLE' as RoomTypeApiDto,
  ECONOMY_SINGLE: 'ECONOMY_SINGLE' as RoomTypeApiDto,
  PREMIUM_SINGLE: 'PREMIUM_SINGLE' as RoomTypeApiDto,
  STANDARD_SINGLE: 'STANDARD_SINGLE' as RoomTypeApiDto,
  STUDIO_DOUBLE: 'STUDIO_DOUBLE' as RoomTypeApiDto,
};
