<kng-quote-course-details
  class="quote__course-details"
  [quote]="quote"
  [isOnlineFlow]="isOnlineFlow"
  [areQuoteTypesAdultAndOnline]="areQuoteTypesAdultAndOnline"
  [isQuoteSlotExpanded]="isQuoteSlotExpanded"
  [languageLabel]="languageLabel"
  [languageName]="languageName"
  [schoolLabel]="schoolLabel"
  [destination]="destination"
  [currentSchoolName]="currentSchoolName"
  [currentCourses]="currentCourses"
  [courseDurationLabel]="courseDurationLabel"
  [quoteType]="quote?.input?.quoteType"
  (editButtonClicked)="editCourseDetails($event)"
></kng-quote-course-details>

<kng-quote-subtotal-item
  class="quote__products"
  [currency]="currency"
  [totalItems]="quote?.products?.length"
  [totalLabel]="itemTotalMessage"
  [amountTotal]="quote?.productTotal"
  [decimalPlaces]="decimalPlaces"
  [roundedElements]="roundedElements"
  (expandAccordion)="expandProductAccordions.emit()"
  [isAccordionExpanded]="isProductsAccordionExpanded"
></kng-quote-subtotal-item>

<ng-container *ngFor="let product of getTuitions()">
  <ng-container
    *ngTemplateOutlet="
      productsTemplate;
      context: {
        products: [product],
        nullMessage: courseNullMessage,
        expand: isProductsAccordionExpanded
      }
    "
  ></ng-container>
</ng-container>
<ng-container
  *ngIf="isJuniorsFlow && premiumResidenceAvailable"
  [ngTemplateOutlet]="productsTemplate"
  [ngTemplateOutletContext]="{
    products: quote?.products | productsByType : productTypes.PREMIUM_RESIDENCE,
    productType: productTypes.PREMIUM_RESIDENCE,
    nullMessage: displayNoPremiumResidence ? premiumResidenceNullMessage : '',
    expand: isProductsAccordionExpanded
  }"
></ng-container>
<ng-container
  *ngIf="areQuoteTypesAdultAndOnline || !isOnlineFlow"
  [ngTemplateOutlet]="productsTemplate"
  [ngTemplateOutletContext]="{
    products: quote?.products | transfersByType : transferServiceTypes.ARRIVAL,
    productType: productTypes.TRANSFER,
    nullMessage: displayNoTransfer ? arrivalTransferNullMessage : '',
    expand: isProductsAccordionExpanded
  }"
></ng-container>
<ng-container
  *ngIf="areQuoteTypesAdultAndOnline || !isOnlineFlow"
  [ngTemplateOutlet]="productsTemplate"
  [ngTemplateOutletContext]="{
    products: quote?.products | transfersByType : transferServiceTypes.DEPARTURE,
    productType: productTypes.TRANSFER,
    nullMessage: displayNoTransfer ? departureTransferNullMessage : '',
    expand: isProductsAccordionExpanded
  }"
></ng-container>
<ng-container
  *ngIf="areQuoteTypesAdultAndOnline || !isOnlineFlow"
  [ngTemplateOutlet]="productsTemplate"
  [ngTemplateOutletContext]="{
    products: quote?.products | productsByType : productTypes.INSURANCE,
    productType: productTypes.INSURANCE,
    nullMessage: displayNoInsurance ? insuranceNullMessage : '',
    expand: isProductsAccordionExpanded
  }"
></ng-container>
<ng-container
  *ngIf="isJuniorsFlow"
  [ngTemplateOutlet]="productsTemplate"
  [ngTemplateOutletContext]="{
    products: quote?.products | productsByType : productTypes.PREMIUM_ACTIVITIES,
    productType: productTypes.PREMIUM_ACTIVITIES,
    nullMessage: premiumActivitiesNullMessage,
    expand: isProductsAccordionExpanded
  }"
></ng-container>
<ng-container
  *ngIf="isJuniorsFlow"
  [ngTemplateOutlet]="productsTemplate"
  [ngTemplateOutletContext]="{
    products: quote?.products | productsByType : productTypes.PRIVATE_LESSONS,
    productType: productTypes.PRIVATE_LESSONS,
    nullMessage: privateLessonsNullMessage,
    expand: isProductsAccordionExpanded
  }"
></ng-container>
<!-- TODO: Review if this works-->
<ng-container
  *ngIf="isJuniorsFlow"
  [ngTemplateOutlet]="productsTemplate"
  [ngTemplateOutletContext]="{
    products: quote?.products | unaccompaniedMinorsByType,
    productType: productTypes.TRANSFER,
    nullMessage: unaccompaniedMinorsNullMessage,
    expand: isProductsAccordionExpanded
  }"
></ng-container>
<ng-container *ngIf="!isPackage">
  <ng-container
    *ngIf="areQuoteTypesAdultAndOnline || !isOnlineFlow"
    [ngTemplateOutlet]="productsTemplate"
    [ngTemplateOutletContext]="{
      products: quote?.products | productsByType : productTypes.ACCOMMODATION,
      productType: productTypes.ACCOMMODATION,
      nullMessage: displayNoAccommodation ? accommodationNullMessage : '',
      expand: isProductsAccordionExpanded
    }"
  ></ng-container>
</ng-container>
<ng-container
  *ngIf="activeAgeGroup !== AgeGroups.Junior && virtualInternshipsAvailable"
  [ngTemplateOutlet]="productsTemplate"
  [ngTemplateOutletContext]="{
    products: quote?.products | productsByType : productTypes.VIRTUAL_INTERNSHIP,
    productType: productTypes.VIRTUAL_INTERNSHIP,
    nullMessage: displayNoVirtualInternship ? virtualInternshipNullMessage : '',
    expand: isProductsAccordionExpanded
  }"
></ng-container>

<ng-container
  *ngIf="availableOptOutProduct"
  [ngTemplateOutlet]="productsTemplate"
  [ngTemplateOutletContext]="{
    products: quote?.products | productsByType : productTypes.OPT_OUT,
    productType: productTypes.OPT_OUT,
    nullMessage: NA,
    expand: isProductsAccordionExpanded
  }"
></ng-container>

<ng-container
  *ngIf="areQuoteTypesAdultAndOnline || !isOnlineFlow"
  [ngTemplateOutlet]="productsTemplate"
  [ngTemplateOutletContext]="{
    products: fees,
    productType: productTypes.FEE,
    nullMessage: feeNullMessage,
    expand: isProductsAccordionExpanded
  }"
></ng-container>

<ng-container *ngFor="let product of [].concat(otherProducts || [], [].constructor(remainingOtherProductsLength))">
  <ng-container *ngTemplateOutlet="productsTemplate; context: { products: [product], expand: isProductsAccordionExpanded }"></ng-container>
</ng-container>

<ng-container *ngIf="discountsStack || quote?.discounts?.length">
  <kng-quote-discount-details
    class="quote__discounts"
    [quoteKey]="quoteKey"
    [quoteOutputsExpirationDates]="quoteOutputsExpirationDates"
    [activeQuoteSlot]="activeQuoteSlot"
    [discounts]="quote?.discounts"
    [discountTotal]="quote?.discountTotal"
    [currency]="currency"
    [decimalPlaces]="decimalPlaces"
    [isDiscountsAccordionExpanded]="isDiscountsAccordionExpanded"
    [roundedElements]="roundedElements"
    [remainingDiscountsLength]="remainingDiscountsLength"
    (expandDiscountAccordions)="expandDiscountAccordions.emit()"
  ></kng-quote-discount-details>
</ng-container>

<kng-quote-price-checklist
  *ngIf="keyPoints?.included?.length"
  [icon]="KeyPointIcons.INCLUDED"
  [label]="includedMessage"
  [currency]="currency"
  [keyPoints]="keyPoints.included"
  [decimalPlaces]="decimalPlaces"
></kng-quote-price-checklist>

<kng-quote-price-checklist
  *ngIf="keyPoints?.notIncluded?.length"
  [icon]="KeyPointIcons.EXCLUDED"
  [label]="notIncludedMessage"
  [currency]="currency"
  [keyPoints]="keyPoints.notIncluded"
  [decimalPlaces]="decimalPlaces"
></kng-quote-price-checklist>

<kng-quote-total-price
  [ngClass]="totalClass"
  [quote]="quote"
  [quoteKey]="quoteKey"
  [activeQuoteSlot]="activeQuoteSlot"
  [selectedCurrency]="currency"
  [hasCurrencySelector]="hasCurrencySelector"
  [filterVisibleCurrencies]="filterVisibleCurrencies"
  [displayDestinationCurrency]="displayDestinationCurrency"
  [hasDisclaimerSection]="hasDisclaimerSection"
  [infoDisclaimer]="confirmationDisclaimer"
  [showDecimals]="!hideDecimals"
  [showBothCurrencies]="depositsIsEnabled"
  (currencyChanged)="onCurrencyChange($event)"
></kng-quote-total-price>

<div class="quote__cta" *ngIf="showCta">
  <kng-button
    class="quote__button-email"
    [disabled]="disabledCta"
    [block]="true"
    buttonStyle="primary"
    [btnRounded]="roundedElements"
    data-qa="enrolment-mail-quotes"
    (buttonClick)="submitQuote.emit()"
    *ngIf="!(depositsIsEnabled && displayEnrolmentButton); else displayEnrolNow"
    [kngGAClickEvent]="googleAnalyticsEmailQuoteEvent"
  >
    <div class="quote__button-email-header">{{ ctaButtonHeaderText }}</div>
    <div class="quote__button-email-subheader">{{ ctaButtonSubheaderText }}</div>
  </kng-button>
  <ng-template #displayEnrolNow>
    <kng-button
      class="quote__button-enrol"
      [block]="true"
      buttonStyle="primary"
      [btnRounded]="roundedElements"
      buttonId="enrol-now-1"
      data-qa="enrolment-enrol-now"
      i18n-text
      text="Enrol now"
      (buttonClick)="triggerEnrolment()"
      [kngGAClickEvent]="googleAnalyticsEnrolNowEvent"
    ></kng-button>
  </ng-template>
</div>

<div class="quote__actions" *ngIf="hasEditButton || hasRemoveButton">
  <kng-button
    *ngIf="hasEditButton"
    i18n-text
    text="Edit"
    [svg]="modifyQuoteType.Edit"
    [style]="{ marginInlineEnd: '10px' }"
    [hasText]="isQuoteSlotExpanded"
    [svgColor]="'primary'"
    [btnRounded]="true"
    [uppercase]="true"
    [buttonStyle]="'quaternary'"
    (buttonClick)="modifyQuote.emit(modifyQuoteType.Edit)"
    [kngGAClickEvent]="googleAnalyticsEditQuoteEvent"
  >
  </kng-button>
  <kng-button
    *ngIf="hasRemoveButton"
    i18n-text
    text="Remove"
    [svg]="modifyQuoteType.Remove"
    [hasText]="isQuoteSlotExpanded"
    [svgColor]="'primary'"
    [btnRounded]="true"
    [uppercase]="true"
    [buttonStyle]="'quaternary'"
    (buttonClick)="modifyQuote.emit(modifyQuoteType.Remove)"
    [kngGAClickEvent]="googleAnalyticsRemoveQuoteEvent"
  >
  </kng-button>
</div>

<ng-template #productsTemplate let-products="products" let-nullMessage="nullMessage" let-expand="expand" let-productType="productType">
  <div class="quote__products-accordion" *ngIf="(products?.length || nullMessage) && expand" @accordionAnimation>
    <ng-container *ngFor="let product of products">
      <kng-quote-product-details
        [product]="product"
        [quoteKey]="quoteKey"
        [currency]="currency"
        [countryCode]="quote.input.countryCode"
        [decimalPlaces]="decimalPlaces"
        [currentCourses]="currentCourses"
        [isQuoteSlotExpanded]="isQuoteSlotExpanded"
        [isAccommodationMandatory]="isAccommodationMandatory"
        (infoButtonClicked)="openPopup.emit({ productType, productOptions: product })"
        (editButtonClicked)="openPopup.emit({ productType, productOptions: product })"
        (removeButtonClicked)="removeQuote.emit({ productType, productOptions: product })"
      ></kng-quote-product-details>
    </ng-container>
    <div *ngIf="!products?.length && nullMessage" class="quote__empty">
      <div class="quote__label--empty">{{ nullMessage }}</div>
      <kng-quote-cta-button
        *ngIf="
          isQuoteSlotExpanded &&
          this.quote?.input?.quoteType !== AgeGroups.Online &&
          (productType !== productTypes.PREMIUM_RESIDENCE || premiumResidenceAddable) &&
          (productType !== productTypes.VIRTUAL_INTERNSHIP || virtualInternshipsAddable) &&
          productType !== productTypes.OPT_OUT
        "
        (buttonClicked)="openPopup.emit({ productType: productType })"
      ></kng-quote-cta-button>
    </div>
  </div>
</ng-template>
