<kng-quote-subtotal-item
  class="quote-discount-details__item"
  i18n-totalLabel
  totalLabel="Discount Total"
  [quoteKey]="quoteKey"
  [activeQuoteSlot]="activeQuoteSlot"
  [quoteOutputsExpirationDates]="quoteOutputsExpirationDates"
  [amountTotal]="discountTotal"
  [currency]="currency"
  [decimalPlaces]="decimalPlaces"
  [totalItems]="discounts?.length"
  [closestExpirationDate]="closestExpirationDate"
  [isAccordionExpanded]="isDiscountsAccordionExpanded"
  [roundedElements]="roundedElements"
  [isDiscount]="true"
  (expandAccordion)="expandDiscountAccordions.emit()"
></kng-quote-subtotal-item>

<ng-container *ngFor="let discount of [].concat(discounts || [], [].constructor(remainingDiscountsLength).fill(undefined))">
  <ng-container *ngTemplateOutlet="discountTemplateAccordion; context: { discount, expand: isDiscountsAccordionExpanded }"></ng-container>
</ng-container>

<ng-template #discountTemplateAccordion let-item="discount" let-expand="expand">
  <div class="quote-discount-details__item quote-discount-item quote-discount-item--name" *ngIf="expand" @accordionAnimation>
    <ng-container *ngIf="discount(item)">
      <div class="quote-discount-item__name">
        {{ discount(item).name }}
      </div>
    </ng-container>
  </div>

  <div class="quote-discount-details__item quote-discount-item" *ngIf="expand" @accordionAnimation>
    <ng-container *ngIf="discount(item)">
      <kng-time-left
        [quoteKey]="quoteKey"
        [expirationDate]="discount(item).expirationDate"
        [activeQuoteSlot]="activeQuoteSlot"
        [quoteOutputsExpirationDates]="quoteOutputsExpirationDates"
      ></kng-time-left>
    </ng-container>
  </div>

  <div class="quote-discount-details__item quote-discount-item" *ngIf="expand" @accordionAnimation>
    <ng-container *ngIf="discount(item)">
      <div *ngIf="discount(item).discount !== null" class="quote-discount-item__discount">
        {{ discount(item).discount | klgPrice : { currency: currency, minDecimalPlaces: decimalPlaces, multiplier: -1 } }}<br />
      </div>
    </ng-container>
    <ng-container *ngIf="!discount(item)">
      <div class="quote-discount-item__empty" i18n>N/A</div>
    </ng-container>
  </div>
</ng-template>
