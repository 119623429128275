import { Component, EventEmitter, OnChanges, Output } from '@angular/core';
import { emailValidator, phoneValidator } from '@klg/core/validators';
import { FormSection } from '@klg/quote-tool/shared/ui/directives';
import { ContactPersonSectionPayload, FormFieldObject } from '@klg/quote-tool/shared/types';

@Component({
  selector: 'kng-contact-person-section',
  templateUrl: './contact-person.component.html',
  styleUrls: ['./contact-person.component.scss'],
})
export class ContactPersonSectionComponent extends FormSection<ContactPersonSectionPayload> implements OnChanges {
  @Output() formChanges = new EventEmitter<keyof ContactPersonSectionPayload>();

  public sectionTitle: string;

  ngOnChanges() {
    this.sectionTitle = this.isAdultSchool() ? $localize`Emergency contact details` : $localize`Parents or guardian/legal representative`;
  }

  protected getFormFields(): { [K in keyof ContactPersonSectionPayload]: FormFieldObject<ContactPersonSectionPayload[K]> } {
    return {
      legalRepresentativeFirstName: {
        value: '',
        validator: (firstName) => firstName.length < 80,
        errorMessage: $localize`Please enter a valid first name`,
        required: true,
      },
      legalRepresentativeLastName: {
        value: '',
        validator: (lastName) => lastName.length < 80,
        errorMessage: $localize`Please enter a valid last name`,
        required: true,
      },
      legalRepresentativeEmail: {
        value: '',
        validator: (email: string) => emailValidator(email),
        errorMessage: $localize`Please enter a valid email`,
        required: true,
      },
      legalRepresentativeAddress: {
        value: null,
        validator: (address: string) => address.length < 80,
        errorMessage: $localize`Please enter a valid address`,
        required: false,
      },
      legalRepresentativeMobile: {
        value: null,
        validator: (mobile) => phoneValidator(mobile),
        errorMessage: $localize`Please enter a valid mobile number`,
        required: true,
      },
      contactPersonFirstName: {
        value: null,
        validator: (firstName) => firstName?.length <= 80,
        errorMessage: $localize`Please enter a valid name`,
        required: true,
      },
      contactPersonLastName: {
        value: null,
        validator: (lastname) => lastname?.length <= 80,
        errorMessage: $localize`Please enter a valid lastname`,
        required: true,
      },
      contactPersonPhone: {
        value: null,
        validator: (phone: string) => phoneValidator(phone),
        errorMessage: $localize`Please enter a valid mobile`,
        required: true,
      },
    };
  }

  public onValueChanges(key: keyof ContactPersonSectionPayload): void {
    this.formData[key].validate();
    this.storeStudentDetails({ duringYourStay: { ...this.getStudentDetails()?.duringYourStay, ...this.getFormValues() } });
  }

  protected getFieldsValues(fieldKeys: Array<keyof ContactPersonSectionPayload>): Partial<ContactPersonSectionPayload> {
    const values: Partial<ContactPersonSectionPayload> = {};

    fieldKeys.forEach((key: keyof ContactPersonSectionPayload) => {
      if (this.getFieldValueIfValid(key) !== undefined || this.getFormFields()[key].required) {
        values[key] = this.getFieldValueIfValid(key);
      }
    });

    return values;
  }
}
