<kng-form-field-selector
  [label]="label"
  [disabled]="disabled"
  [value]="value"
  valueExpr="code"
  [itemList]="itemList"
  [buttonView]="buttonView"
  [placeholder]="placeholder"
  [isOpen]="isOpen"
  [required]="required"
  [sortCriteria]="sortCriteria"
  [iconValidation]="true"
  (valueChange)="valueChange.emit($event)"
  (itemChange)="itemChange.emit($event)"
  [autofill]="autofill"
></kng-form-field-selector>
