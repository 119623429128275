import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { CoreComponentsModule } from '@klg/core/components';
import { DatePipe, OptionalPriceDurationPipe, PricePipe } from '@klg/shared/ui/pipes';
import { CourseSupplementComponent } from './course-supplement/course-supplement.component';
import { CourseTypeSelectorComponent } from './course-type-selector/course-type-selector.component';
import { ProgramTypeSelectorComponent } from './program-type-selector/program-type-selector.component';
import { DetailsPopupComponent } from '../details-popup/details-popup.component';
import { FormFieldSelectorComponent } from '../form-field-selector/form-field-selector.component';
import { ItemCardComponent } from '../item-card/item-card.component';
import { OfferLabelComponent } from '../offer-label/offer-label.component';

@NgModule({
  declarations: [CourseSupplementComponent, CourseTypeSelectorComponent, ProgramTypeSelectorComponent],
  imports: [
    CommonModule,
    CoreComponentsModule,
    PricePipe,
    DatePipe,
    OfferLabelComponent,
    OptionalPriceDurationPipe,
    DetailsPopupComponent,
    FormFieldSelectorComponent,
    ItemCardComponent,
  ],
  providers: [DatePipe],
  exports: [CourseSupplementComponent, CourseTypeSelectorComponent, ProgramTypeSelectorComponent],
})
export class CourseComponentsModule {}
