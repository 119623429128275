import { Company } from '@klg/shared/types';
import { getCompany } from './get-company.function';

const COMPANY_HEADER_MAP = {
  kaplan: 'KIL',
  alpadia: 'ALP',
  esl: 'ESL',
  ksg: 'KSG',
} satisfies Record<Company, 'KIL' | 'ALP' | 'ESL' | 'KSG'>;

export const getCompanyHeader = (company: Company = getCompany()) => COMPANY_HEADER_MAP[company];
