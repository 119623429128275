/**
 * KLG Quotes API
 * This is KLG API to perform quote calculations and retrieve auxiliary pricing information.
 *
 * The version of the OpenAPI document: dev
 * Contact: xxx@kaplan.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { FixedDurationApiDto } from './fixed-duration.model';
import { CourseArticleApiDto } from './course-article.model';
import { CourseTypeApiDto } from './course-type.model';
import { LessonApiDto } from './lesson.model';
import { PriceItemApiDto } from './price-item.model';
import { ScheduleApiDto } from './schedule.model';
import { CourseLevelApiDto } from './course-level.model';
import { ChildCourseTypeApiDto } from './child-course-type.model';
import { AmountApiDto } from './amount.model';
import { CodeAndNameApiDto } from './code-and-name.model';
import { CourseDatesApiDto } from './course-dates.model';

export interface CourseApiDto {
  fixedDurations?: Array<FixedDurationApiDto>;
  ageDescription?: string;
  isWeeklyCourse?: boolean;
  sessionDates?: Array<CourseDatesApiDto>;
  school?: CodeAndNameApiDto;
  disciplines?: Array<CodeAndNameApiDto>;
  studyModes?: Array<CodeAndNameApiDto>;
  academicLevels?: Array<CodeAndNameApiDto>;
  learningModes?: Array<CodeAndNameApiDto>;
  specialisations?: Array<CodeAndNameApiDto>;
  schoolIcon?: string;
  availableInternational?: boolean;
  courseDuration?: string;
  fixedCourseStartDates?: Array<string>;
  code?: string;
  name?: string;
  description?: string;
  thumbnailSmall?: string;
  type?: CourseTypeApiDto;
  childType?: ChildCourseTypeApiDto;
  lesson?: LessonApiDto;
  supportedLevels?: Array<CourseLevelApiDto>;
  accommodationMandatory?: boolean;
  /**
   * Current available year
   */
  schoolYear?: number;
  /**
   * All available years
   */
  schoolYears?: Array<number>;
  providerName?: string;
  durationMin?: number;
  durationMax?: number;
  durationUnit?: CourseApiDto.DurationUnitApiDtoEnum;
  studentAgeMin?: number;
  studentQtyMax?: number;
  courseDates?: Array<CourseDatesApiDto>;
  examDates?: Array<CourseDatesApiDto>;
  schedules?: Array<ScheduleApiDto>;
  samplePrice?: AmountApiDto;
  internationalPrice?: AmountApiDto;
  priceTable?: Array<PriceItemApiDto>;
  courseArticles?: Array<CourseArticleApiDto>;
  quotable?: boolean;
  pageUrl?: string;
  /**
   * Custom element weigh. This property is used to define a custom sort order of this element.
   */
  weight?: number;
  productPosition?: number;
  /**
   * Codes of connected products
   */
  relatedProducts?: Array<string>;
}
export namespace CourseApiDto {
  export type DurationUnitApiDtoEnum = 'WEEKS' | 'MONTH' | 'DAYS' | 'YEARS' | 'EACH';
  export const DurationUnitApiDtoEnum = {
    WEEKS: 'WEEKS' as DurationUnitApiDtoEnum,
    MONTH: 'MONTH' as DurationUnitApiDtoEnum,
    DAYS: 'DAYS' as DurationUnitApiDtoEnum,
    YEARS: 'YEARS' as DurationUnitApiDtoEnum,
    EACH: 'EACH' as DurationUnitApiDtoEnum,
  };
}
