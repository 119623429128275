import { Component, HostBinding, Input } from '@angular/core';
import { ButtonStyle } from '../types';

@Component({
  selector: 'kng-loading-icon-svg',
  standalone: true,
  templateUrl: './loading-icon.component.svg',
  styleUrls: ['./loading-icon.component.scss'],
})
export class LoadingIconSvgComponent {
  @HostBinding('class') get classes(): string {
    const hostClass = 'klg-loading-icon-svg';
    const styleClass = this.iconStyle ? `${hostClass}--${this.iconStyle.toLowerCase()}` : '';

    return `${hostClass} ${styleClass}`.trim();
  }

  @Input() iconStyle: ButtonStyle = 'primary';
}
