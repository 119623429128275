/**
 * KLG Quotes API
 * This is KLG API to perform quote calculations and retrieve auxiliary pricing information.
 *
 * The version of the OpenAPI document: dev
 * Contact: xxx@kaplan.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

/**
 * The meals included
 */
export type MealOptionsApiDto = 'BED_BREAKFAST' | 'HALF_BOARD' | 'MEAL_PLAN' | 'NO_MEALS';

export const MealOptionsApiDto = {
  BED_BREAKFAST: 'BED_BREAKFAST' as MealOptionsApiDto,
  HALF_BOARD: 'HALF_BOARD' as MealOptionsApiDto,
  MEAL_PLAN: 'MEAL_PLAN' as MealOptionsApiDto,
  NO_MEALS: 'NO_MEALS' as MealOptionsApiDto,
};
