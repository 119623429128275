import { Injectable } from '@angular/core';
import { QuoteCamp } from '@klg/quote-tool/shared/types/school';
import { ProductsControllerApiService } from '@klg/shared/api/services';
import { getToday } from '@klg/shared/utils';
import { Observable, of } from 'rxjs';
import { map } from 'rxjs/operators';
import { ProductPackageEnum } from '@klg/quote-tool/shared/types';
import { BaseService, CachedRequestResponse } from '@klg/shared/utils-http';

@Injectable({ providedIn: 'root' })
export class QuoteCampService extends BaseService<QuoteCamp> {
  private responseCache = new CachedRequestResponse<QuoteCamp[]>(
    () => this.productsApiService.getCampsV3(ProductPackageEnum.YOUNG_LEARNER_CAMPS, this.locale, this.companyHeader),
    this.cacheSettings.get,
  );

  private today = getToday().toISOString().split('T')[0];

  constructor(private productsApiService: ProductsControllerApiService) {
    super();
  }

  getCountryCodesForLanguage(languageCode: string): Observable<string[]> {
    return this.getAll().pipe(
      map((camps) => {
        return camps
          .filter((camp) => this.filterCampsByDate(camp))
          .filter((camp) => this.filterCampsByLanguage(camp, languageCode))
          .map((camp) => camp.country);
      }),
    );
  }

  getAllByCountryAndLanguage(countryCode: string, languageCode: string): Observable<QuoteCamp[]> {
    return this.getAll().pipe(
      map((camps) => {
        return camps.filter((camp) => this.filterCampsByDate(camp) && camp.country === countryCode && this.filterCampsByLanguage(camp, languageCode));
      }),
    );
  }

  getBySchoolCode(code: string): Observable<QuoteCamp> {
    return this.getAll().pipe(map((camps) => camps.find((s) => s.code === code)));
  }

  protected getResponseCache$(): Observable<CachedRequestResponse<QuoteCamp[]>> {
    return of(this.responseCache);
  }

  private filterCampsByLanguage(camp: QuoteCamp, languageCode: string): boolean {
    return camp.courseLanguages.some((language) => language.code === languageCode);
  }

  private filterCampsByDate(camp: QuoteCamp): boolean {
    return camp.dates?.length > 0 && camp.dates.some((campDates) => campDates.startDate > this.today);
  }
}
