import { Component, inject, OnDestroy, OnInit } from '@angular/core';
import { QuoteInput, QuoteOutput, StudentDetails } from '@klg/quote-tool/shared/types/quote';
import { FormType } from '@klg/quote-tool/shared/types';
import { combineLatest, EMPTY, of, Subscription, throwError } from 'rxjs';
import { catchError, first, switchMap, tap } from 'rxjs/operators';
import { StepService } from '@klg/quote-tool/shared/services/step-service';
import { isStepBookingFormValid, isStepQuoteFormValid } from '../../../shared/functions/step-validation.function';
import { HttpErrorResponse, HttpStatusCode } from '@angular/common/http';
import { FormLeadsService } from '../../../shared/services/form-leads.service';

@Component({
  selector: 'kng-send-quote-to-email',
  templateUrl: './send-quote-to-email.component.html',
  styleUrls: ['./send-quote-to-email.component.scss'],
})
export class SendQuoteToEmailComponent implements OnInit, OnDestroy {
  private subscription = new Subscription();

  private readonly leadsService = inject(FormLeadsService);

  constructor(private stepService: StepService) {}

  ngOnInit() {
    this.subscription.add(
      combineLatest([this.stepService.quoteRequest$, this.stepService.studentDetails$, this.stepService.requestActiveQuote()])
        .pipe(
          tap(([quoteInput, studentDetails]: [QuoteInput, StudentDetails, QuoteOutput]) => {
            const formType = this.stepService.getFormType();
            const validationExpr =
              isStepQuoteFormValid(quoteInput, studentDetails) && (formType !== FormType.ENROLLMENT || isStepBookingFormValid(quoteInput, studentDetails));
            if (!validationExpr) {
              throwError(() => $localize`Please fill all required data`);
            }
          }),
          switchMap(([, , { quoteId }]: [QuoteInput, StudentDetails, QuoteOutput]) =>
            this.leadsService.createLead(quoteId).pipe(
              catchError((error) => {
                // KLG-8086: We check if we receive an error from the API. If it's a 409 it means it's a duplicated request so we have to let the user continue. Undo this dirty patch once the root cause has been discovered.
                if (!(error instanceof HttpErrorResponse) || (error as HttpErrorResponse).status !== HttpStatusCode.Conflict) {
                  return throwError(error);
                }
                // It's a 409 error, so we return an Empty observable to avoid going through the error path.
                return of(EMPTY);
              }),
            ),
          ),
          first(),
        )
        .subscribe({
          next: () => {
            this.stepService.navigateToNextStep();
          },
          error: (error: Error) => {
            this.stepService.navigateToErrorStep(error);
          },
        }),
    );
  }

  ngOnDestroy() {
    this.subscription.unsubscribe();
  }
}
