import { CourseTypeApiDto } from '@klg/shared/api/models';

export type CourseType = CourseTypeApiDto & {
  hasPromotion?: boolean;
};

export enum CourseTypeCodesEnum {
  BUSINESS = 'BUSINESS',
  EXAM_PREPARATION = 'EXAM',
  FLEXIBLE = 'FLEXIBLE',
  JUNIORS = 'JUNIORS',
  LONG_TERM = 'LONGTERM',
  ONLINE = 'ONLINE',
  ONLINE_EXAM = 'ONLINE_EXAM',
  ONLINE_LIVE_PRACTICE = 'ONLINE_LIVE_PRACTICE',
  TEACHER_TRAINING = 'TEACHER',
}
export type CourseTypeCodes =
  | CourseTypeCodesEnum.BUSINESS
  | CourseTypeCodesEnum.EXAM_PREPARATION
  | CourseTypeCodesEnum.FLEXIBLE
  | CourseTypeCodesEnum.JUNIORS
  | CourseTypeCodesEnum.LONG_TERM
  | CourseTypeCodesEnum.ONLINE
  | CourseTypeCodesEnum.ONLINE_EXAM
  | CourseTypeCodesEnum.ONLINE_LIVE_PRACTICE
  | CourseTypeCodesEnum.TEACHER_TRAINING;

export const CourseTypeIcons: Record<CourseTypeCodesEnum, string> = {
  [CourseTypeCodesEnum.BUSINESS]: '/assets/images/icons/line-course-business.svg',
  [CourseTypeCodesEnum.EXAM_PREPARATION]: '/assets/images/icons/line-course-exam.svg',
  [CourseTypeCodesEnum.FLEXIBLE]: '/assets/images/icons/line-course-flexible.svg',
  [CourseTypeCodesEnum.JUNIORS]: '/assets/images/icons/line-course-juniors.svg',
  [CourseTypeCodesEnum.LONG_TERM]: '/assets/images/icons/line-course-longterm.svg',
  [CourseTypeCodesEnum.ONLINE]: '/assets/images/icons/line-course-online.svg',
  [CourseTypeCodesEnum.ONLINE_EXAM]: '/assets/images/icons/line-course-online.svg',
  [CourseTypeCodesEnum.ONLINE_LIVE_PRACTICE]: '/assets/images/icons/line-course-online.svg',
  [CourseTypeCodesEnum.TEACHER_TRAINING]: '/assets/images/icons/line-course-teachers.svg',
};
