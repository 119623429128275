<p-dialog
  [rtl]="true"
  [modal]="true"
  [style]="{ 'min-height': (isMobile$ | async) === true ? '100%' : '600px', width: '600px' }"
  (onHide)="closePopup()"
  [closable]="true"
  [draggable]="false"
  [(visible)]="isPopupOpen"
  [showHeader]="true"
  [closeOnEscape]="true"
  [dismissableMask]="true"
>
  <p-galleria *ngIf="images?.length" [value]="images" [showThumbnails]="false" [showItemNavigators]="images?.length > 1" [showThumbnailNavigators]="false">
    <ng-template pTemplate="item" let-image>
      <img alt="Image" class="details-popup__gallery" [src]="image" />
    </ng-template>
  </p-galleria>

  <div class="details-popup__content">
    <ng-content></ng-content>
  </div>

  <div *ngIf="showButton">
    <kng-button
      class="details-popup__button"
      *ngIf="!isJuniorCourseQuoteSummary"
      [buttonStyle]="secondaryState ? 'popup-remove' : isJuniors ? 'juniors' : 'adults'"
      [btnRounded]="true"
      (buttonClick)="clickPopupButton()"
      [kngGAClickEvent]="googleAnalyticsClickEvent"
    >
      {{ buttonText }}
    </kng-button>
  </div>
</p-dialog>
