import { Component, Input } from '@angular/core';

@Component({
  standalone: true,
  selector: 'kng-urgency-driver-label',
  templateUrl: './urgency-driver-label.component.html',
  styleUrls: ['./urgency-driver-label.component.scss'],
})
export class UrgencyDriverLabelComponent {
  @Input() hasIcon = true;
  @Input() urgencyDriverTitle = '';
  @Input() urgencyDriverDescription = '';
  @Input() urgencyDriverDate = '';
  @Input() isCourseSelector = false;
  @Input() isQuoteSummary = false;
}
