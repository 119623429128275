import { Component, inject, Input, OnDestroy, OnInit } from '@angular/core';
import {
  CourseComponentsModule,
  DatePickerFieldComponent,
  DatePickerFieldDate,
  DestinationComponentsModule,
  FormFieldSelectorComponent,
  LanguageComponentsModule,
  WeeksSelectorComponent,
} from '@klg/quote-tool/shared/ui/components';
import { AgeGroup, QuoteInput, QuoteSchool, StudentDetails } from '@klg/quote-tool/shared/types';
import { City, CityExtended, Country, CourseType } from '@klg/shared/data-access/types';
import { CityDataService, CountryDataService } from '@klg/shared/data-access/destination';
import { BehaviorSubject, combineLatest, Observable, Subject, takeUntil } from 'rxjs';
import { deepClone, DEFAULT_WEEKS_RANGE, getToday } from '@klg/shared/utils';
import { ActivatedRoute } from '@angular/router';
import { AsyncPipe, NgIf } from '@angular/common';
import { StepService } from '@klg/quote-tool/shared/services';
import { getAvailableCourseTypes } from '@klg/quote-tool/shared/utils/school';
import { LoadingSpinnerComponent } from '@klg/shared/ui/loading-spinner';
import { SectionTemplateComponent } from '../../../../sections/section-template/section-template.component';
import { FormFieldComponent } from '@klg/shared/ui/form-field';
import { InputTextareaModule } from 'primeng/inputtextarea';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { retrieveAccommodationTypes } from '../../../../../shared/functions/accommodation.functions';
import { NumericCodeAndName } from '@klg/shared/types';
import { PriceRequestExperimentStore } from '../../../../../store/store';
import { switchMap } from 'rxjs/operators';
import { PriceRequestExperimentService } from '../../../../../experiments/price-request-experiment.service';

@Component({
  standalone: true,
  selector: 'kng-price-request-course-form',
  templateUrl: './price-request-course-form.component.html',
  styleUrls: ['./price-request-course-form.component.scss'],
  imports: [
    LanguageComponentsModule,
    DestinationComponentsModule,
    CourseComponentsModule,
    NgIf,
    LoadingSpinnerComponent,
    SectionTemplateComponent,
    WeeksSelectorComponent,
    DatePickerFieldComponent,
    AsyncPipe,
    FormFieldComponent,
    FormFieldSelectorComponent,
    InputTextareaModule,
    ReactiveFormsModule,
    FormsModule,
  ],
})
export class PriceRequestCourseFormComponent implements OnInit, OnDestroy {
  // Number of columns of the form, by default it will be 2 just to be as similar as the
  // original price request form
  @Input() columns = 2;

  // The form title (it could be empty)
  @Input() title = '';

  quoteInput$: Observable<QuoteInput> | undefined;
  specialRequests: string | undefined;
  selectedAccommodationType = <NumericCodeAndName>{};

  availableCountries: Country[] = [];
  availableCities: CityExtended[] = [];
  availableCourseTypes: string[];
  availableWeeks: number[];
  accommodationTypes = retrieveAccommodationTypes();

  ageGroup: AgeGroup | undefined = undefined;

  loading = false;

  // Min date for selecting will be today (it is the same behaviour as it is in the normal variant)
  minDate = getToday();

  isStepValid$ = new BehaviorSubject(false);
  experimentDActive = false;

  private allSchools: QuoteSchool[] = [];
  private destroySubscription$: Subject<void> = new Subject<void>();
  private closeSubscriptions$: Subject<void> = new Subject<void>();

  private selectedLanguage = '';

  private stopLoadingFromStore$: Subject<void> = new Subject<void>();

  private readonly route = inject(ActivatedRoute);
  private readonly stepService = inject(StepService);
  private readonly countryService = inject(CountryDataService);
  private readonly cityService = inject(CityDataService);
  private readonly priceRequestStore = inject(PriceRequestExperimentStore);
  private readonly priceRequestExperimentService = inject(PriceRequestExperimentService);

  ngOnInit(): void {
    this.quoteInput$ = this.priceRequestStore.quoteInput$;

    // Load data from store
    combineLatest([this.priceRequestStore.quoteInput$, this.priceRequestStore.studentDetails$])
      .pipe(takeUntil(this.stopLoadingFromStore$))
      .subscribe(([quoteInput, studentDetails]: [QuoteInput, StudentDetails]) => {
        this.specialRequests = studentDetails?.specialRequests || '';
        this.selectedAccommodationType = this.accommodationTypes.find((aT) => aT.name === quoteInput?.accommodationType) || <NumericCodeAndName>{};
        this.stopLoadingFromStore$.next();
      });

    this.priceRequestStore.destinationCountries$
      .pipe(takeUntil(this.closeSubscriptions$))
      .subscribe((destinationCountries) => (this.availableCountries = destinationCountries || []));

    this.priceRequestStore.destinationCities$
      .pipe(takeUntil(this.closeSubscriptions$))
      .subscribe((destinationCities) => (this.availableCities = destinationCities || []));

    this.priceRequestStore.courseTypes$.pipe(takeUntil(this.closeSubscriptions$)).subscribe((courseTypes) => (this.availableCourseTypes = courseTypes || []));

    this.allSchools = deepClone(this.route.snapshot.data?.data?.schools || []);
    this.ageGroup = this.stepService.getActiveAgeGroup();

    // Fill with the list of available weeks
    this.availableWeeks = [...Array(DEFAULT_WEEKS_RANGE.MAX_WEEKS).keys()].map((i) => i + 1);

    this.experimentDActive = this.priceRequestExperimentService.isPriceRequestExperimentDActive();
  }

  ngOnDestroy(): void {
    this.closeSubscriptions$.next();
  }

  processSelectedLanguageChange(language: string) {
    this.selectedLanguage = language;

    // Update the selected language in store and reset country, city and course related fields
    this.priceRequestStore.updateQuoteInput({
      languageCode: language,
      country: undefined,
      countryCode: undefined,
      destinationCity: undefined,
      courseType: undefined,
      tuitionWeeks: undefined,
      startDate: undefined,
    });

    this.priceRequestStore.quoteLanguage$
      .pipe(
        switchMap((languageCode: string) => this.countryService.getAllCountriesWithParameters({ courseLanguageCode: languageCode })),
        takeUntil(this.destroySubscription$),
      )
      .subscribe((countries: Country[]) => {
        this.loading = false;
        this.destroySubscription$.next();
        this.availableCountries = countries;
        this.priceRequestStore.updateDestinationCountries(countries);
        this.destroySubscription$.next();
      });
  }

  processSelectedCountryChanged(country: Country) {
    // Update the selected country in store and reset the course related fields and cities
    this.priceRequestStore.updateQuoteInput({
      country,
      countryCode: country?.code,
      destinationCity: undefined,
      courseType: undefined,
      tuitionWeeks: undefined,
      startDate: undefined,
    });

    this.loading = true;
    combineLatest([
      this.cityService.getAllCitiesWithParameters({
        courseLanguageCode: this.selectedLanguage,
        countryCode: country?.code,
      }),
      this.priceRequestStore.quoteInput$,
    ])
      .pipe(takeUntil(this.destroySubscription$))
      .subscribe(([availableCities, quoteInput]: [CityExtended[], QuoteInput]) => {
        this.loading = false;
        this.destroySubscription$.next();
        this.availableCities = availableCities;
        this.priceRequestStore.updateDestinationCities(availableCities);
        const { languageCode, countryCode } = quoteInput;
        this.availableCourseTypes = getAvailableCourseTypes(this.allSchools, languageCode, countryCode, undefined);
        this.priceRequestStore.updateCourseTypes(this.availableCourseTypes);
        this.destroySubscription$.next();
      });
  }

  processSelectedDestinationCityChanged(city: City) {
    // Update the selected city in store and reset the course related fields
    this.priceRequestStore.updateQuoteInput({
      destinationCity: city,
      courseType: undefined,
      tuitionWeeks: undefined,
      startDate: undefined,
    });

    this.priceRequestStore.quoteInput$.pipe(takeUntil(this.destroySubscription$)).subscribe((quoteInput: QuoteInput) => {
      const { languageCode, countryCode, destinationCity } = quoteInput;
      if (languageCode && countryCode && destinationCity) {
        this.availableCourseTypes = getAvailableCourseTypes(this.allSchools, languageCode, countryCode, destinationCity?.code);
        this.priceRequestStore.updateCourseTypes(this.availableCourseTypes);
      }
      this.destroySubscription$.next();
    });
  }

  processSelectedCourseTypeChanged(courseType: CourseType) {
    this.priceRequestStore.updateQuoteInput({ courseType: courseType?.code });
    this.priceRequestStore.updateCourseType(courseType);
  }

  processSelectedDurationChanged(duration: number) {
    this.priceRequestStore.updateQuoteInput({ tuitionWeeks: duration });
  }

  processSelectedStartDateChange(startDate: DatePickerFieldDate) {
    this.priceRequestStore.updateQuoteInput({ startDate: startDate?.dateString });
  }

  processSelectedAccommodationTypeChanged(accommodationType: NumericCodeAndName) {
    this.selectedAccommodationType = accommodationType;
    this.priceRequestStore.updateQuoteInput({ accommodationType: accommodationType?.name || '' });
  }

  setSpecialRequests(specialRequestsText: string) {
    this.specialRequests = specialRequestsText;
    this.priceRequestStore.updateStudentDetails({ specialRequests: specialRequestsText });
  }
}
