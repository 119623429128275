import { Routes } from '@angular/router';
import { stepRoutes } from '../components/steps/steps.routes';
import { FormComponent } from '../form.component';

/*
 * I had to do this alias because of an error with Compodoc and routes.
 * Apparently, since it's taking the routes from '../components', one of its internal functions applies the '..' to the route and generate an incorrect route
 */
const routes = [...stepRoutes];

export const formRoutes: Routes = [{ path: '', component: FormComponent, children: [...routes] }];
