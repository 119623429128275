import { Component, Input, OnChanges } from '@angular/core';
import { CommonModule } from '@angular/common';

export type StarRatingVariant = 'small' | 'normal' | 'large';

@Component({
  standalone: true,
  selector: 'kng-star-rating',
  templateUrl: './star-rating.component.html',
  styleUrls: ['./star-rating.component.scss'],
  imports: [CommonModule],
})
export class StarRatingComponent implements OnChanges {
  private readonly MAX_NUMBER_STARS = 5;

  @Input() rating = 0;
  @Input() color = '#fac800';
  @Input() variant: StarRatingVariant = 'normal';

  fullStars: number[] = [];
  emptyStars: number[] = [];
  hasHalfStar = false;

  halfStarGradient: string;

  ngOnChanges() {
    if (isNaN(this.rating)) {
      return;
    }
    this.initData();
  }

  private initData(): void {
    const fullStars = Math.floor(this.rating);
    const decimalPart = this.rating % 1;
    this.fullStars = Array(fullStars);
    this.hasHalfStar = decimalPart !== 0;
    this.emptyStars = Array(this.MAX_NUMBER_STARS - Math.ceil(this.rating));
    this.halfStarGradient = `linear-gradient(90deg, ${this.color} ${decimalPart * 100}%, transparent 0%);`;
  }
}
