import { DOCUMENT } from '@angular/common';
import { AfterViewInit, Component, ElementRef, EventEmitter, Inject, OnDestroy, OnInit, Output, ViewChild } from '@angular/core';
import { hasCurrency } from '@klg/currency/shared/functions/currency-price.functions';
import { CurrencyCode } from '@klg/currency/shared/model/currency.model';
import { Language, LanguageService } from '@klg/language';
import {
  getCourseWithMaxStartDateFromQuoteCourses,
  getDefaultQuoteCurrency,
  getDestinationFromInput,
  getMaxCourseStartDateYearFromQuoteCourses,
  QuoteFormLinkUrlsService,
} from '@klg/quote-tool/shared/data-access/quote';
import { CurrencyData, QuoteFormLinkUrls, QuoteOutput, QuoteTotalLayouts, SchoolAndCourseData, StudentDetails } from '@klg/quote-tool/shared/types/quote';
import { SchoolTypesEnum } from '@klg/shared/data-access/types';
import { getToday } from '@klg/shared/utils';
import { quoteOpenAnimation } from '@klg/ui/animations';
import { combineLatest, EMPTY, iif, Observable, of, Subscription } from 'rxjs';
import { catchError, filter, map, switchMap, take, tap } from 'rxjs/operators';
import { ROUTE_IDS, StepDefinition } from '@klg/quote-tool/shared/types';
import { StepService } from '@klg/quote-tool/shared/services/step-service';
import { HostDataReaderService } from '@klg/quote-tool/shared/services';

type NewQuoteDetails = [QuoteOutput, Language];

const TODAY_YEAR = getToday().getFullYear();

@Component({
  selector: 'kng-form',
  templateUrl: './form.component.html',
  styleUrls: ['./form.component.scss', './form.component.print.scss'],
  animations: [quoteOpenAnimation],
})
export class FormComponent implements OnInit, OnDestroy, AfterViewInit {
  @ViewChild('quoteTotalStatic', { read: ElementRef }) quoteTotalStatic: ElementRef;

  @Output() submitQuoteEvent = new EventEmitter<QuoteOutput>();

  private subscription = new Subscription();

  public exchangeCurrencyCode: CurrencyCode;
  private destinationCurrency: string;

  public stepDefinition: StepDefinition;

  public loading = false;

  // Quote Summary Info
  public languageName: string;
  public schoolName: string;
  public destination: string;
  public quoteOutput: QuoteOutput;
  public studentDetails: StudentDetails;
  public isQuoteProductsAccordionExpanded = true;
  public isQuoteDiscountsAccordionExpanded = true;
  public isStepValid: boolean;
  public isSideQuoteOpen = false;
  public isQuoteTotalStaticVisible: boolean;
  public quoteSummaryCtaHeaderText: string;
  public quoteSummaryCtaSubheaderText: string;
  public QuoteTotalLayouts = QuoteTotalLayouts;
  private confirmationLinkUrl: string;

  public confirmationDisclaimer = '';

  showCtaQuote = true;

  constructor(
    private stepService: StepService,
    private languageService: LanguageService,
    private hostDataReader: HostDataReaderService,
    private linkUrlsService: QuoteFormLinkUrlsService,
    @Inject(DOCUMENT) private document: Document,
  ) {}

  ngOnInit(): void {
    this.stepService.initiateNavigation(ROUTE_IDS.FORM_ROOT);
    this.subscription.add(
      combineLatest([this.stepService.currentStepDefinition$, this.loadPrefilledData$()])
        .pipe(
          tap(([stepDefinition]: [StepDefinition, boolean]) => {
            this.stepDefinition = stepDefinition;
            this.setQuoteSummaryTextButton(stepDefinition);
          }),
          switchMap(([stepDefinition]: [StepDefinition, boolean]) => iif(() => stepDefinition?.displayQuote, this.requestQuoteAndDetails$(), EMPTY)),
        )
        .subscribe(),
    );

    this.subscription.add(
      this.linkUrlsService.getLinkUrls$().subscribe((links: QuoteFormLinkUrls) => {
        this.confirmationLinkUrl = links.confirmationLinkUrl;
      }),
    );

    this.subscription.add(
      this.stepService.currentStepDefinition$.pipe(filter((stepDefinition: StepDefinition) => stepDefinition?.stepId === ROUTE_IDS.THANK_YOU)).subscribe(() => {
        this.stepService.clearSessionStorage();
        if (this.confirmationLinkUrl) {
          this.document.location.replace(this.confirmationLinkUrl);
        }
      }),
    );

    this.subscription.add(
      this.stepService.calculatingQuote$.subscribe((calculatingQuote: boolean) => {
        this.loading = calculatingQuote;
      }),
    );

    this.subscription.add(
      this.stepService.getCurrentStepValid().subscribe((isStepValid: boolean) => {
        this.isStepValid = isStepValid;
      }),
    );

    this.subscription.add(
      this.stepService.studentDetails$.subscribe((studentDetails: StudentDetails) => {
        this.studentDetails = studentDetails;
      }),
    );
  }

  ngAfterViewInit() {
    const observer = new IntersectionObserver(
      (entries) => {
        entries.forEach((entry) => {
          this.isQuoteTotalStaticVisible = entry.isIntersecting && entry.intersectionRatio > 0.3;
        });
      },
      { threshold: [0.3, 0] },
    );
    observer.observe(this.quoteTotalStatic.nativeElement);
  }

  ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }

  private loadPrefilledData$(): Observable<boolean> {
    return this.hostDataReader.getPrefilledSchoolCourse$().pipe(
      take(1),
      tap((schoolAndCourseData: SchoolAndCourseData) => {
        if (schoolAndCourseData?.school) {
          this.stepService.setPartialRequest<SchoolAndCourseData>(schoolAndCourseData);
        }
      }),
      map((schoolAndCourseData: SchoolAndCourseData) => Boolean(schoolAndCourseData?.school)),
    );
  }

  private requestQuoteAndDetails$(): Observable<NewQuoteDetails> {
    return this.stepService.requestActiveQuote().pipe(
      switchMap((quoteOutput: QuoteOutput) =>
        combineLatest([of(quoteOutput), this.languageService.get(quoteOutput?.input?.languageCode).pipe(catchError(() => of(null)))]),
      ),
      tap(([quoteOutput, language]: NewQuoteDetails) => {
        this.quoteOutput = quoteOutput;
        // If destinationCurrency changes then reset exchangeCurrencyCode
        const quoteCurrencyByDefault = getDefaultQuoteCurrency(quoteOutput);
        if (this.destinationCurrency !== quoteCurrencyByDefault) {
          this.destinationCurrency = quoteCurrencyByDefault;
          this.updateExchangeCurrency(this.destinationCurrency);
        }

        // Check if the exchangeCurrencyCode can be displayed (does the price list have that currency?)
        if (quoteOutput?.grandTotal) {
          if (!hasCurrency(quoteOutput?.grandTotal, this.exchangeCurrencyCode)) {
            const exchangeCurrencyCode = hasCurrency(quoteOutput?.grandTotal, this.destinationCurrency)
              ? this.destinationCurrency
              : quoteOutput.grandTotal[0].currency;
            this.updateExchangeCurrency(exchangeCurrencyCode);
          }
        }

        this.languageName = language?.name;
        this.destination = getDestinationFromInput(this.quoteOutput?.input);
        this.schoolName = this.quoteOutput?.input?.schoolObject?.name;
        if (this.quoteHasNotConfirmedDate(quoteOutput)) {
          const maxYear = getMaxCourseStartDateYearFromQuoteCourses(quoteOutput);
          this.confirmationDisclaimer =
            quoteOutput.input.schoolType === SchoolTypesEnum.ADULT
              ? this.adultConfirmationDisclaimer(maxYear).toString()
              : this.juniorConfirmationDisclaimer(maxYear).toString();
        } else {
          this.confirmationDisclaimer = '';
        }
      }),
    );
  }

  public expandProduct() {
    this.isQuoteProductsAccordionExpanded = !this.isQuoteProductsAccordionExpanded;
  }

  public expandDiscount() {
    this.isQuoteDiscountsAccordionExpanded = !this.isQuoteDiscountsAccordionExpanded;
  }

  public submitQuote() {
    // Before doing anything else, make sure that the dialog is closed and if not, close it
    if (this.isSideQuoteOpen) {
      this.closeSideQuote();
    }

    // If submitStep is true, emit event with quoteOutput
    if (this.stepDefinition.submitStep) {
      this.submitQuoteEvent.emit(this.quoteOutput);
    }

    // If there is a defined nextStep, navigate to nextStep
    if (this.stepDefinition.nextStep) {
      this.stepService.navigateToNextStep();
    }
  }

  public hasCurrencySelector(): boolean {
    return this.quoteOutput?.grandTotal?.filter((amount) => amount.visible).length > 1;
  }

  public scrollToTop() {
    window.scroll(0, 0);
  }

  private setQuoteSummaryTextButton(stepDefinition: StepDefinition): void {
    if (!stepDefinition) {
      return;
    }
    this.quoteSummaryCtaHeaderText = stepDefinition.nextButton;
    this.showCtaQuote = !!this.stepDefinition.nextButton;
  }

  public openSideQuote() {
    this.isSideQuoteOpen = true;
  }

  public closeSideQuote() {
    this.isSideQuoteOpen = false;
  }

  public updateExchangeCurrency(newCurrencySelected: string) {
    this.exchangeCurrencyCode = newCurrencySelected;
    this.stepService.updatePartialRequest<CurrencyData>({ exchangeCurrencyCode: newCurrencySelected });
  }

  private quoteHasNotConfirmedDate(quoteOutput: QuoteOutput): boolean {
    const maxStartDateCourse = getCourseWithMaxStartDateFromQuoteCourses(quoteOutput);
    return (
      maxStartDateCourse && !maxStartDateCourse.dateConfirmed && quoteOutput?.products?.some((quoteProduct) => quoteProduct.code === maxStartDateCourse.code)
    );
  }

  private juniorConfirmationDisclaimer(quoteYear: number): string {
    return $localize`We will contact you soon to confirm prices, date and programme availability for ${quoteYear}:next_year:.`;
  }

  private adultConfirmationDisclaimer(quoteYear: number): string {
    let disclaimer = $localize`We will contact you soon to confirm date, price and availability.`;
    if (getToday().getMonth() < 10 && TODAY_YEAR <= quoteYear - 1) {
      /**
       * Disclaimer for early bird
       * When quote done before the 1st Nov with start dates next year, this message is displayed
       * to make the user aware that after 01st of November the prices might change ( increase )
       * and if they want to keep this price they should book it before that date.
       */
      const currentPricesYear = getToday().getMonth() < 10 ? TODAY_YEAR : TODAY_YEAR + 1;
      disclaimer =
        $localize`Book before 01.11.${currentPricesYear}:current_year: to get ${currentPricesYear}:current_year: prices for your ${quoteYear}:next_year: stay.` +
        ' ' +
        disclaimer;
    }
    return disclaimer;
  }
}
