import { Component, EventEmitter, Input, OnChanges, Output, SimpleChanges } from '@angular/core';
import { FormFieldSelectorComponent } from '../form-field-selector/form-field-selector.component';
import { CodeAndName } from '@klg/shared/types';

@Component({
  standalone: true,
  selector: 'kng-title-selector',
  templateUrl: './title-selector.component.html',
  styleUrls: ['./title-selector.component.scss'],
  imports: [FormFieldSelectorComponent],
})
export class TitleSelectorComponent<T extends CodeAndName & { [key: string]: any }> implements OnChanges {
  @Input() small = false;
  @Input() label: string;
  @Input() disabled = false;
  @Input() placeholder: string;
  @Input() value: string = undefined;
  @Input() itemList: T[] = [];
  @Input() required = false;
  @Output() valueChange = new EventEmitter<T['code']>();

  dirty = false;
  selectedValue: string;

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.value?.currentValue) {
      this.selectedValue = changes.value.currentValue;
    }
  }
}
