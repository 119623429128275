import { Injectable } from '@angular/core';
import { getLocale } from '@klg/shared/i18n';
import { getAppName, getCompanyHeader } from '@klg/shared/tokens';
import { INGXLoggerMetadata, NGXLoggerServerService } from 'ngx-logger';

@Injectable({
  providedIn: 'root',
})
export class CustomisedNGXLoggerServerService extends NGXLoggerServerService {
  private readonly companyHeader = getCompanyHeader();
  private readonly locale = getLocale();
  private readonly appName = getAppName();

  /**
   * Customise the data sent to the API
   * @param metadata the data provided by NGXLogger
   * @returns the data that will be sent to the API in the body
   */
  customiseRequestBody(metadata: INGXLoggerMetadata) {
    const body = { ...metadata };
    body['company'] = this.companyHeader;
    body['market'] = this.locale;
    body['browserAgent'] = window.navigator.userAgent;
    body['url'] = window.location.href;
    body['appName'] = this.appName;
    return body;
  }
}
