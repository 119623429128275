import { Component, Input } from '@angular/core';

@Component({
  selector: 'kng-brief-icon',
  templateUrl: './brief-icon.component.html',
  styleUrls: ['./brief-icon.component.scss'],
})
export class BriefIconComponent {
  @Input() briefIconUrl: string;
  @Input() briefIconLabel: string;
  @Input() briefIconSeparator: string;
}
