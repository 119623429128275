import { Injectable } from '@angular/core';
import { ComponentStore } from '@ngrx/component-store';
import { defaultPriceRequestExperimentState, PriceRequestExperimentState } from '../state/price-request-experiment.state';
import { QuoteInput, StudentDetails } from '@klg/quote-tool/shared/types';
import { CityExtended, Country, CourseType } from '@klg/shared/data-access/types';

@Injectable({ providedIn: 'root' })
export class PriceRequestExperimentStore extends ComponentStore<PriceRequestExperimentState> {
  constructor() {
    super(defaultPriceRequestExperimentState);
  }

  // --------------------------Selectors--------------------------
  readonly state$ = this.select((s) => s);
  readonly quoteInput$ = this.select((s) => s.quoteInput);
  readonly quoteLanguage$ = this.select((s) => s.quoteInput?.languageCode);
  readonly quoteCourseType$ = this.select((s) => s.courseType);
  readonly studentDetails$ = this.select((s) => s.studentDetails);
  readonly destinationCountries$ = this.select((s) => s.destinationCountries);
  readonly destinationCities$ = this.select((s) => s.destinationCities);
  readonly courseTypes$ = this.select((s) => s.courseTypes);

  // --------------------------Updaters---------------------------
  readonly updateQuoteInput = this.updater((state, quoteInput: QuoteInput) => {
    const newQuoteInput = { ...state.quoteInput, ...quoteInput };
    return {
      ...state,
      quoteInput: newQuoteInput,
    };
  });

  readonly updateCourseType = this.updater((state, courseType: CourseType) => ({
    ...state,
    courseType,
  }));

  readonly updateStudentDetails = this.updater((state, studentDetails: StudentDetails) => {
    const newStudentDetails = { ...state.studentDetails, ...studentDetails };
    return {
      ...state,
      studentDetails: newStudentDetails,
    };
  });

  readonly updateDestinationCountries = this.updater((state, destinationCountries: Country[]) => ({
    ...state,
    destinationCountries,
  }));
  readonly updateDestinationCities = this.updater((state, destinationCities: CityExtended[]) => ({
    ...state,
    destinationCities,
  }));

  readonly updateCourseTypes = this.updater((state, courseTypes: string[]) => ({
    ...state,
    courseTypes,
  }));
}
