/**
 * KLG Quotes API
 * This is KLG API to perform quote calculations and retrieve auxiliary pricing information.
 *
 * The version of the OpenAPI document: dev
 * Contact: xxx@kaplan.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

export interface AccommodationExtraApiDto {
  productCode?: string;
  type?: AccommodationExtraApiDto.TypeApiDtoEnum;
  roomType?: string;
  bathroomType?: string;
  mealsPlan?: string;
  description?: string;
  galleryUrls?: Array<string>;
}
export namespace AccommodationExtraApiDto {
  export type TypeApiDtoEnum = 'HOMESTAY' | 'RESIDENCE' | 'OTHER';
  export const TypeApiDtoEnum = {
    HOMESTAY: 'HOMESTAY' as TypeApiDtoEnum,
    RESIDENCE: 'RESIDENCE' as TypeApiDtoEnum,
    OTHER: 'OTHER' as TypeApiDtoEnum,
  };
}
