import { AfterViewInit, Component, Input, OnDestroy, OnInit } from '@angular/core';
import { calculateEndDate, getValidAccommodations, getValidCourses, hasEnoughDataToGetSupplements } from '@klg/quote-tool/shared/data-access/quote';
import {
  AccommodationQuoteInput,
  CourseQuoteInput,
  QuoteInput,
  QuoteOutput,
  SupplementQuoteInput,
  SupplementsSectionPayload,
} from '@klg/quote-tool/shared/types/quote';
import { AllowOrder, Supplement, SupplementService } from '@klg/shared/data-access/course';
import { accordionAnimationData } from '@klg/ui/animations';
import { EMPTY, Observable, of, Subject, Subscription } from 'rxjs';
import { catchError, debounceTime, map, mergeMap, take, tap } from 'rxjs/operators';
import { StepService } from '@klg/quote-tool/shared/services/step-service';
import { getDateFromString, getDateStringFromDate } from '@klg/shared/utils';
import { SupplementGroup, SupplementGroupAccordion } from './select-options.types';

@Component({
  selector: 'kng-select-options-form-section',
  templateUrl: './select-options.component.html',
  styleUrls: ['./select-options.component.scss'],
  animations: [accordionAnimationData],
})
export class SelectOptionsFormSectionComponent implements OnInit, OnDestroy, AfterViewInit {
  @Input() sectionIndex: string | number;
  WEEKLY = 'W';
  loading: boolean;
  supplementGroups: SupplementGroupAccordion[];
  selectedSchool: string;
  selectedCourses: string;
  selectedCoursesDates: string;
  selectedAccommodations: string;
  selectedCurrency: string;
  quoteStart: Date;
  quoteEnd: Date;
  sectionModel: SupplementsSectionPayload = {} as SupplementsSectionPayload;

  private subscription = new Subscription();
  private selectedSupplementsChanged$ = new Subject<void>();

  constructor(private stepService: StepService, private supplementService: SupplementService) {}

  ngOnInit() {
    this.resetSupplements();
    this.subscription.add(
      this.loadStoredData$()
        .pipe(mergeMap(() => this.resetOnChanges$()))
        .subscribe(),
    );
    this.subscription.add(
      this.stepService.requestActiveQuote().subscribe(({ endDate }: QuoteOutput) => {
        this.quoteEnd = getDateFromString(endDate);
      }),
    );
  }

  ngAfterViewInit() {
    this.subscription.add(
      this.selectedSupplementsChanged$.pipe(debounceTime(400)).subscribe(() => {
        this.storeSectionData();
      }),
    );
  }

  ngOnDestroy() {
    this.subscription.unsubscribe();
  }

  /**
   * Recover previously stored data (user filled data)
   */
  private loadStoredData$(): Observable<QuoteInput> {
    return this.stepService.quoteRequest$.pipe(
      take(1),
      mergeMap((quoteInput: QuoteInput) => {
        this.selectedCurrency = quoteInput.exchangeCurrencyCode;
        if (hasEnoughDataToGetSupplements(quoteInput)) {
          const newSelectedSchoolCode = quoteInput.school;
          const newSelectedCourses = getValidCourses(quoteInput);
          const newSelectedAccommodations = getValidAccommodations(quoteInput);
          return this.loadSchoolSupplements$(newSelectedSchoolCode, newSelectedCourses, newSelectedAccommodations).pipe(map(() => quoteInput));
        } else {
          return of(quoteInput);
        }
      }),
      take(1),
      tap(({ supplements }: QuoteInput) => {
        if (supplements?.length) {
          supplements.forEach(({ code, quantity }: SupplementQuoteInput) => {
            this.quantityChange(code, quantity);
          });
        }
      }),
    );
  }

  private concatCodes(list: { code: string }[]): string {
    return list?.map(({ code }) => code).join(',');
  }

  private concatDates(list: { startDate: string }[]): string {
    return list?.map(({ startDate }) => startDate).join(',');
  }

  private loadSchoolSupplements$(
    selectedSchoolCode: string,
    selectedCourses: CourseQuoteInput[],
    selectedAccommodations: AccommodationQuoteInput[],
  ): Observable<Supplement[]> {
    this.loading = true;
    const startDate = selectedCourses[0]?.startDate;
    this.quoteStart = getDateFromString(startDate);
    return this.supplementService
      .getByCoursesAndAccommodations(
        selectedSchoolCode,
        selectedCourses?.map(({ code }) => code),
        selectedAccommodations?.map(({ code }) => code),
        selectedCourses ? startDate : null,
        selectedCourses ? this.getLastCourseEndDate(selectedCourses) : null,
      )
      .pipe(
        tap((supplementList: Supplement[]) => {
          this.selectedSchool = selectedSchoolCode;
          this.selectedCourses = this.concatCodes(selectedCourses);
          this.selectedCoursesDates = this.concatDates(selectedCourses);
          this.selectedAccommodations = this.concatCodes(selectedAccommodations);
          const supplements = [];
          supplementList.forEach((supplement: Supplement) => {
            if (this.isSupplementWeekly(supplement)) {
              const duration = this.calculateSupplementDuration(selectedAccommodations, selectedCourses, supplement);
              const existingSupplement = this.sectionModel.supplements?.find(this.supplementMatcher(supplement.code));
              if (existingSupplement) {
                existingSupplement.quantity = duration;
              }
              supplements.push({ ...supplement, quantity: duration });
            } else {
              supplements.push(supplement);
            }
          });
          this.prepareSupplements(supplements);
          this.storeSectionData();
          this.loading = false;
        }),
        catchError((error) => {
          console.error(error);
          this.loading = false;
          this.supplementGroups = [];
          this.sectionModel.hasSupplementsAvailable = false;
          this.storeSectionData();
          return EMPTY;
        }),
      );
  }

  private getLastCourseEndDate(selectedCourses: CourseQuoteInput[]): string {
    return getDateStringFromDate(calculateEndDate(selectedCourses[selectedCourses.length - 1]));
  }

  private calculateSupplementDuration(
    selectedAccommodations: AccommodationQuoteInput[],
    selectedCourses: CourseQuoteInput[],
    { related, maxUnit }: Supplement,
  ): number {
    let duration = 0;
    if (related.length > 0) {
      related.forEach((pid) => {
        duration += selectedCourses.filter((course) => Number(course.code) === pid).reduce((d, { weeks }) => d + weeks, 0);
        duration += selectedAccommodations.filter((accommodation) => Number(accommodation.code) === pid).reduce((d, { weeks }) => d + weeks, 0);
      });
    } else {
      duration = selectedCourses.reduce((d, { weeks }) => d + weeks, 0);
    }
    return Math.min(maxUnit, duration);
  }

  private isSupplementWeekly({ allowOrder, unit }: Supplement): boolean {
    return allowOrder === AllowOrder.SELECTABLE && unit === this.WEEKLY;
  }

  private resetOnChanges$() {
    return this.stepService.quoteRequest$.pipe(
      debounceTime(120),
      mergeMap((quoteInput: QuoteInput) => {
        const newSelectedSchool = quoteInput.school;
        const newSelectedCourses = getValidCourses(quoteInput);
        const newSelectedAccommodations = getValidAccommodations(quoteInput);
        this.selectedCurrency = quoteInput.exchangeCurrencyCode;
        const newSelectedCourseIds = this.concatCodes(newSelectedCourses);
        const newSelectedCourseDates = this.concatDates(newSelectedCourses);
        const newSelectedAccommodationIds = this.concatCodes(newSelectedAccommodations);
        const hasRequiredInput = hasEnoughDataToGetSupplements(quoteInput);
        const requestSupplements =
          hasRequiredInput &&
          (newSelectedSchool !== this.selectedSchool ||
            newSelectedCourseIds !== this.selectedCourses ||
            newSelectedAccommodationIds !== this.selectedAccommodations ||
            newSelectedCourseDates !== this.selectedCoursesDates);

        if (requestSupplements) {
          // Get the supplements for current selection
          return this.loadSchoolSupplements$(newSelectedSchool, newSelectedCourses, newSelectedAccommodations).pipe(map(() => quoteInput));
        } else {
          // Reset supplements on changes
          if (!hasRequiredInput && (this.supplementGroups?.length || this.sectionModel.supplements?.length)) {
            this.supplementGroups = [];
            this.sectionModel.hasSupplementsAvailable = false;
            this.resetSupplements();
            this.storeSectionData();
          }
          return of(quoteInput);
        }
      }),
    );
  }

  private supplementMatcher(supplementCode: string) {
    return (storedSupplement: SupplementQuoteInput) => storedSupplement.code === supplementCode;
  }

  private storeSectionData() {
    this.stepService.updatePartialRequest<SupplementsSectionPayload>(this.sectionModel);
  }

  private resetSupplements() {
    this.sectionModel.supplements = null;
  }

  public getSupplementStoredQuantity({ code }: Supplement): number {
    return this.sectionModel.supplements?.find(this.supplementMatcher(code))?.quantity || 0;
  }

  public quantityChange(code: string, quantity: number) {
    if (quantity === 0) {
      this.sectionModel.supplements = this.sectionModel.supplements?.filter((savedSupplement: SupplementQuoteInput) => savedSupplement.code !== code) || [];
    } else {
      const existingSupplement = this.sectionModel.supplements?.find(this.supplementMatcher(code));
      const supplementQuantity = this.getSupplementQuantityByCode(code, quantity);
      if (existingSupplement) {
        existingSupplement.quantity = supplementQuantity;
      } else {
        if (!this.sectionModel.supplements) {
          this.sectionModel.supplements = [];
        }
        this.sectionModel.supplements.push({
          code,
          quantity: supplementQuantity,
        });
      }
    }
    this.selectedSupplementsChanged$.next();
  }

  private getSupplementQuantityByCode(code: string, quantity: number): number {
    const supplement = this.supplementGroups.flatMap((group) => group.supplements).find((supplement) => supplement.code === code);

    if (AllowOrder.SELECTABLE === supplement?.allowOrder) {
      return null;
    }
    return quantity;
  }

  private prepareSupplements(supplements: Supplement[]) {
    const typeSupplementsMap: Record<string, { weight: number; supplements: Supplement[] }> = supplements
      .filter(({ type }) => type)
      .reduce((obj, supplement) => {
        const { type, typeWeight } = supplement;
        if (!(type in obj)) {
          obj[type] = { weight: typeWeight, supplements: [] };
        }
        obj[type] = {
          weight: typeWeight,
          supplements: [...obj[type].supplements, supplement],
        };
        return obj;
      }, {} as Record<string, { weight: number; supplements: Supplement[] }>);
    if (typeSupplementsMap) {
      this.supplementGroups = Object.entries(typeSupplementsMap)
        .map(
          ([key, { weight, supplements }]) =>
            ({
              isOpen: false,
              group: key,
              groupWeight: weight,
              supplements: supplements,
            } as SupplementGroup),
        )
        .sort(this.sortByWeight);
    }
    this.sectionModel.hasSupplementsAvailable = this.supplementGroups?.length > 0;
  }

  private sortByWeight = (a: SupplementGroup, b: SupplementGroup) => {
    if (a?.groupWeight === null || a?.groupWeight === undefined) {
      if (b?.groupWeight === null || b?.groupWeight === undefined) {
        // both have no value => equal
        return 0;
      } else {
        // only b has value => b is smaller => b goes up
        return 1;
      }
    }

    if (b?.groupWeight === null || b?.groupWeight === undefined) {
      // only a has value => a is smaller => a goes up
      return -1;
    }

    return a.groupWeight - b.groupWeight;
  };
}
