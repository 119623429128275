import { Component, Input } from '@angular/core';
import { DateRange } from '@klg/shared/data-access/types';
import { DateFormat } from '@klg/shared/i18n';

@Component({
  selector: 'kng-quote-product-date',
  templateUrl: './quote-product-details-date.component.html',
  styleUrls: ['./quote-product-details-date.component.scss'],
})
export class QuoteProductDetailsDateComponent {
  @Input() isArray = false;
  @Input() dateFormat: DateFormat;
  @Input() productSelectedWeeks: DateRange[] = [];
}
