import { Pipe, PipeTransform } from '@angular/core';
import { QuoteOutput, QuoteProductType } from '@klg/quote-tool/shared/types';
import { Unpacked } from '@klg/shared/utils-ts';
import { isUnaccompaniedMinorsProduct } from '@klg/quote-tool/shared/data-access/quote';

@Pipe({
  name: 'unaccompaniedMinorsByType',
  standalone: true,
})
export class UnaccompaniedMinorsByTypePipe implements PipeTransform {
  transform(
    products: QuoteOutput['products'],
    key?: keyof Unpacked<QuoteOutput['products']>,
  ): QuoteProductType | Unpacked<QuoteOutput['products']>[keyof Unpacked<QuoteOutput['products']>][] {
    const filteredProducts: QuoteOutput['products'] = products?.filter(isUnaccompaniedMinorsProduct);
    if (!filteredProducts?.length) {
      return null;
    }
    if (key) {
      return filteredProducts.map((product) => product[key]);
    }
    return filteredProducts;
  }
}
