import { Course } from '@klg/shared/data-access/types';
import { LightCourse } from '@klg/shared/data-access/course';

export function findConnectedCourses<T extends Course | LightCourse = Course>(courses: T[] = []): T[] {
  const result: T[] = [];

  for (const course of courses) {
    const { relatedProducts } = course;
    const setOfRelatedProducts = new Set(relatedProducts || []);
    const connectedCourse = result.find((filteredCourse) => setOfRelatedProducts.has(filteredCourse.code));

    if (!connectedCourse) {
      result.push(course);
    } else if (course.schoolYear > connectedCourse.schoolYear) {
      result[result.indexOf(connectedCourse)] = course;
    }
  }

  return result;
}
