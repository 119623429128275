<div class="quote-speed-dial-fab">
  <kng-quote-cta-button icon="dots" (buttonClicked)="onToggleFab()" (click)="$event.stopPropagation()"></kng-quote-cta-button>

  <div *ngIf="showOptions" [@speedDialStagger]="showOptions">
    <kng-quote-cta-button-group
      class="quote-speed-dial-fab__action-buttons"
      [hasProductInfo]="hasProductInfo"
      [isProductEditable]="isProductEditable"
      (infoButtonClicked)="infoFabClicked.emit()"
      (editButtonClicked)="editFabClicked.emit()"
      (removeButtonClicked)="removeFabClicked.emit()"
    ></kng-quote-cta-button-group>
  </div>
</div>
