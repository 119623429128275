import { Injectable } from '@angular/core';
import { Memoize } from '@klg/shared/utils-http';
import { LeadsControllerApiService, QuotesControllerApiService } from '@klg/shared/api/services';
import { getLocale } from '@klg/shared/i18n';
import { getCompanyHeader } from '@klg/shared/tokens';
import { clearMemoizeCacheOnError } from '@klg/shared/utils-rxjs';
import { Observable, of } from 'rxjs';
import { map } from 'rxjs/operators';
import { mapQuoteInputV2 } from '../functions';
import {
  QuoteInput,
  QuoteInputApi,
  QuoteInputModel,
  QuoteLead,
  QuoteLeadInput,
  QuoteLeadInputV4,
  QuoteLeadInputV5,
  QuoteLeadOutput,
  QuoteOutput,
  QuoteOutputApi,
} from '@klg/quote-tool/shared/types/quote';

@Injectable({
  providedIn: 'root',
})
export class QuoteService {
  private readonly locale = getLocale();
  private readonly companyHeader = getCompanyHeader();

  constructor(private leadsControllersService: LeadsControllerApiService, private quotesControllerApiService: QuotesControllerApiService) {}

  get(quoteInput: QuoteInput, countryOfResidence?: string): Observable<QuoteOutput> {
    const quoteInputModel: QuoteInputModel = mapQuoteInputV2(quoteInput, countryOfResidence);
    const quotePrices$ = quoteInputModel ? this.getQuotePriceV2(quoteInputModel) : of(null);
    return quotePrices$.pipe(
      map(
        (quotePriceResult: QuoteOutputApi): QuoteOutput => ({
          ...quotePriceResult,
          input: quoteInput,
        }),
      ),
    );
  }

  @Memoize({ tags: ['getQuotePriceV2'], hashFunction: (quoteInput: QuoteInputApi) => JSON.stringify(quoteInput) })
  getQuotePriceV2(quoteInput: QuoteInputApi): Observable<QuoteOutputApi> {
    return this.quotesControllerApiService.requestQuoteV2(this.locale, this.companyHeader, quoteInput).pipe(clearMemoizeCacheOnError('getQuotePriceV2'));
  }

  @Memoize((lead: QuoteLead) => JSON.stringify([lead.leadUserData.quoteId, lead.formOrigin]))
  createLeadV2(lead: QuoteLead): Observable<QuoteLeadOutput> {
    return this.leadsControllersService.createLeadV2(this.locale, this.companyHeader, lead);
  }

  // Disallows the multiple leads of the same quotes
  @Memoize((lead: QuoteLeadInputV4) => JSON.stringify([...lead.quotes.map((quote) => quote?.quoteInput?.quoteId).filter(Boolean), lead.formOrigin]))
  createLeadV4(lead: QuoteLeadInputV4): Observable<QuoteLeadOutput> {
    return this.leadsControllersService.createLeadV4(this.locale, this.companyHeader, lead);
  }

  @Memoize((lead: QuoteLeadInputV5) => JSON.stringify(lead.formOrigin))
  createLeadV5(lead: QuoteLeadInputV5): Observable<QuoteLeadOutput> {
    return this.leadsControllersService.createLeadV5(this.locale, this.companyHeader, lead);
  }

  getEnrolmentFromEnrolmentId(enrolmentId: string, klgToken: string): Observable<QuoteLeadInput> {
    return this.leadsControllersService.getEnrolment(enrolmentId, klgToken, this.locale, this.companyHeader);
  }
}
