import { inject, Injectable } from '@angular/core';
import { CodeAndName } from '@klg/shared/types';
import { sortArrayWithPriorityFn } from '@klg/shared/utils-array';
import { ResidenceCountry, ResidenceCountryService } from '@klg/shared/data-access/destination';
import { getLocaleCountryCode } from '@klg/shared/i18n';
import { getFormattedTimezoneOffset } from '@klg/shared/utils';
import { combineLatest, Observable, of } from 'rxjs';
import { map, shareReplay } from 'rxjs/operators';
import { TIMEZONES } from '@klg/quote-tool/shared/types';

@Injectable()
export class TimezoneService {
  private priorityCountryCode = getLocaleCountryCode();
  private priorityCountry: CodeAndName | undefined;
  private timeZones = inject(TIMEZONES);
  timezones$: Observable<CodeAndName[]> = combineLatest([
    this.residenceCountryService.getAll().pipe(map((countries) => countries.map(({ code, name }) => ({ code, name })))),
    this.residenceCountryService.getByCode(this.priorityCountryCode),
    of(this.timeZones),
  ]).pipe(
    map(([countries, priorityCountry, timezones]: [CodeAndName[], ResidenceCountry, Record<string, string[]>]) => {
      this.priorityCountry = priorityCountry;
      return Object.entries(timezones).reduce((accTimezones, [timezoneKey, entries]) => {
        const country = countries.find(({ code }) => code === timezoneKey);
        const newTimezones = this.mapToTimezones(entries, country);
        return [...accTimezones, ...newTimezones];
      }, [] as CodeAndName[]);
    }),
    map((timeZones) => {
      const priorityTimeZones = this.priorityCountry ? this.mapToTimezones(this.timeZones[this.priorityCountryCode], this.priorityCountry) : [];
      return sortArrayWithPriorityFn(timeZones, [{ property: 'name' }], priorityTimeZones);
    }),
    shareReplay(1),
  );

  constructor(private readonly residenceCountryService: ResidenceCountryService) {}

  private mapToTimezones(entries: string[], country: CodeAndName): CodeAndName[] {
    return entries.map((timezone) => ({
      code: timezone,
      name: [...this.getTimezoneCountryCityNames(country, timezone), `(${getFormattedTimezoneOffset(timezone)})`].join(' - '),
    }));
  }

  private getTimezoneCountryCityNames(country: ResidenceCountry | undefined, timezone: string) {
    const parts: string[] = [];
    if (country) {
      parts.push(country.name);
    }
    const timezoneParts = timezone.split('/');
    parts.push(timezoneParts[timezoneParts.length - 1].replace(/_/, ' '));
    return parts;
  }
}
