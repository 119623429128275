export const DATE_FORMAT = {
  YEAR_MONTH_DATE_DASH: 'yyyy-MM-dd',
};

/**
 * Enum with the default minimum and maximum number of weeks.
 */
export enum DEFAULT_WEEKS_RANGE {
  MIN_WEEKS = 1,
  MAX_WEEKS = 52,
}
