import { Observable } from 'rxjs';

export class SelectedItem<T> {
  constructor(public item: T, public context: symbol = null) {}
}

export interface ItemSelector<T> {
  setSelectedInContext(item: T, context?: symbol): void;
  getSelectedInContext(): Observable<T>;
}
