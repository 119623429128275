<kng-loading-spinner i18n-message message="Loading..." [loading]="loading" shadingColor="rgba(0,0,0,0.5)"></kng-loading-spinner>

<kng-section-template [columns]="columns" [sectionTitle]="title">
  <div class="price-request-course-form__form-group">
    <kng-language-selector
      class="form-field__validated-selector-padding"
      i18n-label
      label="Language"
      [buttonView]="false"
      [value]="(quoteInput$ | async)?.languageCode"
      [required]="true"
      (valueChange)="processSelectedLanguageChange($event)"
      data-qa="qt-language-selector"
    ></kng-language-selector>
  </div>
  <div class="price-request-course-form__form-group">
    <kng-destination-country-selector
      class="form-field__validated-selector-padding"
      i18n-label
      label="Country"
      [autofill]="!!(quoteInput$ | async)?.languageCode"
      [disabled]="!(quoteInput$ | async)?.languageCode"
      [buttonView]="false"
      [countries]="availableCountries"
      [value]="(quoteInput$ | async)?.countryCode"
      [required]="true"
      [iconValidation]="true"
      (itemChange)="processSelectedCountryChanged($event)"
      data-qa="qt-country-selector"
    ></kng-destination-country-selector>
  </div>

  <div class="price-request-course-form__form-group">
    <kng-city-selector
      class="form-field__validated-selector-padding"
      i18n-label
      label="City"
      [autofill]="!!(quoteInput$ | async)?.countryCode"
      [disabled]="!(quoteInput$ | async)?.countryCode"
      [buttonView]="false"
      [availableCities]="availableCities"
      [value]="(quoteInput$ | async)?.destinationCity?.code"
      [countryCode]="(quoteInput$ | async)?.countryCode"
      [required]="!experimentDActive"
      (itemChange)="processSelectedDestinationCityChanged($event)"
      data-qa="qt-city-selector"
    ></kng-city-selector>
  </div>

  <div class="price-request-course-form__form-group">
    <kng-course-type-selector
      class="qt-form-section__start-new-line form-field__validated-selector-padding"
      i18n-label
      label="Program"
      [disabled]="!(quoteInput$ | async)?.countryCode"
      [codes]="availableCourseTypes"
      [buttonView]="false"
      [value]="(quoteInput$ | async)?.courseType"
      [required]="true"
      [ageGroup]="ageGroup"
      [iconValidation]="true"
      (itemChange)="processSelectedCourseTypeChanged($event)"
      [schoolCompany]="'ESL'"
      [attr.data-qa]="'qt-course-type-selector'"
    ></kng-course-type-selector>
  </div>
</kng-section-template>

<kng-section-template [columns]="2">
  <div class="price-request-course-form__form-group">
    <kng-weeks-selector
      class="form-field__validated-selector-padding"
      i18n-label
      label="Duration"
      [disabled]="!(quoteInput$ | async)?.courseType"
      [value]="(quoteInput$ | async)?.tuitionWeeks"
      [availableWeeksValues]="availableWeeks"
      [required]="true"
      [iconValidation]="true"
      (valueChange)="processSelectedDurationChanged($event)"
      [attr.data-qa]="'qt-duration-field'"
    ></kng-weeks-selector>
  </div>
  <div>
    <kng-date-picker-field
      class="form-field__validated-selector-padding"
      i18n-label
      label="Start date"
      i18n-placeholder
      placeholder="— Please select  —"
      [disabled]="!(quoteInput$ | async)?.tuitionWeeks || !(quoteInput$ | async)?.courseType"
      [dateValue]="(quoteInput$ | async)?.startDate"
      [minDate]="minDate"
      [showCalendarIcon]="true"
      [required]="true"
      [iconValidation]="true"
      (dateChanged)="processSelectedStartDateChange($event)"
      [attr.data-qa]="'qt-start-date-field'"
    ></kng-date-picker-field>
  </div>
</kng-section-template>

<kng-section-template [columns]="columns">
  <div class="price-request-course-form__form-group">
    <kng-form-field-selector
      class="form-field__validated-selector-padding"
      i18n-label
      label="Accommodation"
      [disabled]="!(quoteInput$ | async)?.startDate"
      valueExpr="code"
      [value]="selectedAccommodationType.code"
      [itemList]="accommodationTypes"
      [iconValidation]="true"
      [required]="!experimentDActive"
      (itemChange)="processSelectedAccommodationTypeChanged($event)"
      data-qa="pr-accommodation-type-field"
    ></kng-form-field-selector>
  </div>
</kng-section-template>

<kng-section-template [columns]="1">
  <kng-form-field
    class="qt-form-section__double-column-span form-field__no-validation"
    i18n-label
    label="Tell us more about your objective and needs if you’d be interested in other destinations"
    [disabled]="!(quoteInput$ | async)?.startDate"
    [required]="false"
    [small]="true"
    [isValid]="true"
    [iconValidation]="true"
    data-qa="pr-customer-project-field"
  >
    <textarea
      class="qt-form-section__textarea"
      (ngModelChange)="setSpecialRequests($event)"
      [(ngModel)]="specialRequests"
      [autoResize]="false"
      pInputTextarea
    ></textarea>
  </kng-form-field>
</kng-section-template>
