import { LOCALE_ID, Provider } from '@angular/core';
import { COMPANY, getEnvironment, PATH_PREFIX } from '@klg/shared/tokens';
import { CoreEnvironment } from '@klg/shared/types';
import { MessageService } from 'primeng/api';
import { BASE_PATH } from '@klg/shared/api/variables';
import { CONFIGURATION_PROVIDER } from '../providers/configuration.provider';
import { WebComponentService } from '@klg/shared/utils-dom';
import { getEnvironmentProvider } from '../providers/environment.provider';
import { CustomisedNGXLoggerServerService } from '../services/customised-ngx-logger-server.service';

export const getCoreProviders = (env: CoreEnvironment) =>
  [
    { provide: LOCALE_ID, useFactory: () => env.LOCALE },
    { provide: PATH_PREFIX, useValue: env.PATH_PREFIX },
    { provide: COMPANY, useValue: env.COMPANY },
    getEnvironmentProvider(env),
    CONFIGURATION_PROVIDER,
    {
      provide: BASE_PATH,
      useFactory: () => {
        const { API_BASE_PATH } = getEnvironment();
        return API_BASE_PATH;
      },
    },
    MessageService,
    WebComponentService,
    CustomisedNGXLoggerServerService,
  ] as Provider[];
