import { Component, EventEmitter, Input, Output } from '@angular/core';

@Component({
  selector: 'kng-checkbox',
  templateUrl: './checkbox.component.html',
  styleUrls: ['./checkbox.component.scss'],
})
export class CheckboxComponent {
  @Input() value: boolean;
  @Output() valueChange = new EventEmitter<boolean>();

  public isHovered = false;
}
