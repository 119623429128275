<kng-form-field-selector
  label="Language Level"
  i18n-label
  [value]="value"
  valueExpr="code"
  [disabled]="disabled"
  [itemList]="levelOptions"
  [autofill]="true"
  [required]="required"
  [iconValidation]="true"
  (valueChange)="valueChange.emit($event)"
  (itemChange)="itemChange.emit($event)"
></kng-form-field-selector>
