<kng-form-field
  [disabled]="disabled"
  [isValid]="valid"
  [label]="label"
  [required]="required"
  [small]="small && !buttonView"
  [withoutHelper]="!buttonView"
  [ngClass]="{ 'kng-form-field--without-label': !label }"
>
  <ng-content select="[extra-header]"></ng-content>
  <p-dropdown
    *ngIf="!buttonView && !mobile"
    placeholder="{{ placeholder }}"
    filterPlaceholder="Search"
    emptyFilterMessage="{{ noDataText }}"
    i18n-placeholder
    i18n-filterPlaceholder
    i18n-emptyFilterMessage
    [filter]="searchEnabled"
    [options]="itemList | klgSortBy : sortCriteria"
    [filterBy]="'name'"
    [disabled]="disabled"
    [(ngModel)]="selectedValue"
    [optionLabel]="'name'"
    [optionValue]="$any(valueExpr)"
    (ngModelChange)="setSelectedItem($event)"
    (onShow)="isOpenChanged($event)"
    (onHide)="isOpenChanged($event)"
  >
    <ng-template pTemplate="selectedItem" *ngIf="selectedItemTemplate">
      <ng-container *ngTemplateOutlet="selectedItemTemplate; context: { $implicit: selectedItem }"></ng-container>
    </ng-template>
  </p-dropdown>
  <kng-button
    *ngIf="!buttonView && mobile"
    [icon]="'pi pi-chevron-down'"
    [text]="selectedValueText"
    [block]="true"
    [style]="{ width: '100%' }"
    [iconPos]="'after'"
    [disabled]="disabled"
    [btnRounded]="false"
    [buttonStyle]="'dropdown-square'"
    (buttonClick)="setDropdownPopup()"
  >
  </kng-button>
  <kng-dropdown-popup
    *ngIf="isDropdownPopupOpen && mobile"
    [company]="company"
    [itemList]="itemList | klgSortBy : sortCriteria"
    (valueChange)="setDropdownPopupSelectedItem($event)"
    (dropdownPopupClosed)="isDropdownPopupOpen = false"
  >
  </kng-dropdown-popup>
  <div *ngIf="buttonView">
    <kng-button
      *ngIf="isOpen && !hideHelpMeChoose"
      class="form-field-selector__help-button"
      i18n-text
      text="Help me choose"
      [icon]="showDetails ? 'pi pi-times' : ''"
      [iconPos]="'after'"
      [btnRounded]="true"
      [buttonStyle]="'popover'"
      (click)="showDetails = !showDetails"
    >
    </kng-button>
    <kng-select-button-group
      [valueExpr]="valueExpr"
      displayExpr="name"
      [options]="itemList | klgSortBy : sortCriteria"
      [value]="value"
      [(isOpen)]="isOpen"
      [defaultSorting]="false"
      [customEvent$]="buttonGroupCustomEvent"
      (valueChange)="setSelectedItem($event)"
      (isOpenChange)="isOpenChanged($event)"
    >
      <ng-template #optionItem let-currentItem>
        <ng-container
          *ngTemplateOutlet="
            optionItemTemplate ? optionItemTemplate : defaultOptionItemTemplate;
            context: { $implicit: currentItem, showDetails: showDetails, isOpen: isOpen }
          "
        ></ng-container>
      </ng-template>
    </kng-select-button-group>
  </div>
</kng-form-field>

<ng-template #defaultOptionItemTemplate let-currentItem let-showDetails="showDetails">
  <kng-summary-card [mode]="showDetails ? 'back' : 'cover'" [item]="currentItem" titleExpression="name" imageExpression="thumbnailSmall"></kng-summary-card>
</ng-template>
