import { inject, Injectable } from '@angular/core';
import { DestinationControllerApiService } from '@klg/shared/api/services';
import { FetchDataObject, ProductsCatalogFetcher } from '@klg/ui/product-catalog/shared/models/products-catalog-fetcher-service.type';
import { map, Observable } from 'rxjs';
import { getLocale } from '@klg/shared/i18n';
import { getCompanyHeader } from '@klg/shared/tokens';
import { Country, GetAllCountriesParameters } from '@klg/shared/data-access/types';

@Injectable({
  providedIn: 'root',
})
export class CountryDataService implements ProductsCatalogFetcher<Country> {
  private readonly locale = getLocale();
  private readonly companyHeader = getCompanyHeader();

  private readonly destinationControllerApiService = inject(DestinationControllerApiService);

  getAllCountriesWithParameters(parameters: GetAllCountriesParameters): Observable<Country[]> {
    return this.destinationControllerApiService
      .findDestinationCountriesByFilter(parameters, this.locale, this.companyHeader)
      .pipe(map((countries) => [...countries].sort((c1, c2) => c1.weight - c2.weight)));
  }

  getResults(parameters: FetchDataObject): Observable<Country[]> {
    const { courseLanguageCode, purposeCode, courseTypeCode } = parameters;
    return this.getAllCountriesWithParameters({ courseLanguageCode, purposeCode, courseTypeCode });
  }
}
