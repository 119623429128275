import { Directive, HostListener, inject, Input } from '@angular/core';
import { GoogleAnalyticsService } from '../services/google-analytics.service';

@Directive({
  standalone: true,
  selector: '[kngGAClickEvent]',
})
export class GoogleAnalyticsClickEventDirective {
  @Input() kngGAClickEvent: string | undefined = undefined;

  private readonly googleAnalyticsService = inject(GoogleAnalyticsService);

  @HostListener('click', ['$event']) onClick(event: MouseEvent) {
    this.googleAnalyticsService.trackClickEvent(this.kngGAClickEvent);
    event.stopImmediatePropagation();
  }
}
