import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { LanguageSelectorComponent } from './language-selector/language-selector.component';
import { MotherTongueSelectorComponent } from './mother-tongue-selector/mother-tongue-selector.component';
import { SelectButtonModule as PrimeNgSelectButtonModule } from 'primeng/selectbutton';
import { SortByPipe } from '@klg/shared/ui/pipes';
import { FormFieldSelectorComponent } from '../form-field-selector/form-field-selector.component';

@NgModule({
  declarations: [LanguageSelectorComponent, MotherTongueSelectorComponent],
  imports: [CommonModule, FormsModule, PrimeNgSelectButtonModule, SortByPipe, FormFieldSelectorComponent],
  exports: [LanguageSelectorComponent, MotherTongueSelectorComponent],
})
export class LanguageComponentsModule {}
