import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { DatePickerFieldDate } from '@klg/quote-tool/shared/ui/components';
import { AccommodationService } from '@klg/quote-tool/shared/data-access/products';
import { calculateEndDate, getTuitionWeeks, getValidCourses, hasIncompleteCourses, isAccommodationMandatory } from '@klg/quote-tool/shared/data-access/quote';

import {
  AccommodationDatesSectionPayload,
  AccommodationQuoteInput,
  CourseQuoteInput,
  QuoteInput,
  Vacation,
  VacationPeriod,
  noAccommodationOption,
} from '@klg/quote-tool/shared/types';
import { ProductAccommodation } from '@klg/shared/data-access/products';
import {
  addWeeks,
  deepClone,
  getDateFromString,
  weeksDiff,
  isDateWithinRange,
  difference,
  findClosestWeekendDay,
  isDateAfter,
  updateToNextDate,
  updateToClosestTargetDay,
  getDisabledDaysForRange,
  isInFirstWeekOfYear,
  toUtc,
} from '@klg/shared/utils';
import { combineLatest, EMPTY, Observable, of, Subscription } from 'rxjs';
import { catchError, debounceTime, map, mergeMap, take, tap } from 'rxjs/operators';
import { DateFormat } from '@klg/shared/i18n';
import { DatePeriod, SchoolTypes } from '@klg/shared/data-access/types';
import { StepService } from '@klg/quote-tool/shared/services/step-service';

interface AccommodationOptions {
  durationMin: number;
  durationMax: number;
  disabledDays: number[];
  disabledDates: Date[];
  minDate: Date;
  maxDate: Date;
  items: ProductAccommodation[];
}

interface AccommodationCourseOpenPeriod {
  openPeriod: DatePeriod;
  course: CourseQuoteInput;
}

interface AccommodationAvailability {
  from: Date;
  until: Date;
}

const DEFAULT_MAX_WEEKS = 52;
const YEARS_IN_FUTURE = 2;

@Component({
  selector: 'kng-accommodation-dates-form-section',
  templateUrl: './accommodation-dates.component.html',
  styleUrls: ['./accommodation-dates.component.scss'],
})
export class AccommodationDatesFormSectionComponent implements OnInit, OnDestroy {
  @Input() sectionIndex: string | number;

  private subscription = new Subscription();
  public loading: boolean;

  public sectionModel: AccommodationDatesSectionPayload = {} as AccommodationDatesSectionPayload;
  public accommodationOptions: AccommodationOptions[];

  public accommodations: ProductAccommodation[];
  public accommodationsWithEmptyOption: ProductAccommodation[];
  public noAccommodationOption: ProductAccommodation = { ...noAccommodationOption, name: $localize`Without accommodation` };

  public selectedCourses: CourseQuoteInput[];
  public selectedSchool: string;
  public tuitionWeeks = 0;
  public isAccommodationMandatory: boolean;
  public hasIncompleteCourses: boolean;
  public endDateLastCourse: Date;

  public accommodationAvailability: AccommodationAvailability[];

  public DateFormat = DateFormat;

  private schoolType: SchoolTypes;
  private vacationPeriods: Array<VacationPeriod>;

  constructor(private stepService: StepService, private accommodationService: AccommodationService) {}

  ngOnInit() {
    this.resetAccommodations();
    this.subscription.add(
      this.loadStoredData$()
        .pipe(mergeMap(() => this.resetOnChanges$()))
        .subscribe(),
    );
  }

  ngOnDestroy() {
    this.subscription.unsubscribe();
  }

  private calculateAccommodationDisabledDays(index: number): number[] {
    let disabledDays: number[] = [];
    const selectedAccommodation = this.selectedAccommodation(index);

    if (selectedAccommodation && this.selectedCourses.length) {
      const allWeekdays = [0, 1, 2, 3, 4, 5, 6];
      const startingDaysOfTheWeek = selectedAccommodation.startingDaysOfTheWeek;
      // calendar expects dates with sunday as starting date.
      disabledDays = allWeekdays.filter((value) => !startingDaysOfTheWeek.includes(value));
    }

    return disabledDays;
  }

  private calculateAccommodationDisabledDates(index: number): Date[] {
    const selectedAccommodationObject = this.selectedAccommodation(index);
    if (!selectedAccommodationObject) {
      return this.vacationPeriods.map((vacation) => getDisabledDaysForRange(vacation.startDate, vacation.endDate)).flat();
    }

    return this.vacationPeriods
      .map((vacation) =>
        this.normalizeWithAccommodation(vacation, selectedAccommodationObject.startingDaysOfTheWeek, selectedAccommodationObject.endingDaysOfTheWeek),
      )
      .map((vacation) => getDisabledDaysForRange(vacation.startDate, vacation.endDate))
      .flat();
  }

  private calculateMinDate(index: number, selectedAccommodationObject?: ProductAccommodation): Date {
    const limitDateSchoolYear = this.getLimitMinDateSchoolYear(selectedAccommodationObject);
    const limitMaxDateSchoolYear = this.getLimitMaxDateSchoolYear(selectedAccommodationObject);
    const previousAccommodation = this.sectionModel.accommodations[index - 1];
    let startDate: Date;
    let firstSelectedCourseStartDate: Date;
    if (index === 0) {
      firstSelectedCourseStartDate = this.getFirstSelectedCourseStartDate();
      startDate = firstSelectedCourseStartDate ? addWeeks(firstSelectedCourseStartDate, -1) : undefined;
    } else if (selectedAccommodationObject) {
      startDate = this.calculateStartDateFromAccommodation(previousAccommodation, this.selectedAccommodation(index - 1));
    } else {
      const previousAccommodation = this.sectionModel.accommodations[index - 1];
      startDate =
        previousAccommodation.startDateObject && previousAccommodation.weeks
          ? addWeeks(previousAccommodation.startDateObject, previousAccommodation.weeks, [], false)
          : undefined;
    }

    const date = this.calculateLimitedMinDateForSchoolYear(limitDateSchoolYear, limitMaxDateSchoolYear, startDate, firstSelectedCourseStartDate);
    if (!selectedAccommodationObject) {
      return date;
    }

    let accommodationMinDate = this.getMinDateWithVacations(selectedAccommodationObject, date, index);
    if (selectedAccommodationObject.openingPeriods) {
      // check opening periods
      const isDateBetweenPeriod = selectedAccommodationObject.openingPeriods.some((period) => {
        const dateRange: [Date, Date] = [getDateFromString(period.from), getDateFromString(period.until)];
        return isDateWithinRange(accommodationMinDate, dateRange);
      });
      if (!isDateBetweenPeriod) {
        const minAvailablePeriodForYear = selectedAccommodationObject.openingPeriods.find((period) => {
          const periodFromDate = getDateFromString(period.from);
          return periodFromDate.getFullYear() == accommodationMinDate.getFullYear();
        });
        if (minAvailablePeriodForYear) {
          accommodationMinDate = getDateFromString(minAvailablePeriodForYear.from);
        }
      }
    }
    return accommodationMinDate;
  }

  private calculateMaxDate(index: number, selectedAccommodationObject: ProductAccommodation): Date {
    if (this.isAccommodationMandatory) {
      return this.getFirstSelectedCourseStartDate();
    } else {
      const limitDateSchoolYear: Date = this.getLimitMaxDateSchoolYear(selectedAccommodationObject);

      if (selectedAccommodationObject?.durationMaxUnbounded) {
        const firstSelectedCourseStartDate = this.getFirstSelectedCourseStartDate();
        return this.calculateLimitedMaxDateForSchoolYear(limitDateSchoolYear, addWeeks(firstSelectedCourseStartDate, DEFAULT_MAX_WEEKS));
      }

      const selectedDuration = this.sectionModel.accommodations[index]?.weeks ?? 0;
      const maxDateWithVacations = this.getMaxDateWithVacations(selectedDuration, index, selectedAccommodationObject);
      let accommodationMaxDate = this.calculateLimitedMaxDateForSchoolYear(limitDateSchoolYear, maxDateWithVacations, selectedDuration);
      if (selectedAccommodationObject?.openingPeriods) {
        // check opening periods
        const isDateBetweenPeriod = selectedAccommodationObject.openingPeriods.some((period) => {
          const dateRange: [Date, Date] = [getDateFromString(period.from), getDateFromString(period.until)];
          return isDateWithinRange(accommodationMaxDate, dateRange);
        });
        if (!isDateBetweenPeriod) {
          const maxAvailablePeriodForYear = selectedAccommodationObject.openingPeriods.find((period) => {
            const periodUntilDate = getDateFromString(period.until);
            return periodUntilDate.getFullYear() == accommodationMaxDate.getFullYear();
          });
          if (maxAvailablePeriodForYear) {
            accommodationMaxDate = addWeeks(getDateFromString(maxAvailablePeriodForYear.until), -selectedDuration);
          }
        }
      }
      return accommodationMaxDate;
    }
  }

  private calculateStartDateFromAccommodation(
    { startDate, weeks }: AccommodationQuoteInput,
    { startingDaysOfTheWeek, endingDaysOfTheWeek }: ProductAccommodation,
  ): Date {
    const vacationPeriods = this.vacationPeriods.map((vacation) => this.normalizeWithAccommodation(vacation, startingDaysOfTheWeek, endingDaysOfTheWeek));

    const prevEndDate = this.calculateEndDateWithVacations(getDateFromString(startDate), weeks, vacationPeriods);
    return updateToClosestTargetDay(startingDaysOfTheWeek, prevEndDate);
  }

  private getMinDateWithVacations(accommodation: ProductAccommodation, initialDate: Date, index: number): Date {
    const minDate = updateToNextDate(accommodation?.startingDaysOfTheWeek, initialDate);
    if (index === 0) {
      return minDate;
    }
    // consider possible vacation periods between previous accommodation and duration
    let vacationWeeks = 0;
    const vacationPeriods = this.vacationPeriods.map((vacation) =>
      this.normalizeWithAccommodation(vacation, accommodation.startingDaysOfTheWeek, accommodation.endingDaysOfTheWeek),
    );
    if (vacationPeriods.some((vacation) => isDateWithinRange(minDate, [vacation.startDate, vacation.endDate]))) {
      const previousAccommodation = this.sectionModel.accommodations[index - 1];
      const previousStartDate = getDateFromString(previousAccommodation.startDate);
      const previousDuration = previousAccommodation.weeks;
      const previousEndDate = addWeeks(previousStartDate, previousDuration);
      vacationWeeks = this.getVacationWeeksWithinDates(previousStartDate, previousEndDate, vacationPeriods);
    }

    return addWeeks(minDate, vacationWeeks);
  }

  private getMaxDateWithVacations(selectedDuration: number, index: number, accommodation: ProductAccommodation): Date {
    // consider last accommodation booking based on last courses end date.
    const lastCourseDate = this.getLastCourseDateOrLimitDate(accommodation);
    const lastCourseDateUtc = toUtc(lastCourseDate);
    const maxDate = selectedDuration ? addWeeks(lastCourseDateUtc, -selectedDuration) : lastCourseDateUtc;
    const upcomingVacationPeriods = this.vacationPeriods.filter((vacation) => maxDate.getTime() < vacation.startDate.getTime());
    const selectedAccommodationObject = this.selectedAccommodation(index);
    const minDateUtc = toUtc(this.calculateMinDate(index, selectedAccommodationObject));
    const vacationWeeks = this.getVacationWeeksWithinDates(minDateUtc, lastCourseDateUtc, upcomingVacationPeriods);
    return selectedDuration ? addWeeks(toUtc(lastCourseDateUtc), -(selectedDuration + vacationWeeks)) : lastCourseDateUtc;
  }

  private getLastCourseDateOrLimitDate(accommodation: ProductAccommodation): Date {
    if (this.shouldDateBeLimitedToEndOfYear(accommodation)) {
      /**
       * During year shift period when prices for current and next year are available
       * some products might not be available anymore for next year
       * hence the availability in the quote tool should be limited to the current year.
       *
       */
      return new Date(this.getCurrentYear(), 11, 31); // Last day of the current year
    }

    /**
     * Otherwise quote tool should take the last selected course end date
     */
    return this.getLastCourseDate(accommodation);
  }

  private shouldDateBeLimitedToEndOfYear(accommodation: ProductAccommodation): boolean {
    /**
     * Date should be limited to end of the year if:
     * * product next year prices are available ( school ready for year shift )
     * * product availability is only for current year ( product validity = current year )
     */
    const { inPriceShiftPeriod, schoolYears, schoolYear } = accommodation || {};
    const currentYear = this.getCurrentYear();
    return inPriceShiftPeriod && schoolYears?.length == 1 && schoolYear === currentYear;
  }

  private getLimitMinDateSchoolYear(selectedAccommodationObject: ProductAccommodation): Date {
    if (selectedAccommodationObject) {
      const minDate = new Date(Math.min(...selectedAccommodationObject.schoolYears), 0, 1);
      // vacations that may impact the min date only. Those that are after the min date will be handled when calculating the duration.
      const vacationPeriod = this.getNormalizedVacationPeriods(minDate, selectedAccommodationObject).reduce((previousValue, currentValue) => {
        return previousValue && previousValue.endDate.getTime() >= currentValue.endDate.getTime() ? previousValue : currentValue;
      }, undefined);

      return vacationPeriod ? vacationPeriod.endDate : minDate;
    }
  }

  private getLimitMaxDateSchoolYear(selectedAccommodationObject: ProductAccommodation): Date {
    if (selectedAccommodationObject) {
      const maxDate = new Date(Math.max(...selectedAccommodationObject.schoolYears) + YEARS_IN_FUTURE, 11, 31);
      // vacations that may impact the min date only. Those that are after the min date will be handled when calculating the duration.
      const vacationPeriod = this.getNormalizedVacationPeriods(maxDate, selectedAccommodationObject).reduce((previousValue, currentValue) => {
        return previousValue && previousValue.startDate.getTime() <= currentValue.startDate.getTime() ? previousValue : currentValue;
      }, undefined);

      return vacationPeriod ? vacationPeriod.startDate : maxDate;
    }
  }

  private getVacationWeeksWithinDates(startDate: Date, endDate: Date, vacationPeriods: Array<VacationPeriod>) {
    //retrieves number of full weeks found within start and end date range
    return vacationPeriods
      .filter((vacation) => isDateWithinRange(vacation.startDate, [startDate, endDate]) || isDateWithinRange(vacation.endDate, [startDate, endDate]))
      .reduce((previousValue, currentValue) => previousValue + currentValue.weeks, 0);
  }

  private getNormalizedVacationPeriods(date: Date, accommodation: ProductAccommodation) {
    return this.vacationPeriods
      .filter((vacation) => isDateWithinRange(date, [vacation.startDate, vacation.endDate]))
      .map((vacation) => this.normalizeWithAccommodation(vacation, accommodation.startingDaysOfTheWeek, accommodation.endingDaysOfTheWeek));
  }

  private normalizeWithAccommodation(vacation: VacationPeriod, startingDaysOfTheWeek: Array<number>, endingDaysOfTheWeek: Array<number>) {
    //updates vacations taking into account accommodation starting and ending day of the week
    return {
      startDate: updateToClosestTargetDay(startingDaysOfTheWeek, vacation.startDate),
      endDate: updateToClosestTargetDay(endingDaysOfTheWeek, vacation.endDate),
      weeks: vacation.weeks,
    };
  }

  private calculateLimitedMaxDateForSchoolYear(limitDateSchoolYear: Date, maxDate: Date, selectedDuration = 0): Date {
    if (!limitDateSchoolYear) {
      return maxDate;
    }

    const limitDateSchoolYearWithDuration = addWeeks(limitDateSchoolYear, -selectedDuration);
    return isDateAfter(maxDate, limitDateSchoolYearWithDuration) ? limitDateSchoolYearWithDuration : maxDate;
  }

  private calculateLimitedMinDateForSchoolYear(
    limitMinDateSchoolYear: Date,
    limitMaxDateSchoolYear: Date,
    minDate: Date,
    firstSelectedCourseStartDate: Date,
  ): Date {
    if (!limitMinDateSchoolYear || (firstSelectedCourseStartDate && isInFirstWeekOfYear(firstSelectedCourseStartDate))) {
      return minDate;
    }

    const initialDate = isDateAfter(limitMinDateSchoolYear, minDate) ? limitMinDateSchoolYear : minDate;
    return isDateAfter(initialDate, limitMaxDateSchoolYear) ? limitMaxDateSchoolYear : initialDate;
  }

  private calculateDurationMin(selectedAccommodationObject: ProductAccommodation): number {
    return this.isAccommodationMandatory ? this.tuitionWeeks : selectedAccommodationObject?.durationMin;
  }

  private calculateEndDateWithVacations(date: Date, duration: number, vacationPeriods: Array<VacationPeriod>) {
    if (duration < 1) {
      return date;
    }

    if (vacationPeriods.some((vacation) => isDateWithinRange(date, [vacation.startDate, vacation.endDate]))) {
      return this.calculateEndDateWithVacations(addWeeks(date, 1), duration, vacationPeriods);
    }

    return this.calculateEndDateWithVacations(addWeeks(date, 1), duration - 1, vacationPeriods);
  }

  private getLastSelectedCourse(): CourseQuoteInput {
    return this.selectedCourses[this.selectedCourses.length - 1];
  }

  private getFirstSelectedCourseStartDate(): Date {
    return this.selectedCourses[0]?.startDateObject || getDateFromString(this.selectedCourses[0]?.startDate);
  }

  private removeAccommodationsAfter(index: number, storeChanges = true) {
    for (let i = this.sectionModel.accommodations?.length - 1; i > index; i--) {
      this.removeAccommodationItem(i, false);
    }

    if (storeChanges) {
      this.storeSectionData();
    }
  }

  private fillAccommodationList(accommodationList: ProductAccommodation[] = []) {
    this.accommodations = accommodationList
      .filter((accommodation: ProductAccommodation) => accommodation.durationMin <= this.tuitionWeeks)
      .filter((accommodation: ProductAccommodation) => accommodation.durationMin <= this.calculateDurationMax(0, accommodation))
      .filter((accommodation: ProductAccommodation) => this.isOpenDuringAnyCoursePeriod(accommodation));
    this.accommodationsWithEmptyOption = this.hasNoAccommodationOption()
      ? [
          this.noAccommodationOption,
          ...this.accommodations.map((accommodation: ProductAccommodation) => ({ ...accommodation, weight: this.noAccommodationOption.weight + 1 })),
        ]
      : this.accommodations;
    this.accommodationChanged(0, undefined, false);
    this.storeSectionData();
  }

  /**
   * Recover previously stored data (user filled data)
   */
  private loadStoredData$(): Observable<QuoteInput> {
    return this.stepService.quoteRequest$.pipe(
      take(1),
      mergeMap((quoteInput: QuoteInput) => this.loadSchoolAccommodations$(quoteInput).pipe(map(() => quoteInput))),
      take(1),
      tap(({ accommodations }: QuoteInput) => {
        if (accommodations?.length) {
          accommodations.forEach(({ code, weeks, startDate }: AccommodationQuoteInput, index: number) => {
            this.addAccommodationItem(index, false);
            this.accommodationChanged(index, code, false);
            this.durationChange(index, weeks, false);
            this.accommodationStartDateChange(index, { dateString: startDate, dateObject: getDateFromString(startDate) }, false);
          });
        }
      }),
    );
  }

  private loadSchoolAccommodations$(quoteInput: QuoteInput): Observable<ProductAccommodation[][]> {
    const newSchool: string = quoteInput.school;
    const newCourses: CourseQuoteInput[] = getValidCourses(quoteInput);
    const hasValidSchoolAndCourse = !isNaN(+newSchool) && newCourses?.some((newCourse: CourseQuoteInput) => !isNaN(+newCourse?.code) && newCourse.weeks > 0);
    this.loading = hasValidSchoolAndCourse;
    return hasValidSchoolAndCourse
      ? combineLatest(
          newCourses.map((newCourse: CourseQuoteInput) => this.accommodationService.getByCourse(newSchool, newCourse?.code, newCourse?.startDate)),
        ).pipe(
          tap((accommodationLists: ProductAccommodation[][]) => {
            const accommodationList: ProductAccommodation[] = accommodationLists
              .flat()
              // Remove duplicates
              .reduce((noDuplicatesList: ProductAccommodation[], accommodation: ProductAccommodation) => {
                if (!noDuplicatesList.some((listItem) => listItem.code === accommodation.code)) {
                  noDuplicatesList.push(accommodation);
                }
                return noDuplicatesList;
              }, []);

            this.resetAccommodations(quoteInput, accommodationList);
            this.loading = false;
          }),
          catchError((error) => {
            console.error(error);

            this.resetAccommodations(quoteInput);
            this.loading = false;
            return EMPTY;
          }),
        )
      : of([]);
  }

  private resetAccommodations(quoteInput?: QuoteInput, accommodationList?: ProductAccommodation[]) {
    // 1. Store previous sections selected values
    this.schoolType = quoteInput?.schoolType;
    this.selectedSchool = quoteInput?.school;
    this.selectedCourses = deepClone(getValidCourses(quoteInput));
    this.tuitionWeeks = getTuitionWeeks(quoteInput);
    this.isAccommodationMandatory = isAccommodationMandatory(quoteInput);
    this.hasIncompleteCourses = hasIncompleteCourses(quoteInput);
    this.endDateLastCourse = this.selectedCourses.length && calculateEndDate(this.getLastSelectedCourse());
    this.vacationPeriods = this.extractVacationsFromAccommodation(accommodationList);

    // 2. Clear accommodations
    this.sectionModel.accommodations = null;
    this.accommodationOptions = null;
    this.accommodationAvailability = null;
    this.addAccommodationItem(0);

    // 3. Reset section fields
    this.fillAccommodationList(accommodationList);
    this.resetDateOptions(0);
  }

  private resetDateOptions(index: number) {
    const selectedAccommodationObject = this.selectedAccommodation(index);
    this.accommodationOptions[index].minDate = this.calculateMinDate(index, selectedAccommodationObject);
    this.accommodationOptions[index].maxDate = this.calculateMaxDate(index, selectedAccommodationObject);
    this.accommodationOptions[index].disabledDays = this.calculateAccommodationDisabledDays(index);
    this.accommodationOptions[index].disabledDates = this.calculateAccommodationDisabledDates(index);
    this.accommodationOptions[index].durationMin = this.calculateDurationMin(selectedAccommodationObject);
    this.accommodationOptions[index].durationMax = this.calculateDurationMax(index, selectedAccommodationObject);
  }

  private resetOnChanges$(): Observable<QuoteInput> {
    return this.stepService.quoteRequest$.pipe(
      debounceTime(110),
      mergeMap((quoteInput: QuoteInput) => {
        const { school: newSchoolCode, schoolType } = quoteInput;
        const newCourses: CourseQuoteInput[] = getValidCourses(quoteInput);
        const hasRequiredDataSet = newSchoolCode && newCourses?.length;
        const schoolChanged = this.selectedSchool !== newSchoolCode;
        const schoolTypeChanged = this.schoolType !== schoolType;
        const courseDataChanged =
          this.selectedCourses?.length !== newCourses?.length ||
          newCourses?.some(
            (newCourse: CourseQuoteInput, index: number) =>
              newCourse.code !== this.selectedCourses[index].code ||
              newCourse.startDate !== this.selectedCourses[index].startDate ||
              newCourse.weeks !== this.selectedCourses[index].weeks,
          );

        if (schoolChanged || schoolTypeChanged) {
          this.schoolType = schoolType;
          this.fillAccommodationList();
        }

        let requestAccommodations = false;
        if (hasIncompleteCourses(quoteInput)) {
          // Reset accommodations when a new course was added but not completed
          this.resetAccommodations(quoteInput);
        } else {
          requestAccommodations = hasRequiredDataSet && (schoolChanged || schoolTypeChanged || courseDataChanged);
        }

        if (requestAccommodations) {
          // Get the accommodation list for current selection of school and course and then return the quoteInput
          return this.loadSchoolAccommodations$(quoteInput).pipe(map(() => quoteInput));
        } else {
          return of(quoteInput);
        }
      }),
    );
  }

  private extractVacationsFromAccommodation(accommodationList?: ProductAccommodation[]) {
    if (!accommodationList?.length) {
      return [];
    }

    return this.convertVacations(accommodationList[0].vacations ?? []);
  }

  private convertVacations(vacations?: Array<Vacation>): Array<VacationPeriod> {
    return vacations
      .filter((courseVacation) => courseVacation.durationWeeks > 0)
      .map(({ startDate, endDate, durationWeeks }) => {
        return {
          startDate: getDateFromString(startDate),
          endDate: getDateFromString(endDate),
          weeks: durationWeeks,
        };
      });
  }

  private selectedAccommodation(index: number): ProductAccommodation {
    const code = this.sectionModel.accommodations[index]?.code;
    return code ? this.accommodations?.find((item) => item.code === code) : null;
  }

  private storeSectionData() {
    this.stepService.updatePartialRequest<AccommodationDatesSectionPayload>(this.sectionModel);
  }

  public accommodationChanged(index: number, accommodation: string, storeChanges = true) {
    const targetAccommodation = this.sectionModel.accommodations[index];
    if (targetAccommodation) {
      if (accommodation === this.noAccommodationOption.code) {
        targetAccommodation.code = accommodation;
        targetAccommodation.weeks = undefined;
        this.accommodationStartDateChange(index, undefined, false);

        if (storeChanges) {
          this.storeSectionData();
        }
      } else if (targetAccommodation.code !== accommodation) {
        targetAccommodation.code = accommodation;
        this.durationChange(index, undefined, false);
        this.resetDateOptions(index);

        if (storeChanges) {
          this.storeSectionData();
        }
      }

      this.accommodationAvailability = null;
      const productAccommodation = this.getProductAccommodationByCode(accommodation);
      const isFullyOpen = this.isFullyOpenDuringAnyCoursePeriod(productAccommodation);
      if (!isFullyOpen) {
        const accommodationOpenPeriods = this.getOpeningPerCoursePeriod(productAccommodation);
        this.accommodationAvailability = this.buildAccommodationAvailability(accommodationOpenPeriods);
        this.accommodationOptions[index].durationMax = this.calculateDurationMaxByAvailability(index);
      }
    }
  }

  public calculateDurationMax(index: number, selectedAccommodationObject?: ProductAccommodation): number {
    if (selectedAccommodationObject?.durationMaxUnbounded) {
      return DEFAULT_MAX_WEEKS;
    } else if (this.isAccommodationMandatory && index === 0) {
      return this.tuitionWeeks;
    } else {
      let maxWeeksForCurrentIndex = this.tuitionWeeks ?? 0;
      for (let i = 0; i < index; i++) {
        maxWeeksForCurrentIndex -= this.sectionModel.accommodations[i].weeks ?? 0;
      }
      return Math.min(
        this.tuitionWeeks ?? 0,
        selectedAccommodationObject?.durationMax ?? DEFAULT_MAX_WEEKS,
        maxWeeksForCurrentIndex ?? 0,
        weeksDiff(this.calculateMinDate(index, selectedAccommodationObject), this.calculateMaxDate(index, selectedAccommodationObject)) ?? 0,
      );
    }
  }

  public hasNoAccommodationOption(): boolean {
    return !this.isAccommodationMandatory || this.accommodations.length < 1;
  }

  public hasButtons(): boolean {
    return !this.isAccommodationMandatory && !this.isWithoutAccommodation(this.sectionModel.accommodations[0]);
  }

  public isWithoutAccommodation(accommodationItemToCheck: AccommodationQuoteInput): boolean {
    return accommodationItemToCheck?.code === this.noAccommodationOption.code;
  }

  public addAccommodationItem(index?: number, storeChanges = true) {
    if (!this.sectionModel?.accommodations?.length) {
      this.sectionModel.accommodations = [];
      this.accommodationOptions = [];
    }

    const newAccommodationInput = {} as AccommodationQuoteInput;
    const newAccommodationOptions = {} as AccommodationOptions;
    if (index >= 0 && !storeChanges) {
      this.sectionModel.accommodations[index] = newAccommodationInput;
      this.accommodationOptions[index] = newAccommodationOptions;
    } else {
      this.sectionModel.accommodations.push(newAccommodationInput);
      this.accommodationOptions.push(newAccommodationOptions);
      this.accommodationOptions[index].items = this.getAccommodationList(index);
    }

    if (storeChanges) {
      this.storeSectionData();
    }
  }

  getAccommodationList(index) {
    if (index === 0) {
      return this.accommodations;
    }
    return this.accommodations?.filter((accommodation: ProductAccommodation) => accommodation.durationMin <= this.calculateDurationMax(index, accommodation));
  }

  public removeAccommodationItem(index: number, storeChanges = true) {
    this.sectionModel.accommodations.splice(index, 1);
    for (let i = index; i < this.sectionModel.accommodations.length; i++) {
      this.resetDateOptions(index);
    }

    if (storeChanges) {
      this.storeSectionData();
    }
  }

  public durationChange(index: number, duration: number, storeChanges = true) {
    const targetAccommodation = this.sectionModel.accommodations[index];
    if (targetAccommodation && targetAccommodation.weeks !== duration) {
      targetAccommodation.weeks = duration;
      this.accommodationStartDateChange(index, undefined, storeChanges);
      const selectedAccommodationObject = this.selectedAccommodation(index);
      this.accommodationOptions[index].maxDate = this.calculateMaxDate(index, selectedAccommodationObject);

      if (storeChanges) {
        this.storeSectionData();
      }
    }
  }

  public accommodationStartDateChange(index: number, aDate: DatePickerFieldDate, storeChanges = true) {
    this.sectionModel.accommodations[index].startDate = aDate?.dateString;
    this.sectionModel.accommodations[index].startDateObject = aDate?.dateObject;

    // Remove next accommodations
    this.removeAccommodationsAfter(index, false);

    if (storeChanges) {
      this.storeSectionData();
    }
  }

  private isOpenDuringAnyCoursePeriod(accommodation: ProductAccommodation): boolean {
    return accommodation.openingPeriods?.length == 0 || this.getOpeningPerCoursePeriod(accommodation).length > 0;
  }

  private isFullyOpenDuringAnyCoursePeriod(accommodation: ProductAccommodation): boolean {
    const firstSelectedCourseStartDate = this.getFirstSelectedCourseStartDate();
    return (
      accommodation?.openingPeriods.length == 0 ||
      accommodation?.openingPeriods.find((openingPeriod) => {
        const dateRange: [Date, Date] = [getDateFromString(openingPeriod.from), getDateFromString(openingPeriod.until)];
        return isDateWithinRange(firstSelectedCourseStartDate, dateRange) && isDateWithinRange(this.endDateLastCourse, dateRange);
      }) != undefined
    );
  }

  private getOpeningPerCoursePeriod(accommodation: ProductAccommodation): Array<AccommodationCourseOpenPeriod> {
    return this.selectedCourses
      .map((course) => {
        const openPeriod = accommodation?.openingPeriods.find((openingPeriod) => {
          const courseStartDate = getDateFromString(course.startDate);
          const courseEndDate = calculateEndDate(course);
          const dateRange: [Date, Date] = [getDateFromString(openingPeriod.from), getDateFromString(openingPeriod.until)];
          return isDateWithinRange(courseStartDate, dateRange) || isDateWithinRange(courseEndDate, dateRange);
        });
        return {
          openPeriod,
          course,
        };
      })
      .filter((accommodationDatePeriod) => accommodationDatePeriod.openPeriod !== undefined);
  }

  private getProductAccommodationByCode(accommodationCode: string): ProductAccommodation {
    return this.accommodations.find((accommodation) => accommodation.code === accommodationCode);
  }

  private buildAccommodationAvailability(accommodationOpenPeriods: AccommodationCourseOpenPeriod[]): AccommodationAvailability[] {
    return accommodationOpenPeriods?.map((accommodationOpenPeriod) => {
      const course = accommodationOpenPeriod.course;

      const startCourseDate = getDateFromString(course.startDate);
      const endCourseDate = calculateEndDate(course);

      const openPeriodFrom = getDateFromString(accommodationOpenPeriod.openPeriod.from);
      const openPeriodUntil = getDateFromString(accommodationOpenPeriod.openPeriod.until);

      const dateRange: [Date, Date] = [openPeriodFrom, openPeriodUntil];

      if (isDateWithinRange(startCourseDate, dateRange)) {
        return {
          from: findClosestWeekendDay(startCourseDate, 'sunday'),
          until: openPeriodUntil,
        };
      } else if (isDateWithinRange(endCourseDate, dateRange)) {
        return {
          from: openPeriodFrom,
          until: findClosestWeekendDay(endCourseDate, 'saturday'),
        };
      }
    });
  }

  private calculateDurationMaxByAvailability(index: number): number {
    if (this.accommodationAvailability && this.accommodationAvailability.length == 1) {
      return difference(this.accommodationAvailability[0].from, this.accommodationAvailability[0].until, 'week');
    }
    const selectedAccommodationObject = this.selectedAccommodation(index);
    return this.calculateDurationMax(index, selectedAccommodationObject);
  }

  private getLastCourseDate(accommodation: ProductAccommodation) {
    if (this.endDateLastCourse) {
      const lastCourseStartDate = getDateFromString(this.getLastSelectedCourse().startDate);
      const vacationWeeks = this.getVacationWeeksWithinDates(lastCourseStartDate, this.endDateLastCourse, this.vacationPeriods);
      const lastCourseEndDate = addWeeks(this.endDateLastCourse, vacationWeeks);

      return accommodation ? updateToClosestTargetDay(accommodation.startingDaysOfTheWeek, lastCourseEndDate) : lastCourseEndDate;
    }
  }

  private getCurrentYear(): number {
    return new Date().getFullYear();
  }
}
