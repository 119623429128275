<kng-form-field-selector
  [small]="small"
  [label]="label"
  [disabled]="disabled"
  [value]="value"
  valueExpr="code"
  [itemList]="nationalities"
  [sortCriteria]="sortCriteria"
  [buttonView]="false"
  [placeholder]="placeholder"
  [required]="required"
  (valueChange)="dirty = true; valueChange.emit($event)"
></kng-form-field-selector>
