import { animate, keyframes, query, style, transition, trigger } from '@angular/animations';

export const buttonHintAnimation = [
  trigger('buttonHint', [
    transition('* => *', [
      query(':enter', animate('500ms cubic-bezier(0.4, 0.0, 0.2, 1)', keyframes([style({ opacity: 0 }), style({ opacity: 1 })])), { optional: true }),
      query(':leave', animate('500ms cubic-bezier(0.4, 0.0, 0.2, 1)', keyframes([style({ opacity: 1 }), style({ opacity: 0 })])), { optional: true }),
    ]),
  ]),
];
