<kng-form-field-selector
  [label]="label"
  [value]="value"
  valueExpr="code"
  [itemList]="timezones$ | async"
  [disabled]="disabled"
  [required]="required"
  [buttonView]="false"
  [sortCriteria]="null"
  [placeholder]="placeholder"
  [selectedItemTemplate]="selectedItemTemplate"
  (valueChange)="dirty = true; valueChange.emit($event)"
>
</kng-form-field-selector>

<ng-template #selectedItemTemplate let-selectedItem>
  <div class="timezone-selector__selected-item">
    <div class="timezone-selector__selected-item__img"></div>
    <div class="timezone-selector__selected-item__name">{{ selectedItem?.name }}</div>
  </div>
</ng-template>
