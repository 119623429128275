<kng-loading-spinner i18n-message message="Calculating your quote..." [loading]="loading" shadingColor="rgba(0,0,0,0.5)"></kng-loading-spinner>

<div class="qt-form__content" [ngClass]="{ 'qt-form__content--with-aside': stepDefinition?.displayQuote }">
  <div class="qt-form__content-main" id="qt-form-content-main-id">
    <ng-template #router>
      <router-outlet (activate)="scrollToTop()"></router-outlet>
    </ng-template>

    <kng-step-wrapper [stepDefinition]="stepDefinition">
      <ng-container *ngTemplateOutlet="router"></ng-container>

      <kng-quote-total-price
        #quoteTotalStatic
        class="qt-form__quote-total--static"
        [layout]="QuoteTotalLayouts.EXTENDED"
        [ngClass]="{ 'qt-form__hide': !quoteOutput?.products?.length }"
        [quote]="quoteOutput"
        [selectedCurrency]="exchangeCurrencyCode"
        [hasCurrencySelector]="hasCurrencySelector()"
        [filterVisibleCurrencies]="true"
        (viewDetails)="openSideQuote()"
        (currencyChanged)="updateExchangeCurrency($event)"
      ></kng-quote-total-price>
    </kng-step-wrapper>
  </div>

  <aside class="qt-form__content-side" id="qt-form-content-side-id" *ngIf="stepDefinition?.displayQuote" [@sideQuoteOpen]="isSideQuoteOpen ? 'open' : 'close'">
    <div class="qt-form__content-side__navigation-close">
      <i (click)="closeSideQuote()" class="pi pi-arrow-left qt-form__content-side__navigation-close--icon"></i>
    </div>
    <div class="qt-form__quote-summary" *ngIf="stepDefinition?.displayQuote">
      <h3 i18n>Quote Summary</h3>
      <kng-currency-selector
        *ngIf="hasCurrencySelector()"
        class="qt-form__quote-summary__currency"
        [(currencyCode)]="exchangeCurrencyCode"
        [priceCurrencies]="quoteOutput?.grandTotal"
        [hideDestinationCurrency]="true"
        (currencyCodeChange)="updateExchangeCurrency($event)"
      ></kng-currency-selector>
      <kng-quote
        class="qt-form__quote-summary__quote"
        [ctaButtonHeaderText]="quoteSummaryCtaHeaderText"
        [ctaButtonSubheaderText]="quoteSummaryCtaSubheaderText"
        [languageName]="languageName"
        [schoolName]="schoolName"
        [destination]="destination"
        [quote]="quoteOutput"
        [hasEditButton]="false"
        [hasRemoveButton]="false"
        [hasCurrencySelector]="hasCurrencySelector()"
        [displayDestinationCurrency]="hasCurrencySelector()"
        [selectedCurrency]="exchangeCurrencyCode"
        [displayNoInsurance]="false"
        [displayNoVirtualInternship]="false"
        [displayNoPremiumResidence]="false"
        [displayNoTransfer]="false"
        [hasDisclaimerSection]="true"
        [isProductsAccordionExpanded]="isQuoteProductsAccordionExpanded"
        (expandProductAccordions)="expandProduct()"
        [isDiscountsAccordionExpanded]="isQuoteDiscountsAccordionExpanded"
        (expandDiscountAccordions)="expandDiscount()"
        [emailQuoteEnabled]="isStepValid"
        [showCta]="showCtaQuote"
        (submitQuote)="submitQuote()"
        (currencyChanged)="updateExchangeCurrency($event)"
        [hideDecimals]="true"
        [confirmationDisclaimer]="confirmationDisclaimer"
        [roundedElements]="true"
      ></kng-quote>
    </div>
  </aside>
</div>

<aside class="qt-form__quote-footer" [@footerQuoteOpen]="isQuoteTotalStaticVisible ? 'close' : 'open'">
  <kng-quote-total-price
    [ngClass]="{ 'qt-form__hide': !quoteOutput?.products?.length }"
    [layout]="QuoteTotalLayouts.COLLAPSED"
    [quote]="quoteOutput"
    [selectedCurrency]="exchangeCurrencyCode"
    [filterVisibleCurrencies]="true"
    (viewDetails)="openSideQuote()"
  ></kng-quote-total-price>
</aside>

<kng-quote-print
  class="qt-form__print-version"
  [quoteOutput]="quoteOutput"
  [studentDetails]="studentDetails"
  [currency]="exchangeCurrencyCode"
  [displayDestinationCurrency]="hasCurrencySelector()"
  [infoDisclaimer]="confirmationDisclaimer"
></kng-quote-print>
