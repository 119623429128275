import { Injectable } from '@angular/core';
import { Memoize } from '@klg/shared/utils-http';
import { ConfigurationApiService } from '@klg/shared/api/services';
import { getLocale } from '@klg/shared/i18n';
import { getCompanyHeader, getConfiguration } from '@klg/shared/tokens';
import { Configuration } from '@klg/shared/types';
import { companyMarketConfigUIApiDtoToConfiguration } from '@klg/shared/utils';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

@Injectable({
  providedIn: 'root',
})
export class ConfigurationService {
  private static configuration: Configuration;

  private readonly locale = getLocale();
  private readonly companyHeader = getCompanyHeader();
  private readonly configuration = getConfiguration();

  constructor(private configurationApiService: ConfigurationApiService) {
    ConfigurationService.configuration = this.configuration;
  }

  static getValue(key: string): boolean | string {
    return this.configuration[key];
  }

  @Memoize((language) => language)
  get(language?: string): Observable<Configuration> {
    const locale = language || this.locale;
    return this.configurationApiService.getConfigurationV2(locale, this.companyHeader).pipe(map(companyMarketConfigUIApiDtoToConfiguration));
  }
}
