import { CommonModule } from '@angular/common';
import { Component, ElementRef, Input, ViewChild } from '@angular/core';
import { ButtonHintComponent } from '@klg/shared/ui/button-hint';
import { tooltipAnimation } from '@klg/ui/animations';

type FieldType = 'text' | 'number' | 'checkbox';

@Component({
  standalone: true,
  selector: 'kng-form-field',
  templateUrl: './form-field.component.html',
  styleUrls: ['./form-field.component.scss'],
  animations: [tooltipAnimation],
  imports: [CommonModule, ButtonHintComponent],
})
export class FormFieldComponent {
  @ViewChild('tooltipElement') tooltipElement: ElementRef<HTMLDivElement> | undefined;
  @Input() public label: string | undefined;
  @Input() public tooltip: string | undefined;
  @Input() public small = false;
  @Input() public isValid: null | boolean = false;
  @Input() public disabled = false;
  @Input() public withoutHelper = false;
  @Input() public required = false;
  @Input() public fieldType: FieldType | undefined;
  @Input() public errorMessage: string | undefined;
}
