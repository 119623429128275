import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { BannerComponent, ButtonGroupComponent, DrawerComponent, StarRatingComponent } from './components';
import { ButtonComponent } from '@klg/shared/ui/button';
import { SelectButtonModule } from 'primeng/selectbutton';
import { FormsModule } from '@angular/forms';

@NgModule({
  declarations: [BannerComponent, ButtonGroupComponent, DrawerComponent],
  imports: [CommonModule, ButtonComponent, SelectButtonModule, FormsModule, StarRatingComponent],
  exports: [BannerComponent, ButtonGroupComponent, DrawerComponent],
})
export class UiModule {}
