import { Component, inject, OnDestroy, OnInit } from '@angular/core';
import { AccordionModule } from 'primeng/accordion';
import { AsyncPipe, JsonPipe, NgClass, NgIf, NgTemplateOutlet } from '@angular/common';
import { PriceRequestStudentFormComponent } from '../student-form/price-request-student-form.component';
import { PriceRequestCourseFormComponent } from '../course-form/price-request-course-form.component';
import { PriceRequestExperimentStore } from '../../../../../store/store';
import { BehaviorSubject, combineLatest, Observable, Subject, takeUntil } from 'rxjs';
import { CourseQuoteInput, QuoteInput, StudentDetails } from '@klg/quote-tool/shared/types';
import { addWeeks, getDateFromString } from '@klg/shared/utils';
import { CourseType } from '@klg/shared/data-access/types';
import { DateFormat, DateFormatService } from '@klg/shared/i18n';
import { ButtonComponent } from '@klg/shared/ui/button';
import { StepService } from '@klg/quote-tool/shared/services';
import { ButtonModule } from 'primeng/button';
import { QuoteToolStore } from '@klg/quote-tool/shared/store';
import { ResidenceCountry } from '@klg/shared/data-access/destination';

@Component({
  standalone: true,
  selector: 'kng-price-request-form-accordion',
  templateUrl: './price-request-form-accordion.component.html',
  styleUrls: ['./price-request-form-accordion.component.scss'],
  imports: [
    AccordionModule,
    NgIf,
    PriceRequestStudentFormComponent,
    PriceRequestCourseFormComponent,
    AsyncPipe,
    JsonPipe,
    NgTemplateOutlet,
    ButtonComponent,
    NgClass,
    ButtonModule,
  ],
})
export class PriceRequestFormAccordionComponent implements OnInit, OnDestroy {
  activeIndex: number | undefined = 0;

  quoteInput$: Observable<QuoteInput> | undefined;
  courseType$: Observable<CourseType> | undefined;
  studentDetails$: Observable<StudentDetails> | undefined;

  quoteInputSummary = '';

  filledStudentDetails = false;
  studentDetailsContactInfoSummary = '';
  studentDetailsAddressSummary = '';

  quoteInputSummaryVisible = false;
  studentDetailsSummaryVisible = false;

  readonly formIndexes = {
    courseForm: 0,
    studentForm: 1,
  };

  private closeSubscription$: Subject<void> = new Subject<void>();

  courseFormValid$ = new BehaviorSubject(false);
  studentFormValid$ = new BehaviorSubject(false);

  private closeSubscriptions$: Subject<void> = new Subject<void>();

  private readonly dateFormatService = inject(DateFormatService);
  private readonly priceRequestStore = inject(PriceRequestExperimentStore);
  private readonly quoteToolStore = inject(QuoteToolStore);
  private readonly stepService = inject(StepService);

  ngOnInit(): void {
    this.quoteInput$ = this.priceRequestStore.quoteInput$;
    this.courseType$ = this.priceRequestStore.quoteCourseType$;
    this.studentDetails$ = this.priceRequestStore.studentDetails$;

    combineLatest([this.priceRequestStore.quoteInput$, this.priceRequestStore.quoteCourseType$])
      .pipe(takeUntil(this.closeSubscriptions$))
      .subscribe(([quoteInput, courseType]: [QuoteInput, CourseType]) => {
        if (!quoteInput || !courseType) {
          this.courseFormValid$.next(false);
        } else {
          // Calculate the validity of the step. The step is valid if language, country, weeks and start date are selected and student form is valid
          const { languageCode, countryCode, tuitionWeeks, startDate } = quoteInput;
          this.courseFormValid$.next(Boolean(languageCode && countryCode && tuitionWeeks > 0 && startDate));
        }
      });

    // Prepare the summary of the quote input
    this.prepareQuoteInputSummary();

    // Prepare summary of student details
    this.prepareStudentDetailsSummary();
  }

  ngOnDestroy(): void {
    this.closeSubscription$.next();
  }

  displayStudentForm() {
    this.activeIndex = this.formIndexes.studentForm;

    this.showSummary(this.formIndexes.courseForm);
    this.hideSummary(this.formIndexes.studentForm);
  }

  displayCourseForm() {
    this.activeIndex = this.formIndexes.courseForm;

    this.showSummary(this.formIndexes.studentForm);
    this.hideSummary(this.formIndexes.courseForm);
  }

  editForm($event: Event, index: number) {
    this.activeIndexChange(index);
    $event.preventDefault();
    $event.stopPropagation();
  }

  sendEnquiry() {
    combineLatest([this.priceRequestStore.quoteInput$, this.priceRequestStore.studentDetails$, this.priceRequestStore.quoteCourseType$])
      .pipe(takeUntil(this.closeSubscriptions$))
      .subscribe(([quoteInput, studentDetails, courseType]) => {
        this.stepService.updatePartialRequest<QuoteInput>({
          ...quoteInput,
          courses: [
            <CourseQuoteInput>{
              courseType,
              weeks: quoteInput.tuitionWeeks,
              startDate: quoteInput.startDate,
            },
          ],
        });
        this.stepService.setPartialStudentDetails({ ...studentDetails });

        // The next step is the SEND_QUOTE_TO_EMAIL, and it is the one in charge of calling to /leads endpoint
        this.stepService.navigateToNextStep();

        // Close the subscription
        this.closeSubscriptions$.next();
      });
  }

  activeIndexChange(index: number) {
    this.activeIndex = index;
    switch (index) {
      case this.formIndexes.courseForm:
        this.displayCourseForm();
        break;
      case this.formIndexes.studentForm:
        this.displayStudentForm();
        break;
      default:
        break;
    }
  }

  showSummary(index: number) {
    switch (index) {
      case this.formIndexes.courseForm:
        this.quoteInputSummaryVisible = true;
        break;
      case this.formIndexes.studentForm:
        this.studentDetailsSummaryVisible = true;
        break;
      default:
        break;
    }
  }

  hideSummary(index: number) {
    switch (index) {
      case this.formIndexes.courseForm:
        this.quoteInputSummaryVisible = false;
        break;
      case this.formIndexes.studentForm:
        this.studentDetailsSummaryVisible = false;
        break;
      default:
        break;
    }
  }

  private prepareQuoteInputSummary() {
    combineLatest([this.priceRequestStore.quoteInput$, this.priceRequestStore.quoteCourseType$])
      .pipe(takeUntil(this.closeSubscription$))
      .subscribe(([quoteInput, courseType]: [QuoteInput, CourseType]) => {
        this.quoteInputSummary = '';

        if (!quoteInput || !courseType) {
          return;
        }

        const { country, destinationCity, startDate, tuitionWeeks } = quoteInput;
        if (country && tuitionWeeks && startDate) {
          this.quoteInputSummary += courseType.name;
          this.quoteInputSummary += ` | ${country.name} `;
          this.quoteInputSummary += destinationCity ? ` | ${destinationCity.name} ` : '';
          const endDate = addWeeks(getDateFromString(startDate), tuitionWeeks);
          const formattedStartDate = this.dateFormatService.formatDate(startDate, DateFormat.FULL_MONTH_NAME);
          const formattedEndDate = this.dateFormatService.formatDate(endDate, DateFormat.FULL_MONTH_NAME);
          this.quoteInputSummary += ` | ${formattedStartDate} - ${formattedEndDate}`;
        }
      });
  }

  private prepareStudentDetailsSummary() {
    combineLatest([this.studentDetails$, this.quoteToolStore.countriesOfResidence$])
      .pipe(takeUntil(this.closeSubscription$))
      .subscribe(([studentDetails, countriesOfResidence]: [StudentDetails, ResidenceCountry[]]) => {
        if (!studentDetails) {
          return;
        }

        const { firstName, lastName, email, phoneNumber, postcode, countryOfResidence } = studentDetails;
        this.filledStudentDetails = Boolean(firstName && lastName && email && phoneNumber && postcode && countryOfResidence);
        this.studentDetailsContactInfoSummary = email && phoneNumber ? `${email} | ${phoneNumber}` : '';

        if (postcode && countryOfResidence) {
          // Getting the name of the residence country from the list of countries of residences in the store
          const countryOfResidenceName = countriesOfResidence.find(({ code }) => code === countryOfResidence)?.name || countryOfResidence;
          this.studentDetailsAddressSummary = `${postcode} | ${countryOfResidenceName}`;
        } else {
          this.studentDetailsAddressSummary = '';
        }
      });
  }
}
