<div
  class="quote-accordion-item"
  [ngClass]="{
    'quote-accordion-item--open': isAccordionExpanded,
    'quote-accordion-item--rounded': roundedElements
  }"
  (click)="expandAccordion.emit()"
  [ngPlural]="totalItems || 0"
>
  <ng-template ngPluralCase="=1" i18n>1 item</ng-template>
  <ng-template ngPluralCase="other" i18n>{{ totalItems || 0 }} items</ng-template>
</div>
