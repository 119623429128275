<div class="summary-card" #container>
  <div class="summary-card__cover" *ngIf="mode === 'cover'">
    <ng-container *ngTemplateOutlet="cardCoverTpl ? cardCoverTpl : defaultCardCoverTpl"></ng-container>
  </div>
  <div class="summary-card__back" *ngIf="mode === 'back'">
    <ng-container *ngTemplateOutlet="cardBackTpl ? cardBackTpl : defaultCardBackTpl"></ng-container>
  </div>
</div>

<ng-template #defaultCardCoverTpl>
  <div class="summary-card__label">{{ item[titleExpression] }}</div>
</ng-template>

<ng-template #defaultCardBackTpl>
  <ng-container *ngTemplateOutlet="cardCoverTpl ? cardCoverTpl : defaultCardCoverTpl"></ng-container>
  <div class="summary-card__summary">{{ item[summaryExpression] }}</div>
  <div
    *ngIf="item[imageExpression]"
    class="summary-card__image"
    [ngStyle]="{ backgroundImage: 'url(' + item[imageExpression] + ')', width: imageHeight + 'px' }"
  ></div>
</ng-template>

<kng-offer-label *ngIf="hasPromotion" (showPromotionPopup)="showPromotionPopup(item)"></kng-offer-label>
