import { Component, EventEmitter, Input, Output } from '@angular/core';
import { StepService } from '@klg/quote-tool/shared/services/step-service';

export type CtaIcon = 'add' | 'edit' | 'remove' | 'dots' | 'info';

@Component({
  selector: 'kng-quote-cta-button',
  templateUrl: './quote-cta-button.component.html',
  styleUrls: ['./quote-cta-button.component.scss'],
})
export class QuoteCtaButtonComponent {
  @Input() icon: CtaIcon = 'add';
  @Output() buttonClicked = new EventEmitter<void>();
  public ageGroup = this.stepService.getActiveAgeGroup();

  constructor(private stepService: StepService) {}
}
