import { Injectable } from '@angular/core';
import { ComponentStore } from '@ngrx/component-store';
import { QuoteToolFormState, initialQuoteToolFormState } from './quote-tool.form.state';

@Injectable({ providedIn: 'root' })
export class QuoteToolFormStore extends ComponentStore<QuoteToolFormState> {
  constructor() {
    super(initialQuoteToolFormState);
  }

  // --------------------------Selectors--------------------------

  // Selector for getting validationRequests property
  readonly validationRequests$ = this.select(({ validationRequests }) => validationRequests);

  // Selector for getting the disabledNextButton property from the store
  readonly disabledNextButton$ = this.select(({ disabledNextButton }) => disabledNextButton);

  // --------------------------Updaters--------------------------

  // Updater to reset the store to its initial state
  readonly resetStore = this.updater(() => initialQuoteToolFormState);

  // Updater to update increment the value of validationRequests property
  readonly incrementValidationRequests = this.updater((state, validationRequests: number) => ({
    ...state,
    validationRequests: state.validationRequests + validationRequests,
  }));

  // Updater to update the value of disabledNextButton property
  readonly updateDisabledNextButton = this.updater((state, disabledNextButton: boolean) => ({
    ...state,
    disabledNextButton,
  }));
}
