<div *ngIf="(label || tooltip) && iconValidation" class="icon-validation__label" [class.label--disabled]="disabled">
  {{ label }}
  <span class="icon-validation__label__required" *ngIf="required">*</span>
  <div *ngIf="subLabel" class="icon-validation__sub-label">{{ subLabel }}</div>
  <kng-button-hint *ngIf="tooltip" class="icon-validation__label__button-hint" content="{{ tooltip }}"></kng-button-hint>
</div>

<div
  class="form-field"
  [class.form-field--small]="small"
  [class.form-field--valid]="!iconValidation && isValid === true"
  [class.form-field--invalid]="!iconValidation && isValid === false"
  [class.form-field--disabled]="!iconValidation && disabled"
>
  <div
    *ngIf="(label || tooltip) && !iconValidation"
    [ngClass]="{ 'form-field__label--disabled': disabled, 'form-field__label--withouthelper': withoutHelper }"
    class="form-field__label"
  >
    {{ label }}
    <span class="form-field__label__required" *ngIf="required">*</span>
    <kng-button-hint *ngIf="tooltip" class="form-field__label__button-hint" content="{{ tooltip }}"></kng-button-hint>
  </div>
  <div
    class="form-field__control"
    [class.form-field--valid]="iconValidation && isValid === true"
    [class.form-field--invalid]="iconValidation && isValid === false"
    [class.form-field__control--valid]="iconValidation && isValid === true"
    [class.form-field__control--invalid]="iconValidation && isValid === false"
  >
    <ng-content></ng-content>
  </div>
  <div class="form-field__error-message" *ngIf="isValid === false && errorMessage">
    {{ errorMessage }}
  </div>
</div>
