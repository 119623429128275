import { DEFAULT_LOCALE } from '../constants';

/**
 * It parses the locale to make sure that locales always will have one of these formats: xx or xx-XX
 * @param locale
 */
export const parseLocale = (locale: string) => {
  if (!locale) {
    return DEFAULT_LOCALE;
  }
  const splitLocale = locale?.split('-');
  const parsedLocale = splitLocale?.splice(0, 1)[0].toLowerCase();
  if (!splitLocale?.length) {
    return parsedLocale;
  }

  return `${parsedLocale}-${splitLocale[0].toUpperCase()}`;
};
