import { DateFormatService } from '@klg/shared/i18n';
import { PrimeNGConfig } from 'primeng/api';

// Locale factory function
export function localeFactory(primeNGConfig: PrimeNGConfig): () => Promise<void> {
  return () =>
    new Promise((resolve) => {
      primeNGConfig.setTranslation(DateFormatService.getCalendarLocaleSettings());
      resolve();
    });
}
