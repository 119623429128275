import { Component, EventEmitter, Input, Output } from '@angular/core';
import { CountryRegion } from '@klg/shared/data-access/destination';

@Component({
  selector: 'kng-residence-region-selector',
  templateUrl: './residence-region-selector.component.html',
  styleUrls: ['./residence-region-selector.component.scss'],
})
export class ResidenceRegionSelectorComponent {
  @Input() small = false;
  @Input() label: string;
  @Input() disabled = false;
  @Input() placeholder: string;
  @Input() value: string | undefined;
  @Input() required = false;
  @Input() regions: CountryRegion[] = [];
  @Output() valueChange = new EventEmitter<string>();

  public dirty = false;
}
