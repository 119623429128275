import { inject, Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { first, map } from 'rxjs/operators';
import { getConfiguration } from '@klg/shared/tokens';
import { ResidenceCountryService } from '@klg/shared/data-access/destination';
import { getLocaleCountryCode } from '@klg/shared/i18n';

@Injectable({
  providedIn: 'root',
})
export class ResidenceCurrencyService {
  private marketCountry = getLocaleCountryCode();
  private readonly configuration = getConfiguration();
  private readonly residenceCountryService = inject(ResidenceCountryService);

  getResidenceCurrency(countryOfResidence: string): Observable<string> {
    const country = this.getCountryToUseForCurrencySelection(countryOfResidence);

    return this.residenceCountryService.getByCode(country).pipe(
      first(),
      map((residenceCountry) => residenceCountry?.currency ?? 'USD'),
    );
  }

  private getCountryToUseForCurrencySelection(countryOfResidence: string) {
    if (!countryOfResidence) {
      // no country of residence -> default to market country
      return this.marketCountry;
    }

    const useMarketCurrencyConfigProperty = this.configuration.USE_MARKET_CURRENCY as boolean;
    const useResidenceCurrencyCountriesConfigProperty = this.getUseResidenceCurrencyCountries();
    // useResidenceCurrencyCountriesConfigProperty takes precedence over useMarketCurrencyConfigProperty
    if (!useResidenceCurrencyCountriesConfigProperty.includes(countryOfResidence) && useMarketCurrencyConfigProperty) {
      // we need to use market currency -> return market country
      return this.marketCountry;
    }

    // no changes needed
    return countryOfResidence;
  }

  private getUseResidenceCurrencyCountries(): string[] {
    const configValue = this.configuration?.USE_RESIDENCE_CURRENCY_COUNTRIES as string;
    if (configValue === undefined || configValue === null || configValue.trim() === '') {
      return [];
    }

    try {
      return JSON.parse(configValue) as string[];
    } catch (e) {
      return [];
    }
  }
}
