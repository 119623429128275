import { Component, EventEmitter, Input, OnChanges, OnDestroy, OnInit, Output, SimpleChanges } from '@angular/core';
import { deepClone } from '@klg/shared/utils';
import { ProgramType, ProgramTypeService } from '@klg/shared/data-access/course';
import { Subscription } from 'rxjs';

@Component({
  selector: 'kng-program-type-selector',
  templateUrl: './program-type-selector.component.html',
  styleUrls: ['./program-type-selector.component.scss'],
})
export class ProgramTypeSelectorComponent implements OnInit, OnDestroy, OnChanges {
  @Input() label: string;
  @Input() disabled = false;
  @Input() value: string = undefined;
  @Input() buttonView = false;
  @Input() placeholder: string;
  @Input() isOpen = false;
  @Input() courseTypeCodes: string[];
  @Input() firstItem: ProgramType = null;
  @Output() valueChange = new EventEmitter<string>();
  @Output() itemChange = new EventEmitter<ProgramType>();

  private subscription = new Subscription();

  public itemList: ProgramType[] = null;
  public selectedValue: string;

  constructor(private programTypeService: ProgramTypeService) {}

  private getItemList() {
    // Unsubscribe from previous, subscription and create a new one.
    // If we don't create a new one, it will unsubscribe from the subscription added next
    this.subscription?.unsubscribe();
    this.subscription = new Subscription();
    const getProgramTypeList$ = this.courseTypeCodes ? this.programTypeService.getAllByCourseTypes(this.courseTypeCodes) : this.programTypeService.getAll();

    this.subscription.add(
      getProgramTypeList$.subscribe((data) => {
        this.itemList = [...(this.firstItem ? [this.firstItem] : []), ...data.map(deepClone).sort((a, b) => (a.name > b.name ? 1 : -1))];
      }),
    );
  }

  ngOnInit() {
    this.getItemList();
  }

  ngOnChanges({ value, courseTypeCodes }: SimpleChanges): void {
    if (value && value.currentValue) {
      this.selectedValue = value.currentValue;
    }

    if (courseTypeCodes) {
      this.getItemList();
    }
  }

  ngOnDestroy() {
    this.subscription.unsubscribe();
  }
}
