import { Directive, Input } from '@angular/core';
import { FormData } from '@klg/core/forms/form-data.model';
import { FormDataInputValidityDirective } from './form-data-input-validity.directive';
import { postcodeValidator } from '@klg/core/validators/postcode.validator';

/**
 * Directive that handles the validity of a form data.
 * If the field is focused, validation occurs while clicking outside - the first time data is entered.
 * If the field is input, validation occurs while typing - subsequent times data is entered.
 */

@Directive({
  standalone: true,
  selector: '[kngFormDataPostcodeValidity]',
})
export class FormDataPostcodeValidityDirective<T> extends FormDataInputValidityDirective<T> {
  @Input() formData: FormData<T> | undefined;

  protected getNoFormDataWarningMessage() {
    return 'FormDataEmailValidityDirective - Form data is not defined';
  }

  protected calculateFormDataValidity() {
    return super.calculateFormDataValidity() && this.isPostcodeValid();
  }

  private isPostcodeValid(): boolean {
    return postcodeValidator(this.formData?.value?.toString());
  }
}
