import { Component, OnDestroy, OnInit } from '@angular/core';
import { PriceRequestCourseFormComponent } from '../course-form/price-request-course-form.component';
import { SectionTemplateComponent } from '../../../../sections/section-template/section-template.component';
import { PriceRequestStudentFormComponent } from '../student-form/price-request-student-form.component';
import { AsyncPipe, NgIf } from '@angular/common';
import { combineLatest, Observable } from 'rxjs';
import { ButtonComponent } from '@klg/shared/ui/button';
import { map, startWith } from 'rxjs/operators';
import { BasePriceRequestExperiment } from '../base-price-request-experiment.directive';

@Component({
  standalone: true,
  selector: 'kng-price-request-form-simplified',
  templateUrl: './price-request-form-simplified.component.html',
  styleUrls: ['./price-request-form-simplified.component.scss'],
  imports: [PriceRequestCourseFormComponent, SectionTemplateComponent, PriceRequestStudentFormComponent, NgIf, AsyncPipe, ButtonComponent],
})
export class PriceRequestFormSimplifiedComponent extends BasePriceRequestExperiment implements OnInit, OnDestroy {
  courseFormTitle = $localize`Program and school`;
  studentFormTitle = $localize`Tell us a bit more about you`;

  disabledSubmitButton$: Observable<boolean> | undefined;

  ngOnInit(): void {
    super.ngOnInit();
    this.disabledSubmitButton$ = combineLatest([this.courseFormValid$, this.studentFormValid$, this.validationRequests$]).pipe(
      map(([courseFormValid, studentFormValid, validationAttempts]) => !courseFormValid || (courseFormValid && validationAttempts > 0 && !studentFormValid)),
      startWith(true),
    );
  }

  setStudentFormValidity(isValid: boolean) {
    this.studentFormValid$.next(isValid);
  }
}
