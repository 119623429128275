import { animate, state, style, transition, trigger } from '@angular/animations';

export const courseSupplementAnimation = [
  trigger('addOrRemove', [
    state('add', style('*')),
    state('remove', style({ transform: 'rotate(45deg)' })),
    transition('void => *', animate(0)),
    transition('add <=> remove', animate('200ms ease-in-out')),
  ]),
  trigger('showOrHide', [
    state('show', style('*')),
    state('hide', style({ width: 0, padding: 0, margin: 0, opacity: 0, visibility: 'hidden' })),
    transition('void => *', animate(0)),
    transition('show <=> hide', animate('200ms ease-in-out')),
  ]),
];
