import { Pipe, PipeTransform } from '@angular/core';
import { convertTimeWIthOffsetToString } from '@klg/shared/utils';

@Pipe({
  name: 'offsetTime',
  standalone: true,
})
export class OffsetTimePipe implements PipeTransform {
  // aTime: expected formatted time: HH:MM (Hours: 00-23 Minutes:00-59)
  // offset: expected offset in millisecs (examples: ((3*60)*60)*1000, ((-2*60)*60)*1000, etc)
  transform(aTime: string, offset: number): string {
    return convertTimeWIthOffsetToString(aTime, offset);
  }
}
