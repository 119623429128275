import { Inject, Injectable, OnDestroy } from '@angular/core';
import { BASE_PATH } from '@klg/shared/api/variables';
import { Amount } from '@klg/shared/data-access/types';
import { getConfiguration } from '@klg/shared/tokens';
import { addLibrary, removeLibrary } from '@klg/shared/utils';
import { combineLatest, fromEvent, Observable, Subject, Subscription } from 'rxjs';
import { tap } from 'rxjs/operators';
import { CALLBACK_VERSION, DEFAULT_RECIPIENT, FLYWIRE_PATH } from '../constants/flywire.constants';
import { PAYMENTS_GATEWAY_ENVIRONMENT_VARIABLES } from '../tokens';
import { FlywirePayerInfo, PaymentDataObj } from '../types';
import { FlywireConfigurationVariables } from '../types/gateways/flywire-configuration-variables.type';
import { PaymentGatewayService } from './payment-gateway.service';

declare const flywire: any;

interface PaymentObject {
  quoteAmount: Amount;
  depositJwtToken: string;
  payerInfo: FlywirePayerInfo;
  customData: Record<string, unknown>;
  paymentRecipient: string;
  locale: string;
  basePath: string;
}

@Injectable()
export class FlywireGatewayService implements PaymentGatewayService<FlywirePayerInfo>, OnDestroy {
  private scriptLoadedSubject = new Subject<Event>();
  private makePaymentSubject = new Subject<PaymentObject>();
  private makePaymentAction$: Observable<PaymentObject> = this.makePaymentSubject.asObservable();
  private subscription = new Subscription();

  private scriptElement: HTMLScriptElement;

  private readonly PAY_BUTTON_ID = 'pay-now';
  private readonly configuration = getConfiguration();

  constructor(
    @Inject(PAYMENTS_GATEWAY_ENVIRONMENT_VARIABLES) private readonly paymentConfiguration: FlywireConfigurationVariables,
    @Inject(BASE_PATH) private readonly basePath: string,
  ) {
    this.initializePaymentSubscription();
  }

  bootstrap(): void {
    this.scriptElement = addLibrary(FLYWIRE_PATH);
    this.subscription.add(fromEvent(this.scriptElement, 'load', { once: true }).subscribe(this.scriptLoadedSubject));
  }

  makePayment(
    quoteAmount: Amount,
    depositJwtToken: string,
    payerInfo: FlywirePayerInfo,
    customData: Record<string, unknown>,
    paymentRecipient: string,
    locale = this.configuration.FLYWIRE_LOCALE as string,
    basePath = this.basePath,
  ): any {
    this.makePaymentSubject.next({ quoteAmount, depositJwtToken, payerInfo, customData, paymentRecipient, locale, basePath });
  }

  ngOnDestroy(): void {
    if (this.scriptElement) {
      removeLibrary(this.scriptElement);
    }
    this.subscription.unsubscribe();
  }

  private initializePaymentSubscription(): void {
    this.subscription = combineLatest([this.scriptLoadedSubject.asObservable(), this.makePaymentAction$])
      .pipe(
        tap(([, { quoteAmount, depositJwtToken, payerInfo, customData, paymentRecipient, locale, basePath }]) => {
          const flywireTemporaryButton = this.createFlywireTemporaryButton();
          flywire?.Checkout?.render(
            this.getPaymentDataObj(quoteAmount, depositJwtToken, payerInfo, customData, paymentRecipient, locale, basePath),
            `#${this.PAY_BUTTON_ID}`,
          );
          document.getElementById(this.PAY_BUTTON_ID)?.click();
          flywireTemporaryButton.parentNode.removeChild(flywireTemporaryButton);
        }),
      )
      .subscribe();
  }

  private createFlywireTemporaryButton(): HTMLButtonElement {
    const flywireTemporaryButton: HTMLButtonElement = document.createElement('button');
    flywireTemporaryButton.id = this.PAY_BUTTON_ID;
    flywireTemporaryButton.style.width = '0';
    flywireTemporaryButton.style.height = '0';
    document.body.appendChild(flywireTemporaryButton);
    return flywireTemporaryButton;
  }

  private getPaymentDataObj(
    quoteAmount: Amount,
    depositJwtToken: string,
    payerInfo: FlywirePayerInfo,
    customData: Record<string, unknown>,
    paymentRecipient: string,
    locale: string,
    basePath: string,
  ): PaymentDataObj {
    const { environment, provider, returnUrl, callbackUrl } = this.paymentConfiguration;
    const { value } = quoteAmount;
    return {
      //Payment Portal
      env: environment,
      recipient: paymentRecipient ?? DEFAULT_RECIPIENT,

      locale,
      provider,

      //amount in pennies
      amount: (value * 100).toFixed(0),

      //Payer information
      ...payerInfo,
      displayPayerInformation: true,

      //Custom data
      ...customData,

      return_url: returnUrl.includes('http') ? returnUrl : `${window.location.origin}${this.basePath}${returnUrl}`,

      // Callback info
      callback_version: CALLBACK_VERSION,
      callback_id: depositJwtToken,
      callback_url: `${basePath}${callbackUrl}`,
    };
  }
}
