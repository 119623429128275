import { DOCUMENT } from '@angular/common';
import {
  AfterContentChecked,
  AfterViewInit,
  ChangeDetectorRef,
  Component,
  ElementRef,
  EventEmitter,
  inject,
  Inject,
  OnDestroy,
  OnInit,
  Output,
  ViewChild,
} from '@angular/core';
import { hasCurrency } from '@klg/currency/shared/functions/currency-price.functions';
import { CurrencyCode } from '@klg/currency/shared/model/currency.model';
import { Language, LanguageService } from '@klg/language';
import {
  getCourseWithMaxStartDateFromQuoteCourses,
  getDefaultQuoteCurrency,
  getDestinationFromInput,
  getMaxCourseStartDateYearFromQuoteCourses,
  QuoteFormLinkUrlsService,
} from '@klg/quote-tool/shared/data-access/quote';
import { CurrencyData, QuoteFormLinkUrls, QuoteOutput, QuoteTotalLayouts, SchoolAndCourseData, StudentDetails } from '@klg/quote-tool/shared/types/quote';
import { SchoolTypesEnum } from '@klg/shared/data-access/types';
import { getToday } from '@klg/shared/utils';
import { quoteOpenAnimation } from '@klg/ui/animations';
import { combineLatest, EMPTY, iif, Observable, of, Subject, takeUntil } from 'rxjs';
import { catchError, filter, map, switchMap, take, tap } from 'rxjs/operators';
import { ROUTE_IDS, StepDefinition } from '@klg/quote-tool/shared/types';
import { StepService } from '@klg/quote-tool/shared/services/step-service';
import { HostDataReaderService } from '@klg/quote-tool/shared/services';
import { FormStepDefinition } from './shared/types/form.step.definition.interface';
import { QuoteToolFormStore } from './shared/store/quote-tool.form.store';

type NewQuoteDetails = [QuoteOutput, Language];

const TODAY_YEAR = getToday().getFullYear();

@Component({
  selector: 'kng-form',
  templateUrl: './form.component.html',
  styleUrls: ['./form.component.scss', './form.component.print.scss'],
  animations: [quoteOpenAnimation],
})
export class FormComponent implements OnInit, OnDestroy, AfterViewInit, AfterContentChecked {
  @ViewChild('quoteTotalStatic', { read: ElementRef }) quoteTotalStatic: ElementRef;

  @Output() submitQuoteEvent = new EventEmitter<QuoteOutput>();

  exchangeCurrencyCode: CurrencyCode;

  stepDefinition: StepDefinition;

  loading = false;

  // Quote Summary Info
  languageName: string;
  schoolName: string;
  destination: string;
  quoteOutput: QuoteOutput;
  studentDetails: StudentDetails;
  isQuoteProductsAccordionExpanded = true;
  isQuoteDiscountsAccordionExpanded = true;
  isStepValid: boolean;
  isSideQuoteOpen = false;
  isQuoteTotalStaticVisible: boolean;
  quoteSummaryCtaHeaderText: string;
  quoteSummaryCtaSubheaderText: string;
  QuoteTotalLayouts = QuoteTotalLayouts;

  confirmationDisclaimer = '';

  showCtaQuote = true;

  disabledNextButton$: Observable<boolean> | undefined;

  private destinationCurrency: string;
  private confirmationLinkUrl: string;

  private readonly destroy$ = new Subject<void>();

  private readonly changeDetector = inject(ChangeDetectorRef);
  private readonly quoteToolFormStore = inject(QuoteToolFormStore);
  private readonly stepService = inject(StepService);
  private readonly languageService = inject(LanguageService);
  private readonly hostDataReader = inject(HostDataReaderService);
  private readonly linkUrlsService = inject(QuoteFormLinkUrlsService);

  constructor(@Inject(DOCUMENT) private document: Document) {}

  ngOnInit(): void {
    this.stepService.initiateNavigation(ROUTE_IDS.FORM_ROOT);

    this.disabledNextButton$ = this.quoteToolFormStore.disabledNextButton$;

    combineLatest([this.stepService.currentStepDefinition$, this.loadPrefilledData$()])
      .pipe(
        tap(([stepDefinition]: [FormStepDefinition, boolean]) => {
          this.stepDefinition = stepDefinition;
          this.setQuoteSummaryTextButton(stepDefinition);
        }),
        switchMap(([stepDefinition]: [StepDefinition, boolean]) => iif(() => stepDefinition?.displayQuote, this.requestQuoteAndDetails$(), EMPTY)),
        takeUntil(this.destroy$),
      )
      .subscribe();

    this.linkUrlsService
      .getLinkUrls$()
      .pipe(takeUntil(this.destroy$))
      .subscribe((links: QuoteFormLinkUrls) => {
        this.confirmationLinkUrl = links.confirmationLinkUrl;
      });

    this.stepService.currentStepDefinition$
      .pipe(
        filter((stepDefinition: StepDefinition) => stepDefinition?.stepId === ROUTE_IDS.THANK_YOU),
        takeUntil(this.destroy$),
      )
      .subscribe(() => {
        this.stepService.clearSessionStorage();
        if (this.confirmationLinkUrl) {
          this.document.location.replace(this.confirmationLinkUrl);
        }
      });

    this.stepService.calculatingQuote$.pipe(takeUntil(this.destroy$)).subscribe((calculatingQuote: boolean) => {
      this.loading = calculatingQuote;
    });

    this.stepService
      .getCurrentStepValid()
      .pipe(takeUntil(this.destroy$))
      .subscribe((isStepValid: boolean) => {
        this.isStepValid = isStepValid;
      });

    this.stepService.studentDetails$.pipe(takeUntil(this.destroy$)).subscribe((studentDetails: StudentDetails) => {
      this.studentDetails = studentDetails;
    });
  }

  ngAfterViewInit() {
    const observer = new IntersectionObserver(
      (entries) => {
        entries.forEach((entry) => {
          this.isQuoteTotalStaticVisible = entry.isIntersecting && entry.intersectionRatio > 0.3;
        });
      },
      { threshold: [0.3, 0] },
    );
    observer.observe(this.quoteTotalStatic.nativeElement);
  }

  ngAfterContentChecked(): void {
    this.changeDetector.detectChanges();
  }

  ngOnDestroy(): void {
    this.destroy$.next();
  }

  expandProduct() {
    this.isQuoteProductsAccordionExpanded = !this.isQuoteProductsAccordionExpanded;
  }

  expandDiscount() {
    this.isQuoteDiscountsAccordionExpanded = !this.isQuoteDiscountsAccordionExpanded;
  }

  submitQuote() {
    // Before doing anything else, make sure that the dialog is closed and if not, close it
    if (this.isSideQuoteOpen) {
      this.closeSideQuote();
    }

    // If submitStep is true, emit event with quoteOutput
    if (this.stepDefinition.submitStep) {
      this.submitQuoteEvent.emit(this.quoteOutput);
    }

    // If there is a defined nextStep, navigate to nextStep
    if (this.stepDefinition.nextStep) {
      this.stepService.navigateToNextStep();
    }
  }

  hasCurrencySelector(): boolean {
    return this.quoteOutput?.grandTotal?.filter((amount) => amount.visible).length > 1;
  }

  scrollToTop() {
    window.scroll(0, 0);
  }

  private setQuoteSummaryTextButton(stepDefinition: StepDefinition): void {
    if (!stepDefinition) {
      return;
    }
    this.quoteSummaryCtaHeaderText = stepDefinition.nextButton;
    this.showCtaQuote = !!this.stepDefinition.nextButton;
  }

  openSideQuote() {
    this.isSideQuoteOpen = true;
  }

  closeSideQuote() {
    this.isSideQuoteOpen = false;
  }

  updateExchangeCurrency(newCurrencySelected: string) {
    this.exchangeCurrencyCode = newCurrencySelected;
    this.stepService.updatePartialRequest<CurrencyData>({ exchangeCurrencyCode: newCurrencySelected });
  }

  private loadPrefilledData$(): Observable<boolean> {
    return this.hostDataReader.getPrefilledSchoolCourse$().pipe(
      take(1),
      tap((schoolAndCourseData: SchoolAndCourseData) => {
        if (schoolAndCourseData?.school) {
          this.stepService.setPartialRequest<SchoolAndCourseData>(schoolAndCourseData);
        }
      }),
      map((schoolAndCourseData: SchoolAndCourseData) => Boolean(schoolAndCourseData?.school)),
    );
  }

  private requestQuoteAndDetails$(): Observable<NewQuoteDetails> {
    return this.stepService.requestActiveQuote().pipe(
      switchMap((quoteOutput: QuoteOutput) =>
        combineLatest([of(quoteOutput), this.languageService.get(quoteOutput?.input?.languageCode).pipe(catchError(() => of(null)))]),
      ),
      tap(([quoteOutput, language]: NewQuoteDetails) => {
        this.quoteOutput = quoteOutput;
        // If destinationCurrency changes then reset exchangeCurrencyCode
        const quoteCurrencyByDefault = getDefaultQuoteCurrency(quoteOutput);
        if (this.destinationCurrency !== quoteCurrencyByDefault) {
          this.destinationCurrency = quoteCurrencyByDefault;
          this.updateExchangeCurrency(this.destinationCurrency);
        }

        // Check if the exchangeCurrencyCode can be displayed (does the price list have that currency?)
        if (quoteOutput?.grandTotal) {
          if (!hasCurrency(quoteOutput?.grandTotal, this.exchangeCurrencyCode)) {
            const exchangeCurrencyCode = hasCurrency(quoteOutput?.grandTotal, this.destinationCurrency)
              ? this.destinationCurrency
              : quoteOutput.grandTotal[0].currency;
            this.updateExchangeCurrency(exchangeCurrencyCode);
          }
        }

        this.languageName = language?.name;
        this.destination = getDestinationFromInput(this.quoteOutput?.input);
        this.schoolName = this.quoteOutput?.input?.schoolObject?.name;
        if (this.quoteHasNotConfirmedDate(quoteOutput)) {
          const maxYear = getMaxCourseStartDateYearFromQuoteCourses(quoteOutput);
          this.confirmationDisclaimer =
            quoteOutput.input.schoolType === SchoolTypesEnum.ADULT
              ? this.adultConfirmationDisclaimer(maxYear).toString()
              : this.juniorConfirmationDisclaimer(maxYear).toString();
        } else {
          this.confirmationDisclaimer = '';
        }
      }),
    );
  }

  private quoteHasNotConfirmedDate(quoteOutput: QuoteOutput): boolean {
    const maxStartDateCourse = getCourseWithMaxStartDateFromQuoteCourses(quoteOutput);
    return (
      maxStartDateCourse && !maxStartDateCourse.dateConfirmed && quoteOutput?.products?.some((quoteProduct) => quoteProduct.code === maxStartDateCourse.code)
    );
  }

  private juniorConfirmationDisclaimer(quoteYear: number): string {
    return $localize`We will contact you soon to confirm prices, date and programme availability for ${quoteYear}:next_year:.`;
  }

  private adultConfirmationDisclaimer(quoteYear: number): string {
    let disclaimer = $localize`We will contact you soon to confirm date, price and availability.`;
    if (getToday().getMonth() < 10 && TODAY_YEAR <= quoteYear - 1) {
      /**
       * Disclaimer for early bird
       * When quote done before the 1st Nov with start dates next year, this message is displayed
       * to make the user aware that after 01st of November the prices might change ( increase )
       * and if they want to keep this price they should book it before that date.
       */
      const currentPricesYear = getToday().getMonth() < 10 ? TODAY_YEAR : TODAY_YEAR + 1;
      disclaimer =
        $localize`Book before 01.11.${currentPricesYear}:current_year: to get ${currentPricesYear}:current_year: prices for your ${quoteYear}:next_year: stay.` +
        ' ' +
        disclaimer;
    }
    return disclaimer;
  }
}
