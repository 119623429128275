import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { formRoutes } from './routes';

@NgModule({
  declarations: [],
  imports: [RouterModule.forChild(formRoutes)],
  exports: [RouterModule],
})
export class QuoteToolFormRoutingModule {}
