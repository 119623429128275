<kng-section-template [sectionIndex]="sectionIndex" i18n-sectionTitle sectionTitle="Tell Us About You" data-qa="enrolment-tell-us">
  <ng-container *ngTemplateOutlet="isEnrolmentForm() ? enrollmentFormFields : freeQuoteFormFields"></ng-container>
</kng-section-template>

<ng-template #freeQuoteFormFields>
  <ng-container *ngTemplateOutlet="fullNameFields"></ng-container>

  <ng-container *ngTemplateOutlet="isPriceRequestForm() ? dateOfBirthField : null"></ng-container>

  <ng-container *ngTemplateOutlet="contactFields"></ng-container>

  <ng-container *ngTemplateOutlet="addressFields; context: { fullAddress: false }"></ng-container>

  <ng-container *ngTemplateOutlet="isPriceRequestForm() ? nationalityField : null"></ng-container>
</ng-template>

<ng-template #enrollmentFormFields>
  <ng-container *ngTemplateOutlet="fullNameFields"></ng-container>

  <ng-container *ngTemplateOutlet="addressFields; context: { fullAddress: true }"></ng-container>

  <ng-container *ngTemplateOutlet="contactFields"></ng-container>

  <ng-container *ngTemplateOutlet="dateOfBirthField"></ng-container>

  <kng-form-field
    [isValid]="formData.placeOfBirth.isValid"
    [required]="formData.placeOfBirth.required"
    [small]="true"
    i18n-label
    label="Place of birth"
    data-qa="enrolment-place-of-birthday"
  >
    <input pInputText type="text" (input)="onValueChanged('placeOfBirth')" [(ngModel)]="formData.placeOfBirth.value" />
  </kng-form-field>

  <ng-container *ngTemplateOutlet="nationalityField"></ng-container>

  <kng-mother-tongue
    (valueChange)="onSelectChanged($event, 'motherTongue')"
    [(value)]="formData.motherTongue.value"
    [required]="formData.motherTongue.required"
    [small]="true"
    i18n-label
    label="Mother tongue"
    data-qa="enrolment-mother-language"
  ></kng-mother-tongue>
</ng-template>

<ng-template #fullNameFields>
  <kng-form-field-selector
    valueExpr="code"
    [itemList]="titleOptions"
    [required]="true"
    [searchEnabled]="false"
    i18n-label
    label="Title"
    (valueChange)="onSelectChanged($event, 'title')"
    data-qa="qt-title-selector"
  ></kng-form-field-selector>

  <kng-form-field
    *ngIf="formData.identityDocument && identityDocumentFieldIsEnabled"
    [isValid]="formData.identityDocument.isValid"
    [required]="formData.identityDocument.required"
    [small]="true"
    i18n-label
    label="N.I.F / N.I.E / PASAPORTE"
    data-qa="qt-identity-document-field"
  >
    <input pInputText type="text" (input)="onValueChanged('identityDocument')" [(ngModel)]="formData.identityDocument.value" />
  </kng-form-field>

  <kng-form-field
    class="qt-form-section__start-new-line"
    [isValid]="formData.firstName.isValid"
    [required]="formData.firstName.required"
    [small]="true"
    i18n-label
    label="First name"
    data-qa="qt-first-name-field"
  >
    <input pInputText type="text" (input)="onValueChanged('firstName')" [(ngModel)]="formData.firstName.value" />
  </kng-form-field>

  <kng-form-field [isValid]="formData.lastName.isValid" [required]="formData.lastName.required" [small]="true" i18n-label label="Last name">
    <input pInputText type="text" (input)="onValueChanged('lastName')" [(ngModel)]="formData.lastName.value" data-qa="qt-last-name-field" />
  </kng-form-field>
</ng-template>

<ng-template #dateOfBirthField>
  <kng-date-of-birth-field
    [isValid]="formData.dateOfBirth.isValid"
    [required]="formData.dateOfBirth.required"
    (dateChange)="onSelectBirthDate($event)"
    data-qa="enrolment-date-birthday-date-picker"
  ></kng-date-of-birth-field>
</ng-template>

<ng-template #contactFields>
  <kng-form-field [isValid]="formData.email.isValid" [required]="formData.email.required" [small]="true" i18n-label label="Email address">
    <input pInputText type="text" (input)="onValueChanged('email')" [(ngModel)]="formData.email.value" data-qa="qt-email-selector" />
  </kng-form-field>

  <kng-form-field [isValid]="formData.phoneNumber.isValid" [required]="formData.phoneNumber.required" [small]="true" i18n-label label="Mobile">
    <input
      pInputText
      type="tel"
      (input)="onValueChanged('phoneNumber')"
      [(ngModel)]="formData.phoneNumber.value"
      i18n-placeholder
      placeholder="e.g. +442070455000"
      data-qa="qt-phone-number-field"
    />
  </kng-form-field>
</ng-template>

<ng-template #addressFields let-fullAddress="fullAddress">
  <kng-form-field *ngIf="fullAddress" [isValid]="formData.address.isValid" [required]="formData.address.required" [small]="true" i18n-label label="Address">
    <input pInputText type="text" (input)="onValueChanged('address')" [(ngModel)]="formData.address.value" data-qa="qt-address-field" />
  </kng-form-field>

  <kng-form-field [isValid]="formData.postcode.isValid" [required]="formData.postcode.required" [small]="true" i18n-label label="Postcode">
    <input pInputText type="text" (input)="onValueChanged('postcode')" [(ngModel)]="formData.postcode.value" data-qa="qt-post-code-field" />
  </kng-form-field>

  <kng-form-field [isValid]="formData.city.isValid" [required]="formData.city.required" [small]="true" i18n-label label="City/Town">
    <input pInputText type="text" (input)="onValueChanged('city')" [(ngModel)]="formData.city.value" data-qa="qt-city-field" />
  </kng-form-field>

  <kng-residence-country-selector
    (valueChange)="onSelectChanged($event, 'countryOfResidence')"
    [(value)]="formData.countryOfResidence.value"
    [required]="formData.countryOfResidence.required"
    [small]="true"
    i18n-label
    label="Country"
    data-qa="qt-country-selector"
  ></kng-residence-country-selector>
</ng-template>

<ng-template #nationalityField>
  <kng-nationality-selector
    (valueChange)="onSelectChanged($event, 'nationality')"
    [(value)]="formData.nationality.value"
    [isValid]="formData.nationality.isValid"
    [required]="formData.nationality.required"
    [small]="true"
    i18n-label
    label="Nationality"
    data-qa="enrolment-nationality-field"
  ></kng-nationality-selector>
</ng-template>
