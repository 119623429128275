import { Injectable } from '@angular/core';
import { ProductsControllerApiService } from '@klg/shared/api/services';
import { BaseService, CachedRequestResponse } from '@klg/shared/utils-http';
import { Observable, of } from 'rxjs';
import { map } from 'rxjs/operators';
import { QuoteSchool } from '@klg/quote-tool/shared/types/school';

@Injectable({ providedIn: 'root' })
export class QuoteSchoolService extends BaseService<QuoteSchool> {
  protected responseCache = new CachedRequestResponse<QuoteSchool[]>(
    () => this.productsApiService.getSchools(this.locale, this.companyHeader),
    this.cacheSettings.get,
  );

  constructor(private productsApiService: ProductsControllerApiService) {
    super();
  }

  getCountryCodesForLanguage(languageCode: string): Observable<string[]> {
    return this.getAll().pipe(
      map((schools) => schools.filter((school) => school.courseLanguages.some((language) => language.code === languageCode)).map((school) => school.country)),
    );
  }

  getAllByCountryAndLanguage(countryCode: string, languageCode: string): Observable<QuoteSchool[]> {
    return this.getAll().pipe(
      map((schools) => {
        return schools.filter((school) => school.country === countryCode && school.courseLanguages.some((language) => language.code === languageCode));
      }),
    );
  }

  getAllByCountryCityOrLanguage(countryCode?: string, cityCode?: string, languageCode?: string): Observable<QuoteSchool[]> {
    return this.getAll().pipe(
      map((schools) => {
        return schools.filter(
          (school) =>
            (!countryCode || school.country === countryCode) &&
            (!cityCode || school.city === cityCode) &&
            school.courseLanguages.some((language) => !languageCode || language.code === languageCode),
        );
      }),
    );
  }

  getAllWithFilterFn(filterFn: (quoteSchool: QuoteSchool) => boolean): Observable<QuoteSchool[]> {
    return this.getAll().pipe(map((schools) => schools.filter(filterFn)));
  }

  override getByCode(code: string): Observable<QuoteSchool> {
    return this.getAll().pipe(map((schools) => schools.find((s) => s.code === code)));
  }

  protected getResponseCache$(): Observable<CachedRequestResponse<QuoteSchool[]>> {
    return of(this.responseCache);
  }
}
