<kng-section-template [columns]="columns" [sectionTitle]="title">
  <kng-form-error-message *ngIf="withValidationErrors" ngProjectAs="additional-info"></kng-form-error-message>
  <div class="price-request-student-form__form-group">
    <kng-form-field
      i18n-label
      label="First name"
      [small]="true"
      [isValid]="formData.firstName.isValid"
      [required]="formData.firstName.required"
      [errorMessage]="formData.firstName.errorMessage"
      [iconValidation]="true"
    >
      <input
        type="text"
        pInputText
        kngFormDataInputValidity
        [formData]="formData.firstName"
        [(ngModel)]="formData.firstName.value"
        (focusout)="validateField('firstName')"
        data-qa="qt-first-name-field"
      />
    </kng-form-field>
  </div>

  <div class="price-request-student-form__form-group">
    <kng-form-field
      i18n-label
      label="Last name"
      [small]="true"
      [isValid]="formData.lastName.isValid"
      [required]="formData.lastName.required"
      [errorMessage]="formData.lastName.errorMessage"
      [iconValidation]="true"
    >
      <input
        type="text"
        pInputText
        kngFormDataInputValidity
        [formData]="formData.lastName"
        [(ngModel)]="formData.lastName.value"
        (focusout)="validateField('lastName')"
        data-qa="qt-last-name-field"
      />
    </kng-form-field>
  </div>

  <div class="price-request-student-form__form-group">
    <kng-form-field
      i18n-label
      label="Email address"
      [small]="true"
      [isValid]="formData.email.isValid"
      [required]="formData.email.required"
      [errorMessage]="formData.email.errorMessage"
      [iconValidation]="true"
    >
      <input
        type="email"
        pInputText
        kngFormDataEmailValidity
        [formData]="formData.email"
        [(ngModel)]="formData.email.value"
        (focusout)="validateField('email')"
        [attr.autocomplete]="'email'"
        data-qa="enrolment-email"
      />
    </kng-form-field>
  </div>

  <div class="price-request-student-form__form-group">
    <kng-phone-number-field
      label="Phone number"
      [phoneCode]="phoneCode"
      [phoneNumber]="phoneNumber"
      [isValid]="formData.phoneNumber.isValid"
      [required]="formData.phoneNumber.required"
      [errorMessage]="formData.phoneNumber.errorMessage"
      (valueChange)="onSelectPhoneNumber($event)"
      data-qa="qt-phone-number-field"
    ></kng-phone-number-field>
  </div>

  <div class="price-request-student-form__form-group">
    <kng-form-field
      class="form-field__validated-selector-padding form-field__no-border-dropdown"
      [class.form-field__no-validation]="!formData.countryOfResidence.value && formData.countryOfResidence.isValid"
      label="Country"
      i18n-label
      [small]="true"
      [isValid]="formData.countryOfResidence.isValid"
      [required]="formData.countryOfResidence.required"
      [errorMessage]="formData.countryOfResidence.errorMessage"
      [iconValidation]="true"
      kngFormDataDropdownValidity
      [formData]="formData.countryOfResidence"
    >
      <kng-residence-country-selector
        [(value)]="formData.countryOfResidence.value"
        (valueChange)="setSelectedCountry($event)"
        data-qa="qt-country-selector"
      ></kng-residence-country-selector>
    </kng-form-field>
  </div>

  <div class="price-request-student-form__form-group">
    <kng-form-field
      i18n-label
      label="Postcode"
      [small]="true"
      [isValid]="formData.postcode.isValid"
      [required]="formData.postcode.required"
      [errorMessage]="formData.postcode.errorMessage"
      [iconValidation]="true"
    >
      <input
        type="text"
        pInputText
        kngFormDataInputValidity
        [formData]="formData.postcode"
        [(ngModel)]="formData.postcode.value"
        (focusout)="validateField('postcode')"
        data-qa="qt-postcode-field"
      />
    </kng-form-field>
  </div>

  <div class="price-request-student-form__form-group">
    <kng-date-of-birth-field
      [isValid]="formData.dateOfBirth.isValid"
      [required]="formData.dateOfBirth.required"
      [errorMessage]="formData.dateOfBirth.errorMessage"
      (dateChange)="onSelectBirthDate($event)"
      kngFormDataInputValidity
      [formData]="formData.dateOfBirth"
      data-qa="qt-date-of-birth-field"
    ></kng-date-of-birth-field>
  </div>

  <div class="price-request-student-form__form-group" *ngIf="withNationalityField">
    <kng-nationality-selector
      label="Nationality"
      i18n-label
      (valueChange)="onSelectNationality($event)"
      [(value)]="formData.nationality.value"
      [isValid]="formData.nationality.isValid"
      [required]="formData.nationality.required"
      [small]="true"
      data-qa="qt-nationality-field"
    ></kng-nationality-selector>
  </div>
</kng-section-template>

<kng-terms-and-promotions-form-section></kng-terms-and-promotions-form-section>
