import { AfterViewInit, Component, ContentChild, EventEmitter, Input, OnChanges, OnDestroy, Output, SimpleChanges, TemplateRef } from '@angular/core';
import { isDeepEqual } from '@klg/shared/utils';
import { changeVisibilityAnimation } from '@klg/ui/animations';
import { Subject, Subscription } from 'rxjs';
import { CommonModule } from '@angular/common';
import { SortByPipe } from '@klg/shared/ui/pipes';
let id = 0;
@Component({
  standalone: true,
  selector: 'kng-select-button-group',
  templateUrl: './select-button-group.component.html',
  styleUrls: ['./select-button-group.component.scss'],
  animations: [changeVisibilityAnimation],
  imports: [CommonModule, SortByPipe],
})
export class SelectButtonGroupComponent<
  T extends { [k: string]: any },
  V extends keyof T | ((item: T) => any),
  K extends V extends null ? T : V extends keyof T ? T[V] : V extends (item: T) => any ? ReturnType<V> : never,
> implements AfterViewInit, OnChanges, OnDestroy
{
  @Input() customEvent$: Subject<K>;
  @Input() options: T[] = [];
  @Input() displayExpr: keyof T = null;
  @Input() valueExpr: V = null;
  @Input() isSelectedExpr: (item: T, value: V) => boolean;
  @Input() value: K;
  @Input() isOpen = false;
  @Input() defaultSorting = true;
  @Output() isOpenChange = new EventEmitter<boolean>(true);
  @Output() valueChange = new EventEmitter<K>();
  @ContentChild('optionItem') optionItemTpl: TemplateRef<unknown>;
  private subscription = new Subscription();
  id: number;

  constructor() {
    this.id = ++id;
  }

  ngAfterViewInit(): void {
    if (this.displayExpr === null && this.optionItemTpl === undefined) {
      throw new Error('Expected one of: a displayExpression or transclusion of content with the #optionItem tag');
    }
    if (this.customEvent$) {
      this.subscription.add(this.customEvent$.subscribe((value) => this.handleValueChange(value)));
    }
  }

  ngOnChanges({ value, isOpen }: SimpleChanges): void {
    if (
      (value && !isDeepEqual(value.currentValue, value.previousValue) && !isOpen) ||
      (value?.isFirstChange() && (value.currentValue === undefined || value.currentValue === null))
    ) {
      this.setIsOpen(value.currentValue === undefined || value.currentValue === null);
    }
  }

  ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }

  getValue(value: T): K {
    if (value === undefined) {
      return undefined;
    }
    if (this.valueExpr === null) {
      return value as unknown as K;
    }
    if (typeof this.valueExpr === 'string') {
      return value[this.valueExpr];
    }
    if (typeof this.valueExpr === 'function') {
      return (<(item: T) => any>this.valueExpr)(value);
    }
    throw Error('valueExpr needs to be one of: item property, selector callback, null');
  }

  onValueChangeFromSelectOption(value: T): void {
    if (this.customEvent$) {
      return;
    }
    const val = this.getValue(value);
    this.handleValueChange(val);
  }

  public setIsOpen(isOpen: boolean): void {
    if (isOpen === this.isOpen) {
      return;
    }
    this.isOpenChange.emit(isOpen);
    this.isOpen = isOpen;
  }

  public isItemSelected(item: T): boolean {
    if (this.value === undefined) return false;
    if (this.isSelectedExpr) {
      return this.isSelectedExpr(item, this.value);
    }
    const val = this.getValue(item);
    return isDeepEqual(val, this.value);
  }

  public getState(item: T) {
    return this.isOpen || this.isItemSelected(item) ? 'open' : 'folded';
  }

  private handleValueChange(val: K) {
    this.setIsOpen(!this.isOpen);
    if (val === this.value && this.isOpen) {
      return;
    }
    this.value = val;
    this.valueChange.emit(val);
  }
}
