export enum GoogleAnalyticsActions {
  CLICK = 'click',
  VIEW = 'view',
}

export enum GoogleAnalyticsEvents {
  QUOTE_BACK = 'quote_back',
  QUOTE_NEXT = 'quote_next',
  QUOTE_SAVE_AND_CLOSE = 'quote_save_and_close',
  QUOTE_VIEW_UPSELLING = 'quote_tool_view_upselling', // see slide 26
  QUOTE_UPSELLING_ACCEPT = 'quote_upselling_accept', // see slide 27
  QUOTE_UPSELLING_DENY = 'quote_upselling_deny', // see slide 27
  QUOTE_THUMBNAIL_COUNTRY = 'quote_thumbnail_country',
  QUOTE_THUMBNAIL_CITY = 'quote_thumbnail_city',
  QUOTE_THUMBNAIL_COURSE_NAME = 'quote_thumbnail_coursename',
  QUOTE_HIGHLIGHT_COUNTRY = 'quote_highlight_country',
  QUOTE_HIGHLIGHT_CITY = 'quote_highlight_city',
  QUOTE_HIGHLIGHT_COURSE_NAME = 'quote_highlight_coursename',
  QUOTE_HIGHLIGHT_ACCOMMODATION_TYPE = 'quote_highlight_accommodation_type',
  QUOTE_HIGHLIGHT_ACCOMMODATION_TYPE_INFO = 'quote_highlight_accommodation_type_info',
  QUOTE_PROMOTION_ACCOMMODATION = 'quote_promotion_accommodation',
  QUOTE_PROMOTION_COUNTRY = 'quote_promotion_country',
  QUOTE_PROMOTION_SCHOOL = 'quote_promotion_school',
  QUOTE_CALENDAR_STARTDATE_ADULT_COURSES = 'quote_calendar_startdate_adult_courses',
  QUOTE_CALENDAR_STARTDATE_ONLINE_COURSES = 'quote_calendar_startdate_online_courses',
  QUOTE_CALENDAR_STARTDATE_ACCOMMODATION = 'quote_calendar_startdate_accommodation',
  QUOTE_PROMOTION_CAMP = 'quote_promotion_camp',
  QUOTE_PROMOTION_INSURANCE = 'quote_promotion_insurance',
  QUOTE_PROMOTION_TRANSFERS = 'quote_promotion_transfers',
  QUOTE_PROMOTION_VIRTUAL_INTERNSHIP = 'quote_promotion_virtual_internship',
  QUOTE_HIGHLIGHT_COURSE_TYPE = 'quote_highlight_coursetype',
  QUOTE_THUMBNAIL_COURSE_TYPE = 'quote_thumbnail_coursetype',
  QUOTE_EDIT = 'quote_edit',
  QUOTE_REMOVE = 'quote_remove',
  QUOTE_ACCOMMODATION = 'quote_accommodation',
  QUOTE_INSURANCE = 'quote_insurance',
  QUOTE_TRANSFERS = 'quote_transfers',
  QUOTE_VIRTUAL_INTERNSHIP = 'quote_virtual_internship',
  QUOTE_ACCOMMODATION_HELP = 'quote_accommodation_help',
  QUOTE_INSURANCE_HELP = 'quote_insurance_help',
  QUOTE_TRANSFERS_HELP = 'quote_transfers_help',
  QUOTE_VIRTUAL_INTERNSHIP_HELP = 'quote_virtual_internship_help',
  QUOTE_ACCOMMODATION_NOTHANKS = 'quote_accommodation_nothanks',
  QUOTE_INSURANCE_NOTHANKS = 'quote_insurance_nothanks',
  QUOTE_TRANSFERS_NOTHANKS = 'quote_transfers_nothanks',
  QUOTE_VIRTUAL_INTERNSHIP_NOTHANKS = 'quote_virtual_internship_nothanks',
  QUOTE_ACCOMMODATION_ADD = 'quote_accommodation_add',
  QUOTE_INSURANCE_ADD = 'quote_insurance_add',
  QUOTE_TRANSFERS_ADD = 'quote_transfers_add',
  QUOTE_TRANSFERS_UNACCOMPANIED_MINORS_SERVICE = 'quote_transfers_unaccompanied_minors_service',
  QUOTE_TRANSFERS_UNACCOMPANIED_MINORS_SERVICE_SELECT = 'quote_transfers_unaccompanied_minors_service_select',
  QUOTE_PREMIUM_ACTIVITIES = 'quote_premium_activities',
  QUOTE_PREMIUM_ACTIVITIES_SELECT = 'quote_premium_activities_select',
  QUOTE_PRIVATE_LESSONS = 'quote_private_lessons',
  QUOTE_PRIVATE_LESSONS_SELECT = 'quote_private_lessons_select',
  QUOTE_PREMIUM_RESIDENCE = 'quote_premium_residence',
  QUOTE_PREMIUM_RESIDENCE_SELECT = 'quote_premium_residence_select',
  QUOTE_INSURANCE_SELECT = 'quote_insurance_select',
  QUOTE_EXTRAS_NOTHANKS = 'quote_extras_nothanks',
  QUOTE_COURSE_EDIT = 'course_edit',
  QUOTE_VIRTUAL_INTERNSHIP_ADD = 'quote_virtual_internship_add',
  QUOTE_ACCEPT_REMOVE = 'quote_remove_popup_accept',
  QUOTE_CANCEL_REMOVE = 'quote_remove_popup_cancel',
  QUOTE_CREATE_OTHER_QUOTE = 'quote_create_another',
  QUOTE_BREAKDOWN = 'quote_breakdown',
  QUOTE_EMAIL = 'quote_email',
  QUOTE_EMAIL_REGISTER = 'quote_email_register',
  QUOTE_ENROL = 'quote_enrol_now_button',
  QUOTE_ENROL_CONFIRMATION = 'quote_enrol_confirm', // when loading thank you page
  QUOTE_STEP_1 = 'quote_step_1',
  QUOTE_STEP_2 = 'quote_step_2',
  QUOTE_STEP_3 = 'quote_step_3',
  QUOTE_STEP_4 = 'quote_step_4',
  QUOTE_STEP_4_ONLINE = 'quote_step_4_online',
  QUOTE_STEP_5 = 'quote_step_5',
  QUOTE_STEP_6 = 'quote_step_6',
  QUOTE_STEP_7_ENROL = 'quote_enrol_step_7',
  QUOTE_STEP_7_EMAIL = 'quote_email_step_7',
  QUOTE_STEP_8_PAYMENT = 'quote_enrol_step_8',
  QUOTE_STEP_EMAIL_REGISTER_CONFIRMATION = 'quote_email_register_confirmation',
  QUOTE_EMAIL_UPDATED = 'quote_email_updated',
  QUOTE_EXIT_SUMMARY = 'quote_exit_summary',
  QUOTE_EMAIL_MARKETING_OPT_IN_RECHECKED = 'quote_email_marketing_opt_in_rechecked',
  QUOTE_EMAIL_MARKETING_OPT_IN_UNCHECKED = 'quote_email_marketing_opt_in_unchecked',
  QUOTE_ENROL_MARKETING_OPT_IN_RECHECKED = 'quote_enrol_marketing_opt_in_rechecked',
  QUOTE_ENROL_MARKETING_OPT_IN_UNCHECKED = 'quote_enrol_marketing_opt_in_unchecked',
  GENERATE_QUOTE_TOOL_EMAIL = 'generate_quote_tool_email',
  GENERATE_QUOTE_TOOL_ENROLMENT = 'generate_quote_tool_enrolment',
  GENERATE_PRICE_REQUEST_EMAIL = 'generate_price_request_email',
  GENERATE_FREE_QUOTE_EMAIL = 'generate_free_quote_email',
  GENERATE_BOOK_NOW_ENROLMENT = 'generate_book_now_enrolment',
}
