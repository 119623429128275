import { Inject, inject, Injectable, OnDestroy } from '@angular/core';
import { first, Subject, takeUntil } from 'rxjs';
import { isArray, isObject, mapValues, pickBy } from 'lodash';
import { GoogleAnalyticsActions, GoogleAnalyticsEvent } from '../types';
import { GoogleAnalyticsDataLayerService } from './google-analytics.data-layer.service';
import { SessionStorageService, STORAGE_KEY_DATA_LAYER_DETAILS } from '@klg/shared/storage';
import { DATA_LAYER_SERVICE } from '../tokens/data-layer.token';

declare const window: Window & { dataLayer: Partial<GoogleAnalyticsEvent>[] };

@Injectable({
  providedIn: 'root',
})
export class GoogleAnalyticsService implements OnDestroy {
  private readonly sessionStorageService = inject(SessionStorageService);
  private readonly destroy$: Subject<void> = new Subject<void>();

  constructor(@Inject(DATA_LAYER_SERVICE) private googleAnalyticsDataLayerService: GoogleAnalyticsDataLayerService) {
    window.dataLayer = window.dataLayer || [];
  }

  trackViewEvent(event: string) {
    this.trackEvent(event, GoogleAnalyticsActions.VIEW);
  }

  trackClickEvent(event: string) {
    this.trackEvent(event, GoogleAnalyticsActions.CLICK);
  }

  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }

  private trackEvent(event: string, action: GoogleAnalyticsActions) {
    // If there is no event or action, nothing to track
    if (!event || !action) {
      return;
    }

    this.googleAnalyticsDataLayerService
      .fillDataLayer()
      .pipe(takeUntil(this.destroy$), first())
      .subscribe((dataLayer) => {
        const combinedDataLayer = this.cleanAndCombineDataLayer(dataLayer);
        this.sessionStorageService.set(STORAGE_KEY_DATA_LAYER_DETAILS, combinedDataLayer);
        const gaEvent = <GoogleAnalyticsEvent>{ event, action, ...combinedDataLayer };
        window.dataLayer.push(this.cleanNotDefinedFields(gaEvent));
      });
  }

  private getDataLayerFromSessionStorage(): Partial<GoogleAnalyticsEvent> | undefined {
    return this.sessionStorageService.get(STORAGE_KEY_DATA_LAYER_DETAILS);
  }

  private cleanAndCombineDataLayer(dataLayer: Partial<GoogleAnalyticsEvent>): Partial<GoogleAnalyticsEvent> {
    const cleanedDataLayer = this.cleanNotDefinedFields(dataLayer);
    const sessionData = this.getDataLayerFromSessionStorage();
    const cleanedSessionData = this.googleAnalyticsDataLayerService.removeUnnecessarySessionData(sessionData, cleanedDataLayer);
    return this.googleAnalyticsDataLayerService.deepMerge(cleanedSessionData, cleanedDataLayer);
  }

  // Method to clean undefined and null values for Partial<GoogleAnalyticsEvent>
  private cleanNotDefinedFields(data: Partial<GoogleAnalyticsEvent>): Partial<GoogleAnalyticsEvent> {
    // Helper function to clean values recursively
    const cleanRecursive = (item: unknown): unknown => {
      if (isArray(item)) {
        return item
          .map(cleanRecursive) // Recursively clean each item in the array
          .filter((v): v is Exclude<typeof v, undefined | null | string> => v !== undefined && v !== null && v !== 'NaN'); // Filter out undefined and null values
      } else if (isObject(item) && item !== null) {
        return mapValues(
          pickBy(item, (v) => v !== undefined && v !== null && v !== 'NaN'), // Remove undefined and null values from the object
          (value) => cleanRecursive(value), // Recursively clean nested values
        );
      }
      return item;
    };

    return cleanRecursive(data) as Partial<GoogleAnalyticsEvent>;
  }
}
