<kng-section-template [sectionIndex]="sectionIndex" i18n-sectionTitle sectionTitle="Further information">
  <kng-form-field label="Leisure/Interest" i18n-label [small]="true" [isValid]="formData.leisure.isValid" data-qa="enrolment-leisure-field">
    <input pInputText type="text" (focusout)="onValueChanges('leisure')" [(ngModel)]="formData.leisure.value" />
  </kng-form-field>
  <kng-form-field label="Special diet" i18n-label [small]="true" [isValid]="formData.specialDiet.isValid" data-qa="enrolment-special-diet-field">
    <input pInputText type="text" (focusout)="onValueChanges('specialDiet')" [(ngModel)]="formData.specialDiet.value" />
  </kng-form-field>
  <kng-form-field label="Allergies" i18n-label [small]="true" [isValid]="formData.allergies.isValid" data-qa="enrolment-allergies-field">
    <input pInputText type="text" (focusout)="onValueChanges('allergies')" [(ngModel)]="formData.allergies.value" />
  </kng-form-field>
  <div
    class="extras__check-box-container"
    [class.extras__check-box-container--one-column]="!isAdultSchool()"
    [class.extras__check-box-container--two-columns]="isAdultSchool()"
  >
    <kng-form-field [isValid]="formData.vegetarian.isValid" [small]="true">
      <kng-checkbox [(value)]="formData.vegetarian.value" (valueChange)="onValueChanges('vegetarian')" i18n>Vegetarian</kng-checkbox>
    </kng-form-field>
    <kng-form-field *ngIf="isAdultSchool()" [isValid]="formData.smoker.isValid" [small]="true">
      <kng-checkbox [(value)]="formData.smoker.value" (valueChange)="onValueChanges('smoker')" i18n>Smoker</kng-checkbox>
    </kng-form-field>
  </div>

  <kng-form-field
    class="qt-form-section__double-column-span"
    label="Other wishes"
    i18n-label
    [small]="true"
    [isValid]="formData.otherWishes.isValid"
    data-qa="enrolment-other-wishes-field"
  >
    <input pInputText type="text" (focusout)="onValueChanges('otherWishes')" [(ngModel)]="formData.otherWishes.value" />
  </kng-form-field>

  <kng-host-family-selection class="extras-selection__host-family-selection" (valueChanges)="updateFamilyOptions($event)"></kng-host-family-selection>

  <kng-form-field label="Insurance" i18n-label [small]="true" [isValid]="true" [required]="false" class="qt-form-section__double-column-span">
    <div class="insurance-option-container">
      <ng-container *ngFor="let option of insuranceOptions">
        <p-checkbox
          [value]="option.insuranceType"
          name="insuranceOption"
          [label]="option.name"
          [(ngModel)]="selectedInsuranceOptions"
          (ngModelChange)="saveInsuranceOptions()"
        ></p-checkbox>
      </ng-container>
    </div>
  </kng-form-field>
</kng-section-template>
