<ng-container *ngIf="experimentComponent; else normalForm">
  <ng-container *ngComponentOutlet="experimentComponent" />
</ng-container>

<ng-template #normalForm>
  <kng-destination-school-form-section [formType]="formType" sectionIndex="1"></kng-destination-school-form-section>

  <kng-course-dates-form-section sectionIndex="2" [formType]="formType"></kng-course-dates-form-section>

  <ng-container *ngIf="!isPriceRequestFormType()">
    <kng-accommodation-dates-form-section sectionIndex="3"></kng-accommodation-dates-form-section>

    <kng-select-options-form-section sectionIndex="4"></kng-select-options-form-section>
  </ng-container>

  <kng-tell-us-about-you-section [formType]="formType" *ngIf="showTellUsAboutYou()" [sectionIndex]="tellUsAboutYouSectionIndex"></kng-tell-us-about-you-section>

  <kng-terms-and-promotions-form-section *ngIf="showTermsAndConditions()"></kng-terms-and-promotions-form-section>
</ng-template>
