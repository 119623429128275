import { Component, EventEmitter, Input, OnDestroy, OnInit, Output, inject } from '@angular/core';
import { CommonModule } from '@angular/common';
import { InputNumberModule } from 'primeng/inputnumber';
import { DropdownModule } from 'primeng/dropdown';
import { DateFormatService } from '@klg/shared/i18n';
import { FormsModule } from '@angular/forms';
import { FormFieldComponent } from '@klg/shared/ui/form-field';
import { OverlayOptions } from 'primeng/api';
import { BreakpointObserverService } from '@klg/shared/utils-dom';
import { Subscription } from 'rxjs';

const BASE_Z_INDEX = -1000;

@Component({
  standalone: true,
  selector: 'kng-date-of-birth-field',
  imports: [CommonModule, InputNumberModule, DropdownModule, FormsModule, FormFieldComponent],
  templateUrl: './date-of-birth-field.component.html',
  styleUrls: ['./date-of-birth-field.component.scss'],
})
export class DateOfBirthFieldComponent implements OnInit, OnDestroy {
  @Input() date: Date | undefined;
  @Input() label: string | undefined;
  @Input() isValid: null | boolean = false;
  @Input() required = false;
  @Input() errorMessage: string | undefined;
  @Output() dateChange = new EventEmitter<Date>();

  months: { name: string; code: number }[];
  day: number | undefined;
  month: number | undefined;
  year: number | undefined;
  overlayOption: OverlayOptions = {
    autoZIndex: true,
    baseZIndex: BASE_Z_INDEX,
  };
  monthScrollHeight = '500px';

  private subscription: Subscription = new Subscription();
  private readonly breakpointObserverService = inject(BreakpointObserverService);

  ngOnInit(): void {
    this.prepareMonths();
    this.subscription.add(
      this.breakpointObserverService.isMobile$.subscribe((isMobile) => {
        if (isMobile) {
          this.monthScrollHeight = '200px';
        } else {
          this.monthScrollHeight = '500px';
        }
      }),
    );

    if (this.date) {
      this.day = this.date.getDate();
      this.month = this.date.getMonth();
      this.year = this.date.getFullYear();
    }
  }

  ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }

  updateDateOfBirth() {
    if (this.day && this.month !== null && this.month !== undefined && this.year) {
      const date = new Date(this.year, this.month, this.day);
      this.dateChange.emit(date);
    } else if (this.day === null || this.month === null || this.year === null) {
      this.dateChange.emit(null);
    }
  }

  private prepareMonths() {
    const calendarConfig = DateFormatService.getCalendarLocaleSettings().monthNames;
    this.months = calendarConfig.map((name, index) => ({
      name,
      code: index,
    }));
  }
}
