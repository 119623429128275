<kng-section-template [columns]="columns" [sectionTitle]="title">
  <div class="price-request-student-form__form-group">
    <div class="price-request-student-form__form-group__label">
      <span i18n>First name</span><span class="price-request-student-form__form-group__label__required">*</span>
    </div>
    <kng-form-field [isValid]="formData?.firstName?.isValid" [required]="formData?.firstName?.required" [small]="true">
      <input pInputText type="text" (input)="validateField('firstName')" [(ngModel)]="formData.firstName.value" data-qa="qt-first-name-field" />
    </kng-form-field>
  </div>

  <div class="price-request-student-form__form-group">
    <div class="price-request-student-form__form-group__label">
      <span i18n>Last name</span><span class="price-request-student-form__form-group__label__required">*</span>
    </div>
    <kng-form-field [isValid]="formData.lastName.isValid" [required]="formData.lastName.required" [small]="true">
      <input pInputText type="text" (input)="validateField('lastName')" [(ngModel)]="formData.lastName.value" data-qa="qt-last-name-field" />
    </kng-form-field>
  </div>

  <div class="price-request-student-form__form-group">
    <div class="price-request-student-form__form-group__label">
      <span i18n>Email address</span><span class="price-request-student-form__form-group__label__required">*</span>
    </div>
    <kng-form-field [isValid]="formData.email.isValid" [required]="formData.email.required" [small]="true">
      <input pInputText type="text" (input)="validateField('email')" [(ngModel)]="formData.email.value" data-qa="qt-email-selector" />
    </kng-form-field>
  </div>

  <div class="price-request-student-form__form-group">
    <div class="price-request-student-form__form-group__label">
      <span i18n>Mobile</span><span class="price-request-student-form__form-group__label__required">*</span>
    </div>
    <kng-form-field [isValid]="formData.phoneNumber.isValid" [required]="formData.phoneNumber.required" [small]="true">
      <input
        pInputText
        type="tel"
        (input)="validateField('phoneNumber')"
        [(ngModel)]="formData.phoneNumber.value"
        i18n-placeholder
        placeholder="e.g. +442070455000"
        data-qa="qt-phone-number-field"
      />
    </kng-form-field>
  </div>

  <div class="price-request-student-form__form-group">
    <div class="price-request-student-form__form-group__label">
      <span i18n>Country</span><span class="price-request-student-form__form-group__label__required">*</span>
    </div>
    <kng-residence-country-selector
      (valueChange)="setSelectedCountry($event)"
      [(value)]="formData.countryOfResidence.value"
      [required]="formData.countryOfResidence.required"
      [small]="true"
      data-qa="qt-country-selector"
    ></kng-residence-country-selector>
  </div>

  <div class="price-request-student-form__form-group">
    <div class="price-request-student-form__form-group__label">
      <span i18n>Postcode</span><span class="price-request-student-form__form-group__label__required">*</span>
    </div>
    <kng-form-field [isValid]="formData.postcode.isValid" [required]="formData.postcode.required" [small]="true">
      <input pInputText type="text" (input)="validateField('postcode')" [(ngModel)]="formData.postcode.value" data-qa="qt-postcode-field" />
    </kng-form-field>
  </div>
</kng-section-template>

<kng-terms-and-promotions-form-section></kng-terms-and-promotions-form-section>
