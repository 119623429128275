<p-dialog
  i18n-header
  header="Please select"
  [rtl]="true"
  [modal]="true"
  (onHide)="closePopup()"
  [(visible)]="isPopupOpen"
  [style]="{ height: '100%', width: '100%', maxHeight: '100%', maxWidth: '100%', marginTop: '120px' }"
  [draggable]="false"
  [closable]="true"
  [resizable]="false"
  [showHeader]="true"
  [closeOnEscape]="false"
  [dismissableMask]="false"
>
  <p-listbox [filter]="true" [options]="itemList" [optionLabel]="'name'" (onClick)="onItemClick($event)"></p-listbox>
</p-dialog>
