import {
  Component,
  ContentChild,
  EventEmitter,
  HostBinding,
  inject,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  Output,
  SimpleChanges,
  TemplateRef,
} from '@angular/core';
import { CodeAndName, NumericCodeAndName, SortingCriteria } from '@klg/shared/types';
import { BehaviorSubject, Subject, Subscription } from 'rxjs';
import { getCompany } from '@klg/shared/tokens';
import { BreakpointObserverService } from '@klg/shared/utils-dom';
import { DropdownModule } from 'primeng/dropdown';
import { SortByPipe } from '@klg/shared/ui/pipes';
import { FormsModule } from '@angular/forms';
import { ButtonComponent } from '@klg/shared/ui/button';
import { DropdownPopupComponent } from '@klg/shared/ui/dropdown-popup';
import { CommonModule } from '@angular/common';
import { FormFieldComponent } from '@klg/shared/ui/form-field';
import { SummaryCardComponent } from '../summary-card/summary-card.component';
import { SelectButtonGroupComponent } from '../select-button-group/select-button-group.component';

@Component({
  standalone: true,
  selector: 'kng-form-field-selector',
  templateUrl: './form-field-selector.component.html',
  styleUrls: ['./form-field-selector.component.scss'],
  imports: [
    CommonModule,
    DropdownModule,
    SortByPipe,
    FormsModule,
    ButtonComponent,
    DropdownPopupComponent,
    FormFieldComponent,
    SummaryCardComponent,
    SelectButtonGroupComponent,
  ],
})
export class FormFieldSelectorComponent<
  T extends (CodeAndName | NumericCodeAndName) & {
    [key: string]: any;
  },
  K extends keyof T,
> implements OnInit, OnChanges, OnDestroy
{
  @HostBinding('class') private readonly hostClass: string;
  @Input() selectedItemTemplate: TemplateRef<any> | undefined;
  @Input() small = true;
  @Input() label: string;
  @Input() disabled = false;
  @Input() required = false;
  @Input() searchEnabled = true;
  @Input() buttonView = false;
  @Input() value: T[K];
  @Input() itemList: T[] = [];
  @Input() placeholder: string;
  @Input() title: string;
  @Input() isOpen = false;
  @Input() valueExpr: K;
  @Input() hideHelpMeChoose = false;
  @Input() showClearButton = false;
  @Input() showCancelButton = false;
  @Input() autofill = false;
  @Input() sortCriteria: SortingCriteria<T>;
  @Input() buttonGroupCustomEvent: Subject<T[K]> | null = null;
  @Output() valueChange = new EventEmitter<T[K]>();
  @Output() itemChange = new EventEmitter<T>();
  @Output() isOpenChange = new EventEmitter<boolean>();
  @ContentChild('optionItemTemplate') optionItemTemplate: TemplateRef<unknown>;

  valid = false;
  mobile = false;
  noDataText = $localize`No data to display`;
  showDetails = false;
  selectedValue: T[K];
  selectedItem: T | undefined;
  isDropdownPopupOpen = false;

  // For mobile version, there is a button displaying the selected option. This property
  // is used to display the selected option in the button.
  selectedValueText = '';

  readonly company = getCompany();

  private selectedValue$ = new BehaviorSubject<{
    value: T[K];
    withEvent?: boolean;
    dirty?: boolean;
  }>({ value: undefined });
  private dirty = false;
  private defaultPlaceholder = $localize`— Please select —`;
  private subscriptions = new Subscription();

  private readonly breakpointObserverService = inject(BreakpointObserverService);

  constructor() {
    this.placeholder = this.defaultPlaceholder;
    this.hostClass = this.getCompanyClass();
  }

  ngOnInit(): void {
    this.subscriptions.add(
      this.breakpointObserverService.isMobile$.subscribe((isMobile) => {
        this.mobile = isMobile;
      }),
    );
    this.setSelectedValue();
  }

  ngOnChanges({ value, itemList, placeholder }: SimpleChanges): void {
    if (value && this.selectedValue !== value.currentValue) {
      this.selectedValue$.next({
        value: value.currentValue ?? undefined,
        dirty: !!value.currentValue,
      });
    }

    if (itemList) {
      this.setSelectedValue();
      if (this.autofill && itemList.currentValue?.length === 1) {
        const uniqueListItem = itemList.currentValue[0];
        if (uniqueListItem && this.selectedValue !== uniqueListItem.code) {
          setTimeout(() => this.setSelectedItem(uniqueListItem.code), 0);
        }
      } else {
        if (this.selectedValue && this.checkIsValidValue(this.selectedValue) === false) {
          this.selectedValue$.next({ value: undefined });
        } else if (!this.selectedValue && this.checkIsValidValue(this.value)) {
          this.selectedValue$.next(this.value);
        }
      }
    }
    if (placeholder && (placeholder.currentValue === null || placeholder.currentValue === undefined)) {
      this.placeholder = this.defaultPlaceholder;
    }
    this.openIfNotSelectedValue();
  }

  ngOnDestroy(): void {
    this.subscriptions.unsubscribe();
  }

  setSelectedValue() {
    this.subscriptions.add(
      this.selectedValue$.subscribe((selectedValue) => {
        const { value, withEvent, dirty } = selectedValue;
        this.selectedValue = value;
        this.selectedItem = this.itemList?.find((item: T) => item.code === value);
        this.dirty = dirty;
        this.valid = this.checkIsValidValue(this.selectedValue);
        this.setSelectedValueText();

        // Emits event change events if the withEvent flag is true
        if (withEvent) {
          this.itemChange.emit(this.selectedItem);
          this.valueChange.emit(value);
        }
      }),
    );
  }

  setSelectedItem(value: T[K]) {
    if ((value != null && this.value !== value) || this.isOpen) {
      this.selectedValue$.next({ value, withEvent: true, dirty: true });
    }
  }

  isOpenChanged(isOpen: boolean) {
    if (!isOpen) {
      this.showDetails = false;
    }
    this.isOpenChange.emit(isOpen);
  }

  setDropdownPopup() {
    this.isDropdownPopupOpen = true;
  }

  setDropdownPopupSelectedItem($event: T[keyof T] | T) {
    this.setSelectedItem($event.code);
  }

  /**
   * It sets the value of the selectedValueText property based on the selectedValue and selectedItem properties.
   * If there is not selected value, then it sets the placeholder value.
   * @private
   */
  private setSelectedValueText() {
    if (this.selectedValue !== undefined && this.selectedItem !== null) {
      this.selectedValueText = this.selectedItem?.name;
    } else {
      this.selectedValueText = this.placeholder;
    }
  }

  private getCompanyClass() {
    return `form-field-selector--${this.company.toLowerCase()}`;
  }

  private openIfNotSelectedValue() {
    if (this.buttonView && !this.value) {
      this.isOpen = true;
    }
  }

  private checkIsValidValue(value: string | number | T[K]): boolean {
    return this.dirty && this.itemList?.length ? this.itemList?.some((item) => item?.code === value) : undefined;
  }
}
