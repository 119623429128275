import { ErrorHandler, Provider } from '@angular/core';
import { HTTP_INTERCEPTORS } from '@angular/common/http';
import { provideNotificationService, ToastNotificationService } from '@klg/shared/logger';
import { GlobalErrorHandler, HttpErrorInterceptor, WHITELISTED_ERROR_CODES } from '@klg/shared/errors';

export const getErrorHandlerProviders = () =>
  [
    { provide: HTTP_INTERCEPTORS, useClass: HttpErrorInterceptor, multi: true },
    { provide: ErrorHandler, useClass: GlobalErrorHandler },
    { provide: WHITELISTED_ERROR_CODES, useValue: [404] },
    provideNotificationService(ToastNotificationService),
  ] as Provider[];
