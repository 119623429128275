<kng-loading-spinner i18n-message message="Loading School Info..." [loading]="loading" shadingColor="rgba(0,0,0,0.5)"></kng-loading-spinner>

<kng-section-template [sectionIndex]="sectionIndex" i18n-sectionTitle sectionTitle="Select course">
  <ng-container *ngFor="let course of sectionModel.courses; let courseIndex = index; let isLast = last">
    <kng-course-type-selector
      *ngIf="hasCourseTypeSelector()"
      label="Course Type"
      i18n-label
      class="qt-form-section__start-new-line form-field__validated-selector-padding"
      [disabled]="!productSchool"
      [codes]="availableCourseTypes"
      [buttonView]="false"
      [value]="course.courseType?.code"
      [required]="courseIndex === 0"
      [ageGroup]="ageGroup"
      [iconValidation]="true"
      (itemChange)="courseTypeChanged(courseIndex, $event)"
      [attr.data-qa]="'qt-course-type-selector-' + courseIndex"
    ></kng-course-type-selector>

    <kng-level-selector
      *ngIf="hasLanguageLevelSelector()"
      class="form-field__validated-selector-padding"
      [value]="course.level?.code"
      [disabled]="!productSchool || (hasCourseTypeSelector() && !course.courseType)"
      [schoolCode]="schoolCodeForLevels()"
      [codes]="courseOptions[courseIndex]?.availableLevels"
      [required]="courseIndex === 0"
      [ngClass]="{ 'qt-form-section__start-new-line': !hasCourseTypeSelector() }"
      (itemChange)="levelChange(courseIndex, $event)"
      [attr.data-qa]="'qt-language-level-selector-' + courseIndex"
    ></kng-level-selector>

    <kng-form-field-selector
      label="Course"
      class="form-field__validated-selector-padding"
      i18n-label
      *ngIf="hasCourseSelector()"
      [disabled]="(hasLanguageLevelSelector() && !course.level) || (hasCourseTypeSelector() && !course.courseType) || !productSchool"
      [value]="courseOptionExist(courseIndex, course.relatedCourseCode) ? course.relatedCourseCode : course.code"
      valueExpr="code"
      [itemList]="courseOptions[courseIndex]?.courses"
      [autofill]="true"
      [required]="courseIndex === 0"
      [iconValidation]="true"
      [ngClass]="{ 'qt-form-section__start-new-line': !hasCourseTypeSelector() && !hasLanguageLevelSelector() }"
      (valueChange)="courseChanged(courseIndex, $event)"
      [attr.data-qa]="'qt-course-selector-' + courseIndex"
    ></kng-form-field-selector>

    <div>
      <kng-date-picker-field
        i18n-label
        label="Start Date"
        i18n-placeholder
        placeholder="— Please select  —"
        class="form-field__validated-selector-padding"
        [disabled]="hasCourseSelector() ? !course.code : hasLanguageLevelSelector() ? !course.level : !course.courseType"
        [dateValue]="course.startDate"
        [minDate]="courseOptions[courseIndex]?.minDate"
        [defaultDate]="courseOptions[courseIndex]?.minDate"
        [disabledDates]="courseOptions[courseIndex]?.disabledDates"
        [showCalendarIcon]="true"
        [required]="courseIndex === 0"
        [iconValidation]="true"
        [ngClass]="{ 'qt-form-section__start-new-line': !hasCourseTypeSelector() && !hasLanguageLevelSelector() && !hasCourseSelector() }"
        (dateChanged)="startDateChange(courseIndex, $event)"
        (calendarShown)="prepareCalendar(courseIndex, $event)"
        (monthChanged)="monthChanged(courseIndex, $event)"
        [attr.data-qa]="'qt-start-date-field-' + courseIndex"
      ></kng-date-picker-field>
      <div class="form-field-sections__disclaimer" i18n *ngIf="showDisclaimerDatesToBeConfirmed">Dates to be confirmed</div>
    </div>

    <kng-weeks-selector
      label="Duration"
      i18n-label
      class="form-field__validated-selector-padding"
      [disabled]="!course.startDate"
      [value]="course.weeks"
      [availableWeeksValues]="courseOptions[courseIndex]?.availableWeeksValues"
      [required]="courseIndex === 0"
      [iconValidation]="true"
      (valueChange)="durationChange(courseIndex, $event)"
      [attr.data-qa]="'qt-duration-field-' + courseIndex"
    ></kng-weeks-selector>
    <div *ngIf="hasCourseSelector()">
      <kng-button
        i18n-text
        text="Delete the course above"
        [icon]="'pi pi-times'"
        [iconPos]="'before'"
        [iconFill]="true"
        [disabled]="!productSchool || sectionModel.courses.length < 2"
        [iconRounded]="true"
        [buttonStyle]="'plain-text'"
        [attr.data-qa]="'qt-remove-course-' + courseIndex"
        (buttonClick)="removeCourse(courseIndex)"
      >
      </kng-button>
    </div>
    <div *ngIf="isLast && hasCourseSelector() && hasAvailableCourseOptions()">
      <kng-button
        i18n-text
        text="Add a course"
        [icon]="'pi pi-plus'"
        [iconPos]="'before'"
        [iconFill]="true"
        [disabled]="!course.weeks"
        [iconRounded]="true"
        [buttonStyle]="'plain-text'"
        [attr.data-qa]="'qt-add-course-' + courseIndex"
        (buttonClick)="addCourse()"
      >
      </kng-button>
    </div>
  </ng-container>

  <kng-form-field-selector
    *ngIf="hasAccommodationTypeSelector()"
    class="form-field__validated-selector-padding"
    label="Accommodation Type"
    i18n-label
    [disabled]="!sectionModel.courses[0].weeks"
    valueExpr="code"
    [itemList]="accommodationTypes"
    [required]="true"
    [iconValidation]="true"
    (itemChange)="accommodationTypeChanged($event)"
    data-qa="pr-accommodation-type-field"
  ></kng-form-field-selector>

  <kng-form-field
    label="Please tell us more about your project"
    i18n-label
    *ngIf="hasSpecialRequestsField()"
    class="qt-form-section__double-column-span form-field__no-validation"
    [disabled]="!sectionModel.courses[0].weeks"
    [required]="false"
    [small]="true"
    [isValid]="true"
    [iconValidation]="true"
    data-qa="pr-customer-project-field"
  >
    <textarea
      placeholder="Leave your message here"
      class="qt-form-section__textarea"
      i18n-placeholder
      (ngModelChange)="specialRequestsChanged($event)"
      [(ngModel)]="specialRequests"
      [autoResize]="false"
      pInputTextarea
    ></textarea>
  </kng-form-field>
</kng-section-template>
