import { Pipe, PipeTransform } from '@angular/core';
import { CourseDuration, DurationUnit } from '@klg/shared/data-access/types';
import { DurationUnitsService } from '@klg/shared/utils/services';

@Pipe({
  name: 'optionalPriceDuration',
  standalone: true,
})
export class OptionalPriceDurationPipe implements PipeTransform {
  constructor(private durationUnitsService: DurationUnitsService) {}

  transform(durationUnit: DurationUnit, minQuantity: number, maxQuantity: number): string {
    if (!durationUnit) {
      const quantityText = maxQuantity ? `${minQuantity}-${maxQuantity}` : `${minQuantity}+`;
      return `(${quantityText})`;
    }

    const perDurationText = this.durationUnitsService.getPerDurationText(1, durationUnit);
    if (minQuantity && maxQuantity) {
      const courseDuration: CourseDuration = { durationMin: minQuantity, durationMax: maxQuantity, durationUnit };
      const durationRange = this.durationUnitsService.getDurationRange(courseDuration);
      return `(${durationRange}) (${perDurationText})`;
    } else if (minQuantity) {
      const durationPluralText = this.durationUnitsService.getDurationPluralText(durationUnit);
      return `(${minQuantity}+ ${durationPluralText}) (${perDurationText})`;
    }
  }
}
