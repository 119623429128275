import { DOCUMENT } from '@angular/common';
import { inject, Injectable } from '@angular/core';
import { QuoteInput, SchoolAndCourseData } from '@klg/quote-tool/shared/types/quote';
import { City, Country, Course, CourseLevel, CourseType } from '@klg/shared/data-access/types';
import { CityDataService } from '@klg/shared/data-access/destination';
import {
  SessionStorageService,
  STORAGE_KEY_QT_PREFILLED_ENROLMENT_ID,
  STORAGE_KEY_QT_PREFILLED_KLG_TOKEN,
  STORAGE_KEY_QT_QUOTE_PAYMENT_STATUS,
} from '@klg/shared/storage';
import { getCourseDurations, getCourseFirstAvailableDateWithConfirmation } from '@klg/shared/data-access/course';
import { ProductSchoolsService } from '@klg/shared/data-access/school';
import { combineLatest, iif, Observable, of } from 'rxjs';
import { catchError, map, mergeMap, shareReplay, switchMap, take } from 'rxjs/operators';
import { QuoteToolPreselectedModes as PreselectedModes } from '@klg/shared/utils/enums';
import { QuoteSchoolService } from '@klg/quote-tool/shared/data-access/school';
import { QuoteSchool } from '@klg/quote-tool/shared/types/school';
import { CourseTypeService } from '@klg/quote-tool/shared/data-access/course';
import { CountryService } from '@klg/quote-tool/shared/data-access/destination';
import { getCompany } from '@klg/shared/tokens';
import { COMPANIES } from '@klg/shared/types';
import { getDateFromString } from '@klg/shared/utils';
import { DeepLinkParameters } from '@klg/quote-tool/shared/routing';
import { RoiTrackerData } from '@klg/quote-tool/shared/types';

@Injectable({
  providedIn: 'root',
})
export class HostDataReaderService {
  queryParams: URLSearchParams | undefined;
  private _getPrefilledSchoolCourse$: Observable<SchoolAndCourseData>;
  private readonly company = getCompany();

  private readonly schoolService = inject(QuoteSchoolService);
  private readonly productSchoolService = inject(ProductSchoolsService);
  private readonly cityService = inject(CityDataService);
  private readonly countryService = inject(CountryService);
  private readonly courseTypeService = inject(CourseTypeService);
  private readonly sessionStorage = inject(SessionStorageService);

  private readonly document: Document = inject(DOCUMENT);

  getDrupalRoiData(): RoiTrackerData {
    return {
      leadType: this.getRoiTrackerDataSelectorValue('lead-type'),
      leadSource: this.getRoiTrackerDataSelectorValue('lead-source'),
      formCategoryid: this.getRoiTrackerDataSelectorValue('form-categoryid'),
      publication: this.getRoiTrackerDataSelectorValue('publication'),
      roiFirst: this.getRoiTrackerDataSelectorValue('roi-first'),
      roiCurrent: this.getRoiTrackerDataSelectorValue('roi-current'),
      urlOrigin: this.getRoiTrackerDataSelectorValue('url-origin'),
      urlCurrent: this.getRoiTrackerDataSelectorValue('url-current'),
      urlReferer: this.getRoiTrackerDataSelectorValue('url-referer'),
      referredID: this.getRoiTrackerDataSelectorValue('referrerid'),
    };
  }

  getQueryParams() {
    return this.queryParams;
  }

  getQuoteToolMode() {
    return this.getQueryParams()?.get('quoteToolMode');
  }

  getPrefilledSchoolCourse$(): Observable<SchoolAndCourseData> {
    if (!this._getPrefilledSchoolCourse$) {
      this.initGetPrefilledSchoolCourse();
    }
    return this._getPrefilledSchoolCourse$;
  }

  savePrefillQueryParamsToSessionStorage(queryParams?: URLSearchParams) {
    this.queryParams = queryParams ? queryParams : new URLSearchParams(window.location.search);
    this.savePrefilledQuoteQueryParams(this.queryParams);
    this.saveQuotePaymentConfirmationQueryParams(this.queryParams);
  }

  clearPaymentStatus() {
    this.sessionStorage.delete(STORAGE_KEY_QT_QUOTE_PAYMENT_STATUS);
  }

  private getCourseClosestNumberOfWeeks(course: Course, date: string, level?: CourseLevel, desiredWeeks = 0) {
    const courseDurations: number[] = getCourseDurations(course, level, date);
    if (courseDurations?.length) {
      if (desiredWeeks && courseDurations.includes(desiredWeeks)) {
        return desiredWeeks;
      } else if (desiredWeeks && courseDurations[0] < desiredWeeks) {
        for (let i = courseDurations.length - 1; i >= 0; i--) {
          if (courseDurations[i] < desiredWeeks) {
            return courseDurations[i];
          }
        }
      } else {
        return courseDurations[0];
      }
    }
  }

  private getRoiTrackerDataSelectorValue(roiTrackerDataSelectorKey: string): string {
    return this.document.querySelector<HTMLInputElement>(`input[data-drupal-selector="edit-${roiTrackerDataSelectorKey}"]`)?.value;
  }

  private getPreselectedSchoolCourseQueryParams(): DeepLinkParameters {
    if (!this.queryParams) {
      // get query params from URL if not already set
      this.queryParams = new URLSearchParams(window.location.search);
    }

    return {
      preselectedMode: this.queryParams.get('quoteToolMode') as PreselectedModes,
      schoolCode: this.queryParams.get('schoolCode'),
      courseCode: this.queryParams.get('courseCode'),
      courseDuration: Number(this.queryParams.get('courseDuration')),
      startDate: getDateFromString(this.queryParams.get('startDate')),
    };
  }

  private initGetPrefilledSchoolCourse() {
    this._getPrefilledSchoolCourse$ = of(this.getPreselectedSchoolCourseQueryParams()).pipe(
      mergeMap((prefilledSchoolCourse) =>
        iif(
          () => Boolean(prefilledSchoolCourse.schoolCode),
          this.resolveData$(
            prefilledSchoolCourse.schoolCode,
            prefilledSchoolCourse.courseCode,
            prefilledSchoolCourse.courseDuration,
            prefilledSchoolCourse.startDate,
          ),
          of([null, null, prefilledSchoolCourse.courseDuration]),
        ),
      ),
      take(1),
      map(([countries, cities, , school, course, weeks, startDate]: [Country[], City[], CourseType[], QuoteSchool, Course, number, Date]): QuoteInput => {
        if (school && school.courseLanguages.length) {
          const level: CourseLevel = this.getBestLevel(course);
          const fistAvailableDate: [string, boolean] = course && getCourseFirstAvailableDateWithConfirmation(course, startDate, true);
          return {
            languageCode: school.courseLanguages[0].code,
            countryCode: school.country,
            country: countries.find((country: Country) => country.code === school.country),
            destinationCity: cities.find((city) => city.code === school.city),
            school: school.code,
            schoolObject: school,
            courseType: course?.type?.code,
            courses: [
              {
                level: level,
                code: course?.code,
                startDate: fistAvailableDate && fistAvailableDate[0],
                dateConfirmed: fistAvailableDate && fistAvailableDate[1],
                weeks: course && this.getCourseClosestNumberOfWeeks(course, fistAvailableDate && fistAvailableDate[0], level, weeks),
              },
            ],
          };
        }
        return undefined;
      }),
      shareReplay(1),
    );
  }

  private resolveData$(
    schoolCode: string,
    courseCode: string,
    courseDuration: number,
    startDate: Date,
  ): Observable<[Country[], City[], CourseType[], QuoteSchool, Course, number, Date]> {
    const school$ = this.schoolService.get(schoolCode).pipe(catchError(() => of(null)));
    return combineLatest([
      this.countryService.getAll(),
      iif(() => this.company === COMPANIES.ESL, this.getCities(school$), of([])),
      this.courseTypeService.getAll(),
      school$,
      this.productSchoolService.getCourse(schoolCode, courseCode).pipe(catchError(() => of(null))),
      of(courseDuration),
      of(startDate),
    ]);
  }

  private getCities(school$: Observable<QuoteSchool>): Observable<City[]> {
    return school$.pipe(
      switchMap((school: QuoteSchool) => {
        if (school && school.courseLanguages?.length > 0) {
          const courseLanguageCode = school.courseLanguages[0]?.code;
          return this.cityService.getAllCitiesWithParameters({ courseLanguageCode });
        } else {
          return this.cityService.getAll();
        }
      }),
    );
  }

  private savePrefilledQuoteQueryParams(queryParams: URLSearchParams): void {
    const leadId = queryParams.get('leadId');
    const klgToken = queryParams.get('token');
    if (leadId) {
      this.sessionStorage.set(STORAGE_KEY_QT_PREFILLED_ENROLMENT_ID, leadId);
    }
    if (klgToken) {
      this.sessionStorage.set(STORAGE_KEY_QT_PREFILLED_KLG_TOKEN, klgToken);
    }
  }

  private saveQuotePaymentConfirmationQueryParams(queryParams: URLSearchParams): void {
    const paymentStatus = queryParams.get('status') as 'success' | 'error';
    if (paymentStatus) {
      this.sessionStorage.set(STORAGE_KEY_QT_QUOTE_PAYMENT_STATUS, paymentStatus);
    }
  }

  /**
   * Try and get the best level for the course: the smallest level with most available dates or the first one if not possible
   * @param course the course
   * @private the selected level
   */
  private getBestLevel(course: Course): CourseLevel {
    if (!course?.supportedLevels?.length) {
      return null;
    }

    const courseDates = course.courseDates;

    return course?.supportedLevels
      ?.map((level) => {
        const datesContainLevel = courseDates.some((date) => date.level === Number(level.code));
        return {
          level,
          dates: courseDates.filter((date) => (datesContainLevel ? date.level === Number(level.code) : !date.level)).length,
        };
      })
      .reduce((prev, current) => {
        if (prev.dates > current.dates) {
          return prev;
        } else if (prev.dates === current.dates && prev.level.code < current.level.code) {
          return prev;
        } else {
          return current;
        }
      }).level;
  }
}
