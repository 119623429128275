<div class="host-family-selection__title">
  <div i18n>Homestay Preferences</div>
  <div class="host-family-selection__sub-title">(<span i18n>Not Guaranteed</span>)</div>
</div>
<div class="host-family-selection__selection">
  <kng-form-field-selector
    i18n-label
    label="Non Smokers"
    valueExpr="code"
    placeholder="{{ placeholderText }}"
    i18n-placeholder
    [itemList]="hostFamilyOptions"
    [required]="false"
    [searchEnabled]="false"
    (itemChange)="onItemChange('isNonSmokingFamily', $event)"
    data-qa="qt-host-family-selector-non-smokers"
  ></kng-form-field-selector>
  <kng-form-field-selector
    i18n-label
    label="With Children"
    valueExpr="code"
    placeholder="{{ placeholderText }}"
    i18n-placeholder
    [itemList]="hostFamilyOptions"
    [required]="false"
    [searchEnabled]="false"
    (itemChange)="onItemChange('hasChildrenFamily', $event)"
    data-qa="qt-host-family-selector-with-children"
  ></kng-form-field-selector>
  <kng-form-field-selector
    i18n-label
    label="With Pets"
    valueExpr="code"
    placeholder="{{ placeholderText }}"
    i18n-placeholder
    [itemList]="hostFamilyOptions"
    [required]="false"
    [searchEnabled]="false"
    (itemChange)="onItemChange('hasPetsFamily', $event)"
    data-qa="qt-host-family-selector-with-pets"
  ></kng-form-field-selector>
  <kng-form-field-selector
    i18n-label
    label="With Students"
    valueExpr="code"
    placeholder="{{ placeholderText }}"
    i18n-placeholder
    [itemList]="hostFamilyOptions"
    [required]="false"
    [searchEnabled]="false"
    (itemChange)="onItemChange('hasStudentsFamily', $event)"
    data-qa="qt-host-family-selector-with-students"
  ></kng-form-field-selector>
</div>
