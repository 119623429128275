<!-- Product Accordion All Labels Except Supplement ESL -->
<kng-quote-product-label
  [quoteKey]="quoteKey"
  [hasProductLabelUnits]="product?.units > 1 && product?.classification === productTypes?.SUPPLEMENT"
  [productUnits]="product?.units | klgLocalizeAndPluralizePeriod"
  [productName]="product?.classification !== productTypes?.TRANSFER ? product?.name : transferMessage"
  [hasProductInfo]="availableOptOutProduct"
  [isProductEditable]="isQuoteSlotExpanded && isProductEditable"
  (infoButtonClicked)="infoButtonClicked.emit()"
  (editButtonClicked)="editButtonClicked.emit()"
  (removeButtonClicked)="removeButtonClicked.emit()"
></kng-quote-product-label>

<!-- Product Accordion Descriptions Course ESL (Array) -->
<kng-quote-product-description *ngIf="!!product?.descriptions" [isArray]="true" [productDescription]="product?.descriptions"></kng-quote-product-description>

<!-- Product Accordion Descriptions Transfers (Not Array) -->
<kng-quote-product-description
  *ngIf="product?.classification === productTypes?.TRANSFER"
  [productDescription]="[product?.name]"
></kng-quote-product-description>

<!-- Product Accordion All Descriptions (Date Units) except Insurance (Not Array) -->
<kng-quote-product-description
  *ngIf="!!product?.units && !!product?.startDate"
  [productDescription]="!availableOptOutProduct ? [product?.units | klgLocalizeAndPluralizePeriod] : []"
></kng-quote-product-description>

<!-- Product Accordion Descriptions (Date Units) Insurance (Not Array) -->
<kng-quote-product-description
  *ngIf="(product?.classification === productTypes?.INSURANCE || product?.classification === productTypes?.TUITION) && product?.units && !product?.startDate"
  [productDescription]="[product?.units | klgLocalizeAndPluralizePeriod]"
></kng-quote-product-description>

<!-- Product Accordion Descriptions (Date Units) Virtual Internship (Not Array) -->
<kng-quote-product-description
  *ngIf="product?.classification === productTypes?.VIRTUAL_INTERNSHIP"
  [productDescription]="[product?.units | klgLocalizeAndPluralizePeriod]"
></kng-quote-product-description>

<!-- Product Accordion Descriptions (Date Units) Premium Activities (Not Array) -->
<kng-quote-product-description
  *ngIf="product?.classification === productTypes?.PREMIUM_ACTIVITIES"
  [productDescription]="[product?.units | klgLocalizeAndPluralizePeriod]"
></kng-quote-product-description>

<!-- Product Accordion Premium Activities Dates (Array) -->
<kng-quote-product-date
  *ngIf="!!product?.selectedWeeks"
  [isArray]="true"
  [dateFormat]="dateFormat"
  [productSelectedWeeks]="product?.selectedWeeks"
></kng-quote-product-date>

<!-- Product Accordion All Date Ranges (not array) -->
<kng-quote-product-date
  *ngIf="product?.endDate && !(currentCourses?.length > 1 && isAccommodationMandatory && product?.classification === productTypes?.ACCOMMODATION)"
  [dateFormat]="dateFormat"
  [productSelectedWeeks]="!availableOptOutProduct ? selectedWeeks : []"
></kng-quote-product-date>

<!-- Product Accordion Descriptions Disclaimer ESL (Not Array) -->
<kng-quote-product-description
  *ngIf="product?.seasonVariation && product?.classification === productTypes?.SUPPLEMENT"
  [hasDisclaimer]="true"
  [productDescription]="[disclaimerMessage]"
></kng-quote-product-description>

<!-- Product Accordion Descriptions Vacations (Not Array) -->
<kng-quote-product-description
  *ngIf="weekLongVacation?.length > 0"
  [hasVacations]="true"
  [productDescription]="[weekLongVacation | klgClosingDates : product?.classification]"
></kng-quote-product-description>

<!-- Product Accordion All Prices Except Supplement ESL -->
<kng-quote-price-item
  [hasProductPrice]="!product?.priceOnRequest || isCourse"
  [productPrice]="product?.price | klgPrice : { currency: currency, minDecimalPlaces: decimalPlaces }"
></kng-quote-price-item>

<!-- Product Accordion Supplement ESL -->
<kng-quote-product-supplement [product]="product" [currency]="currency" [decimalPlaces]="decimalPlaces"></kng-quote-product-supplement>
