import { CourseDates } from '../models/course.model';
import { Course, CourseLevel } from '@klg/shared/data-access/types';

export function getCourseFirstAvailableDateWithConfirmation(course: Course, afterDate = new Date(), includingAfterDate = false): [string, boolean] {
  const firstAvailableDate = getFirstAvailableDate(course?.courseDates, afterDate, includingAfterDate);
  const courseDate = course?.courseDates?.find((date: CourseDates) => date.from == firstAvailableDate);
  return [courseDate?.from, courseDate?.confirmed];
}

export function getCourseFirstAvailableDate(course: Course, afterDate = new Date(), includingAfterDate = false): string {
  return getFirstAvailableDate(course?.courseDates, afterDate, includingAfterDate);
}

export function getFirstAvailableDate(dates: CourseDates[], afterDate = new Date(), includingAfterDate = false): string {
  const courseAvailableDates = dates?.filter((date: CourseDates) => {
    const courseDateFromTime = new Date(date.from).getTime();
    const afterDateTime = afterDate.getTime();
    return includingAfterDate ? afterDateTime <= courseDateFromTime : afterDateTime < courseDateFromTime;
  });
  const courseFirstAvailableDate: CourseDates = courseAvailableDates?.reduce((minCourseDate: CourseDates, date: CourseDates) => {
    const courseDateFromTime = new Date(date.from).getTime();
    const minCouseDateFromTime = new Date(minCourseDate.from).getTime();
    return minCouseDateFromTime < courseDateFromTime ? minCourseDate : date;
  }, courseAvailableDates[0]);

  return courseFirstAvailableDate?.from;
}

export function getCourseDatesByLevel(course: Course, level?: CourseLevel): CourseDates[] {
  const calendarStartingDates = level ? course?.courseDates?.filter((courseDate) => courseDate.level === +level.code) : [];
  if (calendarStartingDates?.length > 0) {
    return calendarStartingDates;
  }
  return course?.courseDates?.filter((courseDate) => !courseDate.level) ?? [];
}

export function getCourseDatesByCourseAndLevel(courses: Course[], course: Course, level?: CourseLevel): CourseDates[] {
  return [
    ...getCourseDatesByLevel(course, level),
    ...(courses && course?.relatedProducts
      ? course.relatedProducts
          .map((relatedProductId) => getCourseByCode(courses, relatedProductId))
          .flatMap((courseProduct) => getCourseDatesByLevel(courseProduct, level))
      : []),
  ];
}

export function getCourseDurations(course: Course, level?: CourseLevel, date?: string): number[] {
  // No course then empty array
  if (!course) {
    return [];
  }

  // FixedDurations then try to match it by level or first non null durations
  if (course.fixedDurations) {
    let fixedDuration = course.fixedDurations.find((fixedDuration) => fixedDuration?.level?.code === level?.code);
    if (!fixedDuration) {
      fixedDuration = course.fixedDurations[0];
    }
    if (fixedDuration?.durations) {
      return fixedDuration.durations.sort((d1, d2) => d1 - d2);
    }
  }

  let courseDurations;
  const fixDurations: CourseDates[] =
    date && getCourseDatesByLevel(course, level).filter((courseDate: CourseDates) => courseDate.from === date && courseDate.length);
  if (fixDurations?.length) {
    const dateLengthSet = new Set(fixDurations.map((courseDate: CourseDates) => courseDate.length).sort());
    courseDurations = [...dateLengthSet];
  } else {
    courseDurations = [...Array(course.durationMax + 1).keys()].slice(course.durationMin);
  }

  return courseDurations.sort((d1, d2) => d1 - d2);
}

export function getCourseByCode(courses: Course[], code: string): Course {
  return courses && code ? courses.find((course) => course.code === code) : null;
}

export function getCoursesByCodes(courses: Course[], codes: string[]): Course[] {
  return courses && codes ? codes.map((code) => getCourseByCode(courses, code)).filter((course) => !!course) : null;
}

export function getCourseByCourseDate(courses: Course[], date: string): Course {
  return date ? courses?.find(({ courseDates }) => courseDates.map(({ from }) => from).includes(date)) : null;
}

export function hasCourseDatesAvailableByLevelAndMinYear(course: Course, levelId: string, minYear: number): boolean {
  const level = levelId ? { code: levelId } : null;
  return getCourseDatesByLevel(course, level).some((courseDate) => new Date(courseDate.from).getFullYear() >= minYear);
}
