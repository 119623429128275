<kng-form-field-selector
  [label]="label"
  [disabled]="disabled"
  [value]="value"
  valueExpr="code"
  [itemList]="itemList"
  [buttonView]="buttonView"
  [placeholder]="placeholder"
  [isOpen]="isOpen"
  [autofill]="autofill"
  [required]="required"
  [sortCriteria]="sortCriteria"
  [hideHelpMeChoose]="true"
  [iconValidation]="iconValidation"
  (valueChange)="valueChange.emit($event)"
  (itemChange)="itemChange.emit($event)"
  (isOpenChange)="isOpenChange.emit($event)"
>
  <ng-template #optionItemTemplate let-courseTypeItem let-showDetails="showDetails">
    <kng-item-card
      class="qt-course-selection__course-item"
      [mode]="showDetails ? 'back' : 'cover'"
      [item]="courseType(courseTypeItem)"
      [hasPromotion]="courseType(courseTypeItem).hasPromotion"
      titleExpression="name"
      imageExpression="thumbnailSmall"
      [showMoreDetails]="isOpen"
      (moreDetailsClicked)="popupCourseType = courseTypeItem"
      (showOfferPopup)="showOfferPopup(courseType(courseTypeItem).code)"
      [googleAnalyticsClickEvent]="GoogleAnalyticsEvents.QUOTE_THUMBNAIL_COURSE_TYPE"
    ></kng-item-card>
  </ng-template>
</kng-form-field-selector>

<kng-details-popup
  *ngIf="popupCourseType"
  [images]="[popupCourseType?.thumbnailMedium]"
  [isJuniors]="false"
  (ctaClicked)="value = popupCourseType?.code; valueChange.emit(popupCourseType?.code); popupCourseType = null"
  (popupClosed)="popupCourseType = null"
  [googleAnalyticsClickEvent]="GoogleAnalyticsEvents.QUOTE_HIGHLIGHT_COURSE_TYPE"
>
  <h6 class="course-type-selector__popup-title">{{ popupCourseType?.name }}</h6>
  <div class="course-type-selector__popup-description">{{ popupCourseType?.description }}</div>
</kng-details-popup>
