import { Directive, HostListener, inject, Input, OnDestroy, OnInit } from '@angular/core';
import { FormData } from '@klg/core/forms/form-data.model';
import { NotificationService } from '@klg/shared/logger';
import { CustomHostListeners } from '@klg/shared/utils';
import { BehaviorSubject, Subject, switchMap, takeUntil } from 'rxjs';

/**
 * Directive that handles the validity of a form data.
 * If the field is focused, validation occurs while clicking outside - the first time data is entered.
 * If the field is input, validation occurs while typing - subsequent times data is entered.
 */

@Directive({
  standalone: true,
  selector: '[kngFormDataInputValidity]',
})
export class FormDataInputValidityDirective<T> implements OnInit, OnDestroy {
  @Input() formData: FormData<T> | undefined;

  private readonly validation$ = new Subject<void>();
  private readonly enabledValidation$ = new BehaviorSubject(false);

  private readonly destroy$ = new Subject<void>();

  readonly notificationService = inject(NotificationService);

  ngOnInit(): void {
    if (!this.formData) {
      this.notificationService.warn(this.getNoFormDataWarningMessage());
      return;
    }

    // When enabledValidation$ emits, check if the validation is enabled.
    // In that case, emit the validation$ observable.
    this.enabledValidation$.pipe(takeUntil(this.destroy$)).subscribe((enabledValidation) => {
      if (enabledValidation) {
        this.validation$.next();
      }
    });

    // When the validation$ observable emits, check if the validation is enabled.
    // In that case, do the validation.
    this.validation$
      .pipe(
        switchMap(() => this.enabledValidation$),
        takeUntil(this.destroy$),
      )
      .subscribe((enabledValidation) => {
        if (enabledValidation) {
          this.formData.isValid = this.calculateFormDataValidity();
        }
      });
  }

  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }

  @HostListener('input')
  onInput() {
    this.validation$.next();
  }

  @HostListener(`${CustomHostListeners.componentFocusOut}`)
  onComponentFocusOut() {
    this.enabledValidation$.next(true);
  }

  @HostListener('focusout')
  onFocusOut() {
    this.enabledValidation$.next(true);
  }

  protected calculateFormDataValidity() {
    return this.formData.value !== undefined && this.formData.value !== null && this.formData.value !== '';
  }

  protected getNoFormDataWarningMessage() {
    return 'FormDataInputValidityDirective - Form data is not defined';
  }
}
