<div *ngIf="isEsl; else elseBlock">
  <div *ngIf="isShowingPromoExpiry" class="time-left__esl">
    <div *ngIf="onlyAvailable">{{ onlyAvailable }}</div>
    <div *ngIf="showExpiresOn" i18n>Expires on {{ date | klgDate : dateFormat.SHORT_MONTH_NAME }}</div>
  </div>
</div>

<ng-template #elseBlock>
  <div class="time-left__kaplan-alpadia" [class.time-left--accordion-closed]="!isAccordionExpanded">
    <kng-urgency-driver-label
      *ngIf="isUrgencyDriverInRange && isShowingPromoExpiry && !isAccordionExpanded"
      [hasIcon]="isUrgencyDriverInRange && (!quoteKey || isSelectedSlot(quoteKey))"
      [isQuoteSummary]="isUrgencyDriverInRange"
      [urgencyDriverTitle]="onlyAvailable"
      [urgencyDriverDescription]="isUrgencyDriverInRange && (!quoteKey || isSelectedSlot(quoteKey)) ? urgencyDriverDescription : ''"
      [urgencyDriverDate]="isUrgencyDriverInRange && (!quoteKey || isSelectedSlot(quoteKey)) ? (date | klgDate : dateFormat.SHORT_MONTH_NAME) : ''"
    ></kng-urgency-driver-label>
  </div>
</ng-template>
