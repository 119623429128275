import {
  AccommodationApiDto as ProductAccommodationApiDto,
  AccommodationTypeApiDto,
  AccommodationV2ApiDto,
  BathroomTypeApiDto,
  MealOptionsApiDto,
  RoomTypeApiDto,
} from '@klg/shared/api/models';
import { Amount } from '@klg/shared/data-access/types';

export type Accommodation = AccommodationV2ApiDto;
export type RoomType = RoomTypeApiDto;
export const RoomTypeEnum = RoomTypeApiDto;
export type BathroomType = BathroomTypeApiDto;
export const BathroomTypeEnum = BathroomTypeApiDto;
export type MealOption = MealOptionsApiDto;
export const MealOptionEnum = MealOptionsApiDto;

export interface GroupableAccommodation extends Accommodation {
  groupingKey: string;
}

export interface AccommodationWithOptions {
  groupingKey: string;
  name: string;
  thumbnailSmall: string;
  description: string;
  galleryUrls: Array<string>;
  roomTypes: Set<RoomTypeApiDto>;
  bathroomTypes: Set<BathroomTypeApiDto>;
  mealOptions: Set<MealOptionsApiDto>;
  startingPrice: Amount;
  oldPrice: Amount;
  hasPromotion?: boolean;
  accommodationType: AccommodationTypeApiDto;
  seasonalDisclaimer?: string;
  subjectToAvailability?: boolean;
  company: AccommodationV2ApiDto.CompanyApiDtoEnum;
  ageMin?: number;
}

export type ProductAccommodation = ProductAccommodationApiDto & { weight?: number };

export function roomTypeSorter(a: string, b: string): number {
  if (a === RoomTypeApiDto.SINGLE) {
    return -1;
  } else if (b === RoomTypeApiDto.DOUBLE) {
    return 1;
  } else {
    return 0;
  }
}

export function bathroomTypeSorter(a: string, b: string): number {
  if (a === BathroomTypeApiDto.PRIVATE) {
    return -1;
  } else if (b === BathroomTypeApiDto.SHARED) {
    return 1;
  } else {
    return 0;
  }
}

const mealOptionsOrder = {
  [MealOptionEnum.NO_MEALS]: 0,
  [MealOptionEnum.BED_BREAKFAST]: 1,
  [MealOptionEnum.HALF_BOARD]: 2,
  [MealOptionEnum.MEAL_PLAN]: 3,
};

export function mealOptionsSorter(a: string, b: string): number {
  return mealOptionsOrder[a] - mealOptionsOrder[b];
}
