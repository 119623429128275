<kng-form-field class="date-of-birth-field" [small]="true" [label]="label" [required]="required" [errorMessage]="errorMessage" [isValid]="isValid">
  <p-inputNumber
    class="date-of-birth-field__day"
    [(ngModel)]="day"
    (ngModelChange)="updateDateOfBirth()"
    [format]="false"
    [maxlength]="2"
    placeholder="DD"
    i18n-placeholder
  />
  <p-dropdown
    [options]="months"
    class="date-of-birth-field__month"
    [style]="{ width: '100%' }"
    optionLabel="name"
    optionValue="code"
    [scrollHeight]="monthScrollHeight"
    [overlayOptions]="overlayOption"
    placeholder="Month"
    [(ngModel)]="month"
    (ngModelChange)="updateDateOfBirth()"
    i18n-placeholder
  />
  <p-inputNumber
    class="date-of-birth-field__year"
    [(ngModel)]="year"
    (ngModelChange)="updateDateOfBirth()"
    [format]="false"
    [maxlength]="4"
    placeholder="YYYY"
    i18n-placeholder
  />
</kng-form-field>
