import { ChangeDetectionStrategy, Component, EventEmitter, Inject, Input, OnDestroy, OnInit, Output, Renderer2 } from '@angular/core';
import { DOCUMENT } from '@angular/common';

@Component({
  selector: 'kng-popup',
  templateUrl: './popup.component.html',
  styleUrls: ['./popup.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class PopupComponent implements OnInit, OnDestroy {
  @Input() title: string;
  @Input() active: boolean;

  @Input() get width(): string | number {
    return this._width;
  }

  set width(val: string | number) {
    this._width = this.parseSize(val);
  }

  @Input() get maxWidth(): string | number {
    return this._maxWidth;
  }
  set maxWidth(val) {
    this._maxWidth = this.parseSize(val);
  }
  @Output() closePopup = new EventEmitter();

  // Private properties
  _maxWidth: string;
  _width: string;

  constructor(@Inject(DOCUMENT) private document: Document, private readonly renderer: Renderer2) {
    this.width = null;
    this.maxWidth = null;
  }

  ngOnInit(): void {
    this.renderer.setStyle(this.document.documentElement, 'overflow', 'hidden');
  }

  public closePopupEvent() {
    this.closePopup.emit();
  }

  private parseSize(val: string | number): string {
    if (typeof val === 'string') {
      return val;
    }
    if (typeof val === 'number') {
      return val <= 100 ? `${val}%` : `${val}px`;
    }
    return '';
  }

  ngOnDestroy(): void {
    this.renderer.setStyle(this.document.documentElement, 'overflow', '');
  }
}
