import { merge, Observable } from 'rxjs';
import { filter, mapTo } from 'rxjs/operators';

const cssUnitsPattern = /([A-Za-z%]+)$/;

function _htmlElementMatchAnySelector(htmlElement: HTMLElement, elementSelectors: string[]): boolean {
  return elementSelectors.some((elementSelector: string) => {
    switch (elementSelector.substring(0, 1)) {
      case '#':
        return htmlElement.id == elementSelector.replace('#', '');

      case '.':
        return htmlElement.classList.contains(elementSelector.replace('.', ''));

      default:
        return htmlElement.tagName === elementSelector;
    }
  });
}

function _isElementInNodeList(nodeList: NodeList, selectors: string): boolean {
  const elementSelectors: string[] = splitElementSelectors(selectors);
  return Array.from(nodeList).some((addedNode: Node) => _htmlElementMatchAnySelector(addedNode as HTMLElement, elementSelectors));
}

export function addLibrary(path: string): HTMLScriptElement {
  const script: HTMLScriptElement = document.createElement('script');
  script.src = path;
  script.type = 'text/javascript';
  document.head.appendChild(script);
  return script;
}

export function observeMutation(target: Node, config: MutationObserverInit): Observable<MutationRecord[]> {
  return new Observable((observer) => {
    const mutation = new MutationObserver((mutations) => {
      observer.next(mutations);
    });
    mutation.observe(target, config);
    return () => {
      mutation.disconnect();
    };
  });
}

export function observeStyleChangesFromElement(element: HTMLElement): Observable<MutationRecord[]> {
  return observeMutation(element, {
    attributeFilter: ['style'],
    attributeOldValue: true,
    characterDataOldValue: true,
    subtree: true,
  });
}

export function observeStyleChanges(selectors: string): Observable<HTMLElement> {
  const elements: HTMLElement[] = Array.from(document.querySelectorAll<HTMLElement>(selectors));

  return merge(...elements.map((element: HTMLElement) => observeStyleChangesFromElement(element).pipe(mapTo(element))));
}

export function observeElementAddedToDom(selectors: string, targetNode: Node = document.querySelector('body')) {
  return observeMutation(targetNode, { subtree: false, childList: true }).pipe(
    filter((mutations: MutationRecord[]) => mutations.some((mutation) => _isElementInNodeList(mutation.addedNodes, selectors))),
  );
}

export function observeElementRemovedFromDom(selectors: string, targetNode: Node = document.querySelector('body')) {
  return observeMutation(targetNode, { subtree: false, childList: true }).pipe(
    filter((mutations: MutationRecord[]) => mutations.some((mutation) => _isElementInNodeList(mutation.removedNodes, selectors))),
  );
}

export function removeLibrary(scriptElement: HTMLScriptElement): void {
  scriptElement.parentNode.removeChild(scriptElement);
}

export function splitElementSelectors(selectors: string): string[] {
  return selectors.split(',').map((selector) => selector?.trim());
}

export function coerceCssPixelValue(value: any): string {
  if (value === null) {
    return '';
  }
  return cssUnitsPattern.test(value) ? value : `${value}px`;
}
