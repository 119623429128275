import { Component, EventEmitter, Input, OnDestroy, OnInit, Output, inject } from '@angular/core';
import { Subscription } from 'rxjs';
import { getEnvironment } from '@klg/shared/tokens';
import { DialogModule } from 'primeng/dialog';
import { GalleriaModule } from 'primeng/galleria';
import { ButtonComponent } from '@klg/shared/ui/button';
import { CommonModule } from '@angular/common';
import { BreakpointObserverService } from '@klg/shared/utils-dom';
import { GoogleAnalyticsClickEventDirective } from '@klg/shared/google-analytics';
let id = 0;

@Component({
  standalone: true,
  selector: 'kng-details-popup',
  templateUrl: './details-popup.component.html',
  styleUrls: ['./details-popup.component.scss'],
  imports: [CommonModule, DialogModule, GalleriaModule, ButtonComponent, GoogleAnalyticsClickEventDirective],
})
export class DetailsPopupComponent implements OnInit, OnDestroy {
  id = 0;
  @Input() images = [];
  @Input() buttonIdText = 'kng-details-popup' + this.id;
  @Input() buttonText = $localize`Select`;
  @Input() secondaryState = false;
  @Input() buttonText$;
  @Input() secondaryState$;
  @Input() isJuniors = false;
  @Input() showButton = true;
  @Input() isJuniorCourseQuoteSummary = false;
  @Input() googleAnalyticsClickEvent: string | undefined;
  @Output() popupClosed = new EventEmitter<void>();
  @Output() ctaClicked = new EventEmitter<void>();
  public rtlEnabled = false;
  public isPopupOpen = true;
  private readonly environment = getEnvironment();
  private subscriptions: Subscription = new Subscription();
  private readonly breakpointObserverService = inject(BreakpointObserverService);
  readonly isMobile$ = this.breakpointObserverService.isMobile$;

  constructor() {
    this.id = ++id;
  }

  ngOnInit(): void {
    if (this.buttonText$) {
      this.subscriptions.add(
        this.buttonText$.subscribe((buttonText) => {
          this.buttonText = buttonText;
        }),
      );
      this.subscriptions.add(
        this.secondaryState$.subscribe((active) => {
          this.secondaryState = active;
        }),
      );
    }

    this.rtlEnabled = this.environment.RTL_ENABLED;
  }

  clickPopupButton() {
    // Set timeout to allow executing the code of kngGAClickEvent directive before it is destroyed
    setTimeout(() => this.ctaClicked.emit());
  }

  closePopup = () => {
    this.popupClosed.emit();
    this.isPopupOpen = false;
  };

  ngOnDestroy(): void {
    this.subscriptions.unsubscribe();
  }
}
