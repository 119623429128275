import { Component, Input } from '@angular/core';

@Component({
  selector: 'kng-quote-product-description',
  templateUrl: './quote-product-details-description.component.html',
  styleUrls: ['./quote-product-details-description.component.scss'],
})
export class QuoteProductDetailsDescriptionComponent {
  @Input() productDescription: string[] = [];
  @Input() hasVacations = false;
  @Input() hasDisclaimer = false;
  @Input() isArray = false;
}
