import { Injectable } from '@angular/core';
import { ProductsControllerApiService } from '@klg/shared/api/services';
import { DEFAULT_CACHE_TIME, CachedRequestResponse } from '@klg/shared/utils-http';
import { getLocale } from '@klg/shared/i18n';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { ResidenceCountry } from '../types';
import { getCompanyHeader } from '@klg/shared/tokens';

@Injectable({
  providedIn: 'root',
})
export class ResidenceCountryService {
  private locale = getLocale();
  private internalCache$: Observable<ResidenceCountry[]>;
  private cacheSettings: any = {
    get: DEFAULT_CACHE_TIME,
  };

  private responseCache = new CachedRequestResponse<ResidenceCountry[]>(
    () => this.productsApiService.getResidenceCountries(this.locale, this.companyHeader),
    this.cacheSettings.get,
  );

  private readonly companyHeader = getCompanyHeader();

  constructor(private productsApiService: ProductsControllerApiService) {}

  public getAll(): Observable<ResidenceCountry[]> {
    if (!this.internalCache$) {
      this.internalCache$ = this.responseCache.get();
    }
    return this.internalCache$;
  }

  public getByCode(code: string): Observable<ResidenceCountry> {
    return this.getAll().pipe(map((countries) => countries.find((c) => c.code === code)));
  }
}
