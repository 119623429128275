import { Injectable } from '@angular/core';
import { Currency, mockCurrencies } from '../model/currency.model';
import { Observable, of } from 'rxjs';
import { BaseService, CachedRequestResponse } from '@klg/shared/utils-http';

@Injectable({
  providedIn: 'root',
})
export class CurrencyService extends BaseService<Currency> {
  protected responseCache = new CachedRequestResponse<Currency[]>(() => of(mockCurrencies), this.cacheSettings.get);

  constructor() {
    super();
  }

  protected getResponseCache$(): Observable<CachedRequestResponse<Currency[]>> {
    return of(this.responseCache);
  }
}
