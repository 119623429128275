import ar from '@angular/common/locales/ar';
import ar_SA from '@angular/common/locales/ar-SA';
import de from '@angular/common/locales/de';
import de_CH from '@angular/common/locales/de-CH';
import de_AT from '@angular/common/locales/de-AT';
import en from '@angular/common/locales/en';
import en_GB from '@angular/common/locales/en-GB';
import es from '@angular/common/locales/es';
import en_SG from '@angular/common/locales/en-SG';
import es_419 from '@angular/common/locales/es-419';
import fr from '@angular/common/locales/fr';
import fr_BE from '@angular/common/locales/fr-BE';
import fr_CH from '@angular/common/locales/fr-CH';
import it from '@angular/common/locales/it';
import it_CH from '@angular/common/locales/it-CH';
import ja from '@angular/common/locales/ja';
import ko from '@angular/common/locales/ko';
import nl from '@angular/common/locales/nl';
import pl from '@angular/common/locales/pl';
import pt from '@angular/common/locales/pt';
import ru from '@angular/common/locales/ru';
import sv from '@angular/common/locales/sv';
import th from '@angular/common/locales/th';
import tr from '@angular/common/locales/tr';
import zh_Hans from '@angular/common/locales/zh-Hans';
import zh_Hant from '@angular/common/locales/zh-Hant';
import { registerLocaleData } from '@angular/common';

const locales = {
  ar,
  'ar-SA': ar_SA,
  de,
  'de-DE': de,
  'de-CH': de_CH,
  'de-AT': de_AT,
  en,
  'en-US': en,
  'en-GB': en_GB,
  'en-SG': en_SG,
  es,
  'es-ES': es,
  'es-419': es_419,
  fr,
  'fr-FR': fr,
  'fr-BE': fr_BE,
  'fr-CH': fr_CH,
  it,
  'it-IT': it,
  'it-CH': it_CH,
  ja,
  'ja-JP': ja,
  ko,
  'ko-KR': ko,
  nl,
  'nl-NL': nl,
  pl,
  'pl-PL': pl,
  pt,
  'pt-BR': pt,
  ru,
  'ru-RU': ru,
  sv,
  'sv-SE': sv,
  th,
  'th-TH': th,
  tr,
  'tr-TR': tr,
  zh_Hans,
  'zh-CN': zh_Hans,
  zh_Hant,
  'zh-TW': zh_Hant,
};

export function registerLocale(locale: string) {
  registerLocaleData(locales[locale], locale);
}
