import { ChangeDetectionStrategy, Component, EventEmitter, inject, Input, OnInit, Output } from '@angular/core';
import { TimezoneService } from './timezone.service';
import { Observable } from 'rxjs';
import { CodeAndName } from '@klg/shared/types';

@Component({
  selector: 'kng-timezone-selector',
  templateUrl: './timezone-selector.component.html',
  styleUrls: ['./timezone-selector.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [TimezoneService],
})
export class TimezoneSelectorComponent implements OnInit {
  @Input() label = '';
  @Input() small = false;
  @Input() disabled = false;
  @Input() placeholder = $localize`Please select your time zone`;
  @Input() required = false;
  @Input() value: string | null = null;
  @Output() valueChange = new EventEmitter<string>();

  timezones$: Observable<CodeAndName[]> | undefined;
  dirty = false;

  private readonly timezoneService = inject(TimezoneService);

  ngOnInit(): void {
    this.timezones$ = this.timezoneService.timezones$;
  }
}
