import { Component, ElementRef, HostListener, inject, Input, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { CommonModule } from '@angular/common';
import { getEnvironment } from '@klg/shared/tokens';
import { Subscription } from 'rxjs';
import { BreakpointObserverService } from '@klg/shared/utils-dom';
import { buttonHintAnimation } from '@klg/ui/animations';

@Component({
  selector: 'kng-button-hint',
  standalone: true,
  imports: [CommonModule],
  templateUrl: './button-hint.component.html',
  styleUrls: ['./button-hint.component.scss'],
  animations: [buttonHintAnimation],
})
export class ButtonHintComponent implements OnInit, OnDestroy {
  @Input() id = '';
  @Input() content = '';

  contentElementRef?: ElementRef;

  @ViewChild('contentElement') set contentElement(contentElement: ElementRef) {
    this.contentElementRef = contentElement;
    this.setContentTransformStyle();
  }

  @ViewChild('arrowElement') set arrowElement(arrowElement: ElementRef) {
    if (arrowElement) {
      arrowElement.nativeElement.style.transform = this.isRtlEnabled ? 'translate(50%, calc(-100% - 1px))' : 'translate(-50%, calc(-100% - 1px))';
      arrowElement.nativeElement.style.msTransform = this.isRtlEnabled ? 'translate(8px, -5px)' : 'translate(-8px, -5px)';
    }
  }

  public showPopup = false;

  private readonly environment = getEnvironment();
  private readonly isRtlEnabled = this.environment.RTL_ENABLED;

  private readonly breakpointObserverService = inject(BreakpointObserverService);
  private subscriptions = new Subscription();

  private isMobile = true;

  constructor(private elementRef: ElementRef) {}

  ngOnInit(): void {
    this.subscriptions.add(
      this.breakpointObserverService.isMobile$.subscribe((isMobile) => {
        this.isMobile = isMobile;
        this.setContentTransformStyle();
      }),
    );
  }

  ngOnDestroy() {
    this.subscriptions.unsubscribe();
  }

  @HostListener('document:click', ['$event'])
  onClickDocument($event: any) {
    this.onClick($event);
  }

  togglePopup(): void {
    this.showPopup = !this.showPopup;
  }

  onClick(event: any): void {
    if (this.showPopup && !this.elementRef.nativeElement.contains(event.target)) {
      this.showPopup = false;
    }
  }

  private setContentTransformStyle() {
    if (!this.contentElementRef) {
      return;
    }
    if (this.isMobile) {
      this.contentElementRef.nativeElement.style.transform = this.isRtlEnabled
        ? 'translate(90%, calc(-100% - 0.5rem))'
        : 'translate(-90%, calc(-100% - 0.5rem))';
    } else {
      this.contentElementRef.nativeElement.style.transform = this.isRtlEnabled
        ? 'translate(50%, calc(-100% - 0.5rem))'
        : 'translate(-50%, calc(-100% - 0.5rem))';
    }
    this.contentElementRef.nativeElement.style.msTransform = this.isRtlEnabled ? 'translate(50%, -106%)' : 'translate(-50%, -106%)';
  }
}
