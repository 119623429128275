<div
  class="currency-selector__currency"
  id="local-currency"
  [ngClass]="{ 'currency-selector__currency--selected': currencyCode !== DESTINATION_CURRENCY_KEY }"
  (click)="handleDropdownClick()"
>
  {{ selectedCurrency?.code }} <i *ngIf="currencyCode !== DESTINATION_CURRENCY_KEY" class="pi pi-angle-down"></i>
</div>
<div class="currency-selector__icon" *ngIf="!hideDestinationCurrency"></div>
<div
  *ngIf="!hideDestinationCurrency"
  class="currency-selector__currency"
  id="destination-currency"
  [ngClass]="{ 'currency-selector__currency--selected': currencyCode === DESTINATION_CURRENCY_KEY }"
  (click)="handleDestinationClick()"
  i18n
>
  Destination Currency
</div>

<p-dialog
  *ngIf="isCurrencySelectorOpen && displayCurrencies.length > 0"
  [rtl]="true"
  [modal]="true"
  [style]="{ height: '100%', width: '100%', maxHeight: '620px', maxWidth: '406px' }"
  [closable]="true"
  [(visible)]="isCurrencySelectorOpen"
  [draggable]="false"
  [showHeader]="true"
  [closeOnEscape]="true"
  [dismissableMask]="true"
  (onHide)="closePopup()"
  title="Select currency"
  i18n-title
>
  <div class="currency-selector__popup-wrapper">
    <div class="currency-selector__popup-title" i18n>Select your currency</div>
    <div class="currency-selector__popup-selected">
      <div class="currency-selector__popup-label" i18n>Selected currency</div>
      <div
        class="currency-selector__popup-value currency-selector__popup-value--selected"
        [class.currency-selector__popup-value--selected--adult]="!invertPalette"
        [class.currency-selector__popup-value--selected--junior]="invertPalette"
      >
        <div
          class="currency-selector__popup-currency-flag"
          [ngStyle]="{ backgroundImage: 'url(/assets/images/flags/' + (selectedCurrency.countryIso | lowercase) + '.png)' }"
        ></div>
        <div class="currency-selector__popup-currency-code">{{ selectedCurrency.code }}</div>
      </div>
    </div>
    <div class="currency-selector__popup-selector">
      <div class="currency-selector__popup-label" i18n>Available currencies</div>
      <p-scrollPanel [style]="{ width: '100%', height: '300px' }">
        <div
          class="currency-selector__popup-value"
          *ngFor="let currency of displayCurrencies | klgFilter : [selectedCurrency.code] : 'code'"
          (click)="selectCurrency(currency)"
        >
          <div
            class="currency-selector__popup-currency-flag"
            [ngStyle]="{ backgroundImage: 'url(/assets/images/flags/' + (currency.countryIso | lowercase) + '.png)' }"
          ></div>
          <div class="currency-selector__popup-currency-code">{{ currency.code }}</div>
        </div>
      </p-scrollPanel>
    </div>
  </div>
</p-dialog>
