import { inject, Injectable } from '@angular/core';
import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Observable } from 'rxjs';
import { HttpErrorInterceptorService } from './http-error-interceptor.service';

@Injectable()
export class HttpErrorInterceptor implements HttpInterceptor {
  private readonly httpErrorInterceptorService = inject(HttpErrorInterceptorService);

  intercept(request: HttpRequest<unknown>, next: HttpHandler): Observable<HttpEvent<unknown>> {
    // Prepare HttpHandlerFn from HttpHandler to call to httpErrorService
    const nextFn = (req: HttpRequest<unknown>) => next.handle(req);
    return this.httpErrorInterceptorService.intercept(request, nextFn);
  }
}
