<kng-loading-spinner i18n-message message="Loading Options for your selection..." [loading]="loading" shadingColor="rgba(0,0,0,0.5)"></kng-loading-spinner>

<kng-section-template *ngIf="supplementGroups?.length" [columns]="1" [sectionIndex]="sectionIndex" i18n-sectionTitle sectionTitle="Select options">
  <div class="supplements__accordion" [ngClass]="{ 'supplements__accordion--open': supplementGroup.isOpen }" *ngFor="let supplementGroup of supplementGroups">
    <div class="supplements__accordion-title" (click)="supplementGroup.isOpen = !supplementGroup.isOpen">
      {{ supplementGroup.group }}
    </div>
    <div class="supplements__accordion-body" *ngIf="supplementGroup.isOpen" @accordionAnimation>
      <kng-course-supplement
        *ngFor="let supplement of supplementGroup.supplements | klgSortBy : [{ property: 'productPosition' }]"
        [supplement]="supplement"
        [showDescription]="true"
        [currency]="selectedCurrency"
        [quantity]="getSupplementStoredQuantity(supplement)"
        [quoteStart]="quoteStart"
        [quoteEnd]="quoteEnd"
        (quantityChange)="quantityChange(supplement.code, $event)"
      ></kng-course-supplement>
    </div>
  </div>
</kng-section-template>
