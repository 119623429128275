import { InjectionToken } from '@angular/core';

export const LANGUAGE_TOKENS = new InjectionToken<Record<string, string>>('LANGUAGE_TOKENS', {
  providedIn: 'root',
  factory: () => ({
    afr: $localize`Afrikaans`,
    af: $localize`Afrikaans`,
    ara: $localize`Arabic`,
    ar: $localize`Arabic`,
    ces: $localize`Czech`,
    cs: $localize`Czech`,
    dan: $localize`Danish`,
    da: $localize`Danish`,
    deu: $localize`German`,
    de: $localize`German`,
    ell: $localize`Greek`,
    el: $localize`Greek`,
    eng: $localize`English`,
    en: $localize`English`,
    spa: $localize`Spanish`,
    es: $localize`Spanish`,
    fin: $localize`Finnish`,
    fi: $localize`Finnish`,
    fra: $localize`French`,
    fr: $localize`French`,
    heb: $localize`Hebrew`,
    he: $localize`Hebrew`,
    hin: $localize`Hindi`,
    hi: $localize`Hindi`,
    hun: $localize`Hungarian`,
    hu: $localize`Hungarian`,
    ita: $localize`Italian`,
    it: $localize`Italian`,
    jpn: $localize`Japanese`,
    ja: $localize`Japanese`,
    kor: $localize`Korean`,
    ko: $localize`Korean`,
    nld: $localize`Dutch`,
    nl: $localize`Dutch`,
    nor: $localize`Norwegian`,
    no: $localize`Norwegian`,
    pol: $localize`Polish`,
    pl: $localize`Polish`,
    por: $localize`Portuguese`,
    pt: $localize`Portuguese`,
    rus: $localize`Russian`,
    ru: $localize`Russian`,
    swe: $localize`Swedish`,
    sv: $localize`Swedish`,
    tha: $localize`Thai`,
    th: $localize`Thai`,
    tur: $localize`Turkish`,
    tr: $localize`Turkish`,
    cmn: $localize`Mandarin`,
    zh: $localize`Mandarin`,
  }),
});
