import { Pipe, PipeTransform } from '@angular/core';
import { DurationUnit } from '@klg/shared/data-access/types';
import { DurationUnitsService } from '@klg/shared/utils/services';

@Pipe({
  name: 'klgLocalizeAndPluralizePeriod',
  standalone: true,
})
export class LocalizeAndPluralizePeriodPipe implements PipeTransform {
  constructor(private durationUnitsService: DurationUnitsService) {}

  transform(quantity: number, durationUnit?: DurationUnit): string {
    return this.durationUnitsService.getDurationText(quantity, durationUnit);
  }
}
