import { ROUTE_IDS } from '@klg/quote-tool/shared/types';

export const workflows: Record<string, Partial<Record<ROUTE_IDS, number>>> = {
  junior: {
    [ROUTE_IDS.RESIDENCE_COUNTRY]: 1,
    [ROUTE_IDS.AGE]: 2,
    [ROUTE_IDS.JUNIOR_BASE]: 3,
    [ROUTE_IDS.JUNIOR_EXTRAS]: 4,
    [ROUTE_IDS.QUOTE]: 5,
    [ROUTE_IDS.ENROLMENT]: 6,
    [ROUTE_IDS.ENROLMENT_PAYMENT]: 7,
  },
  adult: {
    [ROUTE_IDS.RESIDENCE_COUNTRY]: 1,
    [ROUTE_IDS.AGE]: 2,
    [ROUTE_IDS.ADULT_BASE]: 3,
    [ROUTE_IDS.ADULT_COURSE]: 4,
    [ROUTE_IDS.ADULT_EXTRAS]: 5,
    [ROUTE_IDS.QUOTE]: 6,
    [ROUTE_IDS.ENROLMENT]: 7,
    [ROUTE_IDS.ENROLMENT_PAYMENT]: 8,
  },
  online: {
    [ROUTE_IDS.RESIDENCE_COUNTRY]: 1,
    [ROUTE_IDS.AGE]: 2,
    [ROUTE_IDS.ONLINE_BASE]: 3,
    [ROUTE_IDS.QUOTE]: 4,
    [ROUTE_IDS.ENROLMENT]: 5,
    [ROUTE_IDS.ENROLMENT_PAYMENT]: 6,
  },
};
