import { Component, EventEmitter, Input, OnChanges, OnDestroy, Output, SimpleChanges, ViewChild } from '@angular/core';
import { deepClone, getDateFromString, getDateStringFromDate, getToday } from '@klg/shared/utils';
// eslint-disable-next-line @nrwl/nx/enforce-module-boundaries
import { CalendarModule } from 'primeng/calendar';
import { Subscription } from 'rxjs';
import { FormsModule } from '@angular/forms';
import { NgClass } from '@angular/common';
import { FormFieldComponent } from '@klg/shared/ui/form-field';
import { DropdownModule } from 'primeng/dropdown';
import { DatePickerFieldDate, DatePickerFieldMonthAndYear } from './date-picker.types';
import { UiModule } from '@klg/ui/components';
import { ButtonModule } from 'primeng/button';
import { CalendarFieldComponent } from '@klg/shared/ui/calendar-field';

@Component({
  standalone: true,
  selector: 'kng-date-picker-field',
  templateUrl: './date-picker-field.component.html',
  styleUrls: ['./date-picker-field.component.scss'],
  imports: [CalendarModule, FormsModule, NgClass, FormFieldComponent, DropdownModule, UiModule, CalendarFieldComponent, ButtonModule],
})
export class DatePickerFieldComponent implements OnChanges, OnDestroy {
  @ViewChild(CalendarFieldComponent) calendarFieldComponent: CalendarFieldComponent;
  @Input() label: string;
  @Input() placeholder: string;
  @Input() disabled: boolean;
  @Input() required = false;
  @Input() isValid: boolean;
  @Input() dateValue: string;
  @Input() dateObject: Date;
  @Input() defaultDate = getToday();
  @Input() minDate: Date;
  @Input() maxDate: Date;
  @Input() disabledDates: Date[];
  @Input() disabledDays: number[];
  @Input() showCalendarIcon: boolean;
  @Input() yearRange: string;
  @Input() googleAnalyticsClickEvent: string | undefined;
  @Output() dateChanged = new EventEmitter<DatePickerFieldDate>();
  @Output() calendarShown = new EventEmitter<DatePickerFieldDate>();
  @Output() monthChanged = new EventEmitter<DatePickerFieldMonthAndYear>();
  @Output() datePickerFocused = new EventEmitter<void>();

  today = getToday();
  pickedDate: DatePickerFieldDate = {} as DatePickerFieldDate;

  private subscription: Subscription = new Subscription();

  ngOnChanges({ dateValue, dateObject }: SimpleChanges): void {
    if (dateValue) {
      this.pickedDate = {
        dateString: dateValue.currentValue,
        dateObject: getDateFromString(dateValue.currentValue),
      };
    }

    if (dateObject) {
      this.pickedDate = {
        dateString: getDateStringFromDate(dateObject.currentValue),
        dateObject: deepClone(dateObject.currentValue),
      };
    }
  }

  ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }

  checkIsValid() {
    return this.isValid ?? (this.pickedDate?.dateObject ? true : null);
  }

  openCalendar() {
    this.calendarFieldComponent.openCalendar();
  }

  pickDate(date: Date) {
    date.setHours(0);
    date.setMinutes(0);
    date.setSeconds(0);
    date.setUTCMilliseconds(0);

    this.pickedDate = {
      dateString: getDateStringFromDate(date),
      dateObject: date,
    };
    this.dateChanged.emit(this.pickedDate);
  }
}
