import { FactoryProvider, inject } from '@angular/core';
import { CONFIGURATION } from '@klg/shared/tokens';
import { APPLICATION_CONFIGURATION_SERVICE } from '@klg/shared/app-config';

export const CONFIGURATION_PROVIDER: FactoryProvider = {
  provide: CONFIGURATION,
  useFactory: () => {
    const applicationConfigurationService = inject(APPLICATION_CONFIGURATION_SERVICE);
    return applicationConfigurationService.getConfiguration();
  },
};
