import { InjectionToken } from '@angular/core';
import { DurationUnit } from '@klg/shared/data-access/types';

type DurationPlurality = 'singular' | 'plural';

export const DURATION_TOKENS = new InjectionToken<Record<DurationPlurality, Record<DurationUnit, string>>>('DURATION_TOKENS', {
  providedIn: 'root',
  factory: () => ({
    singular: {
      EACH: $localize`lesson`,
      WEEKS: $localize`week`,
      DAYS: $localize`day`,
      MONTH: $localize`month`,
      YEARS: $localize`year`,
    },
    plural: {
      EACH: $localize`lessons`,
      WEEKS: $localize`weeks`,
      DAYS: $localize`days`,
      MONTH: $localize`months`,
      YEARS: $localize`years`,
    },
  }),
});
