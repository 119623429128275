import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Resolve, Route, RouterStateSnapshot } from '@angular/router';
import { BookingFormComponent } from './booking-form.component';
import { ROUTE_IDS, StepDefinition } from '@klg/quote-tool/shared/types';

const stepId = ROUTE_IDS.BOOKING_FORM;

@Injectable({
  providedIn: 'root',
})
export class BookingFormResolver implements Resolve<StepDefinition> {
  private stepDefinition: StepDefinition = {
    nextButton: $localize`Book Now`,
    nextButtonId: 'enrolment-button',
    prevButton: $localize`Back`,
    displayQuote: true,
    stepId,
    previousStep: () => ROUTE_IDS.QUOTE_FORM,
    nextStep: () => ROUTE_IDS.SEND_QUOTE_TO_EMAIL,
  };

  resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): StepDefinition {
    return this.stepDefinition;
  }
}

export const stepRoute: Route = {
  path: stepId,
  resolve: {
    stepDefinition: BookingFormResolver,
  },
  component: BookingFormComponent,
};
