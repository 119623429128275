<div #stepWrapper>
  <div class="step-wrapper__header" *ngIf="stepDefinition?.subHeader" [class.juniors-step-wrapper__header]="isJuniors | async">
    <h2 class="step-wrapper__header-title">{{ stepDefinition.subHeader.title }}</h2>
    <h6 class="step-wrapper__header-subtitle">{{ stepDefinition.subHeader.subtitle }}</h6>
  </div>
  <div class="step-wrapper" [class.juniors-step-wrapper]="isJuniors | async">
    <div class="step-wrapper__content">
      <div
        *ngIf="stepDefinition?.stepIcon"
        class="step-wrapper__icon"
        [ngStyle]="{ '-webkit-mask': 'url(' + stepDefinition.stepIcon + ')', mask: 'url(' + stepDefinition.stepIcon + ')' }"
      ></div>
      <ng-content></ng-content>
    </div>
    <footer
      class="step-wrapper__footer"
      *ngIf="(stepDefinition?.prevButton || stepDefinition?.nextButton) && !stepDefinition?.hideCtas"
      [ngClass]="{ 'step-wrapper__footer--reverse': !stepDefinition?.prevButton }"
    >
      <kng-button
        id="btn-back"
        data-qa="qt-back-button"
        *ngIf="stepDefinition?.prevButton"
        [icon]="isRtlEnabled ? 'pi pi-chevron-right' : 'pi pi-chevron-left'"
        [text]="stepDefinition.prevButton"
        [style]="{ height: '51px', width: '264px' }"
        [hasText]="true"
        [block]="true"
        [iconPos]="'before'"
        [btnRounded]="true"
        [uppercase]="true"
        [buttonStyle]="'quaternary'"
        [kngGAClickEvent]="googleAnalyticsBackEvent"
        (buttonClick)="navigateToPreviousStep()"
      >
      </kng-button>
      <kng-button
        id="btn-next"
        data-qa="qt-next-button"
        *ngIf="stepDefinition?.nextButton"
        [icon]="isRtlEnabled ? 'pi pi-chevron-left' : 'pi pi-chevron-right'"
        [text]="stepDefinition.nextButton"
        [style]="{ height: '50px', width: '264px' }"
        [hasText]="true"
        [block]="true"
        [iconPos]="'after'"
        [btnRounded]="true"
        [disabled]="!isStepValid"
        [uppercase]="true"
        [buttonStyle]="'primary'"
        (buttonClick)="navigateToNextStep()"
        [kngGAClickEvent]="googleAnalyticsNextEvent"
      >
      </kng-button>
    </footer>
  </div>
</div>
