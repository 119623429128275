/**
 * KLG Quotes API
 * This is KLG API to perform quote calculations and retrieve auxiliary pricing information.
 *
 * The version of the OpenAPI document: dev
 * Contact: xxx@kaplan.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

/**
 * the package to which the courses belong, for now adults to \'null\', juniors to \'YOUNG_LEARNER_CAMPS\'
 */
export type ProductPackageApiDto = 'EMPTY' | 'YOUNG_LEARNER_CAMPS';

export const ProductPackageApiDto = {
  EMPTY: 'EMPTY' as ProductPackageApiDto,
  YOUNG_LEARNER_CAMPS: 'YOUNG_LEARNER_CAMPS' as ProductPackageApiDto,
};
