import { RuntimeEnvironment } from '@klg/shared/types';
import { loadTranslations } from '@angular/localize';
import { DEFAULT_LOCALE } from '@klg/shared/utils';

// this special mappings are due to Weblate limitations for certain languages
const specialLocaleMappings = {
  'ar-SA': 'ar',
  'de-AT': 'de-DE',
  'en-US': 'en',
  'en-GB': 'en',
  'es-ES': 'es',
  'fr-FR': 'fr',
  'fr-BE': 'fr',
  'fr-CH': 'fr',
  'it-IT': 'it',
  'it-CH': 'it',
  'ja-JP': 'ja',
  'ko-KR': 'ko',
  'nl-NL': 'nl',
  'pl-PL': 'pl',
  'ru-RU': 'ru',
  'sv-SE': 'sv',
  'th-TH': 'th',
  'zh-TW': 'zh_Hant',
  'zh-CN': 'zh_Hans',
};

export const loadTranslationsOnBootstrap = async (environment: RuntimeEnvironment, callback: VoidFunction) => {
  const locale = specialLocaleMappings[environment.LOCALE] ?? environment.LOCALE;

  try {
    const response = await fetch(`${environment.I18N_PATH}/messages.${locale}.json`, {
      cache: 'no-store',
    });
    const { translations } = await response.json();
    loadTranslations({ ...translations });
  } catch (error) {
    console.error(error);
    // Set default language on error
    environment.LOCALE = DEFAULT_LOCALE;
  } finally {
    callback();
  }
};
