import { Component, ContentChild, ElementRef, EventEmitter, Host, Input, OnDestroy, OnInit, Output, TemplateRef } from '@angular/core';
import { BaseCard } from '@klg/shared/ui/directives';
import { Subscription } from 'rxjs';
import { observeMutation } from '@klg/shared/utils';
import { CommonModule } from '@angular/common';
import { GoogleAnalyticsClickEventDirective } from '@klg/shared/google-analytics';
import { OfferLabelComponent } from '../offer-label/offer-label.component';

@Component({
  standalone: true,
  selector: 'kng-item-card',
  templateUrl: './item-card.component.html',
  styleUrls: ['./item-card.component.scss'],
  imports: [CommonModule, GoogleAnalyticsClickEventDirective, OfferLabelComponent],
})
export class ItemCardComponent extends BaseCard<unknown> implements OnInit, OnDestroy {
  @Input() mode: 'cover' | 'back' = 'cover';
  @Input() hasPromotion = false;
  @Input() containerElement: HTMLElement;
  @Input() showMoreDetails = false;
  @Input() showImage = true;
  @Input() googleAnalyticsClickEvent: string | undefined;
  @Input() googleAnalyticsOfferLabelClickEvent: string | undefined;
  @Output() moreDetailsClicked = new EventEmitter<void>();
  @Output() showOfferPopup = new EventEmitter<unknown>();
  @ContentChild('cardCoverTpl') cardCoverTpl: TemplateRef<unknown>;
  @ContentChild('cardBackTpl') cardBackTpl: TemplateRef<unknown>;
  public imageHeight = 0;
  private subscription = new Subscription();

  constructor(@Host() private el: ElementRef) {
    super();
  }

  public ngOnInit(): void {
    const el: HTMLElement = this.containerElement ?? this.el.nativeElement.parentElement;
    const mo = observeMutation(el, { subtree: true, childList: true });
    this.subscription.add(
      mo.subscribe(() => {
        this.imageHeight = el.offsetHeight;
      }),
    );
  }

  public ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }

  public handleMoreDetailsClicked($event: MouseEvent) {
    if (!this.showMoreDetails) {
      return;
    }
    $event.stopImmediatePropagation();
    this.moreDetailsClicked.emit();
  }

  showPromotionPopup(item: unknown) {
    this.showOfferPopup.emit(item);
  }
}
