import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { SortByPipe } from '@klg/shared/ui/pipes';
import { UiModule } from '@klg/ui/components';
import { CitySelectorComponent } from './city-selector/city-selector.component';
import { DestinationCountryEnhancedSummaryComponent } from './destination-country-enhanced-summary/destination-country-enhanced-summary.component';
import { DestinationCountrySelectorComponent } from './destination-country-selector/destination-country-selector.component';
import { NationalitySelectorComponent } from './nationality-selector/nationality-selector.component';
import { ResidenceCountrySelectorComponent } from './residence-country-selector/residence-country-selector.component';
import { ResidenceRegionSelectorComponent } from './residence-region-selector/residence-region-selector.component';
import { TimezoneSelectorComponent } from './timezone-selector/timezone-selector.component';
import { DetailsPopupComponent } from '../details-popup/details-popup.component';
import { FormFieldSelectorComponent } from '../form-field-selector/form-field-selector.component';
import { ItemCardComponent } from '../item-card/item-card.component';
import { OfferLabelComponent } from '../offer-label/offer-label.component';

@NgModule({
  declarations: [
    DestinationCountrySelectorComponent,
    DestinationCountryEnhancedSummaryComponent,
    NationalitySelectorComponent,
    ResidenceCountrySelectorComponent,
    ResidenceRegionSelectorComponent,
    TimezoneSelectorComponent,
    CitySelectorComponent,
  ],
  imports: [CommonModule, UiModule, SortByPipe, DetailsPopupComponent, FormFieldSelectorComponent, ItemCardComponent, OfferLabelComponent],
  exports: [
    DestinationCountrySelectorComponent,
    DestinationCountryEnhancedSummaryComponent,
    NationalitySelectorComponent,
    ResidenceCountrySelectorComponent,
    ResidenceRegionSelectorComponent,
    TimezoneSelectorComponent,
    CitySelectorComponent,
  ],
})
export class DestinationComponentsModule {}
