<i
  *ngFor="let star of fullStars"
  class="klg-star klg-star--full"
  [class.klg-star--small]="variant === 'small'"
  [class.klg-star--normal]="variant === 'normal'"
  [class.klg-star--large]="variant === 'large'"
  style="--star-full-background: {{ color }}"
></i>
<i
  class="klg-star klg-star--half"
  [class.klg-star--small]="variant === 'small'"
  [class.klg-star--normal]="variant === 'normal'"
  [class.klg-star--large]="variant === 'large'"
  style="--star-half-gradient: {{ halfStarGradient }}"
  *ngIf="hasHalfStar"
></i>
<i
  *ngFor="let star of emptyStars"
  class="klg-star"
  [class.klg-star--small]="variant === 'small'"
  [class.klg-star--normal]="variant === 'normal'"
  [class.klg-star--large]="variant === 'large'"
></i>
