import { Component, Input, OnInit } from '@angular/core';
import { QuoteLeadUserTitleEnum, TellUsAboutYouSectionFormPayload } from '@klg/quote-tool/shared/types/quote';
import { FormFieldObject, FormType } from '@klg/quote-tool/shared/types';
import { CodeAndName } from '@klg/shared/types';
import { getDateStringFromDate, getToday } from '@klg/shared/utils';
import { emailValidator } from '@klg/core/validators/email.validator';
import { phoneValidator } from '@klg/core/validators/phone.validator';
import { StepService } from '@klg/quote-tool/shared/services/step-service';
import { ConfigurationService } from '@klg/shared/app-config';
import { EMAIL_ERROR_MESSAGE, FormSection, PHONE_ERROR_MESSAGE } from '@klg/quote-tool/shared/ui/directives';

@Component({
  selector: 'kng-tell-us-about-you-section',
  templateUrl: './tell-us-about-you.component.html',
  styleUrls: ['./tell-us-about-you.component.scss'],
})
export class TellUsAboutYouSectionComponent extends FormSection<TellUsAboutYouSectionFormPayload> implements OnInit {
  @Input() formType: FormType;

  // Flag to indicate if form have validation errors and if true, show the error message
  @Input() withValidationErrors: boolean;

  calendarMaxDate = getToday();
  titleOptions: CodeAndName[] = [
    { code: QuoteLeadUserTitleEnum.MR, name: $localize`Mr.` },
    { code: QuoteLeadUserTitleEnum.MS, name: $localize`Ms.` },
  ];
  identityDocumentFieldIsEnabled = false;

  constructor(stepService: StepService) {
    super(stepService);
  }

  ngOnInit(): void {
    this.identityDocumentFieldIsEnabled = ConfigurationService.getValue('ENROLLMENT_IDENTITY_DOCUMENT_ENABLED') as boolean;
    super.ngOnInit();
  }

  onValueChanges(key: keyof TellUsAboutYouSectionFormPayload): void {
    this.formData[key].validate();
    this.storeStudentDetails();
  }

  onSelectChanged(key: keyof TellUsAboutYouSectionFormPayload, selectedItem: string) {
    this.formData[key].value = selectedItem;
    this.onValueChanges(key);
  }

  onSelectBirthDate(date: Date): void {
    this.formData.dateOfBirth.value = getDateStringFromDate(date);
    this.onValueChanges('dateOfBirth');
  }

  updatePhoneNumber($event: string, key: keyof TellUsAboutYouSectionFormPayload) {
    this.formData.phoneNumber.value = $event ? $event : undefined;
    this.onValueChanges(key);
  }

  splitPhoneDigits(value: string, direction: number) {
    return value?.split(' ')[direction];
  }

  isEnrolmentForm(): boolean {
    return this.formType === FormType.ENROLLMENT;
  }

  isPriceRequestForm(): boolean {
    return this.formType === FormType.PRICE_REQUEST;
  }

  recalculateFormValidation(key: keyof TellUsAboutYouSectionFormPayload) {
    this.onValueChanges(key);
    Object.entries(this.formData).forEach(([_key, formField]) => {
      formField.recalculateRequired();
      if (formField.value) {
        formField.validate();
      }
    });
  }

  protected getFormFields(): { [K in keyof Partial<TellUsAboutYouSectionFormPayload>]: FormFieldObject<TellUsAboutYouSectionFormPayload[K]> } {
    const commonFields: { [K in keyof Partial<TellUsAboutYouSectionFormPayload>]: FormFieldObject<TellUsAboutYouSectionFormPayload[K]> } = {
      title: {
        value: null,
        validator: (title) => this.titleOptions?.findIndex(({ code }) => code === title) >= 0,
        errorMessage: this.defaultErrorMessage,
        required: true,
      },
      firstName: {
        value: '',
        validator: (firstName) => firstName && firstName.length <= 80,
        errorMessage: this.defaultErrorMessage,
        required: true,
      },
      lastName: {
        value: '',
        validator: (lastName) => lastName && lastName.length <= 40,
        errorMessage: this.defaultErrorMessage,
        required: true,
      },
      email: {
        value: '',
        validator: (email) => emailValidator(email),
        errorMessage: EMAIL_ERROR_MESSAGE,
        required: true,
      },
      phoneNumber: {
        value: '',
        validator: (phone) => phone && phoneValidator(phone) && this.splitPhoneDigits(phone, 1).length > 4 && /\d/.test(phone),
        errorMessage: PHONE_ERROR_MESSAGE,
        required: true,
      },
      postcode: {
        value: '',
        validator: (postcode) => postcode && postcode.length > 3 && postcode.length <= 20,
        errorMessage: this.defaultErrorMessage,
        required: true,
      },
      city: {
        value: '',
        validator: (city) => city && city.length <= 40,
        errorMessage: this.defaultErrorMessage,
        required: true,
      },
      countryOfResidence: {
        value: this.getCountryOfResidence(),
        validator: Boolean,
        errorMessage: this.defaultErrorMessage,
        required: true,
      },
    };
    const enrollmentFormsFields = {
      identityDocument: {
        value: '',
        validator: (identityDocument: string) => identityDocument && identityDocument.length > 0,
        errorMessage: this.defaultErrorMessage,
        required: this.identityDocumentFieldIsEnabled,
      },
      address: {
        value: '',
        validator: (address: string) => address && address.length < 80,
        errorMessage: this.defaultErrorMessage,
        required: true,
      },
      placeOfBirth: {
        value: '',
        validator: (birthPlace: string) => birthPlace && birthPlace.length < 80,
        errorMessage: this.defaultErrorMessage,
        required: true,
      },
      motherTongue: {
        value: '',
        validator: (motherTongue: string) => !!motherTongue,
        errorMessage: this.defaultErrorMessage,
        required: true,
      },
    };
    const dateOfBirthFormField = {
      dateOfBirth: {
        value: null,
        validator: (birthDate: string) => new Date(birthDate).getTime() < this.calendarMaxDate.getTime(),
        errorMessage: this.defaultErrorMessage,
        required: true,
      },
    };
    const nationalityFormField = {
      nationality: {
        value: '',
        validator: (nationality: string) => !!nationality,
        errorMessage: this.defaultErrorMessage,
        required: true,
      },
    };
    return {
      ...commonFields,
      ...(this.isEnrolmentForm() || this.isPriceRequestForm() ? dateOfBirthFormField : {}),
      ...(this.isEnrolmentForm() || this.isPriceRequestForm() ? nationalityFormField : {}),
      ...(this.isEnrolmentForm() ? enrollmentFormsFields : {}),
    };
  }

  protected getFieldsValues(fieldKeys: Array<keyof TellUsAboutYouSectionFormPayload>): Partial<TellUsAboutYouSectionFormPayload> {
    const values: Partial<TellUsAboutYouSectionFormPayload> = {};

    fieldKeys.forEach((key: keyof TellUsAboutYouSectionFormPayload) => {
      if (this.getFieldValueIfValid(key) !== undefined || this.getFormFields()[key].required) {
        values[key] = this.getFieldValueIfValid(key);
      }
    });

    return values;
  }

  private getCountryOfResidence(): TellUsAboutYouSectionFormPayload['countryOfResidence'] {
    return this.stepService.getResidenceData()?.countryOfResidence ?? '';
  }
}
