import { Component, inject, OnDestroy, OnInit } from '@angular/core';
import { PriceRequestCourseFormComponent } from '../course-form/price-request-course-form.component';
import { SectionTemplateComponent } from '../../../../sections/section-template/section-template.component';
import { PriceRequestStudentFormComponent } from '../student-form/price-request-student-form.component';
import { NgIf } from '@angular/common';
import { CourseQuoteInput, QuoteInput, StudentDetails } from '@klg/quote-tool/shared/types';
import { StepService } from '@klg/quote-tool/shared/services';
import { PriceRequestExperimentStore } from '../../../../../store/store/price-request-experiment.store';
import { BehaviorSubject, combineLatest, Subject, takeUntil } from 'rxjs';
import { CourseType } from '@klg/shared/data-access/types';

@Component({
  standalone: true,
  selector: 'kng-price-request-form-simplified',
  templateUrl: './price-request-form-simplified.component.html',
  styleUrls: ['./price-request-form-simplified.component.scss'],
  imports: [PriceRequestCourseFormComponent, SectionTemplateComponent, PriceRequestStudentFormComponent, NgIf],
})
export class PriceRequestFormSimplifiedComponent implements OnInit, OnDestroy {
  courseFormTitle = $localize`Program and school`;
  studentFormTitle = $localize`Tell us a bit more about you`;

  private closeSubscriptions$: Subject<void> = new Subject<void>();
  private isStepValid$ = new BehaviorSubject(false);
  private isStudentFormValid$ = new BehaviorSubject(false);

  private readonly stepService = inject(StepService);
  private readonly priceRequestStore = inject(PriceRequestExperimentStore);

  ngOnInit(): void {
    this.stepService.setStepValid(this.isStepValid$);

    combineLatest([
      this.priceRequestStore.quoteInput$,
      this.priceRequestStore.quoteCourseType$,
      this.priceRequestStore.studentDetails$,
      this.isStudentFormValid$,
    ])
      .pipe(takeUntil(this.closeSubscriptions$))
      .subscribe(([quoteInput, courseType, studentDetails, validStudentForm]: [QuoteInput, CourseType, StudentDetails, boolean]) => {
        if (!quoteInput || !courseType || !validStudentForm) {
          this.isStepValid$.next(false);
        } else {
          this.stepService.updatePartialRequest<QuoteInput>({
            ...quoteInput,
            courses: [
              <CourseQuoteInput>{
                courseType,
                weeks: quoteInput.tuitionWeeks,
                startDate: quoteInput.startDate,
              },
            ],
          });
          this.stepService.setPartialStudentDetails({ ...studentDetails });

          // Calculate the validity of the step. The step is valid if language, country, weeks and start date are selected and student form is valid
          const { languageCode, countryCode, tuitionWeeks, startDate } = quoteInput;
          this.isStepValid$.next(Boolean(languageCode && countryCode && tuitionWeeks > 0 && startDate && validStudentForm));
        }
      });
  }

  ngOnDestroy(): void {
    this.closeSubscriptions$.next();
  }

  setStudentFormValidity(isValid: boolean) {
    this.isStudentFormValid$.next(isValid);
  }
}
