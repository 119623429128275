/**
 * KLG Quotes API
 * This is KLG API to perform quote calculations and retrieve auxiliary pricing information.
 *
 * The version of the OpenAPI document: dev
 * Contact: xxx@kaplan.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
/* tslint:disable:no-unused-variable member-ordering */

import { Inject, Injectable, Optional } from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams, HttpResponse, HttpEvent, HttpParameterCodec } from '@angular/common/http';
import { CustomHttpParameterCodec } from '../encoder';
import { Observable } from 'rxjs';

import { DeeplinkRequestApiDto } from '../model/models';
import { DeeplinkResponseApiDto } from '../model/models';
import { DefaultErrorMessageApiDto } from '../model/models';
import { QuoteApiDto } from '../model/models';
import { QuoteInputApiDto } from '../model/models';

import { BASE_PATH, COLLECTION_FORMATS } from '../variables';
import { Configuration } from '../configuration';
import { QuotesControllerApiServiceInterface } from './quotes-controller.serviceInterface';

@Injectable({
  providedIn: 'root',
})
export class QuotesControllerApiService implements QuotesControllerApiServiceInterface {
  protected basePath = 'http://apiv2-dev.klgintdc.com/api';
  public defaultHeaders = new HttpHeaders();
  public configuration = new Configuration();
  public encoder: HttpParameterCodec;

  constructor(protected httpClient: HttpClient, @Optional() @Inject(BASE_PATH) basePath: string, @Optional() configuration: Configuration) {
    if (configuration) {
      this.configuration = configuration;
    }
    if (typeof this.configuration.basePath !== 'string') {
      if (typeof basePath !== 'string') {
        basePath = this.basePath;
      }
      this.configuration.basePath = basePath;
    }
    this.encoder = this.configuration.encoder || new CustomHttpParameterCodec();
  }

  private addToHttpParams(httpParams: HttpParams, value: any, key?: string): HttpParams {
    if (typeof value === 'object' && value instanceof Date === false) {
      httpParams = this.addToHttpParamsRecursive(httpParams, value);
    } else {
      httpParams = this.addToHttpParamsRecursive(httpParams, value, key);
    }
    return httpParams;
  }

  private addToHttpParamsRecursive(httpParams: HttpParams, value?: any, key?: string): HttpParams {
    if (value == null) {
      return httpParams;
    }

    if (typeof value === 'object') {
      if (Array.isArray(value)) {
        (value as any[]).forEach((elem) => (httpParams = this.addToHttpParamsRecursive(httpParams, elem, key)));
      } else if (value instanceof Date) {
        if (key != null) {
          httpParams = httpParams.append(key, (value as Date).toISOString().substr(0, 10));
        } else {
          throw Error('key may not be null if value is Date');
        }
      } else {
        Object.keys(value).forEach((k) => (httpParams = this.addToHttpParamsRecursive(httpParams, value[k], key != null ? `${key}.${k}` : k)));
      }
    } else if (key != null) {
      httpParams = httpParams.append(key, value);
    } else {
      throw Error('key may not be null if value is not object or array');
    }
    return httpParams;
  }

  /**
   * @param acceptLanguage Language code to inform the display language for the results
   * @param acceptCompany Company code for the source of data
   * @param deeplinkRequestApiDto
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
  public generateDeeplink(
    acceptLanguage: string,
    acceptCompany: 'ALP' | 'KIL' | 'ESL' | 'KSG',
    deeplinkRequestApiDto: DeeplinkRequestApiDto,
    observe?: 'body',
    reportProgress?: boolean,
    options?: { httpHeaderAccept?: 'application/json' | '*/*' },
  ): Observable<DeeplinkResponseApiDto>;
  public generateDeeplink(
    acceptLanguage: string,
    acceptCompany: 'ALP' | 'KIL' | 'ESL' | 'KSG',
    deeplinkRequestApiDto: DeeplinkRequestApiDto,
    observe?: 'response',
    reportProgress?: boolean,
    options?: { httpHeaderAccept?: 'application/json' | '*/*' },
  ): Observable<HttpResponse<DeeplinkResponseApiDto>>;
  public generateDeeplink(
    acceptLanguage: string,
    acceptCompany: 'ALP' | 'KIL' | 'ESL' | 'KSG',
    deeplinkRequestApiDto: DeeplinkRequestApiDto,
    observe?: 'events',
    reportProgress?: boolean,
    options?: { httpHeaderAccept?: 'application/json' | '*/*' },
  ): Observable<HttpEvent<DeeplinkResponseApiDto>>;
  public generateDeeplink(
    acceptLanguage: string,
    acceptCompany: 'ALP' | 'KIL' | 'ESL' | 'KSG',
    deeplinkRequestApiDto: DeeplinkRequestApiDto,
    observe: any = 'body',
    reportProgress: boolean = false,
    options?: { httpHeaderAccept?: 'application/json' | '*/*' },
  ): Observable<any> {
    if (acceptLanguage === null || acceptLanguage === undefined) {
      throw new Error('Required parameter acceptLanguage was null or undefined when calling generateDeeplink.');
    }
    if (acceptCompany === null || acceptCompany === undefined) {
      throw new Error('Required parameter acceptCompany was null or undefined when calling generateDeeplink.');
    }
    if (deeplinkRequestApiDto === null || deeplinkRequestApiDto === undefined) {
      throw new Error('Required parameter deeplinkRequestApiDto was null or undefined when calling generateDeeplink.');
    }

    let headers = this.defaultHeaders;
    if (acceptLanguage !== undefined && acceptLanguage !== null) {
      headers = headers.set('Accept-Language', String(acceptLanguage));
    }
    if (acceptCompany !== undefined && acceptCompany !== null) {
      headers = headers.set('Accept-Company', String(acceptCompany));
    }

    let httpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
    if (httpHeaderAcceptSelected === undefined) {
      // to determine the Accept header
      const httpHeaderAccepts: string[] = ['application/json', '*/*'];
      httpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
    }
    if (httpHeaderAcceptSelected !== undefined) {
      headers = headers.set('Accept', httpHeaderAcceptSelected);
    }

    // to determine the Content-Type header
    const consumes: string[] = ['application/json'];
    const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
    if (httpContentTypeSelected !== undefined) {
      headers = headers.set('Content-Type', httpContentTypeSelected);
    }

    let responseType: 'text' | 'json' = 'json';
    if (httpHeaderAcceptSelected && httpHeaderAcceptSelected.startsWith('text')) {
      responseType = 'text';
    }

    return this.httpClient.post<DeeplinkResponseApiDto>(`${this.configuration.basePath}/quotes/v1/deeplinks`, deeplinkRequestApiDto, {
      responseType: <any>responseType,
      withCredentials: this.configuration.withCredentials,
      headers: headers,
      observe: observe,
      reportProgress: reportProgress,
    });
  }

  /**
   * @param acceptLanguage Language code to inform the display language for the results
   * @param acceptCompany Company code for the source of data
   * @param quoteInputApiDto
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
  public requestQuoteFromPricingDomain(
    acceptLanguage: string,
    acceptCompany: 'ALP' | 'KIL' | 'ESL' | 'KSG',
    quoteInputApiDto: QuoteInputApiDto,
    observe?: 'body',
    reportProgress?: boolean,
    options?: { httpHeaderAccept?: '*/*' | 'application/json' },
  ): Observable<QuoteApiDto>;
  public requestQuoteFromPricingDomain(
    acceptLanguage: string,
    acceptCompany: 'ALP' | 'KIL' | 'ESL' | 'KSG',
    quoteInputApiDto: QuoteInputApiDto,
    observe?: 'response',
    reportProgress?: boolean,
    options?: { httpHeaderAccept?: '*/*' | 'application/json' },
  ): Observable<HttpResponse<QuoteApiDto>>;
  public requestQuoteFromPricingDomain(
    acceptLanguage: string,
    acceptCompany: 'ALP' | 'KIL' | 'ESL' | 'KSG',
    quoteInputApiDto: QuoteInputApiDto,
    observe?: 'events',
    reportProgress?: boolean,
    options?: { httpHeaderAccept?: '*/*' | 'application/json' },
  ): Observable<HttpEvent<QuoteApiDto>>;
  public requestQuoteFromPricingDomain(
    acceptLanguage: string,
    acceptCompany: 'ALP' | 'KIL' | 'ESL' | 'KSG',
    quoteInputApiDto: QuoteInputApiDto,
    observe: any = 'body',
    reportProgress: boolean = false,
    options?: { httpHeaderAccept?: '*/*' | 'application/json' },
  ): Observable<any> {
    if (acceptLanguage === null || acceptLanguage === undefined) {
      throw new Error('Required parameter acceptLanguage was null or undefined when calling requestQuoteFromPricingDomain.');
    }
    if (acceptCompany === null || acceptCompany === undefined) {
      throw new Error('Required parameter acceptCompany was null or undefined when calling requestQuoteFromPricingDomain.');
    }
    if (quoteInputApiDto === null || quoteInputApiDto === undefined) {
      throw new Error('Required parameter quoteInputApiDto was null or undefined when calling requestQuoteFromPricingDomain.');
    }

    let headers = this.defaultHeaders;
    if (acceptLanguage !== undefined && acceptLanguage !== null) {
      headers = headers.set('Accept-Language', String(acceptLanguage));
    }
    if (acceptCompany !== undefined && acceptCompany !== null) {
      headers = headers.set('Accept-Company', String(acceptCompany));
    }

    let httpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
    if (httpHeaderAcceptSelected === undefined) {
      // to determine the Accept header
      const httpHeaderAccepts: string[] = ['*/*', 'application/json'];
      httpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
    }
    if (httpHeaderAcceptSelected !== undefined) {
      headers = headers.set('Accept', httpHeaderAcceptSelected);
    }

    // to determine the Content-Type header
    const consumes: string[] = ['application/json'];
    const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
    if (httpContentTypeSelected !== undefined) {
      headers = headers.set('Content-Type', httpContentTypeSelected);
    }

    let responseType: 'text' | 'json' = 'json';
    if (httpHeaderAcceptSelected && httpHeaderAcceptSelected.startsWith('text')) {
      responseType = 'text';
    }

    return this.httpClient.post<QuoteApiDto>(`${this.configuration.basePath}/quotes/v2/pricing-quote`, quoteInputApiDto, {
      responseType: <any>responseType,
      withCredentials: this.configuration.withCredentials,
      headers: headers,
      observe: observe,
      reportProgress: reportProgress,
    });
  }

  /**
   * @param acceptLanguage Language code to inform the display language for the results
   * @param acceptCompany Company code for the source of data
   * @param quoteInputApiDto
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
  public requestQuoteV2(
    acceptLanguage: string,
    acceptCompany: 'ALP' | 'KIL' | 'ESL' | 'KSG',
    quoteInputApiDto: QuoteInputApiDto,
    observe?: 'body',
    reportProgress?: boolean,
    options?: { httpHeaderAccept?: 'application/json' | '*/*' },
  ): Observable<QuoteApiDto>;
  public requestQuoteV2(
    acceptLanguage: string,
    acceptCompany: 'ALP' | 'KIL' | 'ESL' | 'KSG',
    quoteInputApiDto: QuoteInputApiDto,
    observe?: 'response',
    reportProgress?: boolean,
    options?: { httpHeaderAccept?: 'application/json' | '*/*' },
  ): Observable<HttpResponse<QuoteApiDto>>;
  public requestQuoteV2(
    acceptLanguage: string,
    acceptCompany: 'ALP' | 'KIL' | 'ESL' | 'KSG',
    quoteInputApiDto: QuoteInputApiDto,
    observe?: 'events',
    reportProgress?: boolean,
    options?: { httpHeaderAccept?: 'application/json' | '*/*' },
  ): Observable<HttpEvent<QuoteApiDto>>;
  public requestQuoteV2(
    acceptLanguage: string,
    acceptCompany: 'ALP' | 'KIL' | 'ESL' | 'KSG',
    quoteInputApiDto: QuoteInputApiDto,
    observe: any = 'body',
    reportProgress: boolean = false,
    options?: { httpHeaderAccept?: 'application/json' | '*/*' },
  ): Observable<any> {
    if (acceptLanguage === null || acceptLanguage === undefined) {
      throw new Error('Required parameter acceptLanguage was null or undefined when calling requestQuoteV2.');
    }
    if (acceptCompany === null || acceptCompany === undefined) {
      throw new Error('Required parameter acceptCompany was null or undefined when calling requestQuoteV2.');
    }
    if (quoteInputApiDto === null || quoteInputApiDto === undefined) {
      throw new Error('Required parameter quoteInputApiDto was null or undefined when calling requestQuoteV2.');
    }

    let headers = this.defaultHeaders;
    if (acceptLanguage !== undefined && acceptLanguage !== null) {
      headers = headers.set('Accept-Language', String(acceptLanguage));
    }
    if (acceptCompany !== undefined && acceptCompany !== null) {
      headers = headers.set('Accept-Company', String(acceptCompany));
    }

    let httpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
    if (httpHeaderAcceptSelected === undefined) {
      // to determine the Accept header
      const httpHeaderAccepts: string[] = ['application/json', '*/*'];
      httpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
    }
    if (httpHeaderAcceptSelected !== undefined) {
      headers = headers.set('Accept', httpHeaderAcceptSelected);
    }

    // to determine the Content-Type header
    const consumes: string[] = ['application/json'];
    const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
    if (httpContentTypeSelected !== undefined) {
      headers = headers.set('Content-Type', httpContentTypeSelected);
    }

    let responseType: 'text' | 'json' = 'json';
    if (httpHeaderAcceptSelected && httpHeaderAcceptSelected.startsWith('text')) {
      responseType = 'text';
    }

    return this.httpClient.post<QuoteApiDto>(`${this.configuration.basePath}/quotes/v2/quote-prices`, quoteInputApiDto, {
      responseType: <any>responseType,
      withCredentials: this.configuration.withCredentials,
      headers: headers,
      observe: observe,
      reportProgress: reportProgress,
    });
  }
}
