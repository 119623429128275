<div class="quote-product-label">
  {{ productName }}
</div>

<ng-container *ngIf="(isProductEditable || hasProductInfo) && quoteKey">
  <kng-quote-cta-button-group
    class="quote-product-label__buttons"
    [hasProductInfo]="hasProductInfo"
    [isProductEditable]="isProductEditable"
    (infoButtonClicked)="infoButtonClicked.emit()"
    (editButtonClicked)="editButtonClicked.emit()"
    (removeButtonClicked)="removeButtonClicked.emit()"
  ></kng-quote-cta-button-group>

  <kng-quote-speed-dial-fab
    class="quote-product-label__mobile"
    [hasProductInfo]="hasProductInfo"
    [isProductEditable]="isProductEditable"
    (infoFabClicked)="infoButtonClicked.emit()"
    (editFabClicked)="editButtonClicked.emit()"
    (removeFabClicked)="removeButtonClicked.emit()"
  ></kng-quote-speed-dial-fab>
</ng-container>
