<div class="kng-drawer__drawer" [class.kng-drawer__drawer--is-active]="active">
  <div class="kng-drawer__drawer-header" (click)="clickToggle()">
    <span>{{ title }}</span>
    <kng-button
      [svg]="'remove'"
      [hasText]="false"
      [svgColor]="'light'"
      [btnRounded]="true"
      [buttonStyle]="'plain-text'"
      (buttonClick)="onClickToggleDrawer($event)"
    >
    </kng-button>
  </div>
  <div class="kng-drawer__drawer-content">
    <ng-content></ng-content>
  </div>
  <div class="kng-drawer__drawer-footer" *ngIf="showDrawerButtons">
    <kng-button [style]="{ marginInlineEnd: '5px' }" [block]="true" [btnRounded]="true" [buttonStyle]="'tertiary'" (buttonClick)="onClickReset()">
      <ng-container i18n>Reset filter</ng-container>
    </kng-button>
    <kng-button [style]="{ marginInlineStart: '5px' }" [block]="true" [btnRounded]="true" [buttonStyle]="'primary'" (buttonClick)="onClickApply()">
      <ng-container i18n>Apply</ng-container>
    </kng-button>
  </div>
</div>
