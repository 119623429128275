import { animate, state, style, transition, trigger } from '@angular/animations';

export const quoteOpenAnimation = [
  trigger('sideQuoteOpen', [
    state('open', style('*')),
    state('close', style({ transform: 'translateX(100%)' })),
    transition('void => close', animate(0)),
    transition('open => close', animate('300ms ease-in')),
    transition('close => open', animate('300ms ease-in')),
  ]),
  trigger('footerQuoteOpen', [
    state('open', style('*')),
    state('close', style({ transform: 'translateY(100%)' })),
    transition('void => close', animate(0)),
    transition('open => close', animate('50ms ease-out')),
    transition('close => open', animate('100ms ease-out')),
  ]),
];
