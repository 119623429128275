import { Component, Input, OnInit } from '@angular/core';
import { AccommodationService } from '@klg/quote-tool/shared/data-access/products';
import { AccommodationWithOptions, bathroomTypeSorter, mealOptionsSorter, roomTypeSorter } from '@klg/shared/data-access/products';
import { CommonModule } from '@angular/common';

@Component({
  standalone: true,
  selector: 'kng-accommodation-options',
  templateUrl: './accommodation-options.component.html',
  styleUrls: ['./accommodation-options.component.scss'],
  imports: [CommonModule],
})
export class AccommodationOptionsComponent implements OnInit {
  @Input() accommodationOptions: AccommodationWithOptions;
  public roomTypeLabels: string[];
  public bathRoomTypeLabels: string[];
  public mealOptionLabels: string[];

  constructor(public accommodationService: AccommodationService) {}

  ngOnInit(): void {
    this.roomTypeLabels = Array.from(this.accommodationOptions.roomTypes)
      .sort(roomTypeSorter)
      .map((roomType) => this.accommodationService.roomTypeLabels[roomType]);
    this.bathRoomTypeLabels = Array.from(this.accommodationOptions.bathroomTypes)
      .sort(bathroomTypeSorter)
      .map((roomType) => this.accommodationService.bathRoomTypeLabels[roomType]);
    this.mealOptionLabels = Array.from(this.accommodationOptions.mealOptions)
      .sort(mealOptionsSorter)
      .map((roomType) => this.accommodationService.mealOptionLabels[roomType]);
  }
}
