import deepEqual from 'fast-deep-equal';
import rfdc from 'rfdc';
const clone = rfdc();

export function deepClone<T>(obj: T): T {
  if (obj === undefined || obj === null) return obj;
  return clone(obj);
}

export function isDeepEqual<T>(obj1: T, obj2: T): boolean {
  return deepEqual(obj1, obj2);
}

export function isEmpty<T>(obj: T): boolean {
  return !obj || (Object.keys(obj).length === 0 && Object.getPrototypeOf(obj) === Object.prototype);
}
