import { Component, EventEmitter, Input, OnChanges, OnDestroy, Output, SimpleChanges } from '@angular/core';
import { ProductSchoolsService, School } from '@klg/shared/data-access/school';
import { Subscription } from 'rxjs';
import { CourseLevel } from '@klg/shared/data-access/types';

@Component({
  selector: 'kng-level-selector',
  templateUrl: './level-selector.component.html',
  styleUrls: ['./level-selector.component.scss'],
})
export class LevelSelectorComponent implements OnChanges, OnDestroy {
  @Input() disabled = false;
  @Input() value: string = undefined;
  @Input() schoolCode: string = undefined;
  @Input() codes: string[] = [];
  @Input() required = false;
  @Output() valueChange = new EventEmitter<string>();
  @Output() itemChange = new EventEmitter<CourseLevel>();

  public levelOptions: CourseLevel[] = [];

  private subscription = new Subscription();

  constructor(private productSchoolService: ProductSchoolsService) {}

  ngOnChanges({ schoolCode, codes }: SimpleChanges) {
    if (schoolCode || codes) {
      this.subscription.add(
        this.productSchoolService.get(this.schoolCode).subscribe((schoolData: School) => {
          this.levelOptions = schoolData?.availableLevels
            ?.map(({ code, name }: CourseLevel) => {
              // Uppercase the first letter on the level name (KLG-3201)
              return { code, name: name.replace(/^./, name[0].toUpperCase()) };
            })
            .filter(({ code }: CourseLevel) => !this.codes?.length || this.codes.includes(code));
        }),
      );
    }
  }

  ngOnDestroy() {
    this.subscription.unsubscribe();
  }
}
