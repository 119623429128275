import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'klgFilter',
  standalone: true,
})
export class FilterPipe implements PipeTransform {
  transform<T, O extends keyof T>(items: T[], filterItems: Array<T[O]>, key: O, typeOfOperation: 'include' | 'exclude' = 'exclude'): T[] {
    if (!items) return [];
    return items.filter((item: T) => {
      if (typeOfOperation === 'include') {
        return !!filterItems.find((i: T[O]) => i === item[key]);
      } else {
        return !filterItems.find((i: T[O]) => i === item[key]);
      }
    });
  }
}
