import currenciesMock from '../currencies.mock.json';
import { LimitedCurrencyAmountApiDto } from '@klg/shared/api/models';

export const DESTINATION_CURRENCY_KEY = 'destination';

export type CurrencyCode = string | typeof DESTINATION_CURRENCY_KEY;

export interface Currency {
  code: string;
  name: string;
  countryIso: string;
}

export const mockCurrencies: Currency[] = currenciesMock;
export type LimitedCurrencyAmount = LimitedCurrencyAmountApiDto;
