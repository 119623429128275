import { Injectable } from '@angular/core';
import { formatDate } from '@angular/common';
import { getConfiguration } from '@klg/shared/tokens';
import { Translation } from 'primeng/api';
import { DATE_FORMAT_CONFIG_MAPPING, DateFormat, DateFormatSettings } from './types';
import { getLocale } from './functions';

@Injectable({
  providedIn: 'root',
})
export class DateFormatService {
  private readonly configuration = getConfiguration();
  private readonly locale = getLocale();

  static getCalendarLocaleSettings(): Translation {
    return {
      dayNames: [$localize`Sunday`, $localize`Monday`, $localize`Tuesday`, $localize`Wednesday`, $localize`Thursday`, $localize`Friday`, $localize`Saturday`],
      dayNamesShort: [$localize`Sun`, $localize`Mon`, $localize`Tue`, $localize`Wed`, $localize`Thu`, $localize`Fri`, $localize`Sat`],
      dayNamesMin: [$localize`Su`, $localize`Mo`, $localize`Tu`, $localize`We`, $localize`Th`, $localize`Fr`, $localize`Sa`],
      monthNames: [
        $localize`January`,
        $localize`February`,
        $localize`March`,
        $localize`April`,
        $localize`May`,
        $localize`June`,
        $localize`July`,
        $localize`August`,
        $localize`September`,
        $localize`October`,
        $localize`November`,
        $localize`December`,
      ],
      monthNamesShort: [
        $localize`Jan`,
        $localize`Feb`,
        $localize`Mar`,
        $localize`Apr`,
        $localize`May`,
        $localize`Jun`,
        $localize`Jul`,
        $localize`Aug`,
        $localize`Sep`,
        $localize`Oct`,
        $localize`Nov`,
        $localize`Dec`,
      ],
      today: $localize`Today`,
      clear: $localize`Clear`,
      weekHeader: $localize`Wk`,
    };
  }

  public getDateFormatSettings(): DateFormatSettings {
    return {
      [DateFormat.SHORT_MONTH_NAME]: this.configuration[DATE_FORMAT_CONFIG_MAPPING[DateFormat.SHORT_MONTH_NAME]],
      [DateFormat.FULL_MONTH_NAME]: this.configuration[DATE_FORMAT_CONFIG_MAPPING[DateFormat.FULL_MONTH_NAME]],
      [DateFormat.NUMBERS_ONLY]: this.configuration[DATE_FORMAT_CONFIG_MAPPING[DateFormat.NUMBERS_ONLY]],
      [DateFormat.SHORT_MONTH_NAME_PRIME_NG]: this.configuration[DATE_FORMAT_CONFIG_MAPPING[DateFormat.SHORT_MONTH_NAME_PRIME_NG]],
    } as DateFormatSettings;
  }

  getDateFormat(key: DateFormat): string {
    return this.getDateFormatSettings()[key];
  }

  formatDate(value: Date | string | number | null | undefined, format: DateFormat) {
    if (!format) {
      format = DateFormat.SHORT_MONTH_NAME;
    }

    return formatDate(value, this.getDateFormatSettings()[format], this.locale);
  }
}
