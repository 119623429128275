<kng-section-template [sectionIndex]="sectionIndex" i18n-sectionTitle sectionTitle="Tell Us About You" data-qa="enrolment-tell-us">
  <kng-form-error-message *ngIf="withValidationErrors" ngProjectAs="additional-info"></kng-form-error-message>
  <ng-container *ngTemplateOutlet="isEnrolmentForm() ? enrollmentFormFields : freeQuoteFormFields"></ng-container>
</kng-section-template>

<ng-template #freeQuoteFormFields>
  <ng-container *ngTemplateOutlet="fullNameFields"></ng-container>

  <ng-container *ngTemplateOutlet="isPriceRequestForm() ? dateOfBirthField : null"></ng-container>

  <ng-container *ngTemplateOutlet="contactFields"></ng-container>

  <ng-container *ngTemplateOutlet="addressFields; context: { fullAddress: false }"></ng-container>

  <ng-container *ngTemplateOutlet="isPriceRequestForm() ? nationalityField : null"></ng-container>
</ng-template>

<ng-template #enrollmentFormFields>
  <ng-container *ngTemplateOutlet="fullNameFields"></ng-container>

  <ng-container *ngTemplateOutlet="addressFields; context: { fullAddress: true }"></ng-container>

  <ng-container *ngTemplateOutlet="contactFields"></ng-container>

  <ng-container *ngTemplateOutlet="dateOfBirthField"></ng-container>

  <kng-form-field
    class="form-field__single-border-input"
    label="Place of birth"
    i18n-label
    [small]="true"
    [isValid]="formData.placeOfBirth.isValid"
    [required]="formData.placeOfBirth.required"
    [errorMessage]="formData.placeOfBirth.errorMessage"
    [iconValidation]="true"
    data-qa="enrolment-place-of-birthday"
  >
    <input
      type="text"
      pInputText
      kngFormDataInputValidity
      [formData]="formData.placeOfBirth"
      [(ngModel)]="formData.placeOfBirth.value"
      (focusout)="recalculateFormValidation('placeOfBirth')"
    />
  </kng-form-field>

  <ng-container *ngTemplateOutlet="nationalityField"></ng-container>
  <kng-form-field
    class="enrolment-form__field-mother-tongue form-field__validated-selector-padding"
    [class.form-field__no-validation]="!formData.motherTongue.value && formData.motherTongue.isValid"
    label="Mother tongue"
    i18n-label
    [small]="true"
    [isValid]="formData.motherTongue.isValid"
    [required]="formData.motherTongue.required"
    [errorMessage]="formData.motherTongue.errorMessage"
    [iconValidation]="true"
    kngFormDataDropdownValidity
    [formData]="formData.motherTongue"
  >
    <kng-mother-tongue
      [(value)]="formData.motherTongue.value"
      (valueChange)="onValueChanges('motherTongue')"
      data-qa="enrolment-mother-language"
    ></kng-mother-tongue>
  </kng-form-field>
</ng-template>

<ng-template #fullNameFields>
  <kng-form-field
    class="form-field__validated-selector-padding"
    [class.form-field__no-validation]="!formData.title.value && formData.title.isValid"
    label="Title"
    i18n-label
    [small]="true"
    [isValid]="formData.title.isValid"
    [required]="formData.title.required"
    [errorMessage]="formData.title.errorMessage"
    [iconValidation]="true"
    kngFormDataDropdownValidity
    [formData]="formData.title"
  >
    <kng-title-selector [itemList]="titleOptions" (valueChange)="onSelectChanged('title', $event)" data-qa="enrolment-mother-language"></kng-title-selector>
  </kng-form-field>

  <kng-form-field
    label="N.I.F / N.I.E / PASAPORTE"
    i18n-label
    *ngIf="formData.identityDocument && identityDocumentFieldIsEnabled"
    [small]="true"
    [isValid]="formData.identityDocument.isValid"
    [required]="formData.identityDocument.required"
    [errorMessage]="formData.identityDocument.errorMessage"
    [iconValidation]="true"
    data-qa="qt-identity-document-field"
  >
    <input
      type="text"
      pInputText
      kngFormDataInputValidity
      [formData]="formData.identityDocument"
      [(ngModel)]="formData.identityDocument.value"
      (focusout)="recalculateFormValidation('identityDocument')"
    />
  </kng-form-field>

  <kng-form-field
    class="qt-form-section__start-new-line form-field__single-border-input"
    label="First name"
    i18n-label
    [small]="true"
    [isValid]="formData.firstName.isValid"
    [required]="formData.firstName.required"
    [errorMessage]="formData.firstName.errorMessage"
    [iconValidation]="true"
  >
    <input
      type="text"
      pInputText
      kngFormDataInputValidity
      [formData]="formData.firstName"
      [(ngModel)]="formData.firstName.value"
      (focusout)="recalculateFormValidation('firstName')"
      data-qa="qt-first-name-field"
    />
  </kng-form-field>

  <kng-form-field
    class="form-field__single-border-input"
    label="Last name"
    i18n-label
    [small]="true"
    [isValid]="formData.lastName.isValid"
    [required]="formData.lastName.required"
    [errorMessage]="formData.lastName.errorMessage"
    [iconValidation]="true"
  >
    <input
      type="text"
      pInputText
      kngFormDataInputValidity
      [formData]="formData.lastName"
      [(ngModel)]="formData.lastName.value"
      (focusout)="recalculateFormValidation('lastName')"
      data-qa="qt-last-name-field"
    />
  </kng-form-field>
</ng-template>

<ng-template #dateOfBirthField>
  <kng-date-of-birth-field
    i18n-label
    label="Date of birth"
    [isValid]="formData.dateOfBirth.isValid"
    [required]="formData.dateOfBirth.required"
    [errorMessage]="formData.dateOfBirth.errorMessage"
    (dateChange)="onSelectBirthDate($event)"
    kngFormDataInputValidity
    [formData]="formData.dateOfBirth"
    data-qa="enrolment-date-birthday-date-picker"
  ></kng-date-of-birth-field>
</ng-template>

<ng-template #contactFields>
  <kng-form-field
    class="form-field__single-border-input"
    label="Email address"
    i18n-label
    [small]="true"
    [isValid]="formData.email.isValid"
    [required]="formData.email.required"
    [errorMessage]="formData.email.errorMessage"
    [iconValidation]="true"
  >
    <input
      type="email"
      pInputText
      kngFormDataEmailValidity
      [formData]="formData.email"
      [(ngModel)]="formData.email.value"
      (focusout)="recalculateFormValidation('email')"
      data-qa="qt-email-selector"
    />
  </kng-form-field>

  <kng-phone-number-field
    class="form-field__search-bar form-field__single-border-input"
    label="Phone number"
    [isValid]="formData.phoneNumber.isValid"
    [required]="formData.phoneNumber.required"
    [errorMessage]="formData.phoneNumber.errorMessage"
    (valueChange)="updatePhoneNumber($event, 'phoneNumber')"
  ></kng-phone-number-field>
</ng-template>

<ng-template #addressFields let-fullAddress="fullAddress">
  <kng-form-field
    class="form-field__single-border-input"
    *ngIf="fullAddress"
    label="Address"
    i18n-label
    [small]="true"
    [isValid]="formData.address.isValid"
    [required]="formData.address.required"
    [errorMessage]="formData.address.errorMessage"
    [iconValidation]="true"
  >
    <input
      type="text"
      pInputText
      kngFormDataInputValidity
      [formData]="formData.address"
      [(ngModel)]="formData.address.value"
      (focusout)="recalculateFormValidation('address')"
      data-qa="qt-address-field"
    />
  </kng-form-field>

  <kng-form-field
    class="form-field__single-border-input"
    i18n-label
    label="Postcode"
    [small]="true"
    [isValid]="formData.postcode.isValid"
    [required]="formData.postcode.required"
    [errorMessage]="formData.postcode.errorMessage"
    [iconValidation]="true"
  >
    <input
      type="text"
      pInputText
      kngFormDataPostcodeValidity
      [formData]="formData.postcode"
      [(ngModel)]="formData.postcode.value"
      (focusout)="recalculateFormValidation('postcode')"
      data-qa="qt-post-code-field"
    />
  </kng-form-field>

  <kng-form-field
    class="form-field__single-border-input"
    label="City/Town"
    i18n-label
    [small]="true"
    [isValid]="formData.city.isValid"
    [required]="formData.city.required"
    [errorMessage]="formData.city.errorMessage"
    [iconValidation]="true"
  >
    <input
      type="text"
      pInputText
      kngFormDataInputValidity
      [formData]="formData.city"
      [(ngModel)]="formData.city.value"
      (focusout)="recalculateFormValidation('city')"
      data-qa="qt-city-field"
    />
  </kng-form-field>

  <kng-form-field
    class="form-field__validated-selector-padding form-field__no-border-dropdown"
    [class.form-field__no-validation]="!formData.countryOfResidence.value && formData.countryOfResidence.isValid"
    label="Country"
    i18n-label
    [small]="true"
    [isValid]="formData.countryOfResidence.isValid"
    [required]="formData.countryOfResidence.required"
    [errorMessage]="formData.countryOfResidence.errorMessage"
    [iconValidation]="true"
    kngFormDataDropdownValidity
    [formData]="formData.countryOfResidence"
  >
    <kng-residence-country-selector
      [(value)]="formData.countryOfResidence.value"
      (valueChange)="onSelectChanged('countryOfResidence', $event)"
      data-qa="qt-country-selector"
    ></kng-residence-country-selector>
  </kng-form-field>
</ng-template>

<ng-template #nationalityField>
  <kng-form-field
    class="form-field__validated-selector-padding"
    [class.form-field__no-validation]="!formData.nationality.value && formData.nationality.isValid"
    label="Nationality"
    i18n-label
    [small]="true"
    [isValid]="formData.nationality.isValid"
    [required]="formData.nationality.required"
    [errorMessage]="formData.nationality.errorMessage"
    [iconValidation]="true"
    kngFormDataDropdownValidity
    [formData]="formData.nationality"
  >
    <kng-nationality-selector
      [(value)]="formData.nationality.value"
      (valueChange)="onValueChanges('nationality')"
      data-qa="enrolment-nationality-field"
    ></kng-nationality-selector>
  </kng-form-field>
</ng-template>
