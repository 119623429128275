import { Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges } from '@angular/core';
import { Amount } from '@klg/shared/data-access/types';
import { AllowOrder, Supplement } from '@klg/shared/data-access/course';
import { courseSupplementAnimation } from '@klg/ui/animations';
import { DateFormat } from '@klg/shared/i18n';
import { getDateFromString, isDateWithinRange } from '@klg/shared/utils';
import { AvailabilityStatus } from './course-supplement-availability-status.enum';

@Component({
  selector: 'kng-course-supplement',
  templateUrl: './course-supplement.component.html',
  styleUrls: ['./course-supplement.component.scss'],
  animations: [courseSupplementAnimation],
})
export class CourseSupplementComponent implements OnInit, OnChanges {
  @Input() supplement: Supplement;
  @Input() quantity = 0;
  @Input() currency: string;
  @Input() showDescription = false;
  @Input() quoteStart: Date | undefined;
  @Input() quoteEnd: Date | undefined;
  @Output() quantityChange = new EventEmitter<number>();

  DateFormat = DateFormat;
  AvailabilityStatus = AvailabilityStatus;

  availabilityStatus: AvailabilityStatus | undefined;
  isMultiSelect: boolean | undefined;
  isSupplementSelected: boolean | undefined;
  canSelectSupplement: boolean | undefined;

  ngOnInit(): void {
    this.updateAvailabilityAndSelection();
    this.isMultiSelect = this.supplement.allowOrder !== AllowOrder.SELECTABLE;
    this.updateSupplementState();
  }

  ngOnChanges({ quoteStart, quoteEnd }: SimpleChanges) {
    if (quoteStart || quoteEnd) {
      this.updateAvailabilityAndSelection();
    }
  }

  toggleSelected() {
    if (!this.canSelectSupplement) return;
    if (this.quantity) {
      this.quantity = 0;
    } else {
      this.quantity = this.supplement.quantity > 0 ? this.supplement.quantity : 1;
    }
    this.updateSupplementState();
    this.quantityChange.emit(this.quantity);
  }

  addSupplement() {
    if (!this.canSelectSupplement) return;
    if (this.quantity < this.supplement.maxUnit) {
      const increment = this.quantity === 0 ? this.getSupplementMinUnit() : 1;
      this.quantity += increment;
      this.updateSupplementState();
      this.quantityChange.emit(this.quantity);
    }
  }

  removeSupplement() {
    if (this.quantity > 0) {
      const supplementMinUnit = this.getSupplementMinUnit();
      const decrement = this.quantity === supplementMinUnit ? supplementMinUnit : 1;
      this.quantity -= decrement;
      this.updateSupplementState();
      this.quantityChange.emit(this.quantity);
    }
  }

  displayCurrency(): string {
    return this.currency && this.supplement.price.some((price: Amount) => price.currency === this.currency) ? this.currency : this.supplement.price[0].currency;
  }

  private updateAvailabilityAndSelection(): void {
    this.availabilityStatus = this.getAvailabilityStatus();
    this.canSelectSupplement = this.isAvailableForSelection();
  }

  private getAvailabilityStatus(): AvailabilityStatus {
    if (!this.supplement?.availableDates?.length || this.isFullyAvailable()) {
      return AvailabilityStatus.FULLY_AVAILABLE;
    }
    if (this.isPartiallyAvailable()) {
      return AvailabilityStatus.PARTIALLY_AVAILABLE;
    }
    return AvailabilityStatus.NOT_AVAILABLE;
  }

  private isAvailableForSelection(): boolean {
    return this.availabilityStatus === AvailabilityStatus.FULLY_AVAILABLE || this.availabilityStatus === AvailabilityStatus.PARTIALLY_AVAILABLE;
  }

  private updateSupplementState() {
    this.isSupplementSelected = this.quantity > 0;
  }

  private getSupplementMinUnit(): number {
    return this.supplement.minUnit || 1;
  }

  private isPartiallyAvailable(): boolean {
    return (
      !this.isFullyAvailable() &&
      this.supplement?.availableDates?.some((period) => {
        const dateRange: [Date, Date] = [getDateFromString(period.from), getDateFromString(period.until)];
        return isDateWithinRange(this.quoteStart, dateRange) || isDateWithinRange(this.quoteEnd, dateRange);
      })
    );
  }

  private isFullyAvailable(): boolean {
    return this.supplement?.availableDates?.some((period) => {
      const dateRange: [Date, Date] = [getDateFromString(period.from), getDateFromString(period.until)];
      return isDateWithinRange(this.quoteStart, dateRange) && isDateWithinRange(this.quoteEnd, dateRange);
    });
  }
}
