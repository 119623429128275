<kng-form-field
  class="phone-number-field"
  label="{{ label }}"
  i18n-label
  [small]="true"
  [isValid]="isValid"
  [required]="required"
  [errorMessage]="errorMessage"
  [iconValidation]="true"
>
  <p-dropdown
    class="phone-number-field__phone-code"
    filterBy="name"
    optionValue="phoneCode"
    [style]="{ width: '100%' }"
    [filter]="true"
    [options]="countries"
    [(ngModel)]="phoneCode"
    (focusin)="dropdownFocus(true)"
    (focusout)="dropdownFocus(false)"
    (ngModelChange)="updateValidation(!dropdownValidation)"
  >
    <ng-template pTemplate="selectedItem">
      <div *ngIf="phoneCode">{{ phoneCode }}</div>
    </ng-template>
    <ng-template pTemplate="item" let-country>
      <div class="phone-number-field__phone-code__items">
        <div class="phone-number-field__phone-code__items__name">{{ country?.name }}</div>
        <div>{{ country?.phoneCode }}</div>
      </div>
    </ng-template>
  </p-dropdown>
  <input
    class="phone-number-field__number"
    type="number"
    [(ngModel)]="phoneNumber"
    (input)="updateValidation(inputValidation)"
    (focusout)="updateValidation(!inputValidation, true)"
  />
</kng-form-field>
