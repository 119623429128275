<kng-price-request-course-form class="form-field__single-border-input" [title]="courseFormTitle"></kng-price-request-course-form>
<kng-price-request-student-form
  #studentForm
  class="form-field__single-border-input"
  [title]="studentFormTitle"
  [withValidationErrors]="(studentFormValid$ | async) === false && (validationRequests$ | async) > 0"
  (validForm)="setStudentFormValidity($event)"
></kng-price-request-student-form>
<div class="price-request-form-simplified__button-container">
  <kng-button
    id="btn-send-enquiry"
    data-qa="qt-send-enquiry"
    icon="pi pi-chevron-right"
    i18n-text
    text="Send enquiry"
    [style]="{ height: '50px', width: '264px' }"
    [hasText]="true"
    [block]="true"
    iconPos="after"
    [uppercase]="true"
    [btnRounded]="true"
    [buttonStyle]="'primary'"
    [disabled]="(disabledSubmitButton$ | async) === true"
    (buttonClick)="sendEnquiry()"
  >
  </kng-button>
</div>
