<div class="course-supplement" [ngClass]="{ 'course-supplement--selected': isSupplementSelected }">
  <div class="course-supplement__actions">
    <ng-container *ngIf="isMultiSelect; else singleSelect">
      <ng-template [ngTemplateOutlet]="multiSelect"></ng-template>
    </ng-container>
  </div>
  <div class="course-supplement__name">
    <span>{{ supplement.name }}</span>
    <ng-container *ngIf="showDescription && supplement.description">
      <br /><span class="course-supplement__name-description">{{ supplement.description }}</span>
    </ng-container>
    <ng-container *ngIf="supplement?.availableDates?.length">
      <br />
      <span class="course-supplement__name-dates">
        <ng-container *ngFor="let availableDate of supplement.availableDates; let first = first; let last = last">
          {{ first ? '(' : '' }}{{ availableDate.from | klgDate : DateFormat.NUMBERS_ONLY }} - {{ availableDate.until | klgDate : DateFormat.NUMBERS_ONLY
          }}{{ !last ? ', ' : ')' }}
        </ng-container>
      </span>
      <br />
      <ng-container [ngSwitch]="availabilityStatus">
        <span i18n class="course-supplement__name-dates-warning" *ngSwitchCase="AvailabilityStatus.NOT_AVAILABLE">Not available during your stay</span>
        <span i18n class="course-supplement__name-dates-warning" *ngSwitchCase="AvailabilityStatus.PARTIALLY_AVAILABLE"
          >Partially available during your stay</span
        >
      </ng-container>
    </ng-container>
  </div>
  <div i18n *ngIf="supplement?.priceOnRequest" class="course-supplement__on_request">On request</div>
  <div *ngIf="!supplement?.priceOnRequest" class="course-supplement__info">
    <ng-container *ngIf="supplement?.optionals?.length; else showPrice">
      <div *ngFor="let optional of supplement.optionals" class="course-supplement__price">
        {{ optional.durationUnit | optionalPriceDuration : optional.minQuantity : optional.maxQuantity }} {{ optional.label }}
        {{ optional.price | klgPrice : { currency: displayCurrency() } }}
      </div>
    </ng-container>
    <ng-template #showPrice>
      <div *ngIf="supplement?.price" class="course-supplement__price">
        {{ supplement.price | klgPrice : { currency: displayCurrency() } }}
      </div>
    </ng-template>
  </div>
</div>

<ng-template #singleSelect>
  <div class="course-supplement__single-select">
    <span
      class="course-supplement__action-icon"
      [ngClass]="{ 'course-supplement__action-icon--disabled': !canSelectSupplement }"
      (click)="toggleSelected()"
      [@addOrRemove]="isSupplementSelected ? 'remove' : 'add'"
    >
      <i class="pi pi pi-plus course-supplement__action-icon--style"></i>
    </span>
  </div>
</ng-template>

<ng-template #multiSelect>
  <div class="course-supplement__multi-select">
    <ng-container *ngIf="isSupplementSelected">
      <div class="course-supplement__action-icon" (click)="removeSupplement()" [@showOrHide]="isSupplementSelected ? 'show' : 'hide'">
        <i class="pi pi-minus course-supplement__action-icon--style"></i>
      </div>
      <div class="course-supplement__quantity" [@showOrHide]="isSupplementSelected ? 'show' : 'hide'">
        {{ quantity }}
      </div>
    </ng-container>

    <div
      class="course-supplement__action-icon"
      (click)="addSupplement()"
      [ngClass]="{ 'course-supplement__action-icon--disabled': !canSelectSupplement || quantity >= supplement.maxUnit }"
    >
      <i class="pi pi pi-plus course-supplement__action-icon--style"></i>
    </div>
  </div>
</ng-template>
