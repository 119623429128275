import { Injectable } from '@angular/core';
import { getAppName, getCompanyHeader } from '@klg/shared/tokens';
import { GoogleAnalyticsErrorEventType } from '../types/google-analytics-error-event.type';

declare const window: Window & { dataLayer: GoogleAnalyticsErrorEventType[] };

/**
 * Service to track errors in Google Analytics
 */
@Injectable({
  providedIn: 'root',
})
export class GoogleAnalyticsErrorsService {
  private readonly company = getCompanyHeader();
  private readonly appName = getAppName();

  constructor() {
    window.dataLayer = window.dataLayer || [];
  }

  trackEvent(errorMessage: string) {
    const event = {
      event: 'error',
      company: this.company,
      appName: this.appName,
      browserAgent: window.navigator.userAgent,
      url: window.location.href,
      message: errorMessage,
    };
    window.dataLayer.push(event);
  }
}
