import { Component, inject, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { QuoteInput, StudentDetails } from '@klg/quote-tool/shared/types/quote';
import { FormType } from '@klg/quote-tool/shared/types';
import { SchoolTypes } from '@klg/shared/data-access/types';
import { BehaviorSubject, combineLatest, Observable, Subject, takeUntil } from 'rxjs';
import { debounceTime, switchMap, tap } from 'rxjs/operators';
import { StepService } from '@klg/quote-tool/shared/services/step-service';
import { tellUsAboutYouSectionIndex } from '../../../shared/functions/section-index.functions';
import { isStepBookingFormValid } from '../../../shared/functions/step-validation.function';
import { TellUsAboutYouSectionComponent } from '../../sections/tell-us-about-you/tell-us-about-you.component';
import { ContactPersonSectionComponent } from '../../sections/contact-person/contact-person.component';
import { FormStepDefinition } from '../../../shared/types/form.step.definition.interface';
import { QuoteToolFormStore } from '../../../shared/store/quote-tool.form.store';
import { TermsAndPromotionsFormSectionComponent } from '../../sections/terms-and-promotions/terms-and-promotions.component';

@Component({
  selector: 'kng-booking-form-step',
  templateUrl: './booking-form.component.html',
  styleUrls: ['./booking-form.component.scss'],
})
export class BookingFormComponent implements OnInit, OnDestroy {
  @ViewChild('tellUsAboutYouForm') tellUsAboutYouForm: TellUsAboutYouSectionComponent | undefined;
  @ViewChild('contactPersonForm') contactPersonForm: ContactPersonSectionComponent | undefined;
  @ViewChild('termsAndPromotionsForm') termsAndPromotionsForm: TermsAndPromotionsFormSectionComponent | undefined;

  schoolType: SchoolTypes | undefined;
  formType: FormType | undefined;

  tellUsAboutYouSectionIndex: number;

  // Observable to know if the form has validation errors
  withValidationErrors$: Observable<boolean> | undefined;

  private stepValid$ = new BehaviorSubject(false);
  private submittableForm$: Subject<boolean> | undefined;

  private readonly destroy$ = new Subject<void>();

  private readonly stepService = inject(StepService);
  private readonly quoteToolFormStore = inject(QuoteToolFormStore);

  ngOnInit(): void {
    this.stepService.setStepValid(this.stepValid$);
    this.formType = this.stepService.getFormType();

    // The form will be invalid if the next button is disabled. In the end,
    // same rule is applied to disable the submit button and showing the error
    // message to the user.
    this.withValidationErrors$ = this.quoteToolFormStore.disabledNextButton$;

    combineLatest([this.stepService.quoteRequest$, this.stepService.studentDetails$])
      .pipe(
        debounceTime(160),
        tap(([quoteInput]) => {
          this.schoolType = quoteInput.schoolType;
          this.tellUsAboutYouSectionIndex = tellUsAboutYouSectionIndex(this.formType, quoteInput.hasSupplementsAvailable);
        }),
        takeUntil(this.destroy$),
      )
      .subscribe(([quoteInput, studentDetails]: [QuoteInput, StudentDetails]) => {
        this.stepValid$.next(isStepBookingFormValid(quoteInput, studentDetails));
      });

    // Take into account the number of validations done to the form and
    // if the step is valid or not to disable the next button
    combineLatest([this.quoteToolFormStore.validationRequests$, this.stepValid$])
      .pipe(takeUntil(this.destroy$))
      .subscribe(([validationRequests, stepValid]) => {
        this.quoteToolFormStore.updateDisabledNextButton(!stepValid && validationRequests > 0);
      });

    // Take from current step the observable requestValidation$ to subscribe to it. On
    // that way, any time the event is emitted, the formValidation event will be emitted too.
    // Also, take the submittableForm$ to notify when the form is submittable.
    this.stepService.currentStepDefinition$
      .pipe(
        tap(({ submittableForm$ }: FormStepDefinition) => {
          this.submittableForm$ = submittableForm$;
        }),
        switchMap(({ requestValidation$ }: FormStepDefinition) => requestValidation$ || new Subject<void>()),
        takeUntil(this.destroy$),
      )
      .subscribe(() => this.validateForms());
  }

  ngOnDestroy(): void {
    this.destroy$.next();
  }

  /**
   * It validates each field of student, contact person and terms and promotions forms and emits the submittableForm$ event depending on
   * if the step is valid or not.
   */
  private validateForms() {
    [this.tellUsAboutYouForm?.formData, this.contactPersonForm?.formData, this.termsAndPromotionsForm?.formData].forEach((formData) => {
      // Null safe, in case the form is not rendered yet.
      if (!formData) {
        return;
      }

      Object.entries(formData).forEach(([, formField]) => {
        formField.recalculateRequired();
        formField.validate();
      });
    });
    this.submittableForm$.next(this.stepValid$.value);
  }
}
