import { Component, EventEmitter, Input, OnChanges, OnDestroy, OnInit, Output, SimpleChanges } from '@angular/core';
import { PromotionSummary } from '@klg/shared/data-access/promotion';
import { deepClone } from '@klg/shared/utils';
import { Subscription } from 'rxjs';
import { Country } from '@klg/shared/data-access/types';
import { SortingCriteria } from '@klg/shared/types';
import { GoogleAnalyticsEvents } from '@klg/shared/google-analytics';

@Component({
  selector: 'kng-destination-country-selector',
  templateUrl: './destination-country-selector.component.html',
  styleUrls: ['./destination-country-selector.component.scss'],
})
export class DestinationCountrySelectorComponent implements OnInit, OnChanges, OnDestroy {
  @Input() label: string;
  @Input() disabled = false;
  @Input() value: string = undefined;
  @Input() isJuniors = false;
  @Input() countries: Country[];
  @Input() codes: string[] = [];
  @Input() buttonView = false;
  @Input() autofill = false;
  @Input() placeholder: string;
  @Input() isOpen = false;
  @Input() required = false;
  @Input() promotionSummaries: PromotionSummary[] = [];
  @Input() sortCriteria: SortingCriteria<Country> = [{ property: 'weight' }, { property: 'name' }];
  @Output() valueChange = new EventEmitter<string>();
  @Output() itemChange = new EventEmitter<Country>();
  @Output() isOpenChange = new EventEmitter<boolean>();
  @Output() showPromoModal = new EventEmitter<PromotionSummary[]>();

  itemList: Country[] = [];
  popupCountry: Country;
  selectedValue: string;

  readonly GoogleAnalyticsEvents = GoogleAnalyticsEvents;

  private subscription: Subscription;

  ngOnInit() {
    this.getItemList();
  }

  ngOnChanges({ value, codes, countries, promotionSummaries }: SimpleChanges): void {
    if (value && value.currentValue) {
      this.selectedValue = this.value;
    }

    if ((codes && codes.currentValue !== codes.previousValue) || (countries && countries.currentValue !== countries.previousValue)) {
      this.getItemList();
    }

    if (promotionSummaries && promotionSummaries.currentValue) {
      this.updateItemList();
    }
  }

  showOfferPopup(code: string) {
    const promotions = this.promotionSummaries?.filter((item: PromotionSummary) => item.groupingKey === code);
    this.showPromoModal.emit(promotions);
  }

  onDetailsPopupCtaClicked() {
    this.value = this.popupCountry.code;
    this.itemChange.emit(this.popupCountry);
    this.valueChange.emit(this.popupCountry.code);
    this.popupCountry = null;
  }

  ngOnDestroy() {
    this.subscription?.unsubscribe();
  }

  private getItemList() {
    // Unsubscribe from previous, subscription and create a new one.
    // If we don't create a new one, it will unsubscribe from the subscription added next
    this.subscription?.unsubscribe();
    this.subscription = new Subscription();

    if (this.countries) {
      this.itemList = this.countries.map(deepClone);
    }
  }

  private updateItemList() {
    this.itemList?.map((country: Country) => (country.hasPromotion = this.hasPromotion(country)));
  }

  private hasPromotion(country: Country): boolean {
    return this.promotionSummaries?.some((promotionSummary: PromotionSummary) => promotionSummary.groupingKey === country.code);
  }
}
