import { inject, Pipe, PipeTransform } from '@angular/core';
import { DateFormat, DateFormatService } from '@klg/shared/i18n';

@Pipe({
  standalone: true,
  name: 'klgDate',
})
export class DatePipe implements PipeTransform {
  private readonly dateFormatService = inject(DateFormatService);

  transform(value: Date | string | number | null | undefined, format: DateFormat): string {
    return this.dateFormatService.formatDate(value, format);
  }
}
