import { Injectable, Injector, Type } from '@angular/core';
import { createCustomElement } from '@angular/elements';
import { WebComponentMap } from '../types/web-component-map.type';

@Injectable({ providedIn: 'root' })
export class WebComponentService {
  constructor(private injector: Injector) {}
  public registerComponents(wcMap: WebComponentMap) {
    Object.entries(wcMap).forEach(([componentName, Component]) => this.registerComponent(componentName, Component));
  }
  public registerComponent(componentName: string, Component: Type<unknown>) {
    // Convert a component to a custom element registered by a name.
    const customElement = createCustomElement(Component, {
      injector: this.injector,
    });
    // Register the custom element with the browser.
    try {
      customElements.define(componentName, customElement);
    } catch (e) {
      console.warn(e.message);
    }
  }
}
