import { Inject, Pipe, PipeTransform } from '@angular/core';
import { QuoteOutput } from '@klg/quote-tool/shared/types';
import { AgeGroup, AgeGroups } from '@klg/quote-tool/shared/types';
import { CourseTypeCodesEnum } from '@klg/shared/data-access/types';
import { CONFIGURATION } from '@klg/shared/tokens';
import { Configuration } from '@klg/shared/types';
import { valueIsNullOrUndefined } from '@klg/shared/utils';

@Pipe({
  name: 'showEnrolmentButton',
  standalone: true,
})
export class ShowEnrolmentButtonPipe implements PipeTransform {
  constructor(@Inject(CONFIGURATION) private readonly configuration: Configuration) {}

  transform(quote: QuoteOutput, ageGroup: AgeGroup): boolean {
    return this.showEnrolmentButton(quote?.input?.courseType, ageGroup);
  }

  private showEnrolmentButton(courseType: string, ageGroup: AgeGroup): boolean {
    // The `LIVE_PRACTICE_PAYMENTS_ONLY` flag is currently only defined for `fr-FR` and `ja-JP`
    if (!(AgeGroups.Online === ageGroup) || valueIsNullOrUndefined(this.configuration.LIVE_PRACTICE_PAYMENTS_ONLY)) {
      return true;
    }
    return courseType === CourseTypeCodesEnum.ONLINE_LIVE_PRACTICE && (this.configuration.LIVE_PRACTICE_PAYMENTS_ONLY as boolean);
  }
}
