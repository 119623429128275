<kng-form-field-selector
  class="camp-selector"
  [label]="label"
  [disabled]="disabled"
  [value]="value"
  valueExpr="code"
  [itemList]="itemList"
  [sortCriteria]="[{ property: 'name' }]"
  [buttonView]="buttonView"
  [placeholder]="placeholder"
  [hideHelpMeChoose]="true"
  [isOpen]="isOpen"
  (valueChange)="valueChange.emit($any($event))"
  (isOpenChange)="isOpenChange.emit($event)"
>
  <ng-template #optionItemTemplate let-camp let-showDetails="showDetails" let-isOpen="isOpen">
    <kng-item-card
      class="camp-selector__item"
      [mode]="showDetails ? 'back' : 'cover'"
      [item]="camp"
      [showMoreDetails]="isOpen"
      (moreDetailsClicked)="popupCamp = camp"
      titleExpression="name"
      imageExpression="thumbnailSmall"
      [googleAnalyticsClickEvent]="GoogleAnalyticsEvents.QUOTE_THUMBNAIL_COURSE_NAME"
      [googleAnalyticsOfferLabelClickEvent]="GoogleAnalyticsEvents.QUOTE_PROMOTION_CAMP"
    >
      <div class="camp-selector__age-range" i18n>Ages {{ camp.ageMin }}-{{ camp.ageMax }}</div>
    </kng-item-card>
  </ng-template>
</kng-form-field-selector>

<kng-details-popup
  *ngIf="popupCamp"
  [images]="popupCamp?.galleryUrls"
  [isJuniors]="true"
  (ctaClicked)="value = popupCamp?.code; valueChange.emit(popupCamp?.code); popupCamp = null"
  (popupClosed)="popupCamp = null"
  [googleAnalyticsClickEvent]="GoogleAnalyticsEvents.QUOTE_HIGHLIGHT_COURSE_NAME"
>
  <h6 class="camp-selector__popup-title">{{ popupCamp?.name }}</h6>
  <div class="camp-selector__popup-age" i18n>Age range:<br />{{ popupCamp?.ageMin }}-{{ popupCamp?.ageMax }}</div>
  <div class="camp-selector__popup-description">{{ popupCamp?.description }}</div>
</kng-details-popup>
