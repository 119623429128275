<kng-form-field-selector
  [small]="small"
  [label]="label"
  [value]="value"
  valueExpr="code"
  [disabled]="disabled"
  [required]="required"
  [itemList]="regions | klgSortBy : [{ property: 'weight' }]"
  [buttonView]="false"
  [placeholder]="placeholder"
  [iconValidation]="iconValidation"
  (valueChange)="dirty = true; valueChange.emit($event)"
></kng-form-field-selector>
