<p-dialog
  class="quote-tool__popup quote-popup__popup"
  [rtl]="true"
  [modal]="true"
  [style]="{ height: '100%', width: '100%', maxWidth: '557px', 'min-height': (isMobile$ | async) === true ? '100%' : '800px' }"
  [closable]="true"
  [(visible)]="isOpen"
  [draggable]="false"
  [showHeader]="true"
  [closeOnEscape]="true"
  [dismissableMask]="true"
  (onHide)="closePopupCb()"
>
  <div class="quote-popup__popup-wrapper">
    <div i18n class="quote-popup__popup-title">Your quote breakdown</div>
    <div class="quote-popup__quote-wrapper">
      <div class="quote-popup__quote-header" i18n>Your Quote</div>
      <kng-quote
        class="quote-popup__quote"
        [class.hide-discounts]="hideDiscounts"
        [isPackage]="isJuniors"
        [quote]="quote"
        [selectedCurrency]="currency"
        [courseName]="courseName"
        [languageName]="languageName"
        [schoolName]="schoolName"
        [isProductsAccordionExpanded]="areProductAccordionsExpanded"
        [isDiscountsAccordionExpanded]="areDiscountAccordionsExpanded"
        [showCta]="false"
        [hasEditButton]="false"
        [hasRemoveButton]="false"
        (expandProductAccordions)="areProductAccordionsExpanded = !areProductAccordionsExpanded"
        (expandDiscountAccordions)="areDiscountAccordionsExpanded = !areDiscountAccordionsExpanded"
        [depositsIsEnabled]="depositsIsEnabled"
        [displayEnrolmentButton]="displayEnrolmentButton"
        [isOnlineFlow]="isOnlineFlow"
      ></kng-quote>
    </div>
    <kng-button
      class="quote-popup__cta"
      text="Continue"
      i18n-text
      [uppercase]="true"
      (buttonClick)="closePopup.emit()"
      [block]="true"
      [btnRounded]="true"
    ></kng-button>
  </div>
</p-dialog>
