import { Component, EventEmitter, Input, OnChanges, OnDestroy, OnInit, Output, SimpleChanges } from '@angular/core';
import { deepClone } from '@klg/shared/utils';
import { SortingCriteria } from '@klg/shared/types';
import { Nationality, NationalityService } from '@klg/shared/data-access/destination';
import { Subscription } from 'rxjs';

@Component({
  selector: 'kng-nationality-selector',
  templateUrl: './nationality-selector.component.html',
  styleUrls: ['./nationality-selector.component.scss'],
})
export class NationalitySelectorComponent implements OnInit, OnDestroy, OnChanges {
  @Input() small = false;
  @Input() label: string;
  @Input() disabled = false;
  @Input() isValid: boolean | null = null;
  @Input() placeholder: string;
  @Input() value: string = undefined;
  @Input() sortCriteria: SortingCriteria<Nationality>;
  @Input() required = false;
  @Output() valueChange = new EventEmitter<string>();

  private subscription = new Subscription();

  public nationalities: Nationality[] = [];
  public selectedValue: string;
  public dirty = false;

  constructor(private nationalityService: NationalityService) {}

  private getItemList() {
    this.subscription.add(
      this.nationalityService.getAll().subscribe((data) => {
        this.nationalities = data.map(deepClone);
      }),
    );
  }

  ngOnInit() {
    this.getItemList();
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.value?.currentValue) {
      this.selectedValue = changes.value.currentValue;
    }
  }

  ngOnDestroy() {
    this.subscription.unsubscribe();
  }
}
