export enum ROUTE_IDS {
  // WIZARD
  RESIDENCE_COUNTRY = 'country',
  AGE = 'age',

  JUNIOR_BASE = 'junior-base',
  JUNIOR_COURSE = 'junior-course',
  JUNIOR_EXTRAS = 'junior-extras',

  ADULT_BASE = 'adult-base',
  ADULT_COURSE = 'adult-course',
  ADULT_EXTRAS = 'adult-extras',
  QUOTE = 'quote',
  ENROLMENT = 'enrolment',
  ENROLMENT_PAYMENT = 'payment',
  ENROLMENT_PAYMENT_CONFIRMATION = 'payment-confirmation',
  REGISTRATION = 'registration',
  DELIVERY = 'delivery',

  ONLINE_BASE = 'online-base',

  // FORM
  FORM_ROOT = '',
  QUOTE_FORM = 'quote-form',
  BOOKING_FORM = 'booking-form',
  SEND_QUOTE_TO_EMAIL = 'email-quote',
  BOOKING_ENROLMENT_PAYMENT = 'payment',
  THANK_YOU = 'thank-you',

  QUOTE_ERROR = 'quote-error',
}
