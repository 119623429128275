import { Injectable } from '@angular/core';
import { City, CityExtended } from '@klg/shared/data-access/types';

@Injectable({
  providedIn: 'root',
})
export class ExtendCityService {
  public atTheTeachersText: string = $localize`At the teacher's`;

  public extendCity(city: City): CityExtended {
    if (!city) {
      return null;
    }
    const isAtTheTeachers = city.name === `At the teacher's`;
    return {
      ...city,
      isAtTheTeachers,
      name: isAtTheTeachers ? this.atTheTeachersText?.toString() : city.name,
    };
  }
}
