import { Component, EventEmitter, inject, Input, OnChanges, OnDestroy, OnInit, Output, SimpleChanges } from '@angular/core';
import { SortingCriteria } from '@klg/shared/types';
import { PromotionSummary } from '@klg/shared/data-access/promotion';
import { QuoteSchoolService } from '@klg/quote-tool/shared/data-access/school';
import { deepClone } from '@klg/shared/utils';
import { Subscription } from 'rxjs';
import { QuoteSchool } from '@klg/quote-tool/shared/types';
import { GoogleAnalyticsEvents } from '@klg/shared/google-analytics';
import { QuoteToolStore } from '@klg/quote-tool/shared/store';

@Component({
  selector: 'kng-school-selector',
  templateUrl: './school-selector.component.html',
  styleUrls: ['./school-selector.component.scss'],
})
export class SchoolSelectorComponent implements OnInit, OnDestroy, OnChanges {
  @Input() label: string;
  @Input() value: string;
  @Input() disabled = false;
  @Input() autofill = false;
  @Input() country: string;
  @Input() city: string;
  @Input() language: string;
  @Input() buttonView = false;
  @Input() placeholder: string;
  @Input() isOpen = false;
  @Input() customDisabled = false;
  @Input() withFilterFn?: (q: QuoteSchool) => boolean;
  @Input() firstItem: QuoteSchool = null;
  @Input() required = false;
  @Input() promotionSummaries: PromotionSummary[] = [];
  @Input() sortCriteria: SortingCriteria<QuoteSchool> = [{ property: 'weight' }, { property: 'name' }];
  @Input() showOnlineSchools = false;
  @Output() valueChange = new EventEmitter<string>();
  @Output() isOpenChange = new EventEmitter<boolean>();
  @Output() itemChange = new EventEmitter<QuoteSchool>();
  @Output() showPromoModal = new EventEmitter<PromotionSummary[]>();

  popupSchool: QuoteSchool = null;
  itemList: QuoteSchool[] = null;
  selectedValue: string;

  readonly GoogleAnalyticsEvents = GoogleAnalyticsEvents;

  private subscription: Subscription;

  private readonly schoolService = inject(QuoteSchoolService);
  private readonly quoteToolStore = inject(QuoteToolStore);

  school = (item: QuoteSchool) => item;

  ngOnInit() {
    this.getItemList();
  }

  ngOnChanges({ country, city, language, value, promotionSummaries }: SimpleChanges): void {
    if (country || city || language) {
      this.getItemList();
    }

    if (value && value.currentValue) {
      this.selectedValue = this.value;
    }

    if (promotionSummaries && promotionSummaries.currentValue) {
      this.updateItemList();
    }
  }

  ngOnDestroy() {
    this.subscription?.unsubscribe();
  }

  emitItemChange($event: QuoteSchool) {
    this.itemChange.emit($event as QuoteSchool);
  }

  showOfferPopup(code: string) {
    const promotions = this.promotionSummaries?.filter((item: PromotionSummary) => item.groupingKey === code);
    this.showPromoModal.emit(promotions);
  }

  onDetailsPopupCtaClicked() {
    this.value = this.popupSchool.code;
    this.emitItemChange(this.popupSchool);
    this.valueChange.emit(this.popupSchool.code);
    this.popupSchool = null;
  }

  private getItemList() {
    // Unsubscribe from previous, subscription and create a new one.
    // If we don't create a new one, it will unsubscribe from the subscription added next
    this.subscription?.unsubscribe();
    this.subscription = new Subscription();

    this.subscription.add(
      this.getAction().subscribe((data: QuoteSchool[]) => {
        const minWeight = 0;
        this.itemList = [
          ...(this.firstItem ? [{ ...this.firstItem, weight: minWeight, hasTeachersHome: false, isOnline: false } as QuoteSchool] : []),
          ...data.map((item: QuoteSchool): QuoteSchool => ({ ...deepClone(item), weight: minWeight + 1 })),
        ];
        if (!this.showOnlineSchools) {
          this.itemList = this.itemList.filter((school) => !school.isOnline);
        }
        if (!this.customDisabled) {
          this.disabled = !this.itemList.length;
        }
        this.updateItemList();

        // Store in quote tool store the list of schools
        this.quoteToolStore.addSchools(this.itemList);
      }),
    );
  }

  private getAction() {
    if (this.withFilterFn) {
      return this.schoolService.getAllWithFilterFn(this.withFilterFn);
    } else {
      return this.schoolService.getAllByCountryAndLanguage(this.country, this.language);
    }
  }

  private updateItemList() {
    this.itemList?.map((school: QuoteSchool) => (school.hasPromotion = this.hasPromotion(school)));
  }

  private hasPromotion(school: QuoteSchool): boolean {
    return this.promotionSummaries?.some((promotionSummary: PromotionSummary) => promotionSummary.groupingKey === school.code);
  }
}
