import { Component, Input } from '@angular/core';
import { Vacation } from '@klg/quote-tool/shared/types';

@Component({
  selector: 'kng-holiday-closures',
  templateUrl: './holiday-closures.component.html',
  styleUrls: ['./holiday-closures.component.scss'],
})
export class HolidayClosuresComponent {
  @Input() holidayClosuresDates: Vacation[] = [];
}
