import { Routes } from '@angular/router';
import { QuoteToolModes, quoteToolPaths } from '@klg/quote-tool/shared/routing';

export const quoteToolRoutes: Routes = [
  {
    title: 'Online Enrolment: Book your language course abroad | ESL',
    path: quoteToolPaths.get(QuoteToolModes.quickQuoteTool),
    loadChildren: () => import('@klg/quote-tool/form').then((m) => m.QuoteToolFormModule),
  },
];
