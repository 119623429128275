import { Component, EventEmitter, inject, Input, OnChanges, OnDestroy, OnInit, Output, SimpleChanges } from '@angular/core';
import { Subscription } from 'rxjs';
import { getQuoteKeyPoints, getQuoteSummaryFees, getQuoteSummaryRestProducts, isAccommodationMandatory } from '@klg/quote-tool/shared/data-access/quote';
import { AgeGroups, CourseQuoteInput, ProductType, QuoteOutput, QuoteProduct, ROUTE_IDS, TransferTypeEnum } from '@klg/quote-tool/shared/types';
import { take } from 'rxjs/operators';
import { accordionAnimationData } from '@klg/ui/animations';
import { addWeeks, deepClone, getDateFromString, getDateStringFromDate } from '@klg/shared/utils';
import { KeyPointIcons } from '@klg/shared/models';
import { DESTINATION_CURRENCY_KEY } from '@klg/currency/shared/model/currency.model';
import { DateFormat } from '@klg/shared/i18n';
import { ProductSchoolsService, School } from '@klg/shared/data-access/school';
import { KeyPoints } from '@klg/shared/data-access/course';
import { StepService } from '@klg/quote-tool/shared/services/step-service';
import { QuoteToolStore } from '@klg/quote-tool/shared/store';
import { GoogleAnalyticsEvents } from '@klg/shared/google-analytics';
import { COMPANIES, ModifyQuoteType } from '@klg/shared/types';
import { getCompany } from '@klg/shared/tokens';

@Component({
  selector: 'kng-quote',
  templateUrl: './quote.component.html',
  styleUrls: ['./quote.component.scss'],
  animations: [accordionAnimationData],
})
export class QuoteComponent implements OnInit, OnChanges, OnDestroy {
  @Input() quote: QuoteOutput;
  @Input() quoteKey = '';
  @Input() activeQuoteSlot = 1;
  @Input() selectedCurrency = 'USD';
  @Input() isProductsAccordionExpanded = false;
  @Input() isDiscountsAccordionExpanded = false;
  @Input() isQuoteSlotExpanded = false;
  @Input() otherProductsStack: number;
  @Input() discountsStack: number;
  @Input() schoolName: string;
  @Input() destination: string;
  @Input() languageName: string;
  @Input() courseName: string;
  @Input() isPackage = false;
  @Input() languageLabel = $localize`Language`;
  @Input() schoolLabel = $localize`Your School`;
  @Input() courseDurationLabel = $localize`Course Duration`;
  @Input() ctaButtonHeaderText = $localize`Email me my quotes`;
  @Input() ctaButtonSubheaderText = $localize`Check the availability with an advisor`;
  @Input() hasEditButton = true;
  @Input() hasRemoveButton = true;
  @Input() hasCurrencySelector = false;
  @Input() displayDestinationCurrency = false;
  @Input() filterVisibleCurrencies = false;
  @Input() disabledCta = false;
  @Input() displayNoInsurance = true;
  @Input() displayNoVirtualInternship = true;
  @Input() displayNoPremiumResidence = true;
  @Input() displayNoTransfer = true;
  @Input() displayNoAccommodation = true;
  @Input() confirmationLinkUrl = 'https://www.esl-languages.com/free-quote/confirmation';
  @Input() hasDisclaimerSection = false;
  @Input() confirmationDisclaimer = '';
  @Input() hideDecimals = true;
  @Input() depositsIsEnabled = false;
  @Input() showCta = true;
  @Input() roundedElements = false;
  @Input() displayEnrolmentButton = true;
  @Input() totalClass: string;
  @Input() isOnlineFlow = false;
  @Input() quoteOutputsExpirationDates = [];
  @Output() submitQuote = new EventEmitter<void>();
  @Output() openPopup = new EventEmitter<{ productType?: ProductType | ROUTE_IDS; productOptions?: QuoteProduct }>();
  @Output() removeQuote = new EventEmitter<{ productType?: ProductType | ROUTE_IDS; productOptions?: QuoteProduct }>();
  @Output() enrolNow = new EventEmitter<void>();
  @Output() modifyQuote = new EventEmitter<ModifyQuoteType>();
  @Output() expandProductAccordions = new EventEmitter<void>();
  @Output() expandDiscountAccordions = new EventEmitter<void>();
  @Output() currencyChanged = new EventEmitter<string>();

  currentSchoolName: string;
  currentCourses: CourseQuoteInput[];
  destinationCurrency: string;
  currency: string;
  otherProducts: QuoteProduct[] = [];
  fees: QuoteProduct[] = [];
  keyPoints: KeyPoints;
  itemTotalMessage = $localize`Item Total`;
  includedMessage = $localize`Included in the price`;
  notIncludedMessage = $localize`Not Included in the price`;
  courseNullMessage = $localize`No course`;
  accommodationNullMessage = $localize`No accommodation`;
  insuranceNullMessage = $localize`No insurance`;
  virtualInternshipNullMessage = $localize`No virtual internship`;
  arrivalTransferNullMessage = $localize`No arrival transfer`;
  departureTransferNullMessage = $localize`No departure transfer`;
  premiumActivitiesNullMessage = $localize`No premium activities`;
  privateLessonsNullMessage = $localize`No private lessons`;
  unaccompaniedMinorsNullMessage = $localize`No unaccompanied minors services`;
  premiumResidenceNullMessage = $localize`No single ensuite residence upgrade`;
  feeNullMessage: string | undefined;
  productTypes = ProductType;
  transferServiceTypes = TransferTypeEnum;
  KeyPointIcons = KeyPointIcons;

  remainingDiscountsLength = 0;
  remainingOtherProductsLength = 0;
  decimalPlaces: number;

  isAccommodationMandatory: boolean;
  dateFormat = DateFormat;
  school: School;
  isJuniorsFlow = this.stepService.isJuniors();
  areQuoteTypesAdultAndOnline = this.stepService.areQuoteTypesAdultAndOnline();
  isOnlineQuoteType = false;

  premiumResidenceAvailable = false;
  premiumResidenceAddable = false;

  virtualInternshipsAvailable = false;
  virtualInternshipsAddable = false;

  activeAgeGroup: AgeGroups | undefined;

  availableOptOutProduct = false;

  googleAnalyticsEditQuoteEvent = GoogleAnalyticsEvents.QUOTE_EDIT;
  googleAnalyticsRemoveQuoteEvent = GoogleAnalyticsEvents.QUOTE_REMOVE;
  googleAnalyticsEmailQuoteEvent = GoogleAnalyticsEvents.QUOTE_EMAIL;
  googleAnalyticsEnrolNowEvent = GoogleAnalyticsEvents.QUOTE_ENROL;

  readonly AgeGroups = AgeGroups;
  readonly NA = $localize`N/A`;

  protected readonly modifyQuoteType = ModifyQuoteType;

  private subscriptions = new Subscription();

  private readonly company = getCompany();
  private readonly quoteToolStore = inject(QuoteToolStore);

  constructor(private stepService: StepService, private schoolService: ProductSchoolsService) {}

  ngOnInit(): void {
    this.activeAgeGroup = this.stepService.getActiveAgeGroup();
    this.remainingDiscountsLength = this.discountsStack !== undefined ? Math.max((this.discountsStack || 0) - (this.quote?.discounts?.length || 0), 0) : 0;
    this.remainingOtherProductsLength =
      this.otherProductsStack !== undefined ? Math.max((this.otherProductsStack || 0) - (this.otherProducts?.length || 0), 0) : 0;

    this.decimalPlaces = this.hideDecimals ? 0 : 2;
    // if on single currency mode, we need to use the market currency
    this.currency = this.quote?.singleCurrencyMode ? this.quote?.marketCurrency : this.selectedCurrency;

    // Logic exclude from ESL
    if (this.company !== COMPANIES.ESL) {
      // Set null message for fee. For ESL this should not be shown at all
      this.feeNullMessage = this.NA;

      // --- Logic of premium residence upgrade and virtual internship are out of ESL scope
      if (this.activeAgeGroup === AgeGroups.Junior) {
        this.calculatePremiumResidenceAvailability();
      }

      // For calculating the availability of virtual internship, we need to check if the quote type is adult or online due to
      // both quote types can be displayed at the same time in quote summary
      if (this.activeAgeGroup === AgeGroups.Adult || this.activeAgeGroup === AgeGroups.Online) {
        this.calculateVirtualInternshipAvailability();
      }

      // Check if there is available opt out product
      this.subscriptions.add(
        this.quoteToolStore.optOutProduct$.subscribe((optOutProduct) => {
          this.availableOptOutProduct = optOutProduct !== null && optOutProduct !== undefined;
        }),
      );
      // --- End of logic out of ESL scope
    }
  }

  ngOnChanges({ quote, selectedCurrency }: SimpleChanges) {
    if (quote?.currentValue && quote.currentValue !== quote.previousValue) {
      this.isOnlineQuoteType = this.quote.input?.quoteType === AgeGroups.Online;
      if (this.isOnlineQuoteType) {
        this.initializeNullMessagesForOnlineFlow();
      }
      this.getSchoolData(quote.previousValue?.school);
      this.onQuoteChanges();
    }

    if (selectedCurrency?.currentValue && selectedCurrency.currentValue !== selectedCurrency.previousValue) {
      const overrideCurrency = this.getOverrideCurrency(this.quote);
      this.currency = overrideCurrency || (this.selectedCurrency === DESTINATION_CURRENCY_KEY ? this.destinationCurrency : this.selectedCurrency);
    }

    // quoting in local currency overrides any other setting
    if (this.quote?.singleCurrencyMode) {
      this.currency = this.quote?.marketCurrency;
    }
  }

  ngOnDestroy(): void {
    this.subscriptions.unsubscribe();
  }

  getSchoolData(previousSchool: string) {
    const school = this.quote?.input?.school;
    if (school && school !== previousSchool) {
      this.schoolService
        .get(school)
        .pipe(take(1))
        .subscribe((schoolData: School) => {
          this.school = schoolData;
        });
    }
  }

  setCourseVacations({ closures }: School) {
    this.currentCourses.forEach((course: CourseQuoteInput, index) => {
      if (course.startDate && (course.weeks || course.endDate)) {
        const startDate = getDateFromString(course.startDate);
        const endDate = course.endDate ?? getDateStringFromDate(addWeeks(startDate, course.weeks));
        this.currentCourses[index].vacations = closures
          ?.filter((date) => date.from <= endDate && date.until >= course.startDate)
          ?.map((closure) => ({ startDate: closure.from, endDate: closure.until }));
      }
    });
  }

  onCurrencyChange(newCurrency: string) {
    if (this.quote?.singleCurrencyMode) {
      // we do not allow any other currency rather than market currency on single currency mode
      this.currency = this.quote?.marketCurrency;
      return;
    }

    if (this.currency !== newCurrency) {
      this.currency = newCurrency;
      this.currencyChanged.emit(newCurrency);
    }
  }

  getTuitions(): QuoteProduct[] {
    return this.quote?.products?.filter((product: QuoteProduct) => product.classification === ProductType.TUITION);
  }

  editCourseDetails(routeId: ROUTE_IDS) {
    this.openPopup.emit({ productType: routeId });
  }

  triggerEnrolment() {
    setTimeout(() => this.enrolNow.emit());
  }

  private onQuoteChanges() {
    const overrideCurrency = this.getOverrideCurrency(this.quote);
    this.destinationCurrency = overrideCurrency || this.quote?.input?.destinationCurrency;
    this.otherProducts = getQuoteSummaryRestProducts(this.quote?.products);
    this.fees = getQuoteSummaryFees(this.quote?.products);
    this.keyPoints = getQuoteKeyPoints(this.quote);

    // Update currentCourses
    if (this.quote?.input?.courses?.length) {
      // ESL way (can have several courses)
      this.currentCourses = deepClone(this.quote?.input?.courses);
    } else {
      // KIL/AKP way (only one course)
      this.currentCourses = [
        {
          name: this.courseName,
          startDate: this.quote.input.courseStartDate,
          endDate: this.quote.input.courseEndDate,
        } as CourseQuoteInput,
      ];
    }
    if (this.school && Object.keys(this.currentCourses[0]).length) {
      this.setCourseVacations(this.school);
    }

    // Update currentSchoolName
    this.currentSchoolName = this.schoolName ?? this.quote.input?.schoolObject?.name;

    this.isAccommodationMandatory = isAccommodationMandatory(this.quote.input);
  }

  private getOverrideCurrency(quote: QuoteOutput) {
    const visiblePrices = quote?.grandTotal?.filter((amount) => amount.visible);
    if (visiblePrices?.length === 1) {
      return visiblePrices[0].currency;
    }
  }

  private initializeNullMessagesForOnlineFlow() {
    this.accommodationNullMessage = this.NA;
    this.insuranceNullMessage = this.NA;
    this.arrivalTransferNullMessage = this.NA;
    this.departureTransferNullMessage = this.NA;
    this.virtualInternshipNullMessage = this.NA;
  }

  private calculatePremiumResidenceAvailability() {
    this.quoteToolStore.quoteToolSlots$.subscribe((slots) => {
      this.premiumResidenceAddable = slots.find((slot) => slot.key === Number(this.activeQuoteSlot))?.premiumResidencesAvailable;
      this.premiumResidenceAvailable = slots?.reduce((acc, { premiumResidencesAvailable }) => premiumResidencesAvailable || acc, false);
    });
  }

  private calculateVirtualInternshipAvailability() {
    this.quoteToolStore.quoteToolSlots$.subscribe((slots) => {
      this.virtualInternshipsAddable = slots.find((slot) => slot.key === Number(this.activeQuoteSlot))?.virtualInternshipsAvailable;
      this.virtualInternshipsAvailable = slots?.reduce((acc, { virtualInternshipsAvailable }) => virtualInternshipsAvailable || acc, false);
    });
  }
}
