<kng-form-field
  [small]="true"
  [disabled]="disabled"
  [label]="label"
  class="date-picker-field"
  [ngClass]="{
    'date-picker-field--disabled': disabled,
    'date-picker-field--with-icon': showCalendarIcon,
    'date-picker-field--mark-enabled-days': disabledDates?.length || disabledDays?.length
  }"
  [isValid]="checkIsValid()"
  [required]="required"
>
  <p-calendar
    #calendar
    class="date-picker-field__calendar"
    selectionMode="single"
    [disabled]="disabled"
    [readonlyInput]="true"
    [firstDayOfWeek]="1"
    [defaultDate]="selectedDate$ | async"
    [dateFormat]="dateFormat"
    [minDate]="minDate"
    [maxDate]="maxDate"
    [disabledDates]="disabledDates"
    [disabledDays]="disabledDays"
    [yearRange]="yearRange"
    [placeholder]="placeholder"
    [(ngModel)]="pickedDate.dateObject"
    (ngModelChange)="pickDate($event)"
    (onShow)="prepareCalendar()"
    (onFocus)="datePickerFocused.emit()"
  >
    <ng-template pTemplate="header">
      <div class="date-picker-field__header">
        <div class="date-picker-field__header__date-selectors">
          <p-dropdown
            class="date-picker-field__header__date-selectors__month"
            [scrollHeight]="monthScrollHeight"
            [options]="months"
            [(ngModel)]="selectedMonth"
            optionLabel="label"
            (onChange)="updateSelectedDate()"
          ></p-dropdown>
          <p-dropdown
            class="date-picker-field__header__date-selectors__year"
            [options]="years"
            [(ngModel)]="selectedYear"
            (onChange)="updateSelectedDate()"
          ></p-dropdown>
        </div>
        <div class="date-picker-field__header__navigation-buttons">
          <p-button
            icon="pi pi-chevron-left"
            styleClass="p-button-rounded p-button-outlined"
            (onClick)="navigateToPreviousMonth()"
            [disabled]="previousMonthDisabled"
          ></p-button>
          <p-button
            icon="pi pi-chevron-right"
            styleClass="p-button-rounded p-button-outlined"
            (onClick)="navigateToNextMonth()"
            [disabled]="nextMonthDisabled"
          ></p-button>
        </div>
      </div>
    </ng-template>
  </p-calendar>
</kng-form-field>
