import { Component, EventEmitter, Input, Output, inject } from '@angular/core';
import { BreakpointObserverService } from '@klg/shared/utils-dom';
import { QuoteOutput } from '@klg/quote-tool/shared/types';

@Component({
  selector: 'kng-quote-popup',
  templateUrl: './quote-popup.component.html',
  styleUrls: ['./quote-popup.component.scss'],
})
export class QuotePopupComponent {
  @Input() isJuniors: boolean;
  @Input() isOnlineFlow: boolean;
  @Input() quote: QuoteOutput;
  @Input() currency = 'USD';
  @Input() courseName: string;
  @Input() languageName: string;
  @Input() schoolName: string;
  @Input() hideDiscounts = false;
  @Input() isOpen = false;
  @Input() depositsIsEnabled = false;
  @Input() displayEnrolmentButton = true;
  @Output() closePopup = new EventEmitter<void>();

  public areProductAccordionsExpanded = false;
  public areDiscountAccordionsExpanded = false;

  private readonly breakpointObserverService = inject(BreakpointObserverService);
  readonly isMobile$ = this.breakpointObserverService.isMobile$;

  public closePopupCb = () => this.closePopup.emit();

  // TODO - Check Template - KLG-11183
}
