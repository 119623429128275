import {
  QuoteLeadAnalyticsDataApiDto,
  QuoteLeadApiDto,
  QuoteLeadInputApiDto,
  QuoteLeadInputV4ApiDto,
  QuoteLeadOutputApiDto,
  QuoteLeadQuoteApiDto,
  QuoteLeadQuoteInputApiDto,
  QuoteLeadUserDataApiDto,
  QuoteRequestApiDto,
  PayableDataV3ApiDto,
} from '@klg/shared/api/models';
import { CodeAndName } from '@klg/shared/types';
import TitleApiDtoEnum = QuoteLeadUserDataApiDto.TitleApiDtoEnum;

export type QuoteLead = QuoteLeadApiDto;
export type QuoteLeadInput = QuoteLeadInputApiDto;
export type QuoteLeadInputV4 = QuoteLeadInputV4ApiDto;
export type QuoteLeadAnalyticsData = QuoteLeadAnalyticsDataApiDto;
export type QuotePayableData = PayableDataV3ApiDto;

export type QuoteLeadOutput = QuoteLeadOutputApiDto;

export type QuoteLeadOrigin = QuoteLeadApiDto.FormOriginApiDtoEnum;
export const QuoteLeadOriginEnum = QuoteLeadApiDto.FormOriginApiDtoEnum;

export type QuoteLeadQuoteInput = QuoteLeadQuoteInputApiDto;
export type QuoteLeadQuote = QuoteLeadQuoteApiDto;
export type StudentDetails = QuoteLeadUserDataApiDto;

export type QuoteRequest = QuoteRequestApiDto;

export type QuoteLeadUserTitle = QuoteLeadUserDataApiDto.TitleApiDtoEnum;
export const QuoteLeadUserTitleEnum = TitleApiDtoEnum;

export interface ResidenceData {
  residenceCurrency: string;
  countryOfResidence: string;
  regionOfResidence?: string;
  countryPhoneCode?: string;
}

export type TellUsAboutYouSectionFormPayload = Pick<
  StudentDetails,
  | 'title'
  | 'identityDocument'
  | 'firstName'
  | 'lastName'
  | 'guardianFirstName'
  | 'guardianLastName'
  | 'email'
  | 'phoneNumber'
  | 'postcode'
  | 'city'
  | 'countryOfResidence'
  | 'dateOfBirth'
  | 'address'
  | 'placeOfBirth'
  | 'nationality'
  | 'motherTongue'
>;

/**
 * Simplified payload for student details. This will have the minimum fields
 * to collect the information about a student
 */
export type SimplifiedTellUsAboutYouSectionFormPayload = Pick<
  StudentDetails,
  'firstName' | 'lastName' | 'email' | 'phoneNumber' | 'postcode' | 'countryOfResidence'
>;

export interface JuniorContactPersonSectionPayload {
  legalRepresentativeFirstName: string;
  legalRepresentativeLastName: string;
  legalRepresentativeEmail: string;
  legalRepresentativeAddress: string;
  legalRepresentativeMobile: string;
}

export interface AdultContactPersonSectionPayload {
  contactPersonFirstName: string;
  contactPersonLastName: string;
  contactPersonPhone: string;
}

export type ContactPersonSectionPayload = JuniorContactPersonSectionPayload & AdultContactPersonSectionPayload;

export type ExtrasSelectionEnrollmentPayload = {
  leisure: string;
  specialDiet: string;
  allergies: string;
  vegetarian: boolean;
  smoker: boolean;
  otherWishes: string;
  hostFamilyOptions: HostFamilyOptions;
};

export type HostFamilyOptions = {
  isNonSmokingFamily: boolean;
  hasChildrenFamily: boolean;
  hasPetsFamily: boolean;
  hasStudentsFamily: boolean;
};

export type ExternalQuoteLeadUserData = Pick<QuoteLeadUserDataApiDto, 'roiFirst' | 'roiCurrent' | 'urlOrigin' | 'urlCurrent' | 'urlReferer' | 'referredID'>;

export interface LeadEntityCodeAndNames {
  language: CodeAndName;
  school: CodeAndName;
  course: CodeAndName;
}
