import { DOCUMENT } from '@angular/common';
import { Inject, Injectable } from '@angular/core';
import { Resolve } from '@angular/router';
import { Language, LanguageService } from '@klg/language';
import { CourseTypeService } from '@klg/quote-tool/shared/data-access/course';
import { CountryService } from '@klg/quote-tool/shared/data-access/destination';
import { QuoteSchoolService } from '@klg/quote-tool/shared/data-access/school';
import { QuoteSchool } from '@klg/quote-tool/shared/types/school';
import { Country } from '@klg/shared/data-access/types';
import { ResidenceCountry, ResidenceCountryService } from '@klg/shared/data-access/destination';
import { QuoteInput } from '@klg/quote-tool/shared/types/quote';
import { CourseType } from '@klg/shared/data-access/types';
import { combineLatest, Observable } from 'rxjs';
import { map, mergeMap, take } from 'rxjs/operators';
import { HostDataReaderService } from '@klg/quote-tool/shared/services';

export interface QuoteFormResolvedData {
  languages: Language[];
  countries: Country[];
  schools: QuoteSchool[];
  courseTypes: CourseType[];
  residenceCountries: ResidenceCountry[];
  prefilled?: Partial<QuoteInput>;
  referrer?: string;
}

@Injectable({
  providedIn: 'root',
})
export class QuoteFormDataResolver implements Resolve<QuoteFormResolvedData> {
  constructor(
    private readonly languageService: LanguageService,
    private readonly countryService: CountryService,
    private readonly schoolService: QuoteSchoolService,
    private readonly courseTypeService: CourseTypeService,
    private readonly residenceCountryService: ResidenceCountryService,
    private readonly hostDataReader: HostDataReaderService,
    @Inject(DOCUMENT) private document: Document,
  ) {}

  public resolve(): Observable<QuoteFormResolvedData> {
    return combineLatest([
      this.languageService.getAll(),
      this.countryService.getAll(),
      this.schoolService.getAll(),
      this.courseTypeService.getAll(),
      this.residenceCountryService.getAll(),
    ]).pipe(
      take(1),
      mergeMap(([languages, countries, schools, courseTypes, residenceCountries]: [Language[], Country[], QuoteSchool[], CourseType[], ResidenceCountry[]]) => {
        return this.hostDataReader.getPrefilledSchoolCourse$().pipe(
          map((prefilled) => ({
            languages,
            countries,
            schools,
            courseTypes,
            residenceCountries,
            prefilled,
            referrer: this.document.referrer,
          })),
        );
      }),
    );
  }
}
