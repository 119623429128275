import { Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges, ViewChild } from '@angular/core';
import { KeyValueLabel } from '../../types/key-value-item.type';
import { SortingItem } from '@klg/shared/types';
import { valueIsNullOrUndefined } from '@klg/shared/utils';
import { Dropdown } from 'primeng/dropdown';

@Component({
  selector: 'kng-sort',
  templateUrl: './sort.component.html',
  styleUrls: ['./sort.component.scss'],
})
export class SortComponent<T> implements OnInit, OnChanges {
  @ViewChild('dropdown') dropdown: Dropdown | undefined;

  @Input() set sortingOptions(options: KeyValueLabel<string, SortingItem<T>>[]) {
    if (options.length) {
      this._sortingOptions = options;
    }
  }

  get sortingOptions(): KeyValueLabel<string, SortingItem<T>>[] {
    return this._sortingOptions;
  }

  @Input() displayExpr: string | ((Object) => string);
  @Input() valueExpr: string | ((Object) => string | number | boolean);
  @Input() selectedValue: string;
  @Output() sortChanges: EventEmitter<SortingItem<T>> = new EventEmitter<SortingItem<T>>();

  isOpened: boolean;
  selectedItem: string;

  private _sortingOptions: KeyValueLabel<string, SortingItem<T>>[] = [];

  constructor() {
    this.displayExpr = 'label';
    this.valueExpr = 'key';
  }

  ngOnInit() {
    if (this.selectedValue) {
      this.setSelectedValue(this.selectedValue);
    }
  }

  ngOnChanges({ selectedValue, sortingOptions }: SimpleChanges): void {
    if (selectedValue) {
      this.setSelectedValue(selectedValue.currentValue);
    }
    if (sortingOptions && sortingOptions.currentValue.length) {
      this.setSelectedValue(sortingOptions.currentValue[0]);
    }
  }

  selectedItemChanges($event): void {
    if ($event && this.selectedValue !== $event.value) {
      this.sortChanges.emit($event.value);
    }
  }

  getDropdownIcon() {
    return this.dropdown && this.dropdown.overlayVisible ? 'pi pi-chevron-up' : 'pi pi-chevron-down';
  }

  toggleDropdown(isOpened: boolean) {
    this.isOpened = isOpened;
  }

  private setSelectedValue(value: string): void {
    this.selectedItem = valueIsNullOrUndefined(value)
      ? this.sortingOptions[0].key
      : this.sortingOptions.find((sortingOption) => sortingOption.key === value)?.key;
  }
}
