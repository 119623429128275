import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { GoogleAnalyticsClickEventDirective } from '@klg/shared/google-analytics';
import { NgIf, NgTemplateOutlet } from '@angular/common';
import { getConfiguration } from '@klg/shared/tokens';

@Component({
  standalone: true,
  selector: 'kng-offer-label',
  templateUrl: './offer-label.component.html',
  styleUrls: ['./offer-label.component.scss'],
  imports: [GoogleAnalyticsClickEventDirective, NgTemplateOutlet, NgIf],
})
export class OfferLabelComponent implements OnInit {
  @Input() googleAnalyticsClickEvent: string | undefined;
  @Output() showPromotionPopup = new EventEmitter<boolean>();

  isPopupShown = false;
  enabledPopupPromo = false;

  private readonly configuration = getConfiguration();

  ngOnInit(): void {
    this.enabledPopupPromo = this.configuration.QUOTE_TOOL_PROMO_POPUP_ENABLED as boolean;
  }

  onLabelClicked($event: Event) {
    $event.stopPropagation();
    this.isPopupShown = !this.isPopupShown;
    this.showPromotionPopup.emit(this.isPopupShown);
  }
}
