import { FactoryProvider } from '@angular/core';
import { APPLICATION_CONFIGURATION_SERVICE, BaseConfigService } from '@klg/shared/app-config';
import { ENVIRONMENT } from '@klg/shared/tokens';
import { CoreEnvironment } from '@klg/shared/types';

export const getEnvironmentProvider = (env: CoreEnvironment): FactoryProvider => {
  return {
    provide: ENVIRONMENT,
    useFactory: (configService: BaseConfigService) => configService.getEnvironment(env),
    deps: [APPLICATION_CONFIGURATION_SERVICE],
  };
};
