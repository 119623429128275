<a
  *ngIf="href"
  [href]="disabled ? '' : href"
  [ngClass]="{ 'klg-button--block': block }"
  [attr.id]="buttonId"
  [attr.data-gtm-id]="googleTagManagerId"
  [attr.target]="target"
>
  <ng-container *ngTemplateOutlet="buttonContent"></ng-container>
</a>

<button
  *ngIf="!href"
  [disabled]="disabled"
  (click)="buttonClick.emit($event)"
  [ngClass]="{ 'klg-button--block': block }"
  [attr.id]="buttonId"
  [attr.data-gtm-id]="googleTagManagerId"
  [type]="buttonType"
>
  <ng-container *ngTemplateOutlet="buttonContent"></ng-container>
</button>

<ng-template #buttonContent>
  <kng-loading-icon-svg *ngIf="loading" [iconStyle]="buttonStyle" class="klg-button__icon" [ngClass]="'klg-button__icon-' + iconPos"></kng-loading-icon-svg>

  <i
    *ngIf="icon"
    [ngClass]="[icon, 'klg-button__icon-' + iconPos]"
    class="
    {{ 'klg-button__icon ' }}
    {{ iconFill ? 'klg-button__icon--fill ' : '' }}
    {{ iconRounded ? 'klg-button__icon--fill--rounded' : '' }}"
  ></i>
  <i *ngIf="svg" class="{{ 'klg-button__svg klg-button__svg--' + svg + ' klg-button__svg--' + svgColor }}"></i>

  <span
    *ngIf="hasText"
    [class.klg-button__svg__text]="(svg || iconFill) && !textLight"
    [ngClass]="{
      'klg-button__text--bold': iconFill,
      'klg-button__text--uppercase': uppercase,
      'klg-button__text--underlined': isUnderlined
    }"
  >
    {{ text }}<ng-content></ng-content>
  </span>
</ng-template>
