import { SchoolTypes, SchoolTypesEnum } from '@klg/shared/data-access/types';

export const getSchoolType = <T extends { types?: SchoolTypes[] }>({ types }: T) => {
  if (!types) {
    return;
  }
  const [type] = types;
  return type;
};

/**
 * @deprecated If you call this function in the template, please use the new isSchoolOfType
 */
export const isAdultSchool = <T extends { types?: SchoolTypes[] }>(obj: T) => {
  return getSchoolType(obj) === SchoolTypesEnum.ADULT;
};

/**
 * @deprecated If you call this function in the template, please use the new isSchoolOfType
 */
export const isJuniorSchool = <T extends { types?: SchoolTypes[] }>(obj: T) => !isAdultSchool(obj);
