export enum DateFormat {
  SHORT_MONTH_NAME,
  FULL_MONTH_NAME,
  NUMBERS_ONLY,
  SHORT_MONTH_NAME_PRIME_NG,
}

export const DATE_FORMAT_CONFIG_MAPPING = {
  [DateFormat.SHORT_MONTH_NAME]: 'QUOTE_TOOL_DATE_FORMAT_SHORT_MONTH_NAME',
  [DateFormat.FULL_MONTH_NAME]: 'QUOTE_TOOL_DATE_FORMAT_FULL_MONTH_NAME',
  [DateFormat.NUMBERS_ONLY]: 'QUOTE_TOOL_DATE_FORMAT_NUMBERS_ONLY',
  [DateFormat.SHORT_MONTH_NAME_PRIME_NG]: 'QUOTE_TOOL_DATE_FORMAT_SHORT_MONTH_NAME_PRIME_NG',
};

export interface DateFormatSettings {
  [DateFormat.SHORT_MONTH_NAME]: string;
  [DateFormat.FULL_MONTH_NAME]: string;
  [DateFormat.NUMBERS_ONLY]: string;
  [DateFormat.SHORT_MONTH_NAME_PRIME_NG]: string;
}
