import { Component, EventEmitter, inject, Input, OnChanges, OnDestroy, OnInit, Output, SimpleChanges } from '@angular/core';
import { SortingCriteria } from '@klg/shared/types';
import { PromotionSummary } from '@klg/shared/data-access/promotion';
import { CourseTypeService } from '@klg/quote-tool/shared/data-access/course';
import { CourseType } from '@klg/shared/data-access/types';
import { getConfiguration } from '@klg/shared/tokens';
import { deepClone } from '@klg/shared/utils';
import { Subscription } from 'rxjs';
import { GoogleAnalyticsEvents } from '@klg/shared/google-analytics';
import { QuoteToolStore } from '@klg/quote-tool/shared/store';
import { arrayEquals } from '@klg/shared/utils-array';

@Component({
  selector: 'kng-course-type-selector',
  templateUrl: './course-type-selector.component.html',
  styleUrls: ['./course-type-selector.component.scss'],
})
export class CourseTypeSelectorComponent implements OnInit, OnDestroy, OnChanges {
  @Input() label: string;
  @Input() disabled = false;
  @Input() value: string = undefined;
  @Input() codes: string[] = [];
  @Input() buttonView = false;
  @Input() isOpen = false;
  @Input() autofill = false;
  @Input() placeholder: string;
  @Input() required = false;
  @Input() sortCriteria: SortingCriteria<CourseType> = [{ property: 'weight' }, { property: 'name' }];
  @Input() promotionSummaries: PromotionSummary[] = [];
  @Input() ageGroup: 'junior' | 'adult' | 'online';
  @Input() iconValidation = false;
  @Input() schoolCompany = '';
  @Output() valueChange = new EventEmitter<string>();
  @Output() itemChange = new EventEmitter<CourseType>();
  @Output() isOpenChange = new EventEmitter<boolean>();
  @Output() showPromoModal = new EventEmitter<PromotionSummary[]>();
  public popupCourseType: CourseType | null = null;
  readonly GoogleAnalyticsEvents = GoogleAnalyticsEvents;

  private subscription: Subscription;

  public itemList: CourseType[] = null;
  public selectedValue: string;
  private readonly configuration = getConfiguration();
  private readonly quoteToolStore = inject(QuoteToolStore);

  public courseType = (item: CourseType) => item;

  constructor(private courseTypeService: CourseTypeService) {}

  private getItemList() {
    // Unsubscribe from previous, subscription and create a new one.
    // If we don't create a new one, it will unsubscribe from the subscription added next
    this.subscription?.unsubscribe();
    this.subscription = new Subscription();

    const getCourseTypes$ = this.codes?.length ? this.courseTypeService.getByCodes(this.codes) : this.courseTypeService.getAll();
    this.subscription.add(
      getCourseTypes$.subscribe((data) => {
        this.itemList = data
          .map(deepClone)
          // filter out online courses in adults flow if the Online Flow is activated
          .filter((courseType) => this.filterCourseTypes(courseType))
          // leave only the course types of the school's company
          .filter((courseType) => !this.schoolCompany || courseType.company === this.schoolCompany);

        // Store in quote tool store the list of course types
        this.quoteToolStore.addCourseTypes(data);
        this.updateItemList();
      }),
    );
  }

  private filterCourseTypes(courseType: CourseType) {
    return this.ageGroup === 'adult' && courseType.code?.includes('ONLINE') ? !this.configuration['QUOTE_TOOL_ONLINE_FLOW_ENABLED'] : true;
  }

  private updateItemList() {
    this.itemList?.map((courseType: CourseType) => (courseType.hasPromotion = this.hasPromotion(courseType)));
  }

  private hasPromotion(courseType: CourseType): boolean {
    return this.promotionSummaries?.some((promotionSummary: PromotionSummary) => promotionSummary.groupingKey === courseType.code);
  }

  showOfferPopup(code: string) {
    const promotions = this.promotionSummaries?.filter((item: PromotionSummary) => item.groupingKey === code);
    this.showPromoModal.emit(promotions);
  }

  ngOnInit() {
    this.getItemList();
  }

  ngOnChanges({ value, codes, promotionSummaries }: SimpleChanges): void {
    if (value && value.currentValue) {
      this.selectedValue = this.value;
    }

    if (codes && arrayEquals(codes.currentValue, codes.previousValue) === false) {
      this.getItemList();
    }

    if (promotionSummaries && promotionSummaries.currentValue) {
      this.updateItemList();
    }
  }

  ngOnDestroy() {
    this.subscription?.unsubscribe();
  }
}
