import { inject, Injectable } from '@angular/core';
import { Route } from '@angular/router';
import { BookingFormComponent } from './booking-form.component';
import { ROUTE_IDS, StepDefinition } from '@klg/quote-tool/shared/types';
import { Subject } from 'rxjs';
import { FormStepDefinition } from '../../../shared/types/form.step.definition.interface';
import { StepDefinitionHelper } from '../../../shared/services/step-definition.helper';
import { QuoteToolFormStore } from '../../../shared/store/quote-tool.form.store';

const stepId = ROUTE_IDS.BOOKING_FORM;

@Injectable({
  providedIn: 'root',
})
export class BookingFormResolver {
  private readonly requestValidation$ = new Subject<void>();
  private readonly submittableForm$ = new Subject<boolean>();

  private stepDefinition: FormStepDefinition = {
    nextButton: $localize`Book Now`,
    nextButtonId: 'enrolment-button',
    prevButton: $localize`Back`,
    displayQuote: true,
    stepId,
    requestValidation$: this.requestValidation$,
    submittableForm$: this.submittableForm$,
    previousStep: () => {
      // Reset the store when going back to have everything
      // clean when arriving again to this step
      this.quoteToolFormStore.resetStore();
      return ROUTE_IDS.QUOTE_FORM;
    },
    nextStep: () =>
      this.stepDefinitionHelper.getRouteIdToNavigateWithValidationForm(
        { requestValidation$: this.requestValidation$, submittableForm$: this.submittableForm$ },
        ROUTE_IDS.SEND_QUOTE_TO_EMAIL,
      ),
  };

  private readonly quoteToolFormStore = inject(QuoteToolFormStore);
  private readonly stepDefinitionHelper = inject(StepDefinitionHelper);

  resolve(): StepDefinition {
    return this.stepDefinition;
  }
}

export const stepRoute: Route = {
  path: stepId,
  resolve: {
    stepDefinition: () => inject(BookingFormResolver).resolve(),
  },
  component: BookingFormComponent,
};
