<div
  class="urgency-driver__container"
  [class.urgency-driver__container--course-selector]="isCourseSelector"
  [class.urgency-driver__container--quote-summary]="isQuoteSummary"
>
  <div
    [class.urgency-driver__container__content__tag-icon]="hasIcon"
    [class.urgency-driver__container__content__tag-icon--course-selector]="isCourseSelector && hasIcon"
    [class.urgency-driver__container__content__tag-icon--quote-summary]="isQuoteSummary && hasIcon"
  ></div>
  <div
    class="urgency-driver__container__content__text"
    [class.urgency-driver__container__content__text--course-selector]="isCourseSelector"
    [class.urgency-driver__container__content__text--quote-summary]="isQuoteSummary"
  >
    <div class="urgency-driver__container__content__text__title">{{ urgencyDriverTitle }}</div>
    <div class="urgency-driver__container__content__text__description">{{ urgencyDriverDescription + ' ' + urgencyDriverDate }}</div>
  </div>
</div>
