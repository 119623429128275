import { SortDirection, SortingCriteria, SortingItem } from '@klg/shared/types';
import { isSortDirection } from './is-sort-direction.function';

interface SortModel {
  properties: string[];
  directions: SortDirection[];
}

export function sortArray<T>(originalArray: T[], sortCriteria: SortingCriteria<T> | SortDirection = 'asc'): T[] {
  // No sort needed
  if (!originalArray || !originalArray.length || !sortCriteria || !sortCriteria.length || originalArray.length <= 1) {
    return originalArray;
  }

  // Simple sorting
  if (isSortDirection(sortCriteria)) {
    const sortedArray = [...originalArray].sort((a, b) => compare(a, b));
    return sortCriteria === 'asc' ? sortedArray : sortedArray.reverse();
  }

  // Objects sorted by properties
  const { properties, directions } = Object.values(sortCriteria).reduce(
    (acc: SortModel, sortItem: SortingItem<T>) => ({
      properties: [...acc.properties, sortItem.property],
      directions: [...acc.directions, sortItem.direction ?? 'asc'],
    }),
    { properties: [], directions: [] },
  ) as SortModel;

  return [...originalArray].sort((a, b) => {
    let result = 0;
    for (let i = 0; i < properties.length && result === 0; i++) {
      const direction = directions[i] === 'asc' ? 1 : -1;
      const property = properties[i];
      const valueA = getValueNestedProperty(a, property);
      const valueB = getValueNestedProperty(b, property);
      result = direction * compare(valueA, valueB);
    }
    return result;
  });
}

export function sortArrayWithPriorityFn<T>(originalArray: T[], sortCriteria: SortingCriteria<T> | SortDirection = 'asc', priorityItems: T[]): T[] {
  let sortedArray = sortArray(originalArray, sortCriteria);
  sortedArray = sortedArray.filter((el) => !priorityItems.includes(el));
  return [...priorityItems, ...sortedArray];
}

export function compare(a, b): number {
  return !isNaN(Number(a)) && !isNaN(Number(b)) ? Number(a) - Number(b) : Intl.Collator().compare(String(a), String(b));
}

export function getValueNestedProperty(obj, property) {
  return property.split('.').reduce((o, key) => {
    return o[key];
  }, obj);
}
