import { Component, EventEmitter, Input, OnChanges, Output, SimpleChanges } from '@angular/core';
import { DateFormat } from '@klg/shared/i18n';
import { DateRange } from '@klg/shared/data-access/types';
import { getCompany } from '@klg/shared/tokens';
import { COMPANIES } from '@klg/shared/types';
import { CourseQuoteInput, ProductType, QuoteProduct, QuoteProductType, Vacation } from '@klg/quote-tool/shared/types';
import { checkHolidayClosures } from '@klg/quote-tool/shared/utils/functions';

@Component({
  selector: 'kng-quote-product-details',
  templateUrl: './quote-product-details.component.html',
  styleUrls: ['./quote-product-details.component.scss'],
})
export class QuoteProductDetailsComponent implements OnChanges {
  @Input() product: QuoteProduct | undefined;
  @Input() quoteKey = '';
  @Input() currency = '';
  @Input() countryCode = '';
  @Input() decimalPlaces = 0;
  @Input() currentCourses: CourseQuoteInput[] = [];
  @Input() isAccommodationMandatory = false;
  @Input() isQuoteSlotExpanded = false;
  @Output() infoButtonClicked = new EventEmitter<void>();
  @Output() editButtonClicked = new EventEmitter<void>();
  @Output() removeButtonClicked = new EventEmitter<void>();
  public productTypes = ProductType;
  public dateFormat = DateFormat.SHORT_MONTH_NAME;
  public selectedWeeks: DateRange[] = [];
  public weekLongVacation: Vacation[] = [];
  public isCourse = false;
  public transferMessage = $localize`Transfer`;
  public disclaimerMessage = $localize`Please note that the price might vary during the high season.`;
  public availableOptOutProduct = false;
  public isProductEditable = false;
  public readonly company = getCompany();
  private editableProductTypes: ProductType[] = [
    ProductType.FEE,
    ProductType.TRANSFER,
    ProductType.INSURANCE,
    ProductType.ACCOMMODATION,
    ProductType.PRIVATE_LESSONS,
    ProductType.PREMIUM_ACTIVITIES,
    ProductType.PREMIUM_RESIDENCE,
    ProductType.VIRTUAL_INTERNSHIP,
  ];

  ngOnChanges({ product }: SimpleChanges) {
    if (product?.currentValue) {
      const { startDate, endDate, vacations, productType } = this.product;
      this.selectedWeeks = [{ startDate, endDate }];
      if (this.company === COMPANIES.ESL && this.product?.classification === this.productTypes?.ACCOMMODATION) {
        this.weekLongVacation = vacations?.filter((vacation) => vacation?.durationWeeks > 0);
      } else if (this.product?.classification !== this.productTypes?.FEE) {
        this.weekLongVacation = checkHolidayClosures(this.countryCode, startDate, endDate);
      }
      this.isCourse = productType === QuoteProductType.COURSE;
      this.availableOptOutProduct = this.product.isRemovable && this.product.classification === ProductType.OPT_OUT;
      this.isProductEditable =
        (this.editableProductTypes.includes(ProductType[this.product.classification]) && this.product.classification !== ProductType.FEE) ||
        (this.editableProductTypes.includes(ProductType.FEE) && this.product.code === 'ALPUMSERV');
    }
  }
}
