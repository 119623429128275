<div class="enrolment-payment">
  <div class="enrolment-payment__title" i18n>Your adventure starts today!</div>
  <div class="enrolment-payment__sub-title" i18n>Thank you for registering online.</div>
  <div class="enrolment-payment__paragraph" i18n>
    We have received your request. One of our agents is checking the availability with the school and will contact you soon.
  </div>
  <ng-container *ngTemplateOutlet="isDepositRequired ? requiredDepositIntro : optionalDepositIntro"></ng-container>

  <div class="enrolment-payment__summary">
    <div class="enrolment-payment__summary-item">
      <div class="enrolment-payment__summary-item-title" i18n>Name:</div>
      <div class="enrolment-payment__summary-item-info">{{ studentDetails.firstName }} {{ studentDetails.lastName }} ({{ studentDetails.email }})</div>
    </div>
    <div class="enrolment-payment__summary-item">
      <div class="enrolment-payment__summary-item-title" i18n>Amount due:</div>
      <div class="enrolment-payment__summary-item-info">
        {{ quote?.payableData?.payableInfo?.amount | klgPrice }}
      </div>
    </div>
  </div>

  <kng-button
    class="enrolment-payment__pay-cta"
    [text]="payNowButtonLabel"
    (buttonClick)="pay()"
    buttonStyle="primary"
    [btnRounded]="true"
    [block]="true"
  ></kng-button>

  <div class="enrolment-payment__payment-card-options">
    <kng-payment-card-options></kng-payment-card-options>
  </div>
</div>

<ng-template #requiredDepositIntro>
  <ng-container *ngTemplateOutlet="depositRequiredMessage ? withDepositRequiredMessage : withoutDepositRequiredMessage"></ng-container>
</ng-template>

<ng-template #optionalDepositIntro>
  <div class="enrolment-payment__paragraph" i18n>
    If you want your registration to be processed faster, you can pay the deposit amount of
    {{ quote?.payableData?.payableInfo?.amount | klgPrice }} by credit card. Please note that this payment does not guarantee the enrolment, until we’ve
    confirmed the availability with the school and communicated it to you personally.
  </div>
</ng-template>

<ng-template #withDepositRequiredMessage>
  <div class="enrolment-payment__paragraph" i18n>
    Since your departure is scheduled in less than {{ depositRequiredMessage }}, now we ask you to pay the deposit amount of
    {{ quote?.payableData?.payableInfo?.amount | klgPrice }} by credit card. Please note that this payment does not guarantee the enrolment, until we’ve
    confirmed the availability with the school and communicated it to you personally.
  </div>
</ng-template>

<ng-template #withoutDepositRequiredMessage>
  <div class="enrolment-payment__paragraph" i18n>
    In order to complete this process, now we ask you to pay the deposit amount of {{ quote?.payableData?.payableInfo?.amount | klgPrice }} by credit card.
    Please note that this payment does not guarantee the enrolment, until we’ve confirmed the availability with the school and communicated it to you
    personally.
  </div>
</ng-template>
