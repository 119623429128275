import { inject, Injectable } from '@angular/core';
import { DestinationControllerApiService } from '@klg/shared/api/services';
import { BaseService, CachedRequestResponse, Memoize } from '@klg/shared/utils-http';
import { Observable, of } from 'rxjs';
import { map } from 'rxjs/operators';
import { GetAllCitiesParameters, City, CityExtended } from '@klg/shared/data-access/types';
import { ExtendCityService } from './extend-city.service';

@Injectable({
  providedIn: 'root',
})
export class CityDataService extends BaseService<CityExtended> {
  protected responseCache = new CachedRequestResponse<CityExtended[]>(
    () =>
      this.destinationControllerApiService
        .findDestinationCitiesByFilter({}, this.locale, this.companyHeader)
        .pipe(map((cities: City[]) => cities.map((city) => this.mapToExtendedCity(city)))),
    this.cacheSettings.get,
  );

  private readonly destinationControllerApiService = inject(DestinationControllerApiService);
  private readonly extendCityService = inject(ExtendCityService);

  @Memoize((parameters) => JSON.stringify(parameters))
  public getAllCitiesWithParameters(parameters: GetAllCitiesParameters): Observable<CityExtended[]> {
    return this.destinationControllerApiService
      .findDestinationCitiesByFilter(parameters, this.locale, this.companyHeader)
      .pipe(map((cities: City[]) => cities.map((city) => this.mapToExtendedCity(city))));
  }

  protected getResponseCache$(): Observable<CachedRequestResponse<CityExtended[]>> {
    return of(this.responseCache);
  }

  private mapToExtendedCity(city: City) {
    return this.extendCityService.extendCity(city);
  }
}
