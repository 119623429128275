import { Component, inject, Input, OnChanges, OnDestroy, OnInit, SimpleChanges } from '@angular/core';
import { getQuoteKeyPoints, QuoteFormLinkUrlsService } from '@klg/quote-tool/shared/data-access/quote';
import { getToday } from '@klg/shared/utils';
import { KeyPointIcons } from '@klg/shared/models';
import { KeyPoints } from '@klg/shared/data-access/course';
import { ProductSchoolsService, School } from '@klg/shared/data-access/school';
import { Subscription } from 'rxjs';
import { take } from 'rxjs/operators';
import { LANGUAGE_TOKENS } from '@klg/shared/i18n';
import { SchoolTypesEnum } from '@klg/shared/data-access/types';
import { ProductType, QuoteFormLinkUrls, QuoteOutput, QuoteProduct, StudentDetails } from '@klg/quote-tool/shared/types';

@Component({
  selector: 'kng-quote-print',
  templateUrl: './quote-print.component.html',
  styleUrls: ['./quote-print.component.scss'],
})
export class QuotePrintComponent implements OnInit, OnChanges, OnDestroy {
  @Input() quoteOutput: QuoteOutput;
  @Input() studentDetails: StudentDetails;
  @Input() currency: string;
  @Input() displayDestinationCurrency = false;
  @Input() infoDisclaimer: string;
  @Input() showDecimals = false;

  private schoolTypeTexts = {
    [SchoolTypesEnum.ADULT]: $localize`Adults`,
    [SchoolTypesEnum.JUNIOR]: $localize`Juniors`,
  };

  public decimalPlaces = 0;
  public today: Date = getToday();
  public KeyPointIcons = KeyPointIcons;
  public termsAndConditionsUrl: string;

  public languageName = '';
  public schoolTypeText = '';
  public products: QuoteProduct[] = [];
  public supplements: QuoteProduct[] = [];
  public keyPoints: KeyPoints = { included: [], notIncluded: [] };
  public headerImageSrc: string;

  private languageTokens = inject(LANGUAGE_TOKENS);

  private subscription = new Subscription();

  constructor(private urlsService: QuoteFormLinkUrlsService, private schoolService: ProductSchoolsService) {}

  ngOnInit() {
    this.languageName = this.languageTokens[this.quoteOutput?.input?.languageCode];
    this.subscription.add(
      this.urlsService.getLinkUrls$().subscribe((urls: QuoteFormLinkUrls) => (this.termsAndConditionsUrl = urls.termsAndConditionsLinkUrl)),
    );
  }

  ngOnChanges(changes: SimpleChanges) {
    if ('showDecimals' in changes) {
      this.decimalPlaces = this.showDecimals ? 2 : 0;
    }

    if ('quoteOutput' in changes) {
      this.updateQuoteRelatedData(changes.quoteOutput.previousValue?.input?.school);
    }
  }

  ngOnDestroy() {
    this.subscription.unsubscribe();
  }

  private updateQuoteRelatedData(previousSchool: string) {
    this.languageName = this.languageTokens[this.quoteOutput?.input?.languageCode] ?? '';
    this.schoolTypeText = this.schoolTypeTexts[this.quoteOutput?.input?.schoolType] ?? '';
    this.products = this.quoteOutput?.products?.filter((product) => product.classification !== ProductType.SUPPLEMENT) ?? [];
    this.supplements = this.quoteOutput?.products?.filter((product) => product.classification === ProductType.SUPPLEMENT) ?? [];
    this.keyPoints = getQuoteKeyPoints(this.quoteOutput);
    if (this.quoteOutput?.input?.school !== previousSchool) {
      this.schoolService
        .get(this.quoteOutput?.input?.school)
        .pipe(take(1))
        // tslint:disable-next-line:rxjs-prefer-angular-composition It only takes one and unsubscribe
        .subscribe((schoolData: School) => {
          this.headerImageSrc = schoolData?.thumbnailMedium;
        });
    }
  }
}
