export class FormData<T> {
  constructor(
    public value: T,
    private _validationFunction: (value: T) => boolean,
    public required: boolean = false,
    public errorMessage: string = '',
    public isDirty = false,
    public isValid: boolean,
    public group?: string,
    public requiredCallback: () => boolean = () => required,
  ) {}

  public validate(isDirty: boolean = true) {
    this.isDirty = isDirty;
    this.isValid = this.calculateIsValid();
  }

  public recalculateRequired() {
    this.required = this.requiredCallback();
  }

  public isFieldRequiredAndPristineAndEmpty(): boolean {
    return this.required && !this.isDirty && [null, undefined, ''].includes(this.value as unknown as string);
  }

  private calculateIsValid(): boolean {
    if ((this.value === null || this.value === undefined || !this.value) && !this.required) {
      return true;
    }
    return this.value !== null && this.value !== undefined && Boolean(this._validationFunction(this.value));
  }
}
