import { CountryRegion, ResidenceCountry } from '@klg/shared/data-access/destination';
import { Country, CourseType } from '@klg/shared/data-access/types';
import {
  AccommodationExtra,
  CourseSelectionDto,
  InsuranceExtra,
  PremiumActivitiesSelection,
  PremiumResidenceExtra,
  PrivateLessonsExtra,
  QuoteCamp,
  QuoteDiscounts,
  QuoteOnlineSchool,
  QuoteSchool,
  SchoolPackageDto,
  StudentDetails,
  TransferExtra,
} from '@klg/quote-tool/shared/types';
import { PremiumActivity } from '@klg/shared/data-access/school';
import { SchoolSupplement, Transfer } from '@klg/shared/data-access/products';
import { VirtualInternship } from '@klg/shared/data-access/products';
import { LimitedCurrencyAmount } from '@klg/currency/shared/model/currency.model';

export interface QuoteToolSlot {
  key: number;
  premiumResidencesAvailable?: boolean;
  virtualInternshipsAvailable?: boolean;
  language?: string;
  programType?: string;
  country?: string;
  school?: string;
  duration?: number;
  startDate?: string; //dd/mm/YYYY
  endDate?: string; //dd/mm/YYYY
  course?: string;
  courseType?: string;
  timezone?: string;
  startTime?: string;
  endTime?: string;
  accommodation?: AccommodationExtra;
  insurance?: InsuranceExtra;
  transfer?: TransferExtra;
  premiumActivities?: PremiumActivitiesSelection[];
  premiumResidence?: PremiumResidenceExtra;
  privateLessons?: PrivateLessonsExtra;
  virtualInternship?: VirtualInternship;
  discounts?: QuoteDiscounts[];
  discountTotal?: LimitedCurrencyAmount[];
  destinationCurrency?: string;
  marketCurrency?: string;
  grandTotal?: LimitedCurrencyAmount[];
  productTotal?: LimitedCurrencyAmount[];
}
export interface OptOutProduct {
  optOutProductCode: string;
  optOutProductInfo: string;
}

export interface QuoteToolState {
  slots: QuoteToolSlot[];
  studentDetails?: StudentDetails;
  selectedCountryOfResidence?: string;
  selectedRegionOfResidence?: string;
  countriesOfResidence?: ResidenceCountry[];
  regionsOfResidence?: CountryRegion[];
  destinationCountries?: Country[];
  dataLayerDestinationCountries?: Country[];
  camps?: QuoteCamp[];
  packages?: SchoolPackageDto[];
  schools?: QuoteSchool[];
  courses?: CourseSelectionDto[];
  courseTypes?: CourseType[];
  premiumActivities?: PremiumActivity[];
  transfers?: Transfer[];
  schoolSupplements?: SchoolSupplement[];
  onlineSchoolCode?: QuoteOnlineSchool;
  optOutProduct?: OptOutProduct;
}

export const defaultQuoteToolState: QuoteToolState = {
  slots: [{ key: 1 }],
};
