import { inject, Injectable } from '@angular/core';
import { combineLatest, mergeMap, of } from 'rxjs';
import {
  ExternalQuoteLeadUserData,
  FormType,
  QuoteInput,
  QuoteInputForm,
  QuoteLeadOrigin,
  QuoteLeadOriginEnum,
  QuoteRequest,
} from '@klg/quote-tool/shared/types';
import { CodeAndName } from '@klg/shared/types';
import { switchMap, tap } from 'rxjs/operators';
import { completeStudentDetailsForLeadEndpoint, QuoteService } from '@klg/quote-tool/shared/data-access/quote';
import { AnalyticsService, HostDataReaderService, StepService } from '@klg/quote-tool/shared/services';
import { QuoteInputFormMapper } from './quote-input-form.mapper';
import { QuoteToolDataLayerHashingService } from '@klg/quote-tool/shared/google-analytics';
import { GoogleAnalyticsEvents } from '@klg/shared/google-analytics';

@Injectable({
  providedIn: 'root',
})
export class FormLeadsService {
  private readonly hostDataReader = inject(HostDataReaderService);
  private readonly analyticsService = inject(AnalyticsService);
  private readonly quoteService = inject(QuoteService);
  private readonly stepService = inject(StepService);
  private readonly quoteInputFormMapper = inject(QuoteInputFormMapper);
  private readonly quoteToolDataLayerHashingService = inject(QuoteToolDataLayerHashingService);

  createLead(quoteId: string) {
    return this.stepService.getActiveQuoteInput().pipe(
      mergeMap((quoteInput: QuoteInput) => combineLatest([of(quoteInput), this.stepService.getLanguageCodeAndName$(quoteInput)])),
      mergeMap(([quoteInput, quoteLanguage]: [QuoteInput, CodeAndName]) => of(this.mapQuoteInputToLeadQuoteRequest(quoteInput, quoteLanguage.name))),
      switchMap((quoteRequest: QuoteRequest) => {
        const leadUserData = completeStudentDetailsForLeadEndpoint(
            this.stepService._studentDetails$.getValue(),
            this.stepService.getLanguage(),
            undefined,
            this.hostDataReader.getDrupalRoiData() as ExternalQuoteLeadUserData,
            quoteId,
          ),
          formOrigin: QuoteLeadOrigin = this.mapFormTypeToMapOrigin();
        const experimentId = this.analyticsService.getLeadsExperimentId();
        const datalayerJson = this.analyticsService.getDatalayerJson();

        return of({
          quoteRequest: [quoteRequest],
          leadUserData: {
            ...leadUserData,
            consentMarketing: !leadUserData.consentMarketing,
          },
          formOrigin,
          analyticsData: { experimentId, datalayerJson },
        });
      }),
      tap((leadData) => {
        const event = this.getDataLayerEvent(leadData.formOrigin);
        this.quoteToolDataLayerHashingService.trackEvent(event, leadData.leadUserData);
      }),
      switchMap((leadData) => this.quoteService.createLeadV2(leadData)),
    );
  }

  private mapFormTypeToMapOrigin(): QuoteLeadOrigin {
    const mapper: Map<FormType, QuoteLeadOrigin> = new Map([
      [FormType.ENROLLMENT, QuoteLeadOriginEnum.ENROLMENT],
      [FormType.FREE_QUOTE, QuoteLeadOriginEnum.QUICK_QUOTE],
      [FormType.PRICE_REQUEST, QuoteLeadOriginEnum.PRICE_REQUEST],
    ]);
    return mapper.get(this.stepService.getFormType());
  }

  private mapQuoteInputToLeadQuoteRequest(quoteInput: QuoteInput, courseLanguageName: string) {
    const quoteInputDto = this.quoteInputFormMapper.mapQuoteInputFormToQuoteRequestDto(<QuoteInputForm>quoteInput);
    Object.assign(quoteInputDto, {
      courseLanguage: courseLanguageName,
      exchangeCurrencyCode: quoteInput.exchangeCurrencyCode,
    });
    return quoteInputDto;
  }

  private getDataLayerEvent(formOrigin: string) {
    switch (formOrigin) {
      case QuoteLeadOriginEnum.ENROLMENT:
        return GoogleAnalyticsEvents.GENERATE_BOOK_NOW_ENROLMENT;
      case QuoteLeadOriginEnum.QUICK_QUOTE:
        return GoogleAnalyticsEvents.GENERATE_FREE_QUOTE_EMAIL;
      case QuoteLeadOriginEnum.PRICE_REQUEST:
        return GoogleAnalyticsEvents.GENERATE_PRICE_REQUEST_EMAIL;
      default:
        return undefined;
    }
  }
}
