import { Component, EventEmitter, HostBinding, Input, OnInit, Output } from '@angular/core';
import { DialogModule } from 'primeng/dialog';
import { NgForOf, NgIf, NgSwitchCase, NgTemplateOutlet } from '@angular/common';
import { CodeAndName, NumericCodeAndName } from '@klg/shared/types';
import { DropdownModule } from 'primeng/dropdown';
import { ListboxModule } from 'primeng/listbox';
import { FormsModule } from '@angular/forms';

@Component({
  selector: 'kng-dropdown-popup',
  standalone: true,
  imports: [DialogModule, NgForOf, NgSwitchCase, NgIf, NgTemplateOutlet, DropdownModule, ListboxModule, FormsModule],
  templateUrl: './dropdown-popup.component.html',
  styleUrls: ['./dropdown-popup.component.scss'],
})
export class DropdownPopupComponent<T extends (CodeAndName | NumericCodeAndName) & { [key: string]: any }, K extends keyof T> implements OnInit {
  @HostBinding('class') private hostClass: string | undefined;
  @Input() itemList: T[] = [];
  @Input() company = '';
  @Output() dropdownPopupClosed = new EventEmitter<void>();
  @Output() valueChange = new EventEmitter<T | T[K]>();
  isPopupOpen = true;

  ngOnInit(): void {
    this.hostClass = this.getCompanyClass();
  }

  closePopup() {
    this.isPopupOpen = false;
    this.dropdownPopupClosed.emit();
  }

  onItemClick(value: { value: T | T[K] | undefined }) {
    this.valueChange.emit(value.value);
    this.closePopup();
  }

  private getCompanyClass() {
    return `dropdown-popup--${this.company.toLowerCase()}`;
  }
}
