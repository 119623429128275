<!-- Language -->
<kng-quote-course-details-item class="quote-course-details__item">
  <ng-container label>{{ languageLabel }}</ng-container>
  <ng-container name *ngTemplateOutlet="nameNoName; context: { name: languageName, noName: 'No language selected' }"></ng-container>
</kng-quote-course-details-item>

<ng-container *ngIf="areQuoteTypesAdultAndOnline || !isOnlineFlow">
  <!-- School -->
  <kng-quote-course-details-item class="quote-course-details__item">
    <ng-container label>{{ schoolLabel }}</ng-container>
    <ng-container name *ngTemplateOutlet="nameNoName; context: { name: currentSchoolName, noName: 'No school selected' }"></ng-container>
  </kng-quote-course-details-item>

  <!-- Destination -->
  <kng-quote-course-details-item class="quote-course-details__item" *ngIf="destination">
    <ng-container label i18n>Destination</ng-container>
    <ng-container name *ngTemplateOutlet="nameNoName; context: { name: destination, noName: 'No destination selected' }"></ng-container>
  </kng-quote-course-details-item>
</ng-container>

<!-- Course -->
<kng-quote-course-details-item class="quote-course-details__item" [showEditButton]="isQuoteSlotExpanded" (editButtonClicked)="editCourseDetails()">
  <ng-container label>
    <ng-container *ngIf="(currentCourses?.length || 0) <= 1; else multiCourseLabel" i18n>Your Course</ng-container>
    <ng-template #multiCourseLabel i18n>Your Courses</ng-template>
  </ng-container>
  <ng-container name *ngIf="currentCourses?.length; else noCourseSelected">
    <ng-container *ngFor="let courseItem of currentCourses">
      <div *ngIf="courseItem.name; else noCourseSelected">{{ courseItem.name }}</div>
    </ng-container>
  </ng-container>
</kng-quote-course-details-item>
<ng-template #noCourseSelected>
  <ng-container i18n>No course selected</ng-container>
</ng-template>

<!-- Course duration -->
<kng-quote-course-details-item class="quote-course-details__item">
  <ng-container label>
    <ng-container *ngIf="currentCourses?.length <= 1; else multiCourseDurationLabel" i18n>{{ courseDurationLabel }}</ng-container>
    <ng-template #multiCourseDurationLabel i18n>Courses Duration</ng-template>
  </ng-container>
  <ng-container name *ngIf="currentCourses?.length; else noDurationSelected">
    <ng-container *ngFor="let course of currentCourses; let courseIndex = index">
      <div *ngIf="course?.startDate; else noDurationSelected">
        {{ course?.startDate | klgDate : dateFormat.FULL_MONTH_NAME }}
        <span *ngIf="course.endDate"> - {{ course.endDate | klgDate : dateFormat.FULL_MONTH_NAME }}</span>
        <span *ngIf="!course.endDate && course.weeks"> - {{ course.weeks | klgLocalizeAndPluralizePeriod }}</span>
      </div>
      <div class="quote-course-details__vacations" *ngIf="quote?.products && quote?.products[courseIndex]?.vacations?.length">
        {{ quote?.products[courseIndex]?.vacations | klgClosingDates }}
      </div>
    </ng-container>
  </ng-container>
</kng-quote-course-details-item>
<ng-template #noDurationSelected>
  <ng-container i18n>No duration selected</ng-container>
</ng-template>

<!-- Class time -->
<kng-quote-course-details-item
  class="quote-course-details__item"
  *ngIf="areQuoteTypesAdultAndOnline || isOnlineFlow"
  [showEditButton]="isQuoteSlotExpanded && isOnlineFlow"
  (editButtonClicked)="editCourseDetails()"
>
  <ng-container label i18n>Class Time</ng-container>
  <ng-container name *ngIf="quoteType !== AgeGroups.Online" [ngTemplateOutlet]="nameNoName" [ngTemplateOutletContext]="{ noName: NA }"></ng-container>
  <ng-container name *ngFor="let onlineClass of quote?.input?.onlineClassCombo?.onlineClasses">
    <div>
      {{ onlineClass.startTime | offsetTime : timezoneOffset }} - {{ onlineClass.endTime | offsetTime : timezoneOffset }} {{ onlineClass.className }} ({{
        formattedTimezoneOffset
      }})
    </div>
  </ng-container>
</kng-quote-course-details-item>

<ng-template #nameNoName let-name="name" let-noName="noName">
  <ng-container *ngIf="name" i18n>{{ name }}</ng-container>
  <ng-container *ngIf="!name" i18n>{{ noName }}</ng-container>
</ng-template>
