import { Component, EventEmitter, forwardRef, inject, Input, OnChanges, OnDestroy, OnInit, Output, SimpleChanges, ViewChild } from '@angular/core';
import { ControlValueAccessor, FormsModule, NG_VALUE_ACCESSOR } from '@angular/forms';
import { Calendar, CalendarModule } from 'primeng/calendar';
import { BehaviorSubject, distinctUntilChanged, fromEvent, map, Observable, shareReplay, startWith, Subscription } from 'rxjs';

import { DropdownModule } from 'primeng/dropdown';
import { CommonModule } from '@angular/common';
import { MAX_NUMBER_OF_YEARS } from './calendar-field.constants';
import { deepClone, getDateFromString, getDateStringFromDate, getToday } from '@klg/shared/utils';
import { DateFormat, DateFormatService } from '@klg/shared/i18n';
import { CalendarFieldDate, CalendarFieldMonthAndYear, DropdownMonth, SCROLL_HEIGHT_COMMON_DEVICES, SCROLL_HEIGHT_SMALL_DEVICES } from './calendar.types';

@Component({
  standalone: true,
  selector: 'kng-calendar-field',
  templateUrl: './calendar-field.component.html',
  styleUrls: ['./calendar-field.component.scss'],
  imports: [CalendarModule, FormsModule, DropdownModule, CommonModule],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => CalendarFieldComponent),
      multi: true,
    },
  ],
})
export class CalendarFieldComponent implements ControlValueAccessor, OnChanges, OnInit, OnDestroy {
  @ViewChild('calendar') calendar: Calendar | undefined;
  @Input() label: string | undefined;
  @Input() placeholder = '';
  @Input() required = false;
  @Input() isValid: boolean | undefined;
  @Input() dateValue: string | undefined;
  @Input() dateObject: Date | undefined;
  @Input() defaultDate = getToday();
  @Input() minDate: Date | undefined;
  @Input() maxDate: Date | undefined;
  @Input() disabledDates: Date[] | undefined;
  @Input() disabledDays: number[] | undefined;
  @Input() showCalendarIcon: boolean | undefined;
  @Input() yearRange: string | undefined;
  @Input() googleAnalyticsClickEvent: string | undefined;
  @Input() inline = false;
  @Input() mobileMode = false;
  @Input() footerText = '';
  @Output() calendarShown = new EventEmitter<CalendarFieldDate>();
  @Output() monthChanged = new EventEmitter<CalendarFieldMonthAndYear>();
  @Output() datePickerFocused = new EventEmitter<void>();
  @Output() selectedDateChange = new EventEmitter<void>();
  dateFormat = 'd M yy';
  today = getToday();
  pickedDate: CalendarFieldDate = {} as CalendarFieldDate;
  monthScrollHeight = SCROLL_HEIGHT_COMMON_DEVICES;
  selectedDate$ = new BehaviorSubject<Date>(getToday());
  selectedDate = new Date();
  years: number[] = [];
  months: DropdownMonth[] = [];
  selectedYear: number | undefined;
  selectedMonth: DropdownMonth | undefined;
  nextMonthDisabled = false;
  previousMonthDisabled = false;
  value: Date | null = null;
  disabled = false;

  private subscription: Subscription = new Subscription();

  // Observer to check if the screen is short (less than 600px of height)
  private readonly shortScreen$: Observable<boolean> = fromEvent(window, 'resize').pipe(
    startWith(window.matchMedia(`(max-height: ${SCROLL_HEIGHT_COMMON_DEVICES})`).matches),
    map(() => {
      return window.matchMedia(`(max-height: ${SCROLL_HEIGHT_COMMON_DEVICES})`).matches;
    }),
    distinctUntilChanged(),
    shareReplay(1),
  );

  private readonly dateFormatService = inject(DateFormatService);

  ngOnInit(): void {
    this.dateFormat = this.dateFormatService.getDateFormatSettings()[DateFormat.SHORT_MONTH_NAME_PRIME_NG];
    this.prepareYears();
    this.prepareMonths();

    this.subscription.add(
      this.shortScreen$.subscribe((shortScreen) => {
        // If screen is short, reduce the size of the month dropdown scroll height
        if (shortScreen) {
          this.monthScrollHeight = SCROLL_HEIGHT_SMALL_DEVICES;
        } else {
          this.monthScrollHeight = SCROLL_HEIGHT_COMMON_DEVICES;
        }
      }),
    );

    this.subscription.add(
      this.selectedDate$.subscribe((date) => {
        // Get selected date, by default this will be today and set selectedMonth and selectedYear
        this.selectedDate = date || this.today;
        this.selectedMonth = this.months.find((month) => month.month === this.selectedDate.getMonth());
        this.selectedYear = this.selectedDate.getFullYear();
      }),
    );
  }

  ngOnChanges({ dateValue, dateObject, defaultDate }: SimpleChanges): void {
    if (dateValue) {
      this.pickedDate = {
        dateString: dateValue.currentValue,
        dateObject: getDateFromString(dateValue.currentValue),
      };
    }

    if (dateObject) {
      this.pickedDate = {
        dateString: getDateStringFromDate(dateObject.currentValue),
        dateObject: deepClone(dateObject.currentValue),
      };
      this.selectedDate$.next(this.pickedDate.dateObject);
    }

    if (defaultDate) {
      this.selectedDate$.next(new Date(defaultDate?.currentValue) || this.today);
    }
  }

  ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }

  /**
   * Prepare the calendar before being open.
   * This means:
   * - to add to the calendar the data-title property with the placeholder or label
   * - to set the selected year and month
   * - to emit the dateChanged event with the picked date to have all disabled dates properly calculated with
   * the change of selected year and month
   */
  prepareCalendar() {
    const calendarElement = this.calendar?.el.nativeElement;
    const querySelector = calendarElement.querySelector('.p-datepicker');
    if (querySelector) {
      querySelector.dataset.title = this.placeholder || this.label;
    }
    if (this.pickedDate.dateObject) {
      this.selectedYear = this.pickedDate.dateObject.getFullYear();
      this.selectedMonth = this.months.find((month) => month.month === this.pickedDate.dateObject.getMonth());
      this.calendarShown.emit(this.pickedDate);
    }
  }

  checkIsValid() {
    return this.isValid ?? (this.pickedDate?.dateObject ? true : null);
  }

  /**
   * Updates the selected date with selected year and month.
   * This means that monthChanged event is emitted with the selected month and year and
   * the navigation buttons state is calculated to know if they are disabled or not.
   */
  updateSelectedDate() {
    this.selectedDate$.next(new Date(Number(this.selectedYear), Number(this.selectedMonth?.month), 1));
    // Emits the event with selectedMonth + 1 because the month is 0 based
    this.monthChanged.emit({ month: Number(this.selectedMonth?.month) + 1, year: Number(this.selectedYear) });
    this.calculateNavigationButtonsState();
  }

  navigateToNextMonth() {
    const currentMonthIndex = this.months.findIndex(({ month }) => month === Number(this.selectedMonth?.month));
    if (currentMonthIndex === 11 && this.selectedYear) {
      this.selectedYear++;
      this.selectedMonth = this.months[0];
    } else {
      this.selectedMonth = this.months[currentMonthIndex + 1];
    }

    this.updateSelectedDate();
  }

  navigateToPreviousMonth() {
    const currentMonthIndex = this.months.findIndex(({ month }) => month === this.selectedMonth?.month);
    if (currentMonthIndex === 0 && this.selectedYear) {
      this.selectedYear--;
      this.selectedMonth = this.months[11];
    } else {
      this.selectedMonth = this.months[currentMonthIndex - 1];
    }

    this.updateSelectedDate();
  }

  openCalendar() {
    // Defer execution to ensure DOM changes are applied.
    setTimeout(() => {
      const inputElement: HTMLInputElement = this.calendar?.inputfieldViewChild?.nativeElement;
      inputElement?.click();
    }, 0);
  }

  onValueChanges(selectedDate: Date) {
    this.selectValue(selectedDate);
  }

  writeValue(value: Date): void {
    this.value = value;
  }

  registerOnChange(fn: any): void {
    this.onChange = fn;
  }

  registerOnTouched(fn: any): void {
    this.onTouched = fn;
  }

  setDisabledState(disabled: boolean): void {
    this.disabled = disabled;
  }

  selectValue(optionValue: Date): void {
    this.value = optionValue;
    this.onChange(optionValue);
    this.onTouched();
  }

  private prepareMonths() {
    this.months = (DateFormatService.getCalendarLocaleSettings().monthNames ?? []).map((month, index) => ({ label: month, month: index }));
  }

  private prepareYears() {
    this.years = Array.from({ length: MAX_NUMBER_OF_YEARS }, (_, i) => this.today.getFullYear() + i);
  }

  /**
   * Calculates the state of next and previous months navigation buttons.
   * If the selected month and year are the last ones, the next month button is disabled.
   * If the selected month and year are the first ones, the previous month button is disabled.
   */
  private calculateNavigationButtonsState() {
    this.previousMonthDisabled =
      this.months.findIndex(({ month }) => month === Number(this.selectedMonth?.month)) === 0 &&
      this.years.findIndex((year) => year === this.selectedYear) === 0;

    this.nextMonthDisabled =
      this.months.findIndex(({ month }) => month === Number(this.selectedMonth?.month)) === 11 &&
      this.years.findIndex((year) => year === this.selectedYear) === this.years.length - 1;
  }

  private onChange: (value: Date) => void = () => {
    // onChange triggered but not registered.
  };

  private onTouched: () => void = () => {
    // onTouched triggered but not registered.'
  };
}
