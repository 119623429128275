export const SCROLL_HEIGHT_COMMON_DEVICES = '600px';
export const SCROLL_HEIGHT_SMALL_DEVICES = '300px';

export interface DropdownMonth {
  label: string;
  month: number;
}

export interface DatePickerFieldDate {
  dateString: string;
  dateObject: Date;
}

export interface DatePickerFieldMonthAndYear {
  month: number;
  year: number;
}
