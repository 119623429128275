import { Component, OnDestroy, OnInit } from '@angular/core';
import { QuoteInput, StudentDetails } from '@klg/quote-tool/shared/types/quote';
import { FormType } from '@klg/quote-tool/shared/types';
import { SchoolTypes } from '@klg/shared/data-access/types';
import { BehaviorSubject, combineLatest, Subscription } from 'rxjs';
import { debounceTime, tap } from 'rxjs/operators';
import { StepService } from '@klg/quote-tool/shared/services/step-service';
import { tellUsAboutYouSectionIndex } from '../../../shared/functions/section-index.functions';
import { isStepBookingFormValid } from '../../../shared/functions/step-validation.function';

@Component({
  selector: 'kng-booking-form-step',
  templateUrl: './booking-form.component.html',
  styleUrls: ['./booking-form.component.scss'],
})
export class BookingFormComponent implements OnInit, OnDestroy {
  public schoolType: SchoolTypes;
  public formType: FormType;

  public tellUsAboutYouSectionIndex: number;
  private isStepValid$ = new BehaviorSubject(false);

  private subscriptions: Subscription = new Subscription();
  constructor(private readonly stepService: StepService) {}

  ngOnInit(): void {
    this.stepService.setStepValid(this.isStepValid$);
    this.formType = this.stepService.getFormType();
    this.subscriptions.add(
      combineLatest([this.stepService.quoteRequest$, this.stepService.studentDetails$])
        .pipe(
          debounceTime(160),
          tap(([quoteInput]) => {
            this.schoolType = quoteInput.schoolType;
            this.tellUsAboutYouSectionIndex = tellUsAboutYouSectionIndex(this.formType, quoteInput.hasSupplementsAvailable);
          }),
        )
        .subscribe(([quoteInput, studentDetails]: [QuoteInput, StudentDetails]) => {
          this.isStepValid$.next(isStepBookingFormValid(quoteInput, studentDetails));
        }),
    );
  }

  ngOnDestroy(): void {
    this.subscriptions.unsubscribe();
  }
}
