import { QuoteSchool } from '@klg/quote-tool/shared/types';

const schoolHasLanguage = (school: QuoteSchool, languageCode: string): boolean =>
  school.courseLanguages.some((schoolLanguage) => schoolLanguage.code === languageCode);

function filterSchools(allSchools: QuoteSchool[], languageCode?: string, countryCode?: string, cityCode?: string) {
  return allSchools.filter(
    (school: QuoteSchool) =>
      (!languageCode || schoolHasLanguage(school, languageCode)) && (!countryCode || school.country === countryCode) && (!cityCode || school.city === cityCode),
  );
}

export function getAvailableCourseTypes(allSchools: QuoteSchool[], languageCode?: string, countryCode?: string, cityCode?: string): string[] {
  return filterSchools(allSchools, languageCode, countryCode, cityCode).reduce((acc: string[], { courseTypes }) => {
    if (!courseTypes) {
      return acc;
    }
    return [...acc, ...courseTypes.filter((courseType) => !acc.includes(courseType))];
  }, []);
}
