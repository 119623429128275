import { Component, EventEmitter, HostBinding, Input, Output } from '@angular/core';
import { getCompany } from '@klg/shared/tokens';

@Component({
  selector: 'kng-drawer',
  templateUrl: './drawer.component.html',
  styleUrls: ['./drawer.component.scss'],
})
export class DrawerComponent {
  // Drawer title
  @Input() title: string;

  // Whether to show the drawer or not
  @Input() active: boolean;

  // Whether to show footer buttons
  @Input() showDrawerButtons: boolean | undefined = true;

  @Output() toggleDrawer: EventEmitter<void> = new EventEmitter<void>();
  @Output() clickApplyButton: EventEmitter<void> = new EventEmitter<void>();
  @Output() clickResetButton: EventEmitter<void> = new EventEmitter<void>();

  private readonly company = getCompany();

  @HostBinding('class') hostClass = `kng-drawer kng-drawer--${this.company}`;

  onClickToggleDrawer($event): void {
    $event.event.stopPropagation();
    this.clickToggle();
  }

  clickToggle() {
    this.toggleDrawer.emit();
  }

  onClickReset(): void {
    this.clickResetButton.emit();
  }

  onClickApply(): void {
    this.clickApplyButton.emit();
  }
}
