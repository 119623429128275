import { Component, EventEmitter, Output } from '@angular/core';
import { FormFieldSelectorComponent } from '@klg/quote-tool/shared/ui/components';
import { NumericCodeAndName } from '@klg/shared/types';
import { HostFamilyOptions } from '@klg/quote-tool/shared/types';

@Component({
  standalone: true,
  selector: 'kng-host-family-selection',
  templateUrl: './host-family-selection.component.html',
  styleUrls: ['./host-family-selection.component.scss'],
  imports: [FormFieldSelectorComponent],
})
export class HostFamilySelectionComponent {
  // default to undefined, which is the same as "Don't mind"
  isNonSmokingFamily: boolean | undefined;
  hasChildrenFamily: boolean | undefined;
  hasPetsFamily: boolean | undefined;
  hasStudentsFamily: boolean | undefined;

  @Output() valueChanges = new EventEmitter<HostFamilyOptions>();

  hostFamilyOptions: NumericCodeAndName[] = [
    { code: 1, name: $localize`Yes` }, // 1 is true
    { code: 2, name: $localize`No` }, // 2 is false
    { code: 3, name: $localize`Don't mind` }, // 3 is undefined
  ];

  placeholderText = this.hostFamilyOptions[2]?.name;

  /**
   * Handles the change of the host family options.
   *
   * It will update only the field that has changed and emit the complete new value for all the options.
   * @param key the key of the changed field
   * @param $event the new value
   */
  onItemChange(key: keyof HostFamilyOptions, $event: NumericCodeAndName) {
    // 3 must be undefined (don't mind == undefined) and then 1 is true, and 2 is false
    const value = $event.code === 3 ? undefined : $event.code === 1;

    // update the actual field
    switch (key) {
      case 'isNonSmokingFamily':
        this.isNonSmokingFamily = value;
        break;
      case 'hasChildrenFamily':
        this.hasChildrenFamily = value;
        break;
      case 'hasPetsFamily':
        this.hasPetsFamily = value;
        break;
      case 'hasStudentsFamily':
        this.hasStudentsFamily = value;
        break;
    }

    // get emit the complete HostFamilyOptions instead of each field
    this.valueChanges.emit({
      isNonSmokingFamily: this.isNonSmokingFamily,
      hasChildrenFamily: this.hasChildrenFamily,
      hasPetsFamily: this.hasPetsFamily,
      hasStudentsFamily: this.hasStudentsFamily,
    });
  }
}
