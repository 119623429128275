import { ErrorHandler, inject, Injectable } from '@angular/core';
import { ErrorNotificationService } from '@klg/shared/logger';
import { GoogleAnalyticsErrorsService } from '@klg/shared/google-analytics';

@Injectable()
export class GlobalErrorHandler implements ErrorHandler {
  private readonly errorNotificationService = inject(ErrorNotificationService);
  private readonly googleAnalyticsErrorsService = inject(GoogleAnalyticsErrorsService);

  handleError(error) {
    if (error.message?.includes('Http')) {
      // The api errors are treated on HttpErrorInterceptor
      return;
    }

    // Track the error in Google Analytics
    this.googleAnalyticsErrorsService.trackEvent(error?.message);

    // Notify the error
    this.errorNotificationService.notifyError(
      $localize`Error`,
      $localize`Oh no! We found an error. Please try again or feel free to contact one of our sales advisors.`,
      error,
    );
  }
}
