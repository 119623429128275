import { OnlineClassCombo } from '@klg/quote-tool/shared/types/course';
import { convertTimeWIthOffsetToString, getDateStringFromDate, getToday } from '@klg/shared/utils';
import {
  ExternalQuoteLeadUserData,
  LeadEntityCodeAndNames,
  QuoteLeadAnalyticsData,
  QuoteLeadInputV4,
  QuoteLeadOrigin,
  QuoteLeadQuoteInput,
  QuoteOutput,
  ResidenceData,
  SlottedArray,
  StudentDetails,
} from '@klg/quote-tool/shared/types';
import { mapQuoteInputV2 } from './quote-input.functions';

export function mapQuoteOutputToQuoteEmailQuoteApiDto(
  quoteOutput: QuoteOutput,
  { language, school, course } = {} as LeadEntityCodeAndNames,
  countryOfResidence: string,
  selectedCurrency: string,
): QuoteLeadQuoteInput {
  if (!quoteOutput) {
    return null;
  }
  const { input } = quoteOutput;
  return {
    headerData: {
      language: language.name,
      school: school.name,
      course: course.name,
      startDate: input.startDate,
      endDate: input.endDate,
      selectedCurrency: selectedCurrency,
      destinationCurrency: input.destinationCurrency,
      bookingDate: getDateStringFromDate(getToday()),
      duration: input.tuitionWeeks,
      classTimes: mapOnlineClassTimes(input.onlineClassCombo),
      userClassTimes: mapOnlineUserClassTimes(input.onlineClassCombo),
    },
    quoteInput: { ...mapQuoteInputV2(input, countryOfResidence), quoteId: quoteOutput.quoteId },
  };
}

function mapOnlineClassTimes(onlineClassCombo: OnlineClassCombo): string[] {
  if (!onlineClassCombo) {
    return [];
  }
  const { onlineClasses } = onlineClassCombo;
  return onlineClasses.map((onlineClass) => {
    return `${onlineClass.startTime} - ${onlineClass.endTime} ${onlineClass.className} (UTC)`;
  });
}

function mapOnlineUserClassTimes(onlineClassCombo: OnlineClassCombo): string[] {
  if (!onlineClassCombo) {
    return [];
  }
  const { formattedTimezoneOffset, onlineClasses, timezoneOffset } = onlineClassCombo;
  return onlineClasses.map((onlineClass) => {
    const startTime = convertTimeWIthOffsetToString(onlineClass.startTime, timezoneOffset);
    const endTime = convertTimeWIthOffsetToString(onlineClass.endTime, timezoneOffset);
    return `${startTime} - ${endTime} ${onlineClass.className} (${formattedTimezoneOffset})`;
  });
}

export function completeStudentDetailsForLeadEndpoint(
  studentDetails: Partial<StudentDetails>,
  language: string,
  countryOfResidence?: string,
  roiTrackerData?: ExternalQuoteLeadUserData,
  quoteId?: string,
): StudentDetails {
  return {
    title: studentDetails.title || null,
    quoteId: quoteId || null,
    identityDocument: studentDetails.identityDocument || '',
    firstName: studentDetails.firstName || '',
    lastName: studentDetails.lastName || '',
    guardianFirstName: studentDetails.guardianFirstName || '',
    guardianLastName: studentDetails.guardianLastName || '',
    email: studentDetails.email || '',
    phoneNumber: studentDetails.phoneNumber || '',
    address: studentDetails.address || '',
    city: studentDetails.city || '',
    postcode: studentDetails.postcode || '',
    countryOfResidence: studentDetails.countryOfResidence || countryOfResidence || '',
    consentResellers: studentDetails.consentResellers ?? false,
    consentMarketing: studentDetails.consentMarketing ?? false,
    websiteLanguage: language.toUpperCase(),
    displayPhone: !!studentDetails.phoneNumber,
    dateOfBirth: studentDetails.dateOfBirth,
    placeOfBirth: studentDetails.placeOfBirth,
    nationality: studentDetails.nationality,
    motherTongue: studentDetails.motherTongue,
    selectedInsuranceOptions: studentDetails.selectedInsuranceOptions,
    duringYourStay: studentDetails.duringYourStay,
    specialRequests: studentDetails.specialRequests,
    ...roiTrackerData,
  };
}

function handleStudentDetailsNullValues(studentDetails: Partial<StudentDetails>): Partial<StudentDetails> {
  const studentDetailsResult = {
    ...studentDetails,
    firstName: studentDetails.firstName || '',
    lastName: studentDetails.lastName || '',
    guardianFirstName: studentDetails.guardianFirstName || '',
    guardianLastName: studentDetails.guardianLastName || '',
    phoneNumber: studentDetails.phoneNumber || '',
    email: studentDetails.email || '',
    consentMarketing: studentDetails.consentMarketing || false,
    consentResellers: studentDetails.consentResellers || false,
    dateOfBirth: studentDetails.dateOfBirth || '',
  };
  if (!studentDetailsResult.hasAllergiesConditionsOrTreatments) {
    delete studentDetailsResult['allergiesConditionsOrTreatments'];
  }
  if (studentDetailsResult.isMinor) {
    delete studentDetailsResult['emergencyContactLastName'];
    delete studentDetailsResult['emergencyContactFirstName'];
    delete studentDetailsResult['emergencyContactEmail'];
    delete studentDetailsResult['emergencyContactPhone'];
  } else {
    delete studentDetailsResult['guardianLastName'];
    delete studentDetailsResult['guardianFirstName'];
    delete studentDetailsResult['guardianEmail'];
    delete studentDetailsResult['guardianPhone'];
  }
  return studentDetailsResult;
}

export function mapQuoteSlotsToQuoteEmailInputApiDto(
  quoteOutputs: SlottedArray<QuoteOutput>,
  studentDetails: Partial<StudentDetails>,
  names: SlottedArray<LeadEntityCodeAndNames>,
  language: string,
  residenceData: ResidenceData,
  analyticsData: QuoteLeadAnalyticsData,
  selectedCurrency: string,
): QuoteLeadInputV4 {
  const leadUserDataWithoutNullValues = handleStudentDetailsNullValues(studentDetails);
  return {
    leadUserData: {
      ...leadUserDataWithoutNullValues,
      consentMarketing: !leadUserDataWithoutNullValues.consentMarketing,
      countryOfResidence: residenceData?.countryOfResidence,
      regionCode: residenceData?.regionOfResidence ?? '',
      websiteLanguage: language,
    },
    quotes: quoteOutputs
      .filter((quoteOutput) => quoteOutput && Object.keys(quoteOutput).length)
      .map((q, i) => mapQuoteOutputToQuoteEmailQuoteApiDto(q, names[i], residenceData?.countryOfResidence, selectedCurrency)),
    analyticsData: analyticsData,
  };
}

export function mapQuoteToQuotePaymentApiDto(
  quote: QuoteOutput,
  studentDetails: Partial<StudentDetails>,
  codeAndNames: LeadEntityCodeAndNames,
  language: string,
  residenceData: ResidenceData,
  formOrigin: QuoteLeadOrigin,
  analyticsData: QuoteLeadAnalyticsData,
  selectedCurrency: string,
): QuoteLeadInputV4 {
  const quoteLeadInputV4ApiDto = mapQuoteSlotsToQuoteEmailInputApiDto(
    [quote, null, null],
    studentDetails,
    [codeAndNames, null, null],
    language,
    residenceData,
    analyticsData,
    selectedCurrency,
  );

  quoteLeadInputV4ApiDto.leadUserData.quoteId = quote.quoteId;
  quoteLeadInputV4ApiDto.leadUserData.dateOfBirth = getDateStringFromDate(new Date(studentDetails?.dateOfBirth));

  quoteLeadInputV4ApiDto.formOrigin = formOrigin;

  return quoteLeadInputV4ApiDto;
}
