import { inject, Injectable } from '@angular/core';
import { CookieService } from 'ngx-cookie-service';
import { PRICE_REQUEST_EXPERIMENT_B, PRICE_REQUEST_EXPERIMENT_C } from './experiment-id.constants';

@Injectable({
  providedIn: 'root',
})
export class PriceRequestExperimentService {
  private readonly cookieService = inject(CookieService);

  /**
   * Check if any of price request experiments are active
   */
  isPriceRequestExperimentActive() {
    return this.isPriceRequestExperimentBActive() || this.isPriceRequestExperimentCActive();
  }

  /**
   * Check if price request experiment B is active
   */
  isPriceRequestExperimentBActive() {
    return this.cookieService.check(PRICE_REQUEST_EXPERIMENT_B) && this.cookieService.get(PRICE_REQUEST_EXPERIMENT_B) === 'true';
  }

  /**
   * Check if price request experiment C is active
   */
  isPriceRequestExperimentCActive() {
    return this.cookieService.check(PRICE_REQUEST_EXPERIMENT_C) && this.cookieService.get(PRICE_REQUEST_EXPERIMENT_C) === 'true';
  }
}
