import { inject, Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import { QuoteInputForm, QuoteLeadOriginEnum, QuoteLeadOutput, QuoteOutput, QuoteRequest } from '@klg/quote-tool/shared/types';
import { map, switchMap } from 'rxjs/operators';
import { QuoteService } from '@klg/quote-tool/shared/data-access/quote';
import { Language, LanguageService } from '@klg/language';
import { Clipboard } from '@angular/cdk/clipboard';
import { MessageService } from 'primeng/api';
import { INFO_TOAST_KEY } from '../constants/toast.constants';
// eslint-disable-next-line @nrwl/nx/enforce-module-boundaries
import { QuoteInputFormMapper } from '@klg/quote-tool/form';

@Injectable({
  providedIn: 'root',
})
export class ArgoService {
  private readonly languageService = inject(LanguageService);
  private readonly quoteService = inject(QuoteService);
  private readonly quoteInputFormMapper = inject(QuoteInputFormMapper);

  private readonly clipboard = inject(Clipboard);
  private readonly messageService = inject(MessageService);

  sendLeadToArgo(quoteOutput: QuoteOutput, orderId?: number): Observable<number> {
    const {
      input,
      input: { exchangeCurrencyCode, languageCode },
      quoteId,
    } = quoteOutput;
    return this.languageService.getByCode(languageCode).pipe(
      map(({ name: courseLanguage }: Language) => {
        return {
          ...this.quoteInputFormMapper.mapQuoteInputFormToQuoteRequestDto(<QuoteInputForm>input),
          courseLanguage,
          exchangeCurrencyCode,
        };
      }),
      switchMap((quoteRequest: QuoteRequest) => {
        return this.quoteService.createLeadV2({
          id: orderId,
          quoteRequest: [quoteRequest],
          leadUserData: { quoteId },
          formOrigin: QuoteLeadOriginEnum.ARGO_QUICK_QUOTE_TOOL,
        });
      }),
      switchMap((quoteLeadOutput: QuoteLeadOutput) => {
        return of(parseInt(quoteLeadOutput?.message));
      }),
    );
  }

  copyIdToClipboard(argoId: string) {
    const successfullyOperation = this.clipboard.copy(argoId);
    if (successfullyOperation) {
      this.messageService.add({
        severity: 'success',
        key: INFO_TOAST_KEY,
        summary: $localize`Successfully copied to clipboard`,
        detail: $localize`ARGO ID: ${argoId}`,
      });
    }
  }
}
