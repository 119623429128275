import { Component, ElementRef, EventEmitter, Input, Output } from '@angular/core';
import { Country } from '@klg/shared/data-access/types';
import { GoogleAnalyticsEvents } from '@klg/shared/google-analytics';

@Component({
  selector: 'kng-destination-country-enhanced-summary',
  templateUrl: './destination-country-enhanced-summary.component.html',
  styleUrls: ['./destination-country-enhanced-summary.component.scss'],
})
export class DestinationCountryEnhancedSummaryComponent {
  @Input() country: Country;
  @Input() showDetails: boolean;
  @Input() showMoreDetailsIcon: boolean;
  @Input() flagIcon: string;
  @Output() itemSelected = new EventEmitter<Country>();
  @Output() moreDetailsClicked = new EventEmitter<void>();
  @Output() showOfferPopup = new EventEmitter<Country>();

  openPopup = false;
  readonly GoogleAnalyticsEvents = GoogleAnalyticsEvents;

  constructor(public elRef: ElementRef) {}

  showPromotionPopup(country: Country) {
    this.showOfferPopup.emit(country);
  }
}
