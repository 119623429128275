<kng-form-field-selector
  [value]="value"
  [label]="label"
  valueExpr="code"
  [autofill]="autofill"
  [itemList]="weeksOptions"
  [disabled]="disabled"
  [required]="required"
  [placeholder]="placeholder"
  (valueChange)="valueChange.emit($event)"
  (itemChange)="itemChange.emit($event)"
></kng-form-field-selector>
