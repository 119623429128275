<kng-loading-spinner i18n-message message="Loading..." [loading]="loading" shadingColor="rgba(0,0,0,0.5)"></kng-loading-spinner>

<kng-section-template [columns]="columns" [sectionTitle]="title">
  <div class="price-request-course-form__form-group">
    <div class="price-request-course-form__form-group__label">
      <span i18n>Language</span><span class="price-request-course-form__form-group__label__required">*</span>
    </div>
    <kng-language-selector
      [buttonView]="false"
      [value]="(quoteInput$ | async)?.languageCode"
      [required]="true"
      (valueChange)="processSelectedLanguageChange($event)"
      data-qa="qt-language-selector"
    ></kng-language-selector>
  </div>
  <div class="price-request-course-form__form-group">
    <div class="price-request-course-form__form-group__label">
      <span i18n>Country</span><span class="price-request-course-form__form-group__label__required">*</span>
    </div>
    <kng-destination-country-selector
      [autofill]="!!(quoteInput$ | async)?.languageCode"
      [disabled]="!(quoteInput$ | async)?.languageCode"
      [buttonView]="false"
      [countries]="availableCountries"
      [value]="(quoteInput$ | async)?.countryCode"
      [required]="true"
      (itemChange)="processSelectedCountryChanged($event)"
      data-qa="qt-country-selector"
    ></kng-destination-country-selector>
  </div>

  <div class="price-request-course-form__form-group">
    <div class="price-request-course-form__form-group__label"><span i18n>City</span></div>
    <kng-city-selector
      [autofill]="!!(quoteInput$ | async)?.countryCode"
      [disabled]="!(quoteInput$ | async)?.countryCode"
      [buttonView]="false"
      [availableCities]="availableCities"
      [value]="(quoteInput$ | async)?.destinationCity?.code"
      [countryCode]="(quoteInput$ | async)?.countryCode"
      [required]="false"
      (itemChange)="processSelectedDestinationCityChanged($event)"
      data-qa="qt-city-selector"
    ></kng-city-selector>
  </div>

  <div class="price-request-course-form__form-group">
    <div class="price-request-course-form__form-group__label">
      <span i18n>Program</span><span class="price-request-course-form__form-group__label__required">*</span>
    </div>
    <kng-course-type-selector
      class="qt-form-section__start-new-line"
      [disabled]="!(quoteInput$ | async)?.countryCode"
      [codes]="availableCourseTypes"
      [buttonView]="false"
      [value]="(quoteInput$ | async)?.courseType"
      [required]="true"
      [ageGroup]="ageGroup"
      (itemChange)="processSelectedCourseTypeChanged($event)"
      [attr.data-qa]="'qt-course-type-selector'"
    ></kng-course-type-selector>
  </div>
</kng-section-template>

<kng-section-template [columns]="2">
  <div class="price-request-course-form__form-group">
    <div class="price-request-course-form__form-group__label">
      <span i18n>Duration</span><span class="price-request-course-form__form-group__label__required">*</span>
    </div>
    <kng-weeks-selector
      [disabled]="!(quoteInput$ | async)?.courseType"
      [value]="(quoteInput$ | async)?.tuitionWeeks"
      [availableWeeksValues]="availableWeeks"
      [required]="true"
      (valueChange)="processSelectedDurationChanged($event)"
      [attr.data-qa]="'qt-duration-field'"
    ></kng-weeks-selector>
  </div>
  <div>
    <div class="price-request-course-form__form-group__label">
      <span i18n>Start date</span><span class="price-request-course-form__form-group__label__required">*</span>
    </div>
    <kng-date-picker-field
      i18n-placeholder
      placeholder="— Please select  —"
      [disabled]="!(quoteInput$ | async)?.tuitionWeeks || !(quoteInput$ | async)?.courseType"
      [dateValue]="(quoteInput$ | async)?.startDate"
      [minDate]="minDate"
      [showCalendarIcon]="true"
      [required]="true"
      (dateChanged)="processSelectedStartDateChange($event)"
      [attr.data-qa]="'qt-start-date-field'"
    ></kng-date-picker-field>
  </div>
</kng-section-template>

<kng-section-template [columns]="columns">
  <div class="price-request-course-form__form-group">
    <div class="price-request-course-form__form-group__label"><span i18n>Accommodation</span></div>
    <kng-form-field-selector
      [disabled]="!(quoteInput$ | async)?.startDate"
      valueExpr="code"
      [value]="selectedAccommodationType.code"
      [itemList]="accommodationTypes"
      [required]="true"
      (itemChange)="processSelectedAccommodationTypeChanged($event)"
      data-qa="pr-accommodation-type-field"
    ></kng-form-field-selector>
  </div>
</kng-section-template>

<kng-section-template [columns]="1">
  <div class="price-request-course-form__form-group__label">
    <span i18n>Tell us more about your objective and needs if you’d be interested in other destinations</span>
  </div>
  <kng-form-field
    class="qt-form-section__double-column-span"
    [disabled]="!(quoteInput$ | async)?.startDate"
    [required]="false"
    [small]="true"
    [isValid]="true"
    data-qa="pr-customer-project-field"
  >
    <textarea
      class="qt-form-section__textarea"
      (ngModelChange)="setSpecialRequests($event)"
      [(ngModel)]="specialRequests"
      [autoResize]="false"
      pInputTextarea
    ></textarea>
  </kng-form-field>
</kng-section-template>
