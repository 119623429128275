import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { CoreComponentsModule } from '@klg/core/components';
import { CurrencyModule } from '@klg/currency';
import {
  AccommodationComponentsModule,
  CourseComponentsModule,
  DateOfBirthFieldComponent,
  DatePickerFieldComponent,
  DestinationComponentsModule,
  FormFieldSelectorComponent,
  LanguageComponentsModule,
  QuoteComponentsModule,
  QuoteToolSharedModule,
  SchoolComponentsModule,
  WeeksSelectorComponent,
} from '@klg/quote-tool/shared/ui/components';
import { AccommodationDatesFormSectionComponent } from './components/sections/accommodation-dates/accommodation-dates.component';
import { ContactPersonSectionComponent } from './components/sections/contact-person/contact-person.component';
import { CourseDatesFormSectionComponent } from './components/sections/course-dates/course-dates.component';
import { DestinationSchoolFormSectionComponent } from './components/sections/destination-school/destination-school.component';
import { ExtrasSelectionComponent } from './components/sections/extras-selection/extras-selection.component';
import { SectionTemplateComponent } from './components/sections/section-template/section-template.component';
import { SelectOptionsFormSectionComponent } from './components/sections/select-options/select-options.component';
import { TellUsAboutYouSectionComponent } from './components/sections/tell-us-about-you/tell-us-about-you.component';
import { TermsAndPromotionsFormSectionComponent } from './components/sections/terms-and-promotions/terms-and-promotions.component';
import { BookingFormComponent } from './components/steps/booking-form/booking-form.component';
import { QuoteFormComponent } from './components/steps/quote-form/quote-form.component';
import { SendQuoteToEmailComponent } from './components/steps/send-quote-to-email/send-quote-to-email.component';
import { ThankYouComponent } from './components/steps/thank-you/thank-you.component';
import { QuoteErrorComponent } from './components/steps/quote-error/quote-error.component';
import { FormComponent } from './form.component';
import { QuoteToolFormRoutingModule } from './routing/form-routing.module';
import { LevelSelectorComponent } from './shared/components/level-selector/level-selector/level-selector.component';
import { EnrolmentPaymentComponent } from './components/steps/enrolment-payment/enrolment-payment.component';
import { UiModule } from '@klg/ui/components';
import { DatePipe, PricePipe, SortByPipe } from '@klg/shared/ui/pipes';
import { ButtonComponent } from '@klg/shared/ui/button';
import { InputTextareaModule } from 'primeng/inputtextarea';
import { InputTextModule } from 'primeng/inputtext';
import { CheckboxModule } from 'primeng/checkbox';
import { LoadingSpinnerComponent } from '@klg/shared/ui/loading-spinner';
import { FormFieldComponent } from '@klg/shared/ui/form-field';
import { HostFamilySelectionComponent } from './components/sections/extras-selection/host-family-selection/host-family-selection.component';

@NgModule({
  declarations: [
    FormComponent,
    QuoteFormComponent,
    BookingFormComponent,
    SendQuoteToEmailComponent,
    ThankYouComponent,
    QuoteErrorComponent,
    DestinationSchoolFormSectionComponent,
    CourseDatesFormSectionComponent,
    AccommodationDatesFormSectionComponent,
    TellUsAboutYouSectionComponent,
    SelectOptionsFormSectionComponent,
    ContactPersonSectionComponent,
    ExtrasSelectionComponent,
    SelectOptionsFormSectionComponent,
    LevelSelectorComponent,
    EnrolmentPaymentComponent,
  ],
  exports: [FormComponent, SectionTemplateComponent, TellUsAboutYouSectionComponent, TermsAndPromotionsFormSectionComponent],
  imports: [
    CommonModule,
    CoreComponentsModule,
    QuoteToolFormRoutingModule,
    QuoteToolSharedModule,
    FormsModule,
    SchoolComponentsModule,
    CourseComponentsModule,
    LanguageComponentsModule,
    DestinationComponentsModule,
    QuoteComponentsModule,
    AccommodationComponentsModule,
    CurrencyModule,
    UiModule,
    ButtonComponent,
    SortByPipe,
    PricePipe,
    DatePipe,
    InputTextareaModule,
    CheckboxModule,
    InputTextModule,
    LoadingSpinnerComponent,
    WeeksSelectorComponent,
    DatePickerFieldComponent,
    FormFieldSelectorComponent,
    FormFieldComponent,
    DateOfBirthFieldComponent,
    HostFamilySelectionComponent,
    SectionTemplateComponent,
    TermsAndPromotionsFormSectionComponent,
  ],
})
export class QuoteToolFormModule {}
