<div class="quote-total" [ngSwitch]="layout">
  <ng-container *ngSwitchCase="QuoteTotalLayouts.COLLAPSED" [ngTemplateOutlet]="collapsedLayout"></ng-container>
  <ng-container *ngSwitchCase="QuoteTotalLayouts.EXTENDED" [ngTemplateOutlet]="extendedLayout"></ng-container>
  <ng-container *ngSwitchDefault [ngTemplateOutlet]="summaryLayout"></ng-container>
</div>

<ng-template #collapsedLayout>
  <div class="quote-total--collapsed" (click)="viewDetails.emit()">
    <div class="quote-total__line">
      <h3 class="quote-total__title" i18n>Quote</h3>
      <div class="quote-total__price" title="{{ quote?.grandTotal | klgPrice : { currency: currency } }}">
        {{ quote?.grandTotal | klgPrice : { currency: currency, minDecimalPlaces: decimalPlaces } }}
        <span class="quote-total__price__arrow">
          <i class="pi pi-angle-right"></i>
        </span>
      </div>
    </div>
  </div>
</ng-template>

<ng-template #extendedLayout>
  <div class="quote-total--extended">
    <div class="quote-total__line">
      <h3 class="quote-total__title" i18n>Your Quote</h3>
      <div class="quote-total__price-and-currency">
        <kng-currency-selector
          *ngIf="hasCurrencySelector && visibleCurrencies?.length"
          class="quote-total__currency-selector"
          [currencyCode]="currency"
          [priceCurrencies]="visibleCurrencies"
          [hideDestinationCurrency]="true"
          (currencyCodeChange)="onCurrencyChange($event)"
        ></kng-currency-selector>
        <div class="quote-total__price" title="{{ quote?.grandTotal | klgPrice : { currency: currency } }}">
          <h4 class="quote-total__price__title" i18n>Total:</h4>
          <span class="quote-total__price__amount">{{ quote?.grandTotal | klgPrice : { currency: currency, minDecimalPlaces: decimalPlaces } }}</span>
        </div>
      </div>
    </div>
    <div class="quote-total__line">
      <kng-button
        class="quote-total__button"
        [block]="true"
        [btnRounded]="true"
        [uppercase]="true"
        [buttonStyle]="'tertiary'"
        (buttonClick)="viewDetails.emit()"
        i18n
        >View details
      </kng-button>
    </div>
  </div>
</ng-template>

<ng-template #summaryLayout>
  <div class="quote-total--summary" [ngClass]="{ 'quote-total--summary-with-currency-selector': hasCurrencySelector && visibleCurrencies?.length }">
    <kng-currency-selector
      *ngIf="hasCurrencySelector && visibleCurrencies?.length"
      class="quote-total__currency-selector"
      [currencyCode]="currency"
      [priceCurrencies]="visibleCurrencies"
      [hideDestinationCurrency]="true"
      (currencyCodeChange)="currencyChanged.emit($event)"
    ></kng-currency-selector>
    <div class="quote-total__price-wrapper">
      <div class="quote-total__label" i18n>Total</div>
      <div
        class="quote-total__price"
        title="{{ quote?.grandTotal | klgPrice : { currency: showBothCurrencies ? destinationCurrency : currency, minDecimalPlaces: decimalPlaces } }}"
      >
        {{
          quote?.grandTotal
            | klgPrice : { currency: showBothCurrencies && !quote?.singleCurrencyMode ? destinationCurrency : currency, minDecimalPlaces: decimalPlaces }
        }}
        <sup class="quote-total__price-asterisk" *ngIf="showCurrencyDisclaimer()">*</sup>
        <span *ngIf="currency !== destinationCurrency && showBothCurrencies && quote?.singleCurrencyMode" class="quote-total__price-asterisk">*</span>
      </div>
      <div
        class="quote-total__price-residence"
        [class.quote-total__price-residence--expanded]="isSelectedSlot(quoteKey)"
        *ngIf="currency !== destinationCurrency && showBothCurrencies && !quote?.singleCurrencyMode"
        title="{{ quote?.grandTotal | klgPrice : { currency: currency, minDecimalPlaces: decimalPlaces } }}"
      >
        <span i18n>approximately</span>
        <span>{{ quote?.grandTotal | klgPrice : { currency: currency, minDecimalPlaces: decimalPlaces } }}</span>
      </div>
      <div class="quote-total__price-destination" *ngIf="showCurrencyDisclaimer()">
        {{ quote?.grandTotal | klgPrice : { currency: destinationCurrency, minDecimalPlaces: decimalPlaces } }}
      </div>
      <div class="quote-total__saving" *ngIf="quote?.discountTotal && quote?.discountTotal[0]?.value" i18n>
        You can save {{ quote?.discountTotal | klgPrice : { currency: currency, minDecimalPlaces: decimalPlaces, isDiscount: true } }} today
      </div>
      <div class="quote-total__price-market-currency" *ngIf="currency !== destinationCurrency && showBothCurrencies && quote?.singleCurrencyMode">
        <span>{{ quote?.grandTotal | klgPrice : { currency: destinationCurrency, minDecimalPlaces: decimalPlaces } }}</span>
      </div>
      <div class="quote-total__discount-validity" *ngIf="quote?.marketDiscountValidityDate">
        <span i18n>Price valid until</span> {{ quote?.marketDiscountValidityDate | klgDate : dateFormat }}
      </div>
    </div>
    <div class="quote-total__disclaimer" *ngIf="hasDisclaimerSection">
      <div class="quote-total__disclaimer__currency" *ngIf="showCurrencyDisclaimer()" i18n>
        We guarantee prices in the currency of the destination. Prices displayed in other currencies are merely informative and might differ at the bottom of
        the booking, due to exchange rate fluctuations.
      </div>

      <div *ngIf="infoDisclaimer" class="quote-total__disclaimer__info">
        {{ infoDisclaimer }}
      </div>
    </div>
  </div>
</ng-template>
