import { Injectable } from '@angular/core';
import { SchoolControllerApiService, SchoolFinderApiService } from '@klg/shared/api/services';
import { Memoize } from '@klg/shared/utils-http';
import { getLocale } from '@klg/shared/i18n';
// eslint-disable-next-line @nrwl/nx/enforce-module-boundaries
import { FetchDataObject, ProductsCatalogFetcher } from '@klg/ui/product-catalog/shared/models/products-catalog-fetcher-service.type';
import { BehaviorSubject, Observable, of } from 'rxjs';
import { map, tap } from 'rxjs/operators';
import { School, SchoolCardSummary, SchoolCourses, SchoolV4 } from '../models';
import { COMPANIES } from '@klg/shared/types';
import { Course } from '@klg/shared/data-access/types';
import { getCompany, getCompanyHeader } from '@klg/shared/tokens';
import { QuoteOnlineSchool } from '@klg/quote-tool/shared/types';

@Injectable({
  providedIn: 'root',
})
export class ProductSchoolsService implements ProductsCatalogFetcher<School> {
  private isLoadingSubject: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  isLoading$: Observable<boolean> = this.isLoadingSubject.asObservable();

  private readonly locale = getLocale();
  private readonly company = getCompany();
  private readonly companyHeader = getCompanyHeader();

  constructor(private schoolControllerService: SchoolControllerApiService, private schoolFinderApiService: SchoolFinderApiService) {}

  @Memoize((id: string) => id)
  get(id: string): Observable<School> {
    if (!id) {
      return of(null);
    }
    // TODO - Switch to findSchoolByCode once it is compatible with ESL
    return this.company === COMPANIES.ESL
      ? this.schoolControllerService.findSchoolById(+id, this.locale, this.companyHeader)
      : this.schoolControllerService.findSchoolByCode(id, this.locale, this.companyHeader);
  }

  getByCode(schoolCode: string): Observable<School> {
    return this.get(schoolCode);
  }

  getCourse(schoolCode: string, courseCode: string): Observable<Course> {
    return schoolCode && courseCode
      ? this.get(schoolCode).pipe(map((school: School) => school.courses?.find((course) => course.code === courseCode)))
      : of(null);
  }

  getCourses(schoolCode: string): Observable<Course[]> {
    if (!schoolCode) {
      return of(null);
    }
    return this.schoolControllerService.findSchoolCourses(schoolCode, this.locale, this.companyHeader);
  }

  getResults(parameters: FetchDataObject): Observable<School[]> {
    switch (this.company) {
      case COMPANIES.KAPLAN:
        return this.getKaplanSchools(parameters);
      case COMPANIES.ALPADIA:
        return this.getAlpadiaSchools(parameters);
      case COMPANIES.ESL:
        return this.getEslSchools(parameters);
    }
  }

  getSchoolsByParam(
    cityCode: string = null,
    courseCode: string = null,
    countryCode: string = null,
    languageCode: string = null,
    purposeCode: string = null,
    courseTypeCode: string = null,
  ): Observable<School[]> {
    this.isLoadingSubject.next(true);
    return this.schoolFinderApiService
      .findSchools1(this.locale, this.companyHeader, languageCode, countryCode, purposeCode, cityCode, courseTypeCode, courseCode)
      .pipe(tap(() => this.isLoadingSubject.next(false)));
  }

  getSchoolV4(schoolCode: string): Observable<SchoolV4> {
    return this.schoolControllerService.findSchoolV4(schoolCode, this.locale, this.companyHeader);
  }

  findOnlineSchools(residenceCountryCode: string): Observable<QuoteOnlineSchool> {
    return this.schoolControllerService.findOnlineSchools(this.locale, this.companyHeader, residenceCountryCode);
  }

  getSchoolsSummariesByCity(
    cityCode: string,
    languageCode: string,
    purposeCode: string = null,
    courseTypeCode: string = null,
  ): Observable<SchoolCardSummary[]> {
    return this.schoolControllerService.findSchoolSummariesByCity(cityCode, this.locale, this.companyHeader, languageCode, null, purposeCode, courseTypeCode);
  }

  getSchoolCourses(schoolCode: string): Observable<SchoolCourses> {
    return this.schoolControllerService.findSchoolWithCourses(schoolCode, this.locale, this.companyHeader);
  }

  getSchoolFiltersV2() {
    return this.schoolFinderApiService.getSchoolFiltersV2(this.locale, this.companyHeader);
  }

  getSchoolCountryFilters(languageCode: string, purposeCode: string, countryCode?: string) {
    return this.schoolFinderApiService.getSchoolCountryFilters(this.locale, this.companyHeader, languageCode, purposeCode, countryCode);
  }

  private getKaplanSchools(parameters: Record<string, string>): Observable<School[]> {
    const { courseCodeList, countryCode } = parameters;
    return this.schoolControllerService.findSchoolsWithFilters({ courseCodeList: courseCodeList?.split(','), countryCode }, this.locale, this.companyHeader);
  }

  private getAlpadiaSchools(parameters: Record<string, string>): Observable<School[]> {
    const { countryCode } = parameters;
    return this.schoolControllerService.findSchoolsWithFilters({ countryCode }, this.locale, this.companyHeader);
  }

  private getEslSchools(parameters: Record<string, string>): Observable<School[]> {
    const { cityCode, courseCode, countryCode, languageCode, purposeCode, courseTypeCode } = parameters;
    return this.getSchoolsByParam(cityCode, courseCode, countryCode, languageCode, purposeCode, courseTypeCode);
  }
}
