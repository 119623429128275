import { Component, Inject, OnDestroy, OnInit } from '@angular/core';
import { QuoteOutput, StudentDetails } from '@klg/quote-tool/shared/types/quote';
import { BehaviorSubject, Subscription } from 'rxjs';
import { DepositService } from '@klg/quote-tool/shared/services';
import { StepService } from '@klg/quote-tool/shared/services/step-service';
import { FlywireGatewayService, FlywirePayerInfo, PAYMENT_GATEWAY_SERVICE, PaymentGatewayService } from '@klg/shared/payment';
import { isEmpty } from '@klg/shared/utils';
import { MetaService } from '@klg/shared/seo';
import { MetaDefinition } from '@angular/platform-browser';

@Component({
  selector: 'kng-enrolment-payment',
  templateUrl: './enrolment-payment.component.html',
  styleUrls: ['./enrolment-payment.component.scss'],
  providers: [{ provide: PAYMENT_GATEWAY_SERVICE, useClass: FlywireGatewayService }],
})
export class EnrolmentPaymentComponent implements OnInit, OnDestroy {
  public studentDetails: Partial<StudentDetails>;
  public quote: QuoteOutput;
  public depositRequiredMessage: string;
  public payNowButtonLabel = '';

  private isStepValid$ = new BehaviorSubject(true);
  private subscription = new Subscription();
  isDepositRequired = false;

  constructor(
    @Inject(PAYMENT_GATEWAY_SERVICE) private readonly paymentGatewayService: PaymentGatewayService<FlywirePayerInfo>,
    private readonly stepService: StepService,
    private metaService: MetaService,
    private readonly depositService: DepositService,
  ) {
    this.metaService.removeMetaTagOnRouteChange('robots');
  }

  ngOnInit(): void {
    const robots: MetaDefinition = { name: 'robots', content: 'noindex, nofollow' };
    this.metaService.addMetaTag(robots);
    this.stepService.setStepValid(this.isStepValid$);
    this.studentDetails = this.stepService.getStudentDetails();
    this.getActiveQuote();
    this.paymentGatewayService.bootstrap();
  }

  ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }

  pay(): void {
    const {
      payableData: {
        jwt,
        payableInfo: { amount, recipient },
      },
    } = this.quote;

    this.paymentGatewayService.makePayment(amount, jwt, this.getPayerInfo(), this.getPaymentCustomData(), recipient);
  }

  private getActiveQuote() {
    this.subscription.add(
      this.stepService.requestActiveQuote().subscribe((quoteOutput: QuoteOutput) => {
        this.quote = quoteOutput;
        const {
          payableData: { requiredThreshold, required },
        } = this.quote;
        this.isDepositRequired = required;
        this.payNowButtonLabel = this.isDepositRequired ? $localize`Pay now` : $localize`I wish to pay online`;
        if (required) {
          this.depositRequiredMessage = this.depositService.getDepositRequiredMessage(requiredThreshold);
        }
      }),
    );
  }

  private getPayerInfo(): Partial<FlywirePayerInfo> {
    if (this.isThereGuardianInformation()) {
      return this.getGuardianPayerInformation();
    }

    return this.getStudentPayerInformation();
  }

  private getStudentPayerInformation(): Partial<FlywirePayerInfo> {
    const { countryOfResidence, firstName, lastName, address, postcode, regionCode, phoneNumber, city, email } = this.studentDetails;
    return {
      sender_country: countryOfResidence,
      sender_first_name: firstName,
      sender_last_name: lastName,
      sender_address1: address,
      sender_address2: '',
      sender_state: regionCode,
      sender_zip: postcode,
      sender_phone: phoneNumber,
      sender_city: city,
      sender_email: email,
    };
  }

  private getGuardianPayerInformation(): Partial<FlywirePayerInfo> {
    const {
      countryOfResidence,
      duringYourStay: { legalRepresentativeFirstName, legalRepresentativeLastName, legalRepresentativeEmail, legalRepresentativeMobile },
    } = this.studentDetails;
    return {
      sender_country: countryOfResidence,
      sender_first_name: legalRepresentativeFirstName,
      sender_last_name: legalRepresentativeLastName,
      sender_phone: legalRepresentativeMobile,
      sender_email: legalRepresentativeEmail,
    };
  }

  private getPaymentCustomData(): Record<string, unknown> {
    const { firstName, lastName, email } = this.studentDetails;
    return {
      student_first_name: firstName,
      student_last_name: lastName,
      student_email: email,
      quote_id: this.quote.quoteId,
      payment_source: 'quoteTool',
    };
  }

  private isThereGuardianInformation(): boolean {
    if (!this.studentDetails.duringYourStay) {
      return false;
    }
    const {
      duringYourStay: { legalRepresentativeFirstName, legalRepresentativeLastName, legalRepresentativeEmail, legalRepresentativeMobile },
    } = this.studentDetails;
    return (
      !isEmpty(legalRepresentativeFirstName) &&
      !isEmpty(legalRepresentativeLastName) &&
      !isEmpty(legalRepresentativeEmail) &&
      !isEmpty(legalRepresentativeMobile)
    );
  }
}
