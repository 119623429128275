import { inject, Injectable } from '@angular/core';
import { NGXLogger } from 'ngx-logger';

@Injectable({ providedIn: 'root' })
export class LoggerService {
  private readonly logger = inject(NGXLogger);

  trace(message: string, ...additional: string[]): void {
    this.logger.trace(message, ...additional);
  }

  debug(message: string, ...additional: string[]): void {
    this.logger.debug(message, additional);
  }

  info(message: string, ...additional: string[]): void {
    this.logger.info(message, additional);
  }

  log(message: string, ...additional: string[]): void {
    this.logger.log(message, additional);
  }

  warn(message: string, ...additional: string[]): void {
    this.logger.warn(message, additional);
  }

  error(message: string, ...additional: string[]): void {
    this.logger.error(message, additional);
  }

  fatal(message: string, ...additional: string[]): void {
    this.logger.fatal(message, additional);
  }
}
