import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { CoreComponentsModule } from '@klg/core/components';
import { CurrencyModule } from '@klg/currency';
import { ClosingDatesPipe, OffsetTimePipe, ProductsByTypePipe, TransfersByTypePipe, UnaccompaniedMinorsByTypePipe } from '@klg/quote-tool/shared/ui/pipes';
import { UiModule } from '@klg/ui/components';
import { QuoteCourseDetailsItemComponent } from './quote-course-details/quote-course-details-item/quote-course-details-item.component';
import { QuoteCourseDetailsLabelComponent } from './quote-course-details/quote-course-details-label/quote-course-details-label.component';
import { QuoteCourseDetailsNameComponent } from './quote-course-details/quote-course-details-name/quote-course-details-name.component';
import { QuoteCourseDetailsComponent } from './quote-course-details/quote-course-details.component';
import { QuoteDiscountDetailsComponent } from './quote-discount-details/quote-discount-details.component';
import { QuoteAccordionItemComponent } from './quote-global-items/quote-accordion-item/quote-accordion-item.component';
import { QuotePriceItemComponent } from './quote-global-items/quote-price-item/quote-price-item.component';
import { QuoteSubtotalItemComponent } from './quote-global-items/quote-subtotal-item/quote-subtotal-item.component';
import { QuotePopupComponent } from './quote-popup/quote-popup.component';
import { QuotePriceChecklistComponent } from './quote-price-checklist/quote-price-checklist.component';
import { QuotePrintComponent } from './quote-print/quote-print.component';
import { QuoteProductDetailsDateComponent } from './quote-product-details/quote-product-details-date/quote-product-details-date.component';
import { QuoteProductDetailsDescriptionComponent } from './quote-product-details/quote-product-details-description/quote-product-details-description.component';
import { QuoteProductDetailsLabelComponent } from './quote-product-details/quote-product-details-label/quote-product-details-label.component';
import { QuoteProductDetailsSupplementComponent } from './quote-product-details/quote-product-details-supplement/quote-product-details-supplement.component';
import { QuoteProductDetailsComponent } from './quote-product-details/quote-product-details.component';
import { QuoteTotalPriceComponent } from './quote-total-price/quote-total-price.component';
import { QuoteComponent } from './quote.component';
import { QuoteCtaButtonComponent } from './quote-global-items/quote-cta-button/quote-cta-button.component';
import { QuoteSpeedDialFabComponent } from './quote-global-items/quote-speed-dial-fab/quote-speed-dial-fab.component';
import { DatePipe, LocalizeAndPluralizePeriodPipe, PricePipe } from '@klg/shared/ui/pipes';
import { ButtonComponent } from '@klg/shared/ui/button';
import { DialogModule } from 'primeng/dialog';
import { QuoteCtaButtonGroupComponent } from './quote-global-items/quote-cta-button-group/quote-cta-button-group.component';
import { TimeLeftComponent } from '../discount';
import { GoogleAnalyticsClickEventDirective } from '@klg/shared/google-analytics';

@NgModule({
  declarations: [
    QuoteComponent,
    QuoteTotalPriceComponent,
    QuotePopupComponent,
    QuotePrintComponent,
    QuotePriceItemComponent,
    QuoteProductDetailsComponent,
    QuoteProductDetailsDateComponent,
    QuoteProductDetailsLabelComponent,
    QuoteProductDetailsSupplementComponent,
    QuoteProductDetailsDescriptionComponent,
    QuoteCourseDetailsComponent,
    QuoteCourseDetailsNameComponent,
    QuoteCourseDetailsItemComponent,
    QuoteCourseDetailsLabelComponent,
    QuoteAccordionItemComponent,
    QuoteSubtotalItemComponent,
    QuoteDiscountDetailsComponent,
    QuoteAccordionItemComponent,
    QuotePriceChecklistComponent,
    QuoteCtaButtonComponent,
    QuoteSpeedDialFabComponent,
    QuoteCtaButtonGroupComponent,
  ],
  imports: [
    CommonModule,
    CoreComponentsModule,
    CurrencyModule,
    ButtonComponent,
    UiModule,
    PricePipe,
    DatePipe,
    LocalizeAndPluralizePeriodPipe,
    ClosingDatesPipe,
    OffsetTimePipe,
    ProductsByTypePipe,
    TransfersByTypePipe,
    UnaccompaniedMinorsByTypePipe,
    TimeLeftComponent,
    DialogModule,
    GoogleAnalyticsClickEventDirective,
  ],
  exports: [
    QuoteComponent,
    QuoteTotalPriceComponent,
    QuotePopupComponent,
    QuotePrintComponent,
    QuotePriceItemComponent,
    QuoteProductDetailsComponent,
    QuoteProductDetailsDateComponent,
    QuoteProductDetailsLabelComponent,
    QuoteProductDetailsSupplementComponent,
    QuoteProductDetailsDescriptionComponent,
    QuoteCourseDetailsComponent,
    QuoteCourseDetailsNameComponent,
    QuoteCourseDetailsItemComponent,
    QuoteCourseDetailsLabelComponent,
    QuoteAccordionItemComponent,
    QuoteSubtotalItemComponent,
    QuoteDiscountDetailsComponent,
    QuoteAccordionItemComponent,
    QuotePriceChecklistComponent,
  ],
})
export class QuoteComponentsModule {}
