import { Component, EventEmitter, Input, Output } from '@angular/core';

@Component({
  selector: 'kng-quote-accordion-item',
  templateUrl: './quote-accordion-item.component.html',
  styleUrls: ['./quote-accordion-item.component.scss'],
})
export class QuoteAccordionItemComponent {
  @Input() totalItems = 0;
  @Input() isAccordionExpanded = false;
  @Input() roundedElements = false;
  @Output() expandAccordion = new EventEmitter<void>();
}
