import { DurationUnit, DurationUnitEnum } from '@klg/shared/data-access/types';
import { getTimezoneOffset, zonedTimeToUtc } from 'date-fns-tz';
import { prependStringWithCharacter } from './string-utils.functions';

export const enum DAYS {
  SUNDAY,
  MONDAY,
  TUESDAY,
  WEDNESDAY,
  THURSDAY,
  FRIDAY,
  SATURDAY,
}

export const FULLWEEK = [DAYS.SUNDAY, DAYS.MONDAY, DAYS.TUESDAY, DAYS.WEDNESDAY, DAYS.THURSDAY, DAYS.FRIDAY, DAYS.SATURDAY];
export const WORKWEEK = [DAYS.MONDAY, DAYS.TUESDAY, DAYS.WEDNESDAY, DAYS.THURSDAY, DAYS.FRIDAY];
export const WEEKEND = [DAYS.SUNDAY, DAYS.SATURDAY];

const ONE_DAY_IN_MILLISECONDS: number = 1000 * 60 * 60 * 24;

export function addWeeks(date: Date, weeks: number, targetWeekdays: number[] = [], fullWeeks = false, unavailableDates: Date[] = []): Date {
  const ONE_DAY: number = 1000 * 60 * 60 * 24;
  const ONE_WEEK: number = ONE_DAY * 7;
  const fullWeeksModifier: number = targetWeekdays.length > 0 && !fullWeeks ? 1 : 0;
  let targetDate = new Date(safeGetTime(date) + ONE_WEEK * (weeks - fullWeeksModifier));
  const dayOfWeek = targetDate.getDay();
  if (targetWeekdays.length > 0 && !targetWeekdays.includes(dayOfWeek)) {
    const sortedAvailableDays = targetWeekdays.sort((a, b) => b - a);
    const closestEnabledDay = sortedAvailableDays.find((day) => day < dayOfWeek) ?? sortedAvailableDays.find((day) => day < dayOfWeek + 7);
    targetDate = findClosestWeekday(targetDate, closestEnabledDay);
  }
  while (unavailableDates.some((unavailableDate) => unavailableDate.getTime() === targetDate.getTime())) {
    targetDate = addDays(targetDate, -1);
  }
  return targetDate;
}

export function difference(date1: Date, date2: Date, unit: 'day' | 'week' = 'day'): number {
  // The number of milliseconds in one day
  const ONE_UNIT: number = 1000 * 60 * 60 * 24 * (unit === 'day' ? 1 : 7);

  // Calculate the difference in milliseconds
  const differenceMs: number = Math.abs(+date1 - +date2);

  // Convert back to days and return
  return Math.ceil(differenceMs / ONE_UNIT);
}

export function isDateAfter(date1: Date, date2: Date): boolean {
  return date1.getTime() >= date2.getTime();
}

export function addDays(date: Date, days: number): Date {
  const newDate = new Date(date);
  newDate.setDate(newDate.getDate() + days);
  return newDate;
}

export function addYears(date: Date, years: number): Date {
  const newDate = new Date(date);
  newDate.setFullYear(newDate.getFullYear() + years);
  return newDate;
}

export function findClosestWeekday(date: Date, weekday: number, direction: 'backward' | 'forward' = 'forward', excludeCurrentDay = false): Date {
  const dateCopy = new Date(date);
  const directionSign = direction === 'backward' ? -1 : 1;
  const additionalDay = +excludeCurrentDay;
  dateCopy.setHours(12, 0, 0, 0);
  dateCopy.setDate(
    dateCopy.getDate() + directionSign * additionalDay + ((weekday + directionSign * 7 - dateCopy.getDay() - directionSign * additionalDay) % 7),
  );
  return dateCopy;
}

export function findClosestWeekendDay(date: Date, weekendDay: 'saturday' | 'sunday' = 'saturday'): Date {
  /**
   * Sunday = 0, Monday = 1, Tuesday = 2, Wednesday = 3, Thursday = 4, Friday = 5, Saturday = 6
   */
  const dateCopy = new Date(date.getTime());
  const weekDay = dateCopy.getDay();
  const targetWeekendDay = weekendDay === 'saturday' ? 6 : 0;

  if (weekDay == targetWeekendDay) {
    return dateCopy;
  }

  let amountFromDateToTarget;
  let amountFromTargetToDate;
  let closestAmountOfDays;
  let signal;

  if (weekendDay === 'saturday') {
    amountFromDateToTarget = Math.abs(targetWeekendDay - weekDay);
    amountFromTargetToDate = Math.abs(7 - Math.abs(weekDay - targetWeekendDay));
  } else {
    amountFromDateToTarget = Math.abs(7 - Math.abs(weekDay - targetWeekendDay));
    amountFromTargetToDate = Math.abs(targetWeekendDay - weekDay);
  }

  closestAmountOfDays = amountFromDateToTarget;
  signal = 1;

  if (amountFromDateToTarget > amountFromTargetToDate) {
    closestAmountOfDays = amountFromTargetToDate;
    signal = -1;
  }

  dateCopy.setTime(dateCopy.getTime() + signal * closestAmountOfDays * ONE_DAY_IN_MILLISECONDS);
  return dateCopy;
}

export function disabledWeekdaysToAvailability(disabledDays: number[]): number[] {
  return Array.from(Array(7), (v, i) => i).filter((i) => !disabledDays.includes(i));
}

export function getMonthStartDate(year: number, month: number): Date {
  return new Date(year, month, 1, 12);
}

export function getMonthEndDate(year: number, month: number): Date {
  return new Date(year, month + 1, 0, 12);
}

export function getDateFromString(dateString: string): Date {
  if (!dateString) return undefined;
  const matches = dateString.match(/(?<year>\d{4})-(?<month>\d{2})-(?<day>\d{2})/);
  const { year, month, day } = matches?.groups ?? {};
  if (!year || !month || !day) {
    throw new Error(`Date passed is not valid. Format should be YYYY-MM-DD.`);
  }
  return new Date(+year, +month - 1, +day, 12);
}

export function getDateStringFromDate(date: Date): string {
  if (!date) return undefined;
  // https://bobbyhadz.com/blog/javascript-typeerror-date-gettime-is-not-a-function
  const newDate = new Date(date);
  const dateObj = new Date(newDate.getTime() - newDate.getTimezoneOffset() * 60 * 1000);
  return dateObj.toISOString().split('T')[0];
}

export function getDisabledDaysForRange(
  startDate: Date,
  endDate: Date,
  disabledDays: Date[] = [],
  targetWeekdays?: number[],
  viableWeekdays: number[] = [1, 2, 3, 4, 5],
  direction: 'backward' | 'forward' = 'forward',
): Date[] {
  if (targetWeekdays?.some((d) => !viableWeekdays.includes(d))) {
    throw Error('targetWeekdays need to be a subset of viableWeekdays.');
  }
  const disabledDaysForRange = [];
  for (
    let currentWeekStart = findClosestWeekday(direction === 'forward' ? startDate : endDate, 0, direction === 'forward' ? 'backward' : 'forward', true);
    direction === 'forward'
      ? currentWeekStart.getTime() < endDate.getTime()
      : currentWeekStart.getTime() > findClosestWeekday(startDate, 0, 'backward', true).getTime();
    currentWeekStart = findClosestWeekday(currentWeekStart, 0, direction, true)
  ) {
    let exception = false;
    for (let weekday = direction === 'forward' ? 0 : 6; direction === 'forward' ? weekday < 7 : weekday >= 0; direction === 'forward' ? weekday++ : weekday--) {
      const currentDate = findClosestWeekday(currentWeekStart, weekday);
      if (!viableWeekdays.includes(weekday)) {
        disabledDaysForRange.push(currentDate);
        continue;
      }
      const isDisabledDay = disabledDays.some((date) => date.getTime() === currentDate.getTime());
      if (isDisabledDay) {
        disabledDaysForRange.push(currentDate);
        if (targetWeekdays?.includes(weekday)) {
          exception = true;
        }
        continue;
      }
      if (exception) {
        exception = false;
        continue;
      }
      if (targetWeekdays && !targetWeekdays.includes(weekday)) {
        disabledDaysForRange.push(currentDate);
      }
    }
  }
  return disabledDaysForRange
    .filter((d) => d.getTime() >= safeGetTime(startDate) && d.getTime() <= safeGetTime(endDate))
    .sort((a, b) => a.getTime() - b.getTime());
}

// See: https://itsjavascript.com/javascript-typeerror-date-gettime-is-not-a-function
export function safeGetTime(aDate: Date): number {
  if (typeof aDate === 'object' && aDate !== null && 'getTime' in aDate) {
    return aDate.getTime();
  } else {
    return new Date(aDate).getTime();
  }
}

export function getToday(): Date {
  const today = new Date();
  today.setHours(12);
  return today;
}

export function isDateWithinRange(date: Date, [rangeStart, rangeEnd]: [Date, Date]): boolean {
  return (!rangeStart || date >= rangeStart) && (!rangeEnd || date <= rangeEnd);
}

export function getDateRangeAroundDate(startDate: Date, daysInRange: number): [Date, Date] {
  const idealRangeStart = addDays(startDate, -daysInRange / 2);
  const rangeEnd = addDays(startDate, daysInRange / 2);
  const today = getToday();
  const rangeStart = idealRangeStart > today ? idealRangeStart : today;
  return [rangeStart, rangeEnd];
}

export function daysDiff(dateStart: Date, dateEnd: Date): number | undefined {
  if (!dateStart || !dateEnd) {
    return undefined;
  }
  const millisecondsIn1Day = 1000 * 60 * 60 * 24;
  const dateStartWithoutTime = Date.UTC(dateStart.getFullYear(), dateStart.getMonth(), dateStart.getDate());
  const dateEndWithoutTime = Date.UTC(dateEnd.getFullYear(), dateEnd.getMonth(), dateEnd.getDate());
  return Math.floor(Math.abs(dateStartWithoutTime - dateEndWithoutTime) / millisecondsIn1Day);
}

export function weeksDiff(dateStart: Date, dateEnd: Date): number | undefined {
  const diffInDays = daysDiff(dateStart, dateEnd);
  return !isNaN(diffInDays) ? Math.floor(diffInDays / 7) : undefined;
}

export function getMinDate(targetDay: number, daysNotice: number, unavailableDates: string[] = []): Date {
  const date = getToday();
  // need to jump date at least the notice days
  date.setDate(date.getDate() + ++daysNotice);

  // get to the nearest target day
  while (date.getDay() !== targetDay) {
    date.setDate(date.getDate() + 1);
  }

  // skip closure dates
  while (unavailableDates?.includes(date.toISOString().slice(0, 10))) {
    date.setDate(date.getDate() + 1);
  }

  return date;
}

export function updateToClosestTargetDay(targetDay: number[], currentDay: Date): Date {
  const prevDay = updateToNextDate(targetDay, currentDay, -1);
  const nextDay = updateToNextDate(targetDay, currentDay, 1);
  return difference(prevDay, currentDay) < difference(nextDay, currentDay) ? prevDay : nextDay;
}

export function updateToNextDate(targetDays: number[], currentDay: Date, incremental: number = 1): Date {
  const date = new Date(currentDay.getTime());
  if (targetDays.includes(date.getDay())) {
    return date;
  }

  date.setDate(date.getDate() + incremental);
  return updateToNextDate(targetDays, date, incremental);
}

export const isLeapYear = (year: number): boolean => (year % 4 == 0 && year % 100 != 0) || year % 400 == 0;

/**
 * Returns the number of days in a month
 * @param month Month number from 1 to 12
 * @param year Required when month is 2 (february)
 * @returns
 */
export const monthDays = (month: number, year?: number): number => {
  if ((month == 2 && !year) || month < 1 || month > 12) {
    return null;
  }

  switch (month) {
    case 2:
      return isLeapYear(year) ? 29 : 28;
    case 4:
    case 6:
    case 9:
    case 11:
      return 30;
    default:
      return 31;
  }
};

/**
 * Given a timezone, returns the time offset inside an array
 * @param timezone Timezone
 * @returns an array of format [number, number, boolean] containing the hours, minutes and offset direction
 */
export const getTimezoneOffsetInHoursMinutes = (timezone: string) => {
  let offset = getTimezoneOffset(timezone);
  const offsetIsNegative = offset < 0;
  if (offsetIsNegative) {
    offset = -1 * offset;
  }
  offset = offset / 60000;
  const hours = Math.floor(offset / 60);
  const minutes = (offset / 60 - hours) * 60;
  return [hours, minutes, offsetIsNegative];
};

/**
 * Given a timezone returns a string with the timezone formatted in UTC +/- hh:mm
 * @param timezone
 */
export const getFormattedTimezoneOffset = (timezone: string) => {
  const [hours, minutes, offsetIsNegative] = getTimezoneOffsetInHoursMinutes(timezone);
  return `UTC ${offsetIsNegative ? '-' : '+'}${prependStringWithCharacter(hours.toString(), '0', 2)}:${prependStringWithCharacter(minutes.toString(), '0', 2)}`;
};

const convertMonthsToWeeks = (months: number): number => (months % 12 === 0 ? (months / 12) * 52 : months * 4);

/**
 * Convert a duration to weeks
 */
export const convertDurationToWeeks = (duration: { durationMin: number; durationMax: number; durationUnit: DurationUnit }) => {
  switch (duration.durationUnit) {
    case DurationUnitEnum.DAYS:
      return {
        durationMin: duration.durationMin / 7,
        durationMax: duration.durationMax / 7,
        durationUnit: DurationUnitEnum.WEEKS,
      };
    case DurationUnitEnum.WEEKS:
      return duration;
    case DurationUnitEnum.MONTH:
      return {
        durationMin: convertMonthsToWeeks(duration.durationMin),
        durationMax: convertMonthsToWeeks(duration.durationMax),
        durationUnit: DurationUnitEnum.WEEKS,
      };
    case DurationUnitEnum.YEARS:
      return {
        durationMin: duration.durationMin * 52,
        durationMax: duration.durationMax * 52,
        durationUnit: DurationUnitEnum.WEEKS,
      };
    default:
      return null;
  }
};

// TODO: review this functions
export const convertTimeWIthOffsetToString = (aTime: string, offset: number): string => {
  if (!aTime) {
    return '';
  }
  const [hours, minutes] = parseTime(aTime);
  const [convertedHours, convertedMinutes] = convertTime(hours, minutes, offset);
  return prependStringWithCharacter('' + convertedHours, '0', 2) + ':' + prependStringWithCharacter('' + convertedMinutes, '0', 2);
};

const parseTime = (aTime: string): [number, number] => {
  const parts = aTime.split(':');
  const hours = parseInt(parts[0]);
  const minutes = parseInt(parts[1]);
  return [hours, minutes];
};

const convertTime = (hours: number, minutes: number, offset: number) => {
  if (offset < 0) {
    offset = offset + 24 * 60 * 60 * 1000;
  }
  let newTime = (hours * 60 + minutes) * 60 * 1000 + offset;
  newTime = newTime % (24 * 60 * 60 * 1000);
  const convertedHours = Math.floor(newTime / (60 * 60 * 1000));
  const dividend = convertedHours !== 0 ? convertedHours : 1;
  const convertedMinutes = (newTime % (dividend * 60 * 60 * 1000)) / (60 * 1000);
  return [convertedHours, convertedMinutes];
};

export const getWeekNumber = (date: Date): number => {
  const beginningOfYear = new Date(date.getFullYear(), 0, 0);
  const daysInYear = (date.getTime() - beginningOfYear.getTime()) / ONE_DAY_IN_MILLISECONDS + 1; // Add 1 to include the day itself( inclusive mode )
  return Math.ceil((daysInYear + date.getDay()) / 7);
};

export const isInFirstWeekOfYear = (date: Date): boolean => {
  return getWeekNumber(date) === 1;
};

export function toUtc(date: Date): Date {
  return zonedTimeToUtc(date, 'UTC');
}
