import { inject, Injectable } from '@angular/core';
import { Route } from '@angular/router';
import { FormType } from '@klg/quote-tool/shared/types';
import { SessionStorageService } from '@klg/shared/storage';
import { getConfiguration } from '@klg/shared/tokens';
import { ROUTE_IDS, StepDefinition } from '@klg/quote-tool/shared/types';
import { StepService } from '@klg/quote-tool/shared/services/step-service';
import { SendQuoteToEmailComponent } from './send-quote-to-email.component';

const STORAGE_KEY_QT_DEPOSITS_IS_ENABLED = 'QT_DEPOSITS_IS_ENABLED';

@Injectable({
  providedIn: 'root',
})
export class SendQuoteToEmailResolver {
  private stepDefinition: StepDefinition = {
    stepId: ROUTE_IDS.SEND_QUOTE_TO_EMAIL,
    previousStep: () => ROUTE_IDS.QUOTE_FORM,
    errorStep: () => ROUTE_IDS.QUOTE_ERROR,
  };
  private readonly configuration = getConfiguration();

  constructor(private readonly sessionStorage: SessionStorageService, private readonly stepService: StepService) {}

  resolve(): StepDefinition {
    return { ...this.stepDefinition, nextStep: () => (this.paymentsAreEnabled() ? ROUTE_IDS.BOOKING_ENROLMENT_PAYMENT : ROUTE_IDS.THANK_YOU) };
  }

  private paymentsAreEnabled() {
    const depositsIsEnabled = Boolean(this.sessionStorage.get(STORAGE_KEY_QT_DEPOSITS_IS_ENABLED));
    const allowedFormTypes = [FormType.ENROLLMENT].includes(this.stepService.getFormType());

    return (allowedFormTypes && (this.configuration.QUOTE_TOOL_ENROLMENT as boolean)) || depositsIsEnabled;
  }
}

export const stepRoute: Route = {
  path: ROUTE_IDS.SEND_QUOTE_TO_EMAIL,
  resolve: {
    stepDefinition: () => inject(SendQuoteToEmailResolver).resolve(),
  },
  component: SendQuoteToEmailComponent,
};
