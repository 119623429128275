<div class="quote-price-checklist">
  <h5 class="quote-price-checklist__label">{{ label }}</h5>
  <kng-key-point
    *ngFor="let keypoint of keyPoints"
    [icon]="icon"
    [text]="keypoint.description"
    [additionalText]="keypoint.additionalDescription"
    [price]="keypoint.price | klgPrice: { currency: currency, minDecimalPlaces: decimalPlaces }"
  ></kng-key-point>
</div>
