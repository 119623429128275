<kng-form-field-selector
  class="school-selector"
  [label]="label"
  [disabled]="disabled"
  [value]="value"
  valueExpr="code"
  [itemList]="itemList"
  [buttonView]="buttonView"
  [placeholder]="placeholder"
  [hideHelpMeChoose]="true"
  [isOpen]="isOpen"
  [autofill]="autofill"
  [required]="required"
  [sortCriteria]="sortCriteria"
  (valueChange)="valueChange.emit($event)"
  (itemChange)="emitItemChange($event)"
>
  <ng-template #optionItemTemplate let-item let-showDetails="showDetails" let-isOpen="isOpen">
    <kng-item-card
      class="school-selector__item"
      [mode]="showDetails ? 'back' : 'cover'"
      [item]="school(item)"
      [showMoreDetails]="isOpen"
      (moreDetailsClicked)="popupSchool = school(item)"
      [hasPromotion]="school(item).hasPromotion"
      titleExpression="name"
      imageExpression="thumbnailSmall"
      [attr.data-qa]="'school-selector-' + school(item).code"
      (showOfferPopup)="showOfferPopup(school(item).code)"
      [googleAnalyticsClickEvent]="GoogleAnalyticsEvents.QUOTE_THUMBNAIL_CITY"
      [googleAnalyticsOfferLabelClickEvent]="GoogleAnalyticsEvents.QUOTE_PROMOTION_SCHOOL"
    >
    </kng-item-card>
  </ng-template>
</kng-form-field-selector>

<kng-details-popup
  *ngIf="popupSchool"
  [images]="popupSchool.galleryUrls"
  [isJuniors]="false"
  (ctaClicked)="onDetailsPopupCtaClicked()"
  (popupClosed)="popupSchool = null"
  [googleAnalyticsClickEvent]="GoogleAnalyticsEvents.QUOTE_HIGHLIGHT_CITY"
>
  <h6 class="school-selector__popup-title">{{ popupSchool?.name }}</h6>
  <div class="school-selector__popup-description">{{ popupSchool?.description }}</div>
</kng-details-popup>
