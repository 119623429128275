import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { deepClone } from '@klg/shared/utils';
import { firstValueFrom } from 'rxjs';
import { Language, LanguageService } from '@klg/language';
import { SortingCriteria } from '@klg/shared/types';
import { AvailableLanguage } from '@klg/quote-tool/shared/types';

@Component({
  selector: 'kng-language-selector',
  templateUrl: './language-selector.component.html',
  styleUrls: ['./language-selector.component.scss'],
})
export class LanguageSelectorComponent implements OnInit {
  @Input() value: string = undefined;
  @Input() buttonView = false;
  @Input() label: string;
  @Input() placeholder: string;
  @Input() disabled = false;
  @Input() required = false;
  @Input() sortCriteria: SortingCriteria<Language> = [{ property: 'name' }];
  @Input() set codes(codes: string[]) {
    this._codes = codes;
    this.initializeData();
  }
  @Output() valueChange = new EventEmitter<AvailableLanguage>();

  private _codes: string[];
  public dataSource: Array<Language> = null;
  public selectedValue: Language;

  constructor(private languageService: LanguageService) {}

  public languageSelected(language: any) {
    this.valueChange.emit(language.value);
  }

  private async initializeData() {
    const allLanguages = await firstValueFrom(this.languageService.getAll());
    this.dataSource = allLanguages.map(deepClone);
    if (this._codes) this.dataSource = this.dataSource.filter((language) => this._codes.includes(language.code));
    if (this.value) this.selectedValue = this.dataSource?.find((language) => language.code === this.value);
  }

  ngOnInit() {
    this.initializeData();
  }
}
