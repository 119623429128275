import { NumericCodeAndName } from '@klg/shared/types';

export const retrieveAccommodationTypes: () => NumericCodeAndName[] = () => {
  const accommodationTypes: string[] = [
    $localize`Family stay`,
    $localize`Residence`,
    $localize`Shared apartment`,
    $localize`Campus`,
    $localize`Without accommodation`,
  ];

  return accommodationTypes.map((accommodationType, index) => ({
    code: index,
    name: accommodationType,
  }));
};
