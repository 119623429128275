import { ItemApiDto, QuoteApiDto, QuoteDiscountsApiDto, QuoteProductApiDto } from '@klg/shared/api/models';
import { QuoteInput } from './quote-input.model';

export const ProductType = QuoteProductApiDto.ClassificationApiDtoEnum;
export type ProductType = QuoteProductApiDto.ClassificationApiDtoEnum;
export const TransferTypeEnum = QuoteProductApiDto.TransferServiceTypeApiDtoEnum;
export type TransferType = QuoteProductApiDto.TransferServiceTypeApiDtoEnum;

export type QuoteOutputApi = QuoteApiDto;
export type QuoteOutput = QuoteApiDto & { input?: QuoteInput };
export type QuoteProduct = QuoteProductApiDto;
export type QuoteDiscounts = QuoteDiscountsApiDto;

export type QuoteOutputItem = ItemApiDto;
