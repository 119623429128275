import { formatDate } from '@angular/common';
import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { calculateEndDate } from '@klg/quote-tool/shared/data-access/quote';
import { AgeGroup, FormType, CourseDatesSectionPayload, CourseQuoteInput, QuoteInput, QuoteOutput, QuoteSchool } from '@klg/quote-tool/shared/types';
import { CodeAndName, NumericCodeAndName } from '@klg/shared/types';
import { addWeeks, deepClone, getDateFromString, getDisabledDaysForRange, getToday, isEmptyObj } from '@klg/shared/utils';
import { getLocale } from '@klg/shared/i18n';
import { Course, CourseLevel, CourseType } from '@klg/shared/data-access/types';
import {
  CourseDates,
  getCourseByCode,
  getCourseByCourseDate,
  getCourseDatesByCourseAndLevel,
  getCourseDurations,
  getCourseFirstAvailableDate,
  getCoursesByCodes,
  getFirstAvailableDate,
  hasCourseDatesAvailableByLevelAndMinYear,
} from '@klg/shared/data-access/course';
import { getSchoolType, ProductSchoolsService, School } from '@klg/shared/data-access/school';
import { Observable, of, Subscription } from 'rxjs';
import { catchError, debounceTime, map, mergeMap, take, tap } from 'rxjs/operators';
import { getAvailableCourseTypes } from '@klg/quote-tool/shared/utils/school';
import { findConnectedCourses } from '@klg/shared/utils-course';
import { StepService } from '@klg/quote-tool/shared/services/step-service';
import { HostDataReaderService } from '@klg/quote-tool/shared/services';
import { DatePickerFieldDate, DatePickerFieldMonthAndYear } from '@klg/quote-tool/shared/ui/components';
import { retrieveAccommodationTypes } from '../../../shared/functions/accommodation.functions';

interface CourseOptions {
  courses: Array<CodeAndName & { weight: number }>;
  availableWeeksValues: number[];
  disabledDates: Date[];
  availableLevels: string[];
  minDate: Date;
}

@Component({
  selector: 'kng-course-dates-form-section',
  templateUrl: './course-dates.component.html',
  styleUrls: ['./course-dates.component.scss'],
})
export class CourseDatesFormSectionComponent implements OnDestroy, OnInit {
  @Input() sectionIndex: string | number;
  @Input() formType: FormType;

  public loading: boolean;

  public sectionModel: CourseDatesSectionPayload = {} as CourseDatesSectionPayload;
  public specialRequests = '';

  // Select option items
  public availableCourseTypes: string[];
  public accommodationTypes = retrieveAccommodationTypes();
  public courseOptions: CourseOptions[];
  private allSchools: QuoteSchool[] = [];

  private today = getToday();

  private latestEndDate: Date | null = null;

  // School-course Data
  public productSchool: School;

  public showDisclaimerDatesToBeConfirmed = false;
  public ageGroup: AgeGroup;

  private locale = getLocale();
  private subscription = new Subscription();

  constructor(
    private stepService: StepService,
    private productSchoolService: ProductSchoolsService,
    private hostDataReader: HostDataReaderService,
    private route: ActivatedRoute,
  ) {
    this.ageGroup = this.stepService.getActiveAgeGroup();
  }

  ngOnInit() {
    // Get allSchools from resolved data
    this.allSchools = deepClone(this.route.snapshot.data?.data?.schools || []);

    this.resetCourses(false);
    this.subscription.add(
      this.loadStoredData$()
        .pipe(mergeMap(() => this.resetOnChanges$()))
        .subscribe(),
    );
    this.subscription.add(this.getEndDateFromActiveQuote$().subscribe());
  }

  ngOnDestroy() {
    this.subscription.unsubscribe();
  }

  /**
   * Recover previously stored data (pre-filled courseCode + weeks or user filled data)
   */
  private loadStoredData$(): Observable<QuoteInput> {
    return this.hostDataReader.getPrefilledSchoolCourse$().pipe(
      mergeMap(() => this.stepService.quoteRequest$),
      take(1),
      tap(({ destinationCurrency, schoolType }: QuoteInput) => {
        Object.assign(this.sectionModel, {
          destinationCurrency,
          schoolType,
        });
      }),
      mergeMap((quoteInput: QuoteInput) => this.loadSchool$(quoteInput.school).pipe(map(() => quoteInput))),
      take(1),
      tap(({ courses }: QuoteInput) => {
        if (courses?.length) {
          courses.forEach(({ level, code, startDate, startDateObject, weeks, courseType }: CourseQuoteInput, index: number) => {
            if (level?.code) {
              this.addCourse(index, false);
              if (this.hasCourseTypeSelector() && courseType) {
                this.courseTypeChanged(index, courseType);
              }
              if (this.hasLanguageLevelSelector() && level) {
                this.levelChange(index, level, false);
              }
              if (this.hasCourseSelector()) {
                this.courseChanged(index, code, false);
              }
              this.startDateChange(index, { dateObject: startDateObject ?? getDateFromString(startDate), dateString: startDate }, false);
              this.durationChange(index, weeks, false);
            }
          });
        }
      }),
    );
  }

  private loadSchool$(newSchoolCode: string): Observable<School> {
    if (newSchoolCode) {
      this.loading = true;
      return this.productSchoolService.get(newSchoolCode).pipe(
        tap((schoolData: School) => {
          this.productSchool = {
            ...schoolData,
            // Filter courses by quotable (KLG-3929)
            courses: schoolData?.courses?.filter((course: Course) => Boolean(course.quotable && getCourseFirstAvailableDate(course, this.today))),
          };

          // Update destinationCurrency?
          let partialInput = {};
          if (schoolData?.currencyCode !== this.sectionModel.destinationCurrency) {
            this.sectionModel.destinationCurrency = schoolData?.currencyCode;
            partialInput = { destinationCurrency: schoolData?.currencyCode };
          }

          // Update schoolType??
          const schoolType = getSchoolType(schoolData);
          if (this.sectionModel.schoolType !== schoolType) {
            this.sectionModel.schoolType = schoolType;
            partialInput = { ...partialInput, schoolType };
          }

          // Update QuoteInput?
          if (!isEmptyObj(partialInput)) {
            this.stepService.updatePartialRequest(partialInput);
          }

          // Re-fill courses select
          this.sectionModel.courses.forEach((course: CourseQuoteInput, index: number) => {
            if (
              (this.hasLanguageLevelSelector() && course.level) ||
              (this.hasCourseTypeSelector() && course.courseType) ||
              (!this.hasLanguageLevelSelector() && !this.hasCourseTypeSelector())
            ) {
              this.fillCourses(index);
            }
          });

          this.loading = false;
        }),
        catchError((error) => {
          console.error(error);
          this.loading = false;
          return of(null);
        }),
      );
    } else if (newSchoolCode === '' && this.formType === FormType.PRICE_REQUEST) {
      const emptySchool: School = {
        code: newSchoolCode,
        name: $localize`I don't know`,
      };
      this.productSchool = emptySchool;
      return of(emptySchool);
    } else {
      return of(null);
    }
  }

  private resetOnChanges$(): Observable<QuoteInput> {
    return this.stepService.quoteRequest$.pipe(
      debounceTime(100),
      mergeMap((quoteInput: QuoteInput) => {
        const { languageCode, country, destinationCity, schoolObject: newSchool, schoolType } = quoteInput;

        // Clear data on school change
        const schoolChanged = (this.productSchool && !newSchool) || newSchool?.code !== this.productSchool?.code;
        const schoolOptionChosen: boolean = this.formType === FormType.PRICE_REQUEST ? newSchool?.code != null : !!newSchool?.code;
        if (schoolChanged) {
          if (schoolType !== undefined) {
            this.sectionModel.schoolType = undefined;
          }
          this.resetCourses(true);
        }

        this.availableCourseTypes = newSchool?.code
          ? newSchool.courseTypes
          : getAvailableCourseTypes(this.allSchools, languageCode, country?.code, destinationCity?.code);

        if (!schoolOptionChosen) {
          this.productSchool = undefined;
        }
        // Get the school details for current selection
        const requestProductSchoolData = schoolChanged && schoolOptionChosen;
        return requestProductSchoolData ? this.loadSchool$(newSchool.code).pipe(map(() => quoteInput)) : of(quoteInput);
      }),
    );
  }

  private removeCoursesAfter(index: number, storeChanges = true) {
    for (let i = this.sectionModel.courses?.length - 1; i > index; i--) {
      this.removeCourse(i, false);
    }

    if (storeChanges) {
      this.storeSectionData();
    }
  }

  private storeSectionData() {
    this.stepService.updatePartialRequest<CourseDatesSectionPayload>(this.sectionModel);
    if (this.specialRequests) {
      this.stepService.setPartialStudentDetails({ specialRequests: this.specialRequests });
    }
  }

  private isAtLeastOneCourseSelected(): boolean {
    return this.sectionModel.courses?.length > 1;
  }

  private resetCourseDates(index: number) {
    this.startDateChange(index, undefined, false);
    this.durationChange(index, undefined, false);

    const dateFrom: Date = this.isAtLeastOneCourseSelected() ? this.getLastCourseEndDate(index) : this.today;
    const courseInput = this.getCourseInput(index);
    const courseObject: Course = this.getProductSchoolCourseByCode(courseInput.code);
    const availableDates = getCourseDatesByCourseAndLevel(this.productSchool?.courses, courseObject, courseInput.level);
    const firstAvailableDate = getFirstAvailableDate(availableDates, dateFrom, index > 0);
    this.courseOptions[index].minDate = new Date(firstAvailableDate);
    if (this.courseOptions[index].disabledDates?.length) {
      // KLG-4846 Reset disabled dates on course change
      this.courseOptions[index].disabledDates = undefined;
    }
    this.fillCalendar(index, this.courseOptions[index].minDate.getFullYear(), this.courseOptions[index].minDate.getMonth());
    this.fillDuration(index);
  }

  private getLastCourseEndDate(index: number): Date {
    if (!this.latestEndDate) {
      return calculateEndDate(this.getCourseInput(index - 1));
    }
    return this.latestEndDate;
  }

  public addCourse(index?: number, storeChanges = true) {
    if (!this.sectionModel?.courses?.length) {
      this.sectionModel.courses = [];
      this.courseOptions = [];
    }

    const newCourseInput = {} as CourseQuoteInput;
    const newCourseOptions = {} as CourseOptions;
    if (index >= 0) {
      this.sectionModel.courses[index] = newCourseInput;
      this.courseOptions[index] = newCourseOptions;
    } else {
      this.sectionModel.courses.push(newCourseInput);
      this.courseOptions.push(newCourseOptions);
    }

    if (storeChanges) {
      this.storeSectionData();
    }
  }

  public removeCourse(index: number, storeChanges = true) {
    this.sectionModel.courses.splice(index, 1);
    this.courseOptions.splice(index, 1);

    if (storeChanges) {
      this.storeSectionData();
    }
  }

  public resetCourses(storeChanges = true) {
    this.sectionModel.courses = undefined;
    this.courseOptions = undefined;
    this.addCourse(0, storeChanges);
  }

  public courseTypeChanged(index: number, newCourseType: CourseType, storeChanges = true) {
    if (this.getCourseInput(index).courseType?.code !== newCourseType?.code) {
      this.getCourseInput(index).courseType = newCourseType;
      if (this.hasLanguageLevelSelector()) {
        this.levelChange(index, undefined, false);
        this.fillLevels(index);
      } else {
        this.courseChanged(index, undefined, false);
        this.fillCourses(index);
      }

      if (storeChanges) {
        this.storeSectionData();
      }
    }
  }

  public levelChange(index: number, aLevel: CourseLevel, storeChanges = true) {
    if (this.getCourseInput(index).level?.code !== aLevel?.code) {
      this.getCourseInput(index).level = aLevel;
      this.courseChanged(index, undefined, false);

      if (this.hasCourseSelector()) {
        this.fillCourses(index);
      } else {
        this.courseOptions[0].minDate = this.today;
      }

      if (storeChanges) {
        this.storeSectionData();
      }
    }
  }

  public courseChanged(index: number, aCourse: string, storeChanges = true) {
    if (this.getCourseInput(index).code !== aCourse) {
      this.getCourseInput(index).code = aCourse;
      const courseObject: Course = this.selectedCourse(index);
      this.getCourseInput(index).name = courseObject?.name;
      this.getCourseInput(index).accommodationMandatory = courseObject?.accommodationMandatory;
      this.getCourseInput(index).relatedCourseCode = null;

      this.resetCourseDates(index);

      if (storeChanges) {
        this.storeSectionData();
      }
    }
  }

  public prepareCalendar(index: number, aDate: DatePickerFieldDate) {
    this.fillCalendar(index, aDate?.dateObject?.getFullYear(), aDate?.dateObject?.getMonth());
  }

  public startDateChange(index: number, aDate: DatePickerFieldDate, storeChanges = true) {
    if (this.getCourseInput(index).startDate === aDate?.dateString) {
      // no changes needed => stop processing
      return;
    }

    this.showDisclaimerDatesToBeConfirmed = false;
    const currentDateConfig = this.getCourseStartDates(index)?.find((date: CourseDates) => date.from === aDate?.dateString);
    this.showDisclaimerDatesToBeConfirmed = currentDateConfig?.confirmed === false;

    this.fillCalendar(index, aDate?.dateObject?.getFullYear(), aDate?.dateObject?.getMonth());
    this.getCourseInput(index).startDate = aDate?.dateString;
    this.getCourseInput(index).startDateObject = aDate?.dateObject;
    this.getCourseInput(index).dateConfirmed = currentDateConfig?.confirmed ?? false;
    this.fillDuration(index, this.getCourseInput(index).startDate);

    // check if we need to get the related product (we do this if there are no dates for the current one)
    const selectedCourse = this.selectedCourse(index);
    if (selectedCourse?.relatedProducts) {
      const relatedCourses = getCoursesByCodes(this.productSchool?.courses, selectedCourse.relatedProducts);
      let relatedCourse = getCourseByCode(relatedCourses, selectedCourse.relatedProducts?.[0]);
      const courseDate = selectedCourse.courseDates.find(({ from }) => from === aDate?.dateString);
      if (!courseDate) {
        // When no date is found for the selected course, then get related course by date
        relatedCourse = getCourseByCourseDate(relatedCourses, aDate?.dateString);
      }

      // do the switch only if there are no course dates for the selected course
      if (!courseDate && relatedCourse) {
        this.switchByRelatedCourse(index, selectedCourse, relatedCourse);
      }
    }

    if (storeChanges) {
      this.storeSectionData();
    }
  }

  private switchByRelatedCourse(index: number, selectedCourse: Course, relatedCourse: Course) {
    const courseInput = this.getCourseInput(index);
    if (!courseInput || !relatedCourse) {
      return;
    }

    const { code, name, accommodationMandatory } = relatedCourse;
    this.sectionModel.courses[index] = {
      ...courseInput,
      code,
      name,
      accommodationMandatory,
      relatedCourseCode: selectedCourse?.code,
    };
  }

  public monthChanged(index: number, { year, month }: DatePickerFieldMonthAndYear) {
    this.fillCalendar(index, year, month + 1);
  }

  public durationChange(index: number, duration: number, storeChanges = true) {
    if (this.getCourseInput(index).weeks !== duration) {
      this.getCourseInput(index).weeks = duration;

      // Remove all other accommodations
      this.removeCoursesAfter(index, false);

      if (storeChanges) {
        this.storeSectionData();
      }
    }
  }

  public accommodationTypeChanged(accommodationType: NumericCodeAndName, storeChanges = true) {
    this.sectionModel.accommodationType = accommodationType?.name.toString() ?? '';
    if (storeChanges) {
      this.storeSectionData();
    }
  }

  public specialRequestsChanged(specialRequestsText: string, storeChanges = true) {
    if (this.specialRequests !== specialRequestsText) {
      this.specialRequests = specialRequestsText;
      if (storeChanges) {
        this.storeSectionData();
      }
    }
  }

  public hasCourseTypeSelector(): boolean {
    return this.formType === FormType.PRICE_REQUEST;
  }

  public hasLanguageLevelSelector(): boolean {
    return true;
  }

  public hasCourseSelector(): boolean {
    return this.formType === FormType.FREE_QUOTE || this.formType === FormType.ENROLLMENT || this.formType === FormType.QUICK_QUOTE_TOOL;
  }

  public hasAccommodationTypeSelector(): boolean {
    return this.formType === FormType.PRICE_REQUEST;
  }

  public hasSpecialRequestsField(): boolean {
    return this.formType === FormType.PRICE_REQUEST;
  }

  public schoolCodeForLevels(): string {
    return this.productSchool ? this.productSchool.code || '1671' : undefined;
  }

  public courseOptionExist(index: number, courseCode: string): boolean {
    return !!this.selectedCourseOption(index, courseCode);
  }

  public hasAvailableCourseOptions(): boolean {
    const minYear = this.getMinYearAvailable(this.courseOptions.length - 1);
    return this.filterCourses(null, null, minYear).length > 0;
  }

  private getMinYearAvailable(index: number) {
    const courseSelected = this.getCourseInput(index);
    if (courseSelected && courseSelected.startDate) {
      return addWeeks(new Date(courseSelected.startDate), courseSelected.weeks ?? 0).getFullYear();
    }
    return new Date().getFullYear();
  }

  private fillLevels(index: number) {
    const selectedCourseType = this.getCourseInput(index)?.courseType?.code;
    const minYear = this.getMinYearAvailable(index - 1);
    this.courseOptions[index].availableLevels =
      this.filterCourses(selectedCourseType, null, minYear)?.reduce((courseLevels: string[], course) => {
        course.supportedLevels.forEach(({ code }: CourseLevel) => {
          if (!courseLevels.includes(code)) {
            courseLevels.push(code);
          }
        });
        return courseLevels;
      }, []) ?? [];
  }

  private fillCourses(index: number) {
    const selectedCourseType = this.getCourseInput(index)?.courseType?.code;
    const selectedLevelCode = this.getCourseInput(index).level?.code;
    const minYear = this.getMinYearAvailable(index - 1);
    this.courseOptions[index].courses =
      this.filterCourses(selectedCourseType, selectedLevelCode, minYear)?.map(({ code, name, type }: Course) => ({
        code,
        name,
        weight: type?.weight,
      })) ?? [];
  }

  private fillCalendar(index: number, year: number, month: number) {
    if (year && typeof month === 'number' && month >= 0) {
      const courseDates = this.getCourseStartDates(index)?.map(({ from }: CourseDates) => from);

      if (courseDates) {
        const date = new Date();
        date.setFullYear(year, month, 1);
        this.setDisabledDates(index, courseDates, date);
      }
    }
  }

  private getCourseStartDates(index: number): CourseDates[] {
    const selectedLevel: CourseLevel = this.getCourseInput(index).level;
    const selectedCourse = this.selectedCourse(index);
    return getCourseDatesByCourseAndLevel(this.productSchool?.courses, selectedCourse, selectedLevel);
  }

  private fillDuration(index: number, date?: string) {
    const selectedLevel = this.sectionModel.courses[index].level;
    const selectedCourse = this.selectedCourse(index);
    this.courseOptions[index].availableWeeksValues = selectedCourse ? getCourseDurations(selectedCourse, selectedLevel, date) : [];
  }

  private filterCourses(selectedCourseType: string, selectedLevelId?: string, minYear?: number): Course[] {
    return findConnectedCourses(this.productSchool?.courses)?.filter(
      (course: Course) =>
        (!selectedCourseType || course.type?.code === selectedCourseType) &&
        (!selectedLevelId || course.supportedLevels?.some((level) => level.code === selectedLevelId)) &&
        (!minYear || hasCourseDatesAvailableByLevelAndMinYear(course, selectedLevelId, minYear)),
    );
  }

  private setDisabledDates(index: number, startingDates: string[], date: Date) {
    if (this.formType === FormType.PRICE_REQUEST) {
      this.courseOptions[index].disabledDates = null;
    } else {
      const numberOfWeeks = 12;
      const disabledDaysForRange = getDisabledDaysForRange(addWeeks(date, -numberOfWeeks), addWeeks(date, numberOfWeeks), [], [], [], 'backward');
      const disabledDaysExcludingStartDates = disabledDaysForRange.filter((aDate) => !startingDates.find((value) => value.includes(this.formatDate(aDate))));
      const previouslyDisabledDates = this.courseOptions[index].disabledDates;

      this.courseOptions[index].disabledDates = (previouslyDisabledDates || []).concat(disabledDaysExcludingStartDates).filter(this.distinctDates);
    }
  }

  private distinctDates(date: Date, pos: number, dateList: Date[]) {
    return dateList.findIndex((aDate) => aDate.getTime() === date.getTime()) === pos;
  }

  private getCourseInput(index: number): CourseQuoteInput {
    return index >= 0 && this.sectionModel.courses?.length > index ? this.sectionModel.courses[index] : undefined;
  }

  private getLatestCourseInput(): CourseQuoteInput {
    return this.getCourseInput(this.sectionModel.courses?.length - 1);
  }

  private formatDate(aDate: Date) {
    return formatDate(aDate, 'yyyy-MM-dd', this.locale);
  }

  private selectedCourse(index: number): Course {
    const courseCode = this.getCourseInput(index)?.code;
    return this.getProductSchoolCourseByCode(courseCode);
  }

  private getProductSchoolCourseByCode(courseCode: string): Course {
    return getCourseByCode(this.productSchool?.courses, courseCode);
  }

  private selectedCourseOption(index: number, courseCode: string): Course {
    return this.courseOptions[index]?.courses?.find(({ code }) => code === courseCode);
  }

  private getEndDateFromActiveQuote$() {
    return this.stepService.requestActiveQuote().pipe(
      tap(({ endDate }: QuoteOutput) => {
        const courseCode = this.getLatestCourseInput()?.code;
        if (courseCode && endDate) {
          this.latestEndDate = getDateFromString(endDate);
        }
      }),
    );
  }
}
