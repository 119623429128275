/**
 * KLG Quotes API
 * This is KLG API to perform quote calculations and retrieve auxiliary pricing information.
 *
 * The version of the OpenAPI document: dev
 * Contact: xxx@kaplan.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

/**
 * The type of bathroom
 */
export type BathroomTypeApiDto = 'SHARED' | 'PRIVATE';

export const BathroomTypeApiDto = {
  SHARED: 'SHARED' as BathroomTypeApiDto,
  PRIVATE: 'PRIVATE' as BathroomTypeApiDto,
};
