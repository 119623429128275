/**
 * KLG Quotes API
 * This is KLG API to perform quote calculations and retrieve auxiliary pricing information.
 *
 * The version of the OpenAPI document: dev
 * Contact: xxx@kaplan.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { DuringYourStayUserDataApiDto } from './during-your-stay-user-data.model';

export interface QuoteLeadUserDataApiDto {
  title?: QuoteLeadUserDataApiDto.TitleApiDtoEnum;
  firstName?: string;
  lastName?: string;
  identityDocument?: string;
  guardianFirstName?: string;
  guardianLastName?: string;
  guardianEmail?: string;
  guardianPhone?: string;
  email?: string;
  phoneNumber?: string;
  countryOfResidence?: string;
  address?: string;
  address2?: string;
  /**
   * Sales force region code. By Email My Quotes feature
   */
  regionCode?: string;
  /**
   * Student residency city.
   */
  city?: string;
  /**
   * Student residency postal code.
   */
  postcode?: string;
  /**
   * Student region, state or province address.
   */
  regionStateProvince?: string;
  accommodationType?: string;
  dateOfBirth?: string;
  placeOfBirth?: string;
  nationality?: string;
  nationalityName?: string;
  motherTongue?: string;
  motherTongueName?: string;
  consentResellers?: boolean;
  consentMarketing?: boolean;
  whatsappOptIn?: boolean;
  publication?: string;
  recordType?: string;
  leadType?: string;
  leadSource?: string;
  websiteLanguage?: string;
  displayPhone?: boolean;
  duringYourStay?: DuringYourStayUserDataApiDto;
  selectedInsuranceOptions?: Array<QuoteLeadUserDataApiDto.SelectedInsuranceOptionsApiDtoEnum>;
  roiFirst?: string;
  roiCurrent?: string;
  urlOrigin?: string;
  urlCurrent?: string;
  urlReferer?: string;
  referredID?: string;
  specialRequests?: string;
  courseType?: string;
  hasAllergiesConditionsOrTreatments?: boolean;
  allergiesConditionsOrTreatments?: string;
  isMinor?: boolean;
  emergencyContactFirstName?: string;
  emergencyContactLastName?: string;
  emergencyContactEmail?: string;
  emergencyContactPhone?: string;
  termsAndConditionsConsent?: boolean;
  hasMedicalInsuranceProof?: boolean;
  quoteId?: string;
}
export namespace QuoteLeadUserDataApiDto {
  export type TitleApiDtoEnum = 'MR' | 'MRS' | 'MS' | 'MISS';
  export const TitleApiDtoEnum = {
    MR: 'MR' as TitleApiDtoEnum,
    MRS: 'MRS' as TitleApiDtoEnum,
    MS: 'MS' as TitleApiDtoEnum,
    MISS: 'MISS' as TitleApiDtoEnum,
  };
  export type SelectedInsuranceOptionsApiDtoEnum =
    | 'CANCELLATION_INSURANCE'
    | 'TRAVEL_AND_MEDICAL_INSURANCE'
    | 'SECOND_EXAM_CERTIFICATION_GUARANTEE'
    | 'EXAM_FREE_CANCELLATION_GUARANTEE'
    | 'FLEX_BOOKING'
    | 'MORE_INFORMATION';
  export const SelectedInsuranceOptionsApiDtoEnum = {
    CANCELLATION_INSURANCE: 'CANCELLATION_INSURANCE' as SelectedInsuranceOptionsApiDtoEnum,
    TRAVEL_AND_MEDICAL_INSURANCE: 'TRAVEL_AND_MEDICAL_INSURANCE' as SelectedInsuranceOptionsApiDtoEnum,
    SECOND_EXAM_CERTIFICATION_GUARANTEE: 'SECOND_EXAM_CERTIFICATION_GUARANTEE' as SelectedInsuranceOptionsApiDtoEnum,
    EXAM_FREE_CANCELLATION_GUARANTEE: 'EXAM_FREE_CANCELLATION_GUARANTEE' as SelectedInsuranceOptionsApiDtoEnum,
    FLEX_BOOKING: 'FLEX_BOOKING' as SelectedInsuranceOptionsApiDtoEnum,
    MORE_INFORMATION: 'MORE_INFORMATION' as SelectedInsuranceOptionsApiDtoEnum,
  };
}
