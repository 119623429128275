import { Injectable } from '@angular/core';
import { ProductsControllerApiService } from '@klg/shared/api/services';
import { Language } from '../models/language.model';
import { BehaviorSubject, Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { ProductPackage, ProductPackageEnum } from '@klg/quote-tool/shared/types';
import { BaseService, CachedRequestResponse } from '@klg/shared/utils-http';

@Injectable({
  providedIn: 'root',
})
export class LanguageService extends BaseService<Language> {
  private productPackage$ = new BehaviorSubject<ProductPackage | 'default'>('default');

  private responseCaches = {
    default: new CachedRequestResponse<Language[]>(() => this.productsApiService.getCourseLanguages(this.locale, this.companyHeader), this.cacheSettings.get),
    [ProductPackageEnum.YOUNG_LEARNER_CAMPS]: new CachedRequestResponse<Language[]>(
      () => this.productsApiService.getCourseLanguages(this.locale, this.companyHeader, ProductPackageEnum.YOUNG_LEARNER_CAMPS),
      this.cacheSettings.get,
    ),
  };

  constructor(private productsApiService: ProductsControllerApiService) {
    super();
  }

  protected getResponseCache$(): Observable<CachedRequestResponse<Language[]>> {
    return this.productPackage$.pipe(map((productPackage) => this.responseCaches[productPackage]));
  }

  public changeProductPackage(packageType: ProductPackage) {
    this.productPackage$.next(packageType ? packageType : 'default');
  }

  public getByCode(code: string): Observable<Language> {
    return this.getAll().pipe(map((languages) => languages.find((l) => l.code === code)));
  }
}
