import { FlywireConfigurationVariables } from '../types/gateways/flywire-configuration-variables.type';

export const FLYWIRE_PATH = 'https://payment.flywire.com/assets/js/checkout.js';
export const DEFAULT_RECIPIENT = 'KEU';
export const CALLBACK_VERSION = '2';
const PROVIDER = 'embed2.0';
const CALLBACK_URL = '/quotes/v1/deposit-status';
const ENVIRONMENT = 'demo';

export const DEFAULT_RETURN_URL = `${window.location.origin}/quote/payment`;

export type CurrencyKey = 'CHF' | 'USD' | 'EUR' | 'GBP' | 'CAD' | 'SEK';

export const FLYWIRE_DEFAULT_VALUES: FlywireConfigurationVariables = {
  provider: PROVIDER,
  returnUrl: DEFAULT_RETURN_URL,
  environment: ENVIRONMENT,
  callbackUrl: CALLBACK_URL,
};
