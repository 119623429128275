import { animate, state, style, transition, trigger } from '@angular/animations';

export const changeVisibilityAnimation = [
  trigger('changeVisibility', [
    state('open', style('*')),
    state('folded', style({ opacity: 0, margin: 0, borderTop: 0, borderBottom: 0, height: 0, minHeight: 0, overflow: 'hidden' })),
    transition('void => folded', animate(0)),
    transition('open => folded', animate('200ms ease-in')),
    transition('folded => open', animate('200ms ease-out')),
  ]),
];
