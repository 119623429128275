<kng-section-template [sectionIndex]="sectionIndex" [sectionTitle]="sectionTitle">
  <ng-container *ngTemplateOutlet="isAdultSchool() ? adultTemplate : juniorTemplate"></ng-container>
</kng-section-template>

<ng-template #adultTemplate>
  <kng-form-field
    [isValid]="formData.contactPersonFirstName.isValid"
    [required]="formData.contactPersonFirstName.required"
    [small]="true"
    i18n-label
    label="Emergency Contact person - First name"
    data-qa="enrolment-emergency-contact-first-name"
  >
    <input pInputText type="text" (input)="onValueChanges('contactPersonFirstName')" [(ngModel)]="formData.contactPersonFirstName.value" />
  </kng-form-field>
  <kng-form-field
    [isValid]="formData.contactPersonLastName.isValid"
    [required]="formData.contactPersonLastName.required"
    [small]="true"
    i18n-label
    label="Emergency Contact person - Last name"
    data-qa="enrolment-emergency-contact-last-name"
  >
    <input pInputText type="text" (input)="onValueChanges('contactPersonLastName')" [(ngModel)]="formData.contactPersonLastName.value" />
  </kng-form-field>
  <kng-form-field
    [isValid]="formData.contactPersonPhone.isValid"
    [required]="formData.contactPersonPhone.required"
    [small]="true"
    i18n-label
    label="Emergency Contact person - mobile"
    data-qa="enrolment-emergency-contact-mobile"
  >
    <input pInputText type="text" (input)="onValueChanges('contactPersonPhone')" [(ngModel)]="formData.contactPersonPhone.value" />
  </kng-form-field>
</ng-template>

<ng-template #juniorTemplate>
  <kng-form-field
    [isValid]="formData.legalRepresentativeFirstName.isValid"
    [required]="formData.legalRepresentativeFirstName.required"
    [small]="true"
    i18n-label
    label="First name"
    data-qa="enrolment-juniors-legal-representative-first-name"
  >
    <input pInputText type="text" (input)="onValueChanges('legalRepresentativeFirstName')" [(ngModel)]="formData.legalRepresentativeFirstName.value" />
  </kng-form-field>
  <kng-form-field
    [isValid]="formData.legalRepresentativeLastName.isValid"
    [required]="formData.legalRepresentativeLastName.required"
    [small]="true"
    i18n-label
    label="Last name"
    data-qa="enrolment-juniors-legal-representative-last-name"
  >
    <input pInputText type="text" (input)="onValueChanges('legalRepresentativeLastName')" [(ngModel)]="formData.legalRepresentativeLastName.value" />
  </kng-form-field>
  <kng-form-field
    [isValid]="formData.legalRepresentativeEmail.isValid"
    [required]="formData.legalRepresentativeEmail.required"
    [small]="true"
    i18n-label
    label="Email address"
    data-qa="enrolment-juniors-legal-representative-email"
  >
    <input pInputText type="text" (input)="onValueChanges('legalRepresentativeEmail')" [(ngModel)]="formData.legalRepresentativeEmail.value" />
  </kng-form-field>
  <kng-form-field
    [isValid]="formData.legalRepresentativeMobile.isValid"
    [required]="formData.legalRepresentativeMobile.required"
    [small]="true"
    i18n-label
    label="Phone number"
    data-qa="enrolment-juniors-legal-representative-phone"
  >
    <input pInputText type="tel" (input)="onValueChanges('legalRepresentativeMobile')" [(ngModel)]="formData.legalRepresentativeMobile.value" />
  </kng-form-field>
  <kng-form-field
    [isValid]="formData.legalRepresentativeAddress.isValid"
    [required]="formData.legalRepresentativeAddress.required"
    [small]="true"
    i18n-label
    label="Address (if different)"
    data-qa="enrolment-juniors-legal-representative-address-different"
  >
    <input pInputText type="text" (input)="onValueChanges('legalRepresentativeAddress')" [(ngModel)]="formData.legalRepresentativeAddress.value" />
  </kng-form-field>
</ng-template>
