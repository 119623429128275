import { Injectable } from '@angular/core';
import { COMPANIES } from '@klg/shared/types';
import { BehaviorSubject, Observable } from 'rxjs';
import { getCompany } from '@klg/shared/tokens';
import { QuoteFormLinkUrls } from '@klg/quote-tool/shared/types/quote';

@Injectable()
export class QuoteFormLinkUrlsService {
  public readonly company = getCompany();

  private linkUrlsSubject = new BehaviorSubject<QuoteFormLinkUrls>({} as QuoteFormLinkUrls);

  private TERMS_AND_CONDITIONS = {
    [COMPANIES.KAPLAN]: $localize`:Privacy policy link for Kaplan@@qt-form-kaplan:https://www.kaplaninternational.com/privacy-notice`,
    [COMPANIES.ALPADIA]: $localize`:Privacy policy link for Alpadia@@qt-form-alpadia:https://www.alpadia.com/en/privacy`,
    [COMPANIES.ESL]: $localize`:Privacy policy link for ESL@@qt-form-esl-terms:https://www.esl-languages.com/terms-and-conditions`,
  };
  private MORE_INFO = {
    [COMPANIES.KAPLAN]: $localize`:More info link for Kaplan@@qt-form-kaplan:https://www.kaplaninternational.com/privacy-notice`,
    [COMPANIES.ALPADIA]: $localize`:More info link for Alpadia@@qt-form-alpadia:https://www.alpadia.com/en/privacy`,
    [COMPANIES.ESL]: $localize`:More info link for ESL@@qt-form-esl-privacy:https://www.esl-languages.com/privacy-policy`,
  };

  public setLinkUrls(linkUrls: QuoteFormLinkUrls) {
    // It will default to the prior translation strategy if no moreInfoLinkUrl and termsAndConditionsLinkUrl are provided when instantiating the quote-tool
    this.linkUrlsSubject.next({
      confirmationLinkUrl: linkUrls.confirmationLinkUrl,
      moreInfoLinkUrl: linkUrls.moreInfoLinkUrl || this.MORE_INFO[this.company],
      termsAndConditionsLinkUrl: linkUrls.termsAndConditionsLinkUrl || this.TERMS_AND_CONDITIONS[this.company],
    });
  }

  public getLinkUrls$(): Observable<QuoteFormLinkUrls> {
    return this.linkUrlsSubject.asObservable();
  }
}
