import { Component, inject, OnDestroy, OnInit, Type } from '@angular/core';
import { hasEnoughDataToGetSupplements } from '@klg/quote-tool/shared/data-access/quote';
import { QuoteInput, StudentDetails } from '@klg/quote-tool/shared/types/quote';
import { FormType, StepDefinition, StepDefinitionExperiment } from '@klg/quote-tool/shared/types';
import { BehaviorSubject, combineLatest, Subject, Subscription, takeUntil } from 'rxjs';
import { debounceTime } from 'rxjs/operators';
import { getCountryOfResidenceFromDomain } from '../../../shared/functions/get-country-of-residence-from-domain.function';
import { tellUsAboutYouSectionIndex } from '../../../shared/functions/section-index.functions';
import { isStepQuoteFormValid } from '../../../shared/functions/step-validation.function';
import { StepService } from '@klg/quote-tool/shared/services/step-service';
import { CookieService } from 'ngx-cookie-service';
import { LoggerService } from '@klg/shared/logger';

@Component({
  selector: 'kng-quote-form-step',
  templateUrl: './quote-form.component.html',
  styleUrls: ['./quote-form.component.scss'],
})
export class QuoteFormComponent implements OnInit, OnDestroy {
  public formType: FormType;
  public tellUsAboutYouSectionIndex: number;

  // Component to be used in the experiment (this will be specified in the step definition)
  experimentComponent: Type<unknown>;

  private hasEnoughDataToGetSupplements = false;
  private isStepValid$ = new BehaviorSubject(false);
  private subscription = new Subscription();

  private destroySubscription$: Subject<void> = new Subject<void>();

  private readonly cookieService = inject(CookieService);
  private readonly stepService = inject(StepService);
  private readonly logger = inject(LoggerService);

  ngOnInit() {
    this.formType = this.stepService.getFormType();
    this.setCountryByDomain();
    this.stepService.setStepValid(this.isStepValid$);

    this.subscription.add(
      combineLatest([this.stepService.quoteRequest$, this.stepService.studentDetails$])
        .pipe(debounceTime(160))
        .subscribe(([quoteInput, studentDetails]: [QuoteInput, StudentDetails]) => {
          this.hasEnoughDataToGetSupplements = hasEnoughDataToGetSupplements(quoteInput);
          this.tellUsAboutYouSectionIndex = tellUsAboutYouSectionIndex(this.formType, quoteInput.hasSupplementsAvailable);
          this.isStepValid$.next(isStepQuoteFormValid(quoteInput, studentDetails));
        }),
    );

    this.stepService.currentStepDefinition$.pipe(takeUntil(this.destroySubscription$)).subscribe((stepDefinition) => {
      this.loadExperiments(stepDefinition);
      this.destroySubscription$.next();
    });
  }

  ngOnDestroy() {
    this.subscription.unsubscribe();
  }

  /**
   * It loads the experiments in case the step definition has associated experiments.
   * If the step has experiments we will load the experiment components in case
   * the experimentId is active by cookie.
   */
  private loadExperiments(stepDefinition: StepDefinition) {
    const experiments = stepDefinition.experiments;

    // If there are no experiments, return
    if (!experiments || experiments.length === 0) {
      return;
    }

    // If the step has associated experiments, take the ones that are active (by cookie)
    const activeExperiments: StepDefinitionExperiment[] = [];
    experiments.forEach((experiment) => {
      const { experimentId } = experiment;
      if (this.cookieService.check(experimentId) && this.cookieService.get(experimentId) === 'true') {
        activeExperiments.push(experiment);
      }
    });

    // If there are not any active experiment, set to undefined the experiment component
    if (!activeExperiments.length) {
      this.experimentComponent = undefined;
      return;
    }

    // If we have more than one active experiment, show an error message and not get any experiment component
    if (activeExperiments.length > 1) {
      this.logger.error(`There are more than one active experiment in the step quote-form for form type ${this.formType}`);
      this.experimentComponent = undefined;
    } else {
      // Else, assign the experiment component to the active experiment
      const [{ experimentComponent, hideCtas }] = activeExperiments;
      this.experimentComponent = experimentComponent;
      stepDefinition.hideCtas = hideCtas;
    }
  }

  public isFreeQuoteFormType(): boolean {
    return this.formType === FormType.FREE_QUOTE;
  }

  public isPriceRequestFormType(): boolean {
    return this.formType === FormType.PRICE_REQUEST;
  }

  public showTellUsAboutYou(): boolean {
    return this.isPriceRequestFormType() || (this.isFreeQuoteFormType() && this.hasEnoughDataToGetSupplements);
  }

  public showTermsAndConditions(): boolean {
    return this.isPriceRequestFormType() || (this.isFreeQuoteFormType() && this.hasEnoughDataToGetSupplements);
  }

  private setCountryByDomain() {
    this.stepService.setPartialStudentDetails({ countryOfResidence: getCountryOfResidenceFromDomain() });
  }
}
