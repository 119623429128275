import { Injectable, inject } from '@angular/core';
import { ProductsControllerApiService } from '@klg/shared/api/services';
import { DEFAULT_CACHE_TIME, CachedRequestResponse } from '@klg/shared/utils-http';
import { getLocale } from '@klg/shared/i18n';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { Nationality } from '../types';
import { getCompanyHeader } from '@klg/shared/tokens';

@Injectable({
  providedIn: 'root',
})
export class NationalityService {
  private locale = getLocale();
  private internalCache$: Observable<Nationality[]>;
  private cacheSettings: any = {
    get: DEFAULT_CACHE_TIME,
  };

  private responseCache = new CachedRequestResponse<Nationality[]>(
    () => this.productsApiService.getNationalities(this.locale, this.companyHeader),
    this.cacheSettings.get,
  );

  private readonly companyHeader = getCompanyHeader();
  private readonly productsApiService = inject(ProductsControllerApiService);

  public getAll(): Observable<Nationality[]> {
    if (!this.internalCache$) {
      this.internalCache$ = this.responseCache.get();
    }
    return this.internalCache$;
  }

  public getByCode(code: string): Observable<Nationality> {
    return this.getAll().pipe(map((countries) => countries.find((c) => c.code === code)));
  }
}
