import { Inject, Injectable } from '@angular/core';
import { DURATION_TOKENS } from '@klg/shared/i18n';
import { CourseDuration, DurationUnit, DurationUnitEnum } from '@klg/shared/data-access/types';
import { isNumeric } from '../functions/type-check.functions';

@Injectable({
  providedIn: 'root',
})
export class DurationUnitsService {
  constructor(@Inject(DURATION_TOKENS) private readonly durationUnitMap: Record<'singular' | 'plural', Record<DurationUnit, string>>) {}

  getPerDurationText(quantity: number | string, durationUnit: DurationUnit = 'WEEKS'): string {
    if (quantity !== 1) {
      return this.getDurationText(quantity, durationUnit);
    }

    switch (durationUnit) {
      case DurationUnitEnum.EACH:
        return $localize`per lesson`;
      case DurationUnitEnum.DAYS:
        return $localize`per day`;
      case DurationUnitEnum.MONTH:
        return $localize`per month`;
      case DurationUnitEnum.YEARS:
        return $localize`per year`;
      case DurationUnitEnum.WEEKS:
      default:
        return $localize`per week`;
    }
  }

  getDurationText(quantity: number | string | undefined | null, durationUnit: DurationUnit = 'WEEKS'): string {
    if (!quantity && quantity !== 0) {
      return this.durationUnitMap.singular[durationUnit] ?? '';
    }

    if (!isNumeric(quantity)) {
      return quantity as string;
    } else {
      quantity = Number(quantity);
    }

    switch (durationUnit) {
      case DurationUnitEnum.EACH:
        return quantity === 1 ? $localize`1 lesson` : $localize`${quantity} lessons`;
      case DurationUnitEnum.DAYS:
        return quantity === 1 ? $localize`1 day` : $localize`${quantity} days`;
      case DurationUnitEnum.MONTH:
        return quantity === 1 ? $localize`1 month` : $localize`${quantity} months`;
      case DurationUnitEnum.YEARS:
        return quantity === 1 ? $localize`1 year` : $localize`${quantity} years`;
      case DurationUnitEnum.WEEKS:
      default:
        return quantity === 1 ? $localize`1 week` : $localize`${quantity}:number_of_weeks: weeks`;
    }
  }

  getDurationRange(duration: CourseDuration): string {
    if (!duration) {
      return '';
    }
    const { durationMin, durationMax, durationUnit } = duration;
    if (durationMin === durationMax) {
      return this.getDurationText(durationMin, durationUnit);
    }

    switch (durationUnit) {
      case DurationUnitEnum.EACH:
        return $localize`${durationMin}:duration_min: - ${durationMax}:duration_max: lessons`;
      case DurationUnitEnum.DAYS:
        return $localize`${durationMin}:duration_min: - ${durationMax}:duration_max: days`;
      case DurationUnitEnum.MONTH:
        return $localize`${durationMin}:duration_min: - ${durationMax}:duration_max: months`;
      case DurationUnitEnum.YEARS:
        return $localize`${durationMin}:duration_min: - ${durationMax}:duration_max: years`;
      case DurationUnitEnum.WEEKS:
      default:
        return $localize`${durationMin}:duration_min: - ${durationMax}:duration_max: weeks`;
    }
  }

  getDurationPluralText(durationUnit: DurationUnit = DurationUnitEnum.WEEKS) {
    return this.durationUnitMap.plural[durationUnit] ?? '';
  }
}
