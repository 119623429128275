import { Component, Input } from '@angular/core';
import { NgIf } from '@angular/common';

@Component({
  standalone: true,
  selector: 'kng-section-template',
  templateUrl: './section-template.component.html',
  styleUrls: ['./section-template.component.scss'],
  imports: [NgIf],
})
export class SectionTemplateComponent {
  @Input() sectionIndex: string | number;
  @Input() sectionTitle: string;
  @Input() columns: number;

  constructor() {
    this.columns = 2;
  }
}
