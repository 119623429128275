export interface GoogleAnalyticsEventVariableMappings {
  [key: string]: string[];
}

export const GA_EVENT_VARIABLE_MAP: GoogleAnalyticsEventVariableMappings = {
  quote_accommodation_type: ['quote_accommodation_name', 'quote_accommodation_bedroom', 'quote_accommodation_bathroom', 'quote_accommodation_meal'],
  quote_insurance_type: ['quote_insurance_weeks'],
  quote_virtual_internship_type: ['quote_virtual_internship_weeks'],
  quote_transfer_type: [
    'quote_transfer_type_name',
    'quote_arrival_pickup',
    'quote_departure_dropoff',
    'quote_unaccompanied_departure',
    'quote_unaccompanied_arrival',
  ],
  quote_premium_activities: ['quote_premium_activities_type', 'quote_premium_activities_weeks'],
  quote_private_lessons: ['quote_private_lessons_weeks'],
  quote_premium_residence: ['quote_premium_residence_weeks'],
  quote_promo_applied: ['quote_promo_code_name'],
  quote_destination_currency: ['quote_destination_currency'],
};
