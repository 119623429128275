import { Injectable } from '@angular/core';
import { fromEvent, Observable } from 'rxjs';
import { distinctUntilChanged, map, shareReplay, startWith } from 'rxjs/operators';

const SCREEN_BREAKPOINTS = {
  xl: 1600,
  lg: 1200,
  md: 992,
  sm: 768,
  xs: 0,
};

@Injectable({
  providedIn: 'root',
})
export class BreakpointObserverService {
  private _size$: Observable<string> = fromEvent(window, 'resize').pipe(
    startWith(this._getScreenSize()),
    map(() => this._getScreenSize()),
    distinctUntilChanged(),
    shareReplay(1),
  );
  public isMobile$ = this._size$.pipe(
    map((size) => size === 'xs'),
    distinctUntilChanged(),
    shareReplay(1),
  );
  public isTablet$ = this._size$.pipe(
    map((size) => size === 'sm'),
    distinctUntilChanged(),
    shareReplay(1),
  );

  public get size$(): Observable<string> {
    return this._size$;
  }

  private _getScreenSize(): string {
    const [newSize = 'never'] = Object.entries(SCREEN_BREAKPOINTS).find(([, size]) => window.matchMedia(`(min-width: ${size}px)`).matches);
    return newSize;
  }
}
