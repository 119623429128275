<p-dropdown
  #dropdown
  i18n-placeholder
  placeholder="— Please select —"
  [style]="{ width: '100%' }"
  [options]="sortingOptions"
  [(ngModel)]="selectedItem"
  [optionLabel]="$any(displayExpr)"
  [optionValue]="'value'"
  [dropdownIcon]="getDropdownIcon()"
  (onChange)="selectedItemChanges($event)"
>
  <ng-template [pTemplate]="'header'">
    <div [style]="{ textAlign: 'end' }">
      <kng-button [svg]="'remove'" [hasText]="false" [svgColor]="'dark'" [btnRounded]="true" [buttonStyle]="'plain-text'" (buttonClick)="toggleDropdown(false)">
      </kng-button>
    </div>
  </ng-template>
</p-dropdown>
