<div
  [ngClass]="{
    'form-field--small': small,
    'form-field--valid': isValid === true,
    'form-field--disabled': disabled,
    'form-field--invalid': isValid === false,
    'form-field__checkbox-field': fieldType === 'checkbox'
  }"
  class="form-field"
>
  <div
    *ngIf="label || tooltip"
    [ngClass]="{ 'form-field__label--disabled': disabled, 'form-field__label--withouthelper': withoutHelper }"
    class="form-field__label"
  >
    {{ label }}
    <span class="form-field__label__required" *ngIf="required">*</span>
    <kng-button-hint *ngIf="tooltip" class="form-field__label__button-hint" content="{{ tooltip }}"></kng-button-hint>
  </div>
  <div class="form-field__control" [class.form-field__control--checkbox]="fieldType === 'checkbox'" [class.form-field__control--invalid]="isValid === false">
    <ng-content></ng-content>
  </div>
  <div class="form-field__error-message" *ngIf="isValid === false && errorMessage">
    {{ errorMessage }}
  </div>
</div>
