import { inject, Injectable, OnDestroy } from '@angular/core';
import { combineLatest, defer, filter, map, Observable, of, Subject, switchMap, take, takeUntil } from 'rxjs';
import { EnhancedConversions, EnhancedConversionsEvent } from './enhanced-conversions-event.type';
import { GoogleAnalyticsActions } from '@klg/shared/google-analytics';
import { StudentDetails } from '@klg/quote-tool/shared/types';
import { QuoteToolStore } from '@klg/quote-tool/shared/store';

declare const window: Window & { dataLayer: Partial<EnhancedConversionsEvent>[] };

@Injectable({
  providedIn: 'root',
})
export class QuoteToolDataLayerHashingService implements OnDestroy {
  private readonly quoteToolStore = inject(QuoteToolStore);
  private readonly destroy$: Subject<void> = new Subject<void>();

  trackEvent(event: string, studentDetails?: StudentDetails) {
    const studentDetails$ = studentDetails ? of(studentDetails) : this.quoteToolStore.studentDetails$;

    studentDetails$
      .pipe(
        take(1),
        filter((studentDetails: StudentDetails | undefined) => !!studentDetails && (!!studentDetails.email || !!studentDetails.phoneNumber)),
        switchMap(({ email, phoneNumber }) => this.hashEmailAndPhone(email, phoneNumber)),
        map((enhancedConversions) => ({
          event,
          enhanced_conversions: enhancedConversions,
          action: GoogleAnalyticsActions.VIEW,
        })),
        takeUntil(this.destroy$),
      )
      .subscribe((gaEvent) => {
        window.dataLayer.push(gaEvent);
      });
  }

  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }

  private hashEmailAndPhone(email: string, phoneNumber: string): Observable<EnhancedConversions> {
    return combineLatest([this.hash(email), this.hash(phoneNumber)]).pipe(
      map(([emailHashed, phoneHashed]) => ({
        email_hashed: emailHashed,
        phone_number_hashed: phoneHashed,
      })),
    );
  }

  private hash(value: string): Observable<string> {
    return defer(() => {
      const utf8 = new TextEncoder().encode(value);
      return crypto.subtle.digest('SHA-256', utf8).then((hashBuffer) => {
        const hashArray = Array.from(new Uint8Array(hashBuffer));
        return hashArray.map((byte) => byte.toString(16).padStart(2, '0')).join('');
      });
    });
  }
}
