import { Component, EventEmitter, OnChanges, Output } from '@angular/core';
import { emailValidator, phoneValidator } from '@klg/core/validators';
import { EMAIL_ERROR_MESSAGE, FormSection, PHONE_ERROR_MESSAGE } from '@klg/quote-tool/shared/ui/directives';
import { ContactPersonSectionPayload, FormFieldObject } from '@klg/quote-tool/shared/types';

@Component({
  selector: 'kng-contact-person-section',
  templateUrl: './contact-person.component.html',
  styleUrls: ['./contact-person.component.scss'],
})
export class ContactPersonSectionComponent extends FormSection<ContactPersonSectionPayload> implements OnChanges {
  @Output() formChanges = new EventEmitter<keyof ContactPersonSectionPayload>();

  sectionTitle: string;

  ngOnChanges() {
    this.sectionTitle = this.isAdultSchool() ? $localize`Emergency contact details` : $localize`Parents or guardian/legal representative`;
  }

  onValueChanges(key: keyof ContactPersonSectionPayload) {
    this.formData[key].validate();
    this.storeStudentDetails({ duringYourStay: { ...this.getStudentDetails()?.duringYourStay, ...this.getFormValues() } });
  }

  updatePhoneNumber($event: string, phoneNumberType: keyof ContactPersonSectionPayload) {
    this.getPhoneNumberType($event, phoneNumberType);
    this.onValueChanges(phoneNumberType);
    this.recalculateFormValidation(phoneNumberType);
  }

  getPhoneNumberType($event: string, phoneNumberType: keyof ContactPersonSectionPayload) {
    switch (phoneNumberType) {
      case 'contactPersonPhone':
        return (this.formData.contactPersonPhone.value = $event ? $event : undefined);
      case 'legalRepresentativeMobile':
        return (this.formData.legalRepresentativeMobile.value = $event ? $event : undefined);
      default:
        return undefined;
    }
  }

  splitPhoneDigits(value: string, direction: number) {
    return value?.split(' ')[direction];
  }

  recalculateFormValidation(key: keyof ContactPersonSectionPayload) {
    this.onValueChanges(key);
    Object.entries(this.formData).forEach(([_key, formField]) => {
      formField.recalculateRequired();
      if (formField.value) {
        formField.validate();
      }
    });
  }

  protected getFormFields(): { [K in keyof ContactPersonSectionPayload]: FormFieldObject<ContactPersonSectionPayload[K]> } {
    return {
      legalRepresentativeFirstName: {
        value: '',
        validator: (firstName) => firstName && firstName.length < 80,
        errorMessage: this.defaultErrorMessage,
        required: true,
      },
      legalRepresentativeLastName: {
        value: '',
        validator: (lastName) => lastName && lastName.length < 80,
        errorMessage: this.defaultErrorMessage,
        required: true,
      },
      legalRepresentativeEmail: {
        value: '',
        validator: (email: string) => emailValidator(email),
        errorMessage: EMAIL_ERROR_MESSAGE,
        required: true,
      },
      legalRepresentativeAddress: {
        value: null,
        validator: (address: string) => address && address.length < 80,
        errorMessage: this.defaultErrorMessage,
        required: false,
      },
      legalRepresentativeMobile: {
        value: null,
        validator: (phone) => phone && phoneValidator(phone) && this.splitPhoneDigits(phone, 1).length > 4 && /\d/.test(phone),
        errorMessage: PHONE_ERROR_MESSAGE,
        required: true,
      },
      contactPersonFirstName: {
        value: null,
        validator: (firstName) => firstName && firstName?.length <= 80,
        errorMessage: this.defaultErrorMessage,
        required: true,
      },
      contactPersonLastName: {
        value: null,
        validator: (lastname) => lastname && lastname?.length <= 80,
        errorMessage: this.defaultErrorMessage,
        required: true,
      },
      contactPersonPhone: {
        value: null,
        validator: (phone) => phone && phoneValidator(phone) && this.splitPhoneDigits(phone, 1).length > 4 && /\d/.test(phone),
        errorMessage: PHONE_ERROR_MESSAGE,
        required: true,
      },
    };
  }

  protected getFieldsValues(fieldKeys: Array<keyof ContactPersonSectionPayload>): Partial<ContactPersonSectionPayload> {
    const values: Partial<ContactPersonSectionPayload> = {};

    fieldKeys.forEach((key: keyof ContactPersonSectionPayload) => {
      if (this.getFieldValueIfValid(key) !== undefined || this.getFormFields()[key].required) {
        values[key] = this.getFieldValueIfValid(key);
      }
    });

    return values;
  }
}
