import { Component } from '@angular/core';
import { StepService } from '@klg/quote-tool/shared/services/step-service';

@Component({
  selector: 'kng-quote-error-step',
  templateUrl: './quote-error.component.html',
  styleUrls: ['./quote-error.component.scss'],
})
export class QuoteErrorComponent {
  constructor(private stepService: StepService) {}

  public goBack() {
    this.stepService.navigateToPreviousStep();
  }
}
