import { formatDate } from '@angular/common';
import { Inject, LOCALE_ID, Pipe, PipeTransform } from '@angular/core';
import { DateFormatService, DateFormat } from '@klg/shared/i18n';
import { ProductType, Vacation } from '@klg/quote-tool/shared/types';

@Pipe({
  name: 'klgClosingDates',
  standalone: true,
})
export class ClosingDatesPipe implements PipeTransform {
  constructor(@Inject(LOCALE_ID) private locale: string, private dateFormatService: DateFormatService) {}

  transform(vacations: Vacation[], productType?: ProductType, format?: string): string {
    if (!vacations || !vacations.length) {
      return '';
    }

    const filteredVacations: Vacation[] =
      productType === ProductType.ACCOMMODATION ? vacations.filter((vacation: Vacation) => vacation.durationWeeks > 0) : vacations;
    if (!filteredVacations || !filteredVacations.length) {
      return '';
    }

    const datesText: string = filteredVacations
      .map((vacation: Vacation, index: number): string => {
        let separator = '';
        if (index > 0 && index < vacations.length - 1) {
          separator = ', ';
        }
        if (index > 0 && index === vacations.length - 1) {
          separator = $localize` and `;
        }

        return separator + this.periodText(vacation.startDate, vacation.endDate, format);
      })
      .join('');

    return productType === ProductType.ACCOMMODATION
      ? $localize`If you wish to book this accommodation during the holidays (${datesText}:dates:), please contact us`
      : $localize`Please note that the school will be closed ${datesText}:dates:`;
  }

  public periodText(startDate: string, endDate?: string, format?: string): string {
    const defaultFormat = this.dateFormatService.getDateFormat(DateFormat.NUMBERS_ONLY);
    const startDateText = formatDate(startDate, format || defaultFormat, this.locale);
    const endDateText = endDate ? formatDate(endDate, format || defaultFormat, this.locale) : '';

    return !endDateText || startDateText === endDateText
      ? $localize`on ${startDateText}:start_date:`
      : $localize`from ${startDateText}:start_date: to ${endDateText}:end_date:`;
  }
}
