import { Component, inject, Input, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { DestinationSchoolSectionPayload, QuoteInput, QuoteSchool } from '@klg/quote-tool/shared/types';
import { FormType } from '@klg/quote-tool/shared/types';
import { deepClone } from '@klg/shared/utils';
import { ProgramType } from '@klg/shared/data-access/course';
import { Subscription } from 'rxjs';
import { take, tap } from 'rxjs/operators';
import { City, CityExtended, Country } from '@klg/shared/data-access/types';
import { CityDataService, CountryDataService } from '@klg/shared/data-access/destination';
import { StepService } from '@klg/quote-tool/shared/services/step-service';

@Component({
  selector: 'kng-destination-school-form-section',
  templateUrl: './destination-school.component.html',
  styleUrls: ['./destination-school.component.scss'],
})
export class DestinationSchoolFormSectionComponent implements OnInit, OnDestroy {
  @Input() sectionIndex: string | number;
  @Input() formType: FormType;

  allSchools: QuoteSchool[] = [];
  allProgramTypes: ProgramType[] = [];

  availableCountries: Country[] = [];
  availableCities: CityExtended[] = [];
  loading = false;

  unknownOption = {
    code: '',
    name: $localize`I don't know`,
  };

  sectionModel: DestinationSchoolSectionPayload = {} as DestinationSchoolSectionPayload;

  private subscription = new Subscription();

  private readonly stepService = inject(StepService);
  private readonly cityService = inject(CityDataService);
  private readonly countryService = inject(CountryDataService);
  private readonly route = inject(ActivatedRoute);

  schoolFilterFn: (school: QuoteSchool) => boolean = (school: QuoteSchool) => {
    const city = this.sectionModel.destinationCity?.code;
    const country = this.sectionModel.countryCode;
    const language = this.sectionModel.languageCode;

    return (
      school.courseLanguages.some((courseLanguage) => !language || courseLanguage.code === language) &&
      (school.isOnline || ((!country || school.country === country) && (school.hasTeachersHome || !city || school.city === city)))
    );
  };

  private loadStoredData$() {
    return this.stepService.quoteRequest$.pipe(
      take(1),
      tap(({ languageCode, country, destinationCity, schoolObject }: QuoteInput) => {
        this.selectedLanguageChanged(languageCode, true);
        this.selectedCountryChanged(country, true);
        this.selectedDestinationCityChanged(destinationCity, true);
        this.selectedSchoolChanged(schoolObject, false);
      }),
    );
  }

  private storeSectionData() {
    this.stepService.updatePartialRequest<DestinationSchoolSectionPayload>(this.sectionModel);
  }

  ngOnInit() {
    // Get allSchools and allProgramTypes from resolved data
    this.allSchools = deepClone(this.route.snapshot.data?.data?.schools || []);
    this.allProgramTypes = deepClone(this.route.snapshot.data?.data?.programTypes || []);
    this.subscription.add(this.loadStoredData$().subscribe());
  }

  ngOnDestroy() {
    this.subscription.unsubscribe();
  }

  selectedLanguageChanged(newLanguageValue: string, fromStoredData = false) {
    if (this.sectionModel.languageCode === newLanguageValue) {
      return;
    }

    this.sectionModel.languageCode = newLanguageValue;

    this.loading = true;
    this.subscription.add(
      this.countryService.getAllCountriesWithParameters({ courseLanguageCode: newLanguageValue }).subscribe({
        next: (countries) => {
          this.availableCountries = countries;

          this.loading = false;

          if (!fromStoredData) {
            this.selectedCountryChanged(undefined);
            this.storeSectionData();
          }
        },
        error: () => (this.loading = false),
      }),
    );
  }

  selectedCountryChanged(newCountry: Country, fromStoredData = false) {
    if (this.sectionModel.countryCode !== newCountry?.code) {
      if (!fromStoredData) {
        this.selectedDestinationCityChanged(undefined);
      }

      this.sectionModel.countryCode = newCountry?.code;
      this.sectionModel.country = newCountry;

      // retrieve cities only if there is a country selected
      if (this.sectionModel.countryCode) {
        this.loading = true;

        const { languageCode, countryCode } = this.sectionModel;

        this.cityService
          .getAllCitiesWithParameters({
            courseLanguageCode: languageCode,
            countryCode,
          })
          .subscribe({
            next: (newAvailableCities) => {
              this.availableCities = newAvailableCities;
              this.loading = false;

              if (!fromStoredData) {
                this.storeSectionData();
              }
            },
            error: () => (this.loading = false),
          });
      }
    }
  }

  selectedDestinationCityChanged(newDestinationCity: City, fromStoredData = false) {
    if (this.sectionModel.destinationCity?.code !== newDestinationCity?.code) {
      this.sectionModel.destinationCity = newDestinationCity;

      if (!fromStoredData) {
        this.selectedSchoolChanged(undefined, false);
        this.storeSectionData();
      }
    }
  }

  selectedSchoolChanged(newSchool: QuoteSchool, fromStoredData = false) {
    if (this.sectionModel.school !== newSchool?.code) {
      this.sectionModel.schoolObject = newSchool;
      this.sectionModel.school = newSchool?.code;

      if (!fromStoredData) {
        this.storeSectionData();
      }
    }
  }

  addUnknownOptionItem() {
    return this.formType === FormType.PRICE_REQUEST ? this.unknownOption : null;
  }
}
