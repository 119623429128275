import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
// TODO: remove this disable rule when shared utils library is available
// eslint-disable-next-line @nrwl/nx/enforce-module-boundaries
import { CalendarModule as PrimeNgCalendarModule } from 'primeng/calendar';
import { BriefIconComponent } from './brief-icon/brief-icon.component';
import { CheckboxComponent } from './checkbox/checkbox.component';
import { KeyPointComponent } from './key-point/key-point.component';
import { PopupComponent } from './popup/popup.component';
import { SortComponent } from './sort/sort.component';
// eslint-disable-next-line @nrwl/nx/enforce-module-boundaries
import { LocalizeAndPluralizePeriodPipe, PricePipe, SafeUrlPipe, SortByPipe } from '@klg/shared/ui/pipes';
import { ButtonHintComponent } from '@klg/shared/ui/button-hint';
import { GalleriaModule } from 'primeng/galleria';
import { DialogModule } from 'primeng/dialog';
import { ButtonComponent } from '@klg/shared/ui/button';
import { CheckboxModule } from 'primeng/checkbox';
import { DropdownModule } from 'primeng/dropdown';
import { DropdownPopupComponent } from '@klg/shared/ui/dropdown-popup';
import { GoogleAnalyticsClickEventDirective } from '@klg/shared/google-analytics';
import { SelectButtonModule } from 'primeng/selectbutton';

@NgModule({
  declarations: [SortComponent, KeyPointComponent, BriefIconComponent, PopupComponent, CheckboxComponent],
  imports: [
    CommonModule,
    FormsModule,
    PrimeNgCalendarModule,
    SortByPipe,
    PricePipe,
    SafeUrlPipe,
    ButtonHintComponent,
    GalleriaModule,
    DialogModule,
    ButtonComponent,
    LocalizeAndPluralizePeriodPipe,
    CheckboxModule,
    DropdownModule,
    DropdownPopupComponent,
    GoogleAnalyticsClickEventDirective,
    SelectButtonModule,
  ],
  providers: [LocalizeAndPluralizePeriodPipe],
  exports: [CommonModule, SortComponent, KeyPointComponent, BriefIconComponent, PopupComponent, CheckboxComponent],
})
export class CoreComponentsModule {}
