import { Component, inject, OnDestroy, OnInit } from '@angular/core';
import { ExtrasSelectionEnrollmentPayload, HostFamilyOptions } from '@klg/quote-tool/shared/types/quote';
import { FormFieldObject } from '@klg/quote-tool/shared/types';
import { FormSection } from '@klg/quote-tool/shared/ui/directives';
import { StepService } from '@klg/quote-tool/shared/services/step-service';
import { InsuranceOption, InsuranceOptionService } from '@klg/quote-tool/shared/data-access/products';
import { deepClone } from '@klg/shared/utils';
import { Subscription } from 'rxjs';

const DURING_YOUR_STAY_TAG = 'duringYourStay';
const HOST_FAMILY_OPTIONS_TAG = 'hostFamilyOptions';

@Component({
  selector: 'kng-extras-selection',
  templateUrl: './extras-selection.component.html',
  styleUrls: ['./extras-selection.component.scss'],
})
export class ExtrasSelectionComponent extends FormSection<ExtrasSelectionEnrollmentPayload> implements OnInit, OnDestroy {
  insuranceOptions: InsuranceOption[];
  selectedInsuranceOptions: string[];
  selectedHostFamilyOptions: HostFamilyOptions = {
    isNonSmokingFamily: undefined,
    hasChildrenFamily: undefined,
    hasPetsFamily: undefined,
    hasStudentsFamily: undefined,
  };

  protected stepService = inject(StepService);
  private readonly insuranceOptionService = inject(InsuranceOptionService);
  private subscription = new Subscription();

  ngOnInit() {
    super.ngOnInit();
    this.getItemList();
  }

  onValueChanges(key: keyof ExtrasSelectionEnrollmentPayload): void {
    this.formData[key].validate();
    const previousStudentDetails = this.getStudentDetails();
    this.storeStudentDetails({
      duringYourStay: {
        ...previousStudentDetails.duringYourStay,
        ...this.getGroupValues(DURING_YOUR_STAY_TAG),
        ...this.selectedHostFamilyOptions,
      },
      extras: {
        ...previousStudentDetails.selectedInsuranceOptions,
        ...this.selectedInsuranceOptions,
      },
    });
  }

  saveInsuranceOptions() {
    this.storeStudentDetails({
      selectedInsuranceOptions: this.selectedInsuranceOptions,
    });
  }

  updateFamilyOptions(options: HostFamilyOptions) {
    this.selectedHostFamilyOptions = options;
    this.onValueChanges('hostFamilyOptions');
  }

  ngOnDestroy() {
    this.subscription.unsubscribe();
  }

  protected getFormFields(): { [K in keyof ExtrasSelectionEnrollmentPayload]: FormFieldObject<ExtrasSelectionEnrollmentPayload[K]> } {
    return {
      leisure: {
        value: null,
        validator: () => true,
        required: false,
        group: DURING_YOUR_STAY_TAG,
      },
      specialDiet: {
        value: null,
        validator: () => true,
        required: false,
        group: DURING_YOUR_STAY_TAG,
      },
      allergies: {
        value: null,
        validator: () => true,
        required: false,
        group: DURING_YOUR_STAY_TAG,
      },
      vegetarian: {
        value: false,
        validator: () => true,
        required: false,
        group: DURING_YOUR_STAY_TAG,
      },
      smoker: {
        value: false,
        validator: () => true,
        required: false,
        group: DURING_YOUR_STAY_TAG,
      },
      otherWishes: {
        value: null,
        validator: () => true,
        required: false,
        group: DURING_YOUR_STAY_TAG,
      },
      hostFamilyOptions: {
        value: {
          isNonSmokingFamily: undefined,
          hasChildrenFamily: undefined,
          hasPetsFamily: undefined,
          hasStudentsFamily: undefined,
        },
        validator: () => true,
        required: false,
        // different group so that we can control how we export the data on the onValueChanges method
        group: HOST_FAMILY_OPTIONS_TAG,
      },
    };
  }

  private getItemList() {
    this.subscription.add(
      this.insuranceOptionService.getAll().subscribe((data) => {
        this.insuranceOptions = data.map(deepClone);
      }),
    );
  }
}
