import { Component, EventEmitter, Input, Output } from '@angular/core';

@Component({
  selector: 'kng-quote-cta-button-group',
  templateUrl: './quote-cta-button-group.component.html',
  styleUrls: ['./quote-cta-button-group.component.css'],
})
export class QuoteCtaButtonGroupComponent {
  @Input() hasProductInfo = false;
  @Input() isProductEditable = false;
  @Output() infoButtonClicked = new EventEmitter<void>();
  @Output() editButtonClicked = new EventEmitter<void>();
  @Output() removeButtonClicked = new EventEmitter<void>();
}
