import { inject, Injectable } from '@angular/core';
import { FormStepDefinition } from '../types/form.step.definition.interface';
import { QuoteToolFormStore } from '../store/quote-tool.form.store';
import { switchMap } from 'rxjs/operators';
import { of } from 'rxjs';
import { ROUTE_IDS } from '@klg/quote-tool/shared/types';

@Injectable({
  providedIn: 'root',
})
export class StepDefinitionHelper {
  private readonly quoteToolFormStore = inject(QuoteToolFormStore);

  /**
   * It gets the navigation function for the next step. It will return an observable with
   * next ROUTE_ID or an empty one if the form is not still submittable.
   *
   * As side effect, it will emit the request validation event and increment the number of validations that have been done. This is
   * needed to accomplish the requirements of having at very beginning the submit button enabled until the users clicks on it and the button wil be disabled
   * if the form is not still valid.
   */
  getRouteIdToNavigateWithValidationForm({ requestValidation$, submittableForm$ }: Partial<FormStepDefinition>, routeIdToNavigate: ROUTE_IDS) {
    // It is needed to wait for sending the event of requestValidation$ and saving in the
    // store the validation request, to allow
    // the function that it is calling this function to do all subscriptions and so on.
    // Without this timeout, if the users don't press the next button until
    // they finish to fill all the fields in the form, the logic about
    // moving to next step won't be executed because the subscription (in StepService) won't be done before
    // executing the code inside this timeout function.
    setTimeout(() => {
      requestValidation$.next();
      this.quoteToolFormStore.incrementValidationRequests(1);
    }, 100);

    return submittableForm$.pipe(switchMap((submittableForm) => (submittableForm ? of(routeIdToNavigate) : of(undefined))));
  }
}
