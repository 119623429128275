import { Directive, inject, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { BehaviorSubject, combineLatest, Observable, Subject, takeUntil } from 'rxjs';
import { CourseQuoteInput, QuoteInput, ROUTE_IDS } from '@klg/quote-tool/shared/types';
import { CourseType } from '@klg/shared/data-access/types';
import { StepService } from '@klg/quote-tool/shared/services';
import { PriceRequestExperimentStore } from '../../../../store/store';
import { PriceRequestStudentFormComponent } from './student-form/price-request-student-form.component';
import { scan, startWith } from 'rxjs/operators';

@Directive()
export class BasePriceRequestExperiment implements OnInit, OnDestroy {
  @ViewChild('studentForm') studentForm: PriceRequestStudentFormComponent | undefined;

  courseFormValid$ = new BehaviorSubject(false);
  studentFormValid$ = new BehaviorSubject(false);

  protected requestValidation = new Subject<void>();
  protected validationRequests$: Observable<number> | undefined;

  protected readonly destroy$ = new Subject<void>();
  protected readonly priceRequestStore = inject(PriceRequestExperimentStore);
  protected readonly stepService = inject(StepService);

  ngOnInit(): void {
    this.validationRequests$ = this.requestValidation.asObservable().pipe(
      scan((validationRequests) => validationRequests + 1, 0),
      startWith(0),
    );

    combineLatest([this.priceRequestStore.quoteInput$, this.priceRequestStore.quoteCourseType$])
      .pipe(takeUntil(this.destroy$))
      .subscribe(([quoteInput, courseType]: [QuoteInput, CourseType]) => {
        if (!quoteInput || !courseType) {
          this.courseFormValid$.next(false);
        } else {
          // Calculate the validity of the course. The course is valid if language, country, weeks and start date are selected and student form is valid
          const { languageCode, countryCode, tuitionWeeks, startDate } = quoteInput;
          this.courseFormValid$.next(Boolean(languageCode && countryCode && tuitionWeeks > 0 && startDate));
        }
      });
  }

  ngOnDestroy(): void {
    this.destroy$.next();
  }

  sendEnquiry() {
    this.validateForm();
    this.requestValidation.next();
    const destroySendEnquirySubscription$ = new Subject<void>();
    combineLatest([this.studentFormValid$, this.priceRequestStore.quoteInput$, this.priceRequestStore.studentDetails$, this.priceRequestStore.quoteCourseType$])
      .pipe(takeUntil(destroySendEnquirySubscription$))
      .subscribe(([studentFormValid, quoteInput, studentDetails, courseType]) => {
        if (studentFormValid) {
          this.stepService.updatePartialRequest<QuoteInput>({
            ...quoteInput,
            courses: [
              <CourseQuoteInput>{
                courseType,
                weeks: quoteInput.tuitionWeeks,
                startDate: quoteInput.startDate,
              },
            ],
          });
          this.stepService.setPartialStudentDetails({ ...studentDetails });

          // The next step is the SEND_QUOTE_TO_EMAIL, and it is the one in charge of calling to /leads endpoint
          this.stepService.navigateToStep(ROUTE_IDS.SEND_QUOTE_TO_EMAIL);
        }

        // Close the subscription
        destroySendEnquirySubscription$.next();
      });
  }

  /**
   * It validates each field of student form and emits the submittableForm$ event depending on
   * if the step is valid or not.
   */
  private validateForm() {
    Object.entries(this.studentForm?.formData).forEach(([, formField]) => {
      formField.recalculateRequired();
      formField.validate();
    });
  }
}
