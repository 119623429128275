import { KeyPoint } from '@klg/shared/models';
import { KeyPoints } from '@klg/shared/data-access/course';
import { getDateFromString, getToday } from '@klg/shared/utils';
import { CourseQuoteInput, ProductType, QuoteOutput, QuoteOutputItem, QuoteProduct } from '@klg/quote-tool/shared/types/quote';

// hardcoded as for the time being there is no way of checking this
export const unaccompaniedMinorsProductCode = 'ALPUMSERV';

function mapQuoteOutputItemAsKeyPoint(item: QuoteOutputItem): KeyPoint {
  return { description: item.name, additionalDescription: item.description } as KeyPoint;
}

export function getQuoteIncludedKeyPoints(quoteOutput: QuoteOutput): KeyPoint[] {
  return quoteOutput?.items?.filter((item: QuoteOutputItem) => item.isIncluded).map(mapQuoteOutputItemAsKeyPoint);
}

export function getQuoteNotIncludedKeyPoints(quoteOutput: QuoteOutput): KeyPoint[] {
  const notIncluded: KeyPoint[] = quoteOutput?.items?.filter((item: QuoteOutputItem) => !item.isIncluded).map(mapQuoteOutputItemAsKeyPoint);

  // Find not included in products
  quoteOutput?.products?.forEach((product) => {
    product.extras?.supplementItems
      ?.filter((item) => !item.isIncluded)
      .forEach((item) =>
        notIncluded.push({
          description: item.name,
          additionalDescription: item.description,
          price: item.price?.length && item.price[0].value ? item.price : undefined,
        } as KeyPoint),
      );
  });

  return notIncluded;
}

export function getQuoteKeyPoints(quoteOutput: QuoteOutput): KeyPoints {
  return {
    included: getQuoteIncludedKeyPoints(quoteOutput),
    notIncluded: getQuoteNotIncludedKeyPoints(quoteOutput),
  };
}

export const completeEslQuoteOutput = (quoteResult: QuoteOutput): QuoteOutput => ({
  ...quoteResult,
  discounts: quoteResult.discounts?.map((discount) => ({
    ...discount,
    name: discount.promotionName || discount.name,
  })),
});

export const getMaxCourseStartDateYearFromQuoteCourses = (quoteResult: QuoteOutput) => {
  const courseQuoteInput = getCourseWithMaxStartDateFromQuoteCourses(quoteResult);
  if (!courseQuoteInput) {
    return getToday().getFullYear();
  }
  return getDateFromString(courseQuoteInput.startDate)?.getFullYear() ?? getToday().getFullYear();
};

export const getCourseWithMaxStartDateFromQuoteCourses = (quoteResult: QuoteOutput): CourseQuoteInput =>
  quoteResult?.input?.courses?.reduce((maxCourse: CourseQuoteInput, courseQuoteInput: CourseQuoteInput) => {
    if (!maxCourse) {
      return courseQuoteInput;
    }
    const maxCourseStartDate = getDateFromString(maxCourse.startDate);
    const courseStartDate = getDateFromString(courseQuoteInput.startDate);
    return courseStartDate?.getTime() > maxCourseStartDate?.getTime() ? courseQuoteInput : maxCourse;
  }, null);

export const getDefaultQuoteCurrency = (quoteResult: QuoteOutput): string => quoteResult?.marketCurrency ?? quoteResult?.input?.destinationCurrency ?? 'USD';

export const getQuoteSummaryRestProducts = (quoteProducts: QuoteProduct[]): QuoteProduct[] => {
  return (
    quoteProducts?.filter((product: QuoteProduct) => product.classification === ProductType.OTHERS || product.classification === ProductType.SUPPLEMENT) ?? []
  );
};
export const isUnaccompaniedMinorsProduct = (product: QuoteProduct): boolean => {
  return product.code === unaccompaniedMinorsProductCode;
};

export const getQuoteSummaryFees = (quoteProducts: QuoteProduct[]): QuoteProduct[] => {
  return quoteProducts?.filter((product: QuoteProduct) => product.classification === ProductType.FEE && !isUnaccompaniedMinorsProduct(product)) ?? [];
};
