import { HttpHeaders } from '@angular/common/http';
import { BaseConfigService } from '@klg/shared/app-config';
import { NGXLogger, NgxLoggerLevel } from 'ngx-logger';
import { CoreEnvironment } from '@klg/shared/types';

// Logging factory function
export function loggingFactory(configService: BaseConfigService, loggingService: NGXLogger, companyHeader: string, env: CoreEnvironment): () => Promise<void> {
  return () =>
    new Promise((resolve) => {
      const { API_BASE_PATH, LOCALE } = configService.getEnvironment(env);
      const loggingConfig = loggingService.getConfigSnapshot();
      loggingConfig.serverLoggingUrl = `${API_BASE_PATH}/admin/v1/logs`;
      loggingConfig.customHttpHeaders = new HttpHeaders({
        'Accept-Language': LOCALE,
        'Accept-Company': companyHeader,
      });
      loggingConfig.serverLogLevel = NgxLoggerLevel.ERROR;
      loggingService.updateConfig(loggingConfig);
      resolve();
    });
}
