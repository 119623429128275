import { inject, Injectable } from '@angular/core';
import { NotificationService } from './notification.service';
import { HttpErrorResponse } from '@angular/common/http';

@Injectable({
  providedIn: 'root',
})
export class ErrorNotificationService {
  private readonly notificationService = inject(NotificationService);
  private readonly blacklistedBrowserAgents = ['ahrefs', 'adsbot', 'claudebot', 'Acunetix', 'Deepscan'];

  notifyError(title: string, message: string, error: Error) {
    const showErrorToast = (title: string, message: string, error: Error) => {
      this.showErrorToast(title, message, error);
    };
    this.onError(showErrorToast, title, message, error);
  }

  notifyApiError(title: string, message: string, error: HttpErrorResponse) {
    const showErrorToast = (title: string, message: string, error: HttpErrorResponse) => {
      this.showApiErrorToast(title, message, error);
    };
    this.onError(showErrorToast, title, message, error);
  }

  private onError(showToastFn: any, title: string, message: string, error: Error | HttpErrorResponse) {
    // don't handle the error is the browser agent is blacklisted
    if (!this.blacklistedBrowserAgents.some((browserAgent) => navigator.userAgent.includes(browserAgent))) {
      showToastFn(title, message, error);
    }
  }

  private showErrorToast(title: string, message: string, error: Error | undefined) {
    this.notificationService.error(message, title, error);
  }

  private showApiErrorToast(title: string, message: string, error: HttpErrorResponse) {
    this.notificationService.apiError(message, error, title);
  }
}
