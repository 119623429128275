import { Injectable, inject } from '@angular/core';
import { InsuranceOption } from '../models';
import { BaseService, CachedRequestResponse } from '@klg/shared/utils-http';
import { Observable } from 'rxjs/internal/Observable';
import { ProductsControllerApiService } from '@klg/shared/api';
import { of } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class InsuranceOptionService extends BaseService<InsuranceOption> {
  private readonly productsApiService = inject(ProductsControllerApiService);

  private responseCache = new CachedRequestResponse<InsuranceOption[]>(
    () => this.productsApiService.getInsuranceOptions(this.locale, this.companyHeader),
    this.cacheSettings.get,
  );

  protected getResponseCache$(): Observable<CachedRequestResponse<InsuranceOption[]>> {
    return of(this.responseCache);
  }
}
