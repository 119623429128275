<kng-form-field
  class="date-of-birth-field"
  label="Date of birth"
  i18n-label
  [small]="true"
  [isValid]="isValid"
  [required]="required"
  [errorMessage]="errorMessage"
  [iconValidation]="true"
  (focusout)="handleFocusEvent($event)"
>
  <input
    #dayField
    class="date-of-birth-field__day"
    type="number"
    placeholder="DD"
    i18n-placeholder
    [maxlength]="2"
    [(ngModel)]="day"
    (input)="updateDateOfBirth()"
  />
  <p-dropdown
    #monthField
    class="date-of-birth-field__month"
    placeholder="Month"
    i18n-placeholder
    optionLabel="name"
    optionValue="code"
    [style]="{ width: '100%' }"
    [options]="months"
    [(ngModel)]="month"
    [scrollHeight]="monthScrollHeight"
    [overlayOptions]="overlayOption"
    (onChange)="updateMonthOfDateOfBirth()"
    (onFocus)="handleMonthDropdownFocusEvent()"
  />
  <input
    #yearField
    class="date-of-birth-field__year"
    type="number"
    placeholder="YYYY"
    i18n-placeholder
    [maxlength]="4"
    [(ngModel)]="year"
    (input)="updateDateOfBirth()"
  />
</kng-form-field>
