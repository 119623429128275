import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { CoreComponentsModule } from '@klg/core/components';
import { CampSelectorComponent } from './camp-selector/camp-selector.component';
import { SchoolSelectorComponent } from './school-selector/school-selector.component';
import { DetailsPopupComponent } from '../details-popup/details-popup.component';
import { FormFieldSelectorComponent } from '../form-field-selector/form-field-selector.component';
import { SummaryCardComponent } from '../summary-card/summary-card.component';
import { ItemCardComponent } from '../item-card/item-card.component';

@NgModule({
  declarations: [CampSelectorComponent, SchoolSelectorComponent],
  imports: [CommonModule, CoreComponentsModule, DetailsPopupComponent, FormFieldSelectorComponent, SummaryCardComponent, ItemCardComponent],
  exports: [CampSelectorComponent, SchoolSelectorComponent],
})
export class SchoolComponentsModule {}
