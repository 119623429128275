import { FormType } from '@klg/quote-tool/shared/types';

export enum QuoteToolModes {
  wizard = 'wizard',
  form = 'form',
  enrollment = 'enrollment',
  priceRequest = 'priceRequest',
  quickQuoteTool = 'quickQuoteTool',
}

export const quoteToolModesTypeMap = new Map<QuoteToolModes, FormType>([
  [QuoteToolModes.enrollment, FormType.ENROLLMENT],
  [QuoteToolModes.form, FormType.FREE_QUOTE],
  [QuoteToolModes.wizard, FormType.WIZARD],
  [QuoteToolModes.priceRequest, FormType.PRICE_REQUEST],
  [QuoteToolModes.quickQuoteTool, FormType.QUICK_QUOTE_TOOL],
]);
