import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { CoreComponentsModule } from '@klg/core/components';
import { FilterPipe } from '@klg/shared/ui/pipes';
import { CurrencySelectorComponent } from './currency-selector/currency-selector.component';
import { DialogModule } from 'primeng/dialog';
import { ScrollPanelModule } from 'primeng/scrollpanel';

@NgModule({
  imports: [CommonModule, CoreComponentsModule, FilterPipe, DialogModule, ScrollPanelModule],
  declarations: [CurrencySelectorComponent],
  exports: [CurrencySelectorComponent],
})
export class CurrencyModule {}
