import { LimitedCurrencyAmount } from '@klg/currency/shared/model/currency.model';
import { Component, EventEmitter, Input, Output } from '@angular/core';

@Component({
  selector: 'kng-quote-subtotal-item',
  templateUrl: './quote-subtotal-item.component.html',
  styleUrls: ['./quote-subtotal-item.component.scss'],
})
export class QuoteSubtotalItemComponent {
  @Input() quoteKey = '';
  @Input() activeQuoteSlot = 1;
  @Input() totalLabel = '';
  @Input() amountTotal: LimitedCurrencyAmount[] = [];
  @Input() currency = '';
  @Input() decimalPlaces = 0;
  @Input() totalItems = 0;
  @Input() closestExpirationDate = '';
  @Input() isAccordionExpanded = false;
  @Input() roundedElements = false;
  @Input() isDiscount = false;
  @Input() quoteOutputsExpirationDates = [];
  @Output() expandAccordion = new EventEmitter<void>();
}
