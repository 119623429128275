import { Directive, Input } from '@angular/core';
import { FormData } from '@klg/core/forms/form-data.model';
import { emailValidator } from '@klg/core/validators';
import { FormDataInputValidityDirective } from './form-data-input-validity.directive';

/**
 * Directive that handles the validity of a form data.
 * If the field is focused, validation occurs while clicking outside - the first time data is entered.
 * If the field is input, validation occurs while typing - subsequent times data is entered.
 */

@Directive({
  standalone: true,
  selector: '[kngFormDataEmailValidity]',
})
export class FormDataEmailValidityDirective<T> extends FormDataInputValidityDirective<T> {
  @Input() formData: FormData<T> | undefined;

  protected getNoFormDataWarningMessage() {
    return 'FormDataEmailValidityDirective - Form data is not defined';
  }

  protected calculateFormDataValidity() {
    return super.calculateFormDataValidity() && this.isEmailValid();
  }

  private isEmailValid(): boolean {
    return emailValidator(this.formData?.value?.toString());
  }
}
