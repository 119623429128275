<kng-form-field
  [small]="true"
  [disabled]="disabled"
  [label]="label"
  class="date-picker-field"
  [ngClass]="{
    'date-picker-field--disabled': disabled,
    'date-picker-field--with-icon': showCalendarIcon,
    'date-picker-field--mark-enabled-days': disabledDates?.length || disabledDays?.length
  }"
  [isValid]="checkIsValid()"
  [required]="required"
>
  <kng-calendar-field
    [(ngModel)]="pickedDate.dateObject"
    (ngModelChange)="pickDate($event)"
    [disabledDays]="disabledDays"
    (calendarShown)="calendarShown.emit($event)"
    (monthChanged)="monthChanged.emit($event)"
    (datePickerFocused)="datePickerFocused.emit($event)"
    [label]="label"
    [disabled]="disabled"
    [placeholder]="placeholder"
    [required]="required"
    [isValid]="isValid"
    [dateValue]="dateValue"
    [dateObject]="dateObject"
    [defaultDate]="defaultDate"
    [minDate]="minDate"
    [maxDate]="maxDate"
    [disabledDates]="disabledDates"
    [disabledDays]="disabledDays"
    [showCalendarIcon]="showCalendarIcon"
    [yearRange]="yearRange"
    [googleAnalyticsClickEvent]="googleAnalyticsClickEvent"
  ></kng-calendar-field>
</kng-form-field>
