import { Component, EventEmitter, Input, OnChanges, Output, SimpleChanges } from '@angular/core';
import { Amount } from '@klg/shared/data-access/types';
import { getDateFromString, isDateAfter } from '@klg/shared/utils';
import { accordionAnimationData } from '@klg/ui/animations';
import { QuoteDiscounts } from '@klg/quote-tool/shared/types';

@Component({
  selector: 'kng-quote-discount-details',
  templateUrl: './quote-discount-details.component.html',
  styleUrls: ['./quote-discount-details.component.scss'],
  animations: [accordionAnimationData],
})
export class QuoteDiscountDetailsComponent implements OnChanges {
  @Input() quoteKey = '';
  @Input() activeQuoteSlot = 1;
  @Input() discounts: QuoteDiscounts[] = [];
  @Input() discountTotal: Amount[] = [];
  @Input() currency = '';
  @Input() decimalPlaces = 0;
  @Input() isDiscountsAccordionExpanded = false;
  @Input() roundedElements = false;
  @Input() remainingDiscountsLength = 0;
  @Input() quoteOutputsExpirationDates = [];
  @Output() expandDiscountAccordions = new EventEmitter<void>();
  public closestExpirationDate = '';
  public discount = (item: QuoteDiscounts) => item;

  ngOnChanges({ discounts }: SimpleChanges): void {
    if (discounts?.currentValue && this.discounts.length) {
      const soonestExpiringDiscount = this.discounts.reduce((previous, current) => {
        const previousDate = getDateFromString(previous?.expirationDate);
        const currentDate = getDateFromString(current?.expirationDate);
        return isDateAfter(currentDate, previousDate) ? previous : current;
      });
      this.closestExpirationDate = soonestExpiringDiscount?.expirationDate;
    }
  }
}
