import { Component, EventEmitter, Input, Output } from '@angular/core';

@Component({
  selector: 'kng-quote-course-details-item',
  templateUrl: './quote-course-details-item.component.html',
  styleUrls: ['./quote-course-details-item.component.scss'],
})
export class QuoteCourseDetailsItemComponent {
  @Input() label = '';
  @Input() showEditButton = false;
  @Output() editButtonClicked = new EventEmitter<void>();
}
