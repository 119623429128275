import { Pipe, PipeTransform } from '@angular/core';
import { ProductType, QuoteOutput } from '@klg/quote-tool/shared/types';
import { Unpacked } from '@klg/shared/utils-ts';

@Pipe({
  name: 'productsByType',
  standalone: true,
})
export class ProductsByTypePipe implements PipeTransform {
  transform(
    products: QuoteOutput['products'],
    productType: ProductType,
    key?: keyof Unpacked<QuoteOutput['products']>,
  ): QuoteOutput['products'] | Unpacked<QuoteOutput['products']>[keyof Unpacked<QuoteOutput['products']>][] {
    const filteredProducts: QuoteOutput['products'] = products?.filter((product) => product.classification.toLowerCase() === productType?.toLowerCase());
    if (!filteredProducts?.length) {
      return null;
    }
    if (key) {
      return filteredProducts.map((product) => product[key]);
    }
    return filteredProducts;
  }
}
