export const isEmpty: (string) => boolean = (str: string) => !str || str.length === 0;

export const trimTrailingSlash = (string: string) => string.replace(/\/$/, '');

export const capitalizeFirstLetter = ([first, ...rest]: string, locale: string = navigator.language): string =>
  [first.toLocaleUpperCase(locale), ...rest].join('');

export const escapeString = (text: string) => text.replace(/'/g, "\\'");

/**
 * Returns text replacing accented characters
 * @param text
 */
export const normalizeString = (text: string) => {
  if (!text) {
    return text;
  }
  return text
    .normalize('NFD')
    .replace(/[\u0300-\u036f]/g, '')
    .toLowerCase();
};

export const cleanDoubleSlashes = (urlString: string) => urlString.replace(/\/\//g, '/');

export const cleanUrl = (urlString: string) => trimTrailingSlash(cleanDoubleSlashes(urlString));

export const prependStringWithCharacter = (string: string, character: string, length: number) => {
  if (string.length > length) {
    return string;
  }
  return `${character.repeat(length - string.length)}${string}`;
};

export const removeLineBreaks = (text: string): string => {
  if (!text) {
    return '';
  }
  return text.replace(/[\n\r]+/g, '');
};

export const convertToTitleCase = (input: string): string =>
  input
    ?.split('_')
    ?.map((word) => word.charAt(0).toUpperCase() + word.slice(1).toLowerCase())
    ?.join(' ');
