<div
  class="quote-product-description"
  [class.quote-product-description--array]="isArray"
  [class.quote-product-description--error]="hasVacations"
  [class.quote-product-description--disclaimer]="hasDisclaimer"
>
  <div *ngFor="let productDescription of productDescription || []">
    {{ productDescription }}
  </div>
</div>
