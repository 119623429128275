// TODO: These should eventually come from the API (KLG-9079).
const holidayClosures = [
  { country: 'CA', startDate: '2023-12-25', endDate: '2024-01-02' },
  { country: 'CH', startDate: '2023-12-25', endDate: '2024-01-08' },
  { country: 'DE', startDate: '2023-12-25', endDate: '2024-01-08' },
  { country: 'FR', startDate: '2023-12-25', endDate: '2024-01-08' },
  { country: 'GB', startDate: '2023-12-25', endDate: '2024-01-02' },
  { country: 'IE', startDate: '2023-12-25', endDate: '2024-01-02' },
  { country: 'US', startDate: '2023-12-25', endDate: '2024-01-02' },
];

export const checkHolidayClosures = (country: string, selectedStartDate: string, selectedEndDate: string) => {
  const holidayClosure = holidayClosures.filter((closure) => closure?.country === country)?.shift();
  if (!holidayClosure) {
    return [];
  }

  const { startDate: closureStartDate, endDate: closureEndDate } = holidayClosure;
  const hasClosureRange = new Date(selectedStartDate) <= new Date(closureEndDate) && new Date(closureStartDate) <= new Date(selectedEndDate);
  return hasClosureRange ? [{ startDate: closureStartDate, endDate: closureEndDate }] : [];
};
