import { Component, inject, OnDestroy, OnInit } from '@angular/core';
import { QuoteFormLinkUrls, QuoteInput, TermsAndPromotionsSectionPayload } from '@klg/quote-tool/shared/types/quote';
import { FormFieldObject, FormType } from '@klg/quote-tool/shared/types';
import { Subject, takeUntil } from 'rxjs';
import { StepService } from '@klg/quote-tool/shared/services/step-service';
import { QuoteFormLinkUrlsService } from '@klg/quote-tool/shared/data-access/quote';
import { CheckboxModule } from 'primeng/checkbox';
import { FormsModule } from '@angular/forms';
import { NgIf } from '@angular/common';
import { FormSection } from '@klg/quote-tool/shared/ui/directives';

@Component({
  standalone: true,
  selector: 'kng-terms-and-promotions-form-section',
  templateUrl: './terms-and-promotions.component.html',
  styleUrls: ['./terms-and-promotions.component.scss'],
  imports: [CheckboxModule, FormsModule, NgIf],
})
export class TermsAndPromotionsFormSectionComponent extends FormSection<TermsAndPromotionsSectionPayload> implements OnInit, OnDestroy {
  isTermsTextHovered = false;
  termsAndConditionsLink: string;
  moreInfoLink: string;

  private termsAndPromotionsData: TermsAndPromotionsSectionPayload | undefined;

  private readonly destroy$ = new Subject<void>();
  private readonly linkUrlsService = inject(QuoteFormLinkUrlsService);

  readonly isEnrollmentFormType = () => this.stepService.getFormType() === FormType.ENROLLMENT;

  constructor(stepService: StepService) {
    super(stepService);
  }

  ngOnInit() {
    this.initFormFields();
    this.initLinks();
  }

  ngOnDestroy() {
    this.destroy$.next();
    this.destroy$.complete();
  }

  onValueChanges(key: keyof TermsAndPromotionsSectionPayload): void {
    this.formData[key].validate();
    this.storeSectionData();
  }

  protected getFormFields(): { [K in keyof TermsAndPromotionsSectionPayload]: FormFieldObject<TermsAndPromotionsSectionPayload[K]> } {
    return {
      agreeToPromotions: {
        value: this.getFieldValue('agreeToPromotions'),
        validator: () => true,
        required: false,
      },
      agreeToTerms: {
        group: 'checkboxRequiredDefaultsToFalse',
        value: this.getFieldValue('agreeToTerms'),
        validator: Boolean,
        required: true,
      },
    };
  }

  private initLinks() {
    this.linkUrlsService
      .getLinkUrls$()
      .pipe(takeUntil(this.destroy$))
      .subscribe(({ moreInfoLinkUrl, termsAndConditionsLinkUrl }: QuoteFormLinkUrls) => {
        this.moreInfoLink = moreInfoLinkUrl;
        this.termsAndConditionsLink = termsAndConditionsLinkUrl;
      });
  }

  private initFormFields() {
    this.stepService.quoteRequest$.pipe(takeUntil(this.destroy$)).subscribe(({ agreeToPromotions, agreeToTerms }: QuoteInput) => {
      this.termsAndPromotionsData = {
        agreeToPromotions: agreeToPromotions ?? false,
        agreeToTerms: agreeToTerms ?? false,
      };
      super.ngOnInit();
    });
  }

  private storeSectionData() {
    const { agreeToTerms, agreeToPromotions } = this.getFormValues() as TermsAndPromotionsSectionPayload;
    this.stepService.updatePartialRequest<TermsAndPromotionsSectionPayload>({
      agreeToTerms: agreeToTerms || false,
      agreeToPromotions: agreeToPromotions || false,
    });
    this.stepService.setPartialStudentDetails({
      consentResellers: false,
      consentMarketing: agreeToPromotions || false,
    });
  }

  private getFieldValue<K extends keyof TermsAndPromotionsSectionPayload>(termsAndPromotionsKey: K): TermsAndPromotionsSectionPayload[K] {
    return this.getDataFromFields()[termsAndPromotionsKey] ?? this.termsAndPromotionsData[termsAndPromotionsKey];
  }
}
