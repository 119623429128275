import { HttpEvent, HttpHandlerFn, HttpInterceptorFn, HttpRequest } from '@angular/common/http';
import { Observable } from 'rxjs';
import { inject } from '@angular/core';
import { HttpErrorInterceptorService } from './http-error-interceptor.service';

/**
 * Function that intercepts Http request.
 *
 * This function is called from standalone application where, instead of importing modules, we
 * use provide functions.
 *
 * Example of use:
 * provideHttpClient(withInterceptors([httpErrorInterceptorFn]))
 *
 * @param request
 * @param next
 */
export const httpErrorInterceptorFn: HttpInterceptorFn = (request: HttpRequest<unknown>, next: HttpHandlerFn): Observable<HttpEvent<unknown>> => {
  const httpErrorService = inject(HttpErrorInterceptorService);
  return httpErrorService.intercept(request, next);
};
