import { Component, EventEmitter, Input, OnChanges, Output, SimpleChanges } from '@angular/core';
import { SortingCriteria } from '@klg/shared/types';
import { CityExtended } from '@klg/shared/data-access/types';

@Component({
  selector: 'kng-city-selector',
  templateUrl: './city-selector.component.html',
  styleUrls: ['./city-selector.component.scss'],
})
export class CitySelectorComponent implements OnChanges {
  @Input() label: string;
  @Input() disabled = false;
  @Input() autofill = false;
  @Input() value: string = undefined;
  @Input() availableCities: CityExtended[] = [];
  @Input() countryCode: string | null = null;
  @Input() buttonView = false;
  @Input() placeholder: string;
  @Input() isOpen = false;
  @Input() required = false;
  @Input() sortCriteria: SortingCriteria<CityExtended> = [{ property: 'isAtTheTeachers' }, { property: 'name' }];
  @Output() valueChange = new EventEmitter<string>();
  @Output() itemChange = new EventEmitter<CityExtended>();

  public itemList: CityExtended[] = null;
  public selectedValue: string;

  ngOnChanges({ value, countryCode, availableCities }: SimpleChanges): void {
    if (value?.currentValue) {
      this.selectedValue = value.currentValue;
    }
    if (countryCode?.currentValue || availableCities?.currentValue) {
      this.itemList = this.filterCitiesByCountryCode(availableCities?.currentValue ?? this.availableCities, countryCode?.currentValue ?? this.countryCode);
    }
  }

  private filterCitiesByCountryCode(cities: CityExtended[], countryCode: string): CityExtended[] {
    return cities?.filter((city) => city?.country?.code === countryCode) || [];
  }
}
