import { Component, ElementRef, inject, OnDestroy, OnInit, Type, ViewChild } from '@angular/core';
import { hasEnoughDataToGetSupplements } from '@klg/quote-tool/shared/data-access/quote';
import { QuoteInput, StudentDetails } from '@klg/quote-tool/shared/types/quote';
import { FormType, StepDefinition, StepDefinitionExperiment } from '@klg/quote-tool/shared/types';
import { BehaviorSubject, combineLatest, Observable, of, Subject, takeUntil } from 'rxjs';
import { debounceTime, switchMap, tap } from 'rxjs/operators';
import { getCountryOfResidenceFromDomain } from '../../../shared/functions/get-country-of-residence-from-domain.function';
import { tellUsAboutYouSectionIndex } from '../../../shared/functions/section-index.functions';
import { isQuoteRequestValid, isStepQuoteFormValid } from '../../../shared/functions/step-validation.function';
import { StepService } from '@klg/quote-tool/shared/services/step-service';
import { CookieService } from 'ngx-cookie-service';
import { LoggerService } from '@klg/shared/logger';
import { TellUsAboutYouSectionComponent } from '../../sections/tell-us-about-you/tell-us-about-you.component';
import { FormStepDefinition } from '../../../shared/types/form.step.definition.interface';
import { QuoteToolFormStore } from '../../../shared/store/quote-tool.form.store';

@Component({
  selector: 'kng-quote-form-step',
  templateUrl: './quote-form.component.html',
  styleUrls: ['./quote-form.component.scss'],
})
export class QuoteFormComponent implements OnInit, OnDestroy {
  @ViewChild('tellUsAboutYouForm') tellUsAboutYouForm: TellUsAboutYouSectionComponent | undefined;
  @ViewChild('courseDatesForm') courseDatesForm: ElementRef;

  formType: FormType;
  tellUsAboutYouSectionIndex: number;

  // Component to be used in the experiment (this will be specified in the step definition)
  experimentComponent: Type<unknown>;

  stepValid$ = new BehaviorSubject(false);
  validationRequests$: Observable<number> | undefined;

  private hasEnoughDataToGetSupplements = false;
  private studentDetailsFormInStep = false;

  private submittableForm$ = new Subject<boolean>();

  private readonly destroy$: Subject<void> = new Subject<void>();

  private readonly cookieService = inject(CookieService);
  private readonly stepService = inject(StepService);
  private readonly quoteToolFormStore = inject(QuoteToolFormStore);
  private readonly logger = inject(LoggerService);

  ngOnInit() {
    this.formType = this.stepService.getFormType();
    this.setCountryByDomain();
    this.stepService.setStepValid(this.stepValid$);
    this.validationRequests$ = this.quoteToolFormStore.validationRequests$;

    combineLatest([this.stepService.quoteRequest$, this.stepService.studentDetails$])
      .pipe(debounceTime(160), takeUntil(this.destroy$))
      .subscribe(([quoteInput, studentDetails]: [QuoteInput, StudentDetails]) => {
        this.hasEnoughDataToGetSupplements = hasEnoughDataToGetSupplements(quoteInput);
        this.tellUsAboutYouSectionIndex = tellUsAboutYouSectionIndex(this.formType, quoteInput.hasSupplementsAvailable);
        this.stepValid$.next(isStepQuoteFormValid(quoteInput, studentDetails));
      });

    // Load experiment and if there is not an experiment, continue
    // with the rest of the initialization logic of the component
    // For experiments all the logic about the behaviour of the component will be covered there
    // and there is no need to execute here the 'normal' logic of the component
    this.stepService.currentStepDefinition$
      .pipe(
        switchMap((stepDefinition) => this.loadExperiments(stepDefinition)),
        takeUntil(this.destroy$),
      )
      .subscribe((loadedExperimentWithOwnCtas) => {
        // If the experiment has been loaded, and it has its own ctas, then, not continue with
        // the initialization the rest of the logic of the component
        if (loadedExperimentWithOwnCtas) {
          return;
        }

        // Init the step from its definition
        this.initStepFromStepDefinition();

        // Prepares the calculation for the status of the next button
        this.prepareCalculationForStatusOfDisabledNextButton();
      });
  }

  ngOnDestroy() {
    this.destroy$.next();
  }

  isFreeQuoteFormType(): boolean {
    return this.formType === FormType.FREE_QUOTE;
  }

  isPriceRequestFormType(): boolean {
    return this.formType === FormType.PRICE_REQUEST;
  }

  showTellUsAboutYou(): boolean {
    return this.isPriceRequestFormType() || (this.isFreeQuoteFormType() && this.hasEnoughDataToGetSupplements);
  }

  showTermsAndConditions(): boolean {
    return this.isPriceRequestFormType() || (this.isFreeQuoteFormType() && this.hasEnoughDataToGetSupplements);
  }

  /**
   * It inits the step by taking the current step definition and subscribing to the requestValidation$ event.
   * On that way, any time the event is emitted, the formValidation event will be emitted too.
   * Also, take the submittableForm$ to notify when the form is submittable and
   * load the experiments associated with the step if there is any
   */
  private initStepFromStepDefinition() {
    this.stepService.currentStepDefinition$
      .pipe(
        tap(({ submittableForm$, studentDetailsFormInStep }: FormStepDefinition) => {
          this.submittableForm$ = submittableForm$;
          this.studentDetailsFormInStep = studentDetailsFormInStep || false;
        }),
        switchMap(({ requestValidation$ }: FormStepDefinition) => requestValidation$ || new Subject<void>()),
        takeUntil(this.destroy$),
      )
      .subscribe(() => this.validateStudentFormForm());
  }

  /**
   * It prepares the subscription to the quoteRequest, validationRequests and isStepValid$ observables to
   * calculate the status of the next button
   * @private
   */
  private prepareCalculationForStatusOfDisabledNextButton() {
    combineLatest([this.stepService.quoteRequest$, this.quoteToolFormStore.validationRequests$, this.stepValid$])
      .pipe(takeUntil(this.destroy$))
      .subscribe(([quoteRequest, validationRequests, stepValid]) => {
        if (!this.studentDetailsFormInStep) {
          this.quoteToolFormStore.updateDisabledNextButton(!stepValid);
          return;
        }

        const quoteRequestValid = isQuoteRequestValid(quoteRequest);
        if (!quoteRequestValid) {
          this.quoteToolFormStore.updateDisabledNextButton(true);
          return;
        }

        this.quoteToolFormStore.updateDisabledNextButton(!stepValid && validationRequests > 0);
      });
  }

  /**
   * It validates each field of the student form and emits the submittableForm$ event depending on
   * if the step is valid or not.
   */
  private validateStudentFormForm() {
    [this.tellUsAboutYouForm?.formData].forEach((formData) => {
      // Null safe, in case the form is not rendered yet.
      if (!formData) {
        return;
      }

      Object.entries(formData).forEach(([, formField]) => {
        formField.recalculateRequired();
        formField.validate();
      });
      this.courseDatesForm?.nativeElement?.scrollIntoView({ behavior: 'smooth', block: 'start' });
    });
    this.submittableForm$.next(this.stepValid$.value);
  }

  /**
   * It loads the experiments in case the step definition has associated experiments.
   * If the step has experiments we will load the experiment components in case
   * the experimentId is active by cookie.
   * It returns a boolean observable with value true if the experiment has been loaded, and it
   * has the hideCtas flag active and false in any other case. Having the hideCtas flag to true
   * it means that the logic regarding the next and back buttons are handle inside each experiment
   */
  private loadExperiments(stepDefinition: StepDefinition): Observable<boolean> {
    const experiments = stepDefinition.experiments;

    // If there are no experiments, return
    if (!experiments || experiments.length === 0) {
      return of(false);
    }

    // If the step has associated experiments, take the ones that are active (by cookie)
    const activeExperiments: StepDefinitionExperiment[] = [];
    experiments.forEach((experiment) => {
      const { experimentId } = experiment;
      if (this.cookieService.check(experimentId) && this.cookieService.get(experimentId) === 'true') {
        activeExperiments.push(experiment);
      }
    });

    // If there are not any active experiment, set to undefined the experiment component
    if (!activeExperiments.length) {
      this.experimentComponent = undefined;
      return of(false);
    }

    // If we have more than one active experiment, show an error message and not get any experiment component
    if (activeExperiments.length > 1) {
      this.logger.error(`There are more than one active experiment in the step quote-form for form type ${this.formType}`);
      this.experimentComponent = undefined;
      return of(false);
    } else {
      // Else, assign the experiment component to the active experiment
      const [{ experimentComponent, hideCtas }] = activeExperiments;
      this.experimentComponent = experimentComponent;
      stepDefinition.hideCtas = hideCtas;
      return of(hideCtas);
    }
  }

  private setCountryByDomain() {
    this.stepService.setPartialStudentDetails({ countryOfResidence: getCountryOfResidenceFromDomain() });
  }
}
