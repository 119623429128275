<kng-form-field-selector
  [label]="label"
  [disabled]="disabled"
  [value]="value"
  valueExpr="code"
  [itemList]="itemList"
  [buttonView]="buttonView"
  [placeholder]="placeholder"
  [hideHelpMeChoose]="true"
  [isOpen]="isOpen"
  [autofill]="autofill"
  [required]="required"
  [sortCriteria]="sortCriteria"
  [iconValidation]="iconValidation"
  (valueChange)="valueChange.emit($event)"
  (itemChange)="itemChange.emit($event)"
  (isOpenChange)="isOpenChange.emit($event)"
>
  <ng-template #optionItemTemplate let-country let-showDetails="showDetails" let-isOpen="isOpen">
    <kng-destination-country-enhanced-summary
      [country]="country"
      [showDetails]="showDetails"
      [showMoreDetailsIcon]="isOpen"
      (itemSelected)="valueChange.emit($event.code)"
      (showOfferPopup)="showOfferPopup($event.code)"
      (moreDetailsClicked)="popupCountry = country"
    ></kng-destination-country-enhanced-summary>
  </ng-template>
</kng-form-field-selector>

<kng-details-popup
  *ngIf="popupCountry"
  [images]="popupCountry?.galleryUrls"
  [isJuniors]="isJuniors"
  (ctaClicked)="onDetailsPopupCtaClicked()"
  (popupClosed)="popupCountry = null"
  [googleAnalyticsClickEvent]="GoogleAnalyticsEvents.QUOTE_HIGHLIGHT_COUNTRY"
>
  <h6 class="country-summary__popup-title">{{ popupCountry?.name }}</h6>
  <div class="country-summary__popup-description">{{ isJuniors ? popupCountry?.descriptionLarge : popupCountry?.description }}</div>
</kng-details-popup>
