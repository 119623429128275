import { Component, EventEmitter, HostListener, Input, Output } from '@angular/core';
import { speedDialFabAnimation } from '@klg/ui/animations';

@Component({
  selector: 'kng-quote-speed-dial-fab',
  templateUrl: './quote-speed-dial-fab.component.html',
  styleUrls: ['./quote-speed-dial-fab.component.scss'],
  animations: [speedDialFabAnimation],
})
export class QuoteSpeedDialFabComponent {
  @Input() hasProductInfo = false;
  @Input() isProductEditable = false;
  @Output() infoFabClicked = new EventEmitter<void>();
  @Output() editFabClicked = new EventEmitter<void>();
  @Output() removeFabClicked = new EventEmitter<void>();
  public showOptions = false;

  public onToggleFab() {
    this.showOptions ? this.hidePopoverOptions() : this.showPopoverOptions();
  }

  public showPopoverOptions() {
    this.showOptions = true;
  }

  @HostListener('window:resize', ['$event'])
  @HostListener('document:click', ['$event'])
  public hidePopoverOptions() {
    this.showOptions = false;
  }
}
