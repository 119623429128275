/**
 * KLG Quotes API
 * This is KLG API to perform quote calculations and retrieve auxiliary pricing information.
 *
 * The version of the OpenAPI document: dev
 * Contact: xxx@kaplan.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
/* tslint:disable:no-unused-variable member-ordering */

import { Inject, Injectable, Optional } from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams, HttpResponse, HttpEvent, HttpParameterCodec } from '@angular/common/http';
import { CustomHttpParameterCodec } from '../encoder';
import { Observable } from 'rxjs';

import { CityApiDto } from '../model/models';
import { CountryApiDto } from '../model/models';
import { CountryWeightApiDto } from '../model/models';
import { DefaultErrorMessageApiDto } from '../model/models';
import { DestinationCityFilterApiDto } from '../model/models';
import { DestinationCountryFilterApiDto } from '../model/models';

import { BASE_PATH, COLLECTION_FORMATS } from '../variables';
import { Configuration } from '../configuration';
import { DestinationControllerApiServiceInterface } from './destination-controller.serviceInterface';

@Injectable({
  providedIn: 'root',
})
export class DestinationControllerApiService implements DestinationControllerApiServiceInterface {
  protected basePath = 'http://apiv2-dev.klgintdc.com/api';
  public defaultHeaders = new HttpHeaders();
  public configuration = new Configuration();
  public encoder: HttpParameterCodec;

  constructor(protected httpClient: HttpClient, @Optional() @Inject(BASE_PATH) basePath: string, @Optional() configuration: Configuration) {
    if (configuration) {
      this.configuration = configuration;
    }
    if (typeof this.configuration.basePath !== 'string') {
      if (typeof basePath !== 'string') {
        basePath = this.basePath;
      }
      this.configuration.basePath = basePath;
    }
    this.encoder = this.configuration.encoder || new CustomHttpParameterCodec();
  }

  private addToHttpParams(httpParams: HttpParams, value: any, key?: string): HttpParams {
    if (typeof value === 'object' && value instanceof Date === false) {
      httpParams = this.addToHttpParamsRecursive(httpParams, value);
    } else {
      httpParams = this.addToHttpParamsRecursive(httpParams, value, key);
    }
    return httpParams;
  }

  private addToHttpParamsRecursive(httpParams: HttpParams, value?: any, key?: string): HttpParams {
    if (value == null) {
      return httpParams;
    }

    if (typeof value === 'object') {
      if (Array.isArray(value)) {
        (value as any[]).forEach((elem) => (httpParams = this.addToHttpParamsRecursive(httpParams, elem, key)));
      } else if (value instanceof Date) {
        if (key != null) {
          httpParams = httpParams.append(key, (value as Date).toISOString().substr(0, 10));
        } else {
          throw Error('key may not be null if value is Date');
        }
      } else {
        Object.keys(value).forEach((k) => (httpParams = this.addToHttpParamsRecursive(httpParams, value[k], key != null ? `${key}.${k}` : k)));
      }
    } else if (key != null) {
      httpParams = httpParams.append(key, value);
    } else {
      throw Error('key may not be null if value is not object or array');
    }
    return httpParams;
  }

  /**
   * @param filter
   * @param acceptLanguage Language code to inform the display language for the results
   * @param acceptCompany Company code for the source of data
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
  public findDestinationCitiesByFilter(
    filter: DestinationCityFilterApiDto,
    acceptLanguage: string,
    acceptCompany: 'ALP' | 'KIL' | 'ESL' | 'KSG',
    observe?: 'body',
    reportProgress?: boolean,
    options?: { httpHeaderAccept?: 'application/json' },
  ): Observable<Array<CityApiDto>>;
  public findDestinationCitiesByFilter(
    filter: DestinationCityFilterApiDto,
    acceptLanguage: string,
    acceptCompany: 'ALP' | 'KIL' | 'ESL' | 'KSG',
    observe?: 'response',
    reportProgress?: boolean,
    options?: { httpHeaderAccept?: 'application/json' },
  ): Observable<HttpResponse<Array<CityApiDto>>>;
  public findDestinationCitiesByFilter(
    filter: DestinationCityFilterApiDto,
    acceptLanguage: string,
    acceptCompany: 'ALP' | 'KIL' | 'ESL' | 'KSG',
    observe?: 'events',
    reportProgress?: boolean,
    options?: { httpHeaderAccept?: 'application/json' },
  ): Observable<HttpEvent<Array<CityApiDto>>>;
  public findDestinationCitiesByFilter(
    filter: DestinationCityFilterApiDto,
    acceptLanguage: string,
    acceptCompany: 'ALP' | 'KIL' | 'ESL' | 'KSG',
    observe: any = 'body',
    reportProgress: boolean = false,
    options?: { httpHeaderAccept?: 'application/json' },
  ): Observable<any> {
    if (filter === null || filter === undefined) {
      throw new Error('Required parameter filter was null or undefined when calling findDestinationCitiesByFilter.');
    }
    if (acceptLanguage === null || acceptLanguage === undefined) {
      throw new Error('Required parameter acceptLanguage was null or undefined when calling findDestinationCitiesByFilter.');
    }
    if (acceptCompany === null || acceptCompany === undefined) {
      throw new Error('Required parameter acceptCompany was null or undefined when calling findDestinationCitiesByFilter.');
    }

    let queryParameters = new HttpParams({ encoder: this.encoder });
    if (filter !== undefined && filter !== null) {
      queryParameters = this.addToHttpParams(queryParameters, <any>filter, 'filter');
    }

    let headers = this.defaultHeaders;
    if (acceptLanguage !== undefined && acceptLanguage !== null) {
      headers = headers.set('Accept-Language', String(acceptLanguage));
    }
    if (acceptCompany !== undefined && acceptCompany !== null) {
      headers = headers.set('Accept-Company', String(acceptCompany));
    }

    let httpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
    if (httpHeaderAcceptSelected === undefined) {
      // to determine the Accept header
      const httpHeaderAccepts: string[] = ['application/json'];
      httpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
    }
    if (httpHeaderAcceptSelected !== undefined) {
      headers = headers.set('Accept', httpHeaderAcceptSelected);
    }

    let responseType: 'text' | 'json' = 'json';
    if (httpHeaderAcceptSelected && httpHeaderAcceptSelected.startsWith('text')) {
      responseType = 'text';
    }

    return this.httpClient.get<Array<CityApiDto>>(`${this.configuration.basePath}/products/v2/destinations/cities`, {
      params: queryParameters,
      responseType: <any>responseType,
      withCredentials: this.configuration.withCredentials,
      headers: headers,
      observe: observe,
      reportProgress: reportProgress,
    });
  }

  /**
   * @param filter
   * @param acceptLanguage Language code to inform the display language for the results
   * @param acceptCompany Company code for the source of data
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
  public findDestinationCountriesByFilter(
    filter: DestinationCountryFilterApiDto,
    acceptLanguage: string,
    acceptCompany: 'ALP' | 'KIL' | 'ESL' | 'KSG',
    observe?: 'body',
    reportProgress?: boolean,
    options?: { httpHeaderAccept?: 'application/json' },
  ): Observable<Array<CountryApiDto>>;
  public findDestinationCountriesByFilter(
    filter: DestinationCountryFilterApiDto,
    acceptLanguage: string,
    acceptCompany: 'ALP' | 'KIL' | 'ESL' | 'KSG',
    observe?: 'response',
    reportProgress?: boolean,
    options?: { httpHeaderAccept?: 'application/json' },
  ): Observable<HttpResponse<Array<CountryApiDto>>>;
  public findDestinationCountriesByFilter(
    filter: DestinationCountryFilterApiDto,
    acceptLanguage: string,
    acceptCompany: 'ALP' | 'KIL' | 'ESL' | 'KSG',
    observe?: 'events',
    reportProgress?: boolean,
    options?: { httpHeaderAccept?: 'application/json' },
  ): Observable<HttpEvent<Array<CountryApiDto>>>;
  public findDestinationCountriesByFilter(
    filter: DestinationCountryFilterApiDto,
    acceptLanguage: string,
    acceptCompany: 'ALP' | 'KIL' | 'ESL' | 'KSG',
    observe: any = 'body',
    reportProgress: boolean = false,
    options?: { httpHeaderAccept?: 'application/json' },
  ): Observable<any> {
    if (filter === null || filter === undefined) {
      throw new Error('Required parameter filter was null or undefined when calling findDestinationCountriesByFilter.');
    }
    if (acceptLanguage === null || acceptLanguage === undefined) {
      throw new Error('Required parameter acceptLanguage was null or undefined when calling findDestinationCountriesByFilter.');
    }
    if (acceptCompany === null || acceptCompany === undefined) {
      throw new Error('Required parameter acceptCompany was null or undefined when calling findDestinationCountriesByFilter.');
    }

    let queryParameters = new HttpParams({ encoder: this.encoder });
    if (filter !== undefined && filter !== null) {
      queryParameters = this.addToHttpParams(queryParameters, <any>filter, 'filter');
    }

    let headers = this.defaultHeaders;
    if (acceptLanguage !== undefined && acceptLanguage !== null) {
      headers = headers.set('Accept-Language', String(acceptLanguage));
    }
    if (acceptCompany !== undefined && acceptCompany !== null) {
      headers = headers.set('Accept-Company', String(acceptCompany));
    }

    let httpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
    if (httpHeaderAcceptSelected === undefined) {
      // to determine the Accept header
      const httpHeaderAccepts: string[] = ['application/json'];
      httpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
    }
    if (httpHeaderAcceptSelected !== undefined) {
      headers = headers.set('Accept', httpHeaderAcceptSelected);
    }

    let responseType: 'text' | 'json' = 'json';
    if (httpHeaderAcceptSelected && httpHeaderAcceptSelected.startsWith('text')) {
      responseType = 'text';
    }

    return this.httpClient.get<Array<CountryApiDto>>(`${this.configuration.basePath}/products/v3/destinations/countries`, {
      params: queryParameters,
      responseType: <any>responseType,
      withCredentials: this.configuration.withCredentials,
      headers: headers,
      observe: observe,
      reportProgress: reportProgress,
    });
  }

  /**
   * @param acceptLanguage Language code to inform the display language for the results
   * @param acceptCompany Company code for the source of data
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
  public getQuoteToolCountryWeights(
    acceptLanguage: string,
    acceptCompany: 'ALP' | 'KIL' | 'ESL' | 'KSG',
    observe?: 'body',
    reportProgress?: boolean,
    options?: { httpHeaderAccept?: 'application/json' },
  ): Observable<Array<CountryWeightApiDto>>;
  public getQuoteToolCountryWeights(
    acceptLanguage: string,
    acceptCompany: 'ALP' | 'KIL' | 'ESL' | 'KSG',
    observe?: 'response',
    reportProgress?: boolean,
    options?: { httpHeaderAccept?: 'application/json' },
  ): Observable<HttpResponse<Array<CountryWeightApiDto>>>;
  public getQuoteToolCountryWeights(
    acceptLanguage: string,
    acceptCompany: 'ALP' | 'KIL' | 'ESL' | 'KSG',
    observe?: 'events',
    reportProgress?: boolean,
    options?: { httpHeaderAccept?: 'application/json' },
  ): Observable<HttpEvent<Array<CountryWeightApiDto>>>;
  public getQuoteToolCountryWeights(
    acceptLanguage: string,
    acceptCompany: 'ALP' | 'KIL' | 'ESL' | 'KSG',
    observe: any = 'body',
    reportProgress: boolean = false,
    options?: { httpHeaderAccept?: 'application/json' },
  ): Observable<any> {
    if (acceptLanguage === null || acceptLanguage === undefined) {
      throw new Error('Required parameter acceptLanguage was null or undefined when calling getQuoteToolCountryWeights.');
    }
    if (acceptCompany === null || acceptCompany === undefined) {
      throw new Error('Required parameter acceptCompany was null or undefined when calling getQuoteToolCountryWeights.');
    }

    let headers = this.defaultHeaders;
    if (acceptLanguage !== undefined && acceptLanguage !== null) {
      headers = headers.set('Accept-Language', String(acceptLanguage));
    }
    if (acceptCompany !== undefined && acceptCompany !== null) {
      headers = headers.set('Accept-Company', String(acceptCompany));
    }

    let httpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
    if (httpHeaderAcceptSelected === undefined) {
      // to determine the Accept header
      const httpHeaderAccepts: string[] = ['application/json'];
      httpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
    }
    if (httpHeaderAcceptSelected !== undefined) {
      headers = headers.set('Accept', httpHeaderAcceptSelected);
    }

    let responseType: 'text' | 'json' = 'json';
    if (httpHeaderAcceptSelected && httpHeaderAcceptSelected.startsWith('text')) {
      responseType = 'text';
    }

    return this.httpClient.get<Array<CountryWeightApiDto>>(`${this.configuration.basePath}/products/v3/destinations/countries/quote-tool-weights`, {
      responseType: <any>responseType,
      withCredentials: this.configuration.withCredentials,
      headers: headers,
      observe: observe,
      reportProgress: reportProgress,
    });
  }
}
