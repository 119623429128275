import { Component, EventEmitter, inject, Input, OnChanges, OnDestroy, OnInit, Output, SimpleChanges } from '@angular/core';
import { deepClone } from '@klg/shared/utils';
import { SortingCriteria } from '@klg/shared/types';
import { ResidenceCountry, ResidenceCountryService } from '@klg/shared/data-access/destination';
import { Subscription } from 'rxjs';
import { QuoteToolStore } from '@klg/quote-tool/shared/store';
import { StepService } from '@klg/quote-tool/shared/services';
import { CountriesExperimentsService } from '@klg/quote-tool/shared/experiments';

@Component({
  selector: 'kng-residence-country-selector',
  templateUrl: './residence-country-selector.component.html',
  styleUrls: ['./residence-country-selector.component.scss'],
})
export class ResidenceCountrySelectorComponent implements OnInit, OnDestroy, OnChanges {
  @Input() small = false;
  @Input() label: string;
  @Input() disabled = false;
  @Input() placeholder: string;
  @Input() value: string | undefined;
  @Input() sortCriteria: SortingCriteria<ResidenceCountry> = [{ property: 'name' }];
  @Input() required = false;
  @Input() iconValidation = false;
  @Output() valueChange = new EventEmitter<string>();

  countriesOfResidence: ResidenceCountry[] = [];
  selectedValue: string | undefined;
  dirty = false;

  private subscription = new Subscription();
  private readonly residenceCountryService = inject(ResidenceCountryService);
  private readonly quoteToolStore = inject(QuoteToolStore);
  private readonly stepService = inject(StepService);
  private readonly countriesExperimentsService = inject(CountriesExperimentsService);

  ngOnInit() {
    this.getItemList();
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.value?.currentValue) {
      this.selectedValue = changes.value.currentValue;
      // emit the event to allow the system be aware that this value has changed (even if this
      // change was done programmatically)
      this.valueChange.emit(this.selectedValue);
      this.setResidenceData();
    }
  }

  ngOnDestroy() {
    this.subscription.unsubscribe();
  }

  private getItemList() {
    this.subscription.add(
      this.residenceCountryService.getAll().subscribe((data) => {
        this.countriesOfResidence = data.map(deepClone);
        this.quoteToolStore.updateCountriesOfResidence(this.countriesOfResidence);
        this.setResidenceData();
      }),
    );
  }

  private setResidenceData() {
    if (!this.countriesExperimentsService.isCountryExperimentActive()) {
      return;
    }
    this.stepService.setResidenceData({
      countryOfResidence: this.selectedValue,
      residenceCurrency: this.stepService.getResidenceData()?.residenceCurrency,
      countryPhoneCode: this.countriesOfResidence?.find((country) => country?.code === this.selectedValue)?.phoneCode,
    });
  }
}
