<kng-loading-spinner i18n-message message="Loading Accommodations..." [loading]="loading" shadingColor="rgba(0,0,0,0.5)"></kng-loading-spinner>

<kng-section-template [sectionIndex]="sectionIndex" i18n-sectionTitle sectionTitle="Select accommodation">
  <ng-container *ngFor="let accommodation of sectionModel.accommodations; let accommodationIndex = index; let isLast = last">
    <div>
      <kng-form-field-selector
        label="Accommodation"
        i18n-label
        class="qt-form-section__start-new-line"
        [disabled]="!selectedCourses?.length || hasIncompleteCourses"
        [autofill]="selectedCourses?.length && !hasIncompleteCourses"
        [value]="accommodation.code"
        valueExpr="code"
        [itemList]="accommodationIndex === 0 ? accommodationsWithEmptyOption : accommodationOptions[accommodationIndex].items"
        (valueChange)="accommodationChanged(accommodationIndex, $event)"
        [required]="isAccommodationMandatory"
        [sortCriteria]="[{ property: 'productPosition' }, { property: 'weight' }, { property: 'name' }]"
        [attr.data-qa]="'qt-accommodation-selector-' + accommodationIndex"
      ></kng-form-field-selector>
      <div class="accommodation-dates-disclaimer" *ngIf="accommodationAvailability?.length">
        <span i18n>This accommodation is only available for the following dates during your stay:</span>
        <span class="accommodation-dates-disclaimer-items" *ngFor="let availDate of accommodationAvailability">
          {{ availDate.from | klgDate : DateFormat.SHORT_MONTH_NAME }} - {{ availDate.until | klgDate : DateFormat.SHORT_MONTH_NAME }}
        </span>
      </div>
    </div>
    <kng-weeks-selector
      *ngIf="!isWithoutAccommodation(accommodation)"
      label="Duration"
      i18n-label
      [disabled]="!accommodation?.code"
      [autofill]="!!accommodation?.code"
      [value]="accommodation.weeks"
      [minWeeks]="accommodationOptions[accommodationIndex].durationMin"
      [maxWeeks]="accommodationOptions[accommodationIndex].durationMax"
      [required]="accommodationIndex === 0"
      (valueChange)="durationChange(accommodationIndex, $event)"
      [attr.data-qa]="'qt-accommodation-duration-selector-' + accommodationIndex"
    ></kng-weeks-selector>

    <kng-date-picker-field
      *ngIf="!isWithoutAccommodation(accommodation)"
      i18n-label
      label="Start Date"
      i18n-placeholder
      placeholder="— Please select  —"
      [disabled]="!accommodation.weeks"
      [dateValue]="accommodation.startDate"
      [minDate]="accommodationOptions[accommodationIndex].minDate"
      [maxDate]="accommodationOptions[accommodationIndex].maxDate"
      [defaultDate]="accommodationOptions[accommodationIndex].minDate"
      [disabledDays]="accommodationOptions[accommodationIndex].disabledDays"
      [disabledDates]="accommodationOptions[accommodationIndex].disabledDates"
      [showCalendarIcon]="true"
      [required]="accommodationIndex === 0"
      (dateChanged)="accommodationStartDateChange(accommodationIndex, $event)"
      [attr.data-qa]="'qt-accommodation-start-date-selector-' + accommodationIndex"
    ></kng-date-picker-field>
    <div *ngIf="hasButtons()" class="qt-form-section__start-new-line">
      <kng-button
        text="Delete the accommodation above"
        i18n-text
        [icon]="'pi pi-times'"
        [iconPos]="'before'"
        [iconFill]="true"
        [disabled]="!selectedCourses?.length || sectionModel.accommodations.length < 2"
        [iconRounded]="true"
        [buttonStyle]="'plain-text'"
        [attr.data-qa]="'qt-remove-accommodation-' + accommodationIndex"
        (buttonClick)="removeAccommodationItem(accommodationIndex)"
      >
      </kng-button>
    </div>
    <div *ngIf="isLast && hasButtons()">
      <kng-button
        text="Add an accommodation"
        i18n-text
        [icon]="'pi pi-plus'"
        [iconPos]="'before'"
        [iconFill]="true"
        [disabled]="!selectedCourses?.length || hasIncompleteCourses || !accommodation.startDate || !calculateDurationMax(accommodationIndex + 1)"
        [iconRounded]="true"
        [buttonStyle]="'plain-text'"
        [attr.data-qa]="'qt-add-accommodation-' + accommodationIndex"
        (buttonClick)="addAccommodationItem(accommodationIndex + 1)"
      >
      </kng-button>
    </div>
  </ng-container>
</kng-section-template>
