/**
 * KLG Quotes API
 * This is KLG API to perform quote calculations and retrieve auxiliary pricing information.
 *
 * The version of the OpenAPI document: dev
 * Contact: xxx@kaplan.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
/* tslint:disable:no-unused-variable member-ordering */

import { Inject, Injectable, Optional } from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams, HttpResponse, HttpEvent, HttpParameterCodec } from '@angular/common/http';
import { CustomHttpParameterCodec } from '../encoder';
import { Observable } from 'rxjs';

import { AccommodationV2ApiDto } from '../model/models';
import { CampV3ApiDto } from '../model/models';
import { CourseTypeApiDto } from '../model/models';
import { DefaultErrorMessageApiDto } from '../model/models';
import { InsuranceOptionApiDto } from '../model/models';
import { LanguageApiDto } from '../model/models';
import { ProductPackageApiDto } from '../model/models';
import { QuoteCoursePurposeApiDto } from '../model/models';
import { QuoteSchoolApiDto } from '../model/models';
import { ResidenceCountryApiDto } from '../model/models';
import { SchoolPackageDtoApiDto } from '../model/models';

import { BASE_PATH, COLLECTION_FORMATS } from '../variables';
import { Configuration } from '../configuration';
import { ProductsControllerApiServiceInterface } from './products-controller.serviceInterface';

@Injectable({
  providedIn: 'root',
})
export class ProductsControllerApiService implements ProductsControllerApiServiceInterface {
  protected basePath = 'http://apiv2-dev.klgintdc.com/api';
  public defaultHeaders = new HttpHeaders();
  public configuration = new Configuration();
  public encoder: HttpParameterCodec;

  constructor(protected httpClient: HttpClient, @Optional() @Inject(BASE_PATH) basePath: string, @Optional() configuration: Configuration) {
    if (configuration) {
      this.configuration = configuration;
    }
    if (typeof this.configuration.basePath !== 'string') {
      if (typeof basePath !== 'string') {
        basePath = this.basePath;
      }
      this.configuration.basePath = basePath;
    }
    this.encoder = this.configuration.encoder || new CustomHttpParameterCodec();
  }

  private addToHttpParams(httpParams: HttpParams, value: any, key?: string): HttpParams {
    if (typeof value === 'object' && value instanceof Date === false) {
      httpParams = this.addToHttpParamsRecursive(httpParams, value);
    } else {
      httpParams = this.addToHttpParamsRecursive(httpParams, value, key);
    }
    return httpParams;
  }

  private addToHttpParamsRecursive(httpParams: HttpParams, value?: any, key?: string): HttpParams {
    if (value == null) {
      return httpParams;
    }

    if (typeof value === 'object') {
      if (Array.isArray(value)) {
        (value as any[]).forEach((elem) => (httpParams = this.addToHttpParamsRecursive(httpParams, elem, key)));
      } else if (value instanceof Date) {
        if (key != null) {
          httpParams = httpParams.append(key, (value as Date).toISOString().substr(0, 10));
        } else {
          throw Error('key may not be null if value is Date');
        }
      } else {
        Object.keys(value).forEach((k) => (httpParams = this.addToHttpParamsRecursive(httpParams, value[k], key != null ? `${key}.${k}` : k)));
      }
    } else if (key != null) {
      httpParams = httpParams.append(key, value);
    } else {
      throw Error('key may not be null if value is not object or array');
    }
    return httpParams;
  }

  /**
   * Return the price for a specific accommodation for a specific school in V2 format
   * @param residenceCountryCode
   * @param schoolCode
   * @param accommodationCode
   * @param acceptLanguage Language code to inform the display language for the results
   * @param acceptCompany Company code for the source of data
   * @param productPackage
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
  public getAccommodationV2(
    residenceCountryCode: string,
    schoolCode: string,
    accommodationCode: string,
    acceptLanguage: string,
    acceptCompany: 'ALP' | 'KIL' | 'ESL' | 'KSG',
    productPackage?: ProductPackageApiDto,
    observe?: 'body',
    reportProgress?: boolean,
    options?: { httpHeaderAccept?: 'application/json' },
  ): Observable<AccommodationV2ApiDto>;
  public getAccommodationV2(
    residenceCountryCode: string,
    schoolCode: string,
    accommodationCode: string,
    acceptLanguage: string,
    acceptCompany: 'ALP' | 'KIL' | 'ESL' | 'KSG',
    productPackage?: ProductPackageApiDto,
    observe?: 'response',
    reportProgress?: boolean,
    options?: { httpHeaderAccept?: 'application/json' },
  ): Observable<HttpResponse<AccommodationV2ApiDto>>;
  public getAccommodationV2(
    residenceCountryCode: string,
    schoolCode: string,
    accommodationCode: string,
    acceptLanguage: string,
    acceptCompany: 'ALP' | 'KIL' | 'ESL' | 'KSG',
    productPackage?: ProductPackageApiDto,
    observe?: 'events',
    reportProgress?: boolean,
    options?: { httpHeaderAccept?: 'application/json' },
  ): Observable<HttpEvent<AccommodationV2ApiDto>>;
  public getAccommodationV2(
    residenceCountryCode: string,
    schoolCode: string,
    accommodationCode: string,
    acceptLanguage: string,
    acceptCompany: 'ALP' | 'KIL' | 'ESL' | 'KSG',
    productPackage?: ProductPackageApiDto,
    observe: any = 'body',
    reportProgress: boolean = false,
    options?: { httpHeaderAccept?: 'application/json' },
  ): Observable<any> {
    if (residenceCountryCode === null || residenceCountryCode === undefined) {
      throw new Error('Required parameter residenceCountryCode was null or undefined when calling getAccommodationV2.');
    }
    if (schoolCode === null || schoolCode === undefined) {
      throw new Error('Required parameter schoolCode was null or undefined when calling getAccommodationV2.');
    }
    if (accommodationCode === null || accommodationCode === undefined) {
      throw new Error('Required parameter accommodationCode was null or undefined when calling getAccommodationV2.');
    }
    if (acceptLanguage === null || acceptLanguage === undefined) {
      throw new Error('Required parameter acceptLanguage was null or undefined when calling getAccommodationV2.');
    }
    if (acceptCompany === null || acceptCompany === undefined) {
      throw new Error('Required parameter acceptCompany was null or undefined when calling getAccommodationV2.');
    }

    let queryParameters = new HttpParams({ encoder: this.encoder });
    if (productPackage !== undefined && productPackage !== null) {
      queryParameters = this.addToHttpParams(queryParameters, <any>productPackage, 'productPackage');
    }
    if (residenceCountryCode !== undefined && residenceCountryCode !== null) {
      queryParameters = this.addToHttpParams(queryParameters, <any>residenceCountryCode, 'residenceCountryCode');
    }
    if (schoolCode !== undefined && schoolCode !== null) {
      queryParameters = this.addToHttpParams(queryParameters, <any>schoolCode, 'schoolCode');
    }

    let headers = this.defaultHeaders;
    if (acceptLanguage !== undefined && acceptLanguage !== null) {
      headers = headers.set('Accept-Language', String(acceptLanguage));
    }
    if (acceptCompany !== undefined && acceptCompany !== null) {
      headers = headers.set('Accept-Company', String(acceptCompany));
    }

    let httpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
    if (httpHeaderAcceptSelected === undefined) {
      // to determine the Accept header
      const httpHeaderAccepts: string[] = ['application/json'];
      httpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
    }
    if (httpHeaderAcceptSelected !== undefined) {
      headers = headers.set('Accept', httpHeaderAcceptSelected);
    }

    let responseType: 'text' | 'json' = 'json';
    if (httpHeaderAcceptSelected && httpHeaderAcceptSelected.startsWith('text')) {
      responseType = 'text';
    }

    return this.httpClient.get<AccommodationV2ApiDto>(
      `${this.configuration.basePath}/quotes/v2/accommodations/${encodeURIComponent(String(accommodationCode))}`,
      {
        params: queryParameters,
        responseType: <any>responseType,
        withCredentials: this.configuration.withCredentials,
        headers: headers,
        observe: observe,
        reportProgress: reportProgress,
      },
    );
  }

  /**
   * Return the list of camps V3
   * @param productPackage
   * @param acceptLanguage Language code to inform the display language for the results
   * @param acceptCompany Company code for the source of data
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
  public getCampsV3(
    productPackage: ProductPackageApiDto,
    acceptLanguage: string,
    acceptCompany: 'ALP' | 'KIL' | 'ESL' | 'KSG',
    observe?: 'body',
    reportProgress?: boolean,
    options?: { httpHeaderAccept?: 'application/json' },
  ): Observable<Array<CampV3ApiDto>>;
  public getCampsV3(
    productPackage: ProductPackageApiDto,
    acceptLanguage: string,
    acceptCompany: 'ALP' | 'KIL' | 'ESL' | 'KSG',
    observe?: 'response',
    reportProgress?: boolean,
    options?: { httpHeaderAccept?: 'application/json' },
  ): Observable<HttpResponse<Array<CampV3ApiDto>>>;
  public getCampsV3(
    productPackage: ProductPackageApiDto,
    acceptLanguage: string,
    acceptCompany: 'ALP' | 'KIL' | 'ESL' | 'KSG',
    observe?: 'events',
    reportProgress?: boolean,
    options?: { httpHeaderAccept?: 'application/json' },
  ): Observable<HttpEvent<Array<CampV3ApiDto>>>;
  public getCampsV3(
    productPackage: ProductPackageApiDto,
    acceptLanguage: string,
    acceptCompany: 'ALP' | 'KIL' | 'ESL' | 'KSG',
    observe: any = 'body',
    reportProgress: boolean = false,
    options?: { httpHeaderAccept?: 'application/json' },
  ): Observable<any> {
    if (productPackage === null || productPackage === undefined) {
      throw new Error('Required parameter productPackage was null or undefined when calling getCampsV3.');
    }
    if (acceptLanguage === null || acceptLanguage === undefined) {
      throw new Error('Required parameter acceptLanguage was null or undefined when calling getCampsV3.');
    }
    if (acceptCompany === null || acceptCompany === undefined) {
      throw new Error('Required parameter acceptCompany was null or undefined when calling getCampsV3.');
    }

    let queryParameters = new HttpParams({ encoder: this.encoder });
    if (productPackage !== undefined && productPackage !== null) {
      queryParameters = this.addToHttpParams(queryParameters, <any>productPackage, 'productPackage');
    }

    let headers = this.defaultHeaders;
    if (acceptLanguage !== undefined && acceptLanguage !== null) {
      headers = headers.set('Accept-Language', String(acceptLanguage));
    }
    if (acceptCompany !== undefined && acceptCompany !== null) {
      headers = headers.set('Accept-Company', String(acceptCompany));
    }

    let httpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
    if (httpHeaderAcceptSelected === undefined) {
      // to determine the Accept header
      const httpHeaderAccepts: string[] = ['application/json'];
      httpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
    }
    if (httpHeaderAcceptSelected !== undefined) {
      headers = headers.set('Accept', httpHeaderAcceptSelected);
    }

    let responseType: 'text' | 'json' = 'json';
    if (httpHeaderAcceptSelected && httpHeaderAcceptSelected.startsWith('text')) {
      responseType = 'text';
    }

    return this.httpClient.get<Array<CampV3ApiDto>>(`${this.configuration.basePath}/quotes/v3/camps`, {
      params: queryParameters,
      responseType: <any>responseType,
      withCredentials: this.configuration.withCredentials,
      headers: headers,
      observe: observe,
      reportProgress: reportProgress,
    });
  }

  /**
   * Return the list of languages offered
   * @param acceptLanguage Language code to inform the display language for the results
   * @param acceptCompany Company code for the source of data
   * @param productPackage
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
  public getCourseLanguages(
    acceptLanguage: string,
    acceptCompany: 'ALP' | 'KIL' | 'ESL' | 'KSG',
    productPackage?: ProductPackageApiDto,
    observe?: 'body',
    reportProgress?: boolean,
    options?: { httpHeaderAccept?: 'application/json' },
  ): Observable<Array<LanguageApiDto>>;
  public getCourseLanguages(
    acceptLanguage: string,
    acceptCompany: 'ALP' | 'KIL' | 'ESL' | 'KSG',
    productPackage?: ProductPackageApiDto,
    observe?: 'response',
    reportProgress?: boolean,
    options?: { httpHeaderAccept?: 'application/json' },
  ): Observable<HttpResponse<Array<LanguageApiDto>>>;
  public getCourseLanguages(
    acceptLanguage: string,
    acceptCompany: 'ALP' | 'KIL' | 'ESL' | 'KSG',
    productPackage?: ProductPackageApiDto,
    observe?: 'events',
    reportProgress?: boolean,
    options?: { httpHeaderAccept?: 'application/json' },
  ): Observable<HttpEvent<Array<LanguageApiDto>>>;
  public getCourseLanguages(
    acceptLanguage: string,
    acceptCompany: 'ALP' | 'KIL' | 'ESL' | 'KSG',
    productPackage?: ProductPackageApiDto,
    observe: any = 'body',
    reportProgress: boolean = false,
    options?: { httpHeaderAccept?: 'application/json' },
  ): Observable<any> {
    if (acceptLanguage === null || acceptLanguage === undefined) {
      throw new Error('Required parameter acceptLanguage was null or undefined when calling getCourseLanguages.');
    }
    if (acceptCompany === null || acceptCompany === undefined) {
      throw new Error('Required parameter acceptCompany was null or undefined when calling getCourseLanguages.');
    }

    let queryParameters = new HttpParams({ encoder: this.encoder });
    if (productPackage !== undefined && productPackage !== null) {
      queryParameters = this.addToHttpParams(queryParameters, <any>productPackage, 'productPackage');
    }

    let headers = this.defaultHeaders;
    if (acceptLanguage !== undefined && acceptLanguage !== null) {
      headers = headers.set('Accept-Language', String(acceptLanguage));
    }
    if (acceptCompany !== undefined && acceptCompany !== null) {
      headers = headers.set('Accept-Company', String(acceptCompany));
    }

    let httpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
    if (httpHeaderAcceptSelected === undefined) {
      // to determine the Accept header
      const httpHeaderAccepts: string[] = ['application/json'];
      httpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
    }
    if (httpHeaderAcceptSelected !== undefined) {
      headers = headers.set('Accept', httpHeaderAcceptSelected);
    }

    let responseType: 'text' | 'json' = 'json';
    if (httpHeaderAcceptSelected && httpHeaderAcceptSelected.startsWith('text')) {
      responseType = 'text';
    }

    return this.httpClient.get<Array<LanguageApiDto>>(`${this.configuration.basePath}/quotes/v1/course-languages`, {
      params: queryParameters,
      responseType: <any>responseType,
      withCredentials: this.configuration.withCredentials,
      headers: headers,
      observe: observe,
      reportProgress: reportProgress,
    });
  }

  /**
   * Return the list of course types
   * @param acceptLanguage Language code to inform the display language for the results
   * @param acceptCompany Company code for the source of data
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
  public getCourseTypes(
    acceptLanguage: string,
    acceptCompany: 'ALP' | 'KIL' | 'ESL' | 'KSG',
    observe?: 'body',
    reportProgress?: boolean,
    options?: { httpHeaderAccept?: 'application/json' },
  ): Observable<Array<CourseTypeApiDto>>;
  public getCourseTypes(
    acceptLanguage: string,
    acceptCompany: 'ALP' | 'KIL' | 'ESL' | 'KSG',
    observe?: 'response',
    reportProgress?: boolean,
    options?: { httpHeaderAccept?: 'application/json' },
  ): Observable<HttpResponse<Array<CourseTypeApiDto>>>;
  public getCourseTypes(
    acceptLanguage: string,
    acceptCompany: 'ALP' | 'KIL' | 'ESL' | 'KSG',
    observe?: 'events',
    reportProgress?: boolean,
    options?: { httpHeaderAccept?: 'application/json' },
  ): Observable<HttpEvent<Array<CourseTypeApiDto>>>;
  public getCourseTypes(
    acceptLanguage: string,
    acceptCompany: 'ALP' | 'KIL' | 'ESL' | 'KSG',
    observe: any = 'body',
    reportProgress: boolean = false,
    options?: { httpHeaderAccept?: 'application/json' },
  ): Observable<any> {
    if (acceptLanguage === null || acceptLanguage === undefined) {
      throw new Error('Required parameter acceptLanguage was null or undefined when calling getCourseTypes.');
    }
    if (acceptCompany === null || acceptCompany === undefined) {
      throw new Error('Required parameter acceptCompany was null or undefined when calling getCourseTypes.');
    }

    let headers = this.defaultHeaders;
    if (acceptLanguage !== undefined && acceptLanguage !== null) {
      headers = headers.set('Accept-Language', String(acceptLanguage));
    }
    if (acceptCompany !== undefined && acceptCompany !== null) {
      headers = headers.set('Accept-Company', String(acceptCompany));
    }

    let httpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
    if (httpHeaderAcceptSelected === undefined) {
      // to determine the Accept header
      const httpHeaderAccepts: string[] = ['application/json'];
      httpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
    }
    if (httpHeaderAcceptSelected !== undefined) {
      headers = headers.set('Accept', httpHeaderAcceptSelected);
    }

    let responseType: 'text' | 'json' = 'json';
    if (httpHeaderAcceptSelected && httpHeaderAcceptSelected.startsWith('text')) {
      responseType = 'text';
    }

    return this.httpClient.get<Array<CourseTypeApiDto>>(`${this.configuration.basePath}/quotes/v1/course-types`, {
      responseType: <any>responseType,
      withCredentials: this.configuration.withCredentials,
      headers: headers,
      observe: observe,
      reportProgress: reportProgress,
    });
  }

  /**
   * Return the insurance options
   * @param acceptLanguage Language code to inform the display language for the results
   * @param acceptCompany Company code for the source of data
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
  public getInsuranceOptions(
    acceptLanguage: string,
    acceptCompany: 'ALP' | 'KIL' | 'ESL' | 'KSG',
    observe?: 'body',
    reportProgress?: boolean,
    options?: { httpHeaderAccept?: 'application/json' },
  ): Observable<Array<InsuranceOptionApiDto>>;
  public getInsuranceOptions(
    acceptLanguage: string,
    acceptCompany: 'ALP' | 'KIL' | 'ESL' | 'KSG',
    observe?: 'response',
    reportProgress?: boolean,
    options?: { httpHeaderAccept?: 'application/json' },
  ): Observable<HttpResponse<Array<InsuranceOptionApiDto>>>;
  public getInsuranceOptions(
    acceptLanguage: string,
    acceptCompany: 'ALP' | 'KIL' | 'ESL' | 'KSG',
    observe?: 'events',
    reportProgress?: boolean,
    options?: { httpHeaderAccept?: 'application/json' },
  ): Observable<HttpEvent<Array<InsuranceOptionApiDto>>>;
  public getInsuranceOptions(
    acceptLanguage: string,
    acceptCompany: 'ALP' | 'KIL' | 'ESL' | 'KSG',
    observe: any = 'body',
    reportProgress: boolean = false,
    options?: { httpHeaderAccept?: 'application/json' },
  ): Observable<any> {
    if (acceptLanguage === null || acceptLanguage === undefined) {
      throw new Error('Required parameter acceptLanguage was null or undefined when calling getInsuranceOptions.');
    }
    if (acceptCompany === null || acceptCompany === undefined) {
      throw new Error('Required parameter acceptCompany was null or undefined when calling getInsuranceOptions.');
    }

    let headers = this.defaultHeaders;
    if (acceptLanguage !== undefined && acceptLanguage !== null) {
      headers = headers.set('Accept-Language', String(acceptLanguage));
    }
    if (acceptCompany !== undefined && acceptCompany !== null) {
      headers = headers.set('Accept-Company', String(acceptCompany));
    }

    let httpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
    if (httpHeaderAcceptSelected === undefined) {
      // to determine the Accept header
      const httpHeaderAccepts: string[] = ['application/json'];
      httpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
    }
    if (httpHeaderAcceptSelected !== undefined) {
      headers = headers.set('Accept', httpHeaderAcceptSelected);
    }

    let responseType: 'text' | 'json' = 'json';
    if (httpHeaderAcceptSelected && httpHeaderAcceptSelected.startsWith('text')) {
      responseType = 'text';
    }

    return this.httpClient.get<Array<InsuranceOptionApiDto>>(`${this.configuration.basePath}/quotes/v1/insurances/options`, {
      responseType: <any>responseType,
      withCredentials: this.configuration.withCredentials,
      headers: headers,
      observe: observe,
      reportProgress: reportProgress,
    });
  }

  /**
   * @param acceptLanguage Language code to inform the display language for the results
   * @param acceptCompany Company code for the source of data
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
  public getLanguages(
    acceptLanguage: string,
    acceptCompany: 'ALP' | 'KIL' | 'ESL' | 'KSG',
    observe?: 'body',
    reportProgress?: boolean,
    options?: { httpHeaderAccept?: 'application/json' },
  ): Observable<any>;
  public getLanguages(
    acceptLanguage: string,
    acceptCompany: 'ALP' | 'KIL' | 'ESL' | 'KSG',
    observe?: 'response',
    reportProgress?: boolean,
    options?: { httpHeaderAccept?: 'application/json' },
  ): Observable<HttpResponse<any>>;
  public getLanguages(
    acceptLanguage: string,
    acceptCompany: 'ALP' | 'KIL' | 'ESL' | 'KSG',
    observe?: 'events',
    reportProgress?: boolean,
    options?: { httpHeaderAccept?: 'application/json' },
  ): Observable<HttpEvent<any>>;
  public getLanguages(
    acceptLanguage: string,
    acceptCompany: 'ALP' | 'KIL' | 'ESL' | 'KSG',
    observe: any = 'body',
    reportProgress: boolean = false,
    options?: { httpHeaderAccept?: 'application/json' },
  ): Observable<any> {
    if (acceptLanguage === null || acceptLanguage === undefined) {
      throw new Error('Required parameter acceptLanguage was null or undefined when calling getLanguages.');
    }
    if (acceptCompany === null || acceptCompany === undefined) {
      throw new Error('Required parameter acceptCompany was null or undefined when calling getLanguages.');
    }

    let headers = this.defaultHeaders;
    if (acceptLanguage !== undefined && acceptLanguage !== null) {
      headers = headers.set('Accept-Language', String(acceptLanguage));
    }
    if (acceptCompany !== undefined && acceptCompany !== null) {
      headers = headers.set('Accept-Company', String(acceptCompany));
    }

    let httpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
    if (httpHeaderAcceptSelected === undefined) {
      // to determine the Accept header
      const httpHeaderAccepts: string[] = ['application/json'];
      httpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
    }
    if (httpHeaderAcceptSelected !== undefined) {
      headers = headers.set('Accept', httpHeaderAcceptSelected);
    }

    let responseType: 'text' | 'json' = 'json';
    if (httpHeaderAcceptSelected && httpHeaderAcceptSelected.startsWith('text')) {
      responseType = 'text';
    }

    return this.httpClient.get<any>(`${this.configuration.basePath}/quotes/v1/languages`, {
      responseType: <any>responseType,
      withCredentials: this.configuration.withCredentials,
      headers: headers,
      observe: observe,
      reportProgress: reportProgress,
    });
  }

  /**
   * @param acceptLanguage Language code to inform the display language for the results
   * @param acceptCompany Company code for the source of data
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
  public getNationalities(
    acceptLanguage: string,
    acceptCompany: 'ALP' | 'KIL' | 'ESL' | 'KSG',
    observe?: 'body',
    reportProgress?: boolean,
    options?: { httpHeaderAccept?: 'application/json' },
  ): Observable<any>;
  public getNationalities(
    acceptLanguage: string,
    acceptCompany: 'ALP' | 'KIL' | 'ESL' | 'KSG',
    observe?: 'response',
    reportProgress?: boolean,
    options?: { httpHeaderAccept?: 'application/json' },
  ): Observable<HttpResponse<any>>;
  public getNationalities(
    acceptLanguage: string,
    acceptCompany: 'ALP' | 'KIL' | 'ESL' | 'KSG',
    observe?: 'events',
    reportProgress?: boolean,
    options?: { httpHeaderAccept?: 'application/json' },
  ): Observable<HttpEvent<any>>;
  public getNationalities(
    acceptLanguage: string,
    acceptCompany: 'ALP' | 'KIL' | 'ESL' | 'KSG',
    observe: any = 'body',
    reportProgress: boolean = false,
    options?: { httpHeaderAccept?: 'application/json' },
  ): Observable<any> {
    if (acceptLanguage === null || acceptLanguage === undefined) {
      throw new Error('Required parameter acceptLanguage was null or undefined when calling getNationalities.');
    }
    if (acceptCompany === null || acceptCompany === undefined) {
      throw new Error('Required parameter acceptCompany was null or undefined when calling getNationalities.');
    }

    let headers = this.defaultHeaders;
    if (acceptLanguage !== undefined && acceptLanguage !== null) {
      headers = headers.set('Accept-Language', String(acceptLanguage));
    }
    if (acceptCompany !== undefined && acceptCompany !== null) {
      headers = headers.set('Accept-Company', String(acceptCompany));
    }

    let httpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
    if (httpHeaderAcceptSelected === undefined) {
      // to determine the Accept header
      const httpHeaderAccepts: string[] = ['application/json'];
      httpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
    }
    if (httpHeaderAcceptSelected !== undefined) {
      headers = headers.set('Accept', httpHeaderAcceptSelected);
    }

    let responseType: 'text' | 'json' = 'json';
    if (httpHeaderAcceptSelected && httpHeaderAcceptSelected.startsWith('text')) {
      responseType = 'text';
    }

    return this.httpClient.get<any>(`${this.configuration.basePath}/quotes/v1/nationalities`, {
      responseType: <any>responseType,
      withCredentials: this.configuration.withCredentials,
      headers: headers,
      observe: observe,
      reportProgress: reportProgress,
    });
  }

  /**
   * Return the whole list of packages
   * @param productPackage
   * @param quoteDate Date when the quote is calculated
   * @param residenceCountryCode Country of residence of the student. ISO 3166-1 alpha-2 (2-letter codes).
   * @param units Quantity used for price calculation: Number of weeks, extra nights, one-to-one lessons, etc
   * @param acceptLanguage Language code to inform the display language for the results
   * @param acceptCompany Company code for the source of data
   * @param dateRangeStartDate
   * @param dateRangeEndDate
   * @param schoolCode The code of the school.
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
  public getPackages(
    productPackage: ProductPackageApiDto,
    quoteDate: string,
    residenceCountryCode: string,
    units: number,
    acceptLanguage: string,
    acceptCompany: 'ALP' | 'KIL' | 'ESL' | 'KSG',
    dateRangeStartDate?: string,
    dateRangeEndDate?: string,
    schoolCode?: string,
    observe?: 'body',
    reportProgress?: boolean,
    options?: { httpHeaderAccept?: 'application/json' },
  ): Observable<Array<SchoolPackageDtoApiDto>>;
  public getPackages(
    productPackage: ProductPackageApiDto,
    quoteDate: string,
    residenceCountryCode: string,
    units: number,
    acceptLanguage: string,
    acceptCompany: 'ALP' | 'KIL' | 'ESL' | 'KSG',
    dateRangeStartDate?: string,
    dateRangeEndDate?: string,
    schoolCode?: string,
    observe?: 'response',
    reportProgress?: boolean,
    options?: { httpHeaderAccept?: 'application/json' },
  ): Observable<HttpResponse<Array<SchoolPackageDtoApiDto>>>;
  public getPackages(
    productPackage: ProductPackageApiDto,
    quoteDate: string,
    residenceCountryCode: string,
    units: number,
    acceptLanguage: string,
    acceptCompany: 'ALP' | 'KIL' | 'ESL' | 'KSG',
    dateRangeStartDate?: string,
    dateRangeEndDate?: string,
    schoolCode?: string,
    observe?: 'events',
    reportProgress?: boolean,
    options?: { httpHeaderAccept?: 'application/json' },
  ): Observable<HttpEvent<Array<SchoolPackageDtoApiDto>>>;
  public getPackages(
    productPackage: ProductPackageApiDto,
    quoteDate: string,
    residenceCountryCode: string,
    units: number,
    acceptLanguage: string,
    acceptCompany: 'ALP' | 'KIL' | 'ESL' | 'KSG',
    dateRangeStartDate?: string,
    dateRangeEndDate?: string,
    schoolCode?: string,
    observe: any = 'body',
    reportProgress: boolean = false,
    options?: { httpHeaderAccept?: 'application/json' },
  ): Observable<any> {
    if (productPackage === null || productPackage === undefined) {
      throw new Error('Required parameter productPackage was null or undefined when calling getPackages.');
    }
    if (quoteDate === null || quoteDate === undefined) {
      throw new Error('Required parameter quoteDate was null or undefined when calling getPackages.');
    }
    if (residenceCountryCode === null || residenceCountryCode === undefined) {
      throw new Error('Required parameter residenceCountryCode was null or undefined when calling getPackages.');
    }
    if (units === null || units === undefined) {
      throw new Error('Required parameter units was null or undefined when calling getPackages.');
    }
    if (acceptLanguage === null || acceptLanguage === undefined) {
      throw new Error('Required parameter acceptLanguage was null or undefined when calling getPackages.');
    }
    if (acceptCompany === null || acceptCompany === undefined) {
      throw new Error('Required parameter acceptCompany was null or undefined when calling getPackages.');
    }

    let queryParameters = new HttpParams({ encoder: this.encoder });
    if (productPackage !== undefined && productPackage !== null) {
      queryParameters = this.addToHttpParams(queryParameters, <any>productPackage, 'productPackage');
    }
    if (quoteDate !== undefined && quoteDate !== null) {
      queryParameters = this.addToHttpParams(queryParameters, <any>quoteDate, 'quoteDate');
    }
    if (residenceCountryCode !== undefined && residenceCountryCode !== null) {
      queryParameters = this.addToHttpParams(queryParameters, <any>residenceCountryCode, 'residenceCountryCode');
    }
    if (dateRangeStartDate !== undefined && dateRangeStartDate !== null) {
      queryParameters = this.addToHttpParams(queryParameters, <any>dateRangeStartDate, 'dateRange.startDate');
    }
    if (dateRangeEndDate !== undefined && dateRangeEndDate !== null) {
      queryParameters = this.addToHttpParams(queryParameters, <any>dateRangeEndDate, 'dateRange.endDate');
    }
    if (units !== undefined && units !== null) {
      queryParameters = this.addToHttpParams(queryParameters, <any>units, 'units');
    }
    if (schoolCode !== undefined && schoolCode !== null) {
      queryParameters = this.addToHttpParams(queryParameters, <any>schoolCode, 'schoolCode');
    }

    let headers = this.defaultHeaders;
    if (acceptLanguage !== undefined && acceptLanguage !== null) {
      headers = headers.set('Accept-Language', String(acceptLanguage));
    }
    if (acceptCompany !== undefined && acceptCompany !== null) {
      headers = headers.set('Accept-Company', String(acceptCompany));
    }

    let httpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
    if (httpHeaderAcceptSelected === undefined) {
      // to determine the Accept header
      const httpHeaderAccepts: string[] = ['application/json'];
      httpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
    }
    if (httpHeaderAcceptSelected !== undefined) {
      headers = headers.set('Accept', httpHeaderAcceptSelected);
    }

    let responseType: 'text' | 'json' = 'json';
    if (httpHeaderAcceptSelected && httpHeaderAcceptSelected.startsWith('text')) {
      responseType = 'text';
    }

    return this.httpClient.get<Array<SchoolPackageDtoApiDto>>(`${this.configuration.basePath}/quotes/v1/packages`, {
      params: queryParameters,
      responseType: <any>responseType,
      withCredentials: this.configuration.withCredentials,
      headers: headers,
      observe: observe,
      reportProgress: reportProgress,
    });
  }

  /**
   * Return the list of course puposes
   * @param acceptLanguage Language code to inform the display language for the results
   * @param acceptCompany Company code for the source of data
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
  public getProgramTypes(
    acceptLanguage: string,
    acceptCompany: 'ALP' | 'KIL' | 'ESL' | 'KSG',
    observe?: 'body',
    reportProgress?: boolean,
    options?: { httpHeaderAccept?: 'application/json' },
  ): Observable<Array<QuoteCoursePurposeApiDto>>;
  public getProgramTypes(
    acceptLanguage: string,
    acceptCompany: 'ALP' | 'KIL' | 'ESL' | 'KSG',
    observe?: 'response',
    reportProgress?: boolean,
    options?: { httpHeaderAccept?: 'application/json' },
  ): Observable<HttpResponse<Array<QuoteCoursePurposeApiDto>>>;
  public getProgramTypes(
    acceptLanguage: string,
    acceptCompany: 'ALP' | 'KIL' | 'ESL' | 'KSG',
    observe?: 'events',
    reportProgress?: boolean,
    options?: { httpHeaderAccept?: 'application/json' },
  ): Observable<HttpEvent<Array<QuoteCoursePurposeApiDto>>>;
  public getProgramTypes(
    acceptLanguage: string,
    acceptCompany: 'ALP' | 'KIL' | 'ESL' | 'KSG',
    observe: any = 'body',
    reportProgress: boolean = false,
    options?: { httpHeaderAccept?: 'application/json' },
  ): Observable<any> {
    if (acceptLanguage === null || acceptLanguage === undefined) {
      throw new Error('Required parameter acceptLanguage was null or undefined when calling getProgramTypes.');
    }
    if (acceptCompany === null || acceptCompany === undefined) {
      throw new Error('Required parameter acceptCompany was null or undefined when calling getProgramTypes.');
    }

    let headers = this.defaultHeaders;
    if (acceptLanguage !== undefined && acceptLanguage !== null) {
      headers = headers.set('Accept-Language', String(acceptLanguage));
    }
    if (acceptCompany !== undefined && acceptCompany !== null) {
      headers = headers.set('Accept-Company', String(acceptCompany));
    }

    let httpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
    if (httpHeaderAcceptSelected === undefined) {
      // to determine the Accept header
      const httpHeaderAccepts: string[] = ['application/json'];
      httpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
    }
    if (httpHeaderAcceptSelected !== undefined) {
      headers = headers.set('Accept', httpHeaderAcceptSelected);
    }

    let responseType: 'text' | 'json' = 'json';
    if (httpHeaderAcceptSelected && httpHeaderAcceptSelected.startsWith('text')) {
      responseType = 'text';
    }

    return this.httpClient.get<Array<QuoteCoursePurposeApiDto>>(`${this.configuration.basePath}/quotes/v1/program-types`, {
      responseType: <any>responseType,
      withCredentials: this.configuration.withCredentials,
      headers: headers,
      observe: observe,
      reportProgress: reportProgress,
    });
  }

  /**
   * Return the list of residence countries
   * @param acceptLanguage Language code to inform the display language for the results
   * @param acceptCompany Company code for the source of data
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
  public getResidenceCountries(
    acceptLanguage: string,
    acceptCompany: 'ALP' | 'KIL' | 'ESL' | 'KSG',
    observe?: 'body',
    reportProgress?: boolean,
    options?: { httpHeaderAccept?: 'application/json' },
  ): Observable<Array<ResidenceCountryApiDto>>;
  public getResidenceCountries(
    acceptLanguage: string,
    acceptCompany: 'ALP' | 'KIL' | 'ESL' | 'KSG',
    observe?: 'response',
    reportProgress?: boolean,
    options?: { httpHeaderAccept?: 'application/json' },
  ): Observable<HttpResponse<Array<ResidenceCountryApiDto>>>;
  public getResidenceCountries(
    acceptLanguage: string,
    acceptCompany: 'ALP' | 'KIL' | 'ESL' | 'KSG',
    observe?: 'events',
    reportProgress?: boolean,
    options?: { httpHeaderAccept?: 'application/json' },
  ): Observable<HttpEvent<Array<ResidenceCountryApiDto>>>;
  public getResidenceCountries(
    acceptLanguage: string,
    acceptCompany: 'ALP' | 'KIL' | 'ESL' | 'KSG',
    observe: any = 'body',
    reportProgress: boolean = false,
    options?: { httpHeaderAccept?: 'application/json' },
  ): Observable<any> {
    if (acceptLanguage === null || acceptLanguage === undefined) {
      throw new Error('Required parameter acceptLanguage was null or undefined when calling getResidenceCountries.');
    }
    if (acceptCompany === null || acceptCompany === undefined) {
      throw new Error('Required parameter acceptCompany was null or undefined when calling getResidenceCountries.');
    }

    let headers = this.defaultHeaders;
    if (acceptLanguage !== undefined && acceptLanguage !== null) {
      headers = headers.set('Accept-Language', String(acceptLanguage));
    }
    if (acceptCompany !== undefined && acceptCompany !== null) {
      headers = headers.set('Accept-Company', String(acceptCompany));
    }

    let httpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
    if (httpHeaderAcceptSelected === undefined) {
      // to determine the Accept header
      const httpHeaderAccepts: string[] = ['application/json'];
      httpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
    }
    if (httpHeaderAcceptSelected !== undefined) {
      headers = headers.set('Accept', httpHeaderAcceptSelected);
    }

    let responseType: 'text' | 'json' = 'json';
    if (httpHeaderAcceptSelected && httpHeaderAcceptSelected.startsWith('text')) {
      responseType = 'text';
    }

    return this.httpClient.get<Array<ResidenceCountryApiDto>>(`${this.configuration.basePath}/quotes/v1/residence-countries`, {
      responseType: <any>responseType,
      withCredentials: this.configuration.withCredentials,
      headers: headers,
      observe: observe,
      reportProgress: reportProgress,
    });
  }

  /**
   * Return the list of schools
   * @param acceptLanguage Language code to inform the display language for the results
   * @param acceptCompany Company code for the source of data
   * @param productPackage
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
  public getSchools(
    acceptLanguage: string,
    acceptCompany: 'ALP' | 'KIL' | 'ESL' | 'KSG',
    productPackage?: ProductPackageApiDto,
    observe?: 'body',
    reportProgress?: boolean,
    options?: { httpHeaderAccept?: 'application/json' },
  ): Observable<Array<QuoteSchoolApiDto>>;
  public getSchools(
    acceptLanguage: string,
    acceptCompany: 'ALP' | 'KIL' | 'ESL' | 'KSG',
    productPackage?: ProductPackageApiDto,
    observe?: 'response',
    reportProgress?: boolean,
    options?: { httpHeaderAccept?: 'application/json' },
  ): Observable<HttpResponse<Array<QuoteSchoolApiDto>>>;
  public getSchools(
    acceptLanguage: string,
    acceptCompany: 'ALP' | 'KIL' | 'ESL' | 'KSG',
    productPackage?: ProductPackageApiDto,
    observe?: 'events',
    reportProgress?: boolean,
    options?: { httpHeaderAccept?: 'application/json' },
  ): Observable<HttpEvent<Array<QuoteSchoolApiDto>>>;
  public getSchools(
    acceptLanguage: string,
    acceptCompany: 'ALP' | 'KIL' | 'ESL' | 'KSG',
    productPackage?: ProductPackageApiDto,
    observe: any = 'body',
    reportProgress: boolean = false,
    options?: { httpHeaderAccept?: 'application/json' },
  ): Observable<any> {
    if (acceptLanguage === null || acceptLanguage === undefined) {
      throw new Error('Required parameter acceptLanguage was null or undefined when calling getSchools.');
    }
    if (acceptCompany === null || acceptCompany === undefined) {
      throw new Error('Required parameter acceptCompany was null or undefined when calling getSchools.');
    }

    let queryParameters = new HttpParams({ encoder: this.encoder });
    if (productPackage !== undefined && productPackage !== null) {
      queryParameters = this.addToHttpParams(queryParameters, <any>productPackage, 'productPackage');
    }

    let headers = this.defaultHeaders;
    if (acceptLanguage !== undefined && acceptLanguage !== null) {
      headers = headers.set('Accept-Language', String(acceptLanguage));
    }
    if (acceptCompany !== undefined && acceptCompany !== null) {
      headers = headers.set('Accept-Company', String(acceptCompany));
    }

    let httpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
    if (httpHeaderAcceptSelected === undefined) {
      // to determine the Accept header
      const httpHeaderAccepts: string[] = ['application/json'];
      httpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
    }
    if (httpHeaderAcceptSelected !== undefined) {
      headers = headers.set('Accept', httpHeaderAcceptSelected);
    }

    let responseType: 'text' | 'json' = 'json';
    if (httpHeaderAcceptSelected && httpHeaderAcceptSelected.startsWith('text')) {
      responseType = 'text';
    }

    return this.httpClient.get<Array<QuoteSchoolApiDto>>(`${this.configuration.basePath}/quotes/v1/schools`, {
      params: queryParameters,
      responseType: <any>responseType,
      withCredentials: this.configuration.withCredentials,
      headers: headers,
      observe: observe,
      reportProgress: reportProgress,
    });
  }
}
