import { Injectable } from '@angular/core';
import { SupplementsControllerApiService } from '@klg/shared/api/services';
import { BaseService, CachedRequestResponse, Memoize } from '@klg/shared/utils-http';
import { Observable, of } from 'rxjs';
import { Supplement } from '../models';

@Injectable({
  providedIn: 'root',
})
export class SupplementService extends BaseService<Supplement> {
  private responseCache = new CachedRequestResponse<Supplement[]>(() => of([]), this.cacheSettings.get);

  constructor(private supplementsControllerApiService: SupplementsControllerApiService) {
    super();
  }

  @Memoize((schoolId: string, courseIds: string[], accommodationIds?: string[], tripStartDate?: string, tripEndDate?: string) =>
    [schoolId, courseIds, accommodationIds, tripStartDate, tripEndDate].join('|'),
  )
  public getByCoursesAndAccommodations(
    schoolId: string,
    courseIds: string[],
    accommodationIds?: string[],
    tripStartDate?: string,
    tripEndDate?: string,
  ): Observable<Supplement[]> {
    return this.supplementsControllerApiService.findSupplementsByCoursesAndAccommodations(
      +schoolId,
      courseIds?.map((id) => +id) ?? [],
      this.locale,
      this.companyHeader,
      accommodationIds ? accommodationIds.map((id) => +id) : null,
      tripStartDate,
      tripEndDate,
    );
  }

  protected getResponseCache$(): Observable<CachedRequestResponse<Supplement[]>> {
    return of(this.responseCache);
  }
}
