import { Component, ElementRef, inject, Input, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { FormType, StepDefinition } from '@klg/quote-tool/shared/types';
import { Observable, Subject, takeUntil } from 'rxjs';
import { getEnvironment } from '@klg/shared/tokens';
import { StepService } from '@klg/quote-tool/shared/services';
import { GoogleAnalyticsEvents } from '@klg/shared/google-analytics';

@Component({
  selector: 'kng-step-wrapper',
  templateUrl: './step-wrapper.component.html',
  styleUrls: ['./step-wrapper.component.scss'],
})
export class StepWrapperComponent implements OnInit, OnDestroy {
  @Input() stepDefinition: StepDefinition | undefined;
  @Input() disabledNextButton = false;

  @ViewChild('stepWrapper') stepWrapperRef: ElementRef<HTMLElement>;

  formType: FormType | undefined = undefined;
  isJuniors: Observable<boolean> | undefined = undefined;

  isStepValid = false;
  isRtlEnabled = false;

  googleAnalyticsBackEvent = GoogleAnalyticsEvents.QUOTE_BACK;
  googleAnalyticsNextEvent = GoogleAnalyticsEvents.QUOTE_NEXT;

  private readonly destroy$ = new Subject<void>();

  private readonly environment = getEnvironment();
  private readonly stepService = inject(StepService);

  ngOnInit(): void {
    this.formType = this.stepService.getFormType();
    this.isJuniors = this.stepService.isJuniors$();
    this.isRtlEnabled = this.environment.RTL_ENABLED;

    this.stepService
      .getCurrentStepValid()
      .pipe(takeUntil(this.destroy$))
      .subscribe((validStep) => {
        this.isStepValid = validStep;
      });
  }

  ngOnDestroy(): void {
    this.destroy$.next();
  }

  navigateToPreviousStep() {
    setTimeout(() => this.stepService.navigateToPreviousStep());
  }

  navigateToNextStep() {
    setTimeout(() => this.stepService.navigateToNextStep());
  }
}
