import { inject, Injectable } from '@angular/core';
import { AccommodationsControllerApiService } from '@klg/shared/api';
import { BathroomType, MealOption, ProductAccommodation, RoomType } from '@klg/shared/data-access/products';
import { getLocale } from '@klg/shared/i18n';
import { getCompanyHeader } from '@klg/shared/tokens';
import { Memoize } from '@klg/shared/utils-http';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class AccommodationService {
  private readonly locale = getLocale();
  private readonly companyHeader = getCompanyHeader();

  private readonly accommodationsApiService = inject(AccommodationsControllerApiService);

  public roomTypeLabels: Record<RoomType, string> = {
    SINGLE: $localize`Single`,
    DOUBLE: $localize`Double`,
    STUDIO: $localize`Studio`,
    STANDARD_SINGLE: $localize`Standard Single`,
    SILVER_SINGLE: $localize`Silver Single`,
    PREMIUM_SINGLE: $localize`Premium Single`,
    GOLD_SINGLE: $localize`Gold Single`,
    ECONOMY_SINGLE: $localize`Economy Single`,
    BRONZE_SINGLE: $localize`Bronze Single`,
    STUDIO_DOUBLE: $localize`Studio Double`,
  };
  public bathRoomTypeLabels: Record<BathroomType, string> = {
    PRIVATE: $localize`Private`,
    SHARED: $localize`Shared`,
  };
  public mealOptionLabels: Record<MealOption, string> = {
    NO_MEALS: $localize`No Meals`,
    BED_BREAKFAST: $localize`Breakfast`,
    HALF_BOARD: $localize`Half Board`,
    MEAL_PLAN: $localize`Meal Plan`,
  };

  @Memoize((schoolId: string, courseId: string, tripDate?: string) => [schoolId, courseId, tripDate].join('|'))
  public getByCourse(schoolId: string, courseId: string, tripDate?: string): Observable<ProductAccommodation[]> {
    return this.accommodationsApiService.findAccommodationsByCourse(+schoolId, +courseId, this.locale, this.companyHeader, tripDate);
  }
}
