import { Component, Input, OnInit } from '@angular/core';
import { QuoteLeadUserTitleEnum, TellUsAboutYouSectionFormPayload } from '@klg/quote-tool/shared/types/quote';
import { FormFieldObject, FormType } from '@klg/quote-tool/shared/types';
import { CodeAndName } from '@klg/shared/types';
import { getDateStringFromDate, getToday } from '@klg/shared/utils';
import { emailValidator } from '@klg/core/validators/email.validator';
import { phoneValidator } from '@klg/core/validators/phone.validator';
import { StepService } from '@klg/quote-tool/shared/services/step-service';
import { ConfigurationService } from '@klg/shared/app-config';
import { FormSection } from '@klg/quote-tool/shared/ui/directives';

@Component({
  selector: 'kng-tell-us-about-you-section',
  templateUrl: './tell-us-about-you.component.html',
  styleUrls: ['./tell-us-about-you.component.scss'],
})
export class TellUsAboutYouSectionComponent extends FormSection<TellUsAboutYouSectionFormPayload> implements OnInit {
  @Input() formType: FormType;

  public calendarMaxDate = getToday();

  public titleOptions: CodeAndName[] = [
    { code: QuoteLeadUserTitleEnum.MR, name: $localize`Mr.` },
    { code: QuoteLeadUserTitleEnum.MS, name: $localize`Ms.` },
  ];

  public identityDocumentFieldIsEnabled = false;

  constructor(stepService: StepService) {
    super(stepService);
  }

  ngOnInit(): void {
    this.identityDocumentFieldIsEnabled = ConfigurationService.getValue('ENROLLMENT_IDENTITY_DOCUMENT_ENABLED') as boolean;
    super.ngOnInit();
  }

  public onSelectChanged(selectedItem: string, key: keyof TellUsAboutYouSectionFormPayload) {
    this.formData[key].value = selectedItem;
    this.formData[key].validate();
    this.storeStudentDetails();
  }

  public onSelectBirthDate(date: Date): void {
    this.formData.dateOfBirth.value = getDateStringFromDate(date);
    this.formData.dateOfBirth.validate();
    this.storeStudentDetails();
  }

  public onValueChanged(key: keyof TellUsAboutYouSectionFormPayload): void {
    this.formData[key].validate();
    this.storeStudentDetails();
  }

  public isEnrolmentForm(): boolean {
    return this.formType === FormType.ENROLLMENT;
  }

  public isPriceRequestForm(): boolean {
    return this.formType === FormType.PRICE_REQUEST;
  }

  protected getFormFields(): { [K in keyof Partial<TellUsAboutYouSectionFormPayload>]: FormFieldObject<TellUsAboutYouSectionFormPayload[K]> } {
    const commonFields: { [K in keyof Partial<TellUsAboutYouSectionFormPayload>]: FormFieldObject<TellUsAboutYouSectionFormPayload[K]> } = {
      title: {
        value: null,
        validator: (title) => this.titleOptions.findIndex(({ code }) => code === title) >= 0,
        errorMessage: $localize`Please select a Title`,
        required: true,
      },
      firstName: {
        value: '',
        validator: (firstName) => firstName && firstName.length <= 80,
        errorMessage: $localize`Please enter a valid First Name`,
        required: true,
      },
      lastName: {
        value: '',
        validator: (lastName) => lastName && lastName.length <= 40,
        errorMessage: $localize`Please enter a valid Last Name`,
        required: true,
      },
      email: {
        value: '',
        validator: (email) => emailValidator(email),
        errorMessage: $localize`Please enter a valid Email`,
        required: true,
      },
      phoneNumber: {
        value: '',
        validator: (phone) => phoneValidator(phone),
        errorMessage: $localize`Please enter a valid Phone`,
        required: true,
      },
      postcode: {
        value: '',
        validator: (postcode) => postcode && postcode.length <= 20,
        errorMessage: $localize`Please enter a valid Postcode`,
        required: true,
      },
      city: {
        value: '',
        validator: (city) => city && city.length <= 40,
        errorMessage: $localize`Please enter a valid City`,
        required: true,
      },
      countryOfResidence: {
        value: this.getCountryOfResidence(),
        validator: Boolean,
        errorMessage: $localize`Please select a Country`,
        required: true,
      },
    };
    const enrollmentFormsFields = {
      identityDocument: {
        value: '',
        validator: (identityDocument: string) => identityDocument && identityDocument.length > 0,
        errorMessage: $localize`Please enter a valid document`,
        required: this.identityDocumentFieldIsEnabled,
      },
      address: {
        value: '',
        validator: (address: string) => address && address.length < 80,
        errorMessage: $localize`Please enter a valid address`,
        required: true,
      },
      placeOfBirth: {
        value: '',
        validator: (birthPlace: string) => birthPlace && birthPlace.length < 80,
        errorMessage: $localize`Please enter a valid place of birth`,
        required: true,
      },
      motherTongue: {
        value: '',
        validator: Boolean,
        errorMessage: $localize`Please enter a valid mother tongue`,
        required: true,
      },
    };
    const dateOfBirthFormField = {
      dateOfBirth: {
        value: null,
        validator: (birthDate: string) => new Date(birthDate).getTime() < this.calendarMaxDate.getTime(),
        errorMessage: $localize`Please enter a valid birth date`,
        required: true,
      },
    };
    const nationalityFormField = {
      nationality: {
        value: '',
        validator: Boolean,
        errorMessage: $localize`Please enter a valid nationality`,
        required: true,
      },
    };
    return {
      ...commonFields,
      ...(this.isEnrolmentForm() || this.isPriceRequestForm() ? dateOfBirthFormField : {}),
      ...(this.isEnrolmentForm() || this.isPriceRequestForm() ? nationalityFormField : {}),
      ...(this.isEnrolmentForm() ? enrollmentFormsFields : {}),
    };
  }

  protected getFieldsValues(fieldKeys: Array<keyof TellUsAboutYouSectionFormPayload>): Partial<TellUsAboutYouSectionFormPayload> {
    const values: Partial<TellUsAboutYouSectionFormPayload> = {};

    fieldKeys.forEach((key: keyof TellUsAboutYouSectionFormPayload) => {
      if (this.getFieldValueIfValid(key) !== undefined || this.getFormFields()[key].required) {
        values[key] = this.getFieldValueIfValid(key);
      }
    });

    return values;
  }

  private getCountryOfResidence(): TellUsAboutYouSectionFormPayload['countryOfResidence'] {
    return this.stepService.getResidenceData()?.countryOfResidence ?? '';
  }
}
