import { Component, OnDestroy, OnInit } from '@angular/core';
import { QuoteFormLinkUrls, QuoteInput, TermsAndPromotionsSectionPayload } from '@klg/quote-tool/shared/types/quote';
import { FormType } from '@klg/quote-tool/shared/types';
import { Subscription } from 'rxjs';
import { take } from 'rxjs/operators';
import { StepService } from '@klg/quote-tool/shared/services/step-service';
import { QuoteFormLinkUrlsService } from '@klg/quote-tool/shared/data-access/quote';
import { CheckboxModule } from 'primeng/checkbox';
import { FormsModule } from '@angular/forms';
import { NgIf } from '@angular/common';

@Component({
  standalone: true,
  selector: 'kng-terms-and-promotions-form-section',
  templateUrl: './terms-and-promotions.component.html',
  styleUrls: ['./terms-and-promotions.component.scss'],
  imports: [CheckboxModule, FormsModule, NgIf],
})
export class TermsAndPromotionsFormSectionComponent implements OnInit, OnDestroy {
  public isTermsTextHovered = false;
  public termsAndConditionsLink: string;
  public moreInfoLink: string;

  private subscription = new Subscription();

  public sectionModel: TermsAndPromotionsSectionPayload = {} as TermsAndPromotionsSectionPayload;

  public readonly isEnrollmentFormType = () => this.stepService.getFormType() === FormType.ENROLLMENT;

  constructor(private stepService: StepService, private linkUrlsService: QuoteFormLinkUrlsService) {}

  private initLinks() {
    this.subscription.add(
      this.linkUrlsService.getLinkUrls$().subscribe(({ moreInfoLinkUrl, termsAndConditionsLinkUrl }: QuoteFormLinkUrls) => {
        this.moreInfoLink = moreInfoLinkUrl;
        this.termsAndConditionsLink = termsAndConditionsLinkUrl;
      }),
    );
  }

  private loadStoredData() {
    this.subscription.add(
      this.stepService.quoteRequest$.pipe(take(1)).subscribe(({ agreeToPromotions, agreeToTerms }: QuoteInput) => {
        Object.assign(this.sectionModel, {
          agreeToPromotions: agreeToPromotions ?? false,
          agreeToTerms: agreeToTerms ?? false,
        });
      }),
    );
  }

  private storeSectionData() {
    this.stepService.updatePartialRequest<TermsAndPromotionsSectionPayload>(this.sectionModel);
    this.stepService.setPartialStudentDetails({
      consentResellers: false,
      consentMarketing: this.sectionModel.agreeToPromotions || false,
    });
  }

  public ngOnInit() {
    this.loadStoredData();
    this.initLinks();
  }

  public ngOnDestroy() {
    this.subscription.unsubscribe();
  }

  public updateAgreeToPromotions() {
    this.storeSectionData();
  }

  public updateAgreeToTerms() {
    this.storeSectionData();
  }
}
