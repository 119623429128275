import { deepClone } from '@klg/shared/utils';
import { BehaviorSubject, Observable, of, Subscription } from 'rxjs';
import { filter, map, switchMap } from 'rxjs/operators';
import { getLocale } from '@klg/shared/i18n';
import { getCompany, getCompanyHeader } from '@klg/shared/tokens';
import { CacheSettings } from '../types/cached-request-response.type';
import { CachedRequestResponse, DEFAULT_CACHE_TIME } from '../functions/cached-request-response.function';
import { CrudApi } from '../interfaces/crud-api.interface';
import { ItemSelector, SelectedItem } from '../interfaces/selected-item-service.interface';
import { CodeAndName } from '@klg/shared/types';

export abstract class BaseService<T extends CodeAndName> implements CrudApi<T>, ItemSelector<T> {
  protected internalCache$: Observable<T[]>;
  protected selectedItemsMap = new Map<symbol, string>();
  protected cacheSettings: CacheSettings = {
    get: DEFAULT_CACHE_TIME,
  };
  protected readonly locale = getLocale();
  protected readonly company = getCompany();
  protected readonly companyHeader = getCompanyHeader();

  protected subscription = new Subscription();

  protected selectedItem$ = new BehaviorSubject<SelectedItem<T>>(new SelectedItem<T>(null));

  protected constructor() {
    this.subscription.add(this.initSelectedWatcher());
  }

  public getAll(): Observable<T[]> {
    if (!this.internalCache$) {
      this.internalCache$ = this.getResponseCache$().pipe(switchMap((cache) => cache.get()));
    }
    return this.internalCache$;
  }

  getByCode(code: string): Observable<T> {
    return code ? this.getAll().pipe(map((itemList) => itemList.find((item) => item.code === code))) : of(null);
  }

  public getByCodes(codes: string[]): Observable<T[]> {
    return this.getAll().pipe(map((itemList) => itemList.filter((item: T) => codes.includes(item.code))));
  }

  public create(model: T): Observable<T> {
    throw Error('create method not implemented');
  }

  public delete(model: T): Observable<T> {
    throw Error('delete method not implemented');
  }

  public get(code: string): Observable<T> {
    return code ? this.getAll().pipe(map((itemList) => itemList.find((item) => item.code === code))) : of(null);
  }

  public update(model: T): Observable<T> {
    throw Error('update method not implemented');
  }

  public createOrUpdate(model: T): Observable<T> {
    return model.code !== '' ? this.update(model) : this.create(model);
  }

  public refreshCache(): Observable<void> {
    return this.getResponseCache$().pipe(switchMap((cache) => cache.refresh()));
  }

  public setSelectedInContext(model: T, context: symbol = null): void {
    this.selectedItem$.next(new SelectedItem(deepClone(model), context));
  }

  public getSelectedInContext(context?: symbol): Observable<T> {
    return this.selectedItem$.pipe(
      filter((selectedItem) => !context || selectedItem.context === context),
      map((selectedItem) => selectedItem.item),
    );
  }

  protected abstract getResponseCache$(): Observable<CachedRequestResponse<T[]>>;

  protected initSelectedWatcher(): Subscription {
    return this.selectedItem$.subscribe((selectedItem) => {
      if (selectedItem.context !== null && selectedItem.item !== null) {
        // happy path, everything is set
        this.selectedItemsMap.set(selectedItem.context, selectedItem.item.code);
        return;
      }

      if (selectedItem.context !== null) {
        // item has been deleted from a context = removing item key
        this.selectedItemsMap.delete(selectedItem.context);
        return;
      }

      // selected item modified for all contexts
      this.selectedItemsMap.forEach((val, key) => {
        if (selectedItem.item === null) {
          this.selectedItemsMap.delete(key);
        } else {
          this.selectedItemsMap.set(key, selectedItem.item.code);
        }
      });
    });
  }
}
