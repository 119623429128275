import { Directive, Input, OnInit } from '@angular/core';
import { FormData } from '@klg/core/forms/form-data.model';
import { SchoolTypes, SchoolTypesEnum } from '@klg/shared/data-access/types';
import { StepService } from '@klg/quote-tool/shared/services/step-service';
import { FormFieldObject, StudentDetails } from '@klg/quote-tool/shared/types';

@Directive()
export abstract class FormSection<T> implements OnInit {
  @Input() sectionIndex: number;
  @Input() schoolType: SchoolTypes;

  public formData?: { [K in keyof T]: FormData<T[K]> } = {} as { [K in keyof T]: FormData<T[K]> };
  defaultErrorMessage = $localize`This question is required`;

  constructor(protected stepService: StepService) {}

  ngOnInit(): void {
    this.formData = this.getFormData();
  }

  protected getDataFromFields() {
    return <T>Object.fromEntries(Object.entries(this.formData).map(([key, formField]: [string, FormData<any>]) => [key, formField.value]));
  }

  protected getFormData() {
    return Object.entries(this.getFormFields()).reduce(
      (acc, [key, field]: [string, FormFieldObject<typeof field>]) => ({
        ...acc,
        [key]: new FormData(
          field.value,
          field.validator,
          field.required,
          field.errorMessage,
          this.formData[key]?.isDirty ?? false,
          this.formData[key]?.isValid,
          field.group,
          field.getRequiredValue,
        ),
      }),
      {},
    ) as { [K in keyof T]: FormData<T[K]> };
  }

  public isAdultSchool(): boolean {
    return this.schoolType === SchoolTypesEnum.ADULT;
  }

  public onValueChanged(key: keyof T, partialUpdate = true): void {
    this.formData[key].validate();
    this.storeStudentDetails(!partialUpdate);
  }

  protected getFieldValueIfValid(fieldKey: keyof T): any {
    return this.formData[fieldKey]?.isValid ? this.formData[fieldKey].value : undefined;
  }

  protected getFieldsValues(fieldKeys: Array<keyof T>): Partial<T> {
    const values: Partial<T> = {};

    fieldKeys.forEach((key: keyof T) => {
      if (this.getFieldValueIfValid(key) !== undefined) {
        values[key] = this.getFieldValueIfValid(key);
      }
    });

    return values;
  }

  protected getFormFields(): { [K in keyof Partial<T>]: FormFieldObject<T[K]> } {
    return {} as undefined;
  }

  protected getFormValues(formObj = null): T {
    const form = formObj ?? this.formData;
    const allKeys = Object.keys(form) as Array<keyof T>;
    return this.getFieldsValues(allKeys) as T;
  }

  protected getGroupValues(groupName: string): Partial<T> {
    const groupKeys = Object.keys(this.formData).filter((key: string) => this.formData[key]?.group === groupName) as Array<keyof T>;
    return this.getFieldsValues(groupKeys);
  }

  protected getStudentDetails(): Partial<StudentDetails> {
    return this.stepService.getStudentDetails();
  }

  protected storeStudentDetails(object = null, fullObject = false): void {
    const formValues = object ?? this.getFormValues();
    fullObject ? this.stepService.setStudentDetails(formValues as StudentDetails) : this.stepService.setPartialStudentDetails(formValues);
  }
}
