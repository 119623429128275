import { inject, Injectable } from '@angular/core';
import { combineLatest, mergeMap, of } from 'rxjs';
import {
  ExternalQuoteLeadUserData,
  FormType,
  QuoteInput,
  QuoteInputForm,
  QuoteLeadOrigin,
  QuoteLeadOriginEnum,
  QuoteRequest,
} from '@klg/quote-tool/shared/types';
import { CodeAndName } from '@klg/shared/types';
import { switchMap } from 'rxjs/operators';
import { completeStudentDetailsForLeadEndpoint, QuoteService } from '@klg/quote-tool/shared/data-access/quote';
import { AnalyticsService, HostDataReaderService, StepService } from '@klg/quote-tool/shared/services';
import { QuoteInputFormMapper } from './quote-input-form.mapper';

@Injectable({
  providedIn: 'root',
})
export class FormLeadsService {
  private readonly hostDataReader = inject(HostDataReaderService);
  private readonly analyticsService = inject(AnalyticsService);
  private readonly quoteService = inject(QuoteService);
  private readonly stepService = inject(StepService);
  private readonly quoteInputFormMapper = inject(QuoteInputFormMapper);

  createLead(quoteId: string) {
    return this.stepService.getActiveQuoteInput().pipe(
      mergeMap((quoteInput: QuoteInput) => combineLatest([of(quoteInput), this.stepService.getLanguageCodeAndName$(quoteInput)])),
      mergeMap(([quoteInput, quoteLanguage]: [QuoteInput, CodeAndName]) => of(this.mapQuoteInputToLeadQuoteRequest(quoteInput, quoteLanguage.name))),
      switchMap((quoteRequest: QuoteRequest) => {
        const leadUserData = completeStudentDetailsForLeadEndpoint(
            this.stepService._studentDetails$.getValue(),
            this.stepService.getLanguage(),
            undefined,
            this.hostDataReader.getDrupalRoiData() as ExternalQuoteLeadUserData,
            quoteId,
          ),
          formOrigin: QuoteLeadOrigin = this.mapFormTypeToMapOrigin();
        const experimentId = this.analyticsService.getLeadsExperimentId();

        const leadData = {
          quoteRequest: [quoteRequest],
          leadUserData: {
            ...leadUserData,
            consentMarketing: !leadUserData.consentMarketing,
          },
          formOrigin,
          analyticsData: { experimentId },
        };

        return this.quoteService.createLeadV2(leadData);
      }),
    );
  }

  private mapFormTypeToMapOrigin(): QuoteLeadOrigin {
    const mapper: Map<FormType, QuoteLeadOrigin> = new Map([
      [FormType.ENROLLMENT, QuoteLeadOriginEnum.ENROLMENT],
      [FormType.FREE_QUOTE, QuoteLeadOriginEnum.QUICK_QUOTE],
      [FormType.PRICE_REQUEST, QuoteLeadOriginEnum.PRICE_REQUEST],
    ]);
    return mapper.get(this.stepService.getFormType());
  }

  private mapQuoteInputToLeadQuoteRequest(quoteInput: QuoteInput, courseLanguageName: string) {
    const quoteInputDto = this.quoteInputFormMapper.mapQuoteInputFormToQuoteRequestDto(<QuoteInputForm>quoteInput);
    Object.assign(quoteInputDto, {
      courseLanguage: courseLanguageName,
      exchangeCurrencyCode: quoteInput.exchangeCurrencyCode,
    });
    return quoteInputDto;
  }
}
