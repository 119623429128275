/**
 * KLG Quotes API
 * This is KLG API to perform quote calculations and retrieve auxiliary pricing information.
 *
 * The version of the OpenAPI document: dev
 * Contact: xxx@kaplan.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { PriceApiDto } from './price.model';
import { ScoreFeedbackApiDto } from './score-feedback.model';
import { AmountApiDto } from './amount.model';
import { SampleWeekApiDto } from './sample-week.model';
import { SchoolPriceIncludedInfoApiDto } from './school-price-included-info.model';
import { LocationPointApiDto } from './location-point.model';
import { AccommodationApiDto } from './accommodation.model';
import { CourseApiDto } from './course.model';
import { LanguageApiDto } from './language.model';
import { CourseLevelApiDto } from './course-level.model';
import { CityApiDto } from './city.model';
import { DatePeriodApiDto } from './date-period.model';
import { CountryApiDto } from './country.model';

export interface SchoolApiDto {
  price?: PriceApiDto;
  onlyJunior?: boolean;
  code?: string;
  name?: string;
  types?: Array<SchoolApiDto.TypesApiDtoEnum>;
  closedDates?: Array<string>;
  exceptionDates?: Array<string>;
  courseLanguages?: Array<LanguageApiDto>;
  availableLevels?: Array<CourseLevelApiDto>;
  courseDateDescription?: string;
  description?: string;
  descriptionShort?: string;
  ageMin?: number;
  ageMax?: number;
  outDay?: Array<SchoolApiDto.OutDayApiDtoEnum>;
  thumbnailSmall?: string;
  thumbnailMedium?: string;
  pageUrl?: string;
  country?: CountryApiDto;
  city?: CityApiDto;
  addressLine1?: string;
  addressLine2?: string;
  addressLocality?: string;
  addressAdministrativeArea?: string;
  addressPostalCode?: string;
  location?: LocationPointApiDto;
  providerArgoCode?: string;
  argoOfferName?: string;
  currencyCode?: string;
  providerCurrencyCode?: string;
  samplePrice?: AmountApiDto;
  internationalPrice?: AmountApiDto;
  fromPrice?: PriceApiDto;
  toPrice?: PriceApiDto;
  exchangeRate?: number;
  schoolFeedback?: ScoreFeedbackApiDto;
  accommodations?: Array<AccommodationApiDto>;
  courses?: Array<CourseApiDto>;
  closures?: Array<DatePeriodApiDto>;
  included?: Array<SchoolPriceIncludedInfoApiDto>;
  online?: boolean;
  teachersHome?: boolean;
  /**
   * Custom element weigh. This property is used to define a custom sort order of this element.
   */
  weight?: number;
  leisure?: string;
  option?: string;
  sampleWeek?: SampleWeekApiDto;
  promotionsAvailable?: boolean;
  validityDate?: string;
  /**
   * Years that school is available to do a quote
   */
  quoteValidityYears?: Array<number>;
  hasSpecialDietSupplement?: boolean;
  /**
   * flag for a High School
   */
  highSchool?: boolean;
}
export namespace SchoolApiDto {
  export type TypesApiDtoEnum = 'ADULT' | 'JUNIOR';
  export const TypesApiDtoEnum = {
    ADULT: 'ADULT' as TypesApiDtoEnum,
    JUNIOR: 'JUNIOR' as TypesApiDtoEnum,
  };
  export type OutDayApiDtoEnum = 'MONDAY' | 'TUESDAY' | 'WEDNESDAY' | 'THURSDAY' | 'FRIDAY' | 'SATURDAY' | 'SUNDAY';
  export const OutDayApiDtoEnum = {
    MONDAY: 'MONDAY' as OutDayApiDtoEnum,
    TUESDAY: 'TUESDAY' as OutDayApiDtoEnum,
    WEDNESDAY: 'WEDNESDAY' as OutDayApiDtoEnum,
    THURSDAY: 'THURSDAY' as OutDayApiDtoEnum,
    FRIDAY: 'FRIDAY' as OutDayApiDtoEnum,
    SATURDAY: 'SATURDAY' as OutDayApiDtoEnum,
    SUNDAY: 'SUNDAY' as OutDayApiDtoEnum,
  };
}
