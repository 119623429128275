import { Component, ElementRef, EventEmitter, inject, Input, OnDestroy, OnInit, Output, ViewChild } from '@angular/core';
import { CommonModule } from '@angular/common';
import { InputNumberModule } from 'primeng/inputnumber';
import { DropdownModule } from 'primeng/dropdown';
import { DateFormatService } from '@klg/shared/i18n';
import { FormsModule } from '@angular/forms';
import { FormFieldComponent } from '@klg/shared/ui/form-field';
import { OverlayOptions } from 'primeng/api';
import { BreakpointObserverService } from '@klg/shared/utils-dom';
import { Subject, takeUntil } from 'rxjs';
import { FocusTrapModule } from 'primeng/focustrap';
import { InputTextModule } from 'primeng/inputtext';

const BASE_Z_INDEX = -1000;

@Component({
  standalone: true,
  selector: 'kng-date-of-birth-field',
  imports: [CommonModule, InputNumberModule, DropdownModule, FormsModule, FormFieldComponent, FocusTrapModule, InputTextModule],
  templateUrl: './date-of-birth-field.component.html',
  styleUrls: ['./date-of-birth-field.component.scss'],
})
export class DateOfBirthFieldComponent implements OnInit, OnDestroy {
  @ViewChild('dayField') dayField: ElementRef<HTMLElement>;
  @ViewChild('monthField', { read: ElementRef }) monthField: ElementRef<HTMLElement>;
  @ViewChild('yearField') yearField: ElementRef<HTMLElement>;

  @Input() date: Date | undefined;
  @Input() label: string | undefined;
  @Input() isValid: null | boolean = false;
  @Input() required = false;
  @Input() errorMessage: string | undefined;

  @Output() dateChange = new EventEmitter<Date>();
  @Output() componentFocusOut = new EventEmitter<FocusEvent>();

  months: { name: string; code: number }[];
  day: number | undefined;
  month: number | undefined;
  year: number | undefined;
  overlayOption: OverlayOptions = {
    autoZIndex: true,
    baseZIndex: BASE_Z_INDEX,
  };
  monthScrollHeight = '500px';

  // Flags to know if the focus is inside the dropdown or not
  private fromChangingMonthDropdown = false;
  private onDropdown = false;

  // Observable to know when the focus event is triggered
  private readonly focusEvent$ = new Subject<FocusEvent>();

  // Observable to know when the focus event is done
  private readonly focusEventDone$ = new Subject<void>();

  private readonly destroy$ = new Subject<void>();

  private readonly breakpointObserverService = inject(BreakpointObserverService);

  ngOnInit(): void {
    this.prepareMonths();

    this.breakpointObserverService.isMobile$.pipe(takeUntil(this.destroy$)).subscribe((isMobile) => {
      if (isMobile) {
        this.monthScrollHeight = '200px';
      } else {
        this.monthScrollHeight = '500px';
      }
    });

    if (this.date) {
      this.day = this.date.getDate();
      this.month = this.date.getMonth();
      this.year = this.date.getFullYear();
    }

    this.focusEventDone$.pipe(takeUntil(this.destroy$)).subscribe(() => {
      this.onDropdown = false;
      this.fromChangingMonthDropdown = false;
    });

    this.focusEvent$.pipe(takeUntil(this.destroy$)).subscribe((event) => {
      this.emitComponentFocusOutEvent(event);
    });
  }

  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }

  updateMonthOfDateOfBirth() {
    this.fromChangingMonthDropdown = true;
    this.updateDateOfBirth();

    // If the date is valid, emit the event of componentFocusOut here instead of in emitComponentFocusOutEvent method
    // because there we have the scenario for when we have selected an option on the dropdown but the rest of the fields are empty
    // on that moment, we don't do anything.
    // To be able to remove the error validation message when the user selects an option on the dropdown, we have to emit the event here
    // because if the user is filling the fields only using the keyboard, until the dropdown loses the focus, the event won't be emitted
    // to remove the error message.
    if (this.isValidDate()) {
      this.componentFocusOut.emit();
    }
  }

  updateDateOfBirth() {
    if (this.isValidDate()) {
      const date = new Date(this.year, this.month, this.day);
      this.dateChange.emit(date);
    } else {
      this.dateChange.emit(null);
    }
  }

  /**
   * Handle the focus event on the month dropdown to set the flag onDropdown to true
   */
  handleMonthDropdownFocusEvent() {
    this.onDropdown = true;
  }

  /**
   * Handle the focus out event to emit the custom componentFocusOut event only when the focus is out
   * of all the elements inside the DateOfBirthField component
   * @param event the event to handle
   */
  handleFocusEvent(event: FocusEvent) {
    // Stop the propagation of the event to manage everything here
    event.stopPropagation();

    // Emit the event with a delay to avoid the focus event to be triggered before the onFocus dropdown event is triggered
    // we need to do it on this way to deal the dropdown event before this one that by default is triggered after the dropdown event
    setTimeout(() => this.focusEvent$.next(event), 200);
  }

  /**
   * Emits the event componentFocusOutEvent only when the focus is out of all the elements inside the DateOfBirthField component.
   * Due to the DateOfBirth component is composed by three components (two inputs - for day and year, and a dropdown - for month),
   * to emit the event we have to take into account the following scenarios:
   * 1. There were changes in the month dropdown, do nothing because the focus is still inside the DateOfBirth component
   * 2. The focus comes from the dropdown and the related target is the day or year field, do nothing because we are still inside the DateOfBirth component
   * 3. The focus goes to the dropdown and, the target is the day or year field, do nothing because we are still inside the DateOfBirth component
   * 4. The related target is not null, but it is not the day, month or year field, emit the event componentFocusOutEvent because the focus is out of the DateOfBirth component
   * 5. The focus does not come from the dropdown, and the target is the day, month or year field and the related target is null, emit the event componentFocusOutEvent because the focus is out of the DateOfBirth component
   * 6. The focus comes from the dropdown, and the target is the month field and the related target is null, emit the event componentFocusOutEvent because the focus is out of the DateOfBirth component.
   * 6.1 There is an edge case on this scenario. This scenario could happen also if the dropdown is open, and then we close the options but without skipping the component.
   * The problem is that, there is no way to distinguish between if the focus is out of the component or if the focus is still inside the component but the dropdown is closed.
   *
   * @param event
   * @private
   */
  private emitComponentFocusOutEvent(event: FocusEvent) {
    // Get the related target and the target elements from the event
    const relatedTargetElement = event.relatedTarget as HTMLElement;
    const targetElement = event.target as HTMLElement;

    // Get the html element that we are going to compare with the elements from the event for the month dropdown
    const monthDropdownElement = this.monthField.nativeElement.querySelector('.p-element');

    // If the there was a change in the month dropdown and the date is not still valid
    // emit the event of focusEventDone$ to reset flags and return because
    // the dropdown has lost the focus but the focus is still inside
    // the date of birth component
    if (this.fromChangingMonthDropdown && !this.isValidDate()) {
      this.focusEventDone$.next();
      return;
    }

    // Scenarios 4, 5 and 6 are handled here
    if (
      this.isFocusOnAnotherComponentOutOfDateOfBirth(relatedTargetElement, monthDropdownElement) ||
      this.isFocusOutOfDateOfBirth(targetElement, relatedTargetElement, monthDropdownElement) ||
      this.isFocusOutOfDateOfBirthFromMonthDropdown(targetElement, relatedTargetElement, monthDropdownElement)
    ) {
      this.componentFocusOut.emit(event);
    }

    // Event to reset flags
    this.focusEventDone$.next();
  }

  /**
   * If the related target is not null, but it is not the day, month or year field, the focus is out of the DateOfBirth component and
   * it is in another component around the DateOfBirth component
   * @private
   */
  private isFocusOnAnotherComponentOutOfDateOfBirth(relatedTargetElement: HTMLElement, monthDropdownElement: Element): boolean {
    return (
      relatedTargetElement &&
      relatedTargetElement !== this.dayField.nativeElement &&
      relatedTargetElement !== this.yearField.nativeElement &&
      relatedTargetElement !== monthDropdownElement
    );
  }

  /**
   * If the focus does not come from the dropdown, and the target is the day, month or year field and the related target is null,
   * the focus is out of the DateOfBirth component and not inside another component around the DateOfBirth component
   * @private
   */
  private isFocusOutOfDateOfBirth(targetElement: HTMLElement, relatedTargetElement: HTMLElement, monthDropdownElement: Element) {
    return (
      !this.onDropdown &&
      !relatedTargetElement &&
      (targetElement === this.dayField.nativeElement || targetElement === this.yearField.nativeElement || targetElement === monthDropdownElement)
    );
  }

  /**
   * If the focus comes from the dropdown, and the target is the month field and the related target is null, the focus is out of the DateOfBirth component.
   *
   * There is an edge case on this scenario. This scenario could happen also if the dropdown is open, and then we close the options but without skipping the component.
   * The problem is that, there is no way to distinguish between if the focus is out of the component or if the focus is still inside the component but the dropdown is closed.
   * @private
   */
  private isFocusOutOfDateOfBirthFromMonthDropdown(targetElement: HTMLElement, relatedTargetElement: HTMLElement, monthDropdownElement: Element) {
    return this.onDropdown && !relatedTargetElement && targetElement === monthDropdownElement;
  }

  private prepareMonths() {
    const calendarConfig = DateFormatService.getCalendarLocaleSettings().monthNames;
    this.months = calendarConfig.map((name, index) => ({
      name,
      code: index,
    }));
  }

  private isValidDate() {
    return this.day && this.month !== null && this.month !== undefined && this.year && this.year?.toString()?.length === 4 && this.day < 32;
  }
}
