import { inject, Injectable } from '@angular/core';
import { Country } from '@klg/shared/data-access/types';
import { CountryDataService } from '@klg/shared/data-access/destination';
import { COMPANIES } from '@klg/shared/types';
import { BaseService, CachedRequestResponse } from '@klg/shared/utils-http';
import { BehaviorSubject, Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { ProductPackage, ProductPackageEnum } from '@klg/quote-tool/shared/types';

const customCountryOrderForEnglishLanguage = ['gb', 'ie', 'us', 'ca', 'ch', 'nz'];

@Injectable({
  providedIn: 'root',
})
export class CountryService extends BaseService<Country> {
  private productPackage$ = new BehaviorSubject<ProductPackage | 'default'>('default');
  private readonly countryDataService = inject(CountryDataService);

  private responseCaches = {
    default: new CachedRequestResponse<Country[]>(() => this.countryDataService.getAllCountriesWithParameters({}), this.cacheSettings.get),
    [ProductPackageEnum.YOUNG_LEARNER_CAMPS]: new CachedRequestResponse<Country[]>(
      () => this.countryDataService.getAllCountriesWithParameters({ productPackage: ProductPackageEnum.YOUNG_LEARNER_CAMPS }),
      this.cacheSettings.get,
    ),
  };

  constructor() {
    super();
  }

  getAll(): Observable<Country[]> {
    return super
      .getAll()
      .pipe(map((countryList) => countryList.map((countryItem: Country) => ({ ...countryItem, weight: this.calculateWeight(countryItem.code) }))));
  }

  changeProductPackage(packageType: ProductPackage) {
    this.productPackage$.next(packageType ? packageType : 'default');
  }

  protected getResponseCache$(): Observable<CachedRequestResponse<Country[]>> {
    return this.productPackage$.pipe(map((productPackage) => this.responseCaches[productPackage]));
  }

  private calculateWeight(countryCode: string): number {
    if (this.company === COMPANIES.KAPLAN && customCountryOrderForEnglishLanguage.includes(countryCode.toLowerCase())) {
      return customCountryOrderForEnglishLanguage.indexOf(countryCode.toLowerCase());
    }

    // If the country code is not in the custom order and company it is not kaplan, return a huge number (MAX_SAFE_INTEGER) just to put it at the end of the list
    return Number.MAX_SAFE_INTEGER;
  }
}
