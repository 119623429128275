import { Component, EventEmitter, Input, OnChanges, Output, SimpleChanges, inject } from '@angular/core';
import { AgeGroups, CourseQuoteInput, QuoteOutput, ROUTE_IDS } from '@klg/quote-tool/shared/types';
import { GoogleAnalyticsEvents, GoogleAnalyticsService } from '@klg/shared/google-analytics';
import { DateFormat } from '@klg/shared/i18n';

@Component({
  selector: 'kng-quote-course-details',
  templateUrl: './quote-course-details.component.html',
  styleUrls: ['./quote-course-details.component.scss'],
})
export class QuoteCourseDetailsComponent implements OnChanges {
  @Input() quote: QuoteOutput | undefined;
  @Input() quoteType = AgeGroups.Adult;
  @Input() isOnlineFlow = false;
  @Input() isOnlineQuoteType = false;
  @Input() isQuoteSlotExpanded = false;
  @Input() languageLabel = $localize`Language`;
  @Input() languageName = '';
  @Input() schoolLabel = $localize`Your School`;
  @Input() destination = '';
  @Input() currentSchoolName = '';
  @Input() currentCourses: CourseQuoteInput[] = [];
  @Input() courseDurationLabel = $localize`Course Duration`;
  @Input() areQuoteTypesAdultAndOnline = false;
  @Output() editButtonClicked = new EventEmitter<ROUTE_IDS>();
  dateFormat = DateFormat;
  timezoneOffset = 0;
  formattedTimezoneOffset = '';
  routeIds = ROUTE_IDS;

  readonly NA = $localize`N/A`;
  readonly AgeGroups = AgeGroups;

  private readonly googleAnalyticsService = inject(GoogleAnalyticsService);

  ngOnChanges({ quote }: SimpleChanges): void {
    const quoteInput = quote?.currentValue?.input;
    if (quoteInput) {
      this.timezoneOffset = quoteInput.onlineClassCombo?.timezoneOffset;
      this.formattedTimezoneOffset = quoteInput.onlineClassCombo?.formattedTimezoneOffset;
    }
  }

  editCourseDetails() {
    this.googleAnalyticsService.trackClickEvent(GoogleAnalyticsEvents.QUOTE_COURSE_EDIT);
    switch (this.quoteType) {
      case AgeGroups.Adult:
        this.editButtonClicked.emit(this.routeIds.ADULT_COURSE);
        break;
      case AgeGroups.Junior:
        this.editButtonClicked.emit(this.routeIds.JUNIOR_COURSE);
        break;
      case AgeGroups.Online:
        this.editButtonClicked.emit(this.routeIds.ONLINE_BASE);
        break;
    }
  }
}
