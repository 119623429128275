import { COMPANIES } from '@klg/shared/types';

export const environment = {
  API_BASE_PATH: 'https://api-dev.klgintdc.com/api',
  I18N_PATH: 'https://translations-dev.klgintdc.com',
  COMPANY: COMPANIES.ESL,
  LOCALE: 'en-US',
  RTL_ENABLED: false,
  production: false,
  environment: null,
  PATH_PREFIX: '',
  FLYWIRE_DEPOSITS_RETURN_URL: '',
};
