import { ApplicationConfig } from '@angular/platform-browser';
import { provideAnimations } from '@angular/platform-browser/animations';
import { provideHttpClient, withInterceptors } from '@angular/common/http';
import { environment } from '../environments/environment';
import { APP_NAME, COMPANY, ENVIRONMENT } from '@klg/shared/tokens';
import { importProvidersFrom } from '@angular/core';
import { BASE_PATH } from '@klg/shared/api/variables';
import { provideRouter } from '@angular/router';
import { quoteToolRoutes } from './app.routes';
import { QuoteFormLinkUrlsService } from '@klg/quote-tool/shared/data-access/quote';
import { LoggerModule, NgxLoggerLevel, TOKEN_LOGGER_SERVER_SERVICE } from 'ngx-logger';
import { APPLICATION_CONFIGURATION_SERVICE } from '@klg/shared/app-config';
import { getApplicationInitializationProviders } from '@klg/shared/app-init/functions/get-application-initializers-providers.function';
import { getCoreProviders } from '@klg/shared/app-init/functions/get-core-providers.function';
import { getErrorHandlerProviders } from '@klg/shared/app-init/functions/get-error-handler-providers.function';
import { ApplicationConfigurationService } from './application-configuration.service';
import { httpErrorInterceptorFn } from '@klg/shared/errors';
import { getGoogleAnalyticsProvider } from '@klg/shared/app-init/functions/get-google-analytics-provider.function';
import { CustomisedNGXLoggerServerService } from '@klg/shared/app-init';
import { QuoteToolGoogleAnalyticsDataLayerService } from '@klg/quote-tool/shared/google-analytics';

export const appConfig: ApplicationConfig = {
  providers: [
    provideAnimations(),
    provideHttpClient(withInterceptors([httpErrorInterceptorFn])),
    provideRouter(quoteToolRoutes),
    importProvidersFrom(
      LoggerModule.forRoot(
        {
          level: NgxLoggerLevel.DEBUG,
          disableConsoleLogging: false,
          serverLogLevel: NgxLoggerLevel.ERROR,
        },
        {
          serverProvider: {
            provide: TOKEN_LOGGER_SERVER_SERVICE,
            useClass: CustomisedNGXLoggerServerService,
          },
        },
      ),
    ),
    QuoteFormLinkUrlsService,
    { provide: APPLICATION_CONFIGURATION_SERVICE, useClass: ApplicationConfigurationService },
    ...getApplicationInitializationProviders(environment),
    ...getCoreProviders(environment),
    ...getErrorHandlerProviders(),
    getGoogleAnalyticsProvider(QuoteToolGoogleAnalyticsDataLayerService),
    { provide: ENVIRONMENT, useValue: {} },
    { provide: BASE_PATH, useValue: environment.API_BASE_PATH },
    { provide: COMPANY, useValue: environment.COMPANY },
    { provide: APP_NAME, useValue: 'esl-quick-quote-tool' },
  ],
};
